import React from "react";
//import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import axios from "axios";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import store from "store/configureStore";
import App from "./App";
import { AUTH_LOG_IN_USER } from "actions/types";
import { returnStripePublishable } from "functions";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { AUTH_LOG_IN_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";
import "./index.sass";

const history = createBrowserHistory();
const token = localStorage.getItem("token");
const tokenProvider = localStorage.getItem("tokenProvider");
const stripePromise = loadStripe(returnStripePublishable());
const root = ReactDOM.createRoot(document.getElementById("root"))

if (token) {
  store.dispatch({ type: AUTH_LOG_IN_USER });
  history.push("/userhome");
}

if (tokenProvider) {
  store.dispatch({ type: AUTH_LOG_IN_PROVIDER });
  history.push("/providerhome");
}

// CLIENT VERSION CHECK
document.addEventListener("visibilitychange", async () => {
  if (document.visibilityState === "visible") {
    try {
      const currentVersion = localStorage.getItem("currentVersion");
      const res = await axios.get("/api/client_settings/client_version_check", {
        params: { currentVersion: currentVersion },
      });
      if (res.data.clientUpdate) {
        localStorage.setItem("currentVersion", res.data.clientVersion);
        caches.keys().then(function (names) {
          for (let name of names) caches.delete(name);
        });
        window.location.reload(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  }
});

//root.render(<App />);
root.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <Router>
        <App />
      </Router>
    </Elements>
  </Provider>
//  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept("./App", () => {
    ReactDOM.render(
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <Router>
            <App />
          </Router>
        </Elements>
      </Provider>,
      document.getElementById("root")
    );
  });
}
