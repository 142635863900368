import {
  InputFieldV5,
  SelectV5,
  CheckboxV5,
  SelectButtonsV5,
  LabelOnly,
} from "components/form";

const INPUT_FIELDS = [
  {
    name: "onGoing",
    group: "foodDiary",
    type: "checkbox",
    className: "input ",
    component: CheckboxV5,
    required: false,
    lastInGroup: true,
    userCanEdit: true,
    labelOnly: false,
    noLabel: true,
  },
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    required: true,
    component: InputFieldV5,
    userCanEdit: true,
  },
  {
    name: "dateEnd",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "time",
    group: "common",
    type: "time",
    icon: "far fa-clock",
    className: "input ",
    required: true,
    component: InputFieldV5,
    lastInGroup: true,
    userCanEdit: true,
  },
  {
    name: "productName",
    group: "foodDiary",
    type: "text",
    icon: "fas fa-utensils",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
  },
  {
    name: "frequency",
    group: "foodDiary",
    type: "select",
    icon: "fas fa-utensils",
    className: "select ",
    required: false,
    component: SelectV5,
    userCanEdit: true,
  },
  {
    name: "foodAmount",
    group: "foodDiary",
    type: "number",
    icon: "fas fa-utensils",
    className: "input ",
    required: false,
    component: InputFieldV5,
    min: 0,
    step: 0.001,
    userCanEdit: true,
  },
  {
    name: "foodAmountUnit",
    group: "foodDiary",
    selectOptionsGroup: "amountUnit",
    type: "select",
    icon: "fas fa-utensils",
    className: "select ",
    required: false,
    component: SelectButtonsV5,
    userCanEdit: true,
    lastInGroup: true,
  },
  {
    name: "manufacturer",
    group: "foodDiary",
    type: "text",
    icon: "fas fa-utensils",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    additionalField: true,
  },
  {
    name: "foodDetail",
    group: "foodDiary",
    type: "text",
    icon: "fas fa-utensils",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    additionalField: true,
    lastInGroup: true,
  },
  {
    name: "convertibleEnergy",
    group: "foodDiary",
    component: LabelOnly,
    additionalField: true,
  },
  {
    // kcal/kg
    name: "kcalKg",
    group: "foodDiary",
    type: "number",
    icon: "fas fa-bolt",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    max: 7000,
    min: 0,
    step: 1,
    lastInGroup: false,
    additionalField: true,
  },
  {
    // kJ/kg
    name: "kJKg",
    group: "foodDiary",
    type: "number",
    icon: "fas fas fa-bolt",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    max: 30000,
    min: 0,
    step: 1,
    lastInGroup: true,
    additionalField: true,
  },
  {
    name: "proteinContent",
    group: "foodDiary",
    type: "number",
    icon: "fas fa-percent",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    max: 100,
    min: 0,
    step: 0.01,
    additionalField: true,
  },
  {
    name: "carbohydrateContent",
    group: "foodDiary",
    type: "number",
    icon: "fas fa-percent",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    max: 100,
    min: 0,
    step: 0.01,
    additionalField: true,
  },
  {
    name: "fatContent",
    group: "foodDiary",
    type: "number",
    icon: "fas fa-percent",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    max: 100,
    min: 0,
    step: 0.01,
    additionalField: true,
  },
  {
    name: "calciumContent",
    group: "foodDiary",
    type: "number",
    icon: "fas fa-percent",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    max: 100,
    min: 0,
    step: 0.01,
    additionalField: true,
  },
];

export { INPUT_FIELDS };
