import React from "react"
import TagGroup from "components/elements/TagGroup"
import Tag from "components/elements/Tag"

function LitterMemembersTags({ litterMembers }) {
  return (
    <TagGroup>
      {litterMembers.map((m) => (
        <Tag key={m._id} label={m.petName} />
      ))}
    </TagGroup>
  )
}

export default LitterMemembersTags
