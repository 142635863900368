import React from "react"
import styled from "styled-components"
import ImageContainer from "components/styled/Image_Container"

const Bubble = styled.div`
  z-index: 2;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  padding: 5px;
`

const ProfileName = styled.div`
  z-index: 2;
  padding: 4px;
  color: white;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  position: absolute;
  left: 0px;
  top: 0px;
`

export default function MenuIcon(props) {
  return (
    <ImageContainer>
      <figure className="image is-1by1">
        {props.feature && props.useProfileImage ? (
          <img
            className="is-rounded"
            src={props.profileImage}
            alt={"profile_image"}
          />
        ) : (
          <img src={props.menuIcon.img} alt={props.menuIcon.alt} />
        )}
      </figure>
      {props.feature ? (
        props.useProfileImage ? (
          <Bubble>
            <figure className="image is-24x24">
              <img src={props.menuIcon.img} alt={props.menuIcon.alt} />
            </figure>
          </Bubble>
        ) : (
          <ProfileName>{props.petName}</ProfileName>
        )
      ) : null}
    </ImageContainer>
  )
}
