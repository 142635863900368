import React, { Component } from "react";
import { connect } from "react-redux";
import { feedback as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "forms/fields/feedback";
import { FormButtons, FormTitle } from "components/form";

class FeedbackForm extends Component {
  renderFields() {
    const { values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (
        values.feedbackCategory !== "feature" &&
        field.name === "featureCategory"
      )
        return null;
      return <Field key={i} values={values} {...field} />;
    });
  }

  render() {
    const { viewMode, editMode, errors, dirty, isSubmitting } = this.props;

    return (
      <Form>
        <FormTitle group={"feedback"} />
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          linkTo={"/userhome/feedback_and_support"}
        />
        <br />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.reducers.auth.user,
  };
};

FeedbackForm = withFormik({
  mapPropsToValues({ user }) {
    return {
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm"),
      email: user.email,
      feedbackCategory: "",
      featureCategory: "",
      feedback: "",
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email("invalid").required("required"),
    feedbackCategory: Yup.string().required("required"),
    featureCategory: Yup.string().when("feedbackCategory", {
      is: "feature",
      then: Yup.string().required("required"),
      otherwise: Yup.string(),
    }),
    feedback: Yup.string().max(500, "max-500").required("required"),
  }),
  handleSubmit(values, { props, setSubmitting, resetForm }) {
    props.submitFeedback(values, setSubmitting, resetForm);
  },
})(FeedbackForm);

export default connect(mapStateToProps, actions)(FeedbackForm);
