import { InputFieldV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "resetPasswordCode",
    group: "resetPassword",
    type: "text",
    icon: "fa fa-key",
    className: "input ",
    required: true,
    component: InputFieldV5,
  },
  {
    name: "newPassword",
    group: "resetPassword",
    type: "password",
    icon: "fa fa-key",
    className: "input ",
    required: true,
    component: InputFieldV5,
  },
  {
    name: "newPassword2",
    group: "resetPassword",
    type: "password",
    icon: "fa fa-key",
    className: "input ",
    required: true,
    component: InputFieldV5,
  },
];

export { INPUT_FIELDS };
