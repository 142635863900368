import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class Fmpi extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.fmpi,
      components: {
        result: state.intl.messages.components.painIndex.result,
      },
    },
    fmpi: state.reducers.fmpi,
  };
};

export default connect(mapStateToProps)(Fmpi);
