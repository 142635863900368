const trainingTypes = {
  defaultSelection: {
    dog: [
      "agility",
      "airSearchTrail",
      "dogShow",
      "dogDancing",
      "frisbee",
      "fieldTrial",
      "hunting",
      "noseWork",
      "obedience",
      "rallyObedience",
      "other",
    ],
    cat: [
      "agility",
      "exhibition",
      "freeWalking",
      "noseWork",
      "outsideEnclosure",
      "playing",
      "tricks",
      "walkingOnLeash",
      "other",
    ],
    horse: [
      "combinedDriving",
      "dressage",
      "enduranceRiding",
      "eventing",
      "gallop",
      "showJumping",
      "vaulting",
      "westernRiding",
      "other",
    ],
  },
  dog: [
    "agility",
    "airSearchTrail",
    "ballHerding",
    "behaviorTest",
    "bikejoring",
    "bloodTracking",
    "bodyCare",
    "canicross",
    "coursingTrial",
    "coursingTrialPack",
    "dobo",
    "dogDancing",
    "dogShow",
    "dogWallClimb",
    "dragWeight",
    "everydayObedience",
    "fhTracking",
    "fieldTest",
    "fieldTrial",
    "fitnessTraining",
    "flyball",
    "frisbee",
    "guideDog",
    "herding",
    "highJump",
    "hunting",
    "idTracking",
    "igp",
    "injuredElkTracking",
    "intervalRunning",
    "ipo",
    "ipofh",
    "ipor",
    "jumpingGrid",
    "kickbike",
    "longDistanceRun",
    "lureCoursing",
    "mondioring",
    "noseWork",
    "obedience",
    "objectSearch",
    "protection",
    "racingTrial",
    "rallyFreestyle",
    "rallyObedience",
    "relayRace",
    "ruinTracking",
    "scentWork",
    "search",
    "searchAndRescue",
    "skijoring",
    "sleddogTraining",
    "specialtyTracking",
    "sprint",
    "swimming",
    "tracking",
    "trickTraining",
    "waterRescue",
    "weightPull",
    "workingDogObedience",
    "workingTrial",
    "other",
  ],
  cat: [
    "agility",
    "exhibition",
    "freeWalking",
    "noseWork",
    "outsideEnclosure",
    "playing",
    "tricks",
    "walkingOnLeash",
    "other",
  ],
  horse: [
    "combinedDriving",
    "dressage",
    "enduranceRiding",
    "eventing",
    "gallop",
    "showJumping",
    "vaulting",
    "westernRiding",
    "other",
  ],
}

export default trainingTypes
