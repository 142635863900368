import React from "react";
import { connect } from "react-redux";

const TaxAmount = (props) => {
  const { field, type, icon, className, min, step, label, placeholder } = props;
  return (
    <div className="field">
      <label className="label is-small">
        <label className="label is-small">
          <div className="heading has-text-grey">{label}</div>
        </label>
      </label>
      <div className="control has-icons-left has-icons-right">
        <input
          {...field}
          type={type}
          className={className}
          min={min}
          step={step}
          placeholder={placeholder}
          readOnly
        />
        <span className="icon is-small is-left">
          <i className={icon} />
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { group, field }) => {
  const { locale, messages } = state.intl;
  return {
    label: messages.components.forms.labels[group][field.name][locale],
  };
};

export default connect(mapStateToProps)(TaxAmount);
