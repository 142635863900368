import { InputFieldV5 } from "components/form";

export const INPUT_FIELDS = [
  {
    name: "declineReason",
    group: "appointments",
    type: "text",
    icon: "fas fa-comment",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
    charLimit: 50,
  },
];
