import React from "react"
import PropTypes from "prop-types"

const GoogleButton = ({ login, register, messages, onClick, children }) => {
  return (
    <button onClick={onClick} className="button is-fullwidth is-danger">
      <span className="icon">
        <i
          className={
            login
              ? messages.google.login.icon
              : register && messages.google.register.icon
          }
        />
      </span>
      <span style={{ fontSize: 14 }}>{children}</span>
    </button>
  )
}

GoogleButton.propTypes = {
  login: PropTypes.bool,
  register: PropTypes.bool,
  messages: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.any
}

export default GoogleButton
