import { AUTH_LOG_OUT_USER } from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";
import {
  BODY_MARKER_FETCH_MARKERS,
  BODY_MARKER_ADD_MARKER,
  BODY_MARKER_ADD_MARKER_DETAIL_FRONT,
  BODY_MARKER_ADD_MARKER_DETAIL_BACK,
  BODY_MARKER_DELETE_MARKER,
  BODY_MARKER_UPDATE_MARKER_DETAIL_FRONT,
  BODY_MARKER_UPDATE_MARKER_DETAIL_BACK,
  BODY_MARKER_DELETE_MARKER_DETAIL_BACK,
  BODY_MARKER_ARCHIVE_MARKER_DETAIL_BACK,
  BODY_MARKER_DELETE_MARKER_DETAIL_FRONT,
  BODY_MARKER_ARCHIVE_MARKER_DETAIL_FRONT,
} from "./actions";
import { replaceObjectInArrayOfObjects } from "functions";

const INITIAL_STATE = {
  frontMarkers: [],
  backMarkers: [],
  archivedIndicators: [],
  lastFetchPetID: "",
};

export default function BodyMarker(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BODY_MARKER_FETCH_MARKERS:
      return {
        ...state,
        frontMarkers: action.payload.frontMarkers,
        backMarkers: action.payload.backMarkers,
        lastFetchPetID: action.payload._pet,
      };
    case BODY_MARKER_ADD_MARKER: {
      return {
        ...state,
        frontMarkers:
          action.payload.type === "front"
            ? [...state.frontMarkers, action.payload]
            : state.frontMarkers,
        backMarkers:
          action.payload.type === "back"
            ? [...state.backMarkers, action.payload]
            : state.backMarkers,
      };
    }
    case BODY_MARKER_DELETE_MARKER:
      return {
        ...state,
        frontMarkers: state.frontMarkers.filter(
          (marker) => marker._id !== action.payload
        ),
        backMarkers: state.backMarkers.filter(
          (marker) => marker._id !== action.payload
        ),
      };
    case BODY_MARKER_ADD_MARKER_DETAIL_FRONT:
    case BODY_MARKER_UPDATE_MARKER_DETAIL_FRONT:
    case BODY_MARKER_DELETE_MARKER_DETAIL_FRONT:
    case BODY_MARKER_ARCHIVE_MARKER_DETAIL_FRONT:
      return {
        ...state,
        frontMarkers: replaceObjectInArrayOfObjects(
          state.frontMarkers,
          action.payload
        ),
      };
    case BODY_MARKER_ADD_MARKER_DETAIL_BACK:
    case BODY_MARKER_UPDATE_MARKER_DETAIL_BACK:
    case BODY_MARKER_DELETE_MARKER_DETAIL_BACK:
    case BODY_MARKER_ARCHIVE_MARKER_DETAIL_BACK:
      return {
        ...state,
        backMarkers: replaceObjectInArrayOfObjects(
          state.backMarkers,
          action.payload
        ),
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
