import { features } from "assets";
import Landing from "components/features/Landing";
import NewEntryTrainingDiary from "./New_Entry_Training_Diary";
import ViewEntriesTrainingDiary from "./View_Entries_Training_Diary";
import ViewEntryTrainingDiary from "./View_Entry_Training_Diary";
import SettingsTrainingDiary from "./Settings_Training_Diary";

const trainingDiaryConfig = {
  name: "trainingDiary",
  type: "feature",
  chartFunc: "trainingDiary",
  defaultRoute: "/userhome/dashboard/training_diary",
  menuIcon: {
    img: features.activity.trainingDiaryViewEntries,
    alt: "training_diary_feature_icon",
  },
  nav: [
    {
      name: "landing",
      disabled: false,
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      name: "newEntry",
      disabled: false,
      menuItem: true,
      image: {
        img: features.activity.trainingDiaryNewEntry,
        alt: "training_diary_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: NewEntryTrainingDiary,
      path: "/new_entry",
    },
    {
      name: "viewEntries",
      disabled: false,
      menuItem: true,
      hr: true,

      image: {
        img: features.activity.trainingDiaryViewEntries,
        alt: "training_diary_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntriesTrainingDiary,
      path: "/view_entries",
    },
    {
      name: "settings",
      disabled: false,
      menuItem: true,
      image: {
        img: features.activity.trainingDiarySettings,
        alt: "training_diary_settings_icon",
      },
      icon: "fas fa-cog",
      component: SettingsTrainingDiary,
      path: "/settings",
    },
    {
      name: "viewEntry",
      disabled: false,
      menuItem: false,
      component: ViewEntryTrainingDiary,
      path: "/view_entry/:id",
    },
  ],
  tableView: {
    trainingDiary: {
      headers: ["date", "trainingSubject", "trainingType", "attachmentURL"],
      mobileHeaders: [
        "date",
        "trainingSubject",
        "trainingType",
        "attachmentURL",
      ],
    },
  },
};

export default trainingDiaryConfig;
