import React from "react";
import { connect } from "react-redux";

function ExistingLitterMemberColors(props) {
  if (props.litterMembers === undefined) return null;

  if (props.litterMembers.length === 0) return null;

  if (props.viewMode) return null;

  return (
    <div className="field">
      <div className="label is-small heading has-text-grey">{props.label}</div>
      <div className="columns is-mobile is-multiline">
        {props.litterMembers.map((member) => (
          <div key={member._id} className="column is-narrow">
            <div
              style={{
                backgroundColor: member.litterMemberColorIdentifier,
                padding: 10,
                borderRadius: 12,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { _id } = state.reducers.litters.selectedLitter;
  const { locale } = state.intl;
  return {
    litterMembers: state.reducers.litters.litterMembers[_id],
    label:
      state.intl.messages.components.forms.labels.addLitterMember
        .existingLitterMemberColors[locale],
  };
};

export default connect(mapStateToProps)(ExistingLitterMemberColors);
