import axios from "axios";
import {
  AWARD_CABINET_FETCH,
  AWARD_CABINET_ADD,
  AWARD_CABINET_UPDATE,
  AWARD_CABINET_DELETE,
  ACTION_STARTED,
  ACTION_ERROR,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
  MODALS_HIDE,
} from "./types";

// Action Creators
export const fetchAwards = (pet) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: AWARD_CABINET_FETCH });
    const res = await axios.get("/api/award_cabinet/fetch", {
      headers: {
        authorization: localStorage.getItem("token"),
      },
      params: { _pet: pet._id, petType: pet.petType },
    });
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: AWARD_CABINET_FETCH, payload: res.data.awards, pet });
  } catch (error) {
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data });
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      });
    }
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 3000);
  }
};

export const addAward =
  (values, setSubmitting, history, successRoute) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AWARD_CABINET_ADD });
      const res = await axios.post("/api/award_cabinet/add", values, {
        headers: { authorization: localStorage.getItem("token") },
      });
      setSubmitting(false);
      dispatch({ type: ACTION_ENDED });
      dispatch({ type: AWARD_CABINET_ADD, payload: res.data.award });
      dispatch({ type: ACTION_RESET });
      return history.push(successRoute);
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 3000);
    }
  };

export const updateAward =
  (values, setSubmitting, history, successRoute, petType) =>
  async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AWARD_CABINET_UPDATE });
      const res = await axios.put(
        "/api/award_cabinet/update",
        { values, petType },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      );
      setSubmitting(false);
      dispatch({ type: AWARD_CABINET_UPDATE, payload: res.data.award });
      dispatch({ type: ACTION_ENDED });
      history.push(successRoute);
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 2000);
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 3000);
    }
  };

export const deleteAward =
  (_id, petType, history, successRoute) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AWARD_CABINET_DELETE });
      dispatch({ type: MODALS_HIDE });
      const res = await axios.put(
        "/api/award_cabinet/delete",
        { _id, petType },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      );
      dispatch({ type: AWARD_CABINET_DELETE, payload: res.data.award });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "deleteSuccess",
      });
      dispatch({ type: ACTION_ENDED });
      history.push(successRoute);
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 2000);
    } catch (error) {
      if (error.response) {
        dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 3000);
    }
  };

const awardCabinetActions = {
  fetchAwards,
  addAward,
  updateAward,
  deleteAward,
};

export default awardCabinetActions;
