import {
  InputFieldV5,
  TextareaV5,
  SelectV5,
  UrlInputLinkV5,
} from "components/form";

export const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "fa fa-calendar",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "time",
    group: "common",
    type: "time",
    icon: "fa fa-clock",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "providerServiceName",
    group: "appointments",
    type: "text",
    icon: "fas fa-comment",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
    charLimit: 50,
  },
  {
    name: "providerServiceType",
    group: "providerRegister",
    altName: "serviceType",
    type: "select",
    icon: "fa fa-address-book",
    className: "select  is-fullwidth",
    component: SelectV5,
    userCanEdit: true,
    required: false,
    lastInGroup: true,
  },
  {
    name: "description",
    group: "common",
    type: "text",
    icon: "fas fa-comment",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
    charLimit: 250,
  },
  {
    name: "notes",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    userCanEdit: true,
    charLimit: 1000,
  },
  {
    name: "attachmentURL",
    group: "common",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: UrlInputLinkV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "postAppointmentNotes",
    group: "appointments",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    userCanEdit: true,
    charLimit: 1000,
    renderCondition: (mode) => mode,
  },
];
