import React from "react";
import { connect } from "react-redux";
import CardNotice from "./Card_Notice";

const RequiresSubOrTrialNotice = (props) => {
  const arr = [props.user.trialPeriod, props.user.subscription];

  if (
    (props.requiresSubOrTrial && !arr.includes(true)) ||
    props.user.signupStage === 1
  )
    return (
      <CardNotice>{props.messages.subOrTrialRequired[props.locale]}</CardNotice>
    );

  return null;
};

const mapStateToProps = (state) => {
  return {
    user: state.reducers.auth.user,
    locale: state.intl.locale,
    messages: state.intl.messages.components.cards.notices,
  };
};

export default connect(mapStateToProps)(RequiresSubOrTrialNotice);
