import _101 from "./cat_101.svg";
import _102 from "./cat_102.svg";
import _103 from "./cat_103.svg";
import _104 from "./cat_104.svg";

import _106 from "./cat_106.svg";
import _107 from "./cat_107.svg";
import _108 from "./cat_108.svg";
import _109 from "./cat_109.svg";

import _201 from "./cat_201.svg";
import _202 from "./cat_202.svg";
import _203 from "./cat_203.svg";
import _204 from "./cat_204.svg";

import _206 from "./cat_206.svg";
import _207 from "./cat_207.svg";
import _208 from "./cat_208.svg";
import _209 from "./cat_209.svg";

import _301 from "./cat_301.svg";
import _302 from "./cat_302.svg";
import _303 from "./cat_303.svg";
import _304 from "./cat_304.svg";

import _307 from "./cat_307.svg";
import _308 from "./cat_308.svg";
import _309 from "./cat_309.svg";

import _401 from "./cat_401.svg";
import _402 from "./cat_402.svg";
import _403 from "./cat_403.svg";
import _404 from "./cat_404.svg";

import _407 from "./cat_407.svg";
import _408 from "./cat_408.svg";
import _409 from "./cat_409.svg";

const individuals = {
  _101,
  _102,
  _103,
  _104,
  _106,
  _107,
  _108,
  _109,
  _201,
  _202,
  _203,
  _204,
  _206,
  _207,
  _208,
  _209,
  _301,
  _302,
  _303,
  _304,
  _307,
  _308,
  _309,
  _401,
  _402,
  _403,
  _404,
  _407,
  _408,
  _409,
};

export default individuals;
