import React from "react"
import { connect } from "react-redux"
import { litters as actions } from "actions"
import SmartTile from "components/SmartTile"

function LittersSection(props) {
  const { litters } = props

  if (litters.length === 0) return <div />

  return (
    <>
      {litters.map((litter, i) => (
        <div
          key={i}
          className={props.config.layout.column}
          onClick={() => props.selectLitter(litter)}
        >
          <SmartTile
            litterTile
            path={"/userhome/dashboard/litter"}
            litterName={litter.litterName}
          />
        </div>
      ))}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const litters = state.reducers.litters.data.filter(
    (litter) => litter._pet === ownProps.selectedPetId
  )
  return {
    litters,
  }
}

export default connect(mapStateToProps, actions)(LittersSection)
