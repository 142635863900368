import { FEATURE_RESOURCES_FETCH } from "actions/types"
import _ from "lodash"

const INITIAL_STATE = {
  featuresFetchedFor: [],
  resources: {},
}

export default function FeatureResourcesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FEATURE_RESOURCES_FETCH:
      return {
        ...state,
        featuresFetchedFor: _.uniqWith(
          [...state.featuresFetchedFor, action.payload.feature],
          _.isEqual
        ),
        resources: {
          ...state.resources,
          [action.payload.feature]: action.payload.featureResources,
        },
      }
    default:
      return state
  }
}
