import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TileWrapper from "./TileWrapper";
import Header from "./Header";
import TileBody from "./TileBody";

function FeatureTile(props) {
  return (
    <TileWrapper>
      <Link to={props.path}>
        <Header
          title={props.messages.features[props.name].main.name[props.locale]}
        />
        <TileBody img={props.img.src} alt={props.img.alt} />
      </Link>
    </TileWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    messages: {
      features: {
        ...state.intl.messages.containers.features,
        ...state.intl.messages.containers.litterFeatures,
        ...state.intl.messages.containers.litterMemberFeatures,
      },
    },
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(FeatureTile);
