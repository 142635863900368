import React from "react";
import { connect } from "react-redux";
import Image from "components/image";

function ImageButtonInputV5(props) {
  const { field, form, viewMode, label, buttons } = props;
  function handleSetValue(value) {
    form.setFieldValue(field.name, value);
  }

  const buttonWrapStyle = { padding: 4, cursor: "pointer"};

  return (
    <div className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">{label}</div>
      </label>
      <div className="field">
        <div className="buttons is-centered">
          {buttons.map((button, i) => (
            <div
              key={i}
              style={
                form.values[field.name] === button.value
                  ? { borderBottom: "3px ridge grey", ...buttonWrapStyle }
                  : buttonWrapStyle
              }
              className="control"
              onClick={viewMode ? null : () => handleSetValue(button.value)}
            >
              <Image
                {...button}
                style={
                  form.values[field.name] !== "" &&
                  form.values[field.name] !== button.value
                    ? {
                        WebkitFilter: "grayscale(100%)",
                        filter: "grayscale(100%)",
                      }
                    : {}
                }
              />
              {button.showValue && (
                <div className="has-text-centered">{button.value}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, { group, field }) => {
  const { locale, messages } = state.intl;
  return {
    label: messages.components.forms.labels[group][field.name][locale],
  };
};

export default connect(mapStateToProps)(ImageButtonInputV5);
