import React, { useEffect } from "react"

function LandingRedirect(props) {
  useEffect(() => {
    props.history.push(props.redirect)
  })

  return <div />
}

export default LandingRedirect
