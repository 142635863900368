import React from "react";
import Question from "./Question";
import Progress from "./Progress";

const Questions = ({
  questions,
  currentQuestion,
  currentAnswer,
  handleOptionSelect,
}) => {
  return (
    <div>
      <Progress />
      <Question
        question={questions[currentQuestion - 1]}
        currentAnswer={currentAnswer}
        handleOptionSelect={handleOptionSelect}
      />
      <br />
    </div>
  );
};

export default Questions;
