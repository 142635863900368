import React from "react"

function FeatureLink(props) {
  return (
    <div className="box is-paddingless">
      <div className="columns is-mobile is-paddingless">
        <div
          className="column is-2 has-text-centered"
          style={{
            backgroundColor: "#00d1b2",
            borderRadius: "4px 0px 0px 4px",
          }}
        >
          <i className={`${props.icon} has-text-white`} />
        </div>
        <div
          className="column has-text-weight-bold"
          style={{ backgroundColor: "whitesmoke" }}
        >
          {props.title}
        </div>
      </div>
    </div>
  )
}

export default FeatureLink
