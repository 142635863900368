import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { campaigns as actions } from "actions";
import { CAMPAIGNS_REDEEM_CODE } from "actions/types";
import Section from "components/layout/Section";
import RedeemCodeInput from "./Redeem_Code/Redeem_Code_Input";
import Message from "components/message";

class RedeemCode extends Component {
  state = { code: "", redeemCode: true };

  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({
      code: e.target.value,
    });
  };

  toggleRedeemCode = () => {
    this.setState({
      redeemCode: !this.state.redeemCode,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.redeemCode(this.state.code, this.props.history);
    this.setState({ code: "" });
  };

  renderSection() {
    const { actionCalls, user } = this.props;
    const { code } = this.state;

    if (
      !_.isEmpty(actionCalls.actionSuccess) &&
      actionCalls.action === CAMPAIGNS_REDEEM_CODE
    )
      return <Message success message={actionCalls.actionSuccess.message} />;

    if (
      !_.isEmpty(actionCalls.actionError) &&
      actionCalls.action === CAMPAIGNS_REDEEM_CODE
    )
      return <Message error message={actionCalls.actionError.message} />;

    if (user.subscription)
      return <Message info message={"notAvailableToSubs"} />;

    return (
      <div className="columns">
        <div className="column is-narrow">
          <RedeemCodeInput
            onChange={this.handleInputChange}
            onClick={this.handleSubmit}
            code={code}
            serverIsBusy={actionCalls.actionInProcess}
          />
          <br />
        </div>
      </div>
    );
  }

  render() {
    return <Section>{this.renderSection()}</Section>;
  }
}

const mapStateToProps = (state) => {
  return {
    actionCalls: state.reducers.actionCalls,
  };
};

export default connect(mapStateToProps, actions)(RedeemCode);
