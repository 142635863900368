import _ from "lodash";
import { shortDateForDisplay } from "functions/moment";
import { COLORS } from "components/chart/constants";

export const chartDataForTrainingDiary = (data, messages, locale) => {
  let sortedData = _.sortBy(data, "date");
  let labels = sortedData.map((item) => shortDateForDisplay(item.date));
  let trainersFeeling = sortedData.map((item) => item.trainersFeeling);
  let petsConcentration = sortedData.map((item) => item.petsConcentration);
  let trainerAndPetCoop = sortedData.map((item) => item.trainerAndPetCoop);
  let overalProgress = sortedData.map((item) => item.overalProgress);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: messages.labels.trainersFeeling[locale],
        data: trainersFeeling,
        fill: false,
        backgroundColor: COLORS.trainersFeeling,
        borderColor: COLORS.trainersFeeling,
        pointBorderWidth: 10,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointRadius: 3,
      },
      {
        label: messages.labels.petsConcentration[locale],
        data: petsConcentration,
        fill: false,
        backgroundColor: COLORS.petsConcentration,
        borderColor: COLORS.petsConcentration,
        pointBorderWidth: 10,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointRadius: 3,
      },
      {
        label: messages.labels.trainerAndPetCoop[locale],
        data: trainerAndPetCoop,
        fill: false,
        backgroundColor: COLORS.trainerAndPetCoop,
        borderColor: COLORS.trainerAndPetCoop,
        pointBorderWidth: 10,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointRadius: 3,
      },
      {
        label: messages.labels.overalProgress[locale],
        data: overalProgress,
        fill: false,
        backgroundColor: COLORS.overalProgress,
        borderColor: COLORS.overalProgress,
        pointBorderWidth: 10,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointRadius: 3,
      },
    ],
  };

  return chartData;
};

export const chartDataForHCPIShowAll = (data, messages, locale) => {
  let sortedData = _.sortBy(data, "date");
  let labels = sortedData.map((item) => shortDateForDisplay(item.date));
  let hcpiScore = sortedData.map((item) => item.totalPoints);
  const chartData = {
    labels,
    datasets: [
      {
        label: messages.labels.score[locale],
        data: hcpiScore,
        fill: false,
        backgroundColor: COLORS.hcpiScore,
        borderColor: COLORS.hcpiScore,
        pointBorderWidth: 10,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointRadius: 3,
      },
    ],
  };
  return chartData;
};

export const chartDataForWeight = (
  data,
  messages,
  locale,
  userPreferredUnit
) => {
  let sortedData = _.sortBy(data, "date");
  let labels = sortedData.map((item) => shortDateForDisplay(item.date));
  let weights =
    userPreferredUnit === "kg"
      ? sortedData.map((item) => item.kg * 1000 + item.g)
      : sortedData.map((item) => item.lbs);
  const chartData = {
    labels,
    datasets: [
      {
        label: `${messages.labels.weight[locale]} (${
          userPreferredUnit === "kg" ? "g" : userPreferredUnit
        })`,
        data: weights,
        backgroundColor: COLORS.weight,
        pointBorderWidth: 10,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointRadius: 3,
      },
    ],
  };
  return chartData;
};

export const chartDataForHeatDiaryProg = (data, messages, locale) => {
  let sortedData = _.sortBy(data, "date");
  let labels = sortedData.map((item) => shortDateForDisplay(item.date));
  let progLevels = sortedData.map((item) => item.progesteroneLevel);
  const chartData = {
    labels,
    datasets: [
      {
        label: messages.labels.progesteroneLevel[locale],
        data: progLevels,
        backgroundColor: COLORS.heatDiaryProg,
        pointBorderWidth: 10,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointRadius: 3,
      },
    ],
  };
  return chartData;
};

export const chartDataForTrainingDiaryAgilityEntries = (
  data,
  messages,
  locale
) => {
  let sortedData = _.sortBy(data, "date");
  let labels = sortedData.map((item) => shortDateForDisplay(item.date));
  let agilityDistancePerSecond = sortedData.map(
    (item) => item.agilityDistancePerSecond
  );
  const chartData = {
    labels,
    datasets: [
      {
        label: messages.labels.agilityDistancePerSecond[locale],
        data: agilityDistancePerSecond,
        fill: false,
        backgroundColor: COLORS.hcpiScore,
        borderColor: COLORS.hcpiScore,
        pointBorderWidth: 10,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointRadius: 3,
      },
    ],
  };
  return chartData;
};

export const chartDataForTemperature = (data, messages, locale) => {
  let sortedData = _.sortBy(data, "date", "time");
  let labels = sortedData.map(
    (item) => `${shortDateForDisplay(item.date)} ${item.time}`
  );
  let temperature = sortedData.map((item) => item.temperature);
  const chartData = {
    labels,
    datasets: [
      {
        label: messages.labels.temperature[locale],
        data: temperature,
        fill: false,
        backgroundColor: COLORS.temperature,
        borderColor: COLORS.temperature,
        pointBorderWidth: 10,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointRadius: 3,
      },
    ],
  };
  return chartData;
};

export const chartDataForActivityCategories = (data, messages, locale) => {
  let cats = [...new Set(data.map((item) => item.category))];
  let categoryTimes = cats.map((c) => {
    return (
      data
        .map((d) => (d.category === c ? d.totalTimeInSeconds : 0))
        .reduce((a, b) => a + b, 0) / 3600
    );
  });
  let backgroundColor = cats.map((c) => COLORS.dailyActivityCats[c]);

  const chartData = {
    labels: cats.map((c) => messages[c][locale]),
    datasets: [
      {
        data: categoryTimes,
        backgroundColor,
      },
    ],
  };

  return chartData;
};

export const chartDataForActivityActivities = (data, messages, locale) => {
  let acts = [...new Set(data.map((item) => item.activity))];
  let activityTimes = acts.map((a) => {
    return (
      data
        .map((d) => (d.activity === a ? d.totalTimeInSeconds : 0))
        .reduce((a, b) => a + b, 0) / 3600
    );
  });
  let backgroundColor = acts.map((a) => {
    return COLORS.dailyActivityActs[a]
      ? COLORS.dailyActivityActs[a]
      : COLORS.dailyActivityCats.goalOriented;
  });

  const chartData = {
    labels: acts.map((a) => messages[a][locale]),
    datasets: [
      {
        data: activityTimes,
        backgroundColor,
      },
    ],
  };
  return chartData;
};

export const activityForHcpiCompare = (data, messages, locale) => {
  let labels = data.map((item) => `${shortDateForDisplay(item.date)}`);
  let totalTimeInHrs = data.map((item) => item.totalTimeInSeconds / 3600);
  const chartData = {
    labels,
    datasets: [
      {
        label: messages.titles.activityForHcpi[locale],
        data: totalTimeInHrs,
        fill: false,
        backgroundColor: COLORS.temperature,
        borderColor: COLORS.temperature,
        pointBorderWidth: 10,
        pointHoverRadius: 10,
        pointHoverBorderWidth: 1,
        pointRadius: 3,
      },
    ],
  };
  return chartData;
};

export const chartDataFunctions = {
  trainingDiary: chartDataForTrainingDiary,
  trainingDiaryAgility: chartDataForTrainingDiaryAgilityEntries,
  hcpiShowAll: chartDataForHCPIShowAll,
  weight: chartDataForWeight,
  heatDiaryProg: chartDataForHeatDiaryProg,
  temperature: chartDataForTemperature,
  activityCategories: chartDataForActivityCategories,
  activityActivities: chartDataForActivityActivities,
  activityForHcpiCompare,
};
