import styled, { css } from "styled-components"

const DivWrapper = styled.div`
  ${css`
    ${props => props.width && `width: ${props.width}px;`}  
  `}
  ${css`
    ${props => props.background && `background: ${props.background};`}
  `}
  ${css`
    ${props => props.padding && `padding: ${props.padding}px;`}
  `}
`

export default DivWrapper
