import React from "react";

function ArchivedDetailsView(props) {
  return (
    <div>
      <p>Archived Details Go Here...</p>
      <ul>
        <li>List of archived details</li>
        <li>view individual entries</li>
        <li>filter by type</li>
        <li>option to delete</li>
      </ul>
    </div>
  );
}

export default ArchivedDetailsView;
