import addPet from "./add_pet.svg";
import profile from "./pet_profile.svg";
import editProfile from "./pet_profile_edit.svg";
import deleteProfile from "./pet_profile_delete.svg";
import profileImage from "./pet_profile_image.svg";
import usefulLinks from "./pet_profile_links.svg";
import transfer from "./pet_transfer.svg";
import heart from "./heart.svg";

const pets = {
  addPet,
  profile,
  editProfile,
  deleteProfile,
  profileImage,
  usefulLinks,
  transfer,
  heart,
};

export default pets;
