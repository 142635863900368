import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import images from "assets";
import BoxWithStatusBackground from "components/styled/Box_With_Status_Background";
import ImageContainer from "components/styled/Image_Container";

// Type: Vaccination

// Type: Parasite

const EntryCardWithStatus = (props) => {
  switch (true) {
    case props.vacEntry:
      return <VacEntryCard {...props} />;
    case props.parasiteEntry:
      return <ParasiteEntryCard {...props} />;
    case props.hoofCareEntry:
      return <HoofCareEntryCard {...props} />;
    default:
    // Do nothing
  }
};

const pStyle = { paddingTop: 8 };

const VacEntryCard = (props) => {
  const LABELS = ["vaccine", "date", "expiry", "status"];
  const date = (date) => moment(date).format("MMMM Do YYYY");
  return (
    <Link to={props.to}>
      <BoxWithStatusBackground padding={10} status={statusCheck(props.expiry)}>
        <div className="level" style={{ margin: 0 }}>
          <div className="level-left">
            <div className="level-item">
              <figure className="image is-32x32">
                <img
                  src={images.features.vaccinations.main}
                  alt={"vaccinations_icon"}
                />
              </figure>
            </div>
          </div>
          {props.litterMember && <LitterMemberIdentifier {...props} />}
        </div>
        {LABELS.map((label, i) => (
          <div key={i} style={pStyle} className="heading">
            <span className="has-text-weight-bold">
              {props.messages.labels[label][props.locale]}:
            </span>
            <p>
              {label === "date"
                ? date(props.date)
                : label === "expiry"
                ? date(props.expiry)
                : label === "status"
                ? props.messages.statuses[statusCheck(props.expiry)][
                    props.locale
                  ]
                : props[label]}
            </p>
          </div>
        ))}
      </BoxWithStatusBackground>
    </Link>
  );
};

const ParasiteEntryCard = (props) => {
  const LABELS = ["parasiteType", "productUsed", "date", "expiry", "status"];
  const date = (date) => moment(date).format("MMMM Do YYYY");
  return (
    <Link to={props.to}>
      <BoxWithStatusBackground padding={10} status={statusCheck(props.expiry)}>
        <div className="level" style={{ margin: 0 }}>
          <div className="level-left">
            <div className="level-item">
              <figure className="image is-32x32">
                <img
                  src={images.features.parasites.main}
                  alt={"parasites_icon"}
                />
              </figure>
            </div>
          </div>
          {props.litterMember && <LitterMemberIdentifier {...props} />}
        </div>
        {LABELS.map((label, i) => (
          <div key={i} style={pStyle} className="heading">
            <span className="has-text-weight-bold">
              {props.messages.labels[label][props.locale]}:
            </span>
            <p>
              {label === "date"
                ? date(props.date)
                : label === "expiry"
                ? date(props.expiry)
                : label === "status"
                ? props.messages.statuses[statusCheck(props.expiry)][
                    props.locale
                  ]
                : label === "parasiteType"
                ? props.messages.types[props.parasiteType][props.locale]
                : props[label]}
            </p>
          </div>
        ))}
      </BoxWithStatusBackground>
    </Link>
  );
};

const HoofCareEntryCard = (props) => {
  const LABELS = ["subject", "date", "nextCareDate", "status"];
  const date = (date) => moment(date).format("MMMM Do YYYY");
  return (
    <Link to={props.to}>
      <BoxWithStatusBackground
        padding={10}
        status={statusCheck(props.nextCareDate)}
      >
        <div className="level" style={{ margin: 0 }}>
          <div className="level-left">
            <div className="level-item">
              <figure className="image is-32x32">
                <img
                  src={images.features.hoofCare.main}
                  alt={"hoof_care_icon"}
                />
              </figure>
            </div>
          </div>
        </div>
        {LABELS.map((label, i) => (
          <div key={i} style={pStyle} className="heading">
            <span className="has-text-weight-bold">
              {props.messages.labels[label][props.locale]}:
            </span>
            <p>
              {label === "date"
                ? date(props.date)
                : label === "nextCareDate"
                ? date(props.nextCareDate)
                : label === "status"
                ? props.messages.statuses[statusCheck(props.nextCareDate)][
                    props.locale
                  ]
                : props[label]}
            </p>
          </div>
        ))}
      </BoxWithStatusBackground>
    </Link>
  );
};

const LitterMemberIdentifier = (props) => (
  <>
    <div className="level-item">
      <p className="label" style={{ marginRight: 4 }}>
        {props.petName}
      </p>
    </div>
    <div className="level-right">
      <div className="level-item">
        <ImageContainer>
          <div
            style={{
              backgroundColor: props.litterMemberColorIdentifier,
              padding: 10,
              borderRadius: 12,
              width: 10,
              height: 10,
              position: "absolute",
              right: 20,
              top: 10,
            }}
          />
        </ImageContainer>
      </div>
    </div>
  </>
);

const statusCheck = (expiry) => {
  switch (true) {
    case moment(expiry).isAfter(moment().add(30, "d")):
      return "valid";
    case moment(expiry).isBefore(moment().add(30, "d")) &&
      moment(expiry).isAfter(moment()):
      return "expiring";
    case moment(expiry).isBefore(moment().add(1, "d")):
      return "expired";
    default:
    //Do nothing
  }
};

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: state.intl.messages.components.cards,
  };
};

export default connect(mapStateToProps)(EntryCardWithStatus);
