import React, { useState } from "react";
import { connect } from "react-redux";
import Message from "components/message";
import { returnNewObjectAsArrayByValidKey } from "functions";

function SubLevelNotice(props) {
  const [showMessage, toggleMessage] = useState(true);

  const billingNotice = returnNewObjectAsArrayByValidKey(
    props.messages,
    "billingNotice",
    props.locale
  );

  function handleHideMessage() {
    toggleMessage(false);
  }

  if (
    props.user.subLevel === "blue" ||
    props.user.petSlots > 0 ||
    props.user.petSlots <= -8
  )
    return null;

  if (!showMessage) return null;

  return (
    <div className="columns is-centered">
      <div className={"column is-5-desktop is-12-mobile is-5-tablet"}>
        <Message
          default
          withHeader
          color={"is-info"}
          header={
            <>
              <p className="has-text-bold">
                <span>
                  <i className="icon fas fa-exclamation-triangle" />
                  {billingNotice[0]}
                </span>
              </p>
              <button
                className="delete"
                aria-label="delete"
                onClick={handleHideMessage}
              />
            </>
          }
        >
          <div>
            <p>
              <SubLevelCheckAndReturnString {...props} />
            </p>
            {billingNotice.splice(1, 1).map((text, i) => (
              <p key={i}>{text}</p>
            ))}
          </div>
        </Message>
      </div>
    </div>
  );
}

function SubLevelCheckAndReturnString(props) {
  switch (true) {
    case props.user.subLevel === "copper":
      return props.messages.fromCopperToSilver[props.locale];
    case props.user.subLevel === "silver":
      return props.messages.fromSilverToGold[props.locale];
    case props.user.subLevel === "gold":
      return props.messages.fromGoldToPlatinum[props.locale];
    case props.user.subLevel === "platinum" && props.user.petSlots === -7:
      return props.messages.fromPlatinumToDiamond[props.locale];
    default:
      return null;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.reducers.auth.user,
    locale: state.intl.locale,
    messages: state.intl.messages.components.messages.infoBlocks,
  };
};

export default connect(mapStateToProps)(SubLevelNotice);
