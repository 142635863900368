import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { pets as actions } from "actions";
import TileWrapper from "./TileWrapper";
import { Link } from "react-router-dom";
import Header from "./Header";
import TileBody from "./TileBody";
import { pets } from "assets";
import BubbleWrap from "components/styled/Bubble_Wrap";
import Intl from "components/Intl";

function PetTile(props) {
  const {
    pet,
    history,
    selectPet,
    providerView,
    dashboard,
    path,
    litterDashboard,
  } = props;

  function handleLinkClick(path) {
    selectPet(pet);
    history.push(path);
  }

  function renderHeaderLabel() {
    if (litterDashboard) return pet.petName;
    if (
      path === "/userhome/dashboard/pet_profile" ||
      path.includes("/providerhome/contacts/contact/contacts_pet_dashboard")
    ) {
      return <Intl message path={"containers.pets.petProfile.main.name"} />;
    } else if (
      path === "/userhome/dashboard/litter/litter_member_dashboard/profile"
    ) {
      return (
        <Intl
          message
          path={"containers.litterMemberFeatures.litterMemberProfile.main.name"}
        />
      );
    }
  }

  let petProfilePath = providerView
    ? "/providerhome/contacts/contact/contacts_pet_dashboard/profile"
    : "/userhome/dashboard/pet_profile";
  let dashboardPath = providerView
    ? "/providerhome/contacts/contact/contacts_pet_dashboard"
    : "/userhome/dashboard";

  if (dashboard) {
    return (
      <TileWrapper>
        <Link to={path}>
          <Header title={renderHeaderLabel()} />
          <TileBody
            litterMemberIdentifier
            img={
              pet.useImageForProfile
                ? pet.profileImageObject.secure_url
                : pets.profile
            }
            imgClassName="is-rounded"
          >
            {props.litterMember && (
              <BubbleWrap>
                <div
                  style={{
                    backgroundColor: props.pet.litterMemberColorIdentifier,
                    padding: 10,
                    borderRadius: 12,
                  }}
                />
              </BubbleWrap>
            )}
          </TileBody>
        </Link>
      </TileWrapper>
    );
  }

  return (
    <TileWrapper>
      <Header
        petTile
        title={pet.petName}
        onClick={() => handleLinkClick(petProfilePath)}
      />
      <TileBody
        img={
          pet.useImageForProfile
            ? pet.profileImageObject.secure_url
            : pets.profile
        }
        onClick={() => handleLinkClick(dashboardPath)}
        imgClassName="is-rounded"
      />
    </TileWrapper>
  );
}

export default withRouter(connect(null, actions)(PetTile));
