const layoutConfig = {
  header: {},
  footer: {
    mobile: {
      left: ["facebook", "mainSite", "contactEmail"],
      right: ["customerReg", "termsCond", "privacyPolicy"],
      style: {
        paddingBottom: 46,
      },
    },
    web: {
      left: ["facebook", "mainSite", "contactEmail"],
      right: ["customerReg", "termsCond", "privacyPolicy"],
      style: {
        paddingBottom: 18,
      },
    },
  },
  hero: {
    className: "hero is-fullheight",
    color: "#c9f2e5",
  },
  heroHead: {
    className: "hero-head",
  },
  heroBody: {
    className: "hero-body",
    style: {
      alignItems: "flex-start",
      padding: 0,
      marginBottom: 37,
    },
  },
  heroFoot: {
    className: "hero-foot",
    style: {
      fontSize: 12,
    },
  },
};

export default layoutConfig;
