import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { auth as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { INPUT_FIELDS } from "forms/fields/user_add_email";
import { FormTitle } from "components/form";

class UserAddEmailForm extends Component {
  renderFields() {
    return INPUT_FIELDS.map((field, i) => {
      return <Field key={i} {...field} />;
    });
  }

  render() {
    const { errors, dirty, isSubmitting, locale } = this.props;
    const { buttons } = this.props.messages;

    return (
      <Form>
        <FormTitle group={"register"} />
        <br />
        {this.renderFields()}
        <div className="buttons is-centered">
          <div onClick={() => this.props.logOutUser()}>
            <Link to="/" className="button">
              <span>
                <i className={buttons.cancel.icon} /> {buttons.cancel[locale]}
              </span>
            </Link>
          </div>
          <button
            disabled={isSubmitting}
            className={`button ${
              !_.isEmpty(errors)
                ? "is-danger"
                : _.isEmpty(errors) && dirty
                ? "is-success"
                : "is-info"
            } ${isSubmitting && "is-loading"}`}
            type="submit"
          >
            {buttons.submit[locale]}
          </button>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      buttons: state.intl.messages.components.buttons.common,
    },
  };
};

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}
Yup.addMethod(Yup.string, "equalTo", equalTo);

const EnhancedUserAddEmailForm = withFormik({
  mapPropsToValues({ locale }) {
    return {
      email: "",
      email2: "",
      locale,
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().required("required"),
    email2: Yup.string()
      .equalTo(Yup.ref("email"), "mustMatch")
      .required("required"),
  }),
  handleSubmit(values, { props, setSubmitting, resetForm }) {
    props.addUserEmail(values);
  },
})(UserAddEmailForm);

export default connect(mapStateToProps, actions)(EnhancedUserAddEmailForm);
