import { features } from "assets"
import Landing from "components/features/Landing"
import NewEntry from "./New_Entry_View"
import ViewEntries from "./View_Entries"
import ViewEntry from "./View_Entry.js"

const hoofCareConfig = {
  name: "hoofCare",
  type: "feature",
  defaultRoute: "/userhome/dashboard/hoof_care",
  menuIcon: { img: features.hoofCare.main, alt: "hoof_care_icon" },
  nav: [
    {
      name: "landing",
      disabled: false,
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      name: "newEntry",
      disabled: false,
      menuItem: true,
      image: {
        img: features.hoofCare.newEntry,
        alt: "hoof_care_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: NewEntry,
      path: "/new_entry",
    },
    {
      name: "viewEntries",
      disabled: false,
      menuItem: true,
      image: {
        img: features.hoofCare.viewEntries,
        alt: "hoof_care_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
    {
      name: "viewEntry",
      disabled: false,
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
  ],
}

export default hoofCareConfig
