export const TOOTH_STATES = {
  removed: { fill: "#333333", priority: 1 },
  missing: { fill: "#999999", priority: 2 },
  broken: { fill: "#ff9f92", priority: 3 },
  loose: { fill: "#ff9f92", priority: 3 },
  wornTooth: { fill: "#ff9f92", priority: 3 },
  enamelErosion: { fill: "#ff9f92", priority: 3 },
  periodontalPocket: { fill: "#ff9f92", priority: 3 },
  biteMisalignment: { fill: "#ff9f92", priority: 3 },
  xrayPerformed: { fill: "#ff9f92", priority: 3 },
  periodontitis: { fill: "#ff9f92", priority: 3 },
  resorptionLesion: { fill: "#ff9f92", priority: 3 },
  cyst: { fill: "#ff9f92", priority: 3 },
  unruptured: { fill: "#ff9f92", priority: 3 },
  gingivitis: { fill: "#ff9f92", priority: 3 },
  dentalCalculus: { fill: "#ff9f92", priority: 3 },
  colorChange: { fill: "#ff9f92", priority: 3 },
};
