import main from "./stable_diary.svg"
import newEntry from "./stable_diary_new_entry.svg"
import viewEntries from "./stable_diary_view_entries.svg"

const stableDiary = {
  main,
  newEntry,
  viewEntries,
}

export default stableDiary
