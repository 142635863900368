import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getSignature,
  getCloudinaryConfig,
  handleUploadResult,
} from "actions/cloudinary";
import { addProfileImageToPet } from "actions/pets";
import ReviewImage from "./Review_Image";
import { CLOUDINARY_RESET } from "actions/types";

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        getSignature,
        getCloudinaryConfig,
        handleUploadResult,
        addProfileImageToPet,
      },
      dispatch
    ),
    dispatch,
  };
};

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      buttons: state.intl.messages.components.buttons.petProfile,
    },
    pet: state.reducers.pets.selectedPet,
    id: state.reducers.pets.selectedPet._id,
    cloudinaryApi: state.reducers.cloudinary.cloudinaryApi,
    uploadResult: state.reducers.cloudinary.uploadResult,
  };
};

class Cloudinary extends Component {
  state = {
    cloudinaryResponse: {},
    cloudinaryError: null,
  };

  componentDidMount() {
    if (_.isEmpty(this.props.cloudinaryApi)) {
      this.props.getCloudinaryConfig();
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: CLOUDINARY_RESET });
  }

  uploadWidget = () => {
    const { id, /* messages,*/ handleUploadResult } = this.props;
    const { api_key, cloud_name, upload_preset } = this.props.cloudinaryApi;
    window.cloudinary.openUploadWidget(
      {
        api_key,
        cloud_name,
        public_id: id,
        cropping_aspect_ratio: "1",
        sources: ["local", "camera"],
        cropping: "server",
        upload_preset,
        client_allowed_formats: ["png", "gif", "jpeg"],
        upload_signature: this.props.getSignature,
      },
      function (error, result) {
        if (result.event === "success") {
          handleUploadResult(result.info);
        }
      }
    );
  };

  handleAddProfileImageToPet = () => {
    const { id, uploadResult, addProfileImageToPet } = this.props;
    addProfileImageToPet(id, uploadResult);
  };

  handleDeleteProfileImage = () => {
    console.log("Handle delete profile image clicked");
  };

  render() {
    const { uploadResult, pet, messages, locale } = this.props;
    return (
      <div className="columns is-centered">
        <div className="column is-4">
          <div className="buttons is-centered">
            <button
              className="button is-fullwidth is-info"
              onClick={() => this.uploadWidget()}
            >
              {_.isEmpty(uploadResult)
                ? messages.buttons.uploadNew[locale]
                : messages.buttons.uploadAgain[locale]}
            </button>
          </div>
          {!_.isEmpty(uploadResult) && (
            <>
              <ReviewImage
                img={uploadResult.secure_url}
                pet={pet}
                handleAddProfileImageToPet={this.handleAddProfileImageToPet}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cloudinary);
