import axios from "axios";
import { ACTION_STARTED, ACTION_SUCCESS, MODALS_HIDE } from "actions/types";
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "actions/functions";

export const TOOTH_MARKER_FETCH_MARKERS = "tooth_marker_fetch_markers";
export const TOOTH_MARKER_ADD_MARKER_DETAIL = "tooth_marker_add_marker_detail";
export const TOOTH_MARKER_ADD_MARKER_DETAIL_MANY =
  "tooth_marker_add_marker_detail_many";
export const TOOTH_MARKER_DELETE_MARKER_DETAIL =
  "tooth_marker_delete_marker_detail";
export const TOOTH_MARKER_UPDATE_MARKER_DETAIL =
  "tooth_marker_update_marker_detail";

export const actionTypes = [
  TOOTH_MARKER_FETCH_MARKERS,
  TOOTH_MARKER_ADD_MARKER_DETAIL,
  TOOTH_MARKER_DELETE_MARKER_DETAIL,
  TOOTH_MARKER_UPDATE_MARKER_DETAIL,
];

const API = (route) => `/api/tooth_marker/${route}`;

export const fetchMarkers = (_pet, providerView) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: TOOTH_MARKER_FETCH_MARKERS });
    const res = await axios.get(
      API(providerView ? "fetch_markers_for_provider" : "fetch_markers"),
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
        params: { _pet },
      }
    );
    dispatch({
      type: TOOTH_MARKER_FETCH_MARKERS,
      payload: {
        toothMarkers: res.data.toothMarkers,
        _pet,
      },
    });
    endAndResetAction(dispatch);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const addMarkerDetail =
  (values, providerContact, providerView) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: TOOTH_MARKER_ADD_MARKER_DETAIL,
      });
      const res = await axios.post(
        API(
          providerView ? "add_marker_detail_by_provider" : "add_marker_detail"
        ),
        {
          _user: providerView ? providerContact._id : undefined,
          ...values,
        },
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      if (res.data.insertMany) {
        dispatch({
          type: TOOTH_MARKER_ADD_MARKER_DETAIL_MANY,
          payload: res.data.toothMarkers,
        });
      } else {
        dispatch({
          type: TOOTH_MARKER_ADD_MARKER_DETAIL,
          payload: res.data.toothMarker,
        });
      }
      endAndResetAction(dispatch);
      dispatch({ type: MODALS_HIDE });
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const deleteToothMarkerDetail =
  (_id, providerView) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: TOOTH_MARKER_DELETE_MARKER_DETAIL,
      });
      const res = await axios.put(
        API(
          providerView
            ? "delete_marker_detail_by_provider"
            : "delete_marker_detail"
        ),
        { _id },
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      dispatch({
        type: TOOTH_MARKER_DELETE_MARKER_DETAIL,
        payload: res.data.toothMarkerId,
      });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "deleteSuccess",
      });
      endAndResetActionWithDelay(dispatch, 1500);
      dispatch({ type: MODALS_HIDE });
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const updateMarkerDetail =
  (values, providerView) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: TOOTH_MARKER_UPDATE_MARKER_DETAIL,
      });
      const res = await axios.put(
        API(
          providerView
            ? "update_marker_detail_by_provider"
            : "update_marker_detail"
        ),
        values,
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      dispatch({
        type: TOOTH_MARKER_UPDATE_MARKER_DETAIL,
        payload: res.data.toothMarker,
      });
      dispatch({ type: MODALS_HIDE });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "updateSuccess",
      });
      endAndResetActionWithDelay(dispatch, 1500);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

const toothMarker = {
  fetchMarkers,
  addMarkerDetail,
  deleteToothMarkerDetail,
  updateMarkerDetail,
};

export default toothMarker;
