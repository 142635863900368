import React, { Component } from "react"
import Section from "components/layout/Section"
import NewEntry from "./New_Entry"
import Message from "components/message"

class NewEntryView extends Component {
  render() {
    // check to see if litter member has new owner
    // if they do, render message to alert that
    if (this.props.selectedLitterMember.hasNewOwner)
      return (
        <Section>
          <Message warning message="memberHasNewOwner" />
        </Section>
      )

    return (
      <Section>
        <NewEntry {...this.props} />
      </Section>
    )
  }
}

export default NewEntryView
