import axios from "axios";
import { updateIntl } from "react-intl-redux";
import {
  ACTION_STARTED,
  ACTION_ENDED,
  ACTION_RESET,
  CLIENT_SETTINGS_FETCH,
  CLIENT_VERSION_CHECK,
} from "actions/types";
import { catchBlockError } from "./functions";
import messages from "messages";
import { updateLocale } from "functions";

export const fetchClientSettings = () => async (dispatch) => {
  try {
    const usersLanguagePreference = localStorage.getItem("languagePreference");
    dispatch({ type: ACTION_STARTED, payload: CLIENT_SETTINGS_FETCH });
    const res = await axios.get("/api/client_settings/fetch_settings");
    const locale = usersLanguagePreference || res.data.clientSettings.locale;
    dispatch(updateIntl({ locale, messages }));
    updateLocale(locale);
    dispatch({ type: CLIENT_SETTINGS_FETCH, payload: res.data.clientSettings });
    dispatch({ type: ACTION_ENDED });
    return dispatch({ type: ACTION_RESET });
    
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const clientVersionCheck = () => async (dispatch) => {
  try {
    const currentVersion = localStorage.getItem("currentVersion");

    dispatch({ type: ACTION_STARTED, payload: CLIENT_VERSION_CHECK });
    const res = await axios.get("/api/client_settings/client_version_check", {
      params: { currentVersion: currentVersion },
    });

    if (res.data.clientUpdate) {
      localStorage.setItem("currentVersion", res.data.clientVersion);
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
      window.location.reload();
    }
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_RESET });
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

const clientSettingsActions = {
  fetchClientSettings,
  clientVersionCheck,
};

export default clientSettingsActions;
