import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import * as actions from "./actions";
import Menu from "components/menu";
import config from "./config";
import "./posts.css";

function Blog(props) {
  const { blogUrl, fetchPostsByTag, history, blog } = props;

  function handleFetchPostsByTag(tag) {
    if (!blog.tagsFetchedBy.includes(tag)) {
      return fetchPostsByTag(blogUrl, tag, history);
    }

    history.push(`${config.main.path}/fetched_posts_by_tag/${tag}`);
  }

  return (
    <div className="blog_feature">
      <Menu
        defaultRoute={config.main.path}
        name={config.main.name}
        menuIcon={{ img: config.main.img.src, ...config.main.img }}
        nav={config.nav}
        actionTypes={config.main.actionTypes}
        handleFetchPostsByTag={handleFetchPostsByTag}
        {...props}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const blog =
    (state.reducers.blog.entries.length === 0 &&
      state.reducers.blog.tags.length === 0) ||
    moment(state.reducers.blog.lastFetch).diff(moment(), "hours") > 3
      ? false
      : state.reducers.blog;
  return {
    locale: state.intl.locale,
    messages: state.intl.messages.containers.main.blog,
    blogUrl: state.reducers.appData.blog.url,
    priorityTags: state.reducers.appData.blog.priorityTags,
    blog,
  };
};

export default connect(mapStateToProps, actions)(Blog);
