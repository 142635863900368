import rosette from "./rosette.svg"
import trophy from "./trophy.svg"
import medal from "./medal.svg"

const awardTypes = {
  rosette,
  trophy,
  medal,
}

export default awardTypes
