import React, { useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { litters as actions } from "actions";
import config from "./config";
import SmartTile from "components/SmartTile";

function LitterMembers(props) {
  const { fetchLitterMembers, litters, selectLitterMember } = props;
  const { selectedLitter, litterMembersFetched, litterMembers } = litters;

  useEffect(() => {
    function handleFetchLitterMembers() {
      if (!litterMembersFetched.includes(selectedLitter._id)) {
        return fetchLitterMembers(selectedLitter._id);
      }
      return;
    }
    handleFetchLitterMembers();
  });

  function handleLitterMemberSelection(litterMember) {
    selectLitterMember(litterMember);
  }

  if (_.isEmpty(selectedLitter)) return null;
  if (!litterMembers[selectedLitter._id]) return null;

  return (
    <>
      {litterMembers[selectedLitter._id].map((litterMember, i) => (
        <div
          key={i}
          className={config.layout.column}
          onClick={() => handleLitterMemberSelection(litterMember)}
        >
          <SmartTile
            petTile
            dashboard
            litterDashboard
            litterMember
            path={"/userhome/dashboard/litter/litter_member_dashboard"}
            pet={litterMember}
          />
        </div>
      ))}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    litters: state.reducers.litters,
  };
};

export default connect(mapStateToProps, actions)(LitterMembers);
