import { features } from "assets";
import Landing from "components/features/Landing";
import BodyMarkerView from "./views/Body_Marker_View";
import ArchivedDetailsView from "./views/Archived_Details_View";

const bodyMarkerRoutes = [
  {
    name: "landing",
    pageInfo: [],
    disabled: false,
    menuItem: true,
    image: {
      img: features.infoMenuIcon,
      alt: "info_menu_icon",
    },
    icon: "fas fa-home",
    hr: true,
    exact: true,
    component: Landing,
    path: "/",
  },
  {
    name: "bodyMarkers",
    disabled: false,
    menuItem: true,
    image: {
      img: features.bodyMarker.markers,
      alt: "body_marker_icon",
    },
    icon: "far fa-plus-square",
    component: BodyMarkerView,
    path: "/markers",
  },
  {
    name: "archivedMarkers",
    disabled: true,
    menuItem: true,
    image: {
      img: features.bodyMarker.archive,
      alt: "body_marker_archive_icon",
    },
    icon: "far fa-plus-square",
    component: ArchivedDetailsView,
    path: "/archived",
  },
];

export default bodyMarkerRoutes;
