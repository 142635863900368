import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class HomeCare extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    petType: state.reducers.pets.selectedPet.petType,
    messages: {
      ...state.intl.messages.containers.features.homeCare,
      ...state.intl.messages.containers.litterMemberFeatures
        .litterMemberHomeCare,
      landing: {
        ...state.intl.messages.containers.litterMemberFeatures
          .litterMemberHomeCare.landing,
        title: state.intl.messages.containers.features.homeCare.landing.title,
        subtitle:
          state.intl.messages.containers.features.homeCare.landing.subtitle,
        menuLink:
          state.intl.messages.containers.features.homeCare.landing.menuLink,
      },
      categories:
        state.intl.messages.components.forms.selectOptions.homeCareCategories,
      activities:
        state.intl.messages.components.forms.selectOptions.homeCareActivities,
    },
    homeCare: state.reducers.homeCare,
  };
};

export default connect(mapStateToProps)(HomeCare);
