import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "containers/features/Appointments/actions";
import { setCurrentContact } from "containers/contacts/actions";
import { diffInSecondsReturnNumber } from "functions";
import Message from "components/message";
import SectionTitle from "../components/SectionTitle";
import Appointment from "containers/features/Appointments/components/Appointment";
import Pagination from "../components/Pagination";

function ProviderAppointments(props) {
  const {
    providerAppointments: { appointments, lastFetch },
    fetchAllAppointmentsForProvider,
    messages,
    locale,
    contacts,
  } = props;

  useEffect(() => {
    function fetchData() {
      if (
        appointments.length === 0 ||
        diffInSecondsReturnNumber(lastFetch) > 30
      ) {
        fetchAllAppointmentsForProvider();
      }

      if (appointments.length > 0) {
        let filteredForConfirmed = appointments.filter(
          (e) =>
            e.confirmationState && e.confirmationState.status === "confirmed"
        );
        setConfirmed(filteredForConfirmed);

        let filteredForUnconfirmed = appointments.filter(
          (e) =>
            e.confirmationState && e.confirmationState.status === "unconfirmed"
        );
        setUnconfirmed(filteredForUnconfirmed);

        let filteredForCanceled = appointments.filter(
          (e) =>
            e.confirmationState && e.confirmationState.status === "canceled"
        );
        setCanceled(filteredForCanceled);

        let filteredForDeclined = appointments.filter(
          (e) =>
            e.confirmationState && e.confirmationState.status === "declined"
        );
        setDeclined(filteredForDeclined);
      }
    }
    fetchData();

    // Set Confirmed, Unconfirmed, Canceled and Declined
  }, [appointments, lastFetch, fetchAllAppointmentsForProvider]);

  const [confirmed, setConfirmed] = useState([]);
  const [unconfirmed, setUnconfirmed] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const [declined, setDeclined] = useState([]);

  if (!appointments)
    return <Message warning message="noEntriesToDisplayAutoFetch" />;

  if (appointments.length === 0)
    return <Message warning message="noEntriesToDisplayAutoFetch" />;

  return (
    <div>
      {confirmed.length > 0 && (
        <SectionTitle
          title={`${messages.titles.confirmed[locale]} - ${confirmed.length}`}
          logo={{
            path: "features",
            featureName: "appointments",
            name: "confirmed",
          }}
          expand
        >
          <Pagination items={confirmed}>
            {confirmed.map((c, i) => {
              return (
                <Appointment
                  key={c._id}
                  index={i}
                  {...c}
                  {...props}
                  providerAppointments
                  contact={contacts.find(
                    (contact) => contact._user._id === c._user._id
                  )}
                />
              );
            })}
          </Pagination>
        </SectionTitle>
      )}
      {unconfirmed.length > 0 && (
        <SectionTitle
          title={`${messages.titles.unconfirmed[locale]} - ${unconfirmed.length}`}
          logo={{
            path: "features",
            featureName: "appointments",
            name: "unconfirmed",
          }}
          expand
        >
          <Pagination items={unconfirmed}>
            {unconfirmed.map((u, i) => {
              return (
                <Appointment
                  key={u._id}
                  index={i}
                  {...u}
                  {...props}
                  providerAppointments
                  contact={contacts.find(
                    (contact) => contact._user._id === u._user._id
                  )}
                />
              );
            })}
          </Pagination>
        </SectionTitle>
      )}
      {canceled.length > 0 && (
        <SectionTitle
          title={`${messages.titles.canceled[locale]} - ${canceled.length}`}
          logo={{
            path: "features",
            featureName: "appointments",
            name: "canceled",
          }}
          expand
        >
          <Pagination items={canceled}>
            {canceled.map((can, i) => {
              return (
                <Appointment
                  key={can._id}
                  index={i}
                  {...can}
                  {...props}
                  providerAppointments
                  contact={contacts.find(
                    (contact) => contact._user._id === can._user._id
                  )}
                />
              );
            })}
          </Pagination>
        </SectionTitle>
      )}
      {declined.length > 0 && (
        <SectionTitle
          title={`${messages.titles.declined[locale]} - ${declined.length}`}
          logo={{
            path: "features",
            featureName: "appointments",
            name: "declined",
          }}
          expand
        >
          <Pagination items={declined}>
            {declined.map((d, i) => {
              return (
                <Appointment
                  key={d._id}
                  index={i}
                  {...d}
                  {...props}
                  providerAppointments
                  contact={contacts.find(
                    (contact) => contact._user._id === d._user._id
                  )}
                />
              );
            })}
          </Pagination>
        </SectionTitle>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ ...actions, setCurrentContact }, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    contacts: state.reducers.contacts.contacts,
    providerAppointment: true,
    providerView: true,
    provider: state.reducers.auth.provider,
    messages: {
      ...state.intl.messages.containers.features.appointments,
      labels: {
        ...state.intl.messages.components.appointment.labels,
        ...state.intl.messages.components.forms.labels.common,
        ...state.intl.messages.components.forms.labels.appointments,
      },
      statuses: state.intl.messages.components.appointment.statuses,
    },
    locale: state.intl.locale,
    providerAppointments: state.reducers.providerAppointments,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProviderAppointments);
