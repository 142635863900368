import { features } from "assets"
import Landing from "components/features/Landing"
import NewEntryView from "./views/New_Entry_View"
import ViewEntriesView from "./views/View_Entries_View"

const tasksRoutes = [
  {
    name: "landing",
    disabled: false,
    menuItem: true,
    image: {
      img: features.infoMenuIcon,
      alt: "info_menu_icon",
    },
    hr: true,
    exact: true,
    component: Landing,
    path: "/",
  },
  {
    name: "newEntryView",
    disabled: false,
    menuItem: true,
    image: {
      img: features.tasks.newEntry,
      alt: "new_entry_icon",
    },
    component: NewEntryView,
    path: "/new_entry",
  },
  {
    name: "viewEntriesView",
    disabled: false,
    menuItem: true,
    image: {
      img: features.tasks.viewEntries,
      alt: "view_entries_icon",
    },
    component: ViewEntriesView,
    path: "/view_entries",
  },
]

export default tasksRoutes
