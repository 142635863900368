import React from "react";
import { connect } from "react-redux";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";
import BodyText from "./BodyText";

function ConfirmEmail({
  handleModalClose,
  handleConfirmAndRegister,
  usersEmail,
  messages,
  locale,
}) {
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title is-size-6-mobile">
            {messages.title[locale]}
          </p>
        </header>
        <section className="modal-card-body has-text-dark">
          <BodyText
            noDefault={true}
            ownBodyText={true}
            messages={messages.bodyText}
            type={"confirmEmail"}
            locale={locale}
          />
          <p
            className="box has-text-weight-bold is-lowercase"
            style={{ padding: 12 }}
          >
            {usersEmail}
          </p>
          <br />
        </section>
        <footer className="modal-card-foot">
          <Buttons>
            <Button name="back" group="common" onClick={handleModalClose} />
            <Button
              name="confirmEmailAndRegister"
              group="auth"
              onClick={handleConfirmAndRegister}
              className="is-success"
            />
          </Buttons>
        </footer>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    messages: {
      title: state.intl.messages.components.modals.titles.confirmEmail,
      bodyText: state.intl.messages.components.modals.bodyText,
    },
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(ConfirmEmail);
