import React from "react";
import { connect } from "react-redux";
import {
  TimelineWrapper,
  TimelineHeader,
  TimelineItem,
  TimelineMarker,
  TimelineContent,
} from "components/timeline";
import ExpenseItem from "./Expense_Item";
import { returnDateLocaleFormat } from "functions";
import Label from "components/styled/Label";

function ExpenseList(props) {
  const { messages, locale, expenses, itemsToDisplay } = props;

  if (!itemsToDisplay) return null;

  return (
    <TimelineWrapper>
      <TimelineHeader>
        <span className="tag is-medium is-primary">
          {messages.timeline.labels.start[locale]}
        </span>
      </TimelineHeader>
      {expenses.map((exp) => (
        <TimelineItem key={exp._id}>
          <TimelineMarker className="is-icon is-link">
            <i className="fas fa-coins"></i>
          </TimelineMarker>
          <TimelineContent>
            <p className="heading">{returnDateLocaleFormat(exp.date)}</p>
            <ExpenseItem expense={exp}>
              <p>{exp.desc}</p>
              <p>{`${exp.total.toFixed(2)}`}</p>
              {!!exp.receiptLink && (
                <>
                  <label className="label is-small has-text-grey">
                    <Label>{messages.labels.receiptLink[locale]}:</Label>
                  </label>
                  <a
                    style={{
                      textDecoration: "none",
                    }}
                    rel="noopener noreferrer"
                    href={exp.receiptLink}
                    target="_blank"
                  >
                    {exp.receiptLink.substring(0, 18) + "..."}
                  </a>
                </>
              )}
            </ExpenseItem>
          </TimelineContent>
        </TimelineItem>
      ))}
    </TimelineWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      timeline: state.intl.messages.components.timeline,
      labels: state.intl.messages.components.forms.labels.litterExpenses,
    },
  };
};

export default connect(mapStateToProps)(ExpenseList);
