import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Bounds from "components/bounds";
import Section from "components/layout/Section";
import SubLevelNotice from "./Sub_Level_Notice";
import AddNewPet from "./Add_New_Pet";
import RetrieveNewPet from "./Retrieve_New_Pet";
import { TRANSFERS_CANCEL_UPDATE_PET_AND_TRANSFER } from "actions/types";
import AddNewPetCard from "components/styled/Add_New_Pet_Card";
import RetrieveNewPetCard from "components/styled/Retrieve_New_Pet_Card";
import FormWrapper from "components/layout/Form_Wrapper";
import Message from "components/message";
import AddPetInfoText from "./AddPetInfoText";

class AddPet extends Component {
  state = {
    addNewPet: false,
    retrieveNewPet: false,
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.setState({
      addNewPet: false,
      retrieveNewPet: false,
    });
    this.props.dispatch({ type: TRANSFERS_CANCEL_UPDATE_PET_AND_TRANSFER });
  };

  handleShowAddNewPetForm = () => {
    this.setState({
      addNewPet: true,
      retrieveNewPet: false,
    });
  };

  handleShowRetrieveNewPetForm = () => {
    this.setState({
      retrieveNewPet: true,
      addNewPet: false,
    });
  };

  render() {
    return (
      <Section>
        <SubLevelNotice />
        <AddPetInfoText />
        {this.state.addNewPet && (
          <FormWrapper>
            <Message info message={"addPetForm"} />
            <AddNewPet {...this.props} cancel={this.handleCancel} />
          </FormWrapper>
        )}
        {this.state.retrieveNewPet && (
          <FormWrapper>
            <RetrieveNewPet {...this.props} cancel={this.handleCancel} />
          </FormWrapper>
        )}
        {this.state.addNewPet || this.state.retrieveNewPet ? null : (
          <AddAndRetrieveButtons
            handleShowAddNewPetForm={this.handleShowAddNewPetForm}
            handleShowRetrieveNewPetForm={this.handleShowRetrieveNewPetForm}
          />
        )}
      </Section>
    );
  }
}

const AddAndRetrieveButtons = (props) => {
  return (
    <div className="columns is-2 is-variable is-mobile is-multiline is-centered">
      <div className="column is-2-desktop is-4-mobile is-4-tablet">
        <Bounds
          maxWidth="800"
          render={() => (
            <AddNewPetCard isMobile onClick={props.handleShowAddNewPetForm} />
          )}
          else={() => <AddNewPetCard onClick={props.handleShowAddNewPetForm} />}
        />
      </div>
      <div className="column is-2-desktop is-4-mobile is-4-tablet">
        <Bounds
          maxWidth="800"
          render={() => (
            <RetrieveNewPetCard
              isMobile
              onClick={props.handleShowRetrieveNewPetForm}
            />
          )}
          else={() => (
            <RetrieveNewPetCard onClick={props.handleShowRetrieveNewPetForm} />
          )}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.containers.pets.addPet,
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(withRouter(AddPet));
