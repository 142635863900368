import React, { Component } from "react"
import { connect } from "react-redux"
import { reporting as actions } from "actions"
import Loading from "components/spinners/Loading"
import ReportTypeSelection from "./Report_Type_Selection"
import ReportRequestForm from "forms/Report_Request_Form"
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"

class NewReport extends Component {
  state = { stage: 1, report: {} }

  componentDidMount() {
    if (!this.props.reporting.templatesFetched)
      return this.props.fetchTemplates()
  }

  handleReportSelection = (reportType, _id) => {
    this.setState({
      stage: 2,
      report: { reportType, _id }
    })
  }

  handleBackToReportSelection = () => {
    this.setState({
      stage: 1,
      report: {}
    })
  }

  renderContent = () => {
    const { actionCalls, reporting, pet } = this.props
    const { stage, report } = this.state
    switch (true) {
      case actionCalls.actionInProcess:
        return <Loading />
      case reporting.templates.length === 0:
        return null
      case stage === 1:
        return (
          <ReportTypeSelection
            {...this.props}
            petType={pet.petType}
            petSex={pet.petSex}
            templates={reporting.templates}
            handleReportSelection={this.handleReportSelection}
          />
        )
      case stage === 2:
        return (
          <div className="columns is-mobile is-centered">
            <div className="column is-5-desktop is-full-mobile is-6-tablet">
              <BackEditDeleteButtons
                backButtonFunc
                handleBackClick={this.handleBackToReportSelection}
                disableEditButton
                disableDeleteButton
              />
              <ReportRequestForm report={report} {...this.props} />
            </div>
          </div>
        )
      default:
      // do nothing
    }
  }

  render() {
    return <>{this.renderContent()}</>
  }
}

export default RequireSubFeature(connect(null, actions)(NewReport))
