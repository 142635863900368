import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import _ from "lodash"
import Section from "components/layout/Section"
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons"
import HealthEntryForm from "forms/Health_Entry_Form"
import MedicineEntryForm from "forms/Medicine_Entry_Form"
import {
  MODALS_SHOW,
  MODALS_CONFIRM_DELETE,
  HEALTH_DIARY_DELETE,
} from "actions/types"

const INITIAL_STATE = {
  viewMode: true,
  valuesToEdit: {},
}

class ViewEntry extends Component {
  state = INITIAL_STATE

  UNSAFE_componentWillMount() {
    const { healthDiary, history, defaultRoute } = this.props
    const { id } = this.props.match.params
    const hde = healthDiary.filter((hde) => hde._id === id)[0]
    if (!hde) return history.push(`${defaultRoute}/view_entries`)
    this.setState({
      valuesToEdit: hde,
    })
  }

  handleEditClick = () => {
    this.setState({
      viewMode: !this.state.viewMode,
    })
  }

  handleDeleteClick = () => {
    const successRoute = `${this.props.defaultRoute}/view_entries`
    this.props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "healthDiary",
        actionType: HEALTH_DIARY_DELETE,
        history: this.props.history,
        successRoute: successRoute,
        item: this.state.valuesToEdit._id,
        entryType: this.state.valuesToEdit.type,
      },
    })
  }

  renderSectionContent() {
    if (_.isEmpty(this.state.valuesToEdit)) return <div />
    return (
      <>
        <BackEditDeleteButtons
          disableEditButton={this.props.selectedLitterMember.hasNewOwner}
          disableDeleteButton={this.props.selectedLitterMember.hasNewOwner}
          handleEditClick={this.handleEditClick}
          handleDeleteClick={this.handleDeleteClick}
          viewMode={this.state.viewMode}
        />
        <div className="columns is-mobile is-centered">
          <div className="column is-5-desktop is-full-mobile is-6-tablet">
            {this.state.valuesToEdit.type === "health" ? (
              <HealthEntryForm
                editMode
                {...this.state}
                {...this.props}
                cancel={this.handleEditClick}
              />
            ) : (
              <MedicineEntryForm
                editMode
                {...this.state}
                {...this.props}
                cancel={this.handleEditClick}
              />
            )}
          </div>
        </div>
      </>
    )
  }

  render() {
    return <Section>{this.renderSectionContent()}</Section>
  }
}

const mapStateToProps = (state) => {
  return {
    healthDiary: state.reducers.healthDiary.entries,
  }
}

export default withRouter(connect(mapStateToProps)(ViewEntry))
