import React from "react"
import { connect } from "react-redux"
import styled, { ThemeProvider } from "styled-components"

const StyledHr = styled.hr`
  background-color: ${props => props.theme.settings.hr};
  height: 1px;
  margin-top: 4px;
  margin-bottom: 8px;
  border: 1px solid black;
`

const Hr = props => {
  return (
    <ThemeProvider theme={props.theme}>
      <StyledHr />
    </ThemeProvider>
  )
}

const mapStateToProps = state => {
  return {
    theme: state.reducers.appData.theme
  }
}

export default connect(mapStateToProps)(Hr)
