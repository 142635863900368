import React from "react"
import Pagination from "components/pagination"
import Post from "./Post"
import FetchedPostsTags from "./FetchedPostsTags"

function PaginatedPosts(props) {
  return (
    <>
      <FetchedPostsTags {...props} />
      <Pagination
        pagination={props.blog.pagination}
        paginationNavigate={props.paginationNavigate}
      />
      {props.blog.pagination.data.map((e) => (
        <Post key={e.id} post={e} excerpt {...props} />
      ))}
    </>
  )
}

export default PaginatedPosts
