import { features } from "assets"
import routes from "containers/features/Home_Care/routes"

const litterMemberHomeCareConfig = {
  name: "litterMemberHomeCare",
  type: "litterMemberFeature",
  defaultRoute: "/userhome/dashboard/litter/litter_member_dashboard/home_care",
  menuIcon: {
    img: features.homeCare.main,
    alt: "home_care_feature_icon",
  },
  nav: routes,
  tableView: {
    headers: ["date", "category", "activity"],
    mobileHeaders: ["date", "category", "activity"],
  },
  chartView: {},
}

export default litterMemberHomeCareConfig
