import { InputFieldV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "queryFromDate",
    group: "reporting",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "queryToDate",
    group: "reporting",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
];

const FETCH_BUTTONS = [
  { name: "last7Days", fetchValue: 7 },
  { name: "last14Days", fetchValue: 14 },
  { name: "last30Days", fetchValue: 30 },
  { name: "last3Months", fetchValue: 90 },
  { name: "last6Months", fetchValue: 180 },
  { name: "last1Year", fetchValue: 365 },
];

export { INPUT_FIELDS, FETCH_BUTTONS };
