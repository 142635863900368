import { features } from "assets"
import { path } from "constants/feature_routes_provider_view"
import NewEntry from "./New_Entry_View"
import ViewEntries from "./View_Entries"
import ViewEntry from "./View_Entry"
import BodyConditionScore from "./Body_Condition_Score"
import CalorieCalculator from "./Calorie_Calculator"
import LandingRedirect from "components/features/Landing_Redirect"

const weightProviderConfig = {
  name: "weight",
  chartFunc: "weight",
  type: "feature",
  redirect: path("weight/view_entries"),
  defaultRoute: path("weight"),
  menuIcon: { img: features.weight.main, alt: "weight_icon" },
  providerView: true,
  providerEdit: true,
  nav: [
    {
      name: "landingRedirect",
      disabled: false,
      menuItem: false,
      exact: true,
      component: LandingRedirect,
      path: "/",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.weight.viewEntries,
        alt: "weight_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
    {
      disabled: false,
      name: "newEntry",
      menuItem: true,
      exact: true,
      image: {
        img: features.weight.newEntry,
        alt: "weight_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: NewEntry,
      path: "/new_entry",
    },
    {
      disabled: false,
      name: "bodyConditionScore",
      petTypeReq: true,
      petTypes: ["cat", "dog"],
      menuItem: true,
      image: {
        img: features.weight.bodyConditionScore,
        alt: "body_condition_score_icon",
      },
      icon: "fas fa-copy",
      component: BodyConditionScore,
      path: "/body_condition_score",
    },
    {
      disabled: false,
      name: "calorieCalculator",
      petTypeReq: true,
      petTypes: ["dog", "cat"],
      menuItem: true,
      image: {
        img: features.weight.calorieCalc,
        alt: "calorie_calculator_icon",
      },
      icon: "fas fa-copy",
      component: CalorieCalculator,
      path: "/calorie_calculator",
    },
    {
      disabled: false,
      name: "viewEntry",
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
  ],
  tableView: {
    headers: ["date", "kg", "g", "lbs"],
    mobileHeaders: ["date", "kg", "g", "lbs"],
  },
}

export default weightProviderConfig
