import React from "react"
import { Link } from "react-router-dom"
import { renderMeOnClient } from "functions"
import { isMobileDevice } from "functions"

import GLOBAL_TILES from "constants/global_tiles"

function GlobalTiles(props) {
  if (GLOBAL_TILES.length === 0) return null
  return GLOBAL_TILES.map((tile) => {
    if (!renderMeOnClient(tile.clients)) return null
    return (
      <div key={tile.tileName} className={props.column}>
        <GlobalTile {...tile} />
      </div>
    )
  })
}

const GlobalTile = (props) => {
  return (
    <div className="card" style={{ borderRadius: 4 }}>
      <header className="card-header">
        <p className={"card-header-title is-size-7 is-centered"}>
          {props.tileName}
        </p>
      </header>
      <div
        className="card-image"
        style={{
          padding: isMobileDevice() ? 20 : 30,
          backgroundColor: props.tileColor,
        }}
      >
        <figure className="image is-1by1">
          <img
            src={props.tileImage}
            alt={`global_tile_image_${props.tileName.toLowerCase()}`}
          />
        </figure>
      </div>
      <footer style={{ maxHeight: 40 }} className="card-footer">
        {props.externalLinks &&
          props.externalLinks.map((externalLink, i) => (
            <a
              key={i}
              href={externalLink.url}
              rel="noopener noreferrer"
              target="_blank"
              className="card-footer-item"
            >
              <i
                className={`${externalLink.icon} ${
                  !isMobileDevice() && "fa-lg"
                }`}
              ></i>
            </a>
          ))}
        {props.clientRoute && (
          <div className="card-footer-item">
            <Link to={`/userhome/global_tiles${props.clientRoute}`}>
              <i
                className={`${props.clientRouteIcon} ${
                  !isMobileDevice() && "fa-lg"
                }`}
              ></i>
            </Link>
          </div>
        )}
      </footer>
    </div>
  )
}

export default GlobalTiles
