import React from "react"
import styled, { css } from "styled-components"
import { AWARD_TYPE_SETTINGS } from "../constants"

const StyledSvg = styled.svg`
  ${(props) =>
    AWARD_TYPE_SETTINGS[props.type].sections.map(
      (section) => css`
        #${section.id}, #${`${section.id}_shadow`} {
          display: ${props.awardData.bling.hasOwnProperty(section.name) &&
          !props.awardData.bling[section.name]
            ? "none"
            : "block"};
        }
        #${section.id}, #${section.id} * {
          fill: ${props.awardData.colors[section.name]} !important;
        }
      `
    )};
`

export default function RosetteRsvg(props) {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 500 500"
      {...props}
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="851.04"
          y1="-701.51"
          x2="861.29"
          y2="-653.3"
          gradientTransform="translate(4.79 1319.65) rotate(-40)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopOpacity="0.6" />
          <stop offset="0.17" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="1409.08"
          y1="-903.33"
          x2="1419.33"
          y2="-855.13"
          gradientTransform="translate(315.1 1915.67) rotate(-60)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="2013.84"
          y1="-913.06"
          x2="2024.09"
          y2="-864.85"
          gradientTransform="matrix(0.17, -0.98, 0.98, 0.17, 819.49, 2382.59)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="2605.94"
          y1="-721.53"
          x2="2616.18"
          y2="-673.33"
          gradientTransform="matrix(-0.17, -0.98, 0.98, -0.17, 1462.89, 2668.11)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="3023.74"
          y1="-609.24"
          x2="3033.99"
          y2="-561.04"
          gradientTransform="matrix(-0.5, -0.87, 0.87, -0.5, 2362.59, 2518.36)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="3419.2"
          y1="-73.53"
          x2="3429.45"
          y2="-25.33"
          gradientTransform="matrix(-0.77, -0.64, 0.64, -0.77, 3003.44, 2316.27)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="3618.36"
          y1="553.78"
          x2="3628.6"
          y2="601.99"
          gradientTransform="matrix(-0.94, -0.34, 0.34, -0.94, 3550.85, 1900.62)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="3611.99"
          y1="1205.63"
          x2="3622.24"
          y2="1253.83"
          gradientTransform="translate(3943.75 1316.57) rotate(180)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-9"
          x1={3207}
          y1="1449.98"
          x2="3217.25"
          y2="1498.19"
          gradientTransform="translate(3823.22 349.53) rotate(160)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="2887.11"
          y1="1924.19"
          x2="2897.36"
          y2="1972.39"
          gradientTransform="matrix(-0.77, 0.64, -0.64, -0.77, 3735.48, -316.67)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-11"
          x1="2435.67"
          y1="2249.46"
          x2="2445.91"
          y2="2297.67"
          gradientTransform="translate(3421.2 -927.95) rotate(120)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-12"
          x1="1920.67"
          y1="2394.54"
          x2="1930.92"
          y2="2442.74"
          gradientTransform="translate(2914.25 -1416.3) rotate(100)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-13"
          x1="1314.05"
          y1="2084.53"
          x2="1324.3"
          y2="2132.73"
          gradientTransform="translate(2017.61 -1583.3) rotate(80)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-14"
          x1="891.29"
          y1="1873.04"
          x2="901.54"
          y2="1921.24"
          gradientTransform="translate(1346.28 -1612.58) rotate(60)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-15"
          x1="577.13"
          y1="1518.36"
          x2="587.38"
          y2="1566.57"
          gradientTransform="matrix(0.77, 0.64, -0.64, 0.77, 689.72, -1409.22)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-16"
          x1="424.27"
          y1="1071.87"
          x2="434.52"
          y2="1120.08"
          gradientTransform="matrix(0.94, 0.34, -0.34, 0.94, 120.76, -994.77)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-17"
          x1="553.32"
          y1="479.44"
          x2="563.57"
          y2="527.64"
          gradientTransform="translate(-392.16 -290.53)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-18"
          x1="757.24"
          y1="87.46"
          x2="767.48"
          y2="135.66"
          gradientTransform="matrix(0.94, -0.34, 0.34, 0.94, -561.61, 392.68)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-19"
          x1="876.58"
          y1="-703.62"
          x2="884.79"
          y2="-665.02"
          gradientTransform="matrix(0.6, -0.8, 0.8, 0.6, 267.06, 1347.32)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-20"
          x1="1432.13"
          y1="-902.87"
          x2="1440.34"
          y2="-864.27"
          gradientTransform="translate(704.6 1857.31) rotate(-73.13)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-21"
          x1="2033.64"
          y1="-911.05"
          x2="2041.85"
          y2="-872.45"
          gradientTransform="translate(1301.85 2197.5) rotate(-93.13)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-22"
          x1="2622.19"
          y1="-719.19"
          x2="2630.39"
          y2="-680.59"
          gradientTransform="translate(1993.27 2329.45) rotate(-113.13)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-23"
          x1="3036.62"
          y1="-607.77"
          x2="3044.82"
          y2="-569.17"
          gradientTransform="translate(2835.46 1979.32) rotate(-133.13)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-24"
          x1="3429.12"
          y1="-74.02"
          x2="3437.32"
          y2="-35.42"
          gradientTransform="translate(3413.67 1636.98) rotate(-153.13)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-25"
          x1="3626.26"
          y1="550.48"
          x2="3634.46"
          y2="589.08"
          gradientTransform="translate(3852.4 1107.88) rotate(-173.13)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-26"
          x1="3618.88"
          y1="1198.91"
          x2="3627.08"
          y2="1237.51"
          gradientTransform="translate(4102.41 449.87) rotate(166.87)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-27"
          x1="3214.24"
          y1="1439.72"
          x2="3222.44"
          y2="1478.32"
          gradientTransform="translate(3765.44 -464.53) rotate(146.87)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-28"
          x1="2895.74"
          y1="1910.74"
          x2="2903.95"
          y2="1949.34"
          gradientTransform="matrix(-0.6, 0.8, -0.8, -0.6, 3528.72, -1093.41)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-29"
          x1="2446.68"
          y1="2233.46"
          x2="2454.88"
          y2="2272.06"
          gradientTransform="translate(3083.84 -1617.35) rotate(106.87)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-30"
          x1="1934.82"
          y1="2376.95"
          x2="1943.03"
          y2="2415.55"
          gradientTransform="translate(2479.24 -1977.83) rotate(86.87)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-31"
          x1="1331.77"
          y1="2066.56"
          x2="1339.97"
          y2="2105.16"
          gradientTransform="translate(1568.09 -1936.86) rotate(66.87)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-32"
          x1="912.41"
          y1="1855.93"
          x2="920.61"
          y2="1894.53"
          gradientTransform="translate(907.66 -1812.93) rotate(46.87)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-33"
          x1="601.24"
          y1="1503.28"
          x2="609.44"
          y2="1541.88"
          gradientTransform="translate(314.42 -1465.79) rotate(26.87)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-34"
          x1="450.42"
          y1="1059.62"
          x2="458.62"
          y2="1098.22"
          gradientTransform="translate(-145.56 -932.97) rotate(6.87)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-35"
          x1="580.42"
          y1="470.63"
          x2="588.63"
          y2="509.23"
          gradientTransform="translate(-485.17 -130.65) rotate(-13.13)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-36"
          x1="784.05"
          y1="82.12"
          x2="792.26"
          y2="120.72"
          gradientTransform="translate(-495.05 573.18) rotate(-33.13)"
          xlinkHref="#linear-gradient"
        />
        <radialGradient
          id="radial-gradient"
          cx="247.75"
          cy="149.83"
          r="96.07"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.54" />
          <stop offset="0.92" stopOpacity={0} />
        </radialGradient>
      </defs>
      <title>rosette</title>
      <g id="transparent_squares" data-name="transparent squares">
        <rect id="transparent-square" width={500} height={500} fill="none" />
      </g>
      <g id="rosette">
        <g id="rosette_body_outer_feathers">
          <path
            d="M251.21,212.2,219.5,299.3a61.74,61.74,0,0,1-15-6.89c-5.92-3.77-8.38-6.87-17.3-14.21-3.52-2.9-6.59-5.27-8.88-7l51.25-61.08Z"
            fill="#ff4a4a"
          />
          <path
            d="M272.11,206.54v92.69a62.12,62.12,0,0,1-16.43-1.35c-6.85-1.52-10.22-3.59-21.12-7.44-4.29-1.52-8-2.7-10.73-3.53L251.1,212Z"
            fill="#ff4a4a"
          />
          <path
            d="M290,194l31.7,87.1a62.15,62.15,0,0,1-15.9,4.35c-7,.91-10.84.12-22.39.23-4.56,0-8.44.2-11.29.36V206.28Z"
            fill="#ff4a4a"
          />
          <path
            d="M302.57,176.23l59.58,71a62.4,62.4,0,0,1-13.46,9.53c-6.22,3.23-10.14,3.82-21,7.87-4.26,1.6-7.86,3.08-10.48,4.2L290,193.9Z"
            fill="#ff4a4a"
          />
          <path
            d="M308.3,155.64,388.57,202a62.47,62.47,0,0,1-9.38,13.55c-4.75,5.17-8.23,7.06-17,14.57-3.46,3-6.33,5.57-8.41,7.53l-51.25-61.08Z"
            fill="#ff4a4a"
          />
          <path
            d="M306.36,134.07l91.28,16.1a62.17,62.17,0,0,1-4.19,15.94c-2.69,6.48-5.31,9.45-11,19.51-2.25,4-4,7.41-5.34,9.95L308.07,155.7Z"
            fill="#ff4a4a"
          />
          <path
            d="M297.48,114.88l91.28-16.1a62.21,62.21,0,0,1,1.52,16.42c-.31,7-1.76,10.69-3.66,22.09-.75,4.49-1.27,8.34-1.61,11.17l-78.52-13.84Z"
            fill="#ff4a4a"
          />
          <path
            d="M282.18,99.4l80.27-46.34A62.22,62.22,0,0,1,369.5,68c2.1,6.69,2,10.65,4.11,22,.83,4.48,1.66,8.28,2.31,11.06L297.4,114.87Z"
            fill="#ff4a4a"
          />
          <path
            d="M263.17,90l59.58-71a62.33,62.33,0,0,1,11.72,11.6c4.26,5.57,5.52,9.32,11.39,19.27,2.31,3.93,4.39,7.21,5.95,9.6L282.76,99.29Z"
            fill="#ff4a4a"
          />
          <path
            d="M241.59,88.14,273.29,1a61.85,61.85,0,0,1,15,6.89c5.91,3.77,8.38,6.87,17.3,14.21,3.51,2.9,6.59,5.27,8.88,7L263.19,90.2Z"
            fill="#ff4a4a"
          />
          <path
            d="M220.49,93.83V1.14a62.08,62.08,0,0,1,16.42,1.35C243.77,4,247.15,6.08,258,9.93c4.3,1.52,8,2.7,10.73,3.52L241.5,88.38Z"
            fill="#ff4a4a"
          />
          <path
            d="M202.6,106.2,170.9,19.1a62,62,0,0,1,15.9-4.34c6.95-.91,10.83-.13,22.39-.23,4.55,0,8.44-.21,11.29-.36V93.9Z"
            fill="#ff4a4a"
          />
          <path
            d="M190.17,123.59l-59.58-71a62.29,62.29,0,0,1,13.46-9.52c6.22-3.23,10.14-3.82,21-7.87,4.26-1.6,7.86-3.08,10.48-4.2l27.27,74.92Z"
            fill="#ff4a4a"
          />
          <path
            d="M184.62,144.52,104.35,98.18a62,62,0,0,1,9.39-13.56c4.74-5.16,8.22-7.05,17-14.56,3.46-3,6.33-5.58,8.42-7.53l51.25,61.08Z"
            fill="#ff4a4a"
          />
          <path
            d="M186.4,165.6,95.12,149.5a62.34,62.34,0,0,1,4.18-15.95c2.69-6.47,5.31-9.44,11-19.5,2.24-4,4-7.41,5.33-10L184.68,144Z"
            fill="#ff4a4a"
          />
          <path
            d="M195.48,185.37,104.2,201.46a62.14,62.14,0,0,1-1.52-16.41c.31-7,1.76-10.69,3.66-22.09.75-4.49,1.27-8.34,1.61-11.18l78.52,13.85Z"
            fill="#ff4a4a"
          />
          <path
            d="M210.73,200.45l-80.27,46.34a61.93,61.93,0,0,1-7-14.9c-2.11-6.69-2-10.65-4.12-22-.83-4.48-1.66-8.27-2.31-11L195.51,185Z"
            fill="#ff4a4a"
          />
          <path
            d="M230.4,209.76l-59.58,71a62.34,62.34,0,0,1-11.72-11.59c-4.26-5.57-5.52-9.32-11.39-19.28-2.31-3.92-4.39-7.2-6-9.59l69.05-39.87Z"
            fill="#ff4a4a"
          />
        </g>
        <g id="rosette_body_outer_feathers_shadow">
          <path
            d="M251.21,212.2,219.5,299.3a61.74,61.74,0,0,1-15-6.89c-5.92-3.77-8.38-6.87-17.3-14.21-3.52-2.9-6.59-5.27-8.88-7l51.25-61.08Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient)"
          />
          <path
            d="M272.11,206.54v92.69a62.12,62.12,0,0,1-16.43-1.35c-6.85-1.52-10.22-3.59-21.12-7.44-4.29-1.52-8-2.7-10.73-3.53L251.1,212Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-2)"
          />
          <path
            d="M290,194l31.7,87.1a62.15,62.15,0,0,1-15.9,4.35c-7,.91-10.84.12-22.39.23-4.56,0-8.44.2-11.29.36V206.28Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-3)"
          />
          <path
            d="M302.57,176.23l59.58,71a62.4,62.4,0,0,1-13.46,9.53c-6.22,3.23-10.14,3.82-21,7.87-4.26,1.6-7.86,3.08-10.48,4.2L290,193.9Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-4)"
          />
          <path
            d="M308.3,155.64,388.57,202a62.47,62.47,0,0,1-9.38,13.55c-4.75,5.17-8.23,7.06-17,14.57-3.46,3-6.33,5.57-8.41,7.53l-51.25-61.08Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-5)"
          />
          <path
            d="M306.36,134.07l91.28,16.1a62.17,62.17,0,0,1-4.19,15.94c-2.69,6.48-5.31,9.45-11,19.51-2.25,4-4,7.41-5.34,9.95L308.07,155.7Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-6)"
          />
          <path
            d="M297.48,114.88l91.28-16.1a62.21,62.21,0,0,1,1.52,16.42c-.31,7-1.76,10.69-3.66,22.09-.75,4.49-1.27,8.34-1.61,11.17l-78.52-13.84Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-7)"
          />
          <path
            d="M282.18,99.4l80.27-46.34A62.22,62.22,0,0,1,369.5,68c2.1,6.69,2,10.65,4.11,22,.83,4.48,1.66,8.28,2.31,11.06L297.4,114.87Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-8)"
          />
          <path
            d="M263.17,90l59.58-71a62.33,62.33,0,0,1,11.72,11.6c4.26,5.57,5.52,9.32,11.39,19.27,2.31,3.93,4.39,7.21,5.95,9.6L282.76,99.29Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-9)"
          />
          <path
            d="M241.59,88.14,273.29,1a61.85,61.85,0,0,1,15,6.89c5.91,3.77,8.38,6.87,17.3,14.21,3.51,2.9,6.59,5.27,8.88,7L263.19,90.2Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-10)"
          />
          <path
            d="M220.49,93.83V1.14a62.08,62.08,0,0,1,16.42,1.35C243.77,4,247.15,6.08,258,9.93c4.3,1.52,8,2.7,10.73,3.52L241.5,88.38Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-11)"
          />
          <path
            d="M202.6,106.2,170.9,19.1a62,62,0,0,1,15.9-4.34c6.95-.91,10.83-.13,22.39-.23,4.55,0,8.44-.21,11.29-.36V93.9Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-12)"
          />
          <path
            d="M190.17,123.59l-59.58-71a62.29,62.29,0,0,1,13.46-9.52c6.22-3.23,10.14-3.82,21-7.87,4.26-1.6,7.86-3.08,10.48-4.2l27.27,74.92Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-13)"
          />
          <path
            d="M184.62,144.52,104.35,98.18a62,62,0,0,1,9.39-13.56c4.74-5.16,8.22-7.05,17-14.56,3.46-3,6.33-5.58,8.42-7.53l51.25,61.08Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-14)"
          />
          <path
            d="M186.4,165.6,95.12,149.5a62.34,62.34,0,0,1,4.18-15.95c2.69-6.47,5.31-9.44,11-19.5,2.24-4,4-7.41,5.33-10L184.68,144Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-15)"
          />
          <path
            d="M195.48,185.37,104.2,201.46a62.14,62.14,0,0,1-1.52-16.41c.31-7,1.76-10.69,3.66-22.09.75-4.49,1.27-8.34,1.61-11.18l78.52,13.85Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-16)"
          />
          <path
            d="M210.73,200.45l-80.27,46.34a61.93,61.93,0,0,1-7-14.9c-2.11-6.69-2-10.65-4.12-22-.83-4.48-1.66-8.27-2.31-11L195.51,185Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-17)"
          />
          <path
            d="M230.4,209.76l-59.58,71a62.34,62.34,0,0,1-11.72-11.59c-4.26-5.57-5.52-9.32-11.39-19.28-2.31-3.92-4.39-7.2-6-9.59l69.05-39.87Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-18)"
          />
        </g>
        <g id="rosette_body_inner_feathers">
          <path
            d="M261.42,197.67l-8.88,73.69a49.44,49.44,0,0,1-12.93-2.65c-5.3-1.87-7.79-3.83-16.08-7.94-3.27-1.62-6.1-2.91-8.19-3.83L244.2,200Z"
            fill="#ff866e"
          />
          <path
            d="M276.7,189.45l16.85,72.29a49.59,49.59,0,0,1-13.06,1.93c-5.62.06-8.63-.94-17.82-2-3.63-.4-6.73-.65-9-.8l7.65-63.39Z"
            fill="#ff866e"
          />
          <path
            d="M288.35,176.41l40.56,62.16a49.65,49.65,0,0,1-11.61,6.28c-5.25,2-8.42,2.07-17.41,4.25-3.55.86-6.55,1.69-8.74,2.33l-14.5-62.18Z"
            fill="#ff866e"
          />
          <path
            d="M294.94,160.27l59.37,44.55a49.94,49.94,0,0,1-8.76,9.87c-4.26,3.65-7.21,4.82-14.91,9.95-3,2-5.57,3.83-7.42,5.18l-34.88-53.47Z"
            fill="#ff866e"
          />
          <path
            d="M295.66,143.18l71,21.54A50,50,0,0,1,361.84,177c-2.76,4.89-5.13,7-10.61,14.45-2.17,2.94-3.93,5.5-5.2,7.4L295,160.54Z"
            fill="#ff866e"
          />
          <path
            d="M290.22,126.71l74.12-4a49.6,49.6,0,0,1-.37,13.2c-.91,5.54-2.42,8.33-5,17.21-1,3.5-1.81,6.51-2.35,8.73l-61.1-18.53Z"
            fill="#ff866e"
          />
          <path
            d="M279.81,113.35,348.07,84.2a49.42,49.42,0,0,1,4.17,12.53c1,5.52.57,8.66,1.16,17.89.24,3.64.53,6.74.78,9l-63.75,3.48Z"
            fill="#ff866e"
          />
          <path
            d="M265.07,104.07l54.17-50.74a50.37,50.37,0,0,1,8.21,10.34c2.85,4.84,3.49,7.94,7.21,16.42,1.46,3.34,2.8,6.15,3.81,8.2l-58.72,25.07Z"
            fill="#ff866e"
          />
          <path
            d="M248.53,100.17,282.08,34a49.81,49.81,0,0,1,11.24,6.91c4.34,3.57,6,6.27,12.39,13,2.52,2.64,4.74,4.82,6.39,6.4l-46.6,43.65Z"
            fill="#ff866e"
          />
          <path
            d="M231.36,102.66,240.25,29a49.86,49.86,0,0,1,12.93,2.65c5.3,1.87,7.79,3.84,16.08,7.94,3.27,1.62,6.1,2.91,8.19,3.83l-28.86,57Z"
            fill="#ff866e"
          />
          <path
            d="M216,110.94,199.09,38.65a49.65,49.65,0,0,1,13.06-1.93c5.62-.06,8.63.94,17.82,2,3.63.41,6.73.65,9,.8l-7.64,63.39Z"
            fill="#ff866e"
          />
          <path
            d="M204.24,123.84,163.68,61.68a50,50,0,0,1,11.61-6.28c5.26-2,8.43-2.07,17.42-4.25,3.54-.86,6.54-1.69,8.74-2.33L215.94,111Z"
            fill="#ff866e"
          />
          <path
            d="M197.71,139.66,138.34,95.12a49.36,49.36,0,0,1,8.76-9.87c4.26-3.66,7.21-4.83,14.91-10,3-2,5.57-3.83,7.42-5.19l34.88,53.48Z"
            fill="#ff866e"
          />
          <path
            d="M197.19,157l-71-21.55A49.91,49.91,0,0,1,131,123.17c2.76-4.89,5.13-7,10.61-14.45,2.16-2.94,3.92-5.5,5.19-7.4l51.08,38.31Z"
            fill="#ff866e"
          />
          <path
            d="M202.41,173.11l-74.12,4a50,50,0,0,1,.37-13.19c.92-5.54,2.42-8.33,5-17.21,1-3.5,1.8-6.52,2.35-8.74l61.09,18.54Z"
            fill="#ff866e"
          />
          <path
            d="M213.09,186.88,144.83,216a49.94,49.94,0,0,1-4.18-12.53c-1-5.52-.57-8.66-1.16-17.89-.23-3.64-.53-6.74-.78-9l63.76-3.48Z"
            fill="#ff866e"
          />
          <path
            d="M227.72,195.86,173.55,246.6a49.84,49.84,0,0,1-8.21-10.34c-2.85-4.84-3.49-7.94-7.21-16.41-1.46-3.35-2.8-6.16-3.81-8.21L213,186.57Z"
            fill="#ff866e"
          />
          <path
            d="M244.75,199.55,211.2,265.76A50.12,50.12,0,0,1,200,258.84c-4.34-3.56-6-6.26-12.39-13-2.51-2.64-4.73-4.82-6.39-6.4l46.61-43.64Z"
            fill="#ff866e"
          />
        </g>
        <g id="rosette_body_inner_feathers_shadow">
          <path
            d="M261.42,197.67l-8.88,73.69a49.44,49.44,0,0,1-12.93-2.65c-5.3-1.87-7.79-3.83-16.08-7.94-3.27-1.62-6.1-2.91-8.19-3.83L244.2,200Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-19)"
          />
          <path
            d="M276.7,189.45l16.85,72.29a49.59,49.59,0,0,1-13.06,1.93c-5.62.06-8.63-.94-17.82-2-3.63-.4-6.73-.65-9-.8l7.65-63.39Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-20)"
          />
          <path
            d="M288.35,176.41l40.56,62.16a49.65,49.65,0,0,1-11.61,6.28c-5.25,2-8.42,2.07-17.41,4.25-3.55.86-6.55,1.69-8.74,2.33l-14.5-62.18Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-21)"
          />
          <path
            d="M294.94,160.27l59.37,44.55a49.94,49.94,0,0,1-8.76,9.87c-4.26,3.65-7.21,4.82-14.91,9.95-3,2-5.57,3.83-7.42,5.18l-34.88-53.47Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-22)"
          />
          <path
            d="M295.66,143.18l71,21.54A50,50,0,0,1,361.84,177c-2.76,4.89-5.13,7-10.61,14.45-2.17,2.94-3.93,5.5-5.2,7.4L295,160.54Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-23)"
          />
          <path
            d="M290.22,126.71l74.12-4a49.6,49.6,0,0,1-.37,13.2c-.91,5.54-2.42,8.33-5,17.21-1,3.5-1.81,6.51-2.35,8.73l-61.1-18.53Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-24)"
          />
          <path
            d="M279.81,113.35,348.07,84.2a49.42,49.42,0,0,1,4.17,12.53c1,5.52.57,8.66,1.16,17.89.24,3.64.53,6.74.78,9l-63.75,3.48Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-25)"
          />
          <path
            d="M265.07,104.07l54.17-50.74a50.37,50.37,0,0,1,8.21,10.34c2.85,4.84,3.49,7.94,7.21,16.42,1.46,3.34,2.8,6.15,3.81,8.2l-58.72,25.07Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-26)"
          />
          <path
            d="M248.53,100.17,282.08,34a49.81,49.81,0,0,1,11.24,6.91c4.34,3.57,6,6.27,12.39,13,2.52,2.64,4.74,4.82,6.39,6.4l-46.6,43.65Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-27)"
          />
          <path
            d="M231.36,102.66,240.25,29a49.86,49.86,0,0,1,12.93,2.65c5.3,1.87,7.79,3.84,16.08,7.94,3.27,1.62,6.1,2.91,8.19,3.83l-28.86,57Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-28)"
          />
          <path
            d="M216,110.94,199.09,38.65a49.65,49.65,0,0,1,13.06-1.93c5.62-.06,8.63.94,17.82,2,3.63.41,6.73.65,9,.8l-7.64,63.39Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-29)"
          />
          <path
            d="M204.24,123.84,163.68,61.68a50,50,0,0,1,11.61-6.28c5.26-2,8.43-2.07,17.42-4.25,3.54-.86,6.54-1.69,8.74-2.33L215.94,111Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-30)"
          />
          <path
            d="M197.71,139.66,138.34,95.12a49.36,49.36,0,0,1,8.76-9.87c4.26-3.66,7.21-4.83,14.91-10,3-2,5.57-3.83,7.42-5.19l34.88,53.48Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-31)"
          />
          <path
            d="M197.19,157l-71-21.55A49.91,49.91,0,0,1,131,123.17c2.76-4.89,5.13-7,10.61-14.45,2.16-2.94,3.92-5.5,5.19-7.4l51.08,38.31Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-32)"
          />
          <path
            d="M202.41,173.11l-74.12,4a50,50,0,0,1,.37-13.19c.92-5.54,2.42-8.33,5-17.21,1-3.5,1.8-6.52,2.35-8.74l61.09,18.54Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-33)"
          />
          <path
            d="M213.09,186.88,144.83,216a49.94,49.94,0,0,1-4.18-12.53c-1-5.52-.57-8.66-1.16-17.89-.23-3.64-.53-6.74-.78-9l63.76-3.48Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-34)"
          />
          <path
            d="M227.72,195.86,173.55,246.6a49.84,49.84,0,0,1-8.21-10.34c-2.85-4.84-3.49-7.94-7.21-16.41-1.46-3.35-2.8-6.16-3.81-8.21L213,186.57Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-35)"
          />
          <path
            d="M244.75,199.55,211.2,265.76A50.12,50.12,0,0,1,200,258.84c-4.34-3.56-6-6.26-12.39-13-2.51-2.64-4.73-4.82-6.39-6.4l46.61-43.64Z"
            fillOpacity="0.25"
            opacity="0.25"
            fill="url(#linear-gradient-36)"
          />
        </g>
        <polygon
          id="rosette_ribbons_ribbon_left"
          points="145.46 446.06 189.8 423.05 189.88 423 197.64 441.52 210.72 472.77 210.94 473.29 211.02 473.05 243.68 370.55 260.95 316.34 267.3 296.42 275.49 270.69 276.65 267.08 283.92 244.26 291.34 220.95 294.08 212.36 295.9 206.66 311.48 157.76 279.54 144.49 260.25 136.46 246 130.54 243 139.94 223.21 202.06 222.81 203.33 221.94 206.05 219.48 213.77 205.22 258.52 191.41 301.83 190.62 304.34 168.66 373.25 145.46 446.06"
          fill="#ff4a4a"
        />
        <polygon
          id="rosette_ribbons_ribbon_centre"
          points="215.58 164.24 216.05 500 250.84 460.91 285.74 499.87 285.26 164.11 215.58 164.24"
          fill="#ff866e"
        />
        <polygon
          id="rosette_ribbons_ribbon_right"
          points="190.38 158.23 290.92 473.75 311.98 423.46 356.4 446.52 255.86 131.01 190.38 158.23"
          fill="#ff4a4a"
        />
        <g id="rosette_body_circle_shadow" opacity="0.35">
          <circle
            cx="247.75"
            cy="149.83"
            r="96.07"
            fill="url(#radial-gradient)"
          />
        </g>
        <path
          id="rosette_body_scallops"
          d="M333.63,157.14a7.83,7.83,0,0,0,4.53-7.1h0v-.22h0a7.84,7.84,0,0,0-4.55-7.1,7.83,7.83,0,0,0,3.3-7.76h0a7.83,7.83,0,0,0-5.66-6.26,7.84,7.84,0,0,0-4.64-13.45,7.84,7.84,0,0,0,.58-8.41h0a7.83,7.83,0,0,0-7.39-4.07,7.83,7.83,0,0,0-.81-8.4h0a7.84,7.84,0,0,0-8-2.8A7.83,7.83,0,0,0,300.55,82a7.83,7.83,0,0,0-11.92-7.77,7.84,7.84,0,0,0-13-5.7A7.83,7.83,0,0,0,261.79,65a7.84,7.84,0,0,0-14.18-1.14A7.81,7.81,0,0,0,240.15,60h0a7.82,7.82,0,0,0-6.7,5.12,7.85,7.85,0,0,0-8-2.66h0a7.83,7.83,0,0,0-5.77,6.17,7.84,7.84,0,0,0-8.35-1.3h0a7.87,7.87,0,0,0-4.67,7,7.83,7.83,0,0,0-11.88,7.81,7.85,7.85,0,0,0-8.31,1.49h0a7.83,7.83,0,0,0-2.14,8.16A7.84,7.84,0,0,0,175.58,103a7.84,7.84,0,0,0-6.76,12.52,7.86,7.86,0,0,0-6.61,5.26h0a7.86,7.86,0,0,0,2,8.21,7.82,7.82,0,0,0-5.64,6.28h0A7.85,7.85,0,0,0,161.9,143a7.85,7.85,0,0,0-4.53,7.11h0a7.87,7.87,0,0,0,4.55,7.11,7.84,7.84,0,0,0,2.34,14,7.83,7.83,0,0,0-2,8.2h0a7.86,7.86,0,0,0,6.61,5.25,7.84,7.84,0,0,0-.6,8.41h0a7.84,7.84,0,0,0,7.39,4.08,7.85,7.85,0,0,0,.8,8.4h0a7.81,7.81,0,0,0,8,2.81,7.86,7.86,0,0,0,2.16,8.16h0a7.85,7.85,0,0,0,8.31,1.47,7.84,7.84,0,0,0,3.47,7.69h0a7.84,7.84,0,0,0,8.44.09,7.84,7.84,0,0,0,4.69,7h0a7.84,7.84,0,0,0,8.35-1.31,7.84,7.84,0,0,0,13.8,3.48,7.83,7.83,0,0,0,6.72,5.1h0a7.84,7.84,0,0,0,7.45-3.94,7.87,7.87,0,0,0,7.46,3.94h0A7.83,7.83,0,0,0,262,235a7.83,7.83,0,0,0,13.79-3.49,7.84,7.84,0,0,0,8.35,1.3h0a7.86,7.86,0,0,0,4.68-7A7.84,7.84,0,0,0,300.7,218a7.85,7.85,0,0,0,8.31-1.48h0a7.84,7.84,0,0,0,2.15-8.16,7.83,7.83,0,0,0,7.95-2.82h0a7.83,7.83,0,0,0,.79-8.4,7.84,7.84,0,0,0,6.77-12.52,7.83,7.83,0,0,0,4.63-13.45A7.87,7.87,0,0,0,337,164.9h0A7.86,7.86,0,0,0,333.63,157.14Z"
          fill="#fff"
        />
        <g id="rosette_body_outer_ring">
          <circle cx="247.75" cy="149.83" r="83.22" fill="#fc6" />
        </g>
        <circle
          id="rosette_body_centre"
          cx="248.09"
          cy="150.17"
          r="71.38"
          fill="#f43737"
        />
      </g>
    </StyledSvg>
  )
}
