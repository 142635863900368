import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Section from "components/layout/Section";
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons";
import VaccinationForm from "forms/Vaccination_Form";
import { MODALS_SHOW, MODALS_CONFIRM_DELETE, VACS_DELETE } from "actions/types";

const INITIAL_STATE = {
  viewMode: true,
  valuesToEdit: {},
};

class ViewEntry extends Component {
  state = INITIAL_STATE;

  UNSAFE_componentWillMount() {
    const { vacs, history } = this.props;
    const { id } = this.props.match.params;
    const vac = vacs.entries.filter((vac) => vac._id === id)[0];
    if (!vac) return history.push(`${this.props.defaultRoute}/view_entries`);
    this.setState({
      valuesToEdit: vac,
    });
  }

  handleEditClick = () => {
    this.setState({
      viewMode: !this.state.viewMode,
    });
  };

  handleDeleteClick = () => {
    const successRoute = `${this.props.defaultRoute}/view_entries`;
    this.props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "vaccination",
        actionType: VACS_DELETE,
        history: this.props.history,
        successRoute: successRoute,
        item: this.state.valuesToEdit._id,
      },
    });
  };

  renderSectionContent() {
    if (_.isEmpty(this.state.valuesToEdit)) return <div />;

    return (
      <>
        <BackEditDeleteButtons
          disableEditButton={this.props.selectedLitterMember.hasNewOwner}
          disableDeleteButton={this.props.selectedLitterMember.hasNewOwner}
          handleEditClick={this.handleEditClick}
          handleDeleteClick={this.handleDeleteClick}
          viewMode={this.state.viewMode}
        />
        <div className="columns is-mobile is-centered">
          <div className="column is-5-desktop is-full-mobile is-6-tablet">
            <VaccinationForm
              editMode
              {...this.state}
              cancel={this.handleEditClick}
            />
          </div>
        </div>
      </>
    );
  }

  render() {
    return <Section>{this.renderSectionContent()}</Section>;
  }
}

export default withRouter(ViewEntry);
