import React from "react";
import PetTile from "./PetTile";
import PartnerTile from "./PartnerTile";
import GlobalTile from "./GlobalTile";
import FeatureTile from "./FeatureTile";
import AddPetTile from "./AddPetTile";
import AppTile from "./AppTile";
import LitterTile from "./LitterTile";
import UserAccounTile from "./UserAccountTile";
import FeedbackAndSupportTile from "./FeedbackAndSupportTile";

// Manage all Props and Functions
// Pass props down to relevant tiles

function SmartTile(props) {
  switch (true) {
    case props.petTile:
      return <PetTile pet={props.pet} {...props} />;
    case props.partnerTile:
      return <PartnerTile {...props} />;
    case props.globalTile:
      return <GlobalTile {...props} />;
    case props.featureTile:
      return <FeatureTile {...props} />;
    case props.addPetTile:
      return <AddPetTile {...props} />;
    case props.appTile:
      return <AppTile {...props} />;
    case props.litterTile:
      return <LitterTile {...props} />;
    case props.userAccountTile:
      return <UserAccounTile {...props} />;
    case props.feedbackAndSupportTile:
      return <FeedbackAndSupportTile {...props} />;
    default:
      return null;
  }
}

export default SmartTile;
