import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SELECT_BUTTONS from "constants/select_options";
import { FormValidationMessageV5 } from ".";

function SelectButtonsV5({
  field,
  form,
  viewMode,
  editMode,
  userCanEdit,
  required,
  messages,
  locale,
  label,
  group,
  altGroup,
  altName,
  multiple,
  orderAZ,
}) {
  const [selectedButton, setSelectedButton] = useState(field.value);
  const [selectOptions, setSelectOptions] = useState(
    SELECT_BUTTONS[altGroup ? altGroup : group][altName ? altName : field.name].options.map(
      (option) => ({
        value: option,
        string: messages.selectOptions[option][locale],
      })
    )
  );

  useEffect(() => {
    setSelectedButton(field.value);
  }, [field.value]);

  useEffect(() => {
    if (orderAZ) {
      setSelectOptions((prevOptions) =>
        prevOptions.sort((a, b) => (a.string > b.string ? 1 : -1))
      );
    }
  }, [orderAZ]);

  function handleButtonPress(value) {
    let newValue;
    if (multiple) {
      if (selectedButton.includes(value)) {
        newValue = selectedButton.filter((v) => v !== value);
      } else {
        newValue = [...selectedButton, value];
      }
    } else {
      newValue = value;
    }
    setSelectedButton(newValue);
    form.setFieldValue(field.name, newValue);
  }

  function checkIfSelected(option) {
    if (multiple) {
      return selectedButton.includes(option);
    }
    return selectedButton === option;
  }

  const style2 = {
    border: `1px solid lightgrey`,
  };

  return (
    <div className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">{label}</div>
      </label>
      <div className="buttons">
        {selectOptions.map((option, i) => (
          <div
            key={i}
            className={`button ${checkIfSelected(option.value) ? "is-primary" : ""}`}
            onClick={
              viewMode || (!userCanEdit && editMode)
                ? null
                : () => {
                    handleButtonPress(option.value);
                  }
            }
            style={style2}
          >
            {option.string}
          </div>
        ))}
      </div>
      <FormValidationMessageV5
        clientMessage
        group={group}
        name={field.name}
        errors={form.errors}
        touched={form.touched}
      />
    </div>
  );
}

const mapStateToProps = (state, { group, altGroup, selectOptionsGroup, field }) => {
  const { locale, messages } = state.intl;
  return {
    locale,
    messages: {
      label: messages.components.forms.labels[group][field.name][locale],
      selectOptions: messages.components.forms.selectOptions[selectOptionsGroup],
    },
  };
};

export default connect(mapStateToProps)(SelectButtonsV5);
