import React from "react"
import Container from "./Container"

export default function HeroHead(props) {
  return (
    <div {...props}>
      <Container>{props.children}</Container>
    </div>
  )
}
