import axios from "axios"
import moment from "moment"
import _ from "lodash"
import {
  STABLE_DIARY_FETCH,
  STABLE_DIARY_FETCH_TYPE,
  STABLE_DIARY_ADD,
  STABLE_DIARY_UPDATE,
  STABLE_DIARY_DELETE,
  ACTION_STARTED,
  ACTION_ERROR,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
  MODALS_HIDE,
  TABLE_CLEAR_CURRENT_STATE,
} from "./types"

// Action Creators
export const fetchStableDiaryEntries = (value, unit, name, pet) => async (
  dispatch
) => {
  const dates = !_.isEmpty(value)
    ? { ...value }
    : {
        from: moment().subtract(value, unit).toISOString(),
        to: moment().toISOString(),
      }
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({
      type: STABLE_DIARY_FETCH_TYPE,
      payload: { dates, type: !_.isEmpty(value) ? "range" : name },
    })
    dispatch({ type: ACTION_STARTED, payload: STABLE_DIARY_FETCH })
    const res = await axios.get("/api/stable_diary/fetch", {
      headers: {
        authorization: localStorage.getItem("token"),
      },
      params: { _pet: pet._id, ...dates },
    })
    dispatch({ type: ACTION_ENDED })
    dispatch({
      type: STABLE_DIARY_FETCH,
      payload: res.data.stableDiaryEntries,
      pet,
    })
  } catch (error) {
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const addStableDiaryEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: STABLE_DIARY_ADD })
    const res = await axios.post("/api/stable_diary/add", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({ type: ACTION_ENDED })
    dispatch({ type: STABLE_DIARY_ADD, payload: res.data.stableDiaryEntry })
    dispatch({ type: ACTION_RESET })
    return history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const updateStableDiaryEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: STABLE_DIARY_UPDATE })
    const res = await axios.put("/api/stable_diary/update", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({ type: STABLE_DIARY_UPDATE, payload: res.data.stableDiaryEntry })
    dispatch({ type: ACTION_ENDED })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    history.push(successRoute)
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
  } catch (error) {
    setSubmitting(false)
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const deleteStableDiaryEntry = (
  _id,
  type,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: STABLE_DIARY_DELETE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      "/api/stable_diary/delete",
      { _id, type },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({ type: STABLE_DIARY_DELETE, payload: res.data.stableDiaryEntry })
    dispatch({ type: ACTION_ENDED })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    history.push(successRoute)
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
  } catch (error) {
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

const stableDiaryActions = {
  fetchStableDiaryEntries,
  addStableDiaryEntry,
  updateStableDiaryEntry,
  deleteStableDiaryEntry,
}

export default stableDiaryActions
