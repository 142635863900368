import axios from "axios";
import {
  LITTERS_FETCH,
  LITTERS_ADD,
  LITTERS_UPDATE,
  LITTERS_SELECT_LITTER,
  LITTERS_UNSELECT_LITTER,
  LITTERS_FETCH_LITTER_MEMBERS,
  LITTERS_ADD_LITTER_MEMBER,
  LITTERS_UPDATE_MEMBER,
  LITTERS_DELETE_MEMBER,
  LITTERS_SELECT_LITTER_MEMBER,
  LITTERS_UNSELECT_LITTER_MEMBER,
  ACTION_STARTED,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
  MODALS_HIDE,
  WEIGHT_ADD_LITTER_MEMBER,
} from "./types";
import { catchBlockError } from "./functions";

export const fetchLitters = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: LITTERS_FETCH });
    const res = await axios.get("/api/litters/fetch", {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    dispatch({ type: ACTION_ENDED });
    return dispatch({ type: LITTERS_FETCH, payload: res.data.litters });
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const addLitter =
  (values, setSubmitting, history, successRoute) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: LITTERS_ADD });
      const res = await axios.post("/api/litters/add", values, {
        headers: { authorization: localStorage.getItem("token") },
      });
      setSubmitting(false);
      dispatch({
        type: ACTION_SUCCESS,
        payload: "addLitterSuccess",
      });
      dispatch({ type: LITTERS_ADD, payload: res.data.litter });
      dispatch({ type: LITTERS_SELECT_LITTER, payload: res.data.litter });
      dispatch({ type: ACTION_ENDED });
      dispatch({ type: ACTION_RESET });
      return history.push(successRoute);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const updateLitter =
  (values, updates, setSubmitting, onUpdateSuccess) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: LITTERS_UPDATE });
      const res = await axios.put(
        "/api/litters/update",
        { values, updates },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      );

      // If there are updates to litter members
      // we expect to receive the litter member array
      // and dispatch them to the store
      // replacing the old array
      if (res.data.updatedLitterMembers) {
        dispatch({
          type: LITTERS_FETCH_LITTER_MEMBERS,
          payload: {
            litterMembers: res.data.litterMembers,
            _litter: res.data._litter,
          },
        });
      }

      // If there are no updates to litter members
      // we expect only to receive the updated litter
      // and we dispatch that to the store replacing
      // the old object
      dispatch({
        type: LITTERS_UPDATE,
        payload: res.data.litter,
      });

      setSubmitting(false);
      dispatch({ type: ACTION_ENDED });
      dispatch({ type: ACTION_RESET });
      return onUpdateSuccess();
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export function deleteLitter() {}

export const deleteLitterMember = (_id, history) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: LITTERS_DELETE_MEMBER });
    dispatch({ type: MODALS_HIDE });
    const res = await axios.put(
      "/api/litters/delete_litter_member",
      { _id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    );
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteLitterMemberSuccess",
    });
    dispatch({ type: LITTERS_DELETE_MEMBER, payload: res.data.litterMember });

    return setTimeout(() => {
      dispatch({ type: ACTION_ENDED });
      dispatch({ type: ACTION_RESET });
      history.push("/userhome/dashboard/litter");
    }, 2000);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const selectLitter = (litter) => (dispatch) => {
  dispatch({ type: LITTERS_SELECT_LITTER, payload: litter });
};

export const unselectLitter = () => (dispatch) => {
  dispatch({ type: LITTERS_UNSELECT_LITTER });
};

export const fetchLitterMembers = (_litter) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: LITTERS_FETCH_LITTER_MEMBERS });
    const res = await axios.get("/api/litters/fetch_litter_members", {
      headers: {
        authorization: localStorage.getItem("token"),
      },
      params: { _litter: _litter },
    });
    dispatch({
      type: LITTERS_FETCH_LITTER_MEMBERS,
      payload: {
        litterMembers: res.data.litterMembers,
        _litter,
      },
    });
    dispatch({ type: ACTION_ENDED });
    return dispatch({ type: ACTION_RESET });
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const addLitterMember =
  (values, setSubmitting, history, successRoute) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: LITTERS_ADD_LITTER_MEMBER });
      const res = await axios.post("/api/litters/add_litter_member", values, {
        headers: { authorization: localStorage.getItem("token") },
      });
      dispatch({
        type: LITTERS_ADD_LITTER_MEMBER,
        payload: res.data.pet,
      });
      if (res.data.weightEntry) {
        dispatch({
          type: WEIGHT_ADD_LITTER_MEMBER,
          payload: res.data.weightEntry,
        });
      }
      dispatch({ type: ACTION_ENDED });
      dispatch({ type: ACTION_RESET });
      return history.push(successRoute);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const updateLitterMember =
  (values, setSubmitting, onUpdateSuccess) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: LITTERS_UPDATE_MEMBER });

      const res = await axios.put("/api/litters/update_litter_member", values, {
        headers: { authorization: localStorage.getItem("token") },
      });
      dispatch({ type: LITTERS_UPDATE_MEMBER, payload: res.data.litterMember });
      dispatch({ type: ACTION_ENDED });
      dispatch({ type: ACTION_RESET });
      return onUpdateSuccess();
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const selectLitterMember = (litterMember) => (dispatch) => {
  dispatch({ type: LITTERS_SELECT_LITTER_MEMBER, payload: litterMember });
};

export const unselectLitterMember = () => (dispatch) => {
  dispatch({ type: LITTERS_UNSELECT_LITTER_MEMBER });
};

const littersActions = {
  fetchLitters,
  addLitter,
  updateLitter,
  deleteLitter,
  selectLitter,
  unselectLitter,
  fetchLitterMembers,
  addLitterMember,
  deleteLitterMember,
  updateLitterMember,
  selectLitterMember,
  unselectLitterMember,
};

export default littersActions;
