import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import PROVIDER_CONFIG from "./provider_config";

class FoodDiary extends Component {
  render() {
    return <Menu {...PROVIDER_CONFIG} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.foodDiary,
      foodCategories:
        state.intl.messages.components.forms.selectOptions.foodCategory,
    },
    foodDiary: state.reducers.foodDiary,
    providerContact: { _id: state.reducers.pets.selectedPet._user },
  };
};

export default connect(mapStateToProps)(FoodDiary);
