import { pets } from "assets"
import Profile from "./Profile"
import { path } from "constants/feature_routes_provider_view"

const petProfileProviderConfig = {
  name: "petProfile",
  type: "petProfile",
  defaultRoute: path("profile"),
  menuIcon: { img: pets.profile, alt: "pet_profile_icon" },
  providerView: true,
  noNav: true,
  nav: [
    {
      name: "profile",
      image: {
        img: pets.editProfile,
        alt: "pet_profile_edit_icon",
      },
      icon: "fas fa-paw",
      component: Profile,
      path: "/",
    },
  ],
}

export default petProfileProviderConfig
