import React, { Component } from "react";
//anssiimport { Chart } from "chart.js";
import { Chart } from 'chart.js/auto';
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
Chart.register(annotationPlugin);

class Charts extends Component {
  static defaultProps = {
    displayTitle: false,
    displayLegend: true,
    legendPosition: "right",
  };

  render() {
    const {
      sizes,
      maintainAspectRatio,
      data,
      options,
      scales,
      displayTitle,
      title,
      displayLegend,
      legendPosition,
      howToUseText,
    } = this.props;
    return (
      <div className="notification has-background-white has-text-white">
        <Line
          data={data}
          width={sizes ? sizes.width : 100}
          height={sizes ? sizes.height : 250}
          options={{
            ...options,
            scales: scales,
            maintainAspectRatio: maintainAspectRatio ? true : false,
            title: {
              display: displayTitle,
              text: title,
              fontSize: 14,
            },
            plugins: {
              legend: {
                display: displayLegend,
                position: legendPosition,
              },
            },
          }}
        />
        <p className="has-text-centered">{howToUseText ? howToUseText : ""}</p>
      </div>
    );
  }
}

export default Charts;
