export const TOOTH_MAPS = {
  cat: {
    _101: { loc: "rightUpper", type: "incisor", num: "101" },
    _102: { loc: "rightUpper", type: "incisor", num: "102" },
    _103: { loc: "rightUpper", type: "incisor", num: "103" },
    _104: { loc: "rightUpper", type: "canine", num: "104" },
    _106: { loc: "rightUpper", type: "premolar", num: "106" },
    _107: { loc: "rightUpper", type: "premolar", num: "107" },
    _108: { loc: "rightUpper", type: "premolar", num: "108" },
    _109: { loc: "rightUpper", type: "molar", num: "109" },
    _201: { loc: "leftUpper", type: "incisor", num: "201" },
    _202: { loc: "leftUpper", type: "incisor", num: "202" },
    _203: { loc: "leftUpper", type: "incisor", num: "203" },
    _204: { loc: "leftUpper", type: "canine", num: "204" },
    _206: { loc: "leftUpper", type: "premolar", num: "206" },
    _207: { loc: "leftUpper", type: "premolar", num: "207" },
    _208: { loc: "leftUpper", type: "premolar", num: "208" },
    _209: { loc: "leftUpper", type: "molar", num: "209" },
    _301: { loc: "rightLower", type: "incisor", num: "301" },
    _302: { loc: "rightLower", type: "incisor", num: "302" },
    _303: { loc: "rightLower", type: "incisor", num: "303" },
    _304: { loc: "rightLower", type: "canine", num: "304" },
    _307: { loc: "rightLower", type: "premolar", num: "307" },
    _308: { loc: "rightLower", type: "premolar", num: "308" },
    _309: { loc: "rightLower", type: "molar", num: "309" },
    _401: { loc: "leftLower", type: "incisor", num: "401" },
    _402: { loc: "leftLower", type: "incisor", num: "402" },
    _403: { loc: "leftLower", type: "incisor", num: "403" },
    _404: { loc: "leftLower", type: "canine", num: "404" },
    _407: { loc: "leftLower", type: "premolar", num: "407" },
    _408: { loc: "leftLower", type: "premolar", num: "408" },
    _409: { loc: "leftLower", type: "molar", num: "409" },
  },
  dog: {
    _101: { loc: "rightUpper", type: "incisor", num: "101" },
    _102: { loc: "rightUpper", type: "incisor", num: "102" },
    _103: { loc: "rightUpper", type: "incisor", num: "103" },
    _104: { loc: "rightUpper", type: "canine", num: "104" },
    _105: { loc: "rightUpper", type: "premolar", num: "105" },
    _106: { loc: "rightUpper", type: "premolar", num: "106" },
    _107: { loc: "rightUpper", type: "premolar", num: "107" },
    _108: { loc: "rightUpper", type: "premolar", num: "108" },
    _109: { loc: "rightUpper", type: "molar", num: "109" },
    _110: { loc: "rightUpper", type: "molar", num: "110" },
    _201: { loc: "leftUpper", type: "incisor", num: "201" },
    _202: { loc: "leftUpper", type: "incisor", num: "202" },
    _203: { loc: "leftUpper", type: "incisor", num: "203" },
    _204: { loc: "leftUpper", type: "canine", num: "204" },
    _205: { loc: "leftUpper", type: "premolar", num: "205" },
    _206: { loc: "leftUpper", type: "premolar", num: "206" },
    _207: { loc: "leftUpper", type: "premolar", num: "207" },
    _208: { loc: "leftUpper", type: "premolar", num: "208" },
    _209: { loc: "leftUpper", type: "molar", num: "209" },
    _210: { loc: "leftUpper", type: "molar", num: "210" },
    _301: { loc: "rightLower", type: "incisor", num: "301" },
    _302: { loc: "rightLower", type: "incisor", num: "302" },
    _303: { loc: "rightLower", type: "incisor", num: "303" },
    _304: { loc: "rightLower", type: "canine", num: "304" },
    _305: { loc: "rightLower", type: "premolar", num: "305" },
    _306: { loc: "rightLower", type: "premolar", num: "306" },
    _307: { loc: "rightLower", type: "premolar", num: "307" },
    _308: { loc: "rightLower", type: "premolar", num: "308" },
    _309: { loc: "rightLower", type: "molar", num: "309" },
    _310: { loc: "rightLower", type: "molar", num: "310" },
    _311: { loc: "rightLower", type: "molar", num: "311" },
    _401: { loc: "leftLower", type: "incisor", num: "401" },
    _402: { loc: "leftLower", type: "incisor", num: "402" },
    _403: { loc: "leftLower", type: "incisor", num: "403" },
    _404: { loc: "leftLower", type: "canine", num: "404" },
    _405: { loc: "leftLower", type: "premolar", num: "405" },
    _406: { loc: "leftLower", type: "premolar", num: "406" },
    _407: { loc: "leftLower", type: "premolar", num: "407" },
    _408: { loc: "leftLower", type: "premolar", num: "408" },
    _409: { loc: "leftLower", type: "molar", num: "409" },
    _410: { loc: "leftLower", type: "molar", num: "410" },
    _411: { loc: "leftLower", type: "molar", num: "411" },
  },
};
