import React from "react";
import Tag from "components/elements/Tag";
import TagGroup from "components/elements/TagGroup";
import _ from "lodash";

function FetchedPostsTags(props) {
  function getItemIds(items) {
    return items.map((item) => item.id);
  }

  function getItemsByIds(items, ids) {
    return items.filter((item) => ids.indexOf(item.id) < 0);
  }

  const {
    priorityTags,
    blog: { tags, fetchedPostsTags },
  } = props;

  const filteredTags = fetchedPostsTags
    .map((t) => tags.find((a) => a.id === t))
    .filter((i) => i !== undefined);
  const priorityTagsRemoved = _.sortBy(
    getItemsByIds(filteredTags, getItemIds(priorityTags)),
    "name"
  );

  return (
    <>
      <div className="title is-6 has-text-grey">{props.title}</div>
      <TagGroup>
        {priorityTagsRemoved.map((t) => (
          <Tag
            key={t.id}
            label={t.name}
            onClick={() =>
              props.history.push(`/userhome/blog/posts_with_tag/${t.id}`)
            }
          />
        ))}{" "}
      </TagGroup>
    </>
  );
}

export default FetchedPostsTags;
