import React, { Component } from "react";
import Section from "components/layout/Section";
import LandingPageInfo from "./Landing_Page_Info";
import { returnNewObjectAsArrayByValidKey } from "functions";

class Landing extends Component {
  render() {
    const {
      messages,
      children,
      top,
      locale,
      menuIcon,
      routeName,
      ownImageProp,
      noTitle,
    } = this.props;

    const pageInfo = returnNewObjectAsArrayByValidKey(
      messages[routeName],
      "info_",
      locale
    );

    return (
      <Section>
        <div className="media" style={{ paddingBottom: noTitle ? 0 : 20 }}>
          {!ownImageProp && (
            <div className="media-left">
              <figure className="image is-32x32">
                <img src={menuIcon.img} alt={menuIcon.alt} />
              </figure>
            </div>
          )}
          {!noTitle && (
            <div className="media-content" style={{ height: 30 }}>
              <div className="title is-4 has-text-grey">
                {messages.landing.title[locale]}
              </div>
            </div>
          )}
        </div>
        {children && top && children}
        <LandingPageInfo pageInfo={pageInfo} noTitle={noTitle} />
        {children && !top && children}
      </Section>
    );
  }
}

export default Landing;
