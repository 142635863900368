import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchTdes } from "actions/training_diary";
import Section from "components/layout/Section";
import Message from "components/message";
import FetchEntries from "components/fetch";
import Table from "components/table_2";
import config from "./config";

class ViewEntries extends Component {
  handleFetchEntries = (value, unit, name) => {
    const { pet, providerView } = this.props;
    this.props.fetchTdes(value, unit, name, pet, providerView);
  };

  renderTableSection() {
    const { trainingDiary, messages, pet, defaultRoute, chartFunc } =
      this.props;
    const { entries } = trainingDiary;
    const entriesFiltered = entries.filter((entry) => entry._pet === pet._id);
    const filterByItems = [
      ...new Set(entriesFiltered.map((entry) => entry.trainingType)),
    ];

    // Create data set for table
    const tableData = {
      feature: "trainingDiary",
      pet: pet._id,
      id: Date.now(),
      headers: config.tableView.trainingDiary.headers,
      mobileHeaders: config.tableView.trainingDiary.mobileHeaders,
      data: entriesFiltered,
      messages: {
        ...messages.trainingTypes,
      },
      filterByItems,
      filterKey: "trainingType",
      viewEntryTo: `${defaultRoute}/view_entry/`,
      fetchDates: trainingDiary.fetchDates,
      fetchType: trainingDiary.fetchType,
    };

    if (entriesFiltered.length === 0) {
      return <Message warning message={"noEntriesToDisplayRetry"} />;
    }

    return (
      <div>
        <Table
          tableData={tableData}
          itemsPerPage={10}
          feature={config.name}
          withCharts
          chartFunc={chartFunc}
          filteredOnly
          ticks={{
            beginAtZero: true,
            ticks: {
              min: 1,
              max: 5,
              stepSize: 1,
            },
          }}
        />
      </div>
    );
  }

  render() {
    const { fetchType, lastFetchPetID } = this.props.trainingDiary;
    const { pet } = this.props;
    return (
      <>
        <FetchEntries
          fetchItems={this.handleFetchEntries}
          fetchType={fetchType}
          newPet={pet._id === lastFetchPetID}
          fetchButtons={["last7Days", "last30Days", "last3Months", "last1Year"]}
        />
        <Section>{this.renderTableSection()}</Section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchTdes,
      dispatch,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(ViewEntries);
