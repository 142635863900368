import React from "react"
import styled from "styled-components"

const Bubble = styled.div`
  z-index: 2;
  position: absolute;
  left: -5px;
  top: -5px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  padding: 5px;
`

const BubbleWrap = props => <Bubble>{props.children}</Bubble>

export default BubbleWrap
