import React, { Component } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import * as actions from "actions/award_cabinet"
import Section from "components/layout/Section"

import { AWARD_TYPES, COLOR_PALETTE, AWARD_TYPE_SETTINGS } from "./constants"
import TypeSelection from "./components/Type_Selection"
import AwardEditor from "./components/Award_Editor"
import MetaDataForm from "forms/Award_Meta_Data_Form"
import {
  MODALS_SHOW,
  MODALS_DISPLAY_COLOR_PICKER,
  MODALS_HIDE,
} from "actions/types"

const INITIAL_STATE = {
  selectedType: "",
  stage: 1,
  awardData: {},
}

class NewEntry extends Component {
  state = INITIAL_STATE

  handleTypeSelection = (selectedType) => {
    this.setState({
      selectedType,
      stage: 2,
      awardData: {
        type: selectedType,
        bling: _.omitBy(
          Object.assign(
            {},
            ...AWARD_TYPE_SETTINGS[selectedType].sections.map((item) => {
              if (item.toggleVis) {
                return {
                  [item.name]: false,
                }
              }
              return null
            })
          ),
          _.isNill
        ),
        colors: Object.assign(
          {},
          ...AWARD_TYPE_SETTINGS[selectedType].sections.map((item) => ({
            [item.name]: item.defaultColor,
          }))
        ),
      },
    })
  }

  showModal = ({ name }) => {
    this.props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_DISPLAY_COLOR_PICKER,
      modalProps: {
        ...this.state,
        ...this.props,
        handleColorPickerComplete: this.handleColorPickerComplete,
        name,
        colors: COLOR_PALETTE,
      },
    })
  }

  handleBlingChange = (name) => {
    this.setState((prevState) => ({
      awardData: {
        ...prevState.awardData,
        bling: {
          ...prevState.awardData.bling,
          [name]: !prevState.awardData.bling[name],
        },
      },
    }))
  }

  handleColorPickerComplete = (color, name) => {
    this.setState((prevState) => ({
      awardData: {
        ...prevState.awardData,
        colors: {
          ...prevState.awardData.colors,
          [name]: color.hex,
        },
      },
    }))
    this.props.dispatch({
      type: MODALS_HIDE,
    })
  }

  handleConfirmStage2 = () => {
    this.setState((prevState) => ({
      ...prevState,
      stage: 3,
    }))
  }

  handleCancel = (e) => {
    e.preventDefault()
    this.setState(INITIAL_STATE)
  }

  handleGoBack = () => {
    this.setState((prevState) => ({
      ...prevState,
      stage: prevState.stage - 1,
    }))
  }

  handleSubmit = (formData, setSubmitting) => {
    const { pet, history, defaultRoute } = this.props
    const values = {
      ...formData,
      ...this.state.awardData,
      _pet: pet._id,
      petType: pet.petType,
    }
    const successRoute = `${defaultRoute}/view_entries`
    this.props.addAward(values, setSubmitting, history, successRoute)
  }

  renderStage = () => {
    switch (this.state.stage) {
      case 1:
        return (
          <TypeSelection
            id="type_selection"
            {...this}
            types={AWARD_TYPES}
            imgSize="is-1by1"
          />
        )
      case 2:
        return (
          <AwardEditor
            {...this.props}
            handleCancel={this.handleCancel}
            handleGoBack={this.handleGoBack}
            handleConfirmStage2={this.handleConfirmStage2}
            handleBlingChange={this.handleBlingChange}
            showModal={this.showModal}
            awardData={this.state.awardData}
            type={this.state.selectedType}
            stage={this.state.stage}
          />
        )
      case 3:
        return (
          <MetaDataForm
            {...this.props}
            handleSubmit={this.handleSubmit}
            awardData={this.state.awardData}
            type={this.state.selectedType}
            stage={this.state.stage}
          />
        )
      default:
      // do nothing
    }
  }

  render() {
    return <Section>{this.renderStage()}</Section>
  }
}

export default connect(null, actions)(NewEntry)
