import { InputFieldV5, TextareaV5, ImageButtonInputV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "time",
    group: "common",
    type: "time",
    icon: "far fa-clock",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "subject",
    group: "common",
    type: "text",
    icon: "fas fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "stressLevel",
    group: "stressLevel",
    component: ImageButtonInputV5,
    buttons: [
      {
        imgPath: "components.buttons.stressLevel1",
        imgClassName: "is-rounded",
        figureSize: "48x48",
        figureClassName: "",
        value: 1,
        showValue: true,
      },
      {
        imgPath: "components.buttons.stressLevel2",
        imgClassName: "is-rounded",
        figureSize: "48x48",
        figureClassName: "",
        value: 2,
        showValue: true,
      },
      {
        imgPath: "components.buttons.stressLevel3",
        imgClassName: "is-rounded",
        figureSize: "48x48",
        figureClassName: "",
        value: 3,
        showValue: true,
      },
      {
        imgPath: "components.buttons.stressLevel4",
        imgClassName: "is-rounded",
        figureSize: "48x48",
        figureClassName: "",
        value: 4,
        showValue: true,
      },
      {
        imgPath: "components.buttons.stressLevel5",
        imgClassName: "is-rounded",
        figureSize: "48x48",
        figureClassName: "",
        value: 5,
        showValue: true,
      },
    ],
    required: true,
    userCanEdit: true,
  },
  {
    name: "notes",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    userCanEdit: true,
    charLimit: 250,
  },
];

export { INPUT_FIELDS };
