import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import FEATURES from "constants/routes"
import config from "./config"
import SmartTile from "components/SmartTile"

function LitterDashboardFeatures(props) {
  const [featureOrderSet, setFeatureOrderState] = useState(false)
  const [featureOrder, addArrayOfFeatures] = useState([])

  useEffect(() => {
    function setFeatureOrder() {
      let features = config.featureOrder.map((name) =>
        FEATURES.find((feature) => feature.name === name)
      )
      addArrayOfFeatures(features)
      setFeatureOrderState(true)
    }
    setFeatureOrder()
  }, [])

  if (!featureOrderSet) return null
  return (
    <>
      {featureOrder.map((feature, i) => {
        if (!feature.litterFeature) return null
        return (
          <div key={feature.name} className={config.layout.column}>
            <div style={{ height: "100%" }}>
              <SmartTile featureTile {...feature} />
            </div>
          </div>
        )
      })}
    </>
  )
}

const mapStateToProps = (state) => {
  const arr = [
    state.reducers.auth.user.trialPeriod,
    state.reducers.auth.user.subscription,
  ]
  return {
    userHasSubOrTrial: arr.includes(true),
  }
}

export default connect(mapStateToProps)(LitterDashboardFeatures)
