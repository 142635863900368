import { features } from "assets"
import Links from "./Links"
import { path } from "constants/feature_routes_provider_view"

const usefulLinksProviderConfig = {
  name: "usefulLinks",
  type: "feature",
  defaultRoute: path("useful_links"),
  menuIcon: { img: features.usefulLinks.main, alt: "useful_links_main_icon" },
  providerView: true,
  providerEdit: true,
  noNav: true,
  nav: [
    {
      disabled: false,
      name: "links",
      menuItem: false,
      image: { img: features.usefulLinks.main, alt: "useful_links_main_icon" },
      icon: "fas fa-copy",
      exact: true,
      component: Links,
      path: "/",
    },
  ],
}

export default usefulLinksProviderConfig
