import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import Bounds from "components/bounds"
import MenuDesktop from "./Menu_Desktop"
import MenuMobile from "./Menu_Mobile"

class Menu extends Component {
  render() {
    return (
      <Bounds
        maxWidth="1023"
        render={() => <MenuMobile {...this.props} />}
        else={() => <MenuDesktop {...this.props} />}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const selectedLitterMember = _.isEmpty(
    state.reducers.litters.selectedLitterMember
  )

  return {
    locale: state.intl.locale,
    pet: !selectedLitterMember
      ? state.reducers.litters.selectedLitterMember
      : state.reducers.pets.selectedPet,
    actionCalls: state.reducers.actionCalls,
    user: state.reducers.auth.user,
    isAuthed: state.reducers.auth.isAuthenticated,
  }
}

export default connect(mapStateToProps)(withRouter(Menu))
