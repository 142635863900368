import axios from "axios";
import { ACTION_STARTED, ACTION_SUCCESS, MODALS_HIDE } from "actions/types";
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "actions/functions";

export const APPOINTMENTS_FETCH = "appointments_fetch";
export const APPOINTMENTS_FETCH_ALL_FOR_PROVIDER =
  "appointments_fetch_all_for_provider";
export const APPOINTMENTS_ADD = "appointments_add";
export const APPOINTMENTS_UPDATE = "appointments_update";
export const APPOINTMENTS_DELETE = "appointments_delete";
export const APPOINTMENTS_CONFIRM_BY_USER = "appointments_confirm_by_user";
export const APPOINTMENTS_DECLINE_BY_USER = "appointments_decline_by_user";
export const APPOINTMENTS_CANCEL_BY_USER = "appointments_cancel_by_user";

const API = (route) => `/api/appointments/${route}`;

export const fetchAppointmentsEntries =
  (_pet, providerView) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: APPOINTMENTS_FETCH });

      const res = await axios.get(
        API(providerView ? "fetch_for_provider" : "fetch"),
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
          params: { _pet },
        }
      );
      dispatch({
        type: APPOINTMENTS_FETCH,
        payload: res.data.appointmentsEntries,
        _pet,
      });
      endAndResetAction(dispatch);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const fetchAllAppointmentsForProvider = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: APPOINTMENTS_FETCH_ALL_FOR_PROVIDER,
    });

    const res = await axios.get(API("fetch_all_for_provider"), {
      headers: {
        authorization: localStorage.getItem("tokenProvider"),
      },
    });
    dispatch({
      type: APPOINTMENTS_FETCH_ALL_FOR_PROVIDER,
      payload: res.data.appointmentsEntries,
    });
    endAndResetAction(dispatch);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const addAppointmentsEntry =
  (
    values,
    setSubmitting,
    history,
    successRoute,
    providerContact,
    providerView
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: APPOINTMENTS_ADD });
      const res = await axios.post(
        API(providerView ? "add_by_provider" : "add"),
        { _user: providerView ? providerContact._id : undefined, ...values },
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      dispatch({ type: APPOINTMENTS_ADD, payload: res.data.appointmentsEntry });
      endAndResetAction(dispatch);
      history.push(successRoute);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const updateAppointmentsEntry =
  (values, setSubmitting, providerView) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: APPOINTMENTS_UPDATE });
      const res = await axios.put(
        API(providerView ? "update_by_provider" : "update"),
        values,
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      dispatch({
        type: ACTION_SUCCESS,
        payload: "updateSuccess",
      });
      dispatch({
        type: APPOINTMENTS_UPDATE,
        payload: res.data.appointmentsEntry,
      });
      endAndResetActionWithDelay(dispatch, 1500);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const deleteAppointmentsEntry =
  (_id, history, successRoute, providerView) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: APPOINTMENTS_DELETE });
      dispatch({ type: MODALS_HIDE });
      const res = await axios.put(
        API(providerView ? "delete_by_provider" : "delete"),
        { _id },
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      dispatch({
        type: ACTION_SUCCESS,
        payload: "deleteSuccess",
      });
      dispatch({
        type: APPOINTMENTS_DELETE,
        payload: res.data.appointmentsEntry,
      });
      endAndResetActionWithDelay(dispatch, 1500);
      history.push(successRoute);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const confirmAppointment = (_id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: APPOINTMENTS_CONFIRM_BY_USER });
    dispatch({ type: MODALS_HIDE });
    const res = await axios.put(
      API("confirm"),
      { _id },
      {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      }
    );
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    });
    dispatch({
      type: APPOINTMENTS_UPDATE,
      payload: res.data.appointmentsEntry,
    });
    endAndResetActionWithDelay(dispatch, 1500);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const delcineAppointment = (values) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: APPOINTMENTS_DECLINE_BY_USER });
    dispatch({ type: MODALS_HIDE });
    const res = await axios.put(API("decline"), values, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    });
    dispatch({
      type: APPOINTMENTS_UPDATE,
      payload: res.data.appointmentsEntry,
    });
    endAndResetActionWithDelay(dispatch, 1500);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const cancelAppointment = (values) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: APPOINTMENTS_CANCEL_BY_USER });
    dispatch({ type: MODALS_HIDE });
    const res = await axios.put(API("cancel"), values, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    });
    dispatch({
      type: APPOINTMENTS_UPDATE,
      payload: res.data.appointmentsEntry,
    });
    endAndResetActionWithDelay(dispatch, 1500);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

const appointmentsActions = {
  fetchAppointmentsEntries,
  fetchAllAppointmentsForProvider,
  addAppointmentsEntry,
  updateAppointmentsEntry,
  deleteAppointmentsEntry,
  confirmAppointment,
  delcineAppointment,
  cancelAppointment,
};

export default appointmentsActions;
