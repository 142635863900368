import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TileWrapper from "./TileWrapper";
import Header from "./Header";
import TileBody from "./TileBody";
import { pets } from "assets";
import RequiresSubOrTrialNotice from "components/styled/Requires_Sub_Or_Trial_Notice";

function AddPetTile(props) {
  return (
    <TileWrapper>
      <Header title={props.messages.titles.add[props.locale]} isCentered />
      <Link
        to={
          props.requiresSubOrTrial || props.requiresSetupSubscription
            ? "/userhome/user_account/subscription"
            : "/userhome/add_pet"
        }
      >
        {props.requiresSetupSubscription || props.requiresSubOrTrial ? (
          <RequiresSubOrTrialNotice requiresSubOrTrial />
        ) : (
          <div />
        )}
        <TileBody img={pets.addPet} />
      </Link>
    </TileWrapper>
  );
}

const mapStateToProps = (state) => {
  const arr = [
    state.reducers.auth.user.trialPeriod,
    state.reducers.auth.user.subscription,
  ];
  return {
    requiresSubOrTrial: !arr.includes(true),
    requiresSetupSubscription: state.reducers.auth.user.signupStage === 1,
    user: state.reducers.auth.user,
    locale: state.intl.locale,
    messages: state.intl.messages.containers.pets.addPet,
  };
};

export default connect(mapStateToProps)(AddPetTile);
