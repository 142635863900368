import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "actions/litter_expenses";
import * as Yup from "yup";
import moment from "moment";
import FormButtons from "components/form/FormButtons";
import { INPUT_FIELDS } from "./fields/litter_expense";
import ExpenseFormSummaryBox from "components/form/Expense_Form_Summary_Box";

class LitterExpenseForm extends Component {
  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          values={values}
          viewMode={viewMode}
          editMode={editMode}
          {...field}
        />
      );
    });
  }

  render() {
    const { errors, dirty, isSubmitting, editMode, viewMode, cancel } =
      this.props;
    return (
      <>
        <ExpenseFormSummaryBox {...this.props.values} />
        <br />
        <Form>
          {this.renderFields()}
          <br />
          <FormButtons
            viewMode={viewMode}
            editMode={editMode}
            isSubmitting={isSubmitting}
            errors={errors}
            dirty={dirty}
            cancel={cancel}
            linkTo={"/userhome/dashboard/litter/expenses"}
          />
          <br />
        </Form>
      </>
    );
  }
}

LitterExpenseForm = withFormik({
  mapPropsToValues({ valuesToEdit, _litter, _pet }) {
    return {
      date: moment(valuesToEdit.date).format("YYYY-MM-DD") || "",
      desc: valuesToEdit.desc || "",
      price: valuesToEdit.price || 0,
      total: valuesToEdit.total || 0,
      taxPercentage: valuesToEdit.taxPercentage || 0,
      taxAmount: valuesToEdit.taxAmount || 0,
      amount: valuesToEdit.amount || 1,
      receiptLink: valuesToEdit.receiptLink || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || _pet,
      _litter: valuesToEdit._litter || _litter,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    desc: Yup.string().max(50, "max-50").required("required"),
    price: Yup.number().required("required"),
    taxPercentage: Yup.number(),
    amount: Yup.number().required("required"),
    receiptLink: Yup.string().url("url"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const total = Number((values.price * values.amount).toFixed(2));
    const valuesWithTotal = { ...values, total };
    if (props.editMode) {
      return props.litterExpensesUpdate(valuesWithTotal, setSubmitting, () =>
        props.history.push("/userhome/dashboard/litter/expenses/view_expenses")
      );
    }
    props.litterExpensesAdd(valuesWithTotal, setSubmitting, () =>
      props.history.push("/userhome/dashboard/litter/expenses/view_expenses")
    );
  },
})(LitterExpenseForm);

export default withRouter(connect(null, actions)(LitterExpenseForm));
