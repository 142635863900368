import main from "./heat_diary.svg"
import newEntry from "./heat_diary_new_entry.svg"
import newEntryCycle from "./heat_diary_new_cycle_entry.svg"
import newEntryProg from "./heat_diary_new_prog_entry.svg"
import newEntryBreeding from "./heat_diary_new_breeding_date.svg"
import viewEntriesCycle from "./heat_diary_view_cycle_entries.svg"
import viewEntriesProg from "./heat_diary_view_prog_entries.svg"
import viewEntriesBreeding from "./heat_diary_view_breeding_dates.svg"

const heatDiary = {
  main,
  newEntry,
  newEntryCycle,
  newEntryProg,
  newEntryBreeding,
  viewEntriesCycle,
  viewEntriesProg,
  viewEntriesBreeding,
}

export default heatDiary
