import React from "react";
import Message from "components/message";

const LandingPageInfo = ({ pageInfo, noTitle }) => {
  if (noTitle)
    return pageInfo.map((item, i) => {
      if (!item) return null;
      return <p key={i}>{item}</p>;
    });
  return (
    <Message landing>
      {pageInfo.map((item, i) => {
        if (!item) return null;
        return <p key={i}>{item}</p>;
      })}
    </Message>
  );
};

export default LandingPageInfo;
