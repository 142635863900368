import React from 'react';
import { TOOTH_MAPS } from "constants/tooth_maps";
import { shortDateForDisplay } from "functions";
import assets from "../assets";

export default function ToothDetail(props) {
  const { detailIndex } = props;
  return (
    <div style={{ marginBottom: 8 }}>
      {detailIndex === 0 && <Header {...props} />}
      <Detail {...props} />
    </div>
  );
}

const Header = ({
  petType,
  toothId,
  toothState,
  detailIndex,
  onClick,
  labels,
  states,
  locale,
}) => (
  <div className="card">
    <div className="card-content">
      <div className="media">
        <div className="media-left">
          <figure className="image is-32x32">
            {detailIndex === 0 && (
              <>
                <img
                  src={assets[petType].individuals[toothId]}
                  alt="individual tooth"
                />
                <div className="is-size-7-mobile has-text-centered">
                  {TOOTH_MAPS[petType][toothId].num}
                </div>
              </>
            )}
          </figure>
        </div>
        <div className="media-content">
          {detailIndex === 0 && (
            <div
              style={{
                borderBottom: "1px solid #c9c9c9",
                paddingBottom: 12,
              }}
            >
              <div className="columns is-mobile title is-6 has-text-centered is-uppercase is-marginless">
                <div className="column">
                  <div className="is-size-7-mobile">
                    {labels[TOOTH_MAPS[petType][toothId].loc][locale]}
                  </div>
                </div>
                <div className="column">
                  <div className="is-size-7-mobile">
                    {labels[TOOTH_MAPS[petType][toothId].type][locale]}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

const Detail = ({
  petType,
  toothId,
  toothState,
  detailIndex,
  onClick,
  labels,
  states,
  locale,
  date,
}) => (
  <div className="box" style={{ padding: 12 }}>
    <div className="columns is-mobile is-marginless">
      <div className="column is-4 has-text-weight-medium">
        {shortDateForDisplay(date)}:{" "}
      </div>
      <div className="is-6" onClick={onClick} style={{ cursor: "pointer" }}>
        <div className="column">{states[toothState][locale]}</div>
      </div>
    </div>
  </div>
);
