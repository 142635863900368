import main from "./fmpi.svg"
import newQuery from "./fmpi_new_entry.svg"
import viewResults from "./fmpi_view_entries.svg"

const fmpi = {
  main,
  newQuery,
  viewResults,
}
export default fmpi
