import {
  STRIPE_CREATE_PAYMENT_INTENT,
  STRIPE_UPDATE_PAYMENT_INTENT,
  STRIPE_CARD_SETUP,
} from "actions/types";

const INITIAL_STATE = {};

export default function StripeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case STRIPE_CREATE_PAYMENT_INTENT:
      return {
        ...state,
        clientSecret: action.payload.paymentIntent.client_secret,
      };

    case STRIPE_UPDATE_PAYMENT_INTENT:
      return state;
    case STRIPE_CARD_SETUP:
      return state;
    default:
      return state;
  }
}
