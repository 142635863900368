import React from "react";
import FOOD_CATEGORIES from "constants/select_options";

const FoodCategories = (props) => {
  return (
    <div className="columns is-multiline is-centered is-mobile">
      {FOOD_CATEGORIES.foodDiary.foodCategory.categorySelection.map(
        (category, i) => {
          if (category.petTypes.includes(props.pet.petType)) {
            return <Category key={i} {...props} {...category} />;
          }
          return null;
        }
      )}
    </div>
  );
};

const Category = (props) => {
  return (
    <div className="column is-narrow is-6-mobile">
      <div
        style={{ cursor: "pointer", padding: 6 }}
        className="box"
        onClick={() => props.handleCategorySelection(props.name)}
      >
        <div
          style={{ justifyContent: "center", padding: 0 }}
          className="card-content is-flex"
        >
          <figure className="image is-96x96">
            <img src={props.icon} alt={props.name} />
          </figure>
        </div>
        <p className="heading has-text-centered is-uppercase">
          {props.messages.foodCategories[props.name][props.locale]}
        </p>
      </div>
    </div>
  );
};

export default FoodCategories;
