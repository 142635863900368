import React from "react";
import { connect } from "react-redux";
import {
  MODALS_CONFIRM_DELETE,
  MODALS_DISPLAY_COLOR_PICKER,
  MODALS_CONFIRM_EMAIL,
  MODALS_BENEFITS_INFO,
  MODALS_MORE_INFO,
  MODALS_BUSY_LOADING,
  MODALS_CONTACTS_INFO,
  MODALS_UPDATE_DOCUMENT,
  MODALS_CONFIRM_APPOINTMENT,
  MODALS_BCS_DESCRIPTION,
  MODALS_BODY_MARKER_DETAIL,
  MODALS_CONFIRM_ARCHIVE,
  MODALS_TOOTH_MARKER_DETAIL,
  MODALS_CONFIRM_PROVIDER_FIELDS,
} from "actions/types";

import ConfirmDelete from "./Confirm_Delete";
import ColorPicker from "./Color_Picker";
import ConfirmEmail from "./Confirm_Email";
import BenefitsInfo from "./Benefits_Info";
import ContactsInfo from "./Contacts_Info";
import MoreInfo from "./More_Info";
import BusyLoading from "./Busy_Loading";
import UpdateDocument from "./Update_Document";
import ConfirmAppointmentNewEntry from "./Confirm_Appointment_New_Entry";
import BodyConditionScoreModal from "./BodyConditionScoreModal";
import BodyMarkerDetailModal from "./BodyMarkerDetailModal";
import ConfirmArchive from "./Confirm_Archive";
import ToothMarkerDetailModal from "./ToothMarkerDetailModal";
import ConfirmProviderFields from "./ConfirmProviderFields";

const ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }
  switch (modalType) {
    case MODALS_CONFIRM_DELETE:
      return <ConfirmDelete {...modalProps} />;
    case MODALS_DISPLAY_COLOR_PICKER:
      return <ColorPicker {...modalProps} />;
    case MODALS_CONFIRM_EMAIL:
      return <ConfirmEmail {...modalProps} />;
    case MODALS_CONFIRM_PROVIDER_FIELDS:
      return <ConfirmProviderFields {...modalProps} />;
    case MODALS_BENEFITS_INFO:
      return <BenefitsInfo {...modalProps} />;
    case MODALS_MORE_INFO:
      return <MoreInfo {...modalProps} />;
    case MODALS_BUSY_LOADING:
      return <BusyLoading {...modalProps} />;
    case MODALS_CONTACTS_INFO:
      return <ContactsInfo {...modalProps} />;
    case MODALS_UPDATE_DOCUMENT:
      return <UpdateDocument {...modalProps} />;
    case MODALS_CONFIRM_APPOINTMENT:
      return <ConfirmAppointmentNewEntry {...modalProps} />;
    case MODALS_BCS_DESCRIPTION:
      return <BodyConditionScoreModal {...modalProps} />;
    case MODALS_BODY_MARKER_DETAIL:
      return <BodyMarkerDetailModal {...modalProps} />;
    case MODALS_CONFIRM_ARCHIVE:
      return <ConfirmArchive {...modalProps} />;
    case MODALS_TOOTH_MARKER_DETAIL:
      return <ToothMarkerDetailModal {...modalProps} />;
    default:
      return;
  }
};

export default connect((state) => state.reducers.modals)(ModalRoot);
