import {
  LITTER_EXPENSES_ADD,
  LITTER_EXPENSES_FETCH,
  LITTER_EXPENSES_UPDATE,
  LITTER_EXPENSES_DELETE,
  AUTH_LOG_OUT_USER,
} from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";
import _ from "lodash";

const INITIAL_STATE = {
  fetchedFor: [],
  data: [],
};

export default function LitterExpensesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LITTER_EXPENSES_FETCH:
      return {
        ...state,
        fetchedFor: [...state.fetchedFor, action.payload._litter],
        data: _.uniqWith(
          _.sortBy([...state.data, ...action.payload.data], "date").reverse(),
          _.isEqual
        ),
      };
    case LITTER_EXPENSES_ADD:
      return {
        ...state,
        data: _.sortBy([...state.data, action.payload], "date").reverse(),
      };
    case LITTER_EXPENSES_UPDATE:
      return {
        ...state,
        data: _.sortBy(
          [
            ...state.data.filter((obj) => obj._id !== action.payload._id),
            action.payload,
          ],
          "date"
        ).reverse(),
      };
    case LITTER_EXPENSES_DELETE:
      return {
        ...state,
        data: state.data.filter((entry) => entry._id !== action.payload),
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
