import React from "react";
import { connect } from "react-redux";
import FormValidationMessageV5 from "./FormValidationMessageV5";

const CheckboxV5 = (props) => {
  const {
    field,
    form,
    group,
    type,
    viewMode,
    editMode,
    userCanEdit,
    label,
    checkboxText,
    withLink,
    links,
    labelOnly,
    required,
  } = props;
  return (
    <div className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">
          {label}
          {!viewMode && required && "*"}
        </div>
      </label>
      <div className="control">
        <label>
          <input
            {...field}
            type={type}
            checked={field.value}
            disabled={viewMode || (!userCanEdit && editMode) ? true : false}
          />
          {!labelOnly && <span className="is-size-7"> {checkboxText}</span>}
        </label>
        {withLink && (
          <a
            style={{
              textDecoration: "none",
              color: "black",
            }}
            rel="noopener noreferrer"
            href={links[field.name]}
            target="_blank"
          >
            {" "}
            <div className="icon">
              <i className={"fas fa-link"} />
            </div>
          </a>
        )}
      </div>
      <FormValidationMessageV5
        clientMessage
        group={group}
        name={field.name}
        errors={form.errors}
        touched={form.touched}
      />
    </div>
  );
};

const mapStateToProps = (state, { noLabel, labelOnly, group, field }) => {
  const { locale, messages } = state.intl;
  return {
    label: noLabel
      ? null
      : messages.components.forms.labels[group][field.name][locale],
    checkboxText: labelOnly
      ? null
      : messages.components.forms.checkboxes[group][field.name][locale],
    links: state.reducers.appData.footerLinks,
  };
};

export default connect(mapStateToProps)(CheckboxV5);
