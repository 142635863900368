import {
  PROVIDER_FETCH_PAIN_TRACKER,
  PROVIDER_FETCH_WEIGHT_TRACKER,
} from "./actions";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";
import { AUTH_LOG_OUT_USER } from "actions/types";

const INITIAL_STATE = {
  painData: [],
  weightData: [],
};

export default function ProviderDashboard(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PROVIDER_FETCH_PAIN_TRACKER:
      return {
        ...state,
        painData: action.payload.painData,
      };
    case PROVIDER_FETCH_WEIGHT_TRACKER:
      return {
        ...state,
        weightData: action.payload.weightData,
      };
    case AUTH_LOG_OUT_PROVIDER:
    case AUTH_LOG_OUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
