import {
  InputFieldV5,
  TaxPercentage,
  TaxAmount,
  UrlInputLinkV5,
} from "components/form";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "fas fa-calculator",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "desc",
    group: "common",
    type: "text",
    icon: "fas fa-calculator",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "price",
    group: "litterExpenses",
    type: "number",
    icon: "fas fa-calculator",
    className: "input ",
    component: InputFieldV5,
    required: true,
    min: 0.01,
    step: 0.01,
    userCanEdit: true,
  },
  {
    name: "taxPercentage",
    group: "litterExpenses",
    type: "number",
    icon: "fas fa-percent",
    className: "input ",
    component: TaxPercentage,
    required: false,
    min: 0,
    step: 0.01,
    userCanEdit: true,
  },
  {
    name: "taxAmount",
    group: "litterExpenses",
    type: "number",
    icon: "fas fa-calculator",
    className: "input ",
    component: TaxAmount,
    required: false,
    min: 0,
    step: 0.01,
  },
  {
    name: "amount",
    group: "litterExpenses",
    type: "number",
    icon: "fas fa-calculator",
    className: "input ",
    component: InputFieldV5,
    required: true,
    min: 0.01,
    step: 0.01,
    userCanEdit: true,
  },
  {
    name: "receiptLink",
    group: "litterExpenses",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: UrlInputLinkV5,
    required: false,
    userCanEdit: true,
  },
];

export { INPUT_FIELDS };
