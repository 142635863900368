import React from 'react';
import { useState } from "react";
import { connect } from "react-redux";
import ToothMarkerDetailForm from "containers/features/Tooth_Marker/forms/Tooth_Marker_Detail_Form";
import EditToothMarkerDetailForm from "containers/features/Tooth_Marker/forms/Edit_Tooth_Marker_Detail_Form";
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons";
import ToothDetailFull from "containers/features/Tooth_Marker/components/ToothDetailFull";

function ToothMarkerDetailModal(props) {
  const [viewMode, setViewMode] = useState(props.viewMode);
  const [editMode, setEditMode] = useState(props.editMode);

  function handleEditClick() {
    setViewMode((prevState) => !prevState);
    setEditMode((prevState) => !prevState);
  }

  function handleCancel() {
    if (editMode) {
      handleEditClick();
    } else {
      props.cancel();
    }
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <section className="modal-card-body has-text-dark">
          <div
            className="has-text-right"
            style={{ fontSize: 16, cursor: "pointer" }}
          >
            <div className="icon is-large">
              <i
                onClick={() => props.handleModalClose()}
                className="far fa-times-circle fa-2x"
              ></i>
            </div>
          </div>
          {viewMode && (
            <BackEditDeleteButtons
              backButtonFunc
              handleBackClick={() => props.handleModalClose()}
              handleEditClick={handleEditClick}
              handleDeleteClick={() =>
                props.handleDeleteButtonPressed(props.valuesToEdit._id)
              }
              providerView={props.providerView}
              providerEdit={props.providerEdit}
            />
          )}
          {viewMode ? (
            <ToothDetailFull
              {...props.valuesToEdit}
              labels={props.messages.labels}
              states={props.messages.states}
              locale={props.locale}
            />
          ) : props.addDetail ? (
            <ToothMarkerDetailForm
              confirmationRequired={props.confirmationRequired}
              valuesToEdit={props.valuesToEdit || {}}
              toothId={props.toothId}
              pet={props.pet}
              editMode={editMode}
              viewMode={viewMode}
              cancel={handleCancel}
              providerView={props.providerView}
              providerContact={props.providerContact}
            />
          ) : (
            <EditToothMarkerDetailForm
              confirmationRequired={props.confirmationRequired}
              valuesToEdit={props.valuesToEdit}
              toothId={props.toothId}
              cancel={handleCancel}
              providerView={props.providerView}
              providerContact={props.providerContact}
            />
          )}
        </section>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    pet: state.reducers.pets.selectedPet,
  };
};

export default connect(mapStateToProps)(ToothMarkerDetailModal);
