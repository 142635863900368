import React from "react";
import { returnNewObjectAsArrayByValidKey } from "functions";

function BodyText({ messages, noDefault, ownBodyText, type, locale }) {
  function getBodyText(key) {
    const bodyText = returnNewObjectAsArrayByValidKey(messages, key, locale);
    return bodyText;
  }

  if (!noDefault)
    return getBodyText("default_").map((t, i) => (
      <p key={i} className="content">
        {t}
      </p>
    ));

  if (ownBodyText) {
    return getBodyText(type).map((t, i) => (
      <p key={i} className="content">
        {t}
      </p>
    ));
  }

  return null;
}

export default BodyText;
