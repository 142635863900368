import React, { useState } from "react";
import Button from "components/elements/Button";

export default function MessageText({ text, readMore }) {
  const [showAll, setShowAll] = useState(false);

  function handleOnClick() {
    setShowAll((prevState) => !prevState);
  }

  if (Array.isArray(text)) {
    return (
      <>
        {text.map((item, i) => {
          if (readMore && !showAll && i > 0) return null;
          return <p key={i}>{item}</p>;
        })}
        {readMore && (
          <Button
            className="button is-link is-outlined is-small"
            onClick={handleOnClick}
            group="common"
            name={showAll ? "showLess" : "showMore"}
          />
        )}
      </>
    );
  }

  return <p>{text}</p>;
}
