import React, { Component } from "react"
import { connect } from "react-redux"
import Section from "components/layout/Section"
import Title from "components/elements/Title"
//import Message from "components/message"

class DeleteAccount extends Component {
  render() {
    return (
      <>
        <Section hr>
          <Title title={"title"} subtitle={"subtitle"} />
        </Section>
        <Section>
          {/* <Message message={"This feature is coming soon!"} color={"is-info"} /> */}
        </Section>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

export default connect(mapStateToProps)(DeleteAccount)
