import React, { Component } from "react"
import { connect } from "react-redux"
import Menu from "components/menu"
import config from "./config"

class StableDiary extends Component {
  render() {
    return <Menu {...config} {...this.props} />
  }
}

const mapStateToProps = state => {
  return {
    messages: state.intl.messages.containers.features.stableDiary,
    stableDiary: state.reducers.stableDiary
  }
}

export default connect(mapStateToProps)(StableDiary)
