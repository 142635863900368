import React from "react";

export default function TeethCat(props) {
  const { onClick, handleToothState, ...rest } = props;
  return (
    <svg
      id="cat"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 263.7 385.04"
      width="263.7"
      height="385.04"
      {...rest}
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".cls-1{fill:none;stroke:#999;}.cls-1,.cls-2{stroke-miterlimit:10;}.cls-2{fill:#f9f7e1;stroke:#333;cursor:pointer;}.cls-3{font-size:14px;font-family:SegoeUI, Segoe UI;}.cls-4{font-size:10px;fill:#4d4d4d;font-family:SegoeUI-Semibold, Segoe UI;font-weight:600;}",
          }}
        />
      </defs>
      <g id="jaw">
        <path
          className="cls-1"
          d="M132.09,23c-7.41-1.22-15.64-1.4-19,3.17-3.18,4.28.15,9.68-3.17,12.68-4,3.63-11.8-3.74-20.08,0A15.14,15.14,0,0,0,84.52,43c-12.58,14.32-1,47.79-11.62,53.9-4.51,2.6-7.83-2.73-16.92-1.05-9.3,1.71-16.35,11.25-22.16,18A182.87,182.87,0,0,0,8.42,153C3,164,2.58,167.48,4.19,169.86c3.75,5.55,12.07,4.1,17.3,1.89a15.7,15.7,0,0,0,7.39-5.67c1.8-2.71,2.48-6.72,6-7.84,5.54-1.78,10,5.4,20.09,9.51a41.19,41.19,0,0,0,22.19,2.12C83,154.5,88.74,154,89.81,154c7.16-.18,14.85,14,19,35.94,0,0,3.16-9.12,9.52-13.74,4.58-3.34,10.13-3.47,13.74-3.17Z"
        />
        <path
          className="cls-1"
          d="M132.33,23c7.41-1.22,15.63-1.4,19,3.17,3.18,4.28-.15,9.68,3.17,12.68,4,3.63,11.8-3.74,20.09,0A15.09,15.09,0,0,1,179.89,43c12.59,14.32,1,47.79,11.63,53.9,4.5,2.6,7.82-2.73,16.91-1.05,9.3,1.71,16.36,11.25,22.16,18A182.11,182.11,0,0,1,256,153c5.38,11,5.84,14.53,4.23,16.91-3.75,5.55-12.07,4.1-17.29,1.89a15.68,15.68,0,0,1-7.4-5.67c-1.8-2.71-2.48-6.72-6-7.84-5.54-1.78-10,5.4-20.08,9.51a41.21,41.21,0,0,1-22.2,2.12C181.42,154.5,175.67,154,174.61,154c-7.17-.18-14.86,14-19,35.94,0,0-3.16-9.12-9.51-13.74-4.59-3.34-10.13-3.47-13.74-3.17Z"
        />
        <path
          className="cls-1"
          d="M80.09,204.43c13.07,30.56,26.84,61.81,34.24,94.34,1.68,7.37,3.92,16.52,11.76,19.66a42.47,42.47,0,0,0,8,2v31.5s-3.63.11-6.54,0c-4-.16-9.45.45-12.73-2.32a16.42,16.42,0,0,1-4-5.73c-3.16-6.79-4.89-14.05-8.3-20.73C93.78,306,83.8,289.27,75.66,271.81a291.48,291.48,0,0,1-12.57-32.38c-4-11.83-6.87-22.36-9-31"
        />
        <path
          className="cls-1"
          d="M188.47,204.43c-13.07,30.56-26.83,61.81-34.23,94.34-1.68,7.37-3.92,16.52-11.77,19.66a43.42,43.42,0,0,1-8.38,2v31.5s4,.11,6.92,0c4.05-.16,9.46.45,12.73-2.32a16.45,16.45,0,0,0,4-5.73c3.16-6.79,4.88-14.05,8.3-20.73,8.75-17.18,18.74-33.88,26.88-51.34a289.18,289.18,0,0,0,12.56-32.38c4-11.83,6.88-22.36,9-31"
        />
      </g>
      <g id="upper">
        <g id="incisors">
          <g id="right">
            <path
              id="_103"
              data-name={103}
              onClick={(e) => onClick(e, true)}
              style={props.handleToothState("_103")}
              className="cls-2"
              d="M113.09,28.43c1-1,2.79-1.61,4-1,3,1.48,2.4,10.19,0,11-1.55.52-4.32-2.11-5-5C112,32.86,111.32,30.14,113.09,28.43Z"
            />
            <path
              id="_102"
              data-name={102}
              onClick={(e) => onClick(e, true)}
              style={props.handleToothState("_102")}
              className="cls-2"
              d="M124.09,25.43c-1.32-1.06-4-.56-5,1-.83,1.24-.37,2.76,0,4,.67,2.21,2.49,5.26,4,5,1.67-.3,2.17-4.53,2-7C125,27.19,124.92,26.09,124.09,25.43Z"
            />
            <path
              id="_101"
              data-name={101}
              onClick={(e) => onClick(e, true)}
              style={props.handleToothState("_101")}
              className="cls-2"
              d="M131.48,24.81a3.7,3.7,0,0,0-4.85,0c-1,1-.82,2.42-.68,3.6.25,2.11,1.44,5.19,2.9,5.21,1.61,0,2.79-3.7,3.06-5.95C132,26.55,132.14,25.55,131.48,24.81Z"
            />
          </g>
          <g id="left">
            <path
              id="_203"
              data-name={203}
              onClick={(e) => onClick(e, true)}
              style={props.handleToothState("_203")}
              className="cls-2"
              d="M151.79,28.43c-1-1-2.79-1.61-4-1-2.95,1.48-2.4,10.19,0,11,1.54.52,4.32-2.11,5-5C152.92,32.86,153.55,30.14,151.79,28.43Z"
            />
            <path
              id="_202"
              data-name={202}
              onClick={(e) => onClick(e, true)}
              style={props.handleToothState("_202")}
              className="cls-2"
              d="M140.79,25.43c1.32-1.06,4-.56,5,1,.83,1.24.37,2.76,0,4-.67,2.21-2.5,5.26-4,5-1.68-.3-2.17-4.53-2-7C139.87,27.19,140,26.09,140.79,25.43Z"
            />
            <path
              id="_201"
              data-name={201}
              onClick={(e) => onClick(e, true)}
              style={props.handleToothState("_201")}
              className="cls-2"
              d="M133.4,24.81a3.7,3.7,0,0,1,4.85,0c1,1,.82,2.42.68,3.6-.25,2.11-1.44,5.19-2.9,5.21-1.61,0-2.79-3.7-3.06-5.95C132.84,26.55,132.73,25.55,133.4,24.81Z"
            />
          </g>
        </g>
        <g id="canines">
          <g id="right-2" data-name="right">
            <path
              id="_104"
              data-name={104}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_104")}
              className="cls-2"
              d="M97.09,63.43a9.76,9.76,0,0,0,5-5c5.56-11-10.48-35.38-14-34-1,.4,0,2.68,0,12,.07,16.37-2.94,22.78,1,26A8.62,8.62,0,0,0,97.09,63.43Z"
            />
          </g>
          <g id="left-2" data-name="left">
            <path
              id="_204"
              data-name={204}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_204")}
              className="cls-2"
              d="M167.21,63.43a9.8,9.8,0,0,1-5-5c-5.55-11,10.49-35.38,14-34,1,.4,0,2.68,0,12-.07,16.37,2.95,22.78-1,26C173.05,64.19,169.61,64.33,167.21,63.43Z"
            />
          </g>
        </g>
        <g id="premolars">
          <g id="right-3" data-name="right">
            <ellipse
              id="_106"
              data-name={106}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_106")}
              className="cls-2"
              cx="87.59"
              cy="77.93"
              rx="4.5"
              ry="6.5"
            />
            <path
              id="_107"
              data-name={107}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_107")}
              className="cls-2"
              d="M71.51,116.29c-2.3-2,.34-7.29,1.26-9.31a40,40,0,0,1,5.46-8.71c1.24-1.52,4.4-5.86,6.79-4.84,2.6,1.12,2,6.41,1.63,8.55a18.39,18.39,0,0,1-6.94,12C78,115.29,73.63,118.08,71.51,116.29Z"
            />
            <path
              id="_108"
              data-name={108}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_108")}
              className="cls-2"
              d="M53.42,152.44c-1.18-1-.86-3-.47-4.33,1.56-5.34,4.35-10.43,6.74-15.42,6.53-13.6,7-15.53,9.42-16,6.46-1.27,15.17,10.09,13.18,14.13-.61,1.23-2.13,1.51-3.35,1.75a24.44,24.44,0,0,0-5.52,1.4c-2.76,1.27-4.07,4-5.33,6.56-1.47,3-3,6.36-5.63,8.66C60.9,150.54,55.47,154.24,53.42,152.44Z"
            />
          </g>
          <g id="left-3" data-name="left">
            <ellipse
              id="_206"
              data-name={206}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_206")}
              className="cls-2"
              cx="177.09"
              cy="77.93"
              rx="4.5"
              ry="6.5"
            />
            <path
              id="_207"
              data-name={207}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_207")}
              className="cls-2"
              d="M193.17,116.29c2.31-2-.33-7.29-1.25-9.31a40.35,40.35,0,0,0-5.46-8.71c-1.25-1.52-4.4-5.86-6.79-4.84-2.6,1.12-2,6.41-1.63,8.55A18.38,18.38,0,0,0,185,114C186.71,115.29,191.06,118.08,193.17,116.29Z"
            />
            <path
              id="_208"
              data-name={208}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_208")}
              className="cls-2"
              d="M211.27,152.44c1.18-1,.86-3,.47-4.33-1.57-5.34-4.35-10.43-6.75-15.42-6.52-13.6-7-15.53-9.41-16-6.46-1.27-15.18,10.09-13.19,14.13.61,1.23,2.13,1.51,3.36,1.75a24.22,24.22,0,0,1,5.51,1.4c2.77,1.27,4.08,4,5.34,6.56,1.47,3,3,6.36,5.63,8.66C203.79,150.54,209.21,154.24,211.27,152.44Z"
            />
          </g>
        </g>
        <g id="molars">
          <g id="right-4" data-name="right">
            <path
              id="_109"
              data-name={109}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_109")}
              className="cls-2"
              d="M52.09,156.43c-.13-2.1,6.49-5,12-4,2.8.49,5.66,2.71,5.06,5.84-1.1,5.79-8.7,2.62-12.06,1.16S52.14,157.27,52.09,156.43Z"
            />
          </g>
          <g id="left-4" data-name="left">
            <path
              id="_209"
              data-name={209}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_209")}
              className="cls-2"
              d="M213.23,156.43c.13-2.1-6.49-5-12-4-2.8.49-5.66,2.71-5.06,5.84,1.1,5.79,8.7,2.62,12.06,1.16S213.18,157.27,213.23,156.43Z"
            />
          </g>
        </g>
      </g>
      <g id="lower">
        <g id="molars-2" data-name="molars">
          <g id="right-5" data-name="right">
            <path
              id="_409"
              data-name={409}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_409")}
              className="cls-2"
              d="M71.09,229.43c3.54-1.27,7.41,2.12,9,5,2.08,3.75-.23,6,2,10,1.22,2.18,4,3.61,4.94,6s-.31,5.54-2.28,7.06c-2.65,2-6,.5-8.2-1.58-2.81-2.67-4.5-6.58-6.35-9.94C68,242,64.52,231.77,71.09,229.43Z"
            />
          </g>
          <g id="left-5" data-name="left">
            <path
              id="_309"
              data-name={309}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_309")}
              className="cls-2"
              d="M197.52,229.43c-3.54-1.27-7.41,2.12-9,5-2.08,3.75.24,6-2,10-1.21,2.18-4,3.61-4.94,6s.31,5.54,2.28,7.06c2.65,2,6,.5,8.2-1.58,2.82-2.67,4.5-6.58,6.35-9.94C200.64,242,204.09,231.77,197.52,229.43Z"
            />
          </g>
        </g>
        <g id="premolars-2" data-name="premolars">
          <g id="right-6" data-name="right">
            <path
              id="_408"
              data-name={408}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_408")}
              className="cls-2"
              d="M91.78,267.61a13.37,13.37,0,0,1,1.48,6.84c-.28,2.55-2.44,5.06-5.17,5-4-.13-5.87-4.8-6.66-8.09-.67-2.84-1.81-10.07,1.86-11.35C86,259,90.08,263.88,91.78,267.61Z"
            />
            <path
              id="_407"
              data-name={407}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_407")}
              className="cls-2"
              d="M89.59,280.75c-1.46,1.59-.47,4.75.41,7.55,1.44,4.61,5,11.94,8.57,11.44a3.15,3.15,0,0,0,1.93-1.19c.8-1.1.76-2.69-1.52-9.65-.8-2.45-1.46-5.23-3.32-7.13C94.18,280.24,91.34,278.84,89.59,280.75Z"
            />
          </g>
          <g id="left-6" data-name="left">
            <path
              id="_308"
              data-name={308}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_308")}
              className="cls-2"
              d="M176.25,267.61a13.37,13.37,0,0,0-1.48,6.84c.28,2.55,2.44,5.06,5.17,5,4-.13,5.87-4.8,6.66-8.09.67-2.84,1.81-10.07-1.86-11.35C182,259,178,263.88,176.25,267.61Z"
            />
            <path
              id="_307"
              data-name={307}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_307")}
              className="cls-2"
              d="M178.22,280.75c1.47,1.59.48,4.75-.4,7.55-1.44,4.61-5,11.94-8.57,11.44a3.13,3.13,0,0,1-1.93-1.19c-.8-1.1-.76-2.69,1.51-9.65.8-2.45,1.47-5.23,3.33-7.13C173.64,280.24,176.48,278.84,178.22,280.75Z"
            />
          </g>
        </g>
        <g id="canines-2" data-name="canines">
          <g id="right-7" data-name="right">
            <path
              id="_404"
              data-name={404}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_404")}
              className="cls-2"
              d="M108.09,328.43a7,7,0,0,1,6,0c3.56,1.73,4.52,6.49,4.14,10.08-.76,7.29-7.58,11.74-14.36,12.47a24.19,24.19,0,0,1-9.27-.77c-1.34-.38-4-1.09-4.49-2.68,0,0,0-.07,0-.1-.18-2,7.48-1.43,12-8C105.29,334.78,104.34,330.32,108.09,328.43Z"
            />
          </g>
          <g id="left-7" data-name="left">
            <path
              id="_304"
              data-name={304}
              onClick={(e) => onClick(e)}
              style={props.handleToothState("_304")}
              className="cls-2"
              d="M161.3,328.43a7,7,0,0,0-6,0c-3.55,1.73-4.51,6.49-4.13,10.08.76,7.29,7.57,11.74,14.35,12.47a24.16,24.16,0,0,0,9.27-.77c1.34-.38,4-1.09,4.5-2.68,0,0,0-.07,0-.1.19-2-7.48-1.43-12-8C164.11,334.78,165.06,330.32,161.3,328.43Z"
            />
          </g>
        </g>
        <g id="incisors-2" data-name="incisors">
          <g id="right-8" data-name="right">
            <path
              id="_403"
              data-name={403}
              onClick={(e) => onClick(e, true)}
              style={props.handleToothState("_403")}
              className="cls-2"
              d="M119.34,347.21c.71.47.87,1.38,1,3.08.23,2.5.34,3.75,0,4.12-1,1.09-3.85.71-4.89-.77-1.59-2.23,1.12-6.67,3-6.71A1.8,1.8,0,0,1,119.34,347.21Z"
            />
            <path
              id="_402"
              data-name={402}
              onClick={(e) => onClick(e, true)}
              style={props.handleToothState("_402")}
              className="cls-2"
              d="M127.35,350.85c.28,1.29.45,3-.67,3.94a3.54,3.54,0,0,1-3.77.25,2.33,2.33,0,0,1-1.26-2.47c.17-1.49,1.28-5.17,3.23-5.18C126.1,347.39,127.05,349.5,127.35,350.85Z"
            />
            <path
              id="_401"
              data-name={401}
              onClick={(e) => onClick(e, true)}
              style={props.handleToothState("_401")}
              className="cls-2"
              d="M134.21,351.26c.32,1.29.55,3-.54,4a3.55,3.55,0,0,1-3.76.37,2.38,2.38,0,0,1-.9-.72c-1.22-1.62.22-7,2.62-7C133,347.89,133.92,350.12,134.21,351.26Z"
            />
          </g>
          <g id="left-8" data-name="left">
            <path
              id="_303"
              data-name={303}
              onClick={(e) => onClick(e, true)}
              style={props.handleToothState("_303")}
              className="cls-2"
              d="M150.59,347.21c-.71.47-.87,1.37-1,3.08-.23,2.49-.34,3.75,0,4.11,1,1.09,3.84.71,4.89-.76,1.58-2.23-1.12-6.67-2.95-6.72A1.69,1.69,0,0,0,150.59,347.21Z"
            />
            <path
              id="_302"
              data-name={302}
              onClick={(e) => onClick(e, true)}
              style={props.handleToothState("_302")}
              className="cls-2"
              d="M142.58,350.84c-.28,1.3-.45,3,.66,4A3.56,3.56,0,0,0,147,355a2.36,2.36,0,0,0,1.26-2.48c-.18-1.49-1.28-5.17-3.24-5.17C143.82,347.38,142.87,349.5,142.58,350.84Z"
            />
            <path
              id="_301"
              data-name={301}
              onClick={(e) => onClick(e, true)}
              style={props.handleToothState("_301")}
              className="cls-2"
              d="M135.72,351.26c-.32,1.28-.55,3,.54,4a3.53,3.53,0,0,0,3.76.38,2.5,2.5,0,0,0,.9-.73c1.22-1.62-.22-7-2.63-7C136.93,347.88,136,350.12,135.72,351.26Z"
            />
          </g>
        </g>
      </g>
      <g id="text" style={{ fontSize: 14 }}>
        <text className="cls-3" transform="translate(3.38 13.94)">
          {props.labels.rightUpper[props.locale]}
        </text>
        <text
          id="Left_Upper"
          data-name="Left Upper"
          className="cls-3"
          transform="translate(202.93 13.94)"
        >
          {props.labels.leftUpper[props.locale]}
        </text>
        <text className="cls-3" transform="translate(204.1 379.73)">
          {props.labels.leftLower[props.locale]}
        </text>
        <text className="cls-3" transform="translate(3.38 379.73)">
          {props.labels.rightLower[props.locale]}
        </text>
      </g>
      <g id="numbering">
        <g id="upper_right">
          <text className="cls-4" transform="translate(115.39 9)">
            101
          </text>
          <text className="cls-4" transform="translate(104.62 18)">
            102
          </text>
          <text className="cls-4" transform="translate(95.59 27.87)">
            103
          </text>
          <text className="cls-4" transform="translate(67.48 38.87)">
            104
          </text>
          <text className="cls-4" transform="translate(58.8 81.64)">
            106
          </text>
          <text className="cls-4" transform="translate(53.13 110.64)">
            107
          </text>
          <text className="cls-4" transform="translate(39.03 135.39)">
            108
          </text>
          <text className="cls-4" transform="translate(38.22 176.32)">
            109
          </text>
        </g>
        <g id="upper_left">
          <text className="cls-4" transform="translate(135.53 9.13)">
            201
          </text>
          <text className="cls-4" transform="translate(142.86 18)">
            202
          </text>
          <text className="cls-4" transform="translate(153.83 27.87)">
            203
          </text>
          <text className="cls-4" transform="translate(179.72 38.87)">
            204
          </text>
          <text className="cls-4" transform="translate(189.03 81.64)">
            206
          </text>
          <text className="cls-4" transform="translate(196.36 110.64)">
            207
          </text>
          <text className="cls-4" transform="translate(209.26 135.39)">
            208
          </text>
          <text className="cls-4" transform="translate(206.46 178.32)">
            209
          </text>
        </g>
        <g id="lower_left">
          <text className="cls-4" transform="translate(207.34 251.15)">
            309
          </text>
          <text className="cls-4" transform="translate(196.35 276.15)">
            308
          </text>
          <text className="cls-4" transform="translate(185.45 298.15)">
            307
          </text>
          <text className="cls-4" transform="translate(170.25 339.15)">
            304
          </text>
          <text className="cls-4" transform="translate(156.35 361.15)">
            303
          </text>
          <text className="cls-4" transform="translate(146.35 371.15)">
            302
          </text>
          <text className="cls-4" transform="translate(138.12 381.15)">
            301
          </text>
        </g>
        <g id="lower_right">
          <text className="cls-4" transform="translate(43.81 251.15)">
            409
          </text>
          <text className="cls-4" transform="translate(53.83 276.15)">
            408
          </text>
          <text className="cls-4" transform="translate(64.92 298.15)">
            407
          </text>
          <text className="cls-4" transform="translate(81.72 339.15)">
            404
          </text>
          <text className="cls-4" transform="translate(96.83 361.15)">
            403
          </text>
          <text className="cls-4" transform="translate(106.83 371.15)">
            402
          </text>
          <text className="cls-4" transform="translate(115.59 381.15)">
            401
          </text>
        </g>
      </g>
    </svg>
  );
}
