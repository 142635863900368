export const LOCALHOST = "localhost";
export const TASSUAPU_STAGING = "www.staging.tassuapu.fi";
export const PETFILER_STAGING = "stagingpetfiler.herokuapp.com";
export const TASSUAPU_PROD = "www.app.tassuapu.fi";
export const PETFILER_PROD = "www.app.pawesomer.com";
export const HOSTNAME = window && window.location && window.location.hostname;
const STRIPE_PK_TASSUAPU_LIVE = "pk_live_LPyUsLkAt1USAYHsfi0cA0nj";
const STRIPE_PK_TASSUAPU_TEST = "pk_test_xsmmcaajEzy5tRYzSaR21BTu";
const STRIPE_PK_PETFILER_LIVE = "pk_live_Q3vkBsu0EHPZzIKVxfAMHM8o";
const STRIPE_PK_PETFILER_TEST = "pk_test_9CzRe8MtVHzMK6bmaz2zhIZw";
const FACEBOOK_APP_ID_DEV = "1156277184503012";
const FACEBOOK_APP_ID_STAGING =
  process.env.REACT_APP_FACEBOOK_CLIENT_ID_STAGING;
const FACEBOOK_APP_ID_LIVE = process.env.REACT_APP_FACEBOOK_CLIENT_ID;

const STRIPE_PK_LIVE =
  HOSTNAME === TASSUAPU_PROD
    ? STRIPE_PK_TASSUAPU_LIVE
    : HOSTNAME === PETFILER_PROD && STRIPE_PK_PETFILER_LIVE;
const STRIPE_PK_TEST =
  HOSTNAME === LOCALHOST || HOSTNAME === TASSUAPU_STAGING
    ? STRIPE_PK_TASSUAPU_TEST
    : HOSTNAME === PETFILER_STAGING && STRIPE_PK_PETFILER_TEST;

export function returnFacebookAppId() {
  const FACEBOOK_APP_ID =
    HOSTNAME === LOCALHOST
      ? FACEBOOK_APP_ID_DEV
      : HOSTNAME === PETFILER_STAGING
      ? FACEBOOK_APP_ID_STAGING
      : FACEBOOK_APP_ID_LIVE;
  return FACEBOOK_APP_ID;
}

export function returnFacebookClientId() {
  const FACEBOOK_CLIENT_ID =
    HOSTNAME === TASSUAPU_STAGING
      ? process.env.REACT_APP_FACEBOOK_CLIENT_ID_STAGING
      : process.env.REACT_APP_FACEBOOK_CLIENT_ID;
  return FACEBOOK_CLIENT_ID;
}

export function returnGoogleClientId() {
switch (HOSTNAME) {
    case LOCALHOST:
      //return process.env.REACT_APP_GOOGLE_CLIENT_ID;
      return process.env.REACT_APP_GOOGLE_CLIENT_ID_STAGING;
    case TASSUAPU_STAGING:
      return process.env.REACT_APP_GOOGLE_CLIENT_ID_STAGING;
    case PETFILER_STAGING:
      return process.env.REACT_APP_GOOGLE_CLIENT_ID_STAGING_PAWESOMER;
    case TASSUAPU_PROD:
      return process.env.REACT_APP_GOOGLE_CLIENT_ID;
    case PETFILER_PROD:
      return process.env.REACT_APP_GOOGLE_CLIENT_ID_PAWESOMER;
    default:
      return process.env.REACT_APP_GOOGLE_CLIENT_ID;
  }
}

export function returnRenderSocialLogins() {
  const renderSocialLogins =
    HOSTNAME === TASSUAPU_STAGING ||
    HOSTNAME === TASSUAPU_PROD ||
    HOSTNAME === PETFILER_STAGING ||
    HOSTNAME === LOCALHOST
      ? true
      : false;
  return renderSocialLogins;
}

export function returnLogoOverlayText() {
  switch (HOSTNAME) {
    case LOCALHOST:
      return { display: true, str: "DEVELOPMENT" };
    case TASSUAPU_STAGING:
    case PETFILER_STAGING:
      return { display: true, str: "STAGING" };
    case TASSUAPU_PROD:
    case PETFILER_PROD:
      return { display: false, str: "" };
    default:
      return { display: false, str: "" };
  }
}

export function returnDefaultLocale() {
  switch (HOSTNAME) {
    case LOCALHOST:
    case TASSUAPU_STAGING:
    case TASSUAPU_PROD:
      return "fi";
    case PETFILER_STAGING:
    case PETFILER_PROD:
      return "en";
    default:
      return "fi";
  }
}

export function returnSiteLogo(tassuApuLogo, pawesomerLogo) {
  switch (HOSTNAME) {
    case LOCALHOST:
    case TASSUAPU_STAGING:
    case TASSUAPU_PROD:
      return tassuApuLogo;
    case PETFILER_STAGING:
    case PETFILER_PROD:
      return pawesomerLogo;
    default:
      return tassuApuLogo;
  }
}

export function returnStripePublishable() {
  const STRIPE_PUBLISHABLE =
    process.env.NODE_ENV === "production" &&
    HOSTNAME !== TASSUAPU_STAGING &&
    HOSTNAME !== PETFILER_STAGING
      ? STRIPE_PK_LIVE
      : STRIPE_PK_TEST;
  return STRIPE_PUBLISHABLE;
}

export function renderMeOnClient(array) {
  return array.includes(HOSTNAME);
}

export function whereAmI() {
  return HOSTNAME;
}
