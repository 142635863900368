import { features } from "assets"
import Landing from "components/features/Landing"
import HealthEntry from "./Health_Entry_View"
import MedicineEntry from "./Medicine_Entry_View"
import ViewEntries from "./View_Entries"
import ViewEntry from "./View_Entry"

const litterMemberHealthDiaryConfig = {
  name: "litterMemberHealthDiary",
  type: "litterMemberFeature",
  defaultRoute:
    "/userhome/dashboard/litter/litter_member_dashboard/health_diary",
  menuIcon: { img: features.healthDiary.main, alt: "health_diary_icon" },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "healthEntry",
      menuItem: true,
      image: {
        img: features.healthDiary.newEntryHealth,
        alt: "health_diary_new_health_entry_icon",
      },
      icon: "fas fa-heart",
      component: HealthEntry,
      path: "/new_health_entry",
    },
    {
      disabled: false,
      name: "medicineEntry",
      menuItem: true,
      image: {
        img: features.healthDiary.newEntryMedicine,
        alt: "health_diary_new_medicine_entry_icon",
      },
      icon: "fas fa-syringe",
      component: MedicineEntry,
      path: "/new_medicine_entry",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.healthDiary.viewEntries,
        alt: "health_diary_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
    {
      disabled: false,
      name: "viewEntry",
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
  ],
  tableView: {
    headers: ["date", "type", "subject", "onGoing", "medicine"],
    mobileHeaders: ["date", "type", "onGoing"],
  },
}

export default litterMemberHealthDiaryConfig
