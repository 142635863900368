import assets from "assets";
import InviteContact from "./Invite_Contact";
import ViewContacts from "./View_Contacts";
import ContactAndPets from "./Contact_And_Pets";
import ProviderViewPetDashboard from "containers/main/Dashboard/Provider_View";
import ContactsPetProfile from "containers/pets/Pet_Profile/Provider_View";
import ProviderAppointments from "containers/contacts/Provider_Appointments";
import PainIndexTracker from "containers/contacts/Provider_Dashboard/Pain_Index_Tracker";
import NutritionAndWeightTracker from "containers/contacts/Provider_Dashboard/Nutrition_And_Weight_Tracker";

const providerContactsRoutes = [
  {
    name: "painIndexTracker",
    view: "providerContacts",
    disabled: false,
    exact: true,
    path: "/providerhome/contacts/pain_index_tracker",
    component: PainIndexTracker,
    img: {
      src: assets.provider.providerDashboard,
      alt: "pain_index_tracker",
    },
    showIfAuthed: true,
  },
  {
    name: "nutritionAndWeightTracker",
    view: "providerContacts",
    disabled: false,
    exact: true,
    path: "/providerhome/contacts/nutrition_and_weight_tracker",
    component: NutritionAndWeightTracker,
    img: {
      src: assets.features.weight.main,
      alt: "nutrition_and_weight_tracker",
    },
    showIfAuthed: true,
  },
  {
    name: "appointments",
    view: "providerContacts",
    disabled: false,
    exact: true,
    path: "/providerhome/contacts/appointments/view_all",
    component: ProviderAppointments,
    img: {
      src: assets.provider.providerAppointments,
      alt: "provider_appointments",
    },
    showIfAuthed: true,
  },
  {
    name: "viewContacts",
    view: "providerContacts",
    disabled: false,
    exact: true,
    path: "/providerhome/contacts/view",
    component: ViewContacts,
    img: {
      src: assets.provider.providerContacts,
      alt: "provider_contacts",
    },
    showIfAuthed: true,
  },
  {
    name: "inviteContact",
    view: "providerContacts",
    disabled: false,
    exact: true,
    path: "/providerhome/contacts/invite",
    component: InviteContact,
    img: {
      src: assets.provider.sendInvite,
      alt: "provider_invite_contact",
    },
    showIfAuthed: true,
  },
  {
    name: "contactAndPets",
    view: "",
    disabled: false,
    exact: true,
    path: "/providerhome/contacts/contact",
    component: ContactAndPets,
    img: {
      src: assets.pets.profile,
      alt: "provider_contacts",
    },
    showIfAuthed: true,
  },
  {
    name: "contactsPetDashboard",
    view: "",
    disabled: false,
    exact: true,
    path: "/providerhome/contacts/contact/contacts_pet_dashboard",
    component: ProviderViewPetDashboard,
    img: {
      src: assets.pets.profile,
      alt: "provider_contacts",
    },
    showIfAuthed: true,
  },
  {
    name: "contactsPetProfile",
    view: "",
    disabled: false,
    exact: true,
    path: "/providerhome/contacts/contact/contacts_pet_dashboard/profile",
    component: ContactsPetProfile,
    showIfAuthed: true,
  },
];

export default providerContactsRoutes;
