import main from "./vaccinations.svg"
import newEntry from "./vaccinations_new_entry.svg"
import viewEntries from "./vaccinations_view_entries.svg"

const vaccinations = {
  main,
  newEntry,
  viewEntries,
}

export default vaccinations
