import {
  ACTION_STARTED,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_ERROR,
  ACTION_RESET,
  ACTION_NO_SUCCESS,
} from "actions/types"

const INITIAL_STATE = {
  action: "",
  actionInProcess: false,
  actionSuccess: {},
  actionError: {},
  actionNoSuccess: {},
}

export default function ActionCallsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_STARTED:
      return { ...state, actionInProcess: true, action: action.payload }
    case ACTION_ENDED:
      return { ...state, actionInProcess: false }
    case ACTION_SUCCESS:
      return {
        ...state,
        actionSuccess: { value: true, message: action.payload },
      }
    case ACTION_ERROR:
      return {
        ...state,
        actionError: { value: true, message: action.payload },
      }
    case ACTION_NO_SUCCESS:
      return {
        ...state,
        actionNoSuccess: { value: true, message: action.payload },
      }
    case ACTION_RESET:
      return INITIAL_STATE
    default:
      return state
  }
}
