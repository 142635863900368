import React from "react";
import assets from "assets";
import { Fragment } from "react";
import moment from "moment";
import AppointmentEntryForm from "../forms/Appointment_Entry_Form";
import FullAppointmentEntryForm from "../forms/Full_Appointment_Entry_Form";
import DeclineAppointmentForm from "../forms/Decline_Appointment_Form";
import CancelAppointmentForm from "../forms/Cancel_Appointment_Form";
import AddNotesAppointmentForm from "../forms/Add_Notes_Appointment_Form";
import { returnAppointmentStatus } from "functions";
import appointmentStatusColors from "constants/appointment_status_colors";
import {
  MODALS_CONFIRM_DELETE,
  MODALS_HIDE,
  MODALS_SHOW,
  MODALS_UPDATE_DOCUMENT,
} from "actions/types";
import { APPOINTMENTS_DELETE } from "../actions";
import { Link } from "react-router-dom";

function Appointment(props) {
  const {
    index,
    date,
    time,
    description,
    providerServiceName,
    dispatch,
    messages,
    locale,
    defaultRoute,
    providerView,
    confirmationState,
    _id,
    _provider,
    _user,
    petName,
    provider,
    confirmAppointment,
    providerAppointments,
    setCurrentContact,
    providerAppointment,
    contact,
  } = props;

  function canEditOrDelete() {
    if (providerView) {
      if (_provider === provider._id) return true;
    }
    if (!providerView && !_provider) {
      return true;
    }

    return false;
  }

  function canConfirmDeclineOrCancel() {
    if (!providerView && _provider) {
      return true;
    }

    return false;
  }

  function handleViewClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_UPDATE_DOCUMENT,
      modalProps: {
        type: "updateDocument",
        handleModalClose: () => dispatch({ type: MODALS_HIDE }),
        children: (
          <FullAppointmentEntryForm
            viewMode
            valuesToEdit={props}
            cancel={() => dispatch({ type: MODALS_HIDE })}
            messages={messages}
            locale={locale}
            defaultRoute={defaultRoute}
            providerView={providerView}
          />
        ),
      },
    });
  }

  function handleEditClick() {
    if (_provider) {
      if (providerView && _provider === provider._id) {
        dispatch({
          type: MODALS_SHOW,
          modalType: MODALS_UPDATE_DOCUMENT,
          modalProps: {
            type: "updateDocument",
            handleModalClose: () => dispatch({ type: MODALS_HIDE }),
            children: (
              <AppointmentEntryForm
                editMode
                valuesToEdit={props}
                cancel={() => dispatch({ type: MODALS_HIDE })}
                messages={messages}
                locale={locale}
                defaultRoute={defaultRoute}
                providerView={providerView}
              />
            ),
          },
        });
      } else {
        dispatch({
          type: MODALS_SHOW,
          modalType: MODALS_UPDATE_DOCUMENT,
          modalProps: {
            type: "updateDocument",
            handleModalClose: () => dispatch({ type: MODALS_HIDE }),
            children: (
              <AddNotesAppointmentForm
                editMode
                valuesToEdit={props}
                cancel={() => dispatch({ type: MODALS_HIDE })}
                messages={messages}
                locale={locale}
                defaultRoute={defaultRoute}
                providerView={providerView}
              />
            ),
          },
        });
      }
    }

    if (!_provider) {
      dispatch({
        type: MODALS_SHOW,
        modalType: MODALS_UPDATE_DOCUMENT,
        modalProps: {
          type: "updateDocument",
          handleModalClose: () => dispatch({ type: MODALS_HIDE }),
          children: (
            <AppointmentEntryForm
              editMode
              valuesToEdit={props}
              cancel={() => dispatch({ type: MODALS_HIDE })}
              messages={messages}
              locale={locale}
              defaultRoute={defaultRoute}
              providerView={providerView}
            />
          ),
        },
      });
    }
  }

  function handleDeleteClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "appointment",
        noDefault: true,
        ownBodyText: false,
        actionType: APPOINTMENTS_DELETE,
        item: _id,
        providerView,
      },
    });
  }

  function handleConfirmClick() {
    confirmAppointment(_id);
  }

  function handleDeclineClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_UPDATE_DOCUMENT,
      modalProps: {
        type: "updateDocument",
        handleModalClose: () => dispatch({ type: MODALS_HIDE }),
        children: (
          <DeclineAppointmentForm
            editMode
            valuesToEdit={props}
            cancel={() => dispatch({ type: MODALS_HIDE })}
            messages={messages}
            locale={locale}
          />
        ),
      },
    });
  }

  function handleCancelClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_UPDATE_DOCUMENT,
      modalProps: {
        type: "updateDocument",
        handleModalClose: () => dispatch({ type: MODALS_HIDE }),
        children: (
          <CancelAppointmentForm
            editMode
            valuesToEdit={props}
            cancel={() => dispatch({ type: MODALS_HIDE })}
            messages={messages}
            locale={locale}
          />
        ),
      },
    });
  }

  return (
    <div
      className="card"
      style={{ backgroundColor: index % 2 === 1 ? "#f2f2f2" : "" }}
    >
      <div className="card-content" style={{ padding: 12 }}>
        <div className="columns is-paddingless">
          <div className="column is-narrow">
            <label className="label has-text-grey is-size-6">
              {messages.labels.date[locale]}
            </label>
            <p className="is-size-7 has-text-dark">
              {moment(date).format("DD.MM.YYYY")}
            </p>
          </div>
          <div className="column is-1-desktop">
            <label className="label has-text-grey is-size-6">
              {messages.labels.time[locale]}
            </label>
            <p className="is-size-7 has-text-dark">{time}</p>
          </div>
          {providerAppointments && (
            <div className="column">
              <label className="label has-text-grey is-size-6">
                {messages.labels.ownerAndPet[locale]}
              </label>
              <p className="is-size-7 has-text-dark">{`${_user.userInfo.firstname} ${_user.userInfo.lastname} / ${petName}`}</p>
            </div>
          )}
          {!providerAppointments && (
            <div className="column">
              <label className="label has-text-grey is-size-6">
                {messages.labels.providerServiceName[locale]}
              </label>
              <p className="is-size-7 has-text-dark">
                {providerServiceName ? providerServiceName : "-"}
              </p>
            </div>
          )}
          <div className="column">
            <label className="label has-text-grey is-size-6">
              {messages.labels.description[locale]}
            </label>
            <p className="is-size-7 has-text-dark">{description}</p>
          </div>
          <div className="column has-text-centered">
            <label className="label has-text-grey is-size-6">
              {messages.labels.status[locale]}
            </label>
            <div
              style={{
                backgroundColor:
                  !_provider ||
                  (_provider && confirmationState.status === "confirmed")
                    ? appointmentStatusColors[
                        returnAppointmentStatus(date).status
                      ].background
                    : appointmentStatusColors[confirmationState.status]
                        .background,
                padding: 0,
              }}
              className={`is-${
                !_provider ||
                (_provider && confirmationState.status === "confirmed")
                  ? returnAppointmentStatus(date).color
                  : "danger"
              }`}
            >
              <p className="is-size-7 has-text-dark" style={{ marginLeft: 3 }}>
                {!_provider ||
                (_provider && confirmationState.status === "confirmed")
                  ? messages.statuses[returnAppointmentStatus(date).status][
                      locale
                    ]
                  : messages.statuses[confirmationState.status][locale]}
              </p>
            </div>
          </div>
          <Fragment>
            <div className="column is-3-desktop">
              <div className="buttons is-right">
                <div style={{ height: 32, paddingRight: 8 }}>
                  <button
                    className="button is-small is-rounded"
                    style={{ padding: 2 }}
                    onClick={handleViewClick}
                  >
                    <figure className={"image is-24x24"}>
                      <img
                        src={assets.features.appointments.viewAppointment}
                        alt="view_icon"
                      />
                    </figure>
                  </button>
                  <button
                    className="button is-small is-rounded"
                    style={{ padding: 2 }}
                    onClick={handleEditClick}
                  >
                    <figure className={"image is-24x24"}>
                      <img
                        src={assets.features.appointments.editAppointment}
                        alt="edit_icon"
                      />
                    </figure>
                  </button>
                  {canEditOrDelete() && (
                    <button
                      className="button is-small is-rounded"
                      style={{ padding: 2 }}
                      onClick={handleDeleteClick}
                    >
                      <figure className={"image is-24x24"}>
                        <img
                          src={assets.features.appointments.deleteAppointment}
                          alt="delete_icon"
                        />
                      </figure>
                    </button>
                  )}
                </div>
                {props.unconfirmed && canConfirmDeclineOrCancel() && (
                  <div
                    style={{
                      borderLeft: "2px solid #bebebe",
                      height: 32,
                      paddingLeft: 8,
                    }}
                  >
                    <button
                      className="button is-small is-rounded"
                      style={{ padding: 2 }}
                      onClick={handleConfirmClick}
                    >
                      <figure className={"image is-24x24"}>
                        <img
                          src={assets.features.appointments.confirmAppointment}
                          alt="confirm_appointment_icon"
                        />
                      </figure>
                    </button>
                    <button
                      className="button is-small is-rounded"
                      style={{ padding: 2 }}
                      onClick={handleDeclineClick}
                    >
                      <figure className={"image is-24x24"}>
                        <img
                          src={
                            assets.features.appointments
                              .cancelDeclineAppointment
                          }
                          alt="decline_appointment_icon"
                        />
                      </figure>
                    </button>
                  </div>
                )}
                {props.confirmed && canConfirmDeclineOrCancel() && (
                  <div
                    style={{
                      borderLeft: "2px solid #bebebe",
                      height: 32,
                      paddingLeft: 8,
                    }}
                  >
                    <button
                      className="button is-small is-rounded"
                      style={{ padding: 2 }}
                      onClick={handleCancelClick}
                    >
                      <figure className={"image is-24x24"}>
                        <img
                          src={
                            assets.features.appointments
                              .cancelDeclineAppointment
                          }
                          alt="cancel_appointment_icon"
                        />
                      </figure>
                    </button>
                  </div>
                )}
                {providerAppointment && (
                  <div
                    style={{
                      borderLeft: "2px solid #bebebe",
                      height: 32,
                      paddingLeft: 8,
                    }}
                  >
                    <Link
                      to="/providerhome/contacts/contact"
                      className="button is-small is-rounded"
                      style={{ padding: 2 }}
                      onClick={() => setCurrentContact(contact)}
                    >
                      <figure className={"image is-24x24"}>
                        <img
                          src={assets.professionals.contactsPets}
                          alt="pets_icon"
                        />
                      </figure>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
}

export default Appointment;
