import React from "react";
import ProfileDisplay from "components/styled/Profile_Display";

const SubscriptionPlans = (props) => {
  const { messages, billing, locale } = props;
  const { pricing } = billing.billingData.plans;
  const FIELDS = [
    {
      label: messages.plans.infoMessage.title[locale],
      value: messages.plans.infoMessage.text[locale],
    },
    {
      label: messages.plans.labels.copper[locale],
      value: `${pricing.copper.withDecimal} ${pricing.currency[locale]} ${messages.plans.labels.perMonth[locale]}`,
    },
    {
      label: messages.plans.labels.silver[locale],
      value: `${pricing.silver.withDecimal} ${pricing.currency[locale]} ${messages.plans.labels.perMonth[locale]}`,
    },
    {
      label: messages.plans.labels.gold[locale],
      value: `${pricing.gold.withDecimal} ${pricing.currency[locale]} ${messages.plans.labels.perMonth[locale]}`,
    },
    {
      label: messages.plans.labels.platinum[locale],
      value: `${pricing.platinum.withDecimal} ${pricing.currency[locale]} ${messages.plans.labels.perMonth[locale]}`,
    },
    {
      label: messages.plans.labels.diamond[locale],
      value: `${pricing.diamond.withDecimal} ${pricing.currency[locale]} ${messages.plans.labels.perMonth[locale]}`,
    },
  ];

  return <ProfileDisplay fields={FIELDS} />;
};

export default SubscriptionPlans;
