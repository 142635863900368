import React from "react";
import { Link } from "react-router-dom";
import { features } from "assets";
import TileWrapper from "./TileWrapper";
import Header from "./Header";
import TileBody from "./TileBody";

function LitterTile(props) {
  return (
    <TileWrapper>
      <Link to={props.path}>
        <Header title={props.litterName} />
        <TileBody img={features.litters.main} alt={props.litterName} />
      </Link>
    </TileWrapper>
  );
}

export default LitterTile;
