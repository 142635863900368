import moment from "moment"
import {
  AUTH_LOG_IN_USER,
  AUTH_LOG_OUT_USER,
  AUTH_FETCH_CURRENT_USER,
  AUTH_COMPLETE_REGISTRATION_REQUIRED,
  AUTH_VERIFY_EMAIL_REQUIRED,
  AUTH_USER_EMAIL_REQUIRED,
} from "actions/types"
import {
  AUTH_FETCH_CURRENT_PROVIDER,
  AUTH_LOG_IN_PROVIDER,
  AUTH_LOG_OUT_PROVIDER,
} from "containers/auth/Pet_Services_Portal/actions"
import { AUTH_VERIFY_EMAIL_PROVIDER_REQUIRED } from "containers/Provider_Home/actions"

const INITIAL_STATE = {
  isAuthenticated: false,
  role: "",
  user: {},
  provider: {},
  lastFetch: "",
}

export default function AuthReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_LOG_IN_USER:
      localStorage.removeItem("tokenProvider")
      return { ...state, isAuthenticated: true, role: "user" }
    case AUTH_LOG_IN_PROVIDER:
      localStorage.removeItem("token")
      return { ...state, isAuthenticated: true, role: "provider" }
    case AUTH_FETCH_CURRENT_USER:
      return { ...state, user: action.payload, lastFetch: moment() }
    case AUTH_FETCH_CURRENT_PROVIDER:
      return { ...state, provider: action.payload, lastFetch: moment() }
    case AUTH_COMPLETE_REGISTRATION_REQUIRED:
      return { ...state, user: { completeRegistrationRequired: true } }
    case AUTH_VERIFY_EMAIL_REQUIRED:
      return { ...state, user: { verifyEmailRequired: true } }
    case AUTH_VERIFY_EMAIL_PROVIDER_REQUIRED:
      return { ...state, provider: action.payload }
    case AUTH_USER_EMAIL_REQUIRED:
      return { ...state, user: { userEmailRequired: true } }
    case AUTH_LOG_OUT_USER:
      localStorage.removeItem("token")
      return INITIAL_STATE
    case AUTH_LOG_OUT_PROVIDER:
      localStorage.removeItem("tokenProvider")
      return INITIAL_STATE
    default:
      return state
  }
}
