import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "actions/auth";
import Button from "components/elements/Button";

function _isButtonSelected(state, btnName) {
  if (state === btnName) {
    return "is-primary";
  } else {
    return "";
  }
}

function WeightUnitSelection(props) {
  const [selectedUnit, setSelectedUnit] = useState(props.userPreferredUnit);

  function handleUnitSelection(unit) {
    props.updateWeightUnitPreference(unit);
    setSelectedUnit(unit);
  }

  return (
    <div className="box" style={{ padding: 8 }}>
      <div className="columns is-mobile is-paddingless">
        <div className="column">
          <Button
            name="kg"
            group="weight"
            onClick={() => handleUnitSelection("kg")}
            className={`is-fullwidth ${_isButtonSelected(selectedUnit, "kg")}`}
          />
        </div>
        <div className="column">
          <Button
            name="lbs"
            group="weight"
            onClick={() => handleUnitSelection("lbs")}
            className={`is-fullwidth ${_isButtonSelected(selectedUnit, "lbs")}`}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userPreferredUnit: state.reducers.auth.user.featurePreferences.weightUnit,
  };
};

export default connect(mapStateToProps, actions)(WeightUnitSelection);
