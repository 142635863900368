import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class HealthDiary extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.healthDiary,
      ...state.intl.messages.containers.litterMemberFeatures
        .litterMemberHealthDiary,
      landing: {
        ...state.intl.messages.containers.litterMemberFeatures
          .litterMemberHealthDiary.landing,
        title:
          state.intl.messages.containers.features.healthDiary.landing.title,
        subtitle:
          state.intl.messages.containers.features.healthDiary.landing.subtitle,
        menuLink:
          state.intl.messages.containers.features.healthDiary.landing.menuLink,
      },
    },
    healthDiary: state.reducers.healthDiary,
  };
};

export default connect(mapStateToProps)(HealthDiary);
