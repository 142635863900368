import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { usefulLinks as actions } from "actions/index";
import Section from "components/layout/Section";
import Message from "components/message";
import UsefulLinkForm from "forms/Useful_Link_Form";
import LinkItem from "./components/Link_Item";
import AddNewLink from "./components/Add_New_Link";
import LandingPageInfo from "components/features/Landing_Page_Info";
import { returnNewObjectAsArrayByValidKey } from "functions";

function Links(props) {
  // Deconstructing props
  const {
    pet,
    locale,
    messages,
    usefulLinks,
    fetchUsefulLinks,
    addUsefulLink,
    updateUsefulLink,
    deleteUsefulLink,
    providerView,
    providerContact,
  } = props;

  const { entries, fetched } = usefulLinks;
  const entriesFiltered = entries.filter(
    (entry) => entry._pet === pet._id || entry.displayForAll
  );

  const pageInfo = returnNewObjectAsArrayByValidKey(
    messages.landing,
    "info_",
    locale
  );

  // Hooks
  const [showForm, toggleForm] = useState(false);
  const [editMode, toggleEditMode] = useState(false);
  const [itemToEdit, setItemToEdit] = useState("");

  // ComponentWillMount & ComponentDidMount
  useEffect(() => {
    if (!fetched) fetchUsefulLinks(pet._user, providerView);
  }, [fetched, fetchUsefulLinks, pet._user, providerView]);

  // Functions
  function handleShowNewLinkForm() {
    toggleForm(true);
  }

  function handleHideNewLinkForm() {
    toggleForm(false);
  }

  function handleToggleEditMode(item) {
    toggleEditMode(!editMode);
    setItemToEdit(item);
    handleHideNewLinkForm();
  }

  function handleAddToLinks(link, setSubmitting) {
    addUsefulLink(
      link,
      setSubmitting,
      handleHideNewLinkForm,
      providerContact,
      providerView
    );
  }

  function handleUpdateLinkAfterEdit(link, setSubmitting) {
    updateUsefulLink(link, setSubmitting, handleToggleEditMode, providerView);
  }

  function handleDeleteLink(_id) {
    deleteUsefulLink(_id, handleToggleEditMode, providerView);
  }

  // Render helpers
  function renderLinkItems(links) {
    if (links.length === 0) return null;
    return (
      <>
        {links.map((link, i) => (
          <LinkItem
            key={i}
            {...link}
            toggleEditMode={handleToggleEditMode}
            editMode={editMode}
          >
            {editMode && itemToEdit === link._id && (
              <UsefulLinkForm
                editMode
                valuesToEdit={link}
                pet={props.pet}
                cancel={handleToggleEditMode}
                updateLink={handleUpdateLinkAfterEdit}
                deleteLink={handleDeleteLink}
              />
            )}
          </LinkItem>
        ))}
      </>
    );
  }

  // Main render
  return (
    <>
      <Section>
        <LandingPageInfo pageInfo={pageInfo} />
      </Section>
      <Section>
        {entriesFiltered.length === 0 ? (
          <Message warning message={"noEntriesToDisplayAutoFetch"} />
        ) : (
          renderLinkItems(entriesFiltered)
        )}
      </Section>
      <Section>
        <AddNewLink showNewLinkForm={handleShowNewLinkForm} />
        <br />
        <br />
        {showForm && (
          <UsefulLinkForm
            valuesToEdit={{}}
            pet={props.pet}
            cancel={handleHideNewLinkForm}
            addToLinks={handleAddToLinks}
          />
        )}
      </Section>
    </>
  );
}

export default connect(null, actions)(Links);
