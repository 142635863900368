import { ACTION_ERROR, ACTION_ENDED, ACTION_RESET } from "actions/types"

export function catchBlockError(error, dispatch) {
  if (error.response) {
    if (error.response.status === 500) {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    } else if (error.response.status === 401) {
      dispatch({
        type: ACTION_ERROR,
        payload: "unauthorized",
      })
    } else
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    dispatch({ type: ACTION_ENDED })
    return setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
  dispatch({
    type: ACTION_ERROR,
    payload: "somethingWentWrong",
  })
  dispatch({ type: ACTION_ENDED })
  return setTimeout(() => {
    dispatch({ type: ACTION_RESET })
  }, 3000)
}

export const endAndResetAction = (dispatch) => {
  dispatch({ type: ACTION_ENDED })
  dispatch({ type: ACTION_RESET })
}

export const endAndResetActionWithDelay = (dispatch, timeInMs) => {
  dispatch({ type: ACTION_ENDED })
  setTimeout(() => {
    dispatch({ type: ACTION_RESET })
  }, timeInMs)
}

export const delayEndAndResetAction = (dispatch, timeInMs) => {
  setTimeout(() => {
    dispatch({ type: ACTION_ENDED })
    dispatch({ type: ACTION_RESET })
  }, timeInMs)
}

const actionFunctions = {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
  delayEndAndResetAction,
}

export default actionFunctions
