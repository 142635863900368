import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Image from "components/image";

const style2 = {
  border: `1px solid lightgrey`,
}

const Button = (props) => {
  if (props.link) {
    return (
      <Link to={props.to} className={`button ${props.className}`}>
        <Label {...props} />
      </Link>
    );
  }

  if (props.hasChildren) {
    return (
      <div className={`button ${props.className}`} onClick={props.onClick}>
        {props.children}
      </div>
    );
  }

  if (props.href) {
    return (
      <a
        className={`button ${props.className}`}
        rel="noopener noreferrer"
        href={props.url}
        target="_blank"
        disabled={props.disabled}
      >
        {props.withIcon && props.iconLeft && <WithIcon {...props} />}
        <Label {...props} />
        {props.withIcon && props.iconRight && <WithIcon {...props} />}
      </a>
    );
  }

  return props.image ? (
    <div
      className={`button ${props.className}`}
      onClick={props.disabled ? null : props.onClick}
      disabled={props.disabled}
    >
      <Image {...props} />
    </div>
  ) : (
    <div
      className={`button ${props.className}`}
      onClick={props.disabled ? null : props.onClick}
      disabled={props.disabled}
      style={style2}
    >
      {props.withIcon && props.iconLeft && <WithIcon {...props} />}
      <Label {...props} />
      {props.withIcon && props.iconRight && <WithIcon {...props} />}
    </div>
  );
};

const Label = (props) => {
  const label = !props.labels[props.group]
    ? `noGroup-${props.group}`
    : !props.labels[props.group][props.name]
    ? `noName-${props.name}`
    : props.labels[props.group][props.name][props.locale];

  return <div className={props.labelClassName}>{label}</div>;
};

const WithIcon = (props) => {
  return (
    <span className="icon">
      <i className={props.labels[props.group][props.name].icon} />
    </span>
  );
};

const mapStateToProps = (state) => {
  return {
    labels: state.intl.messages.components.buttons,
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(Button);
