import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { heatDiary as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./fields/heat_diary_cycle";
import FormButtons from "components/form/FormButtons";

class HeatDiaryCycleEntryForm extends Component {
  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          values={values}
          viewMode={viewMode}
          editMode={editMode}
          {...field}
        />
      );
    });
  }

  render() {
    const { errors, dirty, isSubmitting, viewMode, editMode, cancel } =
      this.props;
    return (
      <Form>
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={"/userhome/dashboard/heat_diary"}
        />
        <br />
      </Form>
    );
  }
}

HeatDiaryCycleEntryForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet }) {
    return {
      type: valuesToEdit.type || "cycle",
      startDate:
        moment(valuesToEdit.startDate).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      endDate: valuesToEdit.endDate
        ? moment(valuesToEdit.endDate).format("YYYY-MM-DD")
        : "",
      cycleNotes: valuesToEdit.cycleNotes || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    startDate: Yup.date().required("required"),
    endDate: Yup.date(),
    cycleNotes: Yup.string().max(500, "max-500"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries_cycle`;
    if (props.editMode) {
      return props.updateHeatDiaryCycleEntry(
        values,
        setSubmitting,
        props.history,
        successRoute
      );
    }
    props.addHeatDiaryCycleEntry(
      values,
      setSubmitting,
      props.history,
      successRoute
    );
  },
})(HeatDiaryCycleEntryForm);

export default withRouter(connect(null, actions)(HeatDiaryCycleEntryForm));
