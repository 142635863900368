import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as actions from "actions/client_settings"
import Layout from "layout"


function App({
  auth,
  history,
  messages,
  locale,
  fetchClientSettings,
  location,
  appData,
  actionCalls,
}) {
  useEffect(() => {
    function fetch() {
      fetchClientSettings()
    }
    return fetch()
  }, [fetchClientSettings])

  if (!appData.clientSettingsFetched) return null

  return (
    <Layout
      auth={auth}
      history={history}
      messages={messages}
      locale={locale}
      location={location}
      actionCalls={actionCalls}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.reducers.auth,
    locale: state.intl.locale,
    messages: state.intl.messages,
    appData: state.reducers.appData,
    actionCalls: state.reducers.actionCalls,
  }
}

export default connect(mapStateToProps, actions)(withRouter(App))
