import en from "./en_both.svg";
import fi from "./fi.svg";
import se from "./se.svg";

const flags = {
  en,
  fi,
  se,
};

export default flags;
