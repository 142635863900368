import React, { useEffect } from "react";
import { connect } from "react-redux";
import { weightReview as actions } from "actions";
import Bounds from "components/bounds";
import { returnDateRange } from "functions";
import Chart from "react-google-charts";
import Loading from "components/spinners/Loading";
import Message from "components/message";

function WeightReviewChart(props) {
  const {
    weightReview,
    _litter,
    weightReviewFetch,
    locale,
    fetchType,
    messages,
    litterDob,
    fetchTypeLabel,
  } = props;
  const dates = returnDateRange(fetchType, litterDob);

  useEffect(() => {
    if (weightReview[_litter]) {
      if (weightReview[_litter][fetchType]) return;
    }
    weightReviewFetch(dates, fetchType, _litter);
  });

  if (!weightReview[_litter]) return null;

  if (!weightReview[_litter][fetchType]) return null;

  const chartData = weightReview[_litter][fetchType].data;

  const chartOptions = weightReview[_litter][fetchType].options;

  function renderContent({ width, height }) {
    if (chartData.length === 0)
      return <Message warning message="noDataToDisplay" />;
    return (
      <div style={{ display: "flex", maxWidth: 900 }}>
        {
          <Chart
            width={width}
            height={height}
            chartType="LineChart"
            loader={<Loading />}
            data={chartData}
            options={{
              ...chartOptions,
              title: messages.title[locale],
              interpolateNulls: true,
              hAxis: {
                title: fetchTypeLabel,
                titleTextStyle: { color: "#333" },
                textPosition: "none",
                ...chartOptions.hAxis,
              },
              vAxis: {
                minValue: 0,
              },
              legend: "none",
              chartArea: { width: "auto", height: "auto" },
              lineWidth: 3,
              animation: {
                duration: 1000,
                easing: "out",
                startup: true,
              },
            }}
          />
        }
      </div>
    );
  }

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ display: "inline-block" }}>
        <Bounds
          maxWidth="400"
          render={() => renderContent({ width: 300, height: 375 })}
          else={() => renderContent({ width: 780, height: 500 })}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    _litter: state.reducers.litters.selectedLitter._id,
    litterDob: state.reducers.litters.selectedLitter.litterDob,
    weightReview: state.reducers.weightReview,
    messages:
      state.intl.messages.containers.litterFeatures.weightReview.chartsView,
  };
};

export default connect(mapStateToProps, actions)(WeightReviewChart);
