import React from "react"

function PostContent({ content }) {
  return (
    <p
      className="post-content-excerpt"
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  )
}

export default PostContent
