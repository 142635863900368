import { InputFieldV5, TextareaV5, UrlInputLinkV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "time",
    group: "common",
    type: "time",
    icon: "far fa-clock",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "subject",
    group: "common",
    type: "text",
    icon: "fas fa-heart",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "notes",
    group: "common",
    type: "text",
    className: "textarea ",
    component: TextareaV5,
    rows: 5,
    required: true,
    charLimit: 1000,
    userCanEdit: true,
  },
  {
    name: "attachmentURL",
    group: "common",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: UrlInputLinkV5,
    required: false,
    userCanEdit: true,
  },
];

export { INPUT_FIELDS };
