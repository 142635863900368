import axios from "axios"

import {
  VACS_FETCH,
  VACS_ADD,
  VACS_UPDATE,
  VACS_DELETE,
  VACS_ADD_FOR_LITTER,
  VACS_FETCH_FOR_LITTER,
  ACTION_STARTED,
  ACTION_SUCCESS,
  MODALS_HIDE,
} from "./types"
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "./functions"

const API = (route) => `/api/vacs/${route}`

export const fetchVacs = (pet, providerView) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: VACS_FETCH })
    const res = await axios.get(
      API(providerView ? "fetch_for_provider" : "fetch"),
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
        params: { _pet: pet._id },
      }
    )
    dispatch({ type: VACS_FETCH, payload: res.data.vacs, pet })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const fetchVacsForLitter = (litterId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: VACS_FETCH_FOR_LITTER })
    const res = await axios.get("/api/vacs/fetch_for_litter", {
      headers: {
        authorization: localStorage.getItem("token"),
      },
      params: { _litter: litterId },
    })
    dispatch({ type: VACS_FETCH_FOR_LITTER, payload: res.data.vacs, litterId })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addVac = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerContact,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: VACS_ADD })
    const res = await axios.post(
      API(providerView ? "add_by_provider" : "add"),
      { _user: providerView ? providerContact._id : undefined, ...values },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({ type: VACS_ADD, payload: res.data.vac })
    endAndResetAction(dispatch)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const updateVac = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: VACS_UPDATE })
    const res = await axios.put(
      API(providerView ? "update_by_provider" : "update"),
      values,
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({ type: VACS_UPDATE, payload: res.data.vac })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const deleteVac = (id, history, successRoute, providerView) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: VACS_DELETE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      API(providerView ? "delete_by_provider" : "delete"),
      { id },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({ type: VACS_DELETE, payload: res.data.vac })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addVacsForLitter = (
  values,
  litterMembers,
  setSubmitting,
  onSuccess
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: VACS_ADD_FOR_LITTER })
    const res = await axios.post(
      "/api/vacs/add_for_litter",
      { values, litterMembers },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({ type: VACS_ADD_FOR_LITTER, payload: res.data.result })
    endAndResetAction(dispatch)
    return onSuccess()
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const vacsActions = {
  fetchVacs,
  addVac,
  updateVac,
  deleteVac,
  addVacsForLitter,
  fetchVacsForLitter,
}

export default vacsActions
