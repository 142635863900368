const INITIAL_STATE = {
  name: "Default",
  settings: {
    main: {
      background: "is-primary",
    },
    _defaultBox: {
      background: "hsl(171, 100%, 89%)",
    },
    get defaultBox() {
      return this._defaultBox
    },
    set defaultBox(value) {
      this._defaultBox = value
    },
    _statusBox: {
      valid: {
        borderColor: "hsl(141, 71%, 48%)",
      },
      expiring: {
        borderColor: "hsl(48, 100%, 67%)",
      },
      expired: {
        borderColor: "hsl(348, 100%, 61%)",
      },
      status: {
        borderColor: "hsl(348, 100%, 61%)",
      },
    },
    get statusBox() {
      return this._statusBox
    },
    set statusBox(value) {
      this._statusBox = value
    },
    hr: "primary",
  },
}

export default function ThemeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    default:
      return state
  }
}
