import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class Tasks extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.tasks,
      task: state.intl.messages.components.task,
      labels: state.intl.messages.components.forms.labels.tasks,
    },
    tasks: state.reducers.tasks,
  };
};

export default connect(mapStateToProps)(Tasks);
