import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";


const style2 = {
  border: `1px solid lightgrey`
};
function FormButtons(props) {
  const {
    viewMode,
    isSubmitting,
    errors,
    dirty,
    editMode,
    cancel,
    linkTo,
    buttons,
    locale,
    useCancel,
    noCancel,
    login,
    register,
    buttonsClass,
    disabled,
    submitButtonLabel,
  } = props;

  if (viewMode) return null;

  if (login) {
    return (
      <div className="buttons is-centered">
        <Link to="/" className="button">
          <span>
            <i className={buttons.common.cancel.icon} />{" "}
            {buttons.common.cancel[locale]}
          </span>
        </Link>
        <button
          disabled={isSubmitting}
          className={`button ${
            !_.isEmpty(errors)
              ? "is-danger"
              : _.isEmpty(errors) && dirty && "is-success"
          } ${isSubmitting && "is-loading"}`}
          type="submit"
        >
          <span>
            <i className={buttons.auth.login.icon} />{" "}
            {buttons.auth.login[locale]}
          </span>
        </button>
      </div>
    );
  }

  if (register) {
    return (
      <div className="buttons is-centered">
        <Link to="/" className="button">
          <span>
            <i className={buttons.common.cancel.icon} />{" "}
            {buttons.common.cancel[locale]}
          </span>
        </Link>
        <button
          disabled={isSubmitting}
          className={`button ${
            !_.isEmpty(errors)
              ? "is-danger"
              : _.isEmpty(errors) && dirty
              ? "is-success"
              : "is-info"
          } ${isSubmitting && "is-loading"}`}
          type="submit"
        >
          <span>
            <i className={buttons.auth.register.icon} />{" "}
            {buttons.auth.register[locale]}
          </span>
        </button>
      </div>
    );
  }

  return (
    
    <div className={`buttons is-centered ${buttonsClass}`}>
      {!noCancel &&
        (editMode || useCancel ? (
          <button type="button" className="button" onClick={cancel} style={style2}>
            {buttons.common.cancel[locale] }
          </button>
          
        ) : (
          <Link to={linkTo} className="button" style={style2}>
            {buttons.common.cancel[locale]}
          </Link>
        ))}
      <button
        disabled={isSubmitting || disabled}
        className={`button ${
          !_.isEmpty(errors)
            ? "is-danger"
            : _.isEmpty(errors) && dirty
            ? "is-success"
            : "is-info"
        } ${isSubmitting && "is-loading"}`}
        type={"submit"}
      >
        {submitButtonLabel
          ? buttons[submitButtonLabel.group][submitButtonLabel.name][locale]
          : editMode
          ? buttons.common.update[locale]
          : buttons.common.submit[locale]}
          
      </button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    buttons: state.intl.messages.components.buttons,
  };
};

export default connect(mapStateToProps)(FormButtons);
