import React from "react";
import styled from "styled-components";

const StyledSpan = styled.span`
  margin-left: 16px;
`;
const Question = ({ question, handleOptionSelect, currentAnswer }) => {
  return (
    <div>
      <p className="title is-6 has-text-weight-bold">{`${question.queryNumberText}) ${question.text}`}</p>
      <br />
      <div className="control">
        {question.options.map(({ option, value }, i) => (
          <div key={i}>
            <label
              className="radio box"
              style={{ padding: 8, marginBottom: 8, width: "100%" }}
            >
              <span
                className={`${currentAnswer.a === i && "has-text-success"}`}
              >
                <input
                  type="radio"
                  name={question.queryNumberText}
                  value={i}
                  data-score={value}
                  data-calc={question.calculateTotal}
                  onChange={(e) => handleOptionSelect(e)}
                  checked={currentAnswer.a === i}
                />
                <StyledSpan>{option}</StyledSpan>
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Question;
