const userHomeConfig = {
  layout: {
    main: {
      columns: "columns is-2 is-variable is-mobile is-multiline",
    },
    petProfiles: {
      column: "column is-2-desktop is-4-mobile is-3-tablet",
    },
    litters: {
      column: "column is-2-desktop is-4-mobile is-3-tablet",
    },
  },
  userHomeWithMessage: {
    columns: "columns is-mobile is-centered",
    column: "column is-12-mobile is-5-desktop is-5-tablet",
  },
}

export default userHomeConfig
