import React from "react";
import Section from "components/layout/Section";

import CalorieCalculatorForm from "forms/Calorie_Calculator_Form_NEW";
import LandingPageInfo from "components/features/Landing_Page_Info";
import FormWrapper from "components/layout/Form_Wrapper";
import { returnNewObjectAsArrayByValidKey } from "functions";

function CalorieCalculator(props) {
  const { messages, locale } = props;
  const pageInfo = returnNewObjectAsArrayByValidKey(
    messages.calorieCalculator,
    "info_",
    locale
  );
  return (
    <>
      <Section>
        <FormWrapper>
          <LandingPageInfo pageInfo={pageInfo} />
        </FormWrapper>
      </Section>
      <Section>
        <FormWrapper>
          <CalorieCalculatorForm {...props} />
        </FormWrapper>
      </Section>
    </>
  );
}

export default CalorieCalculator;
