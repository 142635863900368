import {
  LITTERS_FETCH,
  LITTERS_ADD,
  LITTERS_UPDATE,
  LITTERS_DELETE,
  LITTERS_SELECT_LITTER,
  LITTERS_UNSELECT_LITTER,
  LITTERS_FETCH_LITTER_MEMBERS,
  LITTERS_ADD_LITTER_MEMBER,
  LITTERS_SELECT_LITTER_MEMBER,
  LITTERS_UNSELECT_LITTER_MEMBER,
  LITTERS_UPDATE_MEMBER,
  AUTH_LOG_OUT_USER,
  LITTERS_DELETE_MEMBER,
  TRANSFERS_UPDATE_PET_AND_TRANSFER,
} from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";
import _ from "lodash";

const INITIAL_STATE = {
  data: [],
  fetched: false,
  selectedLitter: {},
  litterMembers: {},
  litterMembersFetched: [],
  selectedLitterMember: {},
};

export default function LittersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LITTERS_FETCH:
      return {
        ...state,
        data: _.uniqWith([...state.data, ...action.payload], _.isEqual),
        fetched: true,
      };
    case LITTERS_ADD:
      return {
        ...state,
        data: _.sortBy(
          [...state.data, action.payload],
          "dateCreated"
        ).reverse(),
      };
    case LITTERS_UPDATE:
      return {
        ...state,
        data: _.sortBy(
          [
            ...state.data.filter((litter) => litter._id !== action.payload._id),
            action.payload,
          ],
          "dateCreated"
        ),
        selectedLitter: action.payload,
      };
    case LITTERS_DELETE:
      return {
        ...state,
        data: state.data.filter((litter) => litter._id !== action.payload),
      };
    case LITTERS_SELECT_LITTER:
      return { ...state, selectedLitter: action.payload };
    case LITTERS_UNSELECT_LITTER:
      return { ...state, selectedLitter: {} };

    // LITTER MEMBER CASES
    case TRANSFERS_UPDATE_PET_AND_TRANSFER:
      return {
        ...state,
        litterMembers: {},
        litterMembersFetched: [],
      };
    case LITTERS_FETCH_LITTER_MEMBERS:
      return {
        ...state,
        litterMembers: {
          ...state.litterMembers,
          [action.payload._litter]: action.payload.litterMembers,
        },
        litterMembersFetched: _.uniqWith(
          [...state.litterMembersFetched, action.payload._litter],
          _.isEqual
        ),
      };
    case LITTERS_ADD_LITTER_MEMBER:
      return {
        ...state,
        litterMembers: {
          ...state.litterMembers,
          [action.payload._litter]: [
            ...state.litterMembers[action.payload._litter],
            action.payload,
          ],
        },
      };
    case LITTERS_UPDATE_MEMBER:
      return {
        ...state,
        litterMembers: {
          ...state.litterMembers,
          [action.payload._litter]: _.sortBy(
            [
              ...state.litterMembers[action.payload._litter].filter(
                (profile) => profile._id !== action.payload._id
              ),
              action.payload,
            ],
            "dateCreated"
          ),
        },
        selectedLitterMember: action.payload,
      };
    case LITTERS_DELETE_MEMBER:
      return {
        ...state,
        litterMembers: {
          ...state.litterMembers,
          [action.payload._litter]: _.sortBy(
            [
              ...state.litterMembers[action.payload._litter].filter(
                (profile) => profile._id !== action.payload._id
              ),
            ],
            "dateCreated"
          ),
        },
      };
    case LITTERS_SELECT_LITTER_MEMBER:
      return { ...state, selectedLitterMember: action.payload };
    case LITTERS_UNSELECT_LITTER_MEMBER:
      return { ...state, selectedLitterMember: {} };

    // Logout and default cases

    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
