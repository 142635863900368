import axios from "axios"
import moment from "moment"
import _ from "lodash"
import {
  ACTION_STARTED,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
  MODALS_HIDE,
  TABLE_CLEAR_CURRENT_STATE,
} from "actions/types"
import { catchBlockError, endAndResetAction } from "actions/functions"

export const STRESS_LEVEL_FETCH = "stress_level_fetch"
export const STRESS_LEVEL_FETCH_TYPE = "stress_level_fetch_type"
export const STRESS_LEVEL_ADD = "stress_level_add"
export const STRESS_LEVEL_UPDATE = "stress_level_update"
export const STRESS_LEVEL_DELETE = "stress_level_delete"

export const actionTypes = [
  STRESS_LEVEL_FETCH_TYPE,
  STRESS_LEVEL_FETCH,
  STRESS_LEVEL_ADD,
  STRESS_LEVEL_UPDATE,
  STRESS_LEVEL_DELETE,
]

const API = (route) => `/api/stress_level/${route}`

// Action Creators
export const fetchStressLevelEntries = (
  value,
  unit,
  name,
  pet,
  providerView
) => async (dispatch) => {
  const dates = !_.isEmpty(value)
    ? { ...value }
    : {
        from: moment().subtract(value, unit).toISOString(),
        to: moment().toISOString(),
      }
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({
      type: STRESS_LEVEL_FETCH_TYPE,
      payload: { dates, type: !_.isEmpty(value) ? "range" : name },
    })
    dispatch({ type: ACTION_STARTED, payload: STRESS_LEVEL_FETCH })
    const res = await axios.get(
      API(providerView ? "fetch_for_provider" : "fetch"),
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
        params: { _pet: pet._id, petType: pet.petType, ...dates },
      }
    )
    dispatch({
      type: STRESS_LEVEL_FETCH,
      payload: res.data.stressLevelEntries,
      pet,
    })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addStressLevelEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: STRESS_LEVEL_ADD })
    const res = await axios.post("/api/stress_level/add", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({ type: ACTION_ENDED })
    dispatch({ type: STRESS_LEVEL_ADD, payload: res.data.stressLevelEntry })
    dispatch({ type: ACTION_RESET })
    return history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const updateStressLevelEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: STRESS_LEVEL_UPDATE })
    const res = await axios.put("/api/stress_level/update", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({
      type: STRESS_LEVEL_UPDATE,
      payload: res.data.stressLevelEntry,
    })
    dispatch({ type: ACTION_ENDED })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    history.push(successRoute)
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const deleteStressLevelEntry = (_id, callback) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: STRESS_LEVEL_DELETE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      "/api/stress_level/delete",
      { _id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({
      type: STRESS_LEVEL_DELETE,
      payload: res.data.stressLevelEntry,
    })
    dispatch({ type: ACTION_ENDED })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    callback()
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const stressLevelActions = {
  fetchStressLevelEntries,
  addStressLevelEntry,
  updateStressLevelEntry,
  deleteStressLevelEntry,
}

export default stressLevelActions
