import React, { Component } from "react";
import { connect } from "react-redux";
import Section from "components/layout/Section";
import FeedbackForm from "forms/Feedback_Form";
import Message from "components/message";

class Feedback extends Component {
  renderContent() {
    const { isAuthed } = this.props;
    if (!isAuthed) {
      return <Message warning message={"featureMustBeLoggedIn"} />;
    }
    return (
      <div className="columns is-mobile is-centered">
        <div className="column is-5-desktop is-full-mobile is-6-tablet">
          <FeedbackForm />
        </div>
      </div>
    );
  }
  render() {
    return <Section>{this.renderContent()}</Section>;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthed: state.reducers.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Feedback);
