import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { vacs as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./fields/vaccinations";
import FormButtons from "components/form/FormButtons";

class LitterVaccinationForm extends Component {
  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  }

  render() {
    const { errors, dirty, isSubmitting, editMode, viewMode, cancel } =
      this.props;
    return (
      <Form>
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={"/userhome/dashboard/litter/vaccinations"}
        />
        <br />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    _litter: state.reducers.litters.selectedLitter._id,
  };
};

LitterVaccinationForm = withFormik({
  mapPropsToValues({ _litter }) {
    return {
      date: moment().format("YYYY-MM-DD"),
      expiry: moment().add(1, "year").format("YYYY-MM-DD"),
      vaccine: "",
      batchNo: "",
      vetsName: "",
      vetsID: "",
      _litter,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    expiry: Yup.date().required("required"),
    vaccine: Yup.string().required("required").max(30, "max-30"),
    batchNo: Yup.string().max(30, "max-30"),
    vetsName: Yup.string().max(30, "max-30"),
    vetsID: Yup.string().max(30, "max-30"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    return props.addVacsForLitter(
      values,
      props.litterMembers,
      setSubmitting,
      () =>
        props.history.push(
          "/userhome/dashboard/litter/vaccinations/view_entries"
        )
    );
  },
})(LitterVaccinationForm);

export default withRouter(
  connect(mapStateToProps, actions)(LitterVaccinationForm)
);
