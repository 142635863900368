import React from 'react';
import HelpTitle from "./HelpTitle";
import HelpText from "./HelpText";
import HelpBullet from "./HelpBullet";
import HelpLabel from "./HelpLabel";
import HelpLink from "./HelpLink";

function HelpItem(props) {
  switch (props.type) {
    case "title":
      return <HelpTitle {...props} />;
    case "text":
      return <HelpText {...props} />;
    case "bullet":
      return <HelpBullet {...props} />;
    case "label":
      return <HelpLabel {...props} />;
    case "link":
      return <HelpLink {...props} />;
    default:
      return null;
  }
}

export default HelpItem;
