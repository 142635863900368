import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchFdes } from "actions/food_diary";
import Section from "components/layout/Section";
import Message from "components/message";
import FetchEntries from "components/fetch";
import Table from "components/table_2";
import config from "./config";

class ViewEntries extends Component {
  handleFetchEntries = (value, unit, name) => {
    const { pet } = this.props;
    this.props.fetchFdes(value, unit, name, pet);
  };

  renderSectionContent() {
    const { foodDiary, pet, defaultRoute, messages } = this.props;
    const { entries } = foodDiary;
    const entriesFiltered = entries.filter((entry) => entry._pet === pet._id);
    const filterByItems = [
      ...new Set(entriesFiltered.map((entry) => entry.foodCategory)),
    ];

    // Create data set for table
    const tableData = {
      feature: "foodDiary",
      pet: pet._id,
      id: Date.now(),
      headers: config.tableView.headers,
      mobileHeaders: config.tableView.mobileHeaders,
      data: entriesFiltered,
      messages: { ...messages.foodCategories, ...messages.foodAmountUnit },
      filterByItems,
      filterKey: "foodCategory",
      viewEntryTo: `${defaultRoute}/view_entry/`,
      fetchDates: foodDiary.fetchDates,
      fetchType: foodDiary.fetchType,
    };

    if (entriesFiltered.length === 0) {
      return <Message warning message={"noEntriesToDisplayRetry"} />;
    }

    return <Table tableData={tableData} itemsPerPage={5} />;
  }

  render() {
    const { fetchType, lastFetchPetID } = this.props.foodDiary;
    const { pet } = this.props;
    return (
      <>
        <FetchEntries
          fetchItems={this.handleFetchEntries}
          fetchType={fetchType}
          newPet={pet._id === lastFetchPetID}
          fetchButtons={["last7Days", "last30Days", "last3Months", "last1Year"]}
        />
        <Section>{this.renderSectionContent()}</Section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchFdes,
      dispatch,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => {
  return {
    pet: state.reducers.litters.selectedLitterMember,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewEntries);
