import React from "react"
import { connect } from "react-redux"
import * as actions from "./actions"
import Menu from "components/menu"
import config from "./config"

function StressLevel(props) {
  return <Menu {...config} {...props} />
}

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.containers.features.stressLevel,
    stressLevel: state.reducers.stressLevel,
  }
}

export default connect(mapStateToProps, actions)(StressLevel)
