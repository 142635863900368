import axios from "axios"

import {
  CAMPAIGNS_REDEEM_CODE,
  ACTION_STARTED,
  ACTION_ERROR,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
  AUTH_FETCH_CURRENT_USER,
} from "./types"

export const redeemCode = (code, history) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_RESET })
    dispatch({ type: ACTION_STARTED, payload: CAMPAIGNS_REDEEM_CODE })
    const res = await axios.post(
      "/api/campaigns/redeem_code",
      { code },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({
      type: AUTH_FETCH_CURRENT_USER,
      payload: res.data.user,
    })
    dispatch({ type: ACTION_ENDED })
    if (res.data.opUser) {
      dispatch({
        type: ACTION_SUCCESS,
        payload: "opCodeRedeemSuccess",
      })
    } else {
      dispatch({
        type: ACTION_SUCCESS,
        payload: "codeRedeemSuccess",
      })
    }
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
      history.push("/userhome")
    }, 3000)
  } catch (error) {
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

const campaignsActions = {
  redeemCode,
}

export default campaignsActions
