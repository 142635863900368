import main from "./training_diary.svg";
import newEntry from "./training_diary_new_entry.svg";
import viewEntries from "./training_diary_view_entries.svg";
import settings from "./settings.svg";

const trainingDiary = {
  main,
  newEntry,
  viewEntries,
  settings,
};

export default trainingDiary;
