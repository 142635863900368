import React, { useEffect } from "react";
import { connect } from "react-redux";
import Message from "components/message";

function LitterMemberSelection(props) {
  const {
    unselectAll,
    unselectOne,
    selectAll,
    selectOne,
    allMembersSelected,
    litterMembers,
    memberSelected,
    buttons,
    locale,
    membersHaveNewOwners,
  } = props;

  useEffect(() => {
    unselectAll();

    return () => {
      unselectAll();
    };
  }, [unselectAll]);

  if (litterMembers.length === 0) {
    return <Message warning message="litterRequiresLitterMembers" />;
  }

  // check to see if all litter members have new owners
  // if they do, render message to alert that
  if (!membersHaveNewOwners.includes(false))
    return <Message warning message="allMembersHaveNewOwners" />;

  return (
    <div>
      <div className="buttons is-centered">
        <div
          className={`button ${
            allMembersSelected ? "is-danger" : "is-success"
          }`}
          onClick={
            allMembersSelected
              ? () => unselectAll()
              : () => selectAll(litterMembers)
          }
        >
          {allMembersSelected
            ? buttons.unselectAll[locale]
            : buttons.selectAll[locale]}
        </div>
      </div>

      <div className="tags are-medium is-centered">
        {litterMembers.map((member) => {
          if (member.hasNewOwner) return null;
          return (
            <div
              key={member._id}
              style={{ cursor: "pointer" }}
              className={`tag ${memberSelected(member._id) && "is-success"}`}
              onClick={
                memberSelected(member._id) ? null : () => selectOne(member)
              }
            >
              {member.petName}
              {memberSelected(member._id) && (
                <button
                  className="delete is-small"
                  onClick={() => unselectOne(member._id)}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { litters, litterMembersSelected } = state.reducers;
  const litterId = litters.selectedLitter._id;
  const memberSelected = (_id) =>
    litterMembersSelected.some((obj) => obj._id === _id);
  const membersHaveNewOwners = litters.litterMembers[litterId].map(
    (member) => member.hasNewOwner
  );
  const litterMembers = litters.litterMembers[litterId].filter(
    (member) => !member.hasNewOwner
  );
  const allMembersSelected =
    litterMembers.length === litterMembersSelected.length;
  return {
    allMembersSelected,
    litterMembersSelected,
    memberSelected,
    litterMembers,
    locale: state.intl.locale,
    buttons: state.intl.messages.components.buttons.common,
    membersHaveNewOwners,
  };
};

export default connect(mapStateToProps)(LitterMemberSelection);
