import React from "react";
import ExternalLink from "components/elements/External_Link";
import { returnNewObjectAsArrayByValidKey } from "functions";

export default function FeatureTitleCard({
  routeName,
  routedItems,
  useMedia,
  useLogo,
  logo,
  icon,
  maxWidth,
  messages,
  locale,
  children,
}) {
  const pageInfo = returnNewObjectAsArrayByValidKey(
    messages[routeName],
    "info_",
    locale
  );

  return (
    <div className="card">
      <div className="card-content">
        {useMedia && (
          <div className="columns">
            <div className="column"></div>
            <div
              className="column is-one-quarter is-flex"
              style={{ justifyContent: "center" }}
            >
              <div className="media">
                <figure className="image">
                  <img
                    src={useLogo ? logo : icon}
                    alt={"feature_logo_or_icon"}
                    style={{ maxWidth: 400 }}
                  />
                </figure>
                <div className="media-content">
                  {!useLogo && (
                    <p className="title is-4 has-text-dark">
                      {messages.main.title[locale]}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="column"></div>
          </div>
        )}
        <div className="content">
          {pageInfo.map((item, i) => {
            if (item.slice(0, 5) === "https")
              return (
                <p key={i}>
                  <ExternalLink key={i} href={item} label={item} />
                </p>
              );
            return <p key={i}>{item}</p>;
          })}
          {children && children}
        </div>
      </div>
    </div>
  );
}
