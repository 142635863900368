import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import FormButtons from "components/form/FormButtons";
import { INPUT_FIELDS } from "./fields";
import Intl from "components/Intl";

class ActivityEntryForm extends Component {
  renderFields = () => {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  };

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      editMode,
      viewMode,
      cancel,
      values,
      defaultRoute,
    } = this.props;
    return (
      <div>
        <Form>
          <div className="columns is-1 is-variable is-mobile is-multiline">
            <div className="column">
              <div className="box">
                <p>
                  <label>
                    <span>
                      <strong>
                        <Intl
                          message
                          path="components.forms.labels.activity.category"
                        />
                      </strong>
                      {": "}
                      <Intl
                        message
                        path={`components.forms.selectOptions.activityCategories.${values.category}`}
                      />
                    </span>
                  </label>
                </p>
                <p>
                  <span>
                    <strong>
                      <Intl
                        message
                        path="components.forms.labels.activity.activity"
                      />
                    </strong>
                    {": "}
                    <Intl
                      message
                      path={`components.forms.selectOptions.activityActivities.${values.activity}`}
                    />
                  </span>
                </p>
              </div>
              {this.renderFields()}
              <br />
            </div>
          </div>
          <FormButtons
            viewMode={viewMode}
            editMode={editMode}
            isSubmitting={isSubmitting}
            errors={errors}
            dirty={dirty}
            cancel={cancel}
            linkTo={defaultRoute}
          />
          <br />
        </Form>
      </div>
    );
  }
}

ActivityEntryForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet, history }) {
    return {
      date:
        moment(valuesToEdit.date).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      type: valuesToEdit.type || "dailyActivity",
      category: valuesToEdit.category || history.location.state.category,
      activity: valuesToEdit.activity || history.location.state.activity,
      activityTimeHrs: valuesToEdit.activityTimeHrs || 0,
      activityTimeMins: valuesToEdit.activityTimeMins || 0,
      notes: valuesToEdit.notes || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    activityTimeHrs: Yup.number(),
    activityTimeMins: Yup.number(),
    notes: Yup.string().max(250, "max-250"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries_daily_activity`;
    if (props.editMode) {
      return props.updateDailyActivityEntry(
        values,
        setSubmitting,
        props.history,
        successRoute
      );
    }
    return props.addDailyActivityEntry(
      values,
      setSubmitting,
      props.history,
      successRoute
    );
  },
})(ActivityEntryForm);

export default ActivityEntryForm;
