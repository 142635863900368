import React from "react";
import _ from "lodash";
import FilterBy from "./Filter_By";

function Filters(props) {
  const {
    filterByItems,
    handleSetFilterBy,
    filterBy,
    messages,
    locale,
    customFilter1,
    handleSetCustomFilterBy1,
    customFilter2,
    handleSetCustomFilterBy2,
    customFilter3,
    handleSetCustomFilterBy3,
  } = props;

  if (filterByItems.length <= 1) return null;

  return (
    <div className="level is-mobile">
      <div className="level-left">
        <div className="level-item">
          <FilterBy
            setFilterBy={handleSetFilterBy}
            filterBy={filterBy}
            filterByItems={filterByItems}
            messages={messages}
            locale={locale}
          />
        </div>
        {!_.isEmpty(customFilter1) && (
          <div className="level-item">
            <FilterBy
              disabled={!customFilter1.renderCondition(filterBy)}
              customFilterName={customFilter1.name}
              setFilterBy={handleSetCustomFilterBy1}
              filterBy={customFilter1.filterBy}
              filterByItems={customFilter1.filterByItems}
              messages={messages}
              locale={locale}
            />
          </div>
        )}
        {!_.isEmpty(customFilter2) && (
          <div className="level-item">
            <FilterBy
              disabled={!customFilter2.renderCondition(customFilter1.filterBy)}
              customFilterName={customFilter2.name}
              setFilterBy={handleSetCustomFilterBy2}
              filterBy={customFilter2.filterBy}
              filterByItems={customFilter2.filterByItems}
              messages={messages}
              locale={locale}
            />
          </div>
        )}
        {!_.isEmpty(customFilter3) && (
          <div className="level-item">
            <FilterBy
              disabled={!customFilter3.renderCondition(customFilter2.filterBy)}
              customFilterName={customFilter3.name}
              setFilterBy={handleSetCustomFilterBy3}
              filterBy={customFilter3.filterBy}
              filterByItems={customFilter3.filterByItems}
              messages={messages}
              locale={locale}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Filters;
