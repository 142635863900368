import main from "./body_marker.svg";
import markers from "./body_marker_view_entries.svg";
import archive from "./body_marker_archive.svg";
import dog from "./dog_body";
import cat from "./cat_body";

const bodyMarker = {
  main,
  markers,
  archive,
  dog,
  cat,
};

export default bodyMarker;
