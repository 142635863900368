import React from "react";
import { connect } from "react-redux";
import * as actions from "actions/transfers";
import Section from "components/layout/Section";
import BoxWithBackground from "components/styled/Box_With_Background";
import SectionTitle from "components/layout/Section_Title";
import TransferLitterMemberForm from "forms/Transfer_Litter_Member_Form";
import RequireSubFeature from "components/hoc/Require_Sub_Feature";

function Transfer(props) {
  function handleTransferRequest(values, setSubmitting) {
    props.transferLitterMember(values, setSubmitting);
  }

  function handleReAttemptAtTransfer(values, setSubmitting) {
    const prevAttemptId = props.transferObject._id;
    props.reAttemptTransferLitterMember(values, prevAttemptId, setSubmitting);
  }

  return (
    <BoxWithBackground>
      <Section>
        <SectionTitle
          title={props.messages.titles.transferLitterMember}
          subtitle={""}
        />
        <br />
        <TransferLitterMemberForm
          reAttempt={props.reAttempt}
          litterMember={props.selectedLitterMember}
          handleTransferRequest={handleTransferRequest}
          handleReAttemptAtTransfer={handleReAttemptAtTransfer}
        />
      </Section>
    </BoxWithBackground>
  );
}

export default RequireSubFeature(connect(null, actions)(Transfer));
