import { features } from "assets"
import Landing from "components/features/Landing"
import NewEntry from "./New_Entry_View"
import ViewEntries from "./View_Entries"
import ViewEntry from "./View_Entry"

const stableDiaryConfig = {
  name: "stableDiary",
  type: "feature",
  defaultRoute: "/userhome/dashboard/stable_diary",
  menuIcon: { img: features.stableDiary.main, alt: "stable_diary_icon" },
  nav: [
    {
      name: "landing",
      disabled: false,
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      name: "newEntry",
      disabled: false,
      menuItem: true,
      image: {
        img: features.stableDiary.newEntry,
        alt: "stable_diary_new_entry_icon",
      },
      icon: "fas fa-heart",
      component: NewEntry,
      path: "/new_entry",
    },
    {
      name: "viewEntries",
      disabled: false,
      menuItem: true,
      image: {
        img: features.stableDiary.viewEntries,
        alt: "stable_diary_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
    {
      name: "viewEntry",
      disabled: false,
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
  ],
  tableView: {
    headers: ["date", "time", "subject"],
    mobileHeaders: ["date", "time", "subject"],
  },
}

export default stableDiaryConfig
