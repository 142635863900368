import React, { Component } from "react"
import { connect } from "react-redux"
import Section from "components/layout/Section"
import Cloudinary from "containers/main/Cloudinary"

class ProfileImage extends Component {
  render() {
    return (
      <Section>
        <Cloudinary {...this.props} />
      </Section>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps)(ProfileImage)
