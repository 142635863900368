import {
  LITTER_MEMBERS_SELECT_ONE,
  LITTER_MEMBERS_SELECT_ALL,
  LITTER_MEMBERS_UNSELECT_ONE,
  LITTER_MEMBERS_UNSELECT_ALL,
} from "actions/types"

const INITIAL_STATE = []

export default function LitterMembersSelectedReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case LITTER_MEMBERS_SELECT_ONE:
      // Take the object and spread it in existing array
      return [...state, action.payload]
    case LITTER_MEMBERS_SELECT_ALL:
      // Take the array from the payload and set as current state
      return action.payload
    case LITTER_MEMBERS_UNSELECT_ONE:
      // Take the array and filter out by id
      return [...state.filter((obj) => obj._id !== action.payload)]
    case LITTER_MEMBERS_UNSELECT_ALL:
      // Return initial state
      return INITIAL_STATE
    default:
      return state
  }
}
