import { features } from "assets"

export const AWARD_TYPES = [
  {
    name: "rosette",
    img: features.awardCabinet.awardTypes.rosette,
    petTypes: "all",
  },
  {
    name: "trophy",
    img: features.awardCabinet.awardTypes.trophy,
    petTypes: "all",
  },
  {
    name: "medal",
    img: features.awardCabinet.awardTypes.medal,
    petTypes: "all",
  },
]

export const COLOR_PALETTE = [
  "#fdba2b",
  "#fad263",
  "#c2cece",
  "#dae4e5",
  "#e29159",
  "#f6a264",
  "#000000",
  "#ffffff",
  "#ffe576",
  "#ffeb3b",
  "#ff9800",
  "#ff5722",
  "#ff4a4a",
  "#ff866e",
  "#d80000",
  "#e91e63",
  "#ff7bac",
  "#ff48b9",
  "#dc4dff",
  "#9c27b0",
  "#673ab7",
  "#000968",
  "#004fc1",
  "#00a0df",
  "#55dfff",
  "#b0f4ff",
  "#00ff90",
  "#00dcab",
  "#dfff9b",
  "#cddc39",
  "#8bc34a",
  "#00c059",
  "#009245",
  "#607d8b",
  "#998675",
  "#795548",
]

export const AWARD_TYPE_SETTINGS = {
  rosette: {
    sections: [
      {
        name: "rosetteBodyOuterFeathers",
        id: "rosette_body_outer_feathers",
        toggleVis: false,
        defaultColor: "#ff4a4a",
      },
      {
        name: "rosetteBodyInnerFeathers",
        id: "rosette_body_inner_feathers",
        toggleVis: true,
        defaultColor: "#ff866e",
      },
      {
        name: "rosetteBodyScallops",
        id: "rosette_body_scallops",
        toggleVis: true,
        defaultColor: "#fff",
      },
      {
        name: "rosetteBodyOuterRing",
        id: "rosette_body_outer_ring",
        toggleVis: false,
        defaultColor: "#fc6",
      },
      {
        name: "rosetteBodyCentre",
        id: "rosette_body_centre",
        toggleVis: false,
        defaultColor: "#f43737",
      },
      {
        name: "rosetteRibbonsRibbonLeft",
        id: "rosette_ribbons_ribbon_left",
        toggleVis: false,
        defaultColor: "#ff4a4a",
      },
      {
        name: "rosetteRibbonsRibbonCentre",
        id: "rosette_ribbons_ribbon_centre",
        toggleVis: true,
        defaultColor: "#ff866e",
      },
      {
        name: "rosetteRibbonsRibbonRight",
        id: "rosette_ribbons_ribbon_right",
        toggleVis: false,
        defaultColor: "#ff4a4a",
      },
    ],
  },
  trophy: {
    sections: [
      {
        name: "trophyCupBody",
        id: "trophy_cup_body",
        toggleVis: false,
        defaultColor: "#fdba2b",
      },
      {
        name: "trophyCupStripe",
        id: "trophy_cup_stripe",
        toggleVis: false,
        defaultColor: "#ff9800",
      },
      {
        name: "trophyCupTopBling",
        id: "trophy_cup_top_bling",
        toggleVis: true,
        defaultColor: "#ff4a4a",
      },
      {
        name: "trophyCupBottomBling1",
        id: "trophy_cup_bottom_bling_1",
        toggleVis: true,
        defaultColor: "#ff4a4a",
      },
      {
        name: "trophyCupBottomBling2",
        id: "trophy_cup_bottom_bling_2",
        toggleVis: true,
        defaultColor: "#673ab7",
      },
      {
        name: "trophyColumnBody",
        id: "trophy_column_body",
        toggleVis: false,
        defaultColor: "#fdba2b",
      },
      {
        name: "trophyColumnHandles1",
        id: "trophy_column_handles_1",
        toggleVis: true,
        defaultColor: "#ff9800",
      },
      {
        name: "trophyColumnHandles2",
        id: "trophy_column_handles_2",
        toggleVis: true,
        defaultColor: "#ff9800",
      },
      {
        name: "trophyColumnStripes",
        id: "trophy_column_stripes",
        toggleVis: false,
        defaultColor: "#ff9800",
      },
      {
        name: "trophyBaseBody",
        id: "trophy_base_body",
        toggleVis: false,
        defaultColor: "#998675",
      },
      {
        name: "trophyBasePlaque",
        id: "trophy_base_plaque",
        toggleVis: false,
        defaultColor: "#fdba2b",
      },
    ],
  },
  medal: {
    sections: [
      {
        name: "medalOuterRing",
        id: "medal_outer_ring",
        toggleVis: false,
        defaultColor: "#fad263",
      },
      {
        name: "medalOuterLoop",
        id: "medal_outer_loop",
        toggleVis: false,
        defaultColor: "#fdba2b",
      },
      {
        name: "medalInnerBody",
        id: "medal_inner_body",
        toggleVis: false,
        defaultColor: "#fdba2b",
      },
      {
        name: "medalInnerBling",
        id: "medal_inner_bling",
        toggleVis: true,
        defaultColor: "#fad263",
      },
      {
        name: "medalInnerTwinStars",
        id: "medal_inner_twin_stars",
        toggleVis: true,
        defaultColor: "#fad263",
      },
      {
        name: "medalInnerMidStar",
        id: "medal_inner_mid_star",
        toggleVis: true,
        defaultColor: "#fad263",
      },
      {
        name: "medalInnerBigStar",
        id: "medal_inner_big_star",
        toggleVis: true,
        defaultColor: "#fad263",
      },
    ],
  },
}

const awardCabinet = {
  AWARD_TYPES,
  COLOR_PALETTE,
  AWARD_TYPE_SETTINGS,
}

export default awardCabinet
