import React, { Component } from "react"
import Section from "components/layout/Section"
import AddExpense from "./Add_Expense"

class NewEntryView extends Component {
  render() {
    return (
      <Section>
        <AddExpense {...this.props} />
      </Section>
    )
  }
}

export default NewEntryView
