import React, { Component } from "react"
import ParasiteForm from "forms/Parasite_Form"

class NewEntry extends Component {
  render() {
    return (
      <div className="columns is-mobile is-centered">
        <div className="column is-5-desktop is-full-mobile is-6-tablet">
          <ParasiteForm valuesToEdit={{}} {...this.props} />
        </div>
      </div>
    )
  }
}

export default NewEntry
