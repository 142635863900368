import React, { Component } from "react";
import { connect } from "react-redux";
import { litters } from "actions";
import { bindActionCreators } from "redux";
import _ from "lodash";
import Bounds from "components/bounds";
import FEATURES from "constants/routes";
import LittersSection from "containers/features/Litters/Litters_Section";
import config from "./config";
import { renderMeOnClient } from "functions";
import SmartTile from "components/SmartTile";

class Dashboard extends Component {
  state = {
    featureOrderSet: false,
    features: [],
  };

  componentDidMount() {
    const { pet, history } = this.props;
    if (_.isEmpty(pet)) {
      return history.push("/userhome");
    }
    this.props.unselectLitter();
    this.props.unselectLitterMember();
    let features = config.featureOrder.map((name) => {
      return FEATURES.find((feature) => feature.name === name);
    });
    this.setState({ featureOrderSet: true, features });
  }

  render() {
    if (!this.state.featureOrderSet) return null;
    return (
      <Bounds
        maxWidth="600"
        render={() => (
          <div style={{ paddingLeft: 8, paddingRight: 8, marginBottom: 20 }}>
            <Content {...this.props} features={this.state.features} />
          </div>
        )}
        else={() => <Content {...this.props} features={this.state.features} />}
      />
    );
  }
}

const Content = (props) => (
  <div className={config.layout.columns}>
    <div className={config.layout.column}>
      <SmartTile
        petTile
        dashboard
        path={"/userhome/dashboard/pet_profile"}
        pet={props.pet}
      />
    </div>
    {props.features.map((feature, i) => {
      if (props.userDashboard[feature.name] === false) return null;
      if (!feature.feature) return null;
      if (feature.litterFeature) return null;
      if (feature.litterMemberFeature) return null;
      if (!feature.petTypes.includes(props.pet.petType)) return null;
      if (feature.petSex && feature.petSex !== props.pet.petSex) return null;
      if (!renderMeOnClient(feature.clients)) return null;
      return (
        <div key={feature.name} className={config.layout.column}>
          <SmartTile featureTile {...feature} />
        </div>
      );
    })}
    <Bounds
      maxWidth="800"
      render={() => (
        <LittersSection
          isMobile
          config={config}
          selectedPetId={props.pet._id}
        />
      )}
      else={() => (
        <LittersSection config={config} selectedPetId={props.pet._id} />
      )}
    />
  </div>
);

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: state.intl.messages,
    pet: state.reducers.pets.selectedPet,
    userDashboard: state.reducers.auth.user.featurePreferences.dashboard,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      unselectLitter: litters.unselectLitter,
      unselectLitterMember: litters.unselectLitterMember,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
