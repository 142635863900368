import { InputFieldV5, SelectV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    required: true,
    component: InputFieldV5,
  },
  {
    name: "expiry",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    required: true,
    component: InputFieldV5,
  },
  {
    name: "parasiteType",
    group: "parasites",
    type: "select",
    icon: "fa fa-paw",
    className: "select ",
    required: true,
    component: SelectV5,
  },
  {
    name: "productUsed",
    group: "parasites",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    required: true,
    component: InputFieldV5,
  },
];

export { INPUT_FIELDS };
