import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Bounds from "components/bounds";
import FEATURES from "constants/feature_routes_provider_view";
import config from "./config";
import { renderMeOnClient } from "functions";
import SmartTile from "components/SmartTile";

class ProviderView extends Component {
  state = {
    featureOrderSet: false,
    features: [],
  };

  componentDidMount() {
    const { pet, history } = this.props;
    if (_.isEmpty(pet)) {
      return history.push("/providerhome");
    }
    let features = config.featureOrderProviderView.map((name) => {
      return FEATURES.find((feature) => feature.name === name);
    });
    this.setState({ featureOrderSet: true, features });
  }

  render() {
    if (!this.state.featureOrderSet) return null;
    return (
      <Bounds
        maxWidth="600"
        render={() => (
          <div style={{ paddingLeft: 8, paddingRight: 8, marginBottom: 20 }}>
            <Content {...this.props} features={this.state.features} />
          </div>
        )}
        else={() => <Content {...this.props} features={this.state.features} />}
      />
    );
  }
}

const Content = (props) => (
  <div className={config.layout.columns}>
    <div className={config.layout.column}>
      <SmartTile
        petTile
        dashboard
        path={"/providerhome/contacts/contact/contacts_pet_dashboard/profile"}
        pet={props.pet}
      />
    </div>
    {props.features.map((feature, i) => {
      if (!feature.petTypes.includes(props.pet.petType)) return null;
      if (feature.petSex && feature.petSex !== props.pet.petSex) return null;
      if (!renderMeOnClient(feature.clients)) return null;
      return (
        <div key={feature.name} className={config.layout.column}>
          <SmartTile featureTile {...feature} />
        </div>
      );
    })}
  </div>
);

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: state.intl.messages,
    pet: state.reducers.pets.selectedPet,
  };
};

export default connect(mapStateToProps, null)(ProviderView);
