import React from "react";
import { connect } from "react-redux";

function LabelOnly({ label }) {
  return (
    <div className="field">
      <br />
      <label className="label is-small">
        <div className="heading has-text-grey">{label}</div>
      </label>
    </div>
  );
}

const mapStateToProps = (state, { group, field }) => {
  const { locale, messages } = state.intl;
  return {
    label: messages.components.forms.labels[group][field.name][locale],
  };
};

export default connect(mapStateToProps)(LabelOnly);
