// Assets
import assets from "assets";

// Constants
import {
  LOCALHOST,
  TASSUAPU_STAGING,
  PETFILER_STAGING,
  TASSUAPU_PROD,
  PETFILER_PROD,
} from "functions/hostname";

// HOCs
import RequireAuth from "components/hoc/Require_Auth";
import RequireSelectedPet from "components/hoc/Require_Selected_Pet";

//Features
import Appointments from "containers/features/Appointments/Provider_View";
import Tasks from "containers/features/Tasks/Provider_View";
import Hcpi from "containers/features/Hcpi/Provider_View";
import Fmpi from "containers/features/Fmpi/Provider_View";
import HealthDiary from "containers/features/Health_Diary/Provider_View";
import Activity from "containers/features/Activity/Provider_View";
import TrainingDiary from "containers/features/Training_Diary/Provider_View";
import HomeCare from "containers/features/Home_Care/Provider_View";
import UsefulLinks from "containers/features/Useful_Links/Provider_View";
import Weight from "containers/features/Weight/Provider_View";
import StressLevel from "containers/features/Stress_Level/Provider_View";
import FoodDiary from "containers/features/Food_Diary/Provider_View";
import Parasites from "containers/features/Parasites/Provider_View";
import Vaccinations from "containers/features/Vaccinations/Provider_View";
import BodyMarker from "containers/features/Body_Marker/Provider_View";
import ToothMarker from "containers/features/Tooth_Marker/Provider_View";

export function path(featureName) {
  const DEFAULT_ROUTE = "/providerhome/contacts/contact/contacts_pet_dashboard";
  return `${DEFAULT_ROUTE}/${featureName}`;
}

const featureRoutesProviderView = [
  // **************************
  //
  // Features
  //
  // **************************
  {
    name: "hcpi",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("hcpi"),
    component: RequireAuth(RequireSelectedPet(Hcpi)),
    petTypes: ["dog"],
    img: {
      src: assets.features.hcpi.main,
      alt: "hcpi_main_logo",
    },
  },
  {
    name: "fmpi",
    disabled: false,
    clients: [LOCALHOST, TASSUAPU_PROD, TASSUAPU_STAGING],
    path: path("fmpi"),
    component: RequireAuth(RequireSelectedPet(Fmpi)),
    petTypes: ["cat"],
    img: {
      src: assets.features.fmpi.main,
      alt: "fmpi_main_logo",
    },
  },
  {
    name: "vaccinations",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("vaccinations"),
    component: RequireAuth(RequireSelectedPet(Vaccinations)),
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.vaccinations.main,
      alt: "vaccinations_main_logo",
    },
  },
  {
    name: "weight",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("weight"),
    component: RequireAuth(RequireSelectedPet(Weight)),
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.weight.main,
      alt: "weight_main_logo",
    },
  },
  {
    name: "foodDiary",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("food_diary"),
    component: RequireAuth(RequireSelectedPet(FoodDiary)),
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.foodDiary.main,
      alt: "food_diary_main_logo",
    },
  },
  {
    name: "healthDiary",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("health_diary"),
    component: RequireAuth(RequireSelectedPet(HealthDiary)),
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.healthDiary.main,
      alt: "health_diary_main_logo",
    },
  },
  {
    name: "parasites",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("parasites"),
    component: RequireAuth(RequireSelectedPet(Parasites)),
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.parasites.main,
      alt: "parasites_main_logo",
    },
  },
  {
    name: "trainingDiary",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("training_diary"),
    component: RequireAuth(RequireSelectedPet(TrainingDiary)),
    petTypes: ["dog", "cat", "horse"],
    img: {
      src: assets.features.trainingDiary.viewEntries,
      alt: "training_diary_feature_logo",
    },
  },
  {
    name: "activity",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("activity"),
    component: RequireAuth(RequireSelectedPet(Activity)),
    petTypes: ["dog"],
    img: {
      src: assets.features.activity.dailyActivityViewEntries,
      alt: "activity_feature_logo",
    },
  },
  {
    name: "stressLevel",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("stress_level"),
    component: RequireAuth(RequireSelectedPet(StressLevel)),
    petTypes: ["dog"],
    img: {
      src: assets.features.stressLevel.main,
      alt: "stress_level_feature_logo",
    },
  },
  {
    name: "usefulLinks",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("useful_links"),
    component: RequireAuth(RequireSelectedPet(UsefulLinks)),
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.usefulLinks.main,
      alt: "useful_links_main_logo",
    },
  },
  {
    name: "homeCare",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("home_care"),
    component: RequireAuth(RequireSelectedPet(HomeCare)),
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.homeCare.main,
      alt: "home_care_feature_logo",
    },
  },
  {
    name: "appointments",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("appointments"),
    component: RequireAuth(RequireSelectedPet(Appointments)),
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.appointments.main,
      alt: "appointments_feature_logo",
    },
  },
  {
    name: "tasks",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("tasks"),
    component: RequireAuth(RequireSelectedPet(Tasks)),
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.tasks.main,
      alt: "tasks_feature_logo",
    },
  },
  {
    name: "bodyMarker",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("body_marker"),
    component: RequireAuth(RequireSelectedPet(BodyMarker)),
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.bodyMarker.main,
      alt: "body_marker_main_logo",
    },
  },
  {
    name: "toothMarker",
    disabled: false,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: path("tooth_marker"),
    component: RequireAuth(RequireSelectedPet(ToothMarker)),
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.toothMarker.main,
      alt: "tooth_marker_main_logo",
    },
  },
];

export default featureRoutesProviderView;
