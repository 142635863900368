import React from "react";

export default function BodyConditionImage(props) {
  return (
    <div className="has-text-centered">
      <figure className="image is-inline-block">
        {props.images.map(
          (item, i) =>
            item.name === props.currentBC && (
              <img key={i} src={item.image} alt={item.name} />
            )
        )}
      </figure>
    </div>
  );
}
