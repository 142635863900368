import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./full_task_fields";
import { FormTitle, FormButtons } from "components/form";

class FullTaskForm extends Component {
  renderFields = () => {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <div className="field" key={i}>
          <Field
            viewMode={viewMode}
            editMode={editMode}
            values={values}
            {...field}
          />
          {field.lastInGroup && <br />}
        </div>
      );
    });
  };

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      editMode,
      viewMode,
      cancel,
      defaultRoute,
    } = this.props;
    return (
      <div>
        <Form>
          <FormTitle group={"fullTask"} />
          <br />
          {this.renderFields()}
          <br />
          <FormButtons
            viewMode={viewMode}
            editMode={editMode}
            isSubmitting={isSubmitting}
            errors={errors}
            dirty={dirty}
            cancel={cancel}
            linkTo={defaultRoute}
          />
          <br />
        </Form>
      </div>
    );
  }
}

FullTaskForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet }) {
    return {
      taskDueDate: moment(valuesToEdit.taskDueDate).format("YYYY-MM-DD") || "",
      taskDueTime: valuesToEdit.taskDueTime || "",
      taskTitle: valuesToEdit.taskTitle || "",
      taskDescription: valuesToEdit.taskDescription || "",
      taskAttachmentURL: valuesToEdit.taskAttachmentURL || "",
      taskCompletionRating: valuesToEdit.taskCompletionRating || "",
      taskCompletionNotes: valuesToEdit.taskCompletionNotes || "",
      taskCompletionURL: valuesToEdit.taskCompletionURL || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    taskDueDate: Yup.date().required("required"),
    taskDueTime: Yup.string(),
    taskTitle: Yup.string().max(50, "max-50").required("required"),
    taskDescription: Yup.string().max(1000, "max-500").required("required"),
    taskAttachmentURL: Yup.string().url("url"),
    taskCompletionRating: Yup.number()
      .min(1, "greaterThanOrEqualTo-1")
      .max(5, "lessThanOrEqualTo-5"),
    taskCompletionNotes: Yup.string().max(500, "max-500"),
    taskCompletionURL: Yup.string().url("url"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    return props.updateTask(values, setSubmitting);
  },
})(FullTaskForm);

export default connect(null, actions)(FullTaskForm);
