import { InputFieldV5, SelectV5, TextareaV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
    readOnly: true,
  },
  {
    name: "feedbackCategory",
    group: "feedback",
    type: "select",
    icon: "far fa-comment",
    className: "select ",
    component: SelectV5,
    required: true,
    orderAZ: true,
  },
  {
    name: "featureCategory",
    group: "common",
    type: "select",
    icon: "far fa-comment",
    className: "select ",
    component: SelectV5,
    required: true,
    orderAZ: true,
  },
  {
    name: "feedback",
    group: "feedback",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: true,
    charLimit: 500,
  },
];

export { INPUT_FIELDS };
