import main from "./activity.svg"
import trainingDiaryNewEntry from "./activity_training_diary_new_entry.svg"
import trainingDiaryViewEntries from "./activity_training_diary_view_entries.svg"
import trainingDiarySettings from "./activity_settings.svg"
import dailyActivityNewEntry from "./activity_daily_activity_new_entry.svg"
import dailyActivityViewEntries from "./activity_daily_activity_view_entries.svg"

const activity = {
  main,
  trainingDiaryNewEntry,
  trainingDiaryViewEntries,
  trainingDiarySettings,
  dailyActivityNewEntry,
  dailyActivityViewEntries,
}

export default activity
