import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchRequests, refreshStatus } from "actions/reporting";
import Section from "components/layout/Section";
import Message from "components/message";
import ReportMediaObject from "./Report_Media_Object";

class ReportRequests extends Component {
  state = { refreshStatusId: "" };

  UNSAFE_componentWillMount() {
    const { pet, reporting } = this.props;
    if (!reporting.fetched.includes(pet._id)) {
      this.props.fetchRequests(pet);
    }
  }

  handledRefreshStatus = (id) => {
    this.setState(
      {
        refreshStatusId: id,
      },
      () => this.props.refreshStatus(id)
    );
  };

  renderSectionContent() {
    const { reporting, pet, actionCalls } = this.props;
    const { requests } = reporting;

    const requestsFiltered = requests.filter(
      (request) => request._pet === pet._id
    );
    if (requestsFiltered.length === 0) {
      return <Message warning message={"noEntriesToDisplayAutoFetch"} />;
    }

    return (
      <div className="columns is-2 is-variable is-mobile is-multiline is-centered">
        {requestsFiltered.map((request, i) => (
          <div key={i} className="column is-3-desktop is-3-tablet">
            <ReportMediaObject
              {...this.props}
              report={request}
              refreshStatus={this.handledRefreshStatus}
              refreshStatusId={this.state.refreshStatusId}
              serverIsBusy={actionCalls.actionInProcess}
            />
          </div>
        ))}
      </div>
    );
  }

  render() {
    return <Section>{this.renderSectionContent()}</Section>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchRequests,
      refreshStatus,
      dispatch,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(ReportRequests);
