import {
  FineWeightControl,
  InputFieldV5,
  CheckboxV5,
  TextareaV5,
  LabelOnly,
  FineWeightControlLBS,
} from "components/form";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "weightInputs",
    group: "weight",
    component: LabelOnly,
  },
  {
    name: "kg",
    type: "number",
    icon: "fas fa-weight",
    className: "input",
    component: FineWeightControl,
    required: true,
    userCanEdit: true,
    renderCondition: (unit) => unit === "kg",
  },
  {
    name: "g",
    type: "number",
    icon: "fas fa-weight",
    className: "input",
    component: FineWeightControl,
    required: true,
    userCanEdit: true,
    renderCondition: (unit) => unit === "kg",
  },
  {
    name: "lbs",
    type: "number",
    icon: "fas fa-weight",
    className: "input",
    component: FineWeightControlLBS,
    required: true,
    userCanEdit: true,
    renderCondition: (unit) => unit === "lbs",
  },
  {
    name: "bodyConditionScore",
    group: "weight",
    type: "number",
    icon: "fas fa-weight",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
    min: 0,
    max: 9,
    step: 1,
    petTypes: ["dog", "cat"],
  },
  {
    name: "notes",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    charLimit: 250,
    userCanEdit: true,
  },
  {
    name: "updatePetProfile",
    group: "weight",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: false,
    userCanEdit: true,
  },
];

export { INPUT_FIELDS };
