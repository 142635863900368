import {
  REPORTING_FETCH_TEMPLATES,
  REPORTING_FETCH_REQUESTS,
  REPORTING_SUBMIT_REQUEST,
  REPORTING_REFRESH_STATUS,
  AUTH_LOG_OUT_USER,
} from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";
import _ from "lodash";

const INITIAL_STATE = {
  templates: [],
  templatesFetched: false,
  requests: [],
  fetched: [],
};

export default function ReportingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REPORTING_FETCH_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
        templatesFetched: true,
      };
    case REPORTING_FETCH_REQUESTS:
      return {
        ...state,
        requests: _.sortBy(
          [
            ...action.payload,
            ...state.requests.filter((item) => item._id === action.payload._id),
          ],
          "dateCreated"
        ).reverse(),
        fetched: [action.pet._id, ...state.fetched],
      };
    case REPORTING_SUBMIT_REQUEST:
      return {
        ...state,
        requests: _.sortBy(
          [action.payload, ...state.requests],
          "dateCreated"
        ).reverse(),
      };
    case REPORTING_REFRESH_STATUS:
      return {
        ...state,
        requests: _.sortBy(
          [
            action.payload,
            ...state.requests.filter((item) => item._id !== action.payload._id),
          ],
          "dateCreated"
        ).reverse(),
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
