import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "actions/litter_expenses";
import Section from "components/layout/Section";
import ExpenseSummary from "./components/Expense_Summary";
import ExpenseList from "./components/Expense_List";
import Message from "components/message";

function ViewExpenses(props) {
  const { fetched, _litter, litterExpensesFetch } = props;

  useEffect(() => {
    if (!fetched) {
      litterExpensesFetch(_litter);
    }
  }, [litterExpensesFetch, _litter, fetched]);

  const { itemsToDisplay, expenses } = props;

  return (
    <Section>
      <div className="columns is-mobile is-centered">
        <div className="column is-5-desktop is-full-mobile is-6-tablet">
          {expenses.length === 0 ? (
            <Message warning message={"noEntriesToDisplayAutoFetch"} />
          ) : (
            <div>
              <ExpenseSummary
                itemsToDisplay={itemsToDisplay}
                expenses={props.expenses}
              />
              <br />
              <ExpenseList
                itemsToDisplay={itemsToDisplay}
                expenses={props.expenses}
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { _litter } = ownProps;
  const fetched = state.reducers.litterExpenses.fetchedFor.includes(_litter);
  const expenses = state.reducers.litterExpenses.data.filter(
    (obj) => obj._litter === _litter
  );
  const itemsToDisplay = expenses.length > 0;
  return {
    fetched,
    itemsToDisplay,
    expenses,
  };
};

export default connect(mapStateToProps, actions)(ViewExpenses);
