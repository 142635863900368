import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchHeatDiaryProgEntries } from "actions/heat_diary";
import Section from "components/layout/Section";
import Message from "components/message";
import FetchEntries from "components/fetch";
import Table from "components/table_2";
import config from "./config";

class ViewEntries extends Component {
  handleFetchEntries = (value, unit, name) => {
    const { pet } = this.props;
    this.props.fetchHeatDiaryProgEntries(value, unit, name, pet);
  };

  renderSectionContent() {
    const { heatDiary, messages, pet, defaultRoute } = this.props;
    const { entries } = heatDiary.progs;
    const entriesFiltered = entries.filter((entry) => entry._pet === pet._id);

    // Create data set for table
    const tableData = {
      feature: "heatDiary",
      pet: pet._id,
      id: Date.now(),
      headers: config.tableView.progs.headers,
      data: entriesFiltered,
      mobileHeaders: config.tableView.progs.mobileHeaders,
      messages: {
        ...messages.filterBy,
      },
      filterByItems: [],
      filterKey: "",
      viewEntryTo: `${defaultRoute}/view_entry_prog/`,
      fetchDates: heatDiary.progs.fetchDates,
      fetchType: heatDiary.progs.fetchType,
    };

    if (entriesFiltered.length === 0) {
      return <Message warning message={"noEntriesToDisplayRetry"} />;
    }

    return (
      <Table
        tableData={tableData}
        itemsPerPage={5}
        withCharts
        feature={config.name}
        chartFunc={config.chartFunc}
        ticks={{
          stepSize: 5,
          min: 0,
          max: 30,
        }}
      />
    );
  }

  render() {
    const { fetchType, lastFetchPetID } = this.props.heatDiary.progs;
    const { pet } = this.props;
    return (
      <>
        <FetchEntries
          fetchItems={this.handleFetchEntries}
          fetchType={fetchType}
          newPet={pet._id === lastFetchPetID}
          fetchButtons={["last7Days", "last30Days", "last3Months", "last1Year"]}
        />
        <Section>{this.renderSectionContent()}</Section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchHeatDiaryProgEntries,
      dispatch,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(ViewEntries);
