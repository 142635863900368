import React from "react"
import SectionTitle from "../components/SectionTitle"
import ContactRequest from "../components/ContactRequest"
import Intl from "components/Intl"

function ContactRequests({
  contacts: { pendingRequests, contacts },
  acceptContactRequest,
  rejectContactRequest,
}) {
  if (pendingRequests.length === 0) return null

  return (
    <div>
      <SectionTitle
        title={
          <Intl
            message
            path="containers.contacts.userContacts.titles.contactRequests"
          />
        }
        logo={{ path: "provider", name: "providerNoLogo" }}
      />
      {pendingRequests.map((pr) => (
        <ContactRequest
          key={pr._id}
          {...pr}
          onClickAccept={() => acceptContactRequest(pr._id)}
          onClickReject={() => rejectContactRequest(pr._id)}
        />
      ))}
      <br />
    </div>
  )
}

export default ContactRequests
