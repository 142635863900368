import axios from "axios";
import { ACTION_STARTED, ACTION_ERROR } from "actions/types";
import {
  endAndResetAction,
  endAndResetActionWithDelay,
} from "actions/functions";

export const PROVIDER_FETCH_PAIN_TRACKER = "provider_fetch_pain_tracker";
export const PROVIDER_FETCH_WEIGHT_TRACKER = "provider_fetch_weight_tracker";

export const fetchDataForPainTracker = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: PROVIDER_FETCH_PAIN_TRACKER,
    });
    const res = await axios.get("/api/provider/fetch_data_for_pain_tracker", {
      headers: { authorization: localStorage.getItem("tokenProvider") },
    });
    dispatch({
      type: PROVIDER_FETCH_PAIN_TRACKER,
      payload: res.data,
    });
    return endAndResetAction(dispatch);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    return endAndResetActionWithDelay(dispatch, 3000);
  }
};

export const fetchDataForWeightTracker = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: PROVIDER_FETCH_WEIGHT_TRACKER,
    });
    const res = await axios.get("/api/provider/fetch_data_for_weight_tracker", {
      headers: { authorization: localStorage.getItem("tokenProvider") },
    });
    dispatch({
      type: PROVIDER_FETCH_WEIGHT_TRACKER,
      payload: res.data,
    });
    return endAndResetAction(dispatch);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    return endAndResetActionWithDelay(dispatch, 3000);
  }
};
