import React from "react"
import RosetteRsvg from "./Rosette_Rsvg"
import TrophyRsvg from "./Trophy_Rsvg"
import MedalRsvg from "./Medal_Rsvg"

export default function AwardPreview(props) {
  switch (props.type) {
    case "rosette":
      return <RosetteRsvg {...props} />
    case "trophy":
      return <TrophyRsvg {...props} />
    case "medal":
      return <MedalRsvg {...props} />
    default:
      return null
  }
}
