import React from "react";
import { Link } from "react-router-dom";
import { features } from "assets";
import Section from "components/layout/Section";

function NewEntryView({ defaultRoute, messages, locale }) {
  const { entryOptions } = messages;

  const NEW_ENTRY_OPTIONS = [
    {
      name: "newEntryCycle",
      img: features.heatDiary.newEntryCycle,
      path: `${defaultRoute}/new_entry_cycle`,
    },
    {
      name: "newEntryProg",
      img: features.heatDiary.newEntryProg,
      path: `${defaultRoute}/new_entry_prog`,
    },
    {
      name: "newEntryBreeding",
      img: features.heatDiary.newEntryBreeding,
      path: `${defaultRoute}/new_entry_breeding`,
    },
  ];

  return (
    <>
      <Section>
        <NewEntryOptions>
          {NEW_ENTRY_OPTIONS.map((option) => (
            <NewEntryOption
              key={option.name}
              {...option}
              messages={entryOptions}
              locale={locale}
            />
          ))}
        </NewEntryOptions>
      </Section>
    </>
  );
}

const NewEntryOption = (props) => {
  return (
    <div className="column is-6-mobile is-2-desktop">
      <Link to={props.path}>
        <div className="box" style={{ height: "100%" }}>
          <div
            style={{ justifyContent: "center", padding: 0 }}
            className="card-content is-flex"
          >
            <figure className="image is-64x64">
              <img
                src={props.img}
                alt={`heat_diary_new_entry_option_${props.name}`}
              />
            </figure>
          </div>
          <p
            style={{ marginTop: 12 }}
            className="heading has-text-centered is-uppercase"
          >
            {props.messages[props.name][props.locale]}
          </p>
        </div>
      </Link>
    </div>
  );
};

const NewEntryOptions = (props) => {
  return (
    <div className="columns is-2 is-variable is-mobile is-centered is-multiline">
      {props.children}
    </div>
  );
};

export default NewEntryView;
