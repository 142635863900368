import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as Yup from "yup";
import { INPUT_FIELDS } from "./mark_task_complete_fields";
import { FormTitle, FormButtons } from "components/form";

class MarkTaskCompleteForm extends Component {
  renderFields = () => {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <div className="field" key={i}>
          <Field
            viewMode={viewMode}
            editMode={editMode}
            values={values}
            {...field}
          />
          {field.lastInGroup && <br />}
        </div>
      );
    });
  };

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      editMode,
      viewMode,
      cancel,
      defaultRoute,
    } = this.props;
    return (
      <div>
        <Form>
          <FormTitle group={"markTaskComplete"} />
          <br />
          {this.renderFields()}
          <br />
          <FormButtons
            viewMode={viewMode}
            editMode={editMode}
            isSubmitting={isSubmitting}
            errors={errors}
            dirty={dirty}
            cancel={cancel}
            linkTo={defaultRoute}
          />
          <br />
        </Form>
      </div>
    );
  }
}

MarkTaskCompleteForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet }) {
    return {
      taskCompletionRating: valuesToEdit.taskCompletionRating || "",
      taskCompletionNotes: valuesToEdit.taskCompletionNotes || "",
      taskCompletionURL: valuesToEdit.taskCompletionURL || "",
      _id: valuesToEdit._id,
    };
  },
  validationSchema: Yup.object().shape({
    taskCompletionRating: Yup.number()
      .min(1, "greaterThanOrEqualTo-1")
      .max(5, "lessThanOrEqualTo-5"),
    taskCompletionNotes: Yup.string().max(1000, "max-1000"),
    taskCompletionURL: Yup.string().url("url"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    return props.markTaskAsComplete(values, setSubmitting);
  },
})(MarkTaskCompleteForm);

export default connect(null, actions)(MarkTaskCompleteForm);
