import React from "react"
import styled from "styled-components"
import Hr from "components/elements/Hr"

const StyledSection = styled.div`
  padding: 26px 10px;
`

const Section = (props) => (
  <StyledSection className={props.className}>
    <div style={{ minHeight: 40, paddingTop: props.withLevel ? 0 : 4 }}>
      {props.children}
    </div>
    {props.hr && <Hr />}
  </StyledSection>
)

export default Section
