import providerContacts from "./provider_contacts.svg";
import providerNoLogo from "./provider_no_logo.svg";
import requestContact from "./provider_request_contact.svg";
import sendInvite from "./provider_send_invite.svg";
import providerAccount from "./provider_account.svg";
import providerAppointments from "./provider_appointments.svg";
import providerDashboard from "./provider_dashboard.svg";
import providerContactsHome from "./provider_contacts_home.svg";
import providerSettings from "./provider_settings.svg";

const provider = {
  providerContacts,
  providerNoLogo,
  requestContact,
  sendInvite,
  providerAccount,
  providerAppointments,
  providerDashboard,
  providerContactsHome,
  providerSettings,
};

export default provider;
