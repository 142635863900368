import React from "react";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";

export default function DownloadLink(props) {
  const linkType = props.animalHospitalLinks[props.pet.petType];
  const link = props.links[linkType].url;
  return (
    <div style={{ marginTop: 30 }}>
      <Buttons className="is-centered">
        <Button
          className="is-outlined is-link is-uppercase"
          group="animalHospital"
          name="appointment"
          withIcon
          iconLeft
          href
          url={link}
        />
      </Buttons>
    </div>
  );
}
