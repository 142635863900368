import React, { Component } from "react"
import Section from "components/layout/Section"
import FormWrapper from "components/layout/Form_Wrapper"
import HealthEntryForm from "forms/Health_Entry_Form"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"

class HealthEntry extends Component {
  render() {
    return (
      <Section>
        <FormWrapper>
          <HealthEntryForm valuesToEdit={{}} {...this.props} />
        </FormWrapper>
      </Section>
    )
  }
}

export default RequireSubFeature(HealthEntry)
