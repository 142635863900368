import React from "react"
import { Link } from "react-router-dom"
import TileWrapper from "./TileWrapper"
import TileBody from "./TileBody"
import Header from "./Header"

function AppTile(props) {
  const { icon, path, header, title, disabled, newNotification } = props
  return (
    <TileWrapper>
      {header && <Header title={title} newNotification={newNotification} />}
      <Link to={path}>
        <TileBody img={icon} disabled={disabled} />
      </Link>
    </TileWrapper>
  )
}

export default AppTile
