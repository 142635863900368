import React, { useState } from "react";
import { connect } from "react-redux";
import Section from "components/layout/Section";
import ProfileDisplay from "components/styled/Profile_Display";
import AddPetForm from "forms/Add_Pet_Form";
import { returnDateLocaleFormat, localizeNumberToFixed3 } from "functions";
import Button from "components/elements/Button";

function Profile(props) {
  const [editMode, toggleEditMode] = useState(false);

  function handleEditClick() {
    toggleEditMode(!editMode);
  }

  function renderContent() {
    const { pet, locale, userPreferredUnit } = props;
    const {
      labels,
      petTypeOptions,
      petSexOptions,
      hasBeenSterillizedOptions,
      livingOptions,
    } = props.messages;
    const FIELDS = [
      {
        label: labels.petName[locale],
        value: pet.petName,
      },
      {
        label: labels.petGivenName[locale],
        value: pet.petGivenName,
      },
      {
        label: labels.petType[locale],
        value:
          pet.petType === "other"
            ? pet.petTypeOther
            : petTypeOptions[pet.petType][locale],
      },
      {
        label: labels.petSex[locale],
        value: petSexOptions[pet.petSex][locale],
      },
      { label: labels.petBreed[locale], value: pet.petBreed },
      {
        label: labels.hasBeenSterillized[locale],
        value: hasBeenSterillizedOptions[pet.hasBeenSterillized][locale],
      },
      {
        label: `${labels.petWeight[locale]} (${userPreferredUnit})`,
        value: localizeNumberToFixed3(
          userPreferredUnit === "lbs"
            ? pet.petWeightLbs
            : pet.petWeightKg + pet.petWeightG / 1000,
          locale
        ),
      },
      {
        label: labels.petDob[locale],
        value: returnDateLocaleFormat(pet.petDob, locale),
      },
      {
        label: labels.petInsuranceNumber[locale],
        value: pet.petInsuranceNumber,
      },
      { label: labels.petRegNumber[locale], value: pet.petRegNumber },
      { label: labels.petChipNumber[locale], value: pet.petChipNumber },
      { label: labels.petPassportNumber[locale], value: pet.petPassportNumber },
      { label: labels.researchProject[locale], value: pet.researchProject },
      {
        label: labels.living[locale],
        value: pet.living
          ? livingOptions.yes[locale]
          : livingOptions.no[locale],
      },
      { label: labels.dateOfDeath[locale], value: pet.dateOfDeath },
      { label: labels.reasonOfDeath[locale], value: pet.reasonOfDeath },
    ];

    if (editMode)
      return (
        <div className="columns is-mobile is-centered">
          <div className="column is-5-desktop is-full-mobile is-6-tablet">
            <AddPetForm
              valuesToEdit={pet}
              editMode
              cancelEdit={() => handleEditClick()}
              history={props.history}
            />
          </div>
        </div>
      );

    return <ProfileDisplay fields={FIELDS} />;
  }

  return (
    <>
      {!props.providerView && (
        <div>
          <br />
          <div className="buttons is-right">
            {!editMode && (
              <Button
                name="edit"
                group="common"
                withIcon
                iconLeft
                onClick={handleEditClick}
              />
            )}
          </div>
        </div>
      )}
      <Section>{renderContent()}</Section>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    messages: {
      labels: state.intl.messages.components.forms.labels.addPet,
      petTypeOptions:
        state.intl.messages.components.forms.selectOptions.petType,
      petSexOptions: state.intl.messages.components.forms.selectOptions.petSex,
      hasBeenSterillizedOptions:
        state.intl.messages.components.forms.selectOptions.hasBeenSterillized,
      livingOptions: state.intl.messages.components.forms.selectOptions.living,
    },
  };
};

export default connect(mapStateToProps)(Profile);
