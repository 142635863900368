import axios from "axios"
import moment from "moment"
import _ from "lodash"
import {
  ACTION_STARTED,
  ACTION_SUCCESS,
  MODALS_HIDE,
  TABLE_CLEAR_CURRENT_STATE,
} from "actions/types"
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "actions/functions"

export const DAILY_ACTIVITY_FETCH_TYPE = "daily_activity_fetch_type"
export const DAILY_ACTIVITY_FETCH = "daily_activity_fetch"
export const DAILY_ACTIVITY_ADD = "daily_activity_add"
export const DAILY_ACTIVITY_UPDATE = "daily_activity_update"
export const DAILY_ACTIVITY_DELETE = "daily_activity_delete"

export const actionTypes = [
  DAILY_ACTIVITY_FETCH_TYPE,
  DAILY_ACTIVITY_FETCH,
  DAILY_ACTIVITY_ADD,
  DAILY_ACTIVITY_UPDATE,
  DAILY_ACTIVITY_DELETE,
]

const API = (route) => `/api/daily_activity/${route}`

export const fetchDailyActivityEntries = (
  value,
  unit,
  name,
  pet,
  providerView
) => async (dispatch) => {
  const dates = !_.isEmpty(value)
    ? { ...value }
    : {
        from: moment().subtract(value, unit).toISOString(),
        to: moment().toISOString(),
      }
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({
      type: DAILY_ACTIVITY_FETCH_TYPE,
      payload: { dates, type: !_.isEmpty(value) ? "range" : name },
    })
    dispatch({ type: ACTION_STARTED, payload: DAILY_ACTIVITY_FETCH })
    const res = await axios.get(
      API(providerView ? "fetch_for_provider" : "fetch"),
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
        params: { _pet: pet._id, ...dates },
      }
    )
    dispatch({
      type: DAILY_ACTIVITY_FETCH,
      payload: res.data.dailyActivityEntries,
      pet,
    })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addDailyActivityEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: DAILY_ACTIVITY_ADD })
    const res = await axios.post("/api/daily_activity/add", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    setSubmitting(false)
    dispatch({ type: DAILY_ACTIVITY_ADD, payload: res.data.dailyActivityEntry })
    endAndResetAction(dispatch)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const updateDailyActivityEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: DAILY_ACTIVITY_UPDATE })
    const res = await axios.put("/api/daily_activity/update", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({
      type: DAILY_ACTIVITY_UPDATE,
      payload: res.data.dailyActivityEntry,
    })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const deleteDailyActivityEntry = (_id) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: DAILY_ACTIVITY_DELETE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      "/api/daily_activity/delete",
      { _id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({
      type: DAILY_ACTIVITY_DELETE,
      payload: res.data.dailyActivityEntry,
    })
    endAndResetActionWithDelay(dispatch, 1500)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const activityActions = {
  fetchDailyActivityEntries,
  addDailyActivityEntry,
  updateDailyActivityEntry,
  deleteDailyActivityEntry,
}

export default activityActions
