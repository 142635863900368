import React, { Component } from "react"
import Section from "components/layout/Section"

import AddLitter from "./Add_Litter"

class AddLitterView extends Component {
  render() {
    return (
      <Section>
        <AddLitter {...this.props} />
      </Section>
    )
  }
}

export default AddLitterView
