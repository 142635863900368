import main from "./reporting.svg"
import newReport from "./reporting_new_report.svg"
import viewReportRequests from "./reporting_view_report_requests.svg"
import reportTypes from "./report_types"

const reporting = {
  main,
  newReport,
  viewReportRequests,
  reportTypes,
}

export default reporting
