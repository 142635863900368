import { features } from "assets";
import Landing from "components/features/Landing";
import NewEntry from "./New_Entry";
import ViewEntries from "./View_Entries";
import ViewEntry from "./View_Entry";

const awardCabinetConfig = {
  name: "awardCabinet",
  type: "feature",
  defaultRoute: "/userhome/dashboard/award_cabinet",
  menuIcon: { img: features.awardCabinet.main, alt: "award_cabinet_icon" },
  nav: [
    {
      name: "landing",
      pageInfo: ["info_p1"],
      disabled: false,
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      name: "newEntry",
      disabled: false,
      menuItem: true,
      image: {
        img: features.awardCabinet.newEntry,
        alt: "award_cabinet_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: NewEntry,
      path: "/new_entry",
    },
    {
      name: "viewEntries",
      disabled: false,
      menuItem: true,
      image: {
        img: features.awardCabinet.viewEntries,
        alt: "award_cabinet_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
    {
      name: "viewEntry",
      disabled: false,
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
  ],
};

export default awardCabinetConfig;
