import React, { useEffect } from "react"
import { Switch, Route } from "react-router-dom"
import ROUTES from "constants/routes"
import Modal from "components/modal"
import PartnerRoutes from "containers/partners/Partner_Routes"
import { MODALS_BUSY_LOADING, MODALS_HIDE, MODALS_SHOW } from "actions/types"

function Routes({ dispatch, actionCalls: { actionInProcess } }) {
  useEffect(() => {
    if (actionInProcess) {
      dispatch({ type: MODALS_SHOW, modalType: MODALS_BUSY_LOADING })
    }
    if (!actionInProcess) {
      dispatch({ type: MODALS_HIDE, modalType: MODALS_BUSY_LOADING })
    }
  }, [dispatch, actionInProcess])

  return (
    <>
      <Modal />
      <Switch>
        {ROUTES.map((route, i) => (
          <Route
            key={i}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ))}
        <PartnerRoutes />
      </Switch>
    </>
  )
}

export default Routes
