import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as Yup from "yup";
import { INPUT_FIELDS } from "./cancel_fields";
import Intl from "components/Intl";
import Message from "components/message";
import { FormTitle, FormButtons } from "components/form";

class CancelAppointmentForm extends Component {
  renderFields = () => {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  };

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      editMode,
      viewMode,
      cancel,
      defaultRoute,
    } = this.props;
    return (
      <div>
        <br />
        <Message default color="is-info">
          <Intl
            message
            path="components.messages.infoBlocks.appointmentCancelReason"
          />
        </Message>
        <Form>
          <FormTitle group={"appointmentsCancel"} />
          <br />
          {this.renderFields()}
          <br />
          <FormButtons
            viewMode={viewMode}
            editMode={editMode}
            isSubmitting={isSubmitting}
            errors={errors}
            dirty={dirty}
            cancel={cancel}
            linkTo={defaultRoute}
          />
          <br />
        </Form>
      </div>
    );
  }
}

CancelAppointmentForm = withFormik({
  mapPropsToValues({ valuesToEdit }) {
    return {
      cancelReason: "",
      _id: valuesToEdit._id,
    };
  },
  validationSchema: Yup.object().shape({
    cancelReason: Yup.string().max(50, "max-50"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    if (props.editMode) {
      return props.cancelAppointment(values, setSubmitting);
    }
  },
})(CancelAppointmentForm);

export default connect(null, actions)(CancelAppointmentForm);
