import React from 'react';
import { connect } from "react-redux";
import { getValueAtPath } from "functions";

function HelpLink(props) {
  let url = getValueAtPath(props.messages, props.path)[props.locale];
  return (
    <div style={{ marginBottom: 20 }}>
      <a rel="noopener noreferrer" href={url} target="_blank">
        {url}
      </a>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    locale: state.intl.locale,
    messages: state.intl.messages,
  };
}

export default connect(mapStateToProps)(HelpLink);
