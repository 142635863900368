import React, { Component } from "react"
import Section from "components/layout/Section"

import NewQuery from "../components/New_Query"

class NewQueryView extends Component {
  render() {
    return (
      <Section>
        <NewQuery {...this.props} />
      </Section>
    )
  }
}

export default NewQueryView
