import React from "react";
import { connect } from "react-redux";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";
import moment from "moment";
import BodyText from "./BodyText";

function ConfirmAppointmentNewEntry({
  handleModalClose,
  handleConfirmAndAddNew,
  messages,
  locale,
  values,
  user,
  providerContact,
  providerView,
}) {
  const CONFIRMATION_ITEMS = [
    {
      label: messages.labels.date[locale],
      value: moment(values.date).format("DD.MM.YYYY"),
      renderMe: true,
    },
    {
      label: messages.labels.time[locale],
      value: values.time,
      renderMe: true,
    },
    {
      label: messages.labels.petName[locale],
      value: values.petName,
      renderMe: providerView,
    },
    {
      label: messages.labels.ownerName[locale],
      value: providerView
        ? `${providerContact.firstname} ${providerContact.lastname}`
        : "",
      renderMe: providerView,
    },
    {
      label: messages.labels.ownerEmail[locale],
      value: providerView ? providerContact.email : "",
      renderMe: providerView,
    },
  ];

  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title is-size-6-mobile">
            {messages.title[locale]}
          </p>
        </header>
        <section className="modal-card-body has-text-dark">
          <BodyText
            noDefault={true}
            ownBodyText={true}
            messages={messages.bodyText}
            type={"confirmAppointmentNewEntry"}
            locale={locale}
          />
          {CONFIRMATION_ITEMS.map((item) => (
            <ConfirmationItem key={item.label} {...item} />
          ))}
        </section>
        <footer className="modal-card-foot">
          <Buttons>
            <Button name="back" group="common" onClick={handleModalClose} />
            <Button
              name="submit"
              group="common"
              onClick={handleConfirmAndAddNew}
              className="is-success"
            />
          </Buttons>
        </footer>
      </div>
    </div>
  );
}

const ConfirmationItem = ({ label, value, renderMe }) => {
  if (!renderMe) return null;
  return (
    <div>
      <p className="label">{label}</p>
      <p>{value}</p>
      <br />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: {
      title:
        state.intl.messages.components.modals.titles.confirmAppointmentNewEntry,
      bodyText: state.intl.messages.components.modals.bodyText,
      labels: {
        ...state.intl.messages.components.forms.labels.common,
        ...state.intl.messages.components.appointment.labels,
      },
    },
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(ConfirmAppointmentNewEntry);
