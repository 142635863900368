import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Section from "components/layout/Section";
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons";
import FMPIMetaDataForm from "../forms/FMPI_Meta_Data_Form";
import { FMPI_DELETE } from "../actions";
import { MODALS_SHOW, MODALS_CONFIRM_DELETE } from "actions/types";
import ResultSimple from "../components/Result_Simple";
import config from "../config";

const INITIAL_STATE = {
  viewMode: true,
  valuesToEdit: {},
};

class ViewResult extends Component {
  state = INITIAL_STATE;

  UNSAFE_componentWillMount() {
    const { fmpi, history, defaultRoute } = this.props;
    const { id } = this.props.match.params;
    const result = fmpi.results.filter((item) => item._id === id)[0];
    if (!result) return history.push(`${defaultRoute}/view_results`);
    this.setState({
      valuesToEdit: result,
    });
  }

  handleEditClick = () => {
    this.setState({
      viewMode: !this.state.viewMode,
    });
  };

  handleDeleteClick = () => {
    this.props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "fmpi",
        actionType: FMPI_DELETE,
        item: this.state.valuesToEdit._id,
        callback: this.handleDeleteResult,
      },
    });
  };

  handleDeleteResult = () => {
    this.props.history.push(`${this.props.defaultRoute}/view_results`);
  };

  renderSectionContent() {
    if (_.isEmpty(this.state.valuesToEdit)) return <div />;

    return (
      <>
        <BackEditDeleteButtons
          handleEditClick={this.handleEditClick}
          handleDeleteClick={this.handleDeleteClick}
          viewMode={this.state.viewMode}
          providerView={this.props.providerView}
          providerEdit={this.props.providerEdit}
        />
        <div className="columns is-mobile is-centered">
          <div className="column is-5-desktop is-full-mobile is-6-tablet">
            <ResultSimple
              title="FMPI Result"
              score={this.state.valuesToEdit.totalPoints}
              results={config.viewResult.results}
              messages={this.props.messages.components.result}
              locale={this.props.locale}
            />
            <FMPIMetaDataForm
              editMode
              {...this.state}
              {...this.props}
              cancel={this.handleEditClick}
            />
          </div>
        </div>
      </>
    );
  }

  render() {
    return <Section>{this.renderSectionContent()}</Section>;
  }
}

export default withRouter(ViewResult);
