import React from "react"
import { connect } from "react-redux"
import _ from "lodash"
import LevelItem from "components/layout/Level_Item"

function NavBarProfileIcon(props) {
  if (_.isEmpty(props.pet)) return null
  return (
    <LevelItem>
      <div style={{ paddingTop: 2, minHeight: 44 }}>
        <div className="box" style={{ padding: 4 }}>
          {props.pet.useImageForProfile ? (
            <figure className="image is-28x28">
              <img
                className="is-rounded"
                src={props.pet.profileImageObject.secure_url}
                alt={"profile_image"}
              />
            </figure>
          ) : (
            <figure className="image is-28x28 has-text-weight-bold">
              <div
                style={{
                  position: "relative",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "grey",
                  padding: 14,
                  borderRadius: 6,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {props.pet.petName.charAt(0)}
                </div>
              </div>
            </figure>
          )}
        </div>
      </div>
    </LevelItem>
  )
}

const mapStateToProps = (state) => {
  //const pet = !_.isEmpty(state.reducers.pets.selectedPet)
  const pet = !_.isEmpty(state.reducers.litters.selectedLitterMember)
    ? state.reducers.litters.selectedLitterMember
    : //? state.reducers.pets.selectedPet
      state.reducers.pets.selectedPet
  /* ? state.reducers.litters.selectedLitterMember
    : {} */
  return {
    pet: { ...pet },
  }
}

export default connect(mapStateToProps)(NavBarProfileIcon)
