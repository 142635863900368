import {
  InputFieldV5,
  TextareaV5,
  TimeSelectButtonsHours,
  TimeSelectButtonsMinutes,
} from "components/form";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "fa fa-calendar",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "activityTimeHrs",
    component: TimeSelectButtonsHours,
    required: false,
  },
  {
    name: "activityTimeMins",
    component: TimeSelectButtonsMinutes,
    required: false,
  },
  {
    name: "notes",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    userCanEdit: true,
    charLimit: 250,
  },
];

export { INPUT_FIELDS };
