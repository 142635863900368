import React, { Component } from "react";
import { connect } from "react-redux";
import { auth as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { INPUT_FIELDS } from "forms/fields/complete_registration";
import { FormButtons, FormTitle } from "components/form";

class CompleteRegistrationForm extends Component {
  renderFields() {
    return INPUT_FIELDS.map((field) => {
      return <Field key={field.name} {...field} />;
    });
  }

  render() {
    const { errors, dirty, isSubmitting } = this.props;

    return (
      <Form>
        <br />
        <FormTitle group={"completeRegistration"} />
        {this.renderFields()}
        <br />
        <FormButtons
          register
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
        />
        <br />
      </Form>
    );
  }
}

CompleteRegistrationForm = withFormik({
  mapPropsToValues() {
    return {
      postcode: "",
      customerReg: false,
      termsCond: false,
      privacyPolicy: false,
      canBeContactedResearch: false,
    };
  },
  validationSchema: Yup.object().shape({
    postcode: Yup.string().max(10, "max-10"),
    customerReg: Yup.boolean()
      .test("customerReg", "consent-CustomerReg", (value) => value === true)
      .required("consent"),
    termsCond: Yup.boolean()
      .test("termsCond", "consent-TermsCond", (value) => value === true)
      .required("consent"),
    privacyPolicy: Yup.boolean()
      .test("privacyPolicy", "consent-PrivacyPolicy", (value) => value === true)
      .required("consent"),
  }),
  handleSubmit(values, { props, setSubmitting, resetForm }) {
    props.completeRegistration(values);
  },
})(CompleteRegistrationForm);

export default connect(null, actions)(CompleteRegistrationForm);
