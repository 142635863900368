import axios from "axios"

import {
  CLOUDINARY_GET_SIGNATURE,
  CLOUDINARY_GET_CONFIG,
  CLOUDINARY_HANDLE_UPLOAD,
  ACTION_STARTED,
  ACTION_ERROR,
  ACTION_ENDED,
  //ACTION_SUCCESS,
  ACTION_RESET,
} from "./types"

// Action Creators
export const getSignature = (callback, params_to_sign) => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: CLOUDINARY_GET_SIGNATURE })
  try {
    const res = await axios.post(
      "/api/cloudinary/get_signature",
      { ...params_to_sign },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({ type: ACTION_RESET })
    return callback(res.data.signature)
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ACTION_ERROR,
        payload: error.response.data.error,
      })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const getCloudinaryConfig = () => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: CLOUDINARY_GET_CONFIG })
  try {
    const res = await axios.get("/api/cloudinary/get_config", {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({
      type: CLOUDINARY_GET_CONFIG,
      payload: res.data,
    })
    dispatch({ type: ACTION_RESET })
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ACTION_ERROR,
        payload: error.response.data.error,
      })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const handleUploadResult = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: CLOUDINARY_HANDLE_UPLOAD,
      payload: obj,
    })
  } catch (error) {
    if (error.response) {
      dispatch({
        type: ACTION_ERROR,
        payload: error.response.data.error,
      })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

const cloundinaryActions = {
  getSignature,
  getCloudinaryConfig,
  handleUploadResult,
}

export default cloundinaryActions
