import main from "./parasites.svg"
import newEntry from "./parasites_new_entry.svg"
import viewEntries from "./parasites_view_entries.svg"

const parasites = {
  main,
  newEntry,
  viewEntries,
}

export default parasites
