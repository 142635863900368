// Props:
// field = string, name of the field
// touched = object containing all fields that have been touched
// errors = object containing all fields that have errors

export default function InputValidationColor(field, touched, errors) {
  if (touched[field] && errors[field]) return "is-danger"
  if (touched[field] && !errors[field]) return "is-success"
  return ""
}
