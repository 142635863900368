import { InputFieldV5, TextareaV5 } from "components/form";

export const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "fa fa-calendar",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "time",
    group: "common",
    type: "time",
    icon: "fa fa-clock",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "notes",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    userCanEdit: true,
    charLimit: 250,
  },
];
