// Route imports
import MAIN_ROUTES from "./main_routes"
import FEATURE_ROUTES from "./feature_routes"
import LITTER_ROUTES from "./litter_routes"
import LITTER_MEMBER_ROUTES from "./litter_member_routes"
import PET_SERVICES_PORTAL_ROUTES from "containers/auth/Pet_Services_Portal/routes"
import PROVIDER_ROUTES from "./provider_routes"
import USER_CONTACTS from "containers/contacts/User_Contacts/routes"
import PROVIDER_VIEW_FEATURE_ROUTES from "constants/feature_routes_provider_view"

const routes = [
  ...MAIN_ROUTES,
  ...FEATURE_ROUTES,
  ...LITTER_ROUTES,
  ...LITTER_MEMBER_ROUTES,
  ...PET_SERVICES_PORTAL_ROUTES,
  ...PROVIDER_ROUTES,
  ...USER_CONTACTS,
  ...PROVIDER_VIEW_FEATURE_ROUTES,
]

export default routes
