import React from "react"
import _ from "lodash"
import ToastNotification from "./ToastNotification"

function Toast(props) {
  switch (true) {
    case !_.isEmpty(props.actionSuccess):
      return (
        <ToastNotification
          {...props.actionSuccess}
          color={"is-success"}
          type="successes"
        />
      )
    case !_.isEmpty(props.actionError):
      return (
        <ToastNotification
          {...props.actionError}
          color={"is-danger"}
          type="errors"
        />
      )
    case !_.isEmpty(props.actionNoSuccess):
      return (
        <ToastNotification
          {...props.actionNoSuccess}
          color={"is-warning"}
          type="warnings"
        />
      )
    default:
      return null
  }
}

export default Toast
