import { features } from "assets";

const bcs = {
  dog: {
    initialState: {
      currentBC: "ideal2",
      color: "is-success",
    },
    scale: [
      {
        score: 1,
        name: "severlyUnderweight",
        image: features.weight.bcs.bcsDog.bcsDog1,
        color: "is-danger",
      },
      {
        score: 2,
        name: "underweight",
        image: features.weight.bcs.bcsDog.bcsDog2,
        color: "is-danger",
      },
      {
        score: 3,
        name: "slightlyUnderweight",
        image: features.weight.bcs.bcsDog.bcsDog3,
        color: "is-warning",
      },
      {
        score: 4,
        name: "ideal",
        image: features.weight.bcs.bcsDog.bcsDog4,
        color: "is-success",
      },
      {
        score: 5,
        name: "ideal2",
        image: features.weight.bcs.bcsDog.bcsDog5,
        color: "is-success",
      },
      {
        score: 6,
        name: "slightlyOverweight",
        image: features.weight.bcs.bcsDog.bcsDog6,
        color: "is-warning",
      },
      {
        score: 7,
        name: "overweight",
        image: features.weight.bcs.bcsDog.bcsDog7,
        color: "is-danger",
      },
      {
        score: 8,
        name: "obese",
        image: features.weight.bcs.bcsDog.bcsDog8,
        color: "is-danger",
      },
      {
        score: 9,
        name: "severlyObese",
        image: features.weight.bcs.bcsDog.bcsDog9,
        color: "is-danger",
      },
    ],
  },
  cat: {
    initialState: {
      currentBC: "ideal2",
      color: "is-success",
    },
    scale: [
      {
        score: 1,
        name: "severlyUnderweight",
        image: features.weight.bcs.bcsCat.bcsCat1,
        color: "is-danger",
      },
      {
        score: 2,
        name: "underweight",
        image: features.weight.bcs.bcsCat.bcsCat2,
        color: "is-danger",
      },
      {
        score: 3,
        name: "slightlyUnderweight",
        image: features.weight.bcs.bcsCat.bcsCat3,
        color: "is-warning",
      },
      {
        score: 4,
        name: "ideal",
        image: features.weight.bcs.bcsCat.bcsCat4,
        color: "is-success",
      },
      {
        score: 5,
        name: "ideal2",
        image: features.weight.bcs.bcsCat.bcsCat5,
        color: "is-success",
      },
      {
        score: 6,
        name: "slightlyOverweight",
        image: features.weight.bcs.bcsCat.bcsCat6,
        color: "is-warning",
      },
      {
        score: 7,
        name: "overweight",
        image: features.weight.bcs.bcsCat.bcsCat7,
        color: "is-danger",
      },
      {
        score: 8,
        name: "obese",
        image: features.weight.bcs.bcsCat.bcsCat8,
        color: "is-danger",
      },
      {
        score: 9,
        name: "severlyObese",
        image: features.weight.bcs.bcsCat.bcsCat9,
        color: "is-danger",
      },
    ],
  },
};

export default bcs;
