import React, { Component } from "react";
import { connect } from "react-redux";
import Section from "components/layout/Section";
import { MODALS_SHOW, MODALS_CONFIRM_DELETE, PETS_DELETE } from "actions/types";

class DeleteProfile extends Component {
  handleDeleteClick = () => {
    this.props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "pet",
        actionType: PETS_DELETE,
        history: this.props.history,
        successRoute: "/userhome",
        item: this.props.pet,
      },
    });
  };

  renderContent() {
    const { buttons, locale } = this.props;

    return (
      <Section>
        <div className="buttons is-centered">
          <div
            className="button is-danger"
            onClick={() => this.handleDeleteClick()}
          >
            <span>{buttons.deleteProfile[locale]}</span>
          </div>
        </div>
      </Section>
    );
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    pet: state.reducers.pets.selectedPet,
    buttons: state.intl.messages.components.buttons.petProfile,
  };
};

export default connect(mapStateToProps)(DeleteProfile);
