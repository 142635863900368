import React from "react";
import Notification from "components/layout/Notification";
import styled from "styled-components";

const NumberWrapper = styled.div`
  width: 72px;
  text-align: center;
  display: inline-block;
`;

const StyledNumber = styled.p`
  font-size: 44px;
  background: ${(props) => props.score};
`;

export default function ResultSimple({ score, results, locale, messages }) {
  return (
    <Notification centered>
      <p className="heading has-text-weight-bold">{`${messages.score[locale]}:`}</p>
      <NumberWrapper>
        <StyledNumber score={results(score).color}>{score}</StyledNumber>
      </NumberWrapper>
      <br />
      <br />
      <p className="heading has-text-weight-bold">
        {messages.definition[locale]}
      </p>
      <p className="heading">{messages[results(score).text][locale]}</p>
    </Notification>
  );
}
