import React from "react";

function FormWrapper(props) {
  return (
    <div style={props.styles}>
      <div className="columns is-mobile is-centered">
        <div className="column is-5-desktop is-full-mobile is-6-tablet">
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default FormWrapper;
