import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import routes from "constants/routes";

import assets from "assets";

import BoxWithBackground from "components/styled/Box_With_Background";
import Level from "components/layout/Level";
import LevelLeft from "components/layout/Level_Left";
import LevelRight from "components/layout/Level_Right";
import LevelItem from "components/layout/Level_Item";

import NavBarProfileIcon from "components/styled/Nav_Bar_Profile_Icon";
import NewNotificationBadge from "components/SmartTile/NewNotificationBadge";

const ACTIVE_STYLE = {
  borderBottom: "4px solid hsl(217, 71%, 53%)",
};

class Navigation extends Component {
  render() {
    const { isAuthed } = this.props;
    const { completeRegistrationRequired } = this.props.auth.user;
    const displayRight =
      this.props.location.pathname === "/userhome" || "/providerhome"
        ? "block"
        : "none";

    const pathnameArray = this.props.location.pathname.split("/");
    const petDashboard = pathnameArray[2] === "dashboard";
    const litterMemberDashboard =
      pathnameArray[3] === "litter_member_dashboard";

    const showSelectedProfile =
      petDashboard || litterMemberDashboard ? true : false;

    if (!isAuthed) return null;

    if (completeRegistrationRequired) return null;

    return (
      <NavBar
        {...this.props}
        displayRight={displayRight}
        showSelectedProfile={showSelectedProfile}
        petDashboard={petDashboard}
        litterMemberDashboard={litterMemberDashboard}
      />
    );
  }
}

const NavBar = (props) => {
  if (props.mobile) {
    return (
      <BoxWithBackground
        style={{
          height: 55,
          _fontSize: 12,
          get fontSize() {
            return this._fontSize;
          },
          set fontSize(value) {
            this._fontSize = value;
          },
          paddingTop: 4,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: "env(safe-area-inset-bottom, 0)",
        }}
      >
        <Level className="is-mobile">
          <NavItemsLeft {...props} />
          <NavItemsRight {...props} style={{ display: props.displayRight }} />
        </Level>
      </BoxWithBackground>
    );
  }
  return (
    <Level className="is-mobile">
      <NavItemsLeft {...props} />
      <NavItemsRight {...props} />
    </Level>
  );
};

const NavItemsLeft = (props) => {
  return (
    <LevelLeft>
      {routes.map(({ path, img, breadcrumb }, i) => {
        if (!breadcrumb) return null;
        if (props.location.pathname.indexOf(path) > -1) {
          return (
            <LevelItem key={i}>
              <div style={{ paddingTop: 2, minHeight: 44 }}>
                <NavLink
                  exact={true}
                  to={path}
                  activeStyle={ACTIVE_STYLE}
                  className="box"
                  style={{ padding: 4, ...props.style }}
                >
                  <figure className="image is-28x28">
                    {props.newNotifications && path === "/userhome" && (
                      <NewNotificationBadge />
                    )}
                    <img src={img.src} alt={img.alt} />
                  </figure>
                </NavLink>
              </div>
            </LevelItem>
          );
        }
        return null;
      })}
      {props.location.pathname.indexOf("/providerhome/contacts") > -1 && (
        <LevelItem>
          <div style={{ paddingTop: 2, minHeight: 44, ...props.style }}>
            <NavLink
              exact={true}
              to={"/providerhome/contacts/pain_index_tracker"}
              activeStyle={ACTIVE_STYLE}
              className="box"
              style={{ padding: 4 }}
            >
              <figure className="image is-28x28">
                <img
                  src={assets.provider.providerDashboard}
                  alt={"pain_index_tracker"}
                />
              </figure>
            </NavLink>
          </div>
        </LevelItem>
      )}
      {props.location.pathname.indexOf("/providerhome/contacts") > -1 && (
        <LevelItem>
          <div style={{ paddingTop: 2, minHeight: 44, ...props.style }}>
            <NavLink
              exact={true}
              to={"/providerhome/contacts/nutrition_and_weight_tracker"}
              activeStyle={ACTIVE_STYLE}
              className="box"
              style={{ padding: 4 }}
            >
              <figure className="image is-28x28">
                <img
                  src={assets.features.weight.main}
                  alt={"nutrition_and_weight_tracker"}
                />
              </figure>
            </NavLink>
          </div>
        </LevelItem>
      )}
      {props.location.pathname.indexOf("/providerhome/contacts") > -1 && (
        <LevelItem>
          <div style={{ paddingTop: 2, minHeight: 44, ...props.style }}>
            <NavLink
              exact={true}
              to={"/providerhome/contacts/view"}
              activeStyle={ACTIVE_STYLE}
              className="box"
              style={{ padding: 4 }}
            >
              <figure className="image is-28x28">
                <img src={assets.provider.providerContacts} alt={"contacts"} />
              </figure>
            </NavLink>
          </div>
        </LevelItem>
      )}
      {props.location.pathname.indexOf("/contacts/contact") > -1 && (
        <LevelItem>
          <div style={{ paddingTop: 2, minHeight: 44, ...props.style }}>
            <NavLink
              exact={true}
              to={"/providerhome/contacts/contact"}
              activeStyle={ACTIVE_STYLE}
              className="box"
              style={{ padding: 4 }}
            >
              <figure className="image is-28x28">
                <img src={assets.pets.profile} alt={"contacts_pets_icon"} />
              </figure>
            </NavLink>
          </div>
        </LevelItem>
      )}
      {props.location.pathname.indexOf("/contacts_pet_dashboard") > -1 && (
        <LevelItem>
          <div style={{ paddingTop: 2, minHeight: 44, ...props.style }}>
            <NavLink
              exact={true}
              to={"/providerhome/contacts/contact/contacts_pet_dashboard"}
              activeStyle={ACTIVE_STYLE}
              className="box"
              style={{ padding: 4 }}
            >
              <figure className="image is-28x28">
                <img src={assets.main.dashboard} alt={"dashboard_icon"} />
              </figure>
            </NavLink>
          </div>
        </LevelItem>
      )}
    </LevelLeft>
  );
};

const NavItemsRight = (props) => {
  return (
    <LevelRight>
      {routes.map(
        ({ path, img, permaLink, hideIfSignupIncomplete, role }, i) => {
          if (props.litterMemberDashboard || props.petDashboard) return null;
          if (!permaLink) return null;
          if (role !== props.role) return null;
          if (hideIfSignupIncomplete && !props.auth.user.signupComplete)
            return null;
          return (
            <LevelItem key={i}>
              <div style={{ paddingTop: 2, minHeight: 44, ...props.style }}>
                <NavLink
                  exact={true}
                  to={path}
                  activeStyle={ACTIVE_STYLE}
                  className="box"
                  style={{ padding: 4 }}
                >
                  <figure className="image is-28x28">
                    <img src={img.src} alt={img.alt} />
                  </figure>
                </NavLink>
              </div>
            </LevelItem>
          );
        }
      )}
      {props.showSelectedProfile && props.mobile && (
        <LevelItem>
          <NavBarProfileIcon {...props} />
        </LevelItem>
      )}
    </LevelRight>
  );
};

const mapStateToProps = (state) => {
  const newNotifications = state.reducers.contacts.pendingRequests.length > 0;
  return {
    newNotifications,
  };
};

export default connect(mapStateToProps)(Navigation);
