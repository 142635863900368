import React, { Component } from "react"
import { connect } from "react-redux"
import Menu from "components/menu"
import config from "./config"

class LitterMemberProfile extends Component {
  render() {
    return <Menu {...config} {...this.props} />
  }
}

const mapStateToProps = state => {
  return {
    locale: state.intl.locale,
    messages:
      state.intl.messages.containers.litterMemberFeatures.litterMemberProfile,
    litterMember: state.reducers.litters.selectedLitterMember
  }
}

export default connect(mapStateToProps)(LitterMemberProfile)
