import React from "react"
import { connect } from "react-redux"
import styled, { ThemeProvider } from "styled-components"

const StyledBox = styled.div`
    border: solid;border-width 2px;
    ${props => props.theme.settings.statusBox[props.status].borderColor} !important;
`

const BoxWithStatusBackground = props => {
  return (
    <ThemeProvider theme={props.theme}>
      <StyledBox
        style={{ padding: props.padding ? props.padding : 20 }}
        className="box"
        status={props.status}
      >
        {props.children}
      </StyledBox>
    </ThemeProvider>
  )
}

const mapStateToProps = state => {
  return {
    theme: state.reducers.theme
  }
}

export default connect(mapStateToProps)(BoxWithStatusBackground)
