import React, { useEffect } from "react";
import { connect } from "react-redux";
import inputValidationColor from "./Input_Validation_Color";

const DistancePerSecond = (props) => {
  const { field, form, type, icon, className, label, viewMode } = props;

  const { agilityDistanceInMetres, agilityTimeInSeconds } = form.values;
  const { setFieldValue } = form;

  useEffect(() => {
    function setDistancePerSecondsValue() {
      if (agilityDistanceInMetres > 0 && agilityTimeInSeconds > 0) {
        const distancePerSecond = (
          agilityDistanceInMetres / agilityTimeInSeconds
        ).toFixed(2);
        setFieldValue("agilityDistancePerSecond", distancePerSecond);
      }
      return;
    }
    setDistancePerSecondsValue();
  }, [agilityDistanceInMetres, agilityTimeInSeconds, setFieldValue]);

  return (
    <div className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">{label}</div>
      </label>
      <div className="control has-icons-left has-icons-right">
        <input
          {...field}
          type={type}
          readOnly={true}
          className={
            viewMode
              ? className
              : `${className} ${inputValidationColor(
                  field.name,
                  form.touched,
                  form.errors
                )}`
          }
        />
        <span className="icon is-small is-left">
          <i className={icon} />
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { group, field }) => {
  const { locale, messages } = state.intl;
  return {
    label: messages.components.forms.labels[group][field.name][locale],
  };
};

export default connect(mapStateToProps)(DistancePerSecond);
