import { features } from "assets";
import { path } from "constants/feature_routes_provider_view";
import NewEntryView from "./views/New_Entry_View";
import ViewEntriesViewProvider from "./views/View_Entries_View";
import LandingRedirect from "components/features/Landing_Redirect";

const appointmentsProviderConfig = {
  name: "appointments",
  type: "feature",
  redirect: path("appointments/view_entries"),
  defaultRoute: path("appointments"),
  menuIcon: {
    img: features.appointments.main,
    alt: "appointments_feature_icon",
  },
  providerView: true,
  providerEdit: true,
  nav: [
    {
      name: "landingRedirect",
      disabled: false,
      menuItem: false,
      exact: true,
      component: LandingRedirect,
      path: "/",
    },
    {
      name: "viewEntriesView",
      disabled: false,
      menuItem: true,
      exact: true,
      image: {
        img: features.appointments.viewEntries,
        alt: "view_entries_icon",
      },
      component: ViewEntriesViewProvider,
      path: "/view_entries",
    },
    {
      name: "newEntryView",
      disabled: false,
      menuItem: true,
      exact: true,
      image: {
        img: features.appointments.newEntry,
        alt: "new_entry_icon",
      },
      component: NewEntryView,
      path: "/new_entry",
    },
  ],
  tableView: {},
  chartView: {},
};

export default appointmentsProviderConfig;
