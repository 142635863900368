import React from "react";
import { connect } from "react-redux";
import Title from "components/elements/Title";

function FormTitle({ titles, group, locale }) {
  if (!titles[group]) return null;

  if (!titles[group].title[locale]) return null;

  return (
    <>
      <Title
        title={titles[group].title[locale]}
        subtitle={titles[group].subtitle[locale]}
        titleSize={"is-6"}
        subtitleSize={"is-7"}
        titleColor={"has-text-grey has-text-weight-bold"}
        subtitleColor={"has-text-grey"}
        className={"has-text-centered"}
      />
      <br />
    </>
  );
}

function mapStateToProps(state) {
  return {
    locale: state.intl.locale,
    titles: state.intl.messages.components.forms.titles,
  };
}

export default connect(mapStateToProps)(FormTitle);
