import React from "react";
import LitterMemberWeightForm from "forms/Litter_Member_Weight_Form";
import RequireSubFeature from "components/hoc/Require_Sub_Feature";
import FormWrapper from "components/layout/Form_Wrapper";

function NewEntry(props) {
  return (
    <FormWrapper>
      <LitterMemberWeightForm valuesToEdit={{}} {...props} />
    </FormWrapper>
  );
}

export default RequireSubFeature(NewEntry);
