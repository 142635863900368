import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchHealthDiaryEntries } from "actions/health_diary";
import Section from "components/layout/Section";
import Message from "components/message";
import FetchEntries from "components/fetch";
import Table from "components/table_2";
import config from "./config";

class ViewEntries extends Component {
  handleFetchEntries = (value, unit, name) => {
    const { pet, providerView } = this.props;
    this.props.fetchHealthDiaryEntries(value, unit, name, pet, providerView);
  };

  renderSectionContent() {
    const { healthDiary, messages, pet, defaultRoute } = this.props;
    const { entries } = healthDiary;
    const entriesFiltered = entries.filter(
      (entry) => entry._pet === pet._id && entry.type === "medicine"
    );

    // Create data set for table
    const tableData = {
      feature: "healthDiary",
      pet: pet._id,
      id: Date.now(),
      data: entriesFiltered,
      headers: config.tableView.medicine.headers,
      mobileHeaders: config.tableView.medicine.mobileHeaders,
      messages: {
        ...messages.entryTypes,
        ...messages.medicineAmountUnit,
        ...messages.filterBy,
      },
      viewEntryTo: `${defaultRoute}/view_entry/`,
      fetchDates: healthDiary.fetchDates,
      fetchType: healthDiary.fetchType,
    };

    if (entriesFiltered.length === 0) {
      return <Message warning message={"noEntriesToDisplayRetry"} />;
    }

    return <Table tableData={tableData} itemsPerPage={5} />;
  }

  render() {
    const { fetchType, lastFetchPetID } = this.props.healthDiary;
    const { pet } = this.props;
    return (
      <>
        <FetchEntries
          fetchItems={this.handleFetchEntries}
          fetchType={fetchType}
          newPet={pet._id === lastFetchPetID}
          fetchButtons={["last7Days", "last30Days", "last3Months", "last1Year"]}
        />
        <Section>{this.renderSectionContent()}</Section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchHealthDiaryEntries,
      dispatch,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(ViewEntries);
