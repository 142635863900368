import React, { Component } from "react"
import { connect } from "react-redux"

export default function RequireAuth(ComposedComponent) {
  class Authentication extends Component {
    UNSAFE_componentWillMount() {
      if (!this.props.isAuthed) {
        this.props.history.push("/")
      }
    }

    UNSAFE_componentWillUpdate(nextProps) {
      if (!nextProps.isAuthed) {
        this.props.history.push("/")
      }
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }

  const mapStateToProps = (state) => {
    return {
      isAuthed: state.reducers.auth.isAuthenticated,
    }
  }
  return connect(mapStateToProps)(Authentication)
}
