import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import WeightDataView from "./WeightDataView";

function NutritionAndWeightTracker(props) {
  const { fetchDataForWeightTracker } = props;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchDataForWeightTracker();
    }
    return () => (mounted = false);
  }, [fetchDataForWeightTracker]);

  return (
    <div>
      <WeightDataView {...props} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    weightData: state.reducers.providerDashboard.weightData,
    messages: {
      ...state.intl.messages.containers.contacts.providerContacts.dataTrackers,
      ...state.intl.messages.containers.contacts.providerContacts.weightTracker,
    },
    locale: state.intl.locale,
    contacts: state.reducers.contacts.contacts,
  };
};

export default connect(mapStateToProps, actions)(NutritionAndWeightTracker);
