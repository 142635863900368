import React from "react"
import { connect } from "react-redux"
import Section from "components/layout/Section"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"
import LitterMemberSelection from "./Litter_Member_Selection"
import LitterVaccinationForm from "forms/Litter_Vaccination_Form"
import LitterParasiteForm from "forms/Litter_Parasite_Form"
import LitterFoodDiaryNewEntry from "../Litter_Food_Diary/New_Entry"
import LitterHomeCareNewEntry from "containers/features/Home_Care/views/New_Entry_Selection_View"
import LitterUsefulLinks from "containers/litter_features/Litter_Useful_Links/Links"
import FormWrapper from "components/layout/Form_Wrapper"
import { litterMembersSelected as actions } from "actions"

function AddNew(props) {
  const {
    litterVacs,
    litterParasites,
    litterFoodDiary,
    litterUsefulLinks,
    litterHomeCare,
    hasAtLeastOne,
    litterMembers,
  } = props

  function renderForm() {
    switch (true) {
      case litterVacs:
        return (
          <FormWrapper>
            <br />
            <LitterVaccinationForm litterMembers={litterMembers} />
          </FormWrapper>
        )
      case litterParasites:
        return (
          <FormWrapper>
            <br />
            <LitterParasiteForm litterMembers={litterMembers} />
          </FormWrapper>
        )
      case litterFoodDiary:
        return (
          <LitterFoodDiaryNewEntry litterMembers={litterMembers} {...props} />
        )
      case litterUsefulLinks:
        return <LitterUsefulLinks {...props} />
      case litterHomeCare:
        return (
          <LitterHomeCareNewEntry litterMembers={litterMembers} {...props} />
        )
      default:
        return <div />
    }
  }

  return (
    <Section>
      <LitterMemberSelection {...props} />
      {hasAtLeastOne && renderForm()}
    </Section>
  )
}

const mapStateToProps = (state) => {
  const hasAtLeastOne = state.reducers.litterMembersSelected.length > 0
  return {
    hasAtLeastOne,
    litterMembers: state.reducers.litterMembersSelected,
  }
}

export default connect(mapStateToProps, actions)(RequireSubFeature(AddNew))
