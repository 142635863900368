import { features } from "assets";
import { path } from "constants/feature_routes_provider_view";
import ToothMarkerView from "./views/Tooth_Marker_View";
import LandingRedirect from "components/features/Landing_Redirect";

const toothMarkerProviderConfig = {
  name: "toothMarker",
  type: "feature",
  redirect: path("tooth_marker/markers"),
  defaultRoute: path("tooth_marker"),
  menuIcon: {
    img: features.bodyMarker.main,
    alt: "tooth_marker_feature_icon",
  },
  providerView: true,
  providerEdit: true,
  nav: [
    {
      name: "landingRedirect",
      disabled: false,
      menuItem: false,
      exact: true,
      component: LandingRedirect,
      path: "/",
    },
    {
      name: "markers",
      disabled: false,
      menuItem: true,
      image: {
        img: features.toothMarker.main,
        alt: "tooth_marker_icon",
      },
      icon: "far fa-plus-square",
      component: ToothMarkerView,
      path: "/markers",
    },
  ],
};

export default toothMarkerProviderConfig;
