import { AUTH_LOG_OUT_USER } from "actions/types"
import { ADS_FETCH } from "./actions"
import moment from "moment"

const INITIAL_STATE = {
  items: [],
  lastFetch: moment(),
}

export default function AdvertisementsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADS_FETCH:
      return {
        ...state,
        items: action.payload,
        lastFetch: moment(),
      }
    case AUTH_LOG_OUT_USER:
      return INITIAL_STATE
    default:
      return state
  }
}
