import React, { useState } from "react"
import { connect } from "react-redux"

function SingleSelectButton({
  form,
  locale,
  selectButtons,
  labels,
  formName,
  field,
  viewMode,
  editMode,
  userCanEdit,
  required,
  style,
  resetFields,
  disabledIf,
}) {
  const [buttonState, setButtonState] = useState(field.value)

  function handleButtonPress(value) {
    setButtonState(value)
    form.setFieldValue(field.name, !buttonState)
    if (!value && resetFields) {
      resetFields.map((rf) => form.setFieldValue(rf.name, rf.value))
    }
  }

  function handleDisabledIf() {
    let arr = disabledIf.map((obj) => obj.value === form.values[obj.name])
    if (arr.includes(true)) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="field" style={style ? style : {}}>
      <button
        type="button"
        className={`button ${buttonState && "is-primary"}`}
        onClick={() => handleButtonPress(!buttonState)}
        disabled={(disabledIf ? handleDisabledIf() : false) || viewMode}
      >
        {selectButtons[field.name][locale]}
      </button>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.intl.locale,
    selectButtons:
      state.intl.messages.components.form.selectButtons[ownProps.formName],
    labels: state.intl.messages.components.form.labels[ownProps.formName],
  }
}

export default connect(mapStateToProps)(SingleSelectButton)
