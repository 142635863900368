import React from "react"
import Bounds from "components/bounds"

import Container from "./Container"

export default function HeroFoot(props) {
  return (
    <Bounds
      maxWidth="600"
      render={() => <div {...props}>{props.children}</div>}
      else={() => (
        <div {...props}>
          <Container>{props.children}</Container>
        </div>
      )}
    />
  )
}
