import React, { Component } from "react";
import { connect } from "react-redux";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { INPUT_FIELDS } from "forms/fields/register";
import FormTitle from "components/form/FormTitle";
import { FormButtons } from "components/form";

class RegisterForm extends Component {
  renderFields() {
    return INPUT_FIELDS.map((field) => {
      return <Field key={field.name} {...field} />;
    });
  }

  render() {
    const { errors, dirty, isSubmitting } = this.props;

    return (
      <Form>
        <FormTitle group={"register"} />
        {this.renderFields()}
        <br />
        <FormButtons
          register
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
        />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    actionCalls: state.reducers.actionCalls,
  };
};

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}
Yup.addMethod(Yup.string, "equalTo", equalTo);

const EnhancedRegisterForm = withFormik({
  mapPropsToValues({ locale }) {
    return {
      firstname: "",
      lastname: "",
      email: "",
      postcode: "",
      password: "",
      password2: "",
      customerReg: false,
      termsCond: false,
      privacyPolicy: false,
      canBeContactedResearch: false,
      locale,
    };
  },

  validationSchema: Yup.object().shape({
    firstname: Yup.string().required("required"),
    lastname: Yup.string().required("required"),
    email: Yup.string().email("invalid").required("required"),
    postcode: Yup.string().max(10, "max-10"),
    password: Yup.string().min(8, "min-8").required("required"),
    password2: Yup.string()
      .equalTo(Yup.ref("password"), "passwordsMustMatch")
      .required("required"),
    customerReg: Yup.boolean()
      .test("customerReg", "consent-CustomerReg", (value) => value === true)
      .required("consent"),
    termsCond: Yup.boolean()
      .test("termsCond", "consent-TermsCond", (value) => value === true)
      .required("consent"),
    privacyPolicy: Yup.boolean()
      .test("privacyPolicy", "consent-PrivacyPolicy", (value) => value === true)
      .required("consent"),
  }),
  handleSubmit(values, { props, setSubmitting, resetForm }) {
    props.handleFormSubmit(values, setSubmitting, resetForm);
  },
})(RegisterForm);

export default connect(mapStateToProps)(EnhancedRegisterForm);
