import { features } from "assets"
import Landing from "components/features/Landing"
import NewEntry from "./New_Entry"
import ViewEntries from "./View_Entries"
import ViewEntry from "./View_Entry"

const litterMemberFoodDiaryConfig = {
  name: "litterMemberFoodDiary",
  type: "litterMemberFeature",
  defaultRoute: "/userhome/dashboard/litter/litter_member_dashboard/food_diary",
  menuIcon: { img: features.foodDiary.main, alt: "food_diary_icon" },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "newEntry",
      menuItem: true,
      image: {
        img: features.foodDiary.newEntry,
        alt: "food_diary_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: NewEntry,
      path: "/new_entry",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.foodDiary.viewEntries,
        alt: "food_diary_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
    {
      disabled: false,
      name: "viewEntry",
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
  ],
  tableView: {
    headers: [
      "date",
      "time",
      "onGoing",
      "foodCategory",
      "foodAmount",
      "foodAmountUnit",
    ],
    mobileHeaders: ["date", "time", "onGoing", "foodCategory"],
  },
}

export default litterMemberFoodDiaryConfig
