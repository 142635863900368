import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BottomNav from "./components/Bottom_Nav";
import RoutedItems from "./components/Routed_Items";
import BoxWithBackground from "components/styled/Box_With_Background";

const StickyDiv = styled.div`
  position: sticky;
  top: 0;
  overflow: hidden;
  z-index: 10;
  padding-bottom: 60px;
`;

class MenuMobile extends Component {
  constructor(props) {
    super(props);
    this.top = React.createRef();
  }

  componentDidMount() {
    this.scrollToMyRef();
  }

  scrollToMyRef = () => {
    window.scrollTo({
      top: this.top.current.offsetTop + 10,
      behavior: "auto",
    });
  };

  render() {
    return (
      <>
        <StickyDiv ref={this.top}>
          <BoxWithBackground squareBorder>
            <RoutedItems
              ref={this.top}
              {...this.props}
              routedItems={this.props.nav}
            />
          </BoxWithBackground>
        </StickyDiv>
        <div
          style={{
            position: "fixed",
            width: "100%",
            bottom: 55,
            zIndex: 10,
          }}
        >
          {!this.props.noNav && (
            <BoxWithBackground squareBorder style={{ padding: 4 }}>
              <BottomNav {...this.props} />
            </BoxWithBackground>
          )}
        </div>
      </>
    );
  }
}

export default MenuMobile;

MenuMobile.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  defaultRoute: PropTypes.string,
  menuIcon: PropTypes.object,
  nav: PropTypes.array,
  routes: PropTypes.object,
};
