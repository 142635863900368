import { AUTH_LOG_OUT_USER } from "actions/types";
import {
  DAILY_ACTIVITY_FETCH_TYPE,
  DAILY_ACTIVITY_FETCH,
  DAILY_ACTIVITY_ADD,
  DAILY_ACTIVITY_UPDATE,
  DAILY_ACTIVITY_DELETE,
} from "./actions";
import _ from "lodash";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";

const INITIAL_STATE = {
  entries: [],
  fetchType: "",
  fetchDates: "",
  lastFetchPetID: "",
};

export default function Activity(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DAILY_ACTIVITY_FETCH:
      return {
        ...state,
        entries: _.sortBy(action.payload, "date").reverse(),
        lastFetchPetID: action.pet._id,
      };
    case DAILY_ACTIVITY_FETCH_TYPE:
      return {
        ...state,
        fetchDates: action.payload.dates,
        fetchType: action.payload.type,
      };
    case DAILY_ACTIVITY_ADD:
      return {
        ...state,
        entries: _.sortBy([...state.entries, action.payload], "date").reverse(),
        fetchDates: "",
      };
    case DAILY_ACTIVITY_UPDATE:
      return {
        ...state,
        entries: _.sortBy(
          [
            ...state.entries.filter(
              (entry) => entry._id !== action.payload._id
            ),
            action.payload,
          ],
          "date"
        ).reverse(),
      };
    case DAILY_ACTIVITY_DELETE:
      return {
        ...state,
        entries: state.entries.filter((entry) => entry._id !== action.payload),
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
