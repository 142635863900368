import React from 'react';
import { useEffect, useState } from "react";
import {
  BODY_MARKER_DELETE_MARKER_DETAIL,
  BODY_MARKER_DELETE_MARKER,
  BODY_MARKER_ARCHIVE_MARKER_DETAIL,
} from "../actions";
import {
  MODALS_CONFIRM_DELETE,
  MODALS_SHOW,
  MODALS_HIDE,
  MODALS_BODY_MARKER_DETAIL,
  MODALS_CONFIRM_ARCHIVE,
} from "actions/types";
import { isMobileDevice } from "functions";
import bodyMarkerAssets from "../assets";
import RequireSubFeature from "components/hoc/Require_Sub_Feature";
import BodyMarkerImage from "../components/BodyMarkerImage";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";

function BodyMarkerView(props) {
  const {
    bodyMarker: { frontMarkers, backMarkers, lastFetchPetID },
    fetchMarkers,
    pet,
    providerView,
    providerContact,
  } = props;

  useEffect(() => {
    if (lastFetchPetID !== pet._id) {
      fetchMarkers(pet._id, providerView);
    }
  }, [lastFetchPetID, fetchMarkers, pet._id, providerView]);

  const [backView, setBackView] = useState(true);
  const [frontView, setFrontView] = useState(false);

  // Functions
  function handleAddMarker(values, type) {
    const marker = {
      type,
      top: values.top,
      left: values.left,
      _pet: pet._id,
    };

    handleAddInitialMarkerDetail(marker);
  }

  function handleChangeView(view) {
    if ((view === "back" && backView) || (view === "front" && frontView))
      return;
    setBackView(!backView);
    setFrontView(!frontView);
  }

  function handleDeleteMarker(_id) {
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        noDefault: true,
        ownBodyText: true,
        providerView,
        providerContact,
        type: "bodyMarker",
        actionType: BODY_MARKER_DELETE_MARKER,
        item: _id,
      },
    });
  }

  function handleAddInitialMarkerDetail(marker) {
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_BODY_MARKER_DETAIL,
      modalProps: {
        initialMarkerDetails: true,
        providerView,
        providerContact,
        marker,
        cancel: () => {
          props.dispatch({ type: MODALS_HIDE });
        },
        handleModalClose: () => {
          props.dispatch({ type: MODALS_HIDE });
        },
      },
    });
  }

  function handleAddMarkerDetail(markerId) {
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_BODY_MARKER_DETAIL,
      modalProps: {
        markerId,
        providerView,
        providerContact,
        cancel: () => {
          props.dispatch({ type: MODALS_HIDE });
        },
        handleModalClose: () => {
          props.dispatch({ type: MODALS_HIDE });
        },
      },
    });
  }

  function handleViewMarkerDetail(detail) {
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_BODY_MARKER_DETAIL,
      modalProps: {
        valuesToEdit: detail,
        providerView,
        providerContact,
        viewMode: true,
        editMode: false,
        handleModalClose: () => {
          props.dispatch({ type: MODALS_HIDE });
        },
        handleDeleteButtonPressed: (_id) => handleDeleteDetail(_id),
        handleArchiveButtonPressed: (_id) => handleArchiveDetail(_id),
      },
    });
  }

  function handleDeleteDetail(_id) {
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        providerView,
        providerContact,
        noDefault: true,
        ownBodyText: true,
        type: "markerDetail",
        actionType: BODY_MARKER_DELETE_MARKER_DETAIL,
        item: _id,
      },
    });
  }

  function handleArchiveDetail(_id) {
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_ARCHIVE,
      modalProps: {
        providerView,
        providerContact,
        noDefault: true,
        ownBodyText: true,
        type: "archiveDetail",
        actionType: BODY_MARKER_ARCHIVE_MARKER_DETAIL,
        item: _id,
      },
    });
  }

  if (isMobileDevice()) {
    return (
      <div style={{ paddingTop: 10 }}>
        <Buttons className="is-centered">
          <Button
            group="bodyMarker"
            name="back"
            onClick={() => handleChangeView("back")}
            className={backView && "is-primary"}
          />
          <Button
            group="bodyMarker"
            name="front"
            onClick={() => handleChangeView("front")}
            className={frontView && "is-primary"}
          />
        </Buttons>
        <div style={{ marginTop: 12 }}>
          <div className="columns">
            {backView && (
              <div className="column">
                <BodyMarkerImage
                  img={bodyMarkerAssets[pet.petType].back}
                  markers={backMarkers}
                  setMarker={(marker) => handleAddMarker(marker, "back")}
                  deleteMarker={handleDeleteMarker}
                  addMarkerDetail={handleAddMarkerDetail}
                  viewDetail={handleViewMarkerDetail}
                />
              </div>
            )}
            {!backView && (
              <div className="column">
                <BodyMarkerImage
                  img={bodyMarkerAssets[pet.petType].front}
                  markers={frontMarkers}
                  setMarker={(marker) => handleAddMarker(marker, "front")}
                  deleteMarker={handleDeleteMarker}
                  addMarkerDetail={handleAddMarkerDetail}
                  viewDetail={handleViewMarkerDetail}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ marginTop: 12 }}>
      <div className="columns">
        <div className="column">
          <BodyMarkerImage
            img={bodyMarkerAssets[pet.petType].back}
            markers={backMarkers}
            setMarker={(marker) => handleAddMarker(marker, "back")}
            deleteMarker={handleDeleteMarker}
            addMarkerDetail={handleAddMarkerDetail}
            viewDetail={handleViewMarkerDetail}
          />
        </div>
        <div className="column">
          <BodyMarkerImage
            img={bodyMarkerAssets[pet.petType].front}
            markers={frontMarkers}
            setMarker={(marker) => handleAddMarker(marker, "front")}
            deleteMarker={handleDeleteMarker}
            addMarkerDetail={handleAddMarkerDetail}
            viewDetail={handleViewMarkerDetail}
          />
        </div>
      </div>
    </div>
  );
}

export default RequireSubFeature(BodyMarkerView);
