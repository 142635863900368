import React, { useEffect } from "react"
import Section from "components/layout/Section"
import LitterUsefulLinkForm from "forms/Litter_Useful_Link_Form"

function Links(props) {
  // Deconstructing props
  const {
    usefulLinks,
    fetchUsefulLinks,
    addUsefulLink,
    unselectAll,
    litterMembers,
    selectedLitter,
    history,
  } = props
  const { fetched } = usefulLinks

  // ComponentWillMount & ComponentDidMount
  useEffect(() => {
    if (!fetched) fetchUsefulLinks(selectedLitter._id)
  }, [fetched, fetchUsefulLinks, selectedLitter._id])

  // Main render
  return (
    <Section>
      <LitterUsefulLinkForm
        valuesToEdit={{}}
        cancel={unselectAll}
        litterMembers={litterMembers}
        addUsefulLink={addUsefulLink}
        _litter={selectedLitter._id}
        history={history}
      />
    </Section>
  )
}

export default Links
