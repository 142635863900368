import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./actions";
import Menu from "components/menu";
import config from "./config";

class Appointments extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.appointments,
      labels: {
        ...state.intl.messages.components.appointment.labels,
        ...state.intl.messages.components.forms.labels.common,
        ...state.intl.messages.components.forms.labels.appointments,
      },
      statuses: state.intl.messages.components.appointment.statuses,
    },
    appointments: state.reducers.appointments,
  };
};

export default connect(mapStateToProps, actions)(Appointments);
