import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class Weight extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.weight,
      components: {
        calorieCalculator: state.intl.messages.components.calorieCalculator,
        bodyConditionScore: state.intl.messages.components.bodyConditionScore,
      },
    },
    weight: state.reducers.weight,
    userPreferredUnit: state.reducers.auth.user.featurePreferences.weightUnit,
    pet: state.reducers.pets.selectedPet,
  };
};

export default connect(mapStateToProps)(Weight);
