export const ACTIVITIES = [
  {
    category: "regularWalks",
    activity: "onLeash",
  },
  {
    category: "regularWalks",
    activity: "offLeash",
  },
  {
    category: "play",
    activity: "withPerson",
  },
  {
    category: "play",
    activity: "withOtherDog",
  },
  {
    category: "fitnessTraining",
    activity: "aerobic",
  },
  {
    category: "fitnessTraining",
    activity: "cardio",
  },
  {
    category: "fitnessTraining",
    activity: "balance",
  },
  {
    category: "fitnessTraining",
    activity: "coordination",
  },
  {
    category: "fitnessTraining",
    activity: "flexibility",
  },
  {
    category: "fitnessTraining",
    activity: "coreTraining",
  },
  {
    category: "fitnessTraining",
    activity: "strength",
  },
  {
    category: "otherActivities",
    activity: "swimming",
  },
  {
    category: "otherActivities",
    activity: "hiking",
  },
  {
    category: "otherActivities",
    activity: "enrichment",
  },
  {
    category: "otherActivities",
    activity: "other",
  },
];
