import React, { Component } from "react";
import { connect } from "react-redux";
import { auth as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { INPUT_FIELDS } from "forms/fields/login";
import FormTitle from "components/form/FormTitle";
import FormButtons from "components/form/FormButtons";

class LoginForm extends Component {
  renderFields = () => {
    return INPUT_FIELDS.map((field, i) => {
      return <Field key={field.name} {...field} />;
    });
  };

  render() {
    const { errors, dirty, isSubmitting, viewMode, editMode } = this.props;
    return (
      <Form>
        <FormTitle group={"login"} />
        {this.renderFields()}
        <br />
        <FormButtons
          login
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
        />
      </Form>
    );
  }
}

LoginForm = withFormik({
  mapPropsToValues({ locale }) {
    return {
      email: "",
      password: "",
      locale,
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email("invalid").required("required"),
    password: Yup.string().required("required"),
  }),
  handleSubmit(values, { setSubmitting, props }) {
    props.loginUser(values, setSubmitting, props.history);
  },
})(LoginForm);

const mapStateToProps = (state) => {
  return { locale: state.intl.locale };
};

export default connect(mapStateToProps, actions)(LoginForm);
