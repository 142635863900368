import React from "react";
import { connect } from "react-redux";
import Message from "components/message";

function ExpenseFormSummary({ price, amount, messages, locale }) {
  if (!price || !amount) return null;

  return (
    <Message default>
      <div className="label">{`${messages.labels.total[locale]}:`}</div>
      <p>{(price * amount).toFixed(2)}</p>
    </Message>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: state.intl.messages.components.expenseSummary,
  };
};

export default connect(mapStateToProps)(ExpenseFormSummary);
