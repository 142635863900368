import {
  InputFieldV5,
  TextareaV5,
  CheckboxV5,
  SelectV5,
  SelectButtonsV5,
} from "components/form";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    required: true,
    component: InputFieldV5,
    userCanEdit: true,
  },
  {
    name: "expiry",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    required: true,
    component: InputFieldV5,
    userCanEdit: true,
  },
  {
    name: "parasiteType",
    group: "parasites",
    type: "select",
    icon: "fa fa-paw",
    className: "select ",
    required: true,
    component: SelectV5,
    userCanEdit: true,
  },
  {
    name: "productUsed",
    group: "parasites",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    required: true,
    component: InputFieldV5,
    userCanEdit: true,
  },
  {
    name: "dosageAmount",
    group: "parasites",
    type: "number",
    icon: "fas fa-syringe",
    className: "input ",
    component: InputFieldV5,
    required: false,
    min: 0,
    step: 0.001,
    userCanEdit: true,
  },
  {
    name: "dosageAmountUnit",
    group: "parasites",
    selectOptionsGroup: "amountUnit",
    icon: "fas fa-syringe",
    className: "",
    component: SelectButtonsV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "sideEffectsObserved",
    group: "common",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "sideEffectsDesc",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 3,
    required: false,
    charLimit: 300,
    userCanEdit: true,
  },
  {
    name: "otherObservations",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 3,
    required: false,
    charLimit: 300,
    userCanEdit: true,
  },
];

export { INPUT_FIELDS };
