import React from "react";
import { connect } from "react-redux";
import BodyText from "./BodyText";

function ContactsInfo({ handleModalClose, messages, locale }) {
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <section className="modal-card-body has-text-dark">
          <div className="has-text-right">
            <button
              className="delete is-right"
              aria-label="close"
              onClick={() => handleModalClose()}
            />
          </div>
          <div className="columns is-mobile is-2 is-variable is-paddingless">
            <div className="column"></div>
            <div className="column is-10 has-text-centered">
              <BodyText
                noDefault={true}
                ownBodyText={true}
                messages={messages.bodyText}
                type={"contactsMoreInfo_"}
                locale={locale}
              />
            </div>
            <div className="column"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.components.modals,
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(ContactsInfo);
