import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { auth, pets, litters } from "actions";
import Message from "components/message";
import VerifyEmailForm from "forms/Verify_Email_Form";
import CompleteRegistrationForm from "forms/Complete_Registration_Form";
import UserAddEmailForm from "forms/User_Add_Email_Form";
import BoxWithBackground from "components/styled/Box_With_Background";
import SubscribeNowButton from "components/elements/Subscribe_Now_Button";
import GlobalAndPartnerTiles from "./global_and_partner_tiles";
import SmartTile from "components/SmartTile";
import config from "./config";
import Button from "components/elements/Button";
import TassuApuBlog from "./global_and_partner_tiles/TassuApu_Blog";
import ExternalLink from "components/elements/External_Link";
import Advertisements from "./global_and_partner_tiles/Advertisements";
import Benefits from "./global_and_partner_tiles/Benefits";
import Contacts from "./global_and_partner_tiles/Contacts";
import blogConfig from "containers/main/Blog/config";
import advertisementsConfig from "containers/main/Advertisements/config";
import benefitsConfig from "containers/main/Benefits/config";
import {
  renderMeOnClient,
  whereAmI,
  returnNewObjectAsArrayByValidKey,
  isMobileDevice,
} from "functions";
import FormWrapper from "components/layout/Form_Wrapper";
import Intl from "components/Intl";
import { InfoUpdate } from "components/home/InfoUpdate";

class Userhome extends Component {
  async UNSAFE_componentWillMount() {
    if (_.isEmpty(this.props.user)) {
      // Passing history object for redirect
      // and true to indicate initialFetch
      await this.props.fetchCurrentUser(
        this.props.history,
        true,
        this.props.locale
      );
    }
    await this.props.fetchInfoAndUpdates();
    await this.props.unselectPet();
    await this.props.unselectLitter();
    await this.props.unselectLitterMember();
  }

  render() {
    const { actionCalls, user } = this.props;
    switch (true) {
      case !actionCalls.actionInProcess && !_.isEmpty(actionCalls.actionError):
        return (
          <div>
            <Message error message={actionCalls.actionError.message} />
            <br />
          </div>
        );
      case !actionCalls.actionInProcess && user.completeRegistrationRequired:
        return (
          <FormWrapper styles={{ padding: 8 }}>
            <Message warning message={"completeRegistrationRequired"} />
            <BoxWithBackground>
              <CompleteRegistrationForm />
            </BoxWithBackground>
          </FormWrapper>
        );
      case !actionCalls.actionInProcess && user.verifyEmailRequired:
        return (
          <FormWrapper styles={{ padding: 8 }}>
            <Message warning message={"verifyEmailRequired"} />
            <BoxWithBackground>
              <VerifyEmailForm />
            </BoxWithBackground>
          </FormWrapper>
        );
      case !actionCalls.actionInProcess && user.userEmailRequired:
        return (
          <FormWrapper styles={{ padding: 8 }}>
            <Message warning message={"emailRequired"} />
            <BoxWithBackground>
              <UserAddEmailForm />
            </BoxWithBackground>
          </FormWrapper>
        );
      default:
        return (
          <>
            {this.props.user.signupStage === 1 &&
            this.props.user.userhomeSubscriptionNotice ? (
              <div>
                <Message landing>
                  {this.props.messages.setupSubscription.map((item, i) => {
                    if (!item) return null;
                    if (item.slice(0, 5) === "https")
                      return (
                        <p key={i}>
                          <ExternalLink href={item} label={item} />
                        </p>
                      );
                    return <p key={i}>{item}</p>;
                  })}
                  <br />
                  <SubscribeNowButton subscribe redeemCode />
                  <Button
                    group="common"
                    name="close"
                    withIcon
                    iconRight
                    onClick={() => this.props.dismissUserhomeSubMessage()}
                  />
                </Message>
                <br />
              </div>
            ) : (
              <div />
            )}
            <UserhomeDefault {...this.props} />
          </>
        );
    }
  }
}

const UserhomeDefault = (props) => {
  return (
    <div style={isMobileDevice() ? { marginLeft: 8, marginRight: 8 } : {}}>
      <div className="box" style={{ marginTop: 3 }}>
        <div
          className="title is-3 has-text-weight-bold"
          style={{ color: "#00a0df" }}
        >
          <Intl message path={"components.messages.greetings.welcome_1"} />{" "}
          {props.user.firstname}!
        </div>
        <div className="subtitle is-5">
          <Intl message path={"components.messages.greetings.welcome_2"} />
        </div>
        {props.infoAndUpdates.length > 0 &&
          props.infoAndUpdates.map(({ _id, ...rest }) => (
            <InfoUpdate key={_id} {...rest} />
          ))}
      </div>
      <div style={{ marginBottom: 20 }}>
        <PetSection {...props} />
        <AppTileSection {...props} />
        <GlobalAndPartnerTiles isMobile {...props} />
      </div>
    </div>
  );
};

const PetSection = (props) => {
  return (
    <SectionColumns>
      {props.pets.profiles.length > 0 &&
        props.pets.profiles.map((pet) => (
          <SectionColumn key={pet._id}>
            <SmartTile petTile pet={pet} />
          </SectionColumn>
        ))}
      <SectionColumn>
        <SmartTile addPetTile {...props} />
      </SectionColumn>
    </SectionColumns>
  );
};

const AppTileSection = (props) => {
  return (
    <SectionColumns>
      <SectionColumn>
        <SmartTile userAccountTile />
      </SectionColumn>
      <SectionColumn>
        <SmartTile feedbackAndSupportTile />
      </SectionColumn>
      <SectionColumn>
        <Contacts name={props.messages.contactsTile[props.locale]} {...props} />
      </SectionColumn>
      {renderMeOnClient(blogConfig.clients) && (
        <SectionColumn>
          <TassuApuBlog name={props.messages.blogTile[props.locale]} />
        </SectionColumn>
      )}
      {(whereAmI() === "localhost" ||
        (renderMeOnClient(benefitsConfig.clients) &&
          props.user.subscription)) && (
        <SectionColumn>
          <Benefits
            name={props.messages.benefitsTile[props.locale]}
            {...props}
          />
        </SectionColumn>
      )}
      {(whereAmI() === "localhost" ||
        renderMeOnClient(advertisementsConfig.clients)) && (
        <SectionColumn>
          <Advertisements
            name={props.messages.advertisementsTile[props.locale]}
          />
        </SectionColumn>
      )}
    </SectionColumns>
  );
};

const SectionColumns = (props) => {
  return <div className={config.layout.main.columns}>{props.children}</div>;
};

const SectionColumn = (props) => {
  return (
    <div className={config.layout.petProfiles.column}>{props.children}</div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchCurrentUser: auth.fetchCurrentUser,
      fetchInfoAndUpdates: auth.fetchInfoAndUpdates,
      unselectPet: pets.unselectPet,
      unselectLitter: litters.unselectLitter,
      unselectLitterMember: litters.unselectLitterMember,
      dismissUserhomeSubMessage: auth.dismissUserhomeSubMessage,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => {
  const { messages, locale } = state.intl;
  const setupSubscription = returnNewObjectAsArrayByValidKey(
    state.intl.messages.components.messages.warnings,
    "setupSubscription_",
    locale
  );
  return {
    locale,
    actionCalls: state.reducers.actionCalls,
    user: state.reducers.auth.user,
    pets: state.reducers.pets,
    messages: {
      setupSubscription,
      advertisementsTile: messages.containers.main.advertisements.main.name,
      benefitsTile: messages.containers.main.benefits.main.name,
      contactsTile: messages.containers.contacts.userContacts.tiles.contacts,
      blogTile: messages.containers.main.blog.main.name,
    },
    contacts: state.reducers.contacts,
    infoAndUpdates: state.reducers.appData.infoAndUpdates,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Userhome);
