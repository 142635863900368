import axios from "axios";
import moment from "moment";
import _ from "lodash";
import {
  TABLE_CLEAR_CURRENT_STATE,
  ACTION_STARTED,
  ACTION_SUCCESS,
  MODALS_HIDE,
} from "actions/types";
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "actions/functions";

export const FMPI_FETCH_QUESTIONS = "fmpi_fetch_questions";
export const FMPI_FETCH_RESULTS = "fmpi_fetch_results";
export const FMPI_FETCH_TYPE = "fmpi_fetch_types";
export const FMPI_SUBMIT_RESULT = "fmpi_submit_result";
export const FMPI_DELETE = "fmpi_delete";
export const FMPI_UPDATE = "fmpi_update";

const API = (route) => `/api/fmpi/${route}`;

export const fetchQuestions = () => async (dispatch, getState) => {
  try {
    const { locale } = getState().intl;
    dispatch({ type: ACTION_STARTED, payload: FMPI_FETCH_QUESTIONS });
    const res = await axios.get(API("fetch_questions"), {
      headers: { authorization: localStorage.getItem("token") },
      params: { locale },
    });
    dispatch({ type: FMPI_FETCH_QUESTIONS, payload: res.data.fmpiQuestions });
    endAndResetAction(dispatch);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const fetchResults =
  (value, unit, name, pet, providerView) => async (dispatch) => {
    const dates = !_.isEmpty(value)
      ? { ...value }
      : {
          from: moment().subtract(value, unit).toISOString(),
          to: moment().toISOString(),
        };
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({
        type: FMPI_FETCH_TYPE,
        payload: { dates, type: !_.isEmpty(value) ? "range" : name },
      });
      dispatch({ type: ACTION_STARTED, payload: FMPI_FETCH_RESULTS });
      const res = await axios.get(
        API(providerView ? "fetch_for_provider" : "fetch_results"),
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
          params: { _pet: pet._id, ...dates },
        }
      );
      dispatch({ type: FMPI_FETCH_RESULTS, payload: res.data.results, pet });
      endAndResetAction(dispatch);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const submitResult =
  (values, setSubmitting, history, successRoute) => async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: FMPI_SUBMIT_RESULT });
      const res = await axios.post(API("submit_result"), values, {
        headers: { authorization: localStorage.getItem("token") },
      });
      dispatch({ type: FMPI_SUBMIT_RESULT, payload: res.data.result });
      endAndResetAction(dispatch);
      history.push(successRoute);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const deleteFmpiResult = (_id, callback) => async (dispatch) => {
  try {
    dispatch({ type: MODALS_HIDE });
    dispatch({ type: ACTION_STARTED, payload: FMPI_DELETE });
    const res = await axios.put(
      API("delete"),
      { _id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    );
    dispatch({ type: FMPI_DELETE, payload: res.data.fmpiEntryId });
    endAndResetAction(dispatch);
    return callback();
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const updateFmpiMetaData =
  (values, setSubmitting, history, successRoute) => async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: FMPI_UPDATE });
      const res = await axios.put(API("update"), values, {
        headers: { authorization: localStorage.getItem("token") },
      });
      dispatch({
        type: FMPI_UPDATE,
        payload: res.data.fmpiEntry,
      });
      history.push(successRoute);
      dispatch({
        type: ACTION_SUCCESS,
        payload: "updateSuccess",
      });
      endAndResetActionWithDelay(dispatch, 2000);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

const fmpiActions = {
  fetchQuestions,
  fetchResults,
  submitResult,
  deleteFmpiResult,
  updateFmpiMetaData,
};

export default fmpiActions;
