import React from "react";

function FeaturePromo(props) {
  return (
    <div
      className="box"
      style={{
        padding: 8,
        backgroundColor: "whitesmoke",
        border: "2px solid black",
        borderColor: "#0d2926",
      }}
    >
      {props.title}
    </div>
  );
}

export default FeaturePromo;
