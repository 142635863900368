import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import RequireSubFeature from "components/hoc/Require_Sub_Feature";
import Section from "components/layout/Section";
import SectionTitle from "components/layout/Section_Title";
import { trainingDiary as actions } from "actions";
import TRAINING_TYPES from "constants/training_types";
import TagGroup from "components/elements/TagGroup";
import Tag from "components/elements/Tag";
import Message from "components/message";

class SettingsTrainingDiary extends Component {
  state = {
    initialState: {},
    savePreviousTrainingType: "",
    previousTrainingType: "",
    trainingTypes: [],
  };

  componentDidMount() {
    const { trainingDiarySettings } = this.props.pet;
    this.setState({
      ...trainingDiarySettings,
      initialState: { ...trainingDiarySettings },
    });
  }

  handleSwitchToggle = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };

  handleSelect = (v) => {
    this.state.trainingTypes.includes(v)
      ? this.setState({
          trainingTypes: [
            ...this.state.trainingTypes.filter((item) => item !== v),
          ],
        })
      : this.setState({
          trainingTypes: [...this.state.trainingTypes, v],
        });
  };

  doesExistInArray = (type) => {
    if (!this.state.trainingTypes) {
      return false;
    }
    const res = this.state.trainingTypes.includes(type) ? true : false;
    return res;
  };

  changesDetected = () => {
    const { trainingTypes, savePreviousTrainingType, initialState } =
      this.state;
    const res =
      _.isEqual(
        initialState.savePreviousTrainingType,
        savePreviousTrainingType
      ) &&
      _.isEqual(
        initialState.trainingTypes.concat().sort(),
        trainingTypes.concat().sort()
      )
        ? true
        : false;
    return res;
  };

  handleCancel = () => {
    this.setState({
      ...this.state.initialState,
      initialState: { ...this.state.initialState },
    });
  };

  handleSaveSettings = () => {
    this.setState({
      initialState: {
        savePreviousTrainingType: this.state.savePreviousTrainingType,
        previousTrainingType: this.state.previousTrainingType,
        trainingTypes: this.state.trainingTypes,
      },
    });
    this.props.setCustomSettings(
      this.state,
      this.props.pet._id,
      this.props.providerView
    );
  };

  render() {
    const { pet, messages, checkboxes, buttons, locale } = this.props;
    const { prevTrainingTypeSection, trainingTypeSection } = messages;

    const trainingTypesLocalized = TRAINING_TYPES[pet.petType].map((opt) => ({
      value: opt,
      label: messages.trainingTypes[opt][locale],
    }));

    const trainingTypesOrdered = trainingTypesLocalized.sort((a, b) =>
      a.label > b.label ? 1 : -1
    );

    return (
      <Section>
        <Message info message={"trainingDiarySettings"} />
        <SectionTitle
          title={prevTrainingTypeSection.title}
          subtitle={prevTrainingTypeSection.subtitle}
        />
        <div style={{ margin: 8 }} className="field">
          <input
            id="savePreviousTrainingType"
            type="checkbox"
            name="savePreviousTrainingType"
            className="switch is-rounded"
            checked={this.state.savePreviousTrainingType}
            onChange={(e) => this.handleSwitchToggle(e)}
          />
          <label htmlFor="savePreviousTrainingType">
            {checkboxes.savePreviousTrainingType[locale]}
          </label>
        </div>
        <br />
        <SectionTitle
          title={trainingTypeSection.title}
          subtitle={trainingTypeSection.subtitle}
        />
        <br />
        <TagGroup>
          {trainingTypesOrdered.map(({ value, label }) => (
            <Tag
              key={value}
              color={"is-primary is-light"}
              className={"is-light"}
              onClick={() => this.handleSelect(value)}
              label={label}
              selected={this.doesExistInArray(value)}
            />
          ))}
        </TagGroup>
        <Buttons
          locale={locale}
          disabled={this.changesDetected()}
          cancel={this.handleCancel}
          saveSettings={this.handleSaveSettings}
          messages={buttons}
        />
      </Section>
    );
  }
}

const Buttons = (props) => (
  <div className="buttons">
    <button
      className="button"
      onClick={() => props.cancel()}
      disabled={props.disabled}
    >
      {props.messages.cancel[props.locale]}
    </button>
    <button
      className="button is-info"
      onClick={() => props.saveSettings()}
      disabled={props.disabled}
    >
      {props.messages.saveSettings[props.locale]}
    </button>
  </div>
);

const mapStateToProps = (state) => {
  return {
    checkboxes: state.intl.messages.components.forms.checkboxes.trainingDiary,
    buttons: state.intl.messages.components.buttons.common,
  };
};

export default RequireSubFeature(
  connect(mapStateToProps, actions)(SettingsTrainingDiary)
);
