import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "actions/transfers";
import Section from "components/layout/Section";
import FormWrapper from "components/layout/Form_Wrapper";
import Transfer from "./Transfer";
import TransferStatus from "./Transfer_Status";
import Loading from "components/spinners/Loading";

function TransferLitterMember(props) {
  const { transfersFetch, selectedLitterMember } = props;

  useEffect(() => {
    async function fetch() {
      transfersFetch(selectedLitterMember._id);
    }
    fetch();
  }, [transfersFetch, selectedLitterMember._id]);

  if (props.loading) return <Loading />;

  if (props.transfers)
    return (
      <Section>
        <FormWrapper>
          <TransferStatus {...props} />
        </FormWrapper>
      </Section>
    );

  return (
    <Section>
      <FormWrapper>
        <Transfer {...props} />
      </FormWrapper>
    </Section>
  );
}

const mapStateToProps = (state, ownProps) => {
  const transfersArrayOfObjects = state.reducers.transferPet.transfers.filter(
    (obj) => obj._pet === ownProps.selectedLitterMember._id
  );
  return {
    locale: state.intl.locale,
    transfers: transfersArrayOfObjects.length > 0,
    transferObject: transfersArrayOfObjects[0],
    loading: state.reducers.actionCalls.actionInProcess,
    messages:
      state.intl.messages.containers.litterMemberFeatures.transferLitterMember,
  };
};

export default connect(mapStateToProps, actions)(TransferLitterMember);
