import { InputFieldV5, TextareaV5, CheckboxV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "time",
    group: "common",
    type: "time",
    icon: "far fa-clock",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "temperature",
    group: "temperature",
    type: "number",
    icon: "fas fa-thermometer-half",
    className: "input ",
    component: InputFieldV5,
    withAddons: true,
    addons: "has-addons",
    addonLabel: "temperatureUnit",
    required: true,
    userCanEdit: true,
    min: 34,
    max: 41,
    step: 0.1,
  },
  {
    name: "medicine",
    group: "temperature",
    type: "checkbox",
    className: "input",
    component: CheckboxV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "medicine1",
    group: "temperature",
    type: "text",
    icon: "fas fa-notes-medical",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "medicine2",
    group: "temperature",
    type: "text",
    icon: "fas fa-notes-medical",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "medicine3",
    group: "temperature",
    type: "text",
    icon: "fas fa-notes-medical",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "notes",
    group: "common",
    type: "text",
    className: "textarea ",
    component: TextareaV5,
    rows: 5,
    required: false,
    userCanEdit: true,
    charLimit: 250,
  },
];

export { INPUT_FIELDS };
