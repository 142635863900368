import { features } from "assets"
import routes from "./routes"

const tasksConfig = {
  name: "tasks",
  type: "feature",
  defaultRoute: "/userhome/dashboard/tasks",
  menuIcon: {
    img: features.tasks.main,
    alt: "tasks_feature_icon",
  },
  nav: routes,
  tableView: {},
  chartView: {},
}

export default tasksConfig
