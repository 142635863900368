import React, { useState } from "react";
import { connect } from "react-redux";
import { litters as actions } from "actions/index";
import Section from "components/layout/Section";
import AddLitterMemberForm from "forms/Add_Litter_Member_Form";
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons";
import RequireSubFeature from "components/hoc/Require_Sub_Feature";

function ProfileDetails({ litterMember, updateLitterMember, history, locale }) {
  // Use State
  const [viewMode, setViewMode] = useState(true);
  const [cancelledByUser, setCancelledByUser] = useState(false);

  // Functions
  function handleCancelClick() {
    setCancelledByUser(true);
    setViewMode(true);
  }

  function handleSetViewMode() {
    setCancelledByUser(false);
    setViewMode(false);
  }

  function onUpdateSuccess() {
    const successRoute =
      "/userhome/dashboard/litter/litter_member_dashboard/profile/details";

    setCancelledByUser(false);
    setViewMode(true);
    history.push(successRoute);
  }

  function handleUpdateLitterMember(values, setSubmitting) {
    updateLitterMember(values, setSubmitting, onUpdateSuccess);
  }

  // Main Render
  return (
    <Section>
      <div className="columns is-mobile is-centered">
        <div className="column is-5-desktop is-full-mobile is-6-tablet">
          {!litterMember.hasNewOwner && (
            <BackEditDeleteButtons
              disableBackButton
              disableDeleteButton
              handleEditClick={handleSetViewMode}
              viewMode={viewMode}
            />
          )}
          <AddLitterMemberForm
            cancelledByUser={cancelledByUser}
            valuesToEdit={litterMember}
            editMode
            viewMode={viewMode}
            cancel={handleCancelClick}
            updateLitterMember={handleUpdateLitterMember}
            locale={locale}
          />
        </div>
      </div>
    </Section>
  );
}

export default connect(null, actions)(RequireSubFeature(ProfileDetails));
