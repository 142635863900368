import React from "react";
import { pets } from "assets";
import { connect } from "react-redux";
import * as actions from "actions/transfers";
import Section from "components/layout/Section";
import BoxWithBackground from "components/styled/Box_With_Background";
import SectionTitle from "components/layout/Section_Title";
import { returnDateAndTimeLocaleFormat } from "functions";
import Transfer from "./Transfer";

function TransferStatus(props) {
  return (
    <BoxWithBackground>
      <Section>
        <SectionTitle
          title={props.messages.titles.transferStatus}
          subtitle={""}
        />
        <br />
        <TransferObject {...props} {...props.transferObject} />
      </Section>
      {props.transferObject.lifeCycleState === "error" ||
      props.transferObject.lifeCycleState === "completed" ? null : (
        <div className="buttons is-centered">
          <button
            className="button"
            onClick={() => props.transfersFetch(props.selectedLitterMember._id)}
          >
            {props.messages.buttons.refreshStatus[props.locale]}
          </button>
        </div>
      )}
      {props.transferObject.lifeCycleState === "error" && (
        <Transfer reAttempt {...props} />
      )}
    </BoxWithBackground>
  );
}

const MEDIA_ITEMS = [
  {
    name: "petTransferReceiverEmail",
    icon: false,
    img: pets.transfer,
    alt: "transfer_pet_icon",
  },
  {
    name: "petTransferSenderInitiateTime",
    icon: "far fa-calendar-alt",
    img: false,
    alt: "",
  },
  {
    name: "lifeCycleState",
    icon: "fas fa-exclamation-triangle",
    img: false,
    alt: "",
  },
  {
    name: "petTransferError",
    icon: "fas fa-comment-alt",
    img: false,
    alt: "",
  },
];

const TransferObject = (props) => {
  return (
    <div className="card">
      <div className="card-content">
        {MEDIA_ITEMS.map((item) => {
          if (!props[item.name]) return null;
          return (
            <MediaItem key={item.name} {...item}>
              {item.name === "petTransferSenderInitiateTime" ? (
                <time dateTime={props.petTransferSenderInitiateTime}>
                  {returnDateAndTimeLocaleFormat(
                    props.petTransferSenderInitiateTime,
                    props.locale
                  )}
                </time>
              ) : item.name === "lifeCycleState" ? (
                <p>{props.messages.statuses[props[item.name]][props.locale]}</p>
              ) : (
                <p className="title is-6 has-text-dark">{props[item.name]}</p>
              )}
            </MediaItem>
          );
        })}
      </div>
    </div>
  );
};

const MediaItem = (props) => {
  return (
    <div className="media">
      <div className="media-left">
        {!props.icon && (
          <figure className="image is-24x24">
            <img src={props.img} alt={props.alt} />
          </figure>
        )}
        {!props.img && (
          <span className="icon is-left">
            <i className={props.icon} />
          </span>
        )}
      </div>
      <div className="media-content">{props.children}</div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    messages: {
      ...ownProps.messages,
      buttons: state.intl.messages.components.buttons.common,
    },
  };
};

export default connect(mapStateToProps, actions)(TransferStatus);
