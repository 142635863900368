import React from "react"

function PostWrapper({ children }) {
  return (
    <article className="media box">
      <div className="content">{children}</div>
    </article>
  )
}

export default PostWrapper
