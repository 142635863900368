import axios from "axios";
import moment from "moment";
import _ from "lodash";

import {
  HCPI_FETCH_QUESTIONS,
  HCPI_FETCH_RESULTS,
  HCPI_FETCH_TYPE,
  HCPI_SUBMIT_RESULT,
  HCPI_DELETE,
  HCPI_UPDATE,
  HCPI_ACTIVITY_CHART_DATA,
  ACTION_STARTED,
  ACTION_ERROR,
  ACTION_ENDED,
  ACTION_RESET,
  TABLE_CLEAR_CURRENT_STATE,
  MODALS_HIDE,
  ACTION_SUCCESS,
} from "./types";
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "./functions";

const API = (route) => `/api/hcpi/${route}`;

export const fetchQuestions = () => async (dispatch, getState) => {
  try {
    const { locale } = getState().intl;
    dispatch({ type: ACTION_STARTED, payload: HCPI_FETCH_QUESTIONS });
    const res = await axios.get("/api/hcpi/fetch_questions", {
      headers: { authorization: localStorage.getItem("token") },
      params: { locale },
    });
    dispatch({ type: HCPI_FETCH_QUESTIONS, payload: res.data.hcpiQuestions });
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_RESET });
  } catch (error) {
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      });
    }
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 3000);
  }
};

export const fetchResults = (value, unit, name, pet) => async (dispatch) => {
  const dates = !_.isEmpty(value)
    ? { ...value }
    : {
        from: moment().subtract(value, unit).toISOString(),
        to: moment().toISOString(),
      };
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
    dispatch({
      type: HCPI_FETCH_TYPE,
      payload: { dates, type: !_.isEmpty(value) ? "range" : name },
    });
    dispatch({ type: ACTION_STARTED, payload: HCPI_FETCH_RESULTS });
    const res = await axios.get(API("fetch_results"), {
      headers: {
        authorization: localStorage.getItem("token"),
      },
      params: { _pet: pet._id, ...dates },
    });
    dispatch({ type: HCPI_FETCH_RESULTS, payload: res.data.results, pet });
    endAndResetAction(dispatch);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const fetchResultsForProvider =
  (value, unit, name, pet) => async (dispatch) => {
    const dates = !_.isEmpty(value)
      ? { ...value }
      : {
          from: moment().subtract(value, unit).toISOString(),
          to: moment().toISOString(),
        };
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({
        type: HCPI_FETCH_TYPE,
        payload: { dates, type: !_.isEmpty(value) ? "range" : name },
      });
      dispatch({ type: ACTION_STARTED, payload: HCPI_FETCH_RESULTS });
      const res = await axios.get(API("fetch_for_provider"), {
        headers: {
          authorization: localStorage.getItem("tokenProvider"),
        },
        params: { _pet: pet._id, ...dates },
      });
      dispatch({
        type: HCPI_FETCH_RESULTS,
        payload: res.data.hcpiResults,
        pet,
      });
      dispatch({
        type: HCPI_ACTIVITY_CHART_DATA,
        payload: res.data.activityChartData,
      });
      endAndResetAction(dispatch);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const submitResult =
  (values, setSubmitting, history, successRoute) => async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: HCPI_SUBMIT_RESULT });
      const res = await axios.post("/api/hcpi/submit_result", values, {
        headers: { authorization: localStorage.getItem("token") },
      });
      dispatch({ type: HCPI_SUBMIT_RESULT, payload: res.data.result });
      dispatch({ type: ACTION_RESET });
      history.push(successRoute);
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 3000);
    }
  };

export const deleteHcpiResult = (_id, callback) => async (dispatch) => {
  try {
    dispatch({ type: MODALS_HIDE });
    dispatch({ type: ACTION_STARTED, payload: HCPI_DELETE });
    const res = await axios.put(
      "/api/hcpi/delete",
      { _id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    );
    dispatch({ type: HCPI_DELETE, payload: res.data.hcpiEntryId });
    endAndResetAction(dispatch);
    return callback();
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const updateHCPIMetaData =
  (values, setSubmitting, history, successRoute) => async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: HCPI_UPDATE });
      const res = await axios.put("/api/hcpi/update", values, {
        headers: { authorization: localStorage.getItem("token") },
      });
      dispatch({
        type: HCPI_UPDATE,
        payload: res.data.hcpiEntry,
      });
      history.push(successRoute);
      dispatch({
        type: ACTION_SUCCESS,
        payload: "updateSuccess",
      });
      endAndResetActionWithDelay(dispatch, 2000);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

const hcpiActions = {
  fetchQuestions,
  fetchResults,
  submitResult,
  deleteHcpiResult,
  updateHCPIMetaData,
  fetchResultsForProvider,
};

export default hcpiActions;
