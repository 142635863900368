import { AUTH_LOG_OUT_USER } from "actions/types";
import {
  STRESS_LEVEL_FETCH,
  STRESS_LEVEL_FETCH_TYPE,
  STRESS_LEVEL_ADD,
  STRESS_LEVEL_UPDATE,
  STRESS_LEVEL_DELETE,
} from "./actions";
import _ from "lodash";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";

const INITIAL_STATE = {
  entries: [],
  fetchType: "",
  fetchDates: "",
  lastFetchPetID: "",
};

export default function StressLevel(state = INITIAL_STATE, action) {
  switch (action.type) {
    case STRESS_LEVEL_FETCH:
      return {
        ...state,
        entries: _.sortBy(action.payload, "date", "time").reverse(),
        lastFetchPetID: action.pet._id,
      };
    case STRESS_LEVEL_FETCH_TYPE:
      return {
        ...state,
        fetchDates: action.payload.dates,
        fetchType: action.payload.type,
      };
    case STRESS_LEVEL_ADD:
      return {
        ...state,
        entries: _.sortBy(
          [...state.entries, action.payload],
          "date",
          "time"
        ).reverse(),
        fetchDates: "",
      };
    case STRESS_LEVEL_UPDATE:
      return {
        ...state,
        entries: _.sortBy(
          [
            ...state.entries.filter(
              (entry) => entry._id !== action.payload._id
            ),
            action.payload,
          ],
          "date",
          "time"
        ).reverse(),
      };
    case STRESS_LEVEL_DELETE:
      return {
        ...state,
        entries: state.entries.filter((entry) => entry._id !== action.payload),
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
