import React from "react"
import SectionTitle from "containers/contacts/components/SectionTitle"
import Task from "./Task"

// Groups: Completed || Active
// Title
// Array of Tasks for the group

function TaskGroup(props) {
  return (
    <SectionTitle
      title={`${props.title} - ${props.items.length}`}
      logo={{
        path: "features",
        featureName: "tasks",
        name: props.completed ? "completed" : "viewEntries",
      }}
      expand={props.expand}
    >
      {props.items.map((item, i) => (
        <Task key={item._id} index={i} {...item} {...props} />
      ))}
    </SectionTitle>
  )
}

export default TaskGroup
