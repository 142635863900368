import React, { Component } from "react"
import { connect } from "react-redux"
import Menu from "components/menu"
import config from "./config"

class LitterProfile extends Component {
  render() {
    return <Menu {...config} {...this.props} />
  }
}

const mapStateToProps = state => {
  const { _id } = state.reducers.litters.selectedLitter
  const litterHasMembers =
    state.reducers.litters.litterMembers[_id] === undefined
      ? false
      : state.reducers.litters.litterMembers[_id].length === 0
      ? false
      : state.reducers.litters.litterMembers[_id].length > 0 && true
  return {
    messages: state.intl.messages.containers.litterFeatures.litterProfile,
    litter: state.reducers.litters.selectedLitter,
    litterHasMembers
  }
}

export default connect(mapStateToProps)(LitterProfile)
