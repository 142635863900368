const trainingDiary = {
  smilieButtons: [
    {
      imgPath: "components.buttons.trainingDiaryLevel1",
      imgClassName: "is-rounded",
      figureSize: "48x48",
      figureClassName: "",
      value: 1,
      showValue: true,
    },
    {
      imgPath: "components.buttons.trainingDiaryLevel2",
      imgClassName: "is-rounded",
      figureSize: "48x48",
      figureClassName: "",
      value: 2,
      showValue: true,
    },
    {
      imgPath: "components.buttons.trainingDiaryLevel3",
      imgClassName: "is-rounded",
      figureSize: "48x48",
      figureClassName: "",
      value: 3,
      showValue: true,
    },
    {
      imgPath: "components.buttons.trainingDiaryLevel4",
      imgClassName: "is-rounded",
      figureSize: "48x48",
      figureClassName: "",
      value: 4,
      showValue: true,
    },
    {
      imgPath: "components.buttons.trainingDiaryLevel5",
      imgClassName: "is-rounded",
      figureSize: "48x48",
      figureClassName: "",
      value: 5,
      showValue: true,
    },
  ],
}

export default trainingDiary
