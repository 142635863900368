import React from "react";
import { returnNewObjectOfObjectsAsArray } from "functions";

export default function BodyConditionDescription({
  messages,
  locale,
  name,
  color,
  petType,
}) {
  const descriptions = returnNewObjectOfObjectsAsArray(messages, petType);
  return (
    <div className={`message ${color}`}>
      <div className="message-body content">
        <div className="label heading has-text-grey">
          {messages.titles[name][locale]}
        </div>
        <ul>
          {descriptions[`${petType}_${name}`].map((item, i) => {
            if (!item[locale]) return null;
            return <li key={i}>{item[locale]}</li>;
          })}
        </ul>
      </div>
    </div>
  );
}
