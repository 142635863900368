import React, { useState } from "react";

function Pagination({ children }) {
  const [offset, setOffset] = useState(0);
  const itemsPerPage = 20;

  if (children.length <= 20) return children;

  const changePage = (offset) => {
    setOffset(offset);
  };

  let listItems = [];
  const page = offset * itemsPerPage;
  for (let i = page; i < page + itemsPerPage; i++) {
    if (i >= children.length) {
      break;
    }
    listItems.push(children[i]);
  }

  listItems = listItems.map((item, idx) => {
    return <div key={`list-${idx}`}>{item}</div>;
  });

  const btns = [];
  for (let i = 1; i <= Math.ceil(children.length / itemsPerPage); i++) {
    const elem = (
      <button
        key={i}
        className={`button ${offset === i - 1 && "is-link"}`}
        onClick={() => changePage(i - 1)}
      >
        {i}
      </button>
    );
    btns.push(elem);
  }

  return (
    <>
      <div
        className="buttons is-centered is-marginless"
        style={{ paddingTop: 6 }}
      >
        {btns}
      </div>
      <div>{listItems}</div>
    </>
  );
}

export default Pagination;
