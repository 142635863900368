import React from "react"
import Bounds from "components/bounds"
import styled from "styled-components"

const Label = styled.div`
  font-size: ${props => props.fontSize}px !important;
  text-align: center;
  padding-top: 10px;
`

const CardLabel = props => {
  return (
    <Bounds
      maxWidth={600}
      render={() => (
        <Label fontSize={10}>
          <p className={`is-uppercase  ${props.className}`}>{props.children}</p>
        </Label>
      )}
      else={() => (
        <Label fontSize={12}>
          <p className={`is-uppercase ${props.className}`}>{props.children}</p>
        </Label>
      )}
    />
  )
}

export default CardLabel
