import React, { Component } from "react";
import { connect } from "react-redux";
import BodyCondtionImage from "components/bcs/Body_Condition_Image";
import BodyCondtionDesc from "components/bcs/Body_Condition_Desc";
import Section from "components/layout/Section";

import BCS from "./constants";

class BodyConditionScore extends Component {
  state = {};

  UNSAFE_componentWillMount() {
    const { pet, messages } = this.props;
    this.setState({
      petType: pet.petType,
      bodyConditions: BCS[pet.petType].scale,
      messages: messages.components.bodyConditionScore,
      ...BCS[pet.petType].initialState,
    });
  }

  renderButtons() {
    const { bodyConditions, currentBC } = this.state;
    return (
      <div className="buttons is-centered is-multiline">
        {bodyConditions.map(({ name, color, score }, i) => (
          <button
            style={{ backgroundColor: name !== currentBC ? "white" : "" }}
            key={i}
            className={`button is-rounded ${color} ${
              name !== currentBC ? "is-outlined" : ""
            }`}
            onClick={() => this.handleButtonClick(name, color)}
          >
            <div className="has-text-black">{score}</div>
          </button>
        ))}
      </div>
    );
  }

  handleButtonClick = (name, color) => {
    this.setState({
      currentBC: name,
      color,
    });
  };

  render() {
    const { bodyConditions, messages, currentBC, color, petType } = this.state;
    return (
      <Section>
        {this.renderButtons()}
        <div className="columns">
          <div className="column">
            <div className="box">
              <BodyCondtionImage
                currentBC={currentBC}
                images={bodyConditions}
              />
            </div>
          </div>
          <div className="column">
            <BodyCondtionDesc
              locale={this.props.locale}
              color={color}
              name={currentBC}
              messages={messages}
              petType={petType}
            />
          </div>
        </div>
      </Section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    buttons: state.intl.messages.components.buttons.bodyConditionScore,
  };
};

export default connect(mapStateToProps)(BodyConditionScore);
