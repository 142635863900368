import React, { Component } from "react"
import Routes from "routing"

class Content extends Component {
  componentDidUpdate() {
    window.scrollTo(0, 0)
  }

  render() {
    return <Routes {...this.props} />
  }
}

export default Content
