// Assets
import assets from "assets"

// HOCs
import RequireAuth from "components/hoc/Require_Auth"

// Containers (Main Routes)
import ProviderRegister from "containers/auth/Pet_Services_Portal/Provider_Register"
import ProviderLogin from "containers/auth/Pet_Services_Portal/Provider_Login"
import ProviderHome from "containers/Provider_Home"

// Containers (Nested Routes)
import PROVIDER_HOME_ROUTES from "containers/Provider_Home/routes"
import PROVIDER_ACCOUNT_ROUTES from "containers/Provider_Account/routes"
import PROVIDER_CONTACTS_ROUTES from "containers/contacts/Provider_Contacts/routes"

const providerRoutes = [
  {
    name: "registerProvider",
    path: "/pet_services_portal/register",
    exact: true,
    component: ProviderRegister,
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: false,
    img: {
      src: "",
      alt: "",
    },
  },
  {
    name: "loginProvider",
    path: "/pet_services_portal/login",
    exact: true,
    component: ProviderLogin,
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: false,
    img: {
      src: "",
      alt: "",
    },
  },
  {
    name: "providerHome",
    path: "/providerhome",
    exact: true,
    component: RequireAuth(ProviderHome),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    img: {
      src: assets.main.home,
      alt: "home_logo",
    },
  },
  ...PROVIDER_HOME_ROUTES,
  ...PROVIDER_ACCOUNT_ROUTES,
  ...PROVIDER_CONTACTS_ROUTES,
]

export default providerRoutes
