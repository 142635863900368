export const COLORS = {
  trainersFeeling: "rgba(255, 139, 255, 0.6)",
  petsConcentration: "rgba(255, 0, 124, 0.6)",
  trainerAndPetCoop: "rgba(142, 11, 207, 0.6)",
  overalProgress: "rgba(4, 143, 242, 0.6)",
  hcpiScore: "rgba(0, 160, 223, 0.6)",
  weight: "rgba(0, 216, 154, 0.6)",
  heatDiaryProg: "rgba(174, 143, 234, 0.6)",
  temperature: "rgba(0, 160, 223, 0.6)",
  dailyActivityCats: {
    regularWalks: "#0E4D64",
    play: "#b02121",
    fitnessTraining: "#5A9F2E",
    otherActivities: "#9F4F0F",
    goalOriented: "#4F1E80",
  },
  dailyActivityActs: {
    onLeash: "#15799e",
    offLeash: "#319dc4",
    withPerson: "#b56969",
    withOtherDog: "#783838",
    aerobic: "#7fd14b",
    cardio: "#80b360",
    balance: "#61824d",
    coordination: "#91b37d",
    flexibility: "#63c22b",
    coreTraining: "#449116",
    strength: "#286304",
    swimming: "#c27a40",
    hiking: "#7d4415",
    enrichment: "#d66a11",
    other: "#dea576",
  },
}

export default COLORS
