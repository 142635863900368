import React from "react"
import Section from "components/layout/Section"
import Message from "components/message"

function AddForLitterSuccess(props) {
  return (
    <Section>
      <Message message={"addForLitterSuccess"} success />
    </Section>
  )
}

export default AddForLitterSuccess
