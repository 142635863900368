import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class Vaccinations extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.vaccinations,
      ...state.intl.messages.containers.litterMemberFeatures
        .litterMemberVaccinations,
      landing: {
        ...state.intl.messages.containers.litterMemberFeatures
          .litterMemberVaccinations.landing,
        title:
          state.intl.messages.containers.features.vaccinations.landing.title,
        subtitle:
          state.intl.messages.containers.features.vaccinations.landing.subtitle,
        menuLink:
          state.intl.messages.containers.features.vaccinations.landing.menuLink,
      },
    },
    vacs: state.reducers.vacs,
  };
};

export default connect(mapStateToProps)(Vaccinations);
