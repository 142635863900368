import React from "react";
import { connect } from "react-redux";
import Message from "components/message";
import { returnNewObjectAsArrayByValidKey } from "functions";

function AddPetInfoText(props) {
  const addPetInfoText = returnNewObjectAsArrayByValidKey(
    props.messages,
    "addPet_",
    props.locale
  );

  return (
    <div className="columns is-centered">
      <div className={"column is-5-desktop is-12-mobile is-5-tablet"}>
        <Message default color={"is-info"}>
          {addPetInfoText.map((text, i) => (
            <p key={i}>{text}</p>
          ))}
        </Message>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.reducers.auth.user,
    locale: state.intl.locale,
    messages: state.intl.messages.components.messages.infoBlocks,
  };
};

export default connect(mapStateToProps)(AddPetInfoText);
