import React from "react"
import LitterExpenseForm from "forms/Litter_Expense_Form"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"
import FormWrapper from "components/layout/Form_Wrapper"

function AddExpense(props) {
  return (
    <FormWrapper>
      <LitterExpenseForm
        valuesToEdit={{}}
        _litter={props._litter}
        _pet={props.pet._id}
      />
    </FormWrapper>
  )
}

export default RequireSubFeature(AddExpense)
