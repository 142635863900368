import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Section from "components/layout/Section";
import Message from "components/message";
import FetchEntries from "components/fetch";
import Table from "components/table_2";
import config from "../config";

class ViewResults extends Component {
  handleFetchResults = (value, unit, name) => {
    const { pet, providerView } = this.props;
    this.props.fetchResults(value, unit, name, pet, providerView);
  };

  renderTableSection() {
    const { fmpi, pet, defaultRoute } = this.props;
    const { results } = fmpi;
    const resultsFiltered = results.filter((entry) => entry._pet === pet._id);

    // Create data set for table
    const tableData = {
      feature: "fmpi",
      pet: pet._id,
      id: Date.now(),
      headers: config.tableView.headers,
      mobileHeaders: config.tableView.mobileHeaders,
      data: resultsFiltered,
      viewEntryTo: `${defaultRoute}/view_result/`,
      fetchDates: fmpi.fetchDates,
      fetchType: fmpi.fetchType,
    };

    if (resultsFiltered.length === 0) {
      return <Message warning message={"noEntriesToDisplayRetry"} />;
    }

    return (
      <Table
        withKeys
        tableKeys={config.tableView.tableKeys}
        cellStyle={{ totalPoints: config.tableView.cellStyle.totalPoints }}
        itemsPerPage={20}
        tableData={tableData}
        withCharts
        feature={config.name}
        chartFunc={config.chartFunc}
        ticks={{
          stepSize: 4,
          min: 0,
          max: 80,
        }}
        chartOptions={{
          plugins: {
            annotation: {
              annotations: [
                {
                  id: "box1",
                  type: "box",
                  xMin: 0,
                  xMax: 19,
                  yMin: 0,
                  yMax: 19,
                  backgroundColor: "rgba(163, 242, 141, 0.4)",
                  borderColor: "rgba(163, 242, 141, 0.4)",
                },
                {
                  id: "box2",
                  type: "box",
                  xMin: 0,
                  xMax: 28,
                  yMin: 20,
                  yMax: 28,
                  backgroundColor: "rgba(254, 222, 135, 0.4)",
                  borderColor: "rgba(254, 222, 135, 0.4)",
                },
                {
                  id: "box3",
                  type: "box",
                  xMin: 0,
                  xMax: 80,
                  yMin: 29,
                  yMax: 80,
                  backgroundColor: "rgba(250, 172, 166, 0.4)",
                  borderColor: "rgba(250, 172, 166, 0.4)",
                },
              ],
            },
          },
        }}
      />
    );
  }

  render() {
    const { fetchType, lastFetchPetID } = this.props.fmpi;
    const { pet } = this.props;
    return (
      <>
        <FetchEntries
          fetchItems={this.handleFetchResults}
          fetchType={fetchType}
          newPet={pet._id === lastFetchPetID}
          fetchButtons={["last7Days", "last30Days", "last3Months", "last1Year"]}
        />
        <Section>{this.renderTableSection()}</Section>
      </>
    );
  }
}

export default connect(null, actions)(ViewResults);
