import React from "react";
import Button from "components/elements/Button";

function AddNewLink({ showForm, showNewLinkForm }) {
  return (
    <Button
      withIcon
      iconRight
      group="usefulLinks"
      name="addNewLink"
      className={`is-rounded ${showForm ? "is-success is-focused" : ""}`}
      onClick={() => showNewLinkForm()}
    />
  );
}

export default AddNewLink;
