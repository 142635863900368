import {
  TextareaV5,
  UrlInputLinkV5,
  ImageButtonInputV5,
} from "components/form";

export const INPUT_FIELDS = [
  {
    name: "taskCompletionRating",
    group: "tasks",
    component: ImageButtonInputV5,
    buttons: [
      {
        imgPath: "components.buttons.stressLevel1",
        imgClassName: "is-rounded",
        figureSize: "48x48",
        figureClassName: "",
        value: 1,
        showValue: true,
      },
      {
        imgPath: "components.buttons.stressLevel2",
        imgClassName: "is-rounded",
        figureSize: "48x48",
        figureClassName: "",
        value: 2,
        showValue: true,
      },
      {
        imgPath: "components.buttons.stressLevel3",
        imgClassName: "is-rounded",
        figureSize: "48x48",
        figureClassName: "",
        value: 3,
        showValue: true,
      },
      {
        imgPath: "components.buttons.stressLevel4",
        imgClassName: "is-rounded",
        figureSize: "48x48",
        figureClassName: "",
        value: 4,
        showValue: true,
      },
      {
        imgPath: "components.buttons.stressLevel5",
        imgClassName: "is-rounded",
        figureSize: "48x48",
        figureClassName: "",
        value: 5,
        showValue: true,
      },
    ],
    required: false,
    userCanEdit: true,
    lastInGroup: true,
  },
  {
    name: "taskCompletionNotes",
    group: "tasks",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    userCanEdit: true,
    charLimit: 500,
  },
  {
    name: "taskCompletionURL",
    group: "tasks",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: UrlInputLinkV5,
    required: false,
    userCanEdit: true,
  },
];
