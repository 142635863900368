import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BoxWithBackground from "components/styled/Box_With_Background";
import RequestResetPasswordCodeForm from "forms/Request_Reset_Password_Code_Form";
//import App from "App";
//Anssi imported compose
//import {compose} from "redux";

class ForgotPassword extends Component {
  state = { resetPasswordCodeSent: false };

  handleRequestResetPasswordCodeSentSuccess = () => {
    this.props.history.push("/reset_password");
  };

  render() {
    if (this.props.actionCalls.actionInProcess) return null;
    return (
      <div className="columns is-mobile is-centered">
        <div className="column is-5-desktop is-12-mobile is-5-tablet">
          <BoxWithBackground squareBorder>
            <RequestResetPasswordCodeForm
              user={this.props.user}
              provider={this.props.provider}
              history={this.props.history}
              handleRequestResetPasswordCodeSentSuccess={
                this.handleRequestResetPasswordCodeSentSuccess
              }
            />
          </BoxWithBackground>
        </div>
      </div>
    );
  }
}
//Anssi refactored this
const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const mapStateToProps = (state) => {
  return {
    actionCalls: state.reducers.actionCalls,
  };
};

//export default compose( withRouter,
//  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
//);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
