import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./fields";
import { FormTitle, FormButtons } from "components/form";

class StressLevelEntryForm extends Component {
  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  }

  handleCancelForm = () => {
    this.props.resetForm();
    this.props.cancel();
  };

  render() {
    const { errors, dirty, isSubmitting, viewMode, editMode } = this.props;
    return (
      <Form>
        <FormTitle group={"stressLevel"} />
        <br />
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={() => this.handleCancelForm()}
          linkTo={"/userhome/dashboard/stress_level"}
        />
        <br />
      </Form>
    );
  }
}

StressLevelEntryForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet }) {
    return {
      date:
        moment(valuesToEdit.date).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      time: valuesToEdit.time || moment().format("HH:mm"),
      subject: valuesToEdit.subject || "",
      stressLevel: valuesToEdit.stressLevel || "",
      notes: valuesToEdit.notes || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    time: Yup.string().required("required"),
    subject: Yup.string().max(30, "max-50"),
    stressLevel: Yup.number()
      .required("required")
      .min(1, "greaterThanOrEqualTo-1")
      .max(5, "lessThanOrEqualTo-5"),
    notes: Yup.string().max(250, "max-250"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = "/userhome/dashboard/stress_level/view_entries";
    if (props.editMode) {
      return props.updateStressLevelEntry(
        values,
        setSubmitting,
        props.history,
        successRoute
      );
    }
    return props.addStressLevelEntry(
      values,
      setSubmitting,
      props.history,
      successRoute
    );
  },
})(StressLevelEntryForm);

export default withRouter(StressLevelEntryForm);
