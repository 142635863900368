import React, { useEffect } from "react";
import { connect } from "react-redux";
import Bounds from "components/bounds";
import { objectIsEmpty } from "functions";
import LitterDashboardFeatures from "./Litter_Dashboard_Features";
import LitterMembers from "./Litter_Members";
import config from "./config";

function LitterDashboard(props) {
  useEffect(() => {
    if (objectIsEmpty(props.litter)) {
      return props.history.push("/userhome/dashboard");
    }
  }, [props.litter, props.history]);

  function renderContent() {
    return (
      <div className={config.layout.columns}>
        <Bounds
          maxWidth="800"
          render={() => (
            <>
              <LitterDashboardFeatures isMobile />
              <LitterMembers isMobile />
            </>
          )}
          else={() => (
            <>
              <LitterDashboardFeatures />
              <LitterMembers />
            </>
          )}
        />
      </div>
    );
  }

  return (
    <Bounds
      maxWidth="600"
      render={() => (
        <div style={{ paddingLeft: 8, paddingRight: 8, marginBottom: 20 }}>
          {renderContent()}
        </div>
      )}
      else={() => renderContent()}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    litter: state.reducers.litters.selectedLitter,
  };
};

export default connect(mapStateToProps)(LitterDashboard);
