import React from "react"

export default function NewNotificationBadge({ value }) {
  return (
    <span
      title="new notification"
      className="badge is-danger has-text-centered"
    >
      {value ? value : "!"}
    </span>
  )
}
