import React from "react"
import Styles from "./smartTile.module.css"

export default function Footer(props) {
  function footerItem(link, i) {
    if (link.href) return <div>href</div>

    if (link.to) return <div>href</div>

    if (link.onClick)
      return (
        <div
          key={i}
          className={`card-footer-item ${Styles.tileFooterItem}`}
          onClick={() => link.onClick(link.path)}
        >
          <figure className="image is-24x24">
            <img src={link.img} alt={`smart_tile_footer_item_${link.i}`} />
          </figure>
        </div>
      )
  }

  return (
    <div className={`card-footer ${Styles.tileFooter}`}>
      {props.links && props.links.map((link, i) => footerItem(link, i))}
    </div>
  )
}
