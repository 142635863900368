import {
  InputFieldV5,
  TextareaV5,
  UrlInputLinkV5,
  SelectButtonsV5,
} from "components/form";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "fa fa-paw",
    className: "input",
    required: true,
    component: InputFieldV5,
    userCanEdit: true,
    lastInGroup: true,
  },
  {
    name: "toothState",
    group: "toothMarker",
    selectOptionsGroup: "toothState",
    type: "select",
    icon: "fa fa-paw",
    className: "select ",
    required: true,
    component: SelectButtonsV5,
    userCanEdit: true,
    lastInGroup: true,
    orderAZ: true,
    multiple: true,
  },
  {
    name: "description",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    charLimit: 1000,
    userCanEdit: true,
  },
  {
    name: "attachmentURL",
    group: "common",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: UrlInputLinkV5,
    required: false,
    userCanEdit: true,
    lastInGroup: true,
  },
  {
    name: "gingivalPockets",
    group: "toothMarker",
    type: "number",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
    lastInGroup: true,
  },
  {
    name: "gumRecession",
    group: "toothMarker",
    type: "number",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
    lastInGroup: true,
  },
];

export { INPUT_FIELDS };
