import React from 'react';
import Intl from "components/Intl";

function HelpText(props) {
  return (
    <div className="content has-text-weight-medium">
      <Intl message {...props} />
    </div>
  );
}

export default HelpText;
