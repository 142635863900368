import React, { useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as actions from "./actions";
import { Link } from "react-router-dom";
import BoxWithBackground from "components/styled/Box_With_Background";
import VerifyEmailProviderForm from "./forms/Verify_Email_Form";
import Message from "components/message";
import providerHomeItems from "./routes";
import Section from "components/layout/Section";
import Intl from "components/Intl";
import config from "./config";

function ProviderHome(props) {
  const { fetchCurrentProvider, history, provider } = props;

  useEffect(() => {
    if (_.isEmpty(provider)) {
      fetchCurrentProvider(history);
    }
  }, [fetchCurrentProvider, history, provider]);

  if (provider.verifyEmailRequired) {
    return (
      <div>
        <Message warning message={"verifyEmailRequired"} />
        <BoxWithBackground>
          <VerifyEmailProviderForm />
        </BoxWithBackground>
      </div>
    );
  }

  return (
    <Section>
      <div className="columns is-2 is-variable is-mobile is-centered is-multiline is-flex">
        {providerHomeItems.map((item) => {
          if (item.view !== config.view) return null;
          return (
            <div
              key={item.name}
              className="column is-2-desktop has-text-centered"
            >
              <Link to={item.path}>
                <BoxWithBackground>
                  <div className="level">
                    <div className="level-item">
                      <figure className="image is-48x48">
                        <img src={item.img.src} alt={item.img.alt} />
                      </figure>
                    </div>
                  </div>
                  <p className="title is-6 has-text-grey">
                    <Intl
                      message
                      path={`containers.providers.${[config.view]}.tiles.${[
                        item.name,
                      ]}`}
                    />
                  </p>
                </BoxWithBackground>
              </Link>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

const mapStateToProps = (state) => {
  return {
    provider: state.reducers.auth.provider,
  };
};

export default connect(mapStateToProps, actions)(ProviderHome);
