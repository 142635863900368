import {
  InputFieldV5,
  SelectBoxTrainingType,
  TextareaV5,
  UrlInputLinkV5,
  DistancePerSecond,
  ImageButtonInputV5,
} from "components/form";

import config from "constants/training_diary";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    required: true,
    component: InputFieldV5,
    userCanEdit: true,
  },
  {
    name: "timeStart",
    group: "trainingDiary",
    type: "time",
    icon: "far fa-clock",
    className: "input ",
    required: true,
    component: InputFieldV5,
    userCanEdit: true,
  },
  {
    name: "timeEnd",
    group: "trainingDiary",
    type: "time",
    icon: "far fa-clock",
    className: "input ",
    required: true,
    component: InputFieldV5,
    userCanEdit: true,
  },
  {
    name: "trainingType",
    group: "trainingDiary",
    type: "select",
    icon: "fas fa-paw",
    className: "select ",
    required: true,
    component: SelectBoxTrainingType,
    userCanEdit: false,
  },
  {
    name: "trainingTypeOther",
    group: "trainingDiary",
    renderCondition: (values) => values.trainingType === "other",
    type: "input",
    icon: "fas fa-paw",
    className: "input ",
    required: true,
    component: InputFieldV5,
    userCanEdit: true,
  },
  {
    name: "trainingSubject",
    group: "trainingDiary",
    type: "input",
    icon: "fas fa-paw",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
  },
  {
    name: "distance",
    group: "trainingDiary",
    renderCondition: (values) => values.trainingType !== "agility",
    type: "number",
    icon: "fas fa-paw",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    additionalField: true,
  },
  {
    name: "time",
    group: "trainingDiary",
    renderCondition: (values) => values.trainingType !== "agility",
    type: "text",
    icon: "fas fa-paw",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    additionalField: true,
  },
  {
    name: "agilityDistanceInMetres",
    group: "trainingDiary",
    renderCondition: (values) => values.trainingType === "agility",
    type: "number",
    icon: "fas fa-paw",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    readOnly: false,
    additionalField: true,
  },
  {
    name: "agilityTimeInSeconds",
    group: "trainingDiary",
    renderCondition: (values) => values.trainingType === "agility",
    type: "number",
    icon: "fas fa-paw",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    readOnly: false,
    additionalField: true,
  },
  {
    name: "agilityDistancePerSecond",
    group: "trainingDiary",
    renderCondition: (values) => values.trainingType === "agility",
    type: "number",
    icon: "fas fa-paw",
    className: "input ",
    required: false,
    component: DistancePerSecond,
    userCanEdit: false,
    readOnly: true,
    additionalField: true,
  },
  {
    name: "agilityMinimumSpeedInSeconds",
    group: "trainingDiary",
    renderCondition: (values) => values.trainingType === "agility",
    type: "number",
    icon: "fas fa-paw",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    readOnly: false,
    additionalField: true,
  },
  {
    name: "agilityIdealTimeInSeconds",
    group: "trainingDiary",
    renderCondition: (values) => values.trainingType === "agility",
    type: "number",
    icon: "fas fa-paw",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    readOnly: false,
    additionalField: true,
  },
  {
    name: "points",
    group: "trainingDiary",
    type: "number",
    icon: "fas fa-paw",
    className: "input ",
    required: false,
    component: InputFieldV5,
    userCanEdit: true,
    additionalField: true,
  },
  {
    name: "notes",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    charLimit: 1200,
    userCanEdit: true,
    additionalField: true,
  },
  {
    name: "attachmentURL",
    group: "common",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: UrlInputLinkV5,
    required: false,
    userCanEdit: true,
    additionalField: true,
  },
  {
    name: "trainersFeeling",
    group: "trainingDiary",
    component: ImageButtonInputV5,
    buttons: config.smilieButtons,
    required: false,
    userCanEdit: true,
    additionalField: true,
  },
  {
    name: "petsConcentration",
    group: "trainingDiary",
    component: ImageButtonInputV5,
    buttons: config.smilieButtons,
    required: false,
    userCanEdit: true,
    additionalField: true,
  },
  {
    name: "trainerAndPetCoop",
    group: "trainingDiary",
    component: ImageButtonInputV5,
    buttons: config.smilieButtons,
    required: false,
    userCanEdit: true,
    additionalField: true,
  },
  {
    name: "overalProgress",
    group: "trainingDiary",
    component: ImageButtonInputV5,
    buttons: config.smilieButtons,
    required: false,
    userCanEdit: true,
    additionalField: true,
  },
];

export { INPUT_FIELDS };
