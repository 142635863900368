import React from 'react';
import Date from "./Date";
import Message from "./Message";
import Number from "./Number";

const Intl = ({
  date,
  message,
  number,
  path,
  dateProps,
  numberProps,
  messageProps,
}) => {
  switch (true) {
    case date:
      return <Date dateProps={dateProps} />;
    case message:
      return <Message path={path} messageProps={messageProps} />;
    case number:
      return <Number numberProps={numberProps} />;
    default:
      return null;
  }
};

export default Intl;
