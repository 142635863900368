import React, { Component } from "react"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"
import TrainingDiaryEntryForm from "forms/Training_Diary_Entry_Form"
import Section from "components/layout/Section"

import FormWrapper from "components/layout/Form_Wrapper"

class NewEntryTrainingDiary extends Component {
  render() {
    return (
      <Section>
        <FormWrapper>
          <TrainingDiaryEntryForm valuesToEdit={{}} {...this.props} />
        </FormWrapper>
      </Section>
    )
  }
}

export default RequireSubFeature(NewEntryTrainingDiary)
