import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./full_entry_fields";
import { FormTitle, FormButtons } from "components/form";

class FullAppointmentEntryForm extends Component {
  renderFields = () => {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  };

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      editMode,
      viewMode,
      cancel,
      defaultRoute,
    } = this.props;
    return (
      <div>
        <Form>
          <FormTitle group={"appointments"} />
          <br />
          {this.renderFields()}
          <br />
          <FormButtons
            viewMode={viewMode}
            editMode={editMode}
            isSubmitting={isSubmitting}
            errors={errors}
            dirty={dirty}
            cancel={cancel}
            linkTo={defaultRoute}
          />
          <br />
        </Form>
      </div>
    );
  }
}

FullAppointmentEntryForm = withFormik({
  mapPropsToValues({ valuesToEdit, provider, pet }) {
    return {
      date:
        moment(valuesToEdit.date).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      time: valuesToEdit.time || moment().format("HH:mm"),
      providerServiceName: provider
        ? provider.providerInfo.serviceName
        : valuesToEdit
        ? valuesToEdit.providerServiceName
        : "",
      providerServiceType: provider
        ? provider.providerInfo.serviceType
        : valuesToEdit
        ? valuesToEdit.providerServiceType
        : "",
      description: valuesToEdit.description || "",
      notes: valuesToEdit.notes || "",
      attachmentURL: valuesToEdit.attachmentURL || "",
      userNotes: valuesToEdit.userNotes || "",
      declineReason: valuesToEdit.confirmationState.declineReason || "",
      cancelReason: valuesToEdit.confirmationState.cancelReason || "",
      postAppointmentNotes: valuesToEdit.postAppointmentNotes || "",
      petName: valuesToEdit.petName || pet.petName,
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    time: Yup.string().required("required"),
    providerServiceName: Yup.string().max(50, "max-50"),
    providerServiceType: Yup.string(),
    description: Yup.string().max(30, "max-30").required("required"),
    notes: Yup.string().max(1000, "max-1000"),
    attachmentURL: Yup.string().url("url"),
    userNotes: Yup.string().max(1000, "max-1000"),
    declineReason: Yup.string().max(50, "max-50"),
    cancelReason: Yup.string().max(50, "max-50"),
    postAppointmentNotes: Yup.string().max(1000, "max-1000"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries`;
    if (props.editMode) {
      return props.updateAppointmentsEntry(
        values,
        setSubmitting,
        props.providerView
      );
    }
    return props.addAppointmentsEntry(
      values,
      setSubmitting,
      props.history,
      successRoute,
      props.providerContact,
      props.providerView
    );
  },
})(FullAppointmentEntryForm);

export default connect(null, actions)(FullAppointmentEntryForm);
