import React from "react";

export default function CatUpperIncisors(props) {
  const { confirmSelection, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 263.7 109.73"
      width="263.7"
      height="109.73"
      {...rest}
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".cls-6{fill:#f9f7e1;stroke:#333;stroke-miterlimit:10;stroke-width:1.5px;cursor:pointer;}.cls-7{font-size:12px;fill:#4d4d4d;font-family:SegoeUI-Semibold, Segoe UI;font-weight:600;}",
          }}
        />
      </defs>
      <g id="incisors" style={{ pointerEvents: "bounding-box" }}>
        <g id="cat_upper_incisors">
          <g id="right">
            <path
              id="_103"
              data-name={103}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M11.45,29.93c6.29-6.09,17.34-10,24.87-6.22,18.34,9.2,14.9,63.38,0,68.4C26.7,95.35,9.44,79,5.23,61,4.41,57.48.45,40.58,11.45,29.93Z"
            />
            <path
              id="_102"
              data-name={102}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M82.85,11.28c-8.24-6.6-24.62-3.46-31.09,6.21-5.18,7.75-2.33,17.18,0,24.87C55.91,56.1,67.25,75.11,76.63,73.45,87,71.62,90.08,45.28,89.07,29.93,88.56,22.21,88,15.4,82.85,11.28Z"
            />
            <path
              id="_101"
              data-name={101}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M127.78,7.41C121.19,0,105.32,0,97.62,7.54,91.46,13.61,92.53,22.61,93.4,30c1.56,13.12,9,32.25,18,32.4,10,.18,17.37-23,19-37C131.27,18.28,131.91,12,127.78,7.41Z"
            />
          </g>
          <g id="left">
            <path
              id="_203"
              data-name={203}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M255,29.93c-6.29-6.09-17.33-10-24.87-6.22-18.34,9.2-14.9,63.38,0,68.4C239.8,95.35,257.05,79,261.26,61,262.09,57.48,266,40.58,255,29.93Z"
            />
            <path
              id="_202"
              data-name={202}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M183.65,11.28c8.23-6.6,24.62-3.46,31.08,6.21,5.18,7.75,2.33,17.18,0,24.87-4.15,13.74-15.49,32.75-24.87,31.09-10.39-1.83-13.45-28.17-12.43-43.52C177.94,22.21,178.49,15.4,183.65,11.28Z"
            />
            <path
              id="_201"
              data-name={201}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M138.71,7.41c6.6-7.39,22.47-7.45,30.16.13C175,13.61,174,22.61,173.09,30c-1.56,13.12-9,32.25-18,32.4-10,.18-17.37-23-19-37C135.22,18.28,134.58,12,138.71,7.41Z"
            />
          </g>
          <g id="numbering">
            <text className="cls-7" transform="translate(27.67 107.21)">
              103
            </text>
            <text className="cls-7" transform="translate(68.49 89.94)">
              102
            </text>
            <text className="cls-7" transform="translate(106.16 77.53)">
              101
            </text>
            <text className="cls-7" transform="translate(144.72 77.65)">
              201
            </text>
            <text className="cls-7" transform="translate(179.11 89.82)">
              202
            </text>
            <text className="cls-7" transform="translate(220.64 107.21)">
              203
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}
