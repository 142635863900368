import axios from "axios"

import {
  FEEDBACK_SUBMIT,
  ACTION_STARTED,
  ACTION_ERROR,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
} from "./types"

export const submitFeedback = (values, setSubmitting, resetForm) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: FEEDBACK_SUBMIT })
    await axios.post("/api/feedback/submit", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    setSubmitting(false)
    dispatch({
      type: ACTION_SUCCESS,
      payload: "submitFeedbackSuccess",
    })
    resetForm()
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
  } catch (error) {
    setSubmitting(false)
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

const feedbackActions = {
  submitFeedback,
}

export default feedbackActions
