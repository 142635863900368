import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { user } from "assets";
import TileWrapper from "./TileWrapper";
import Header from "./Header";
import TileBody from "./TileBody";

function UserAccounTile(props) {
  return (
    <TileWrapper>
      <Link to={"/userhome/user_account"}>
        <Header title={props.messages.userAccount.title[props.locale]} />
        <TileBody img={user.main} alt={"user account"} />
      </Link>
    </TileWrapper>
  );
}

const mapStateToProps = (state) => {
  const { messages, locale } = state.intl;
  return {
    locale,
    messages: {
      userAccount: messages.containers.auth.userAccount.landing,
    },
  };
};

export default connect(mapStateToProps, null)(UserAccounTile);
