import { features } from "assets";
import { actionTypes } from "./actions";
import nav from "./routes";

const bodyMarkerConfig = {
  name: "bodyMarker",
  type: "feature",
  defaultRoute: "/userhome/dashboard/body_marker",
  menuIcon: {
    img: features.bodyMarker.main,
    alt: "body_marker_feature_icon",
  },
  nav,
  actionTypes,
  tableView: {
    headers: [],
    mobileHeaders: [],
  },
  chartView: {},
};

export default bodyMarkerConfig;
