import React, { useState } from "react";
import { connect } from "react-redux";
import Bounds from "components/bounds";
import WeightReviewFetchIcon from "./Weight_Review_Fetch_Icon";
import BoxWithBackground from "components/styled/Box_With_Background";
import WeightReviewChart from "./Weight_Review_Chart";
import RequireSubFeature from "components/hoc/Require_Sub_Feature";
import { returnDateRange, isBefore } from "functions";

const ARR = [
  { name: "fromDob" },
  { name: "last7Days" },
  { name: "last14Days" },
  { name: "last30Days" },
  { name: "last3Months" },
  { name: "last6Months" },
  { name: "last9Months" },
  { name: "last1Year" },
];

function FetchButtons(props) {
  const [selected, setSelected] = useState({ name: null });

  function handleFetchTypeSelection(name) {
    setSelected({ name });
  }

  function renderFetchTypes() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          cursor: "pointer",
        }}
      >
        {ARR.map((item, i) => {
          // if the fetch button date range pre-dates the litter dob, do not render the button
          let dateRange = returnDateRange(item.name);
          if (isBefore(dateRange.from, props.litter.litterDob)) return null;
          return (
            <div key={i} onClick={() => handleFetchTypeSelection(item.name)}>
              <BoxWithBackground
                squareBorder={selected.name === item.name}
                style={{
                  padding: 4,
                  margin: 10,
                }}
              >
                <Bounds
                  maxWidth="800"
                  render={() => (
                    <WeightReviewFetchIcon
                      label={props.labels[item.name][props.locale]}
                      width={90}
                      height={90}
                    />
                  )}
                  else={() => (
                    <WeightReviewFetchIcon
                      label={props.labels[item.name][props.locale]}
                      width={100}
                      height={100}
                    />
                  )}
                />
              </BoxWithBackground>
            </div>
          );
        })}
      </div>
    );
  }

  function renderChart() {
    if (!selected.name) return null;

    return (
      <WeightReviewChart
        fetchType={selected.name}
        fetchTypeLabel={props.labels[selected.name][props.locale]}
      />
    );
  }

  return (
    <Bounds
      maxWidth="600"
      render={() => (
        <div style={{ paddingLeft: 8, paddingRight: 8 }}>
          {renderFetchTypes()}
          <br />
          {renderChart()}
        </div>
      )}
      else={() => (
        <div>
          {renderFetchTypes()}
          <br />
          {renderChart()}
        </div>
      )}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    litter: state.reducers.litters.selectedLitter,
    locale: state.intl.locale,
    labels: state.intl.messages.components.fetch.buttons,
  };
};

export default RequireSubFeature(connect(mapStateToProps)(FetchButtons));
