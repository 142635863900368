import React from "react"
import styled from "styled-components"

const LinkWrapper = styled.div`
  margin: 30px;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid black;
  opacity: 0.7;
  box-shadow: 2px 4px 6px;
  border-radius: 8px;
  :hover {
    background-color: #cccccc;
    opacity: 1;
  }

  a,
  a:hover {
    color: #000000;
    font-weight: bold;
    text-decoration: none;
  }
`

const PartnerPageLink = props => (
  <div className="content has-text-dark">
    <LinkWrapper>
      <a rel="noopener noreferrer" href={props.url} target="_blank">
        <span>
          <div className="icon">
            <i className="fas fa-globe" />
          </div>
          {props.label}
        </span>
      </a>
    </LinkWrapper>
  </div>
)

export default PartnerPageLink
