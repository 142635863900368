import React from "react";
import { Form, Field, withFormik } from "formik";
import { difference } from "functions";
import * as Yup from "yup";
import { FIELDS } from "./form_fields";
import _ from "lodash";
import FormValidationMessageV5 from "components/form/FormValidationMessageV5";

function ProviderInfoForm(props) {
  const {
    labels,
    selectOptions,
    locale,
    isSubmitting,
    cancelEdit,
    setFieldInEditState,
    fieldInEditState,
    resetForm,
  } = props;

  function handleCancelEdit() {
    cancelEdit();
    resetForm();
  }

  return (
    <Form>
      {FIELDS.map((f) => (
        <FormField
          key={f.name}
          label={labels[f.name][locale]}
          fieldInEditState={fieldInEditState}
          setFieldInEditState={setFieldInEditState}
          handleCancelEdit={handleCancelEdit}
          disabled={fieldInEditState !== f.name}
          isSubmitting={isSubmitting}
          selectOptions={f.fieldProps.type === "select" ? selectOptions : false}
          locale={locale}
          name={f.name}
          {...f}
          {...props}
        />
      ))}
    </Form>
  );
}

const FormField = ({
  name,
  label,
  labels,
  fieldProps,
  fieldInEditState,
  setFieldInEditState,
  handleCancelEdit,
  disabled,
  isSubmitting,
  locale,
  selectOptions,
  providerCanEdit,
  touched,
  errors,
}) => {
  return (
    <div style={{ marginBottom: 6, backgroundColor: "white" }}>
      <div style={{ paddingLeft: 8, paddingTop: 4 }}>
        <label className="label is-small is-paddingless is-marginless has-text-grey">
          {label}
        </label>
        <FormValidationMessageV5
          clientMessage
          noBox
          labels={labels}
          formName={"register"}
          fields={[name]}
          touched={touched}
          errors={errors}
        />
      </div>
      <div className="columns is-2 is-variable is-mobile is-vcentered is-paddingless is-marginless">
        <div className="column">
          <Field
            name={name}
            disabled={disabled}
            locale={locale}
            selectOptions={selectOptions}
            {...fieldProps}
          />
        </div>
        {providerCanEdit && (
          <>
            {fieldInEditState === name && (
              <div className="column is-1-desktop is-3-mobile is-3-tablet has-text-centered">
                <div className="buttons is-centered">
                  <button
                    className="button is-small"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <i className="has-text-success fas fa-check fa-lg" />
                  </button>
                  <button
                    className="button is-small"
                    type="button"
                    onClick={handleCancelEdit}
                    disabled={isSubmitting}
                  >
                    <i className="has-text-danger fas fa-times fa-lg" />
                  </button>
                </div>
              </div>
            )}
            {!fieldInEditState ? (
              <div className="column is-1-desktop is-3-mobile is-3-tablet has-text-centered">
                <button className="button is-small" type="button">
                  <i
                    className="fas fa-edit fa-lg"
                    onClick={() => setFieldInEditState(name)}
                  />
                </button>
              </div>
            ) : (
              fieldInEditState &&
              fieldInEditState !== name && (
                <div className="column is-1-desktop is-3-mobile is-3-tablet"></div>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};

const EnhancedProviderInfoForm = withFormik({
  mapPropsToValues: ({ providerInfo }) => ({
    email: providerInfo.email,
    vatNumber: providerInfo.vatNumber,
    firstname: providerInfo.firstname,
    lastname: providerInfo.lastname,
    serviceName: providerInfo.serviceName,
    serviceType: providerInfo.serviceType,
    streetAddress: providerInfo.streetAddress,
    postcode: providerInfo.postcode,
    city: providerInfo.city,
    province: providerInfo.province,
  }),

  validationSchema: Yup.object().shape({
    firstname: Yup.string().required("required"),
    lastname: Yup.string().required("required"),
    serviceName: Yup.string().required("required"),
    serviceType: Yup.string().required("required"),
    streetAddress: Yup.string().required("required"),
    postcode: Yup.string().required("required").max(10, "max10"),
    city: Yup.string().required("required"),
    province: Yup.string().required("required"),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    const newValues = difference(values, props.providerInfo);
    if (_.isEmpty(newValues)) return setSubmitting(false);
    props.updateProviderInfo(newValues, setSubmitting);
    props.cancelEdit();
  },
})(ProviderInfoForm);

export default EnhancedProviderInfoForm;
