import React from "react";
import { Link } from "react-router-dom";
import { registerProvider } from "./actions";
import { connect } from "react-redux";
import RegisterForm from "./forms/Register";
import FormWrapper from "components/layout/Form_Wrapper";
import BoxWithBackground from "components/styled/Box_With_Background";
import {
  MODALS_SHOW,
  MODALS_HIDE,
  MODALS_CONFIRM_PROVIDER_FIELDS,
} from "actions/types";
import Intl from "components/Intl";
import { bindActionCreators } from "redux";

function ProviderRegister(props) {
  function handleFormSubmit(values, setSubmitting, resetForm) {
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_PROVIDER_FIELDS,
      modalProps: {
        providerSignup: true,
        type: "confirmProviderFields",
        fieldsToConfirm: { vatNumber: values.vatNumber, email: values.email },
        handleModalClose: () => {
          props.dispatch({ type: MODALS_HIDE });
          setSubmitting(false);
        },
        handleConfirmAndRegister: () => {
          props.dispatch({ type: MODALS_HIDE });
          props.registerProvider(
            values,
            setSubmitting,
            resetForm,
            props.history
          );
        },
      },
    });
  }
  return (
    <FormWrapper>
      <BoxWithBackground squareBorder>
        <br />
        <RegisterForm
          {...props}
          defaultRoute={"/pet_services_portal"}
          handleFormSubmit={handleFormSubmit}
        />
        <br />
        <p className="heading has-text-centered has-text-link has-text-weight-bold">
          <Link to={"/pet_services_portal/login"}>
            <Intl
              message
              path="components.forms.authLinks.register.alreadyRegistered"
            />
          </Link>
        </p>
      </BoxWithBackground>
    </FormWrapper>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        registerProvider,
        dispatch,
      },
      dispatch
    ),
  };
};

export default connect(null, mapDispatchToProps)(ProviderRegister);
