import React, { useEffect } from "react"
import { connect } from "react-redux"
import * as actions from "actions/feature_resources"
import Section from "components/layout/Section"
import FeatureResource from "./Feature_Resource"

function FeatureResources({
  fetched,
  name,
  featureResources,
  fetchFeatureResources,
}) {
  useEffect(() => {
    if (!fetched) {
      fetchFeatureResources(name)
    }
  }, [fetched, fetchFeatureResources, name])

  if (!fetched) return null

  if (featureResources.length === 0) return null

  return (
    <Section>
      <div className="columns">
        {featureResources.map((f) => (
          <div key={f._id} className="column is-4-desktop">
            <FeatureResource {...f} />
          </div>
        ))}
      </div>
    </Section>
  )
}

const mapStateToProps = (state, { name }) => {
  return {
    fetched: state.reducers.featureResources.featuresFetchedFor.includes(name),
    featureResources: state.reducers.featureResources.resources[name],
  }
}

export default connect(mapStateToProps, actions)(FeatureResources)
