import React, { useEffect } from "react";
import { connect } from "react-redux";
import Bounds from "components/bounds";
import { objectIsEmpty } from "functions";
import LitterMemberDashboardFeatures from "./Litter_Member_Dashboard_Features";
import config from "./config";

function LitterMemberDashboard(props) {
  useEffect(() => {
    if (objectIsEmpty(props.pet))
      return props.history.push("/userhome/dashboard/litter");
  }, [props.pet, props.history]);

  function renderContent() {
    return (
      <div className={config.layout.columns}>
        <Bounds
          maxWidth="800"
          render={() => <LitterMemberDashboardFeatures isMobile {...props} />}
          else={() => <LitterMemberDashboardFeatures {...props} />}
        />
      </div>
    );
  }

  return (
    <Bounds
      maxWidth="600"
      render={() => (
        <div style={{ paddingLeft: 8, paddingRight: 8, marginBottom: 20 }}>
          {renderContent()}
        </div>
      )}
      else={() => renderContent()}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    pet: state.reducers.litters.selectedLitterMember,
  };
};

export default connect(mapStateToProps)(LitterMemberDashboard);
