import React, { useEffect } from "react"
import { connect } from "react-redux"
import Section from "components/layout/Section"
import LitterExpenseForm from "forms/Litter_Expense_Form"
import FormWrapper from "components/layout/Form_Wrapper"

function ViewExpenseItem(props) {
  const { expense, location, history } = props
  const { _id } = location

  useEffect(() => {
    if (!_id) {
      history.goBack()
    }
  })

  function handleCancel(e) {
    e.preventDefault()
    props.history.goBack()
  }

  if (!expense) return null

  return (
    <>
      <Section>
        <FormWrapper>
          <LitterExpenseForm
            editMode
            valuesToEdit={expense}
            cancel={handleCancel}
          />
        </FormWrapper>
      </Section>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { _id } = ownProps.location
  const expense = state.reducers.litterExpenses.data.find(
    (exp) => exp._id === _id
  )
  return {
    expense,
  }
}

export default connect(mapStateToProps)(ViewExpenseItem)
