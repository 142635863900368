import React from "react"
import SectionTitle from "containers/contacts/components/SectionTitle"
import Appointment from "./Appointment"

// Groups: Completed || Active
// Title
// Array of Tasks for the group

function TaskGroup(props) {
  return (
    <SectionTitle
      title={`${props.title} - ${props.items.length}`}
      logo={
        props.logo
          ? props.logo
          : {
              path: "features",
              featureName: "appointments",
              name: "viewEntries",
            }
      }
      expand={props.expand}
    >
      {props.items.map((item, i) => {
        return <Appointment key={item._id} index={i} {...item} {...props} />
      })}
    </SectionTitle>
  )
}

export default TaskGroup
