import React from "react";
import inputValidationColor from "./Input_Validation_Color";
import styled from "styled-components";
import { connect } from "react-redux";

const StyledIcon = styled.span`
  .far[data-count] {
    position: relative;
  }

  .far[data-count]:after {
    position: absolute;
    right: -0.75em;
    top: -0.55em;
    content: attr(data-count);
    padding: 0.1em;
    border-radius: 10em;
    line-height: 0.9em;
    color: #444444;
    background: hsl(48, 100%, 67%);
    text-align: center;
    min-width: 2em;
    font: bold 0.4em sans-serif;
  }
`;

function SliderV2(props) {
  const {
    field,
    form,
    className,
    type,
    viewMode,
    min,
    max,
    step,
    showValue,
    useSmilies,
    locale,
    sliders,
    label,
  } = props;

  return (
    <div style={{ padding: "12px 0" }} className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">
          {label} {field.value}
        </div>
      </label>
      <input
        style={{ padding: "0 34px" }}
        id={field.name}
        {...field}
        className={
          viewMode
            ? className
            : `${className} ${inputValidationColor(
                field.name,
                form.touched,
                form.errors
              )}`
        }
        min={min}
        max={max}
        step={step}
        type={type}
        disabled={viewMode}
      />
      {showValue && <output htmlFor={field.name}>{field.value}</output>}
      {useSmilies && (
        <StyledIcon>
          <i
            style={{ paddingLeft: 20 }}
            className={smilies(field.value)}
            data-count={field.value}
          />
        </StyledIcon>
      )}
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <label className="label is-small">
              <div className="heading has-text-grey">
                {sliders[field.name].endLabelLeft[locale]}
              </div>
            </label>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <label className="label is-small">
              <div className="heading has-text-grey">
                {sliders[field.name].endLabelRight[locale]}
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

const smilies = (value) => {
  switch (value) {
    case 1:
      return "far fa-sad-tear fa-2x";
    case 2:
      return "far fa-frown fa-2x";
    case 3:
      return "far fa-meh fa-2x";
    case 4:
      return "far fa-grin fa-2x";
    case 5:
      return "far fa-grin-tongue fa-2x";
    default:
      return "far fa-grin-tongue fa-2x";
  }
};

const mapStateToProps = (state, { group, altName, field }) => {
  const { locale, messages } = state.intl;
  return {
    locale,
    label: messages.components.forms.labels[group][field.name][locale],
    sliders: messages.components.forms.sliders,
  };
};

export default connect(mapStateToProps)(SliderV2);
