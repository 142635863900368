import React, { useState } from "react"
import assets from "assets"
import Styles from "./sectionTitle.module.css"
import NewNotificationBadge from "components/SmartTile/NewNotificationBadge"

export default function SectionTitle({
  title,
  logo: { path, featureName, name },
  badge,
  expand,
  children,
}) {
  const [showChildren, setShowChildren] = useState(false)

  return (
    <div className={Styles.sectionTitleWrapper}>
      <div
        className="card"
        style={{
          border: "2px solid #e6e6e6",
          marginBottom: 3,
          borderRadius: 4,
        }}
      >
        <div className={`card-content ${Styles.cardContent}`}>
          <div className="media">
            <div className="media-left">
              <figure className="image is-24x24">
                {badge && <NewNotificationBadge value={badge} />}
                {featureName ? (
                  <img src={assets[path][featureName][name]} alt="title logo" />
                ) : (
                  <img src={assets[path][name]} alt="title logo" />
                )}
              </figure>
            </div>
            <div className="media-content">
              <div className="content" style={{ paddingBottom: 4 }}>
                <div className="title has-text-dark is-5">{title}</div>
              </div>
            </div>
            {expand && (
              <div
                className="media-right"
                onClick={() => setShowChildren((prevState) => !prevState)}
              >
                <i
                  className={`icon fas fa-chevron-${
                    showChildren ? "up" : "down"
                  }`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {children && (
        <div style={{ borderRadius: 6, border: "2px solid #e6e6e6" }}>
          {expand && showChildren ? children : !expand && children}
        </div>
      )}
    </div>
  )
}
