import { user, main } from "assets"
import Landing from "components/features/Landing"
import Profile from "./Profile"
import Subscription from "./Subscription"
import RedeemCode from "./Redeem_Code"
import DeleteAccount from "./Delete_Account"
import Password from "./Password"

const userAccountConfig = {
  name: "userAccount",
  type: "userAccount",
  defaultRoute: "/userhome/user_account",
  menuIcon: { img: user.main, alt: "user_account_icon" },
  nav: [
    {
      name: "landing",
      disabled: false,
      requiresSubLevel: false,
      menuItem: true,
      image: {
        img: main.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      name: "profile",
      disabled: false,
      requiresSubLevel: false,
      menuItem: true,
      image: {
        img: user.profile,
        alt: "user_account_profile_icon",
      },
      icon: "far fa-address-card",
      component: Profile,
      path: "/profile",
    },
    {
      name: "subscription",
      exact: true,
      disabled: false,
      requiresSubLevel: true,
      subLevels: [
        "none",
        "trial",
        "copper",
        "silver",
        "gold",
        "platinum",
        "diamond",
      ],
      menuItem: true,
      image: {
        img: user.subscription,
        alt: "user_account_subscription_icon",
      },
      icon: "far fa-credit-card",
      component: Subscription,
      path: "/subscription",
    },
    {
      name: "redeemCode",
      disabled: false,
      requiresSubLevel: true,
      subLevels: [
        "none",
        "trial",
        "copper",
        "silver",
        "gold",
        "platinum",
        "diamond",
      ],
      menuItem: true,
      image: {
        img: user.redeemCode,
        alt: "user_account_redeem_code_icon",
      },
      icon: "far fa-credit-card",
      component: RedeemCode,
      path: "/redeem_code",
    },
    {
      name: "password",
      disabled: false,
      requiresSubLevel: false,
      menuItem: true,
      image: {
        img: user.password,
        alt: "user_account_password_icon",
      },
      icon: "fas fa-key",
      component: Password,
      path: "/password_management",
    },
    {
      name: "deleteAccount",
      disabled: true,
      requiresSubLevel: false,
      menuItem: true,
      image: {
        img: user.deleteProfile,
        alt: "user_account_delete_icon",
      },
      icon: "fas fa-ban",
      component: DeleteAccount,
      path: "/delete_account",
    },
  ],
}

export default userAccountConfig
