import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BoxWithBackground from "components/styled/Box_With_Background";
import ResetPasswordForm from "forms/Reset_Password_Form";

class ResetPassword extends Component {
  render() {
    return (
      <div className="columns is-mobile is-centered">
        <div className="column is-5-desktop is-12-mobile is-5-tablet">
          <BoxWithBackground squareBorder>
            <ResetPasswordForm
              user={this.props.user}
              provider={this.props.provider}
              history={this.props.history}
            />
          </BoxWithBackground>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const mapStateToProps = (state) => {
  return {
    actionCalls: state.reducers.actionCalls,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
