import React from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";
import * as actions from "./actions";

function ToothMarker(props) {
  return <Menu {...config} {...props} />;
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.toothMarker,
      labels: {
        ...state.intl.messages.components.toothMarker.labels,
        ...state.intl.messages.components.forms.labels.common,
        ...state.intl.messages.components.forms.labels.toothMarker,
      },
      states: state.intl.messages.components.forms.selectOptions.toothState,
    },
    toothMarker: state.reducers.toothMarker,
  };
};

export default connect(mapStateToProps, actions)(ToothMarker);
