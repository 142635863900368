import axios from "axios"

import {
  PARASITES_FETCH,
  PARASITES_ADD,
  PARASITES_UPDATE,
  PARASITES_DELETE,
  PARASITES_ADD_FOR_LITTER,
  PARASITES_FETCH_FOR_LITTER,
  ACTION_STARTED,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
  MODALS_HIDE,
} from "./types"
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "./functions"

const API = (route) => `/api/parasites/${route}`

export const fetchParasites = (pet, providerView) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: PARASITES_FETCH })
    const res = await axios.get(
      API(providerView ? "fetch_for_provider" : "fetch"),
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
        params: { _pet: pet._id },
      }
    )
    dispatch({ type: PARASITES_FETCH, payload: res.data.parasites, pet })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const fetchParasitesForLitter = (litterId) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: PARASITES_FETCH_FOR_LITTER })
    const res = await axios.get("/api/parasites/fetch_for_litter", {
      headers: {
        authorization: localStorage.getItem("token"),
      },
      params: { _litter: litterId },
    })
    dispatch({ type: ACTION_ENDED })
    dispatch({
      type: PARASITES_FETCH_FOR_LITTER,
      payload: res.data.parasites,
      litterId,
    })
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addParasite = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerContact,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: PARASITES_ADD })
    const res = await axios.post(
      API(providerView ? "add_by_provider" : "add"),
      { _user: providerView ? providerContact._id : undefined, ...values },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({ type: PARASITES_ADD, payload: res.data.parasite })
    endAndResetAction(dispatch)
    history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const updateParasite = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: PARASITES_UPDATE })
    const res = await axios.put(
      API(providerView ? "update_by_provider" : "update"),
      values,
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({ type: PARASITES_UPDATE, payload: res.data.parasite })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const deleteParasite = (
  id,
  history,
  successRoute,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: PARASITES_DELETE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      API(providerView ? "delete_by_provider" : "delete"),
      { id },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({ type: PARASITES_DELETE, payload: res.data.parasite })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addParasiteForLitter = (
  values,
  litterMembers,
  setSubmitting,
  onSuccess
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: PARASITES_ADD_FOR_LITTER })
    const res = await axios.post(
      "/api/parasites/add_for_litter",
      { values, litterMembers },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({ type: PARASITES_ADD_FOR_LITTER, payload: res.data.result })
    dispatch({ type: ACTION_ENDED })
    dispatch({ type: ACTION_RESET })
    onSuccess()
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const parasitesActions = {
  fetchParasites,
  addParasite,
  updateParasite,
  deleteParasite,
  addParasiteForLitter,
}

export default parasitesActions
