import React from "react"
import PostWrapper from "./PostWrapper"
import PostTitle from "./PostTitle"
import PostExcerpt from "./PostExcerpt"
import PostReadMoreButton from "./PostReadMoreButton"
import PostTags from "./PostTags"
import PostContent from "./PostContent"

function Post(props) {
  const { post, locale, excerpt, blog } = props
  return (
    <PostWrapper>
      {!excerpt && <PostTags postTags={post.tags} blogTags={blog.tags} />}
      <PostTitle title={post.title.rendered} date={post.date} locale={locale} />
      {excerpt ? (
        <PostExcerpt content={post.excerpt.rendered} />
      ) : (
        <PostContent content={post.content.rendered} />
      )}
      {excerpt && (
        <>
          <PostReadMoreButton post={post} {...props} />
          <PostTags postTags={post.tags} blogTags={blog.tags} />
        </>
      )}
    </PostWrapper>
  )
}

export default Post
