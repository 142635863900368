import React from "react"

function AssociateLogo(props) {
  return (
    <div
      style={{
        padding: 12,
        backgroundColor: "white",
        width: "auto",
        maxHeight: 75,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 4,
        marginBottom: 44
      }}
    >
      <a rel="noopener noreferrer" href={props.url} target="_blank">
        <figure className="image is-256x256 is-centered">
          <img src={props.logo.fields.file.url} alt="associate_logo" />
        </figure>
      </a>
    </div>
  )
}

export default AssociateLogo
