import { litterFeatures } from "assets"
import Landing from "components/features/Landing"
import ChartsView from "./Charts_View"

const weightReviewConfig = {
  name: "weightReview",
  type: "litterFeature",
  defaultRoute: "/userhome/dashboard/litter/weight_review",
  menuIcon: { img: litterFeatures.weight, alt: "weight_review_icon" },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: litterFeatures.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "chartsView",
      menuItem: true,
      image: {
        img: litterFeatures.weightReview,
        alt: "weight_review_icon",
      },
      icon: "far fa-plus-square",
      component: ChartsView,
      path: "/charts",
    },
  ],
  testData: {
    data: [
      ["Date", "Puppy 11", "Puppy 12", "Puppy 13"],
      [new Date(2019, 10, 9), 0.41, 0.42, 0.425],
      ["2019-10-10", 0.43, 0.433, 0.439],
      ["2019-10-11", 0.44, 0.442, 0.444],
      ["2019-10-12", 0.46, 0.468, 0.49],
      ["2019-10-13", 0.48, 0.484, 0.488],
      ["2019-10-14", 0.5, 0.514, 0.517],
      ["2019-10-15", 0.52, 0.524, 0.527],
      ["2019-10-16", 0.53, 0.534, 0.537],
      ["2019-10-17", 0.54, 0.554, 0.557],
      ["2019-10-18", 0.56, 0.564, 0.568],
      ["2019-10-19", 0.57, 0.573, 0.574],
      ["2019-10-20", 0.58, 0.583, 0.578],
      ["2019-10-21", 0.59, 0.594, 0.598],
      ["2019-10-22", 0.61, 0.615, 0.618],
      ["2019-10-23", 0.62, 0.625, 0.632],
      ["2019-10-24", 0.64, 0.643, 0.645],
      ["2019-10-25", 0.65, 0.652, 0.655],
      ["2019-10-26", 0.67, 0.674, 0.678],
      ["2019-10-27", 0.69, 0.692, 0.693],
      ["2019-10-28", 0.7, 0.71, 0.712],
      ["2019-10-29", 0.72, 0.723, 0.727],
      ["2019-10-30", 0.73, 0.737, 0.738],
      ["2019-10-31", 0.75, 0.751, 0.753],
      ["2019-11-01", 0.76, 0.764, 0.765],
      ["2019-11-02", 0.78, 0.781, 0.783],
      ["2019-11-03", null, null, null], // average values
      ["2019-11-04", null, null, null], // average values
      ["2019-11-05", 1, 1.1, 1.12],
      ["2019-11-06", null, null, null], // average values
      ["2019-11-07", null, null, null], // average values
      ["2019-11-08", 1.3, 1.35, 1.39],
    ],
  },
}

export default weightReviewConfig
