import React from "react";
import { connect } from "react-redux";

const ButtonIconOnly = (props) => {
  const buttonStyle = { width: 28, height: 28 };

  if (props.href)
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        style={{ minHeight: 40, ...buttonStyle }}
        className={`button ${props.className}`}
        href={props.href}
      >
        <i className={props.labels[props.group][props.name].icon} />
      </a>
    );

  return (
    <div
      style={{ minHeight: 40, ...buttonStyle,border:`1px ridge lightgrey`}}
      className={`button ${props.className}`}
      onClick={props.onClick}
    >
      <i className={props.labels[props.group][props.name].icon} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    labels: state.intl.messages.components.buttons,
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(ButtonIconOnly);
