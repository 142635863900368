import axios from "axios";
import {
  ACTION_STARTED,
  ACTION_SUCCESS,
  ACTION_ERROR,
  ACTION_NO_SUCCESS,
  ACTION_STARTED_NO_LOADING_STATE,
  MODALS_HIDE,
} from "actions/types";
import {
  endAndResetAction,
  endAndResetActionWithDelay,
} from "actions/functions";

export const USER_FETCH_CONTACTS = "user_fetch_contacts";
export const USER_ACCEPTS_CONTACT_REQUEST = "user_accepts_contact_request";
export const USER_REJECTS_CONTACT_REQUEST = "user_rejects_contact_request";
export const USER_TOGGLES_ACCESS_FOR_PROVIDER =
  "user_toggles_access_for_provider";
export const USER_REQUESTS_AN_APPOINTMENT = "user_requests_and_appointment";
export const USER_REQUESTS_TO_BE_CONTACTED = "user_requests_to_be_contacted";

export const PROVIDER_FETCH_CONTACTS = "provider_fetch_contacts";
export const PROVIDER_FETCH_CONTACTS_PETS = "provider_fetch_contacts_pets";
export const PROVIDER_SETS_CURRENT_CONTACT = "provider_sets_current_contact";
export const PROVIDER_UNSETS_CURRENT_CONTACT =
  "provider_unsets_current_contact";
export const PROVIDER_SENDS_INVITE_TO_CONTACT =
  "provider_sends_invite_to_contact";
export const PROVIDER_UPDATES_CLIENT_DATA = "provider_updates_client_data";
export const PROVIDER_DELETES_CONTACT = "provider_deletes_contact";
export const PROVIDER_ACKNOWLEDGES_APPOINTMENT_REQUEST =
  "provider_acknowledges_appointment_request";
export const PROVIDER_ACKNOWLEDGES_CONTACT_REQUEST =
  "provider_acknowledges_contact_request";

export const sendInviteToContact =
  (values, setSubmitting, resetForm) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: PROVIDER_SENDS_INVITE_TO_CONTACT,
      });
      const res = await axios.post("/api/contacts/send_invite", values, {
        headers: { authorization: localStorage.getItem("tokenProvider") },
      });

      function wrapUpAction() {
        setSubmitting(false);
        resetForm();
        endAndResetActionWithDelay(dispatch, 3000);
      }

      if (res.data.pending) {
        dispatch({
          type: ACTION_NO_SUCCESS,
          payload: "requestPending",
        });
        return wrapUpAction();
      }
      if (res.data.accepted) {
        dispatch({
          type: ACTION_NO_SUCCESS,
          payload: "requestAccepted",
        });
        return wrapUpAction();
      }
      dispatch({
        type: ACTION_SUCCESS,
        payload: "sendInviteToContactSuccess",
      });
      return wrapUpAction();
    } catch (error) {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      });
      return endAndResetActionWithDelay(dispatch, 3000);
    }
  };

export const fetchContactsForUser = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED_NO_LOADING_STATE,
      payload: USER_FETCH_CONTACTS,
    });
    const res = await axios.get("/api/contacts/fetch_for_user", {
      headers: { authorization: localStorage.getItem("token") },
    });
    dispatch({ type: USER_FETCH_CONTACTS, payload: res.data.contacts });
    return endAndResetAction(dispatch);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    return endAndResetActionWithDelay(dispatch, 3000);
  }
};

export const acceptContactRequest = (_id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: USER_ACCEPTS_CONTACT_REQUEST });
    const res = await axios.post(
      "/api/contacts/accept_request",
      { _id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    );
    dispatch({ type: USER_FETCH_CONTACTS, payload: res.data.contacts });
    return endAndResetAction(dispatch);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    return endAndResetActionWithDelay(dispatch, 3000);
  }
};

export const rejectContactRequest = (_id) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: USER_REJECTS_CONTACT_REQUEST });
    const res = await axios.post(
      "/api/contacts/reject_request",
      { _id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    );
    dispatch({ type: USER_FETCH_CONTACTS, payload: res.data.contacts });
    return endAndResetAction(dispatch);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    return endAndResetActionWithDelay(dispatch, 3000);
  }
};

export const toggleAccessForProvider = (_id) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: USER_TOGGLES_ACCESS_FOR_PROVIDER,
    });
    const res = await axios.post(
      "/api/contacts/toggle_provider_access",
      { _id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    );
    dispatch({ type: USER_FETCH_CONTACTS, payload: res.data.contacts });
    return endAndResetAction(dispatch);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    return endAndResetActionWithDelay(dispatch, 3000);
  }
};

export const fetchContactsForProvider = () => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED_NO_LOADING_STATE,
      payload: PROVIDER_FETCH_CONTACTS,
    });
    const res = await axios.get("/api/contacts/fetch_for_provider", {
      headers: { authorization: localStorage.getItem("tokenProvider") },
    });
    if (res.data.contacts) {
      dispatch({ type: PROVIDER_FETCH_CONTACTS, payload: res.data.contacts });
    }
    return endAndResetAction(dispatch);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    return endAndResetActionWithDelay(dispatch, 3000);
  }
};

export const fetchContactsPets = (_user) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED_NO_LOADING_STATE,
      payload: PROVIDER_FETCH_CONTACTS_PETS,
    });
    const res = await axios.post(
      "/api/pets/fetch_for_provider",
      { _user },
      {
        headers: { authorization: localStorage.getItem("tokenProvider") },
      }
    );
    if (res.data.pets) {
      dispatch({
        type: PROVIDER_FETCH_CONTACTS_PETS,
        payload: { pets: res.data.pets, _user },
      });
    }
    return endAndResetAction(dispatch);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    return endAndResetActionWithDelay(dispatch, 3000);
  }
};

export const setCurrentContact = (contact) => (dispatch) => {
  dispatch({ type: PROVIDER_SETS_CURRENT_CONTACT, payload: contact });
};

export const unsetCurrentContact = () => (dispatch) => {
  dispatch({ type: PROVIDER_UNSETS_CURRENT_CONTACT });
};

export const updateClientDataForProvider =
  (data, setSubmitting) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: PROVIDER_UPDATES_CLIENT_DATA,
      });
      const res = await axios.put("/api/contacts/update_client_data", data, {
        headers: { authorization: localStorage.getItem("tokenProvider") },
      });
      dispatch({
        type: PROVIDER_UPDATES_CLIENT_DATA,
        payload: res.data.contact,
      });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "updateSuccess",
      });
      return endAndResetActionWithDelay(dispatch, 1500);
    } catch (error) {
      setSubmitting(false);
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      });
      return endAndResetActionWithDelay(dispatch, 3000);
    }
  };

export const deleteContactForProvider = (_id) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: PROVIDER_DELETES_CONTACT,
    });
    dispatch({ type: MODALS_HIDE });
    const res = await axios.put(
      "/api/contacts/provider_delete_contact",
      { _id },
      {
        headers: { authorization: localStorage.getItem("tokenProvider") },
      }
    );
    dispatch({
      type: PROVIDER_DELETES_CONTACT,
      payload: res.data.contactId,
    });
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    });
    return endAndResetActionWithDelay(dispatch, 1500);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    return endAndResetActionWithDelay(dispatch, 3000);
  }
};

export const userRequestsAnAppointment = (values) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: USER_REQUESTS_AN_APPOINTMENT,
    });
    const res = await axios.post(
      "/api/contacts/user_requests_an_appointment",
      values,
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    );
    dispatch({ type: USER_FETCH_CONTACTS, payload: res.data.contacts });
    dispatch({
      type: ACTION_SUCCESS,
      payload: "requestSentSuccess",
    });
    return endAndResetActionWithDelay(dispatch, 1500);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    return endAndResetActionWithDelay(dispatch, 3000);
  }
};

export const userRequestsToBeContacted = (values) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: USER_REQUESTS_TO_BE_CONTACTED,
    });
    const res = await axios.post(
      "/api/contacts/user_requests_to_be_contacted",
      values,
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    );
    dispatch({ type: USER_FETCH_CONTACTS, payload: res.data.contacts });
    dispatch({
      type: ACTION_SUCCESS,
      payload: "requestSentSuccess",
    });
    return endAndResetActionWithDelay(dispatch, 1500);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    return endAndResetActionWithDelay(dispatch, 3000);
  }
};

export const providerAcknowledgesAppointmentRequest =
  (_id) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: PROVIDER_ACKNOWLEDGES_APPOINTMENT_REQUEST,
      });
      const res = await axios.put(
        "/api/contacts/provider_acknowledges_appointment_request",
        { _id },
        {
          headers: { authorization: localStorage.getItem("tokenProvider") },
        }
      );
      dispatch({
        type: PROVIDER_UPDATES_CLIENT_DATA,
        payload: res.data.contact,
      });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "updateSuccess",
      });
      return endAndResetActionWithDelay(dispatch, 1500);
    } catch (error) {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      });
      return endAndResetActionWithDelay(dispatch, 3000);
    }
  };

export const providerAcknowledgesContactRequest = (_id) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: PROVIDER_ACKNOWLEDGES_CONTACT_REQUEST,
    });
    const res = await axios.put(
      "/api/contacts/provider_acknowledges_contact_request",
      { _id },
      {
        headers: { authorization: localStorage.getItem("tokenProvider") },
      }
    );
    dispatch({
      type: PROVIDER_UPDATES_CLIENT_DATA,
      payload: res.data.contact,
    });
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    });
    return endAndResetActionWithDelay(dispatch, 1500);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    });
    return endAndResetActionWithDelay(dispatch, 3000);
  }
};
