import React from "react"
import { features } from "assets"

const ReportTypes = props => {
  return (
    <div className="columns is-2 is-variable is-multiline is-centered is-mobile">
      {props.templates.map((template, i) => {
        if (props.type === "feature" && !template.petFeature) return null
        if (
          props.type === "litterMemberFeature" &&
          !template.litterMemberFeature
        )
          return null

        if (
          (template.petTypes.includes(props.petType) ||
            template.petTypes.includes("all")) &&
          (template.petSex.includes(props.petSex) ||
            template.petSex.includes("all"))
        ) {
          return (
            <ReportType
              key={i}
              {...template}
              {...props}
              icons={features.reporting.reportTypes}
            />
          )
        }
        return null
      })}
    </div>
  )
}

const ReportType = props => {
  return (
    <div className="column is-6-mobile is-2-desktop is-2-tablet">
      <div
        style={{ cursor: "pointer", padding: 6, height: "100%" }}
        className="box"
        onClick={() => props.handleReportSelection(props.reportType, props._id)}
      >
        <div
          style={{ justifyContent: "center", padding: 10 }}
          className="card-content is-flex"
        >
          <figure className="image is-96x96">
            <img
              src={props.icons[props.reportType]}
              alt={props.messages.reportTypes[props.reportType][props.locale]}
            />
          </figure>
        </div>
        <p className="heading has-text-centered is-uppercase">
          {props.messages.reportTypes[props.reportType][props.locale]}
        </p>
      </div>
    </div>
  )
}

export default ReportTypes
