import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SELECT_BUTTONS from "constants/select_options";

function TimeSelectButtons({ form, locale, selectOptions, field, viewMode }) {
  const { name } = field;
  const { setFieldValue } = form;

  const [timeInMins, setTimeInMins] = useState(field.value);

  useEffect(() => {
    function updateFormValues() {
      setFieldValue(name, timeInMins);
    }

    updateFormValues();
  }, [timeInMins, name, setFieldValue]);

  return (
    <div className="field">
      <div className="buttons is-centered">
        {SELECT_BUTTONS.timeMins.options.map((b, i) => (
          <div
            key={i}
            className={`button ${timeInMins === b && "is-primary"}`}
            style={{ width: 50 }}
            onClick={viewMode ? null : () => setTimeInMins(b)}
          >
            {selectOptions.timeMins[b][locale]}
          </div>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.intl.locale,
    selectOptions: {
      timeHrs: state.intl.messages.components.forms.selectOptions.timeHrs,
      timeMins: state.intl.messages.components.forms.selectOptions.timeMins,
    },
  };
};

export default connect(mapStateToProps)(TimeSelectButtons);
