import React from "react"
import { features, litterFeatures } from "assets"
import Landing from "components/features/Landing"
import AddNew from "../components/Add_New"
import AddForLitterSuccess from "../components/Add_For_Litter_Success"

const litterFoodDiaryConfig = {
  name: "litterFoodDiary",
  type: "litterFeature",
  defaultRoute: "/userhome/dashboard/litter/food_diary",
  menuIcon: {
    img: features.foodDiary.main,
    alt: "litter_food_diary_icon",
  },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: litterFeatures.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "newEntry",
      menuItem: true,
      image: {
        img: features.foodDiary.newEntry,
        alt: "litter_food_diary_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: (props) => <AddNew litterFoodDiary {...props} />,
      path: "/new_entry",
    },
    {
      disabled: false,
      name: "addForLitterSuccess",
      menuItem: false,
      component: (props) => <AddForLitterSuccess {...props} />,
      path: "/add_for_litter_success",
    },
  ],
}

export default litterFoodDiaryConfig
