const homeCareOptions = {
  dog: [
    {
      category: "coatCare",
      activities: ["coatCareBrushing", "coatCareWashing", "coatCareTrimming"],
    },
    {
      category: "earCare",
      activities: ["earCareHairRemoval", "earCareCleaning"],
    },
    {
      category: "dentalCare",
      activities: [
        "dentalCareBrushing",
        "dentalCareUltrasonicCleaning",
        "dentalCareCheckup",
      ],
    },
    {
      category: "eyeCare",
      activities: ["eyeCareCleaning", "eyeCareCheckup"],
    },
    {
      category: "pawCare",
      activities: [
        "pawCareNailClipping",
        "pawCareWashing",
        "pawCareHairTrimming",
        "pawCareCheckup",
        "pawCareMoisturizing",
      ],
    },
    {
      category: "tickCheck",
      activities: ["tickCheckRemoval", "tickCheckCheckup"],
    },
    {
      category: "bodyCare",
      activities: ["bodyCareHomeMassage", "bodyCareHomePhysiotherapy"],
    },
    {
      category: "other",
      activities: [
        "otherEmptyingAnalGlands",
        "otherScreeningForTumorChanges",
        "otherOwnEntry",
      ],
    },
  ],
  cat: [
    {
      category: "coatCare",
      activities: ["coatCareBrushing", "coatCareWashing", "coatCareTrimming"],
    },
    {
      category: "earCare",
      activities: ["earCareCleaning"],
    },
    {
      category: "dentalCare",
      activities: [
        "dentalCareBrushing",
        "dentalCareUltrasonicCleaning",
        "dentalCareCheckup",
      ],
    },
    {
      category: "eyeCare",
      activities: ["eyeCareCleaning", "eyeCareCheckup"],
    },
    {
      category: "pawCare",
      activities: ["pawCareNailClipping", "pawCareCheckup"],
    },
    {
      category: "tickCheck",
      activities: ["tickCheckRemoval", "tickCheckCheckup"],
    },
    {
      category: "bodyCare",
      activities: ["bodyCareHomeMassage", "bodyCareHomePhysiotherapy"],
    },
    {
      category: "other",
      activities: ["otherScreeningForTumorChanges", "otherOwnEntry"],
    },
  ],
}

export default homeCareOptions
