import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchWeightEntries } from "actions/weight";
import Section from "components/layout/Section";
import Message from "components/message";
import FetchEntries from "components/fetch";
import Table from "components/table_2";
import config from "./config";

class ViewEntries extends Component {
  handleFetchEntries = (value, unit, name) => {
    const { pet, providerView } = this.props;
    this.props.fetchWeightEntries(value, unit, name, pet, providerView);
  };

  returnStepSize = (max) => {
    const { pet, userPreferredUnit } = this.props;
    if (pet.petType === "dog" || pet.petType === "cat") {
      if (userPreferredUnit === "kg") {
        return 200;
      } else {
        return 10;
      }
    }
    return null;
  };

  renderTableSection() {
    const { weight, pet, defaultRoute, userPreferredUnit } = this.props;
    const { entries } = weight;
    const entriesFiltered = entries.filter((entry) => entry._pet === pet._id);

    // Create data set for table
    const tableData = {
      feature: "weight",
      pet: pet._id,
      id: Date.now(),
      headers: config.tableView.headers,
      mobileHeaders: config.tableView.mobileHeaders,
      data: entriesFiltered,
      viewEntryTo: `${defaultRoute}/view_entry/`,
      fetchDates: weight.fetchDates,
      fetchType: weight.fetchType,
    };

    if (entriesFiltered.length === 0) {
      return <Message warning message={"noEntriesToDisplayRetry"} />;
    }

    return (
      <Table
        tableData={tableData}
        itemsPerPage={5}
        withCharts
        feature={config.name}
        chartFunc={config.chartFunc}
        userPreferredUnit={userPreferredUnit}
        ticks={{
          beginAtZero: false,
          ticks: {
            stepSize: this.returnStepSize(),
          },
        }}
      />
    );
  }

  render() {
    const { fetchType, lastFetchPetID } = this.props.weight;
    const { pet } = this.props;
    return (
      <>
        <FetchEntries
          fetchItems={this.handleFetchEntries}
          fetchType={fetchType}
          newPet={pet._id === lastFetchPetID}
          fetchButtons={["last7Days", "last30Days", "last3Months", "last1Year"]}
        />

        <Section>{this.renderTableSection()}</Section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchWeightEntries,
      dispatch,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(ViewEntries);
