import React from "react";
import Buttons from "components/elements/Buttons";
import ButtonIconOnly from "components/elements/ButtonIconOnly";

function LinkItem({
  displayName,
  desc,
  url,
  _id,
  toggleEditMode,
  children,
  editMode,
}) {
  return (
    <div style={{ padding: 8 }} className="box">
      <div className="level is-mobile">
        <div className="levelItem">
          <p
            style={{
              padding: 4,
              maxWidth: 220,
              borderRadius: 3,
              textShadow: "1px 1px #ffE000",
            }}
          >
            {displayName}
          </p>
          <p style={{ fontSize: 14, paddingLeft: 4 }}>{desc}</p>
        </div>
        <div className="level-right">
          <div className="levelItem">
            <Buttons className="is-centered">
              <ButtonIconOnly
                href={url}
                group="usefulLinks"
                name="gotoLink"
                className="is-rounded"
              />
              <ButtonIconOnly
                group="common"
                name="edit"
                className="is-rounded"
                onClick={() => toggleEditMode(_id)}
              />
            </Buttons>
          </div>
        </div>
      </div>
      {children}
      {editMode && <br />}
    </div>
  );
}

export default LinkItem;
