import { AUTH_LOG_OUT_USER } from "actions/types";
import {
  TASKS_FETCH,
  TASKS_ADD,
  TASKS_UPDATE,
  TASKS_MARK_COMPLETE,
  TASKS_DELETE,
} from "./actions";
import _ from "lodash";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";

const INITIAL_STATE = {
  entries: [],
  fetched: [],
};

export default function Tasks(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TASKS_FETCH:
      return {
        ...state,
        entries: _.uniqWith([...state.entries, ...action.payload], _.isEqual),
        fetched: [...state.fetched, action._pet],
      };
    case TASKS_ADD:
      return {
        ...state,
        entries: _.sortBy([...state.entries, action.payload], "taskDueDate"),
      };
    case TASKS_UPDATE:
    case TASKS_MARK_COMPLETE:
      return {
        ...state,
        entries: _.sortBy(
          [
            ...state.entries.filter(
              (entry) => entry._id !== action.payload._id
            ),
            action.payload,
          ],
          "taskDueDate"
        ),
      };
    case TASKS_DELETE:
      return {
        ...state,
        entries: state.entries.filter((entry) => entry._id !== action.payload),
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
