import React from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";
import * as actions from "./actions";

function Activity(props) {
  return <Menu {...config} {...props} />;
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.activity,
      categories:
        state.intl.messages.components.forms.selectOptions.activityCategories,
      activities:
        state.intl.messages.components.forms.selectOptions.activityActivities,
    },
    activity: state.reducers.activity,
  };
};

export default connect(mapStateToProps, actions)(Activity);
