import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Message from "components/message";

const BackEditDeleteButtons = ({
  backButtonFunc,
  handleBackClick,
  handleEditClick,
  handleDeleteClick,
  disableBackButton,
  disableEditButton,
  disableDeleteButton,
  locale,
  buttons,
  history,
  user,
  name,
  viewMode,
  providerEdit,
  providerView,
}) => {
  const arr = [user.trialPeriod, user.subscription, user.subscriptionEnding];
  const exceptions = ["vaccinations", "parasites"];

  if (
    (viewMode && !providerView) ||
    (viewMode && providerEdit) ||
    backButtonFunc
  ) {
    return (
      <div>
        <div className="buttons is-centered">
          {disableBackButton || (providerView && !backButtonFunc) ? null : (
            <div
              className="button"
              onClick={
                backButtonFunc
                  ? () => handleBackClick()
                  : () => history.goBack()
              }
            >
              <span className="icon">
                <i className={buttons.back.icon} />
              </span>
              <span>{buttons.back[locale]}</span>
            </div>
          )}
          {disableEditButton ? null : (
            <div
              className="button"
              onClick={
                !arr.includes(true) &&
                !exceptions.includes(name) &&
                !providerView
                  ? null
                  : () => handleEditClick()
              }
              disabled={
                !arr.includes(true) &&
                !exceptions.includes(name) &&
                !providerView
              }
            >
              <span className="icon">
                <i className={buttons.edit.icon} />
              </span>
              <span>{buttons.edit[locale]}</span>
            </div>
          )}
          {disableDeleteButton ? null : (
            <div className="button" onClick={() => handleDeleteClick()}>
              <span className="icon">
                <i className={buttons.delete.icon} />
              </span>
              <span>{buttons.delete[locale]}</span>
            </div>
          )}
        </div>
        {!arr.includes(true) && !exceptions.includes(name) && !providerView && (
          <Message warning message="noSubNoTrialNoEdit" />
        )}
        <br />
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    buttons: state.intl.messages.components.buttons.common,
    user: state.reducers.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(BackEditDeleteButtons));
