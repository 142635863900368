import React from "react";
import { connect } from "react-redux";
import AuthorizeCard from "./Authorize_Card";
import SetupSub from "./Setup_Sub";
import UpdateCard from "./Update_Card";
import AddPaymentMethod from "./Add_Payment_Method";
import Message from "components/message";

function StripeRoot(props) {
  // DECONSTRUCT PROPS
  //
  //
  const { user } = props;

  // FUNCTIONS
  //
  //
  function renderStripe() {
    switch (true) {
      case user.stripeRequiresPaymentMethod:
        return <AddPaymentMethod {...props} />;
      case !user.subscription && !user.stripeRequiresAuth:
        return <SetupSub {...props} />;
      case user.stripeRequiresAuth:
      case user.stripeRequiresAction && user.stripeActionType === "SCA":
        return <AuthorizeCard {...props} />;
      case user.subscription &&
        user.stripeCustomerId &&
        user.stripeSubscriptionId:
        return <UpdateCard {...props} />;
      case user.subscription && !user.stripeSubscriptionId:
        return <Message info message={"userHasFreeSub"} />;
      default:
        return <div />;
    }
  }

  // MAIN RENDER
  //
  //
  return <div>{renderStripe()}</div>;
}

const mapStateToProps = (state) => {
  return {
    user: state.reducers.auth.user,
    messages: state.intl.messages.containers.subscription.stripe,
    locale: state.intl.locale,
    pets: state.reducers.pets.numActivePets,
  };
};

export default connect(mapStateToProps)(StripeRoot);
