import React from "react";
import Bounds from "components/bounds";
import { year } from "functions/moment";
import config from "./config";
import { connect } from "react-redux";
import FooterLinks from "./Footer_Links";

const Footer = (props) => {
  return (
    <Bounds
      maxWidth="600"
      render={() => null}
      else={() => (
        <WebBrowserFooter
          {...config.footer}
          appData={props.appData}
          locale={props.locale}
        />
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    appData: state.reducers.appData,
  };
};

export default connect(mapStateToProps)(Footer);

const WebBrowserFooter = (props) => {
  return (
    <div style={props.web.style}>
      <hr />
      <div className="columns is-mobile is-centered is-vcentered">
        <div className="column">
          <FooterLinks {...props} links={props.web.left} />
        </div>
        <div className="column has-text-centered">
          <p className="heading has-text-weight-bold has-text-dark">
            {props.appData.appName} &copy; {props.appData.copyRightOwner} {year}
          </p>
        </div>
        <div className="column has-text-right">
          <FooterLinks {...props} links={props.web.right} />
        </div>
      </div>
    </div>
  );
};
