import React from "react"
import { connect } from "react-redux"
import * as actions from "./actions"
import Menu from "components/menu"
import PROVIDER_CONFIG from "./provider_config"

function StressLevel(props) {
  return <Menu {...PROVIDER_CONFIG} {...props} />
}

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.containers.features.stressLevel,
    stressLevel: state.reducers.stressLevel,
    providerContact: { _id: state.reducers.pets.selectedPet._user },
  }
}

export default connect(mapStateToProps, actions)(StressLevel)
