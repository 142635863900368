import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { toothMarker as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import FormButtons from "components/form/FormButtons";
import { INPUT_FIELDS } from "./edit_fields";
import { FormTitle } from "components/form";
import assets from "../assets";
import { TOOTH_MAPS } from "constants/tooth_maps";

function EditToothMarkerDetailForm(props) {
  const {
    errors,
    dirty,
    isSubmitting,
    editMode,
    cancel,
    resetForm,
    values,
    valuesToEdit,
  } = props;

  function handleCancel() {
    if (editMode) {
      resetForm();
      cancel();
    } else {
      cancel();
    }
  }

  const renderFields = () => {
    return INPUT_FIELDS.map((field, i) => {
      return (
        <div key={i}>
          <Field values={values} editMode={editMode} {...field} />
          {field.lastInGroup && <br />}
        </div>
      );
    });
  };

  return (
    <Form>
      <FormTitle group={"toothMarkerDetail"} />
      <div className="media">
        <div className="media-left">
          <figure className="image is-48x48">
            <img
              src={
                assets[valuesToEdit.petType].individuals[valuesToEdit.toothId]
              }
              alt={`individual ${values.toothId}`}
            />
          </figure>
        </div>
        <div className="media-content">
          {TOOTH_MAPS[valuesToEdit.petType][valuesToEdit.toothId].num}
        </div>
      </div>
      <br />
      {renderFields()}
      <FormButtons
        editMode={editMode}
        isSubmitting={isSubmitting}
        errors={errors}
        dirty={dirty}
        cancel={handleCancel}
        useCancel
      />
      <br />
    </Form>
  );
}

const EnhancedToothMarkerDetailForm = withFormik({
  mapPropsToValues({ valuesToEdit }) {
    return {
      date: moment(valuesToEdit.date).format("YYYY-MM-DD"),
      description: valuesToEdit.description,
      attachmentURL: valuesToEdit.attachmentURL,
      gingivalPockets: valuesToEdit.gingivalPockets || 0,
      gumRecession: valuesToEdit.gumRecession || 0,
      _id: valuesToEdit._id,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    description: Yup.string().max(1000, "max-1000"),
    attachmentURL: Yup.string().url("url"),
    gingivalPockets: Yup.number(),
    gumRecession: Yup.number(),
  }),
  handleSubmit(values, { props }) {
    return props.updateMarkerDetail(values, props.providerView);
  },
})(EditToothMarkerDetailForm);

export default withRouter(
  connect(null, actions)(EnhancedToothMarkerDetailForm)
);
