import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import _ from "lodash";
import * as actions from "../actions";
import * as Yup from "yup";
import { INPUT_FIELDS } from "./invite_contact_form_fields";
import { FormTitle } from "components/form";

class InviteContactForm extends Component {
  renderFields = () => {
    return INPUT_FIELDS.map((field, i) => {
      return <Field key={i} {...field} values={this.props.values} />;
    });
  };

  render() {
    const { errors, dirty, isSubmitting, cancel, buttons, locale } = this.props;
    return (
      <div>
        <Form>
          <FormTitle group={"inviteContact"} />
          <br />
          {this.renderFields()}
          <br />
          <div className="buttons is-centered">
            <button className="button" type="button" onClick={cancel}>
              <span>
                <i className={buttons.cancel.icon} /> {buttons.cancel[locale]}
              </span>
            </button>
            <button
              disabled={isSubmitting}
              className={`button ${
                !_.isEmpty(errors)
                  ? "is-danger"
                  : _.isEmpty(errors) && dirty
                  ? "is-success"
                  : "is-info"
              } ${isSubmitting && "is-loading"}`}
              type="submit"
            >
              <span>
                <i className={buttons.sendInvite.icon} />{" "}
                {buttons.sendInvite[locale]}
              </span>
            </button>
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    buttons: {
      ...state.intl.messages.components.buttons.auth,
      ...state.intl.messages.components.buttons.common,
    },
  };
};

InviteContactForm = withFormik({
  mapPropsToValues({ receiverEmail }) {
    return {
      receiverEmail,
      codeForReceiver: "",
      messageForReceiver: "",
    };
  },
  validationSchema: Yup.object().shape({
    receiverEmail: Yup.string().email("invalid").required("required"),
    codeForReceiver: Yup.string().max(20, "max-20"),
    messageForReceiver: Yup.string().max(250, "max-250"),
  }),
  handleSubmit(values, { props, setSubmitting, resetForm }) {
    return props.sendInviteToContact(
      values,
      setSubmitting,
      resetForm,
      props.callback
    );
  },
})(InviteContactForm);

export default connect(mapStateToProps, actions)(InviteContactForm);
