import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchVacs } from "actions/vacs";
import Section from "components/layout/Section";
import Message from "components/message";
import EntryCardWithStatus from "components/cards/Entry_Card_With_Status";

class ViewEntries extends Component {
  UNSAFE_componentWillMount() {
    const { pet, vacs, providerView } = this.props;
    if (!vacs.fetched.includes(pet._id)) {
      this.props.fetchVacs(pet, providerView);
    }
  }

  renderSectionContent() {
    const { vacs, pet, defaultRoute } = this.props;
    const { entries } = vacs;

    const entriesFiltered = entries.filter((entry) => entry._pet === pet._id);

    if (entriesFiltered.length === 0) {
      return <Message warning message={"noEntriesToDisplayAutoFetch"} />;
    }

    return (
      <div className="columns is-2 is-variable is-mobile is-multiline is-centered">
        {entriesFiltered.map((entry, i) => (
          <div
            key={i}
            className="column is-full-mobile is-3-desktop is-4-tablet"
          >
            <EntryCardWithStatus
              vacEntry
              to={`${defaultRoute}/view_entry/${entry._id}`}
              key={i}
              {...entry}
            />
          </div>
        ))}
      </div>
    );
  }

  render() {
    return <Section>{this.renderSectionContent()}</Section>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchVacs,
      dispatch,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(ViewEntries);
