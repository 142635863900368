import React from 'react';
import { TOOTH_MAPS } from "constants/tooth_maps";
import { shortDateForDisplay } from "functions";
import assets from "../assets";
import ExternalLink from "components/elements/External_Link";

export default function ToothDetailFull(props) {
  const { petType, toothId, toothState, labels, states, locale } = props;
  return (
    <>
      <div
        className="box columns is-mobile title is-6 is-uppercase"
        style={{ marginBottom: 18 }}
      >
        <div className="column is-2">
          <figure className="image is-32x32">
            <img
              src={assets[petType].individuals[toothId]}
              alt="individual tooth"
            />
            <div
              className="is-size-7 has-text-centered"
              style={{ paddingTop: 4 }}
            >
              {TOOTH_MAPS[petType][toothId].num}
            </div>
          </figure>
        </div>
        <div className="column has-text-centered">
          <div className="is-size-7-mobile">
            {labels[TOOTH_MAPS[petType][toothId].loc][locale]}
          </div>
        </div>
        <div className="column has-text-centered">
          <div className="is-size-7-mobile">
            {labels[TOOTH_MAPS[petType][toothId].type][locale]}
          </div>
        </div>
      </div>
      <DisplayField
        label={labels.toothState[locale]}
        value={states[toothState][locale]}
      />
      <DisplayField
        label={labels.date[locale]}
        value={shortDateForDisplay(props.date)}
      />
      <DisplayField label={labels.desc[locale]} value={props.description} />
      <DisplayField
        url
        label={labels.attachmentURL[locale]}
        value={props.attachmentURL}
      />
      <DisplayField
        label={labels.gingivalPockets[locale]}
        value={props.gingivalPockets}
      />
      <DisplayField
        label={labels.gumRecession[locale]}
        value={props.gumRecession}
      />
    </>
  );
}

const DisplayField = (props) => (
  <div className="box columns is-mobile" style={{ padding: 8 }}>
    <div className="column is-5 has-text-weight-medium">{props.label}:</div>
    <div className="column">
      {props.url ? <ExternalLink href={props.value} /> : props.value}
    </div>
  </div>
);
