import React from "react";
import { connect } from "react-redux";

const PAGES = [
  { page: 1 },
  { page: 2 },
  { page: 3 },
  { page: 4 },
  { page: 5 },
  { page: 6 },
  { page: 7 },
];

const Pagination = (props) => {
  const { locale } = props;
  const disablePrev = props.page === 1;
  const disableNext = props.page === props.pages;
  return (
    <nav
      style={{ paddingBottom: 6 }}
      className="pagination is-small is-rounded"
      role="navigation"
      aria-label="pagination"
      pages={props.pages}
    >
      <div
        className="pagination-previous"
        onClick={disablePrev ? null : () => props.prevPage()}
        disabled={disablePrev}
      >
        {props.buttons.prev[locale]}
      </div>
      <div
        className="pagination-next"
        onClick={disableNext ? null : () => props.nextPage()}
        disabled={disableNext}
      >
        {props.buttons.next[locale]}
      </div>
      {props.pages > 7 ? (
        <GotoNumbersMoreThanSeven
          disableNext={disableNext}
          disablePrev={disablePrev}
          {...props}
        />
      ) : (
        <ul className="pagination-list">
          {PAGES.map(({ page }, i) => {
            if (i + 1 > props.pages) return null;
            return (
              <li key={i}>
                <div
                  className={`pagination-link ${
                    props.page === page && "is-current"
                  }`}
                  aria-label={`Page ${page}`}
                  aria-current="page"
                  onClick={
                    page === props.page ? null : () => props.gotoPage(page)
                  }
                >
                  {page}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </nav>
  );
};

const GotoNumbersMoreThanSeven = (props) => {
  return (
    <ul className="pagination-list">
      {props.page !== 1 && (
        <li>
          <div
            className="pagination-link"
            aria-label={"Goto page 1"}
            disabled={props.disablePrev}
            onClick={props.disablePrev ? null : () => props.gotoPage(1)}
          >
            1
          </div>
        </li>
      )}
      {props.page !== 1 && props.page - 1 !== 1 && (
        <>
          {props.page - 1 !== 2 && (
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          )}
          <li>
            <div
              className="pagination-link"
              aria-label={`Page ${props.page - 1}`}
              aria-current="page"
              onClick={() => props.gotoPage(props.page - 1)}
            >
              {props.page - 1}
            </div>
          </li>
        </>
      )}
      <li>
        <div
          className="pagination-link is-current"
          aria-label={`Page ${props.page}`}
          aria-current="page"
          onClick={() => props.gotoPage(props.page)}
        >
          {props.page}
        </div>
      </li>
      {props.page !== props.pages && props.page + 1 !== props.pages && (
        <>
          <li>
            <div
              className="pagination-link"
              aria-label={`Page ${props.page + 1}`}
              aria-current="page"
              onClick={() => props.gotoPage(props.page + 1)}
            >
              {props.page + 1}
            </div>
          </li>
          {props.page + 1 !== props.pages - 1 && (
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          )}
        </>
      )}
      {props.page !== props.pages && (
        <li>
          <div
            className="pagination-link"
            aria-label={`Goto page ${props.pages}`}
            disabled={props.disableNext}
            onClick={
              props.disableNext ? null : () => props.gotoPage(props.pages)
            }
          >
            {props.pages}
          </div>
        </li>
      )}
    </ul>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    buttons: state.intl.messages.components.buttons.common,
  };
};

export default connect(mapStateToProps)(Pagination);
