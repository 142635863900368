import main from "./home_care.svg"
import newEntry from "./home_care_new_entry.svg"
import viewEntries from "./home_care.svg"

const homeCare = {
  main,
  newEntry,
  viewEntries,
}

export default homeCare
