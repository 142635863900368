import React from "react";
import Intl from "components/Intl";

function ChartTitleBox(props) {
  return (
    <div className="box" style={{ marginBottom: 6, padding: 8 }}>
      <div className={"is-6 has-text-grey has-text-weight-bold"}>
        <Intl message path={props.path} />
      </div>
    </div>
  );
}

export default ChartTitleBox;
