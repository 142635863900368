import axios from "axios";
import moment from "moment";
import _ from "lodash";
import {
  FOOD_DIARY_FETCH,
  FOOD_DIARY_FETCH_TYPE,
  FOOD_DIARY_ADD,
  FOOD_DIARY_UPDATE,
  FOOD_DIARY_DELETE,
  ACTION_STARTED,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
  MODALS_HIDE,
  TABLE_CLEAR_CURRENT_STATE,
  FOOD_DIARY_ADD_FOR_LITTER,
} from "./types";
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "./functions";

const API = (route) => `/api/food_diary/${route}`;

// Action Creators
export const fetchFdes =
  (value, unit, name, pet, providerView) => async (dispatch) => {
    const dates = !_.isEmpty(value)
      ? { ...value }
      : {
          from: moment().subtract(value, unit).toISOString(),
          to: moment().toISOString(),
        };
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({
        type: FOOD_DIARY_FETCH_TYPE,
        payload: { dates, type: !_.isEmpty(value) ? "range" : name },
      });
      dispatch({ type: ACTION_STARTED, payload: FOOD_DIARY_FETCH });
      const res = await axios.get(
        API(providerView ? "fetch_for_provider" : "fetch"),
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
          params: { _pet: pet._id, petType: pet.petType, ...dates },
        }
      );
      dispatch({ type: FOOD_DIARY_FETCH, payload: res.data.fdes, pet });
      endAndResetAction(dispatch);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const addFde =
  (
    values,
    setSubmitting,
    history,
    successRoute,
    providerContact,
    providerView
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: FOOD_DIARY_ADD });
      const res = await axios.post(
        API(providerView ? "add_by_provider" : "add"),
        { _user: providerView ? providerContact._id : undefined, ...values },
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      dispatch({ type: FOOD_DIARY_ADD, payload: res.data.fde });
      endAndResetAction(dispatch);
      history.push(successRoute);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const addFdeForLitter =
  (values, litterMembers, petType, setSubmitting, onSuccess) =>
  async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: FOOD_DIARY_ADD_FOR_LITTER });
      const res = await axios.post(
        "/api/food_diary/add_for_litter",
        { values, litterMembers, petType },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      );
      dispatch({ type: FOOD_DIARY_ADD_FOR_LITTER, payload: res.data.result });
      dispatch({ type: ACTION_ENDED });
      dispatch({ type: ACTION_RESET });
      onSuccess();
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const updateFde =
  (values, setSubmitting, history, successRoute, providerView) =>
  async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: FOOD_DIARY_UPDATE });
      const res = await axios.put(
        API(providerView ? "update_by_provider" : "update"),
        values,
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      dispatch({
        type: ACTION_SUCCESS,
        payload: "updateSuccess",
      });
      dispatch({ type: FOOD_DIARY_UPDATE, payload: res.data.fde });
      endAndResetActionWithDelay(dispatch, 1500);
      history.push(successRoute);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const deleteFde =
  (_id, petType, history, successRoute, providerView) => async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: FOOD_DIARY_DELETE });
      dispatch({ type: MODALS_HIDE });
      const res = await axios.put(
        API(providerView ? "delete_by_provider" : "delete"),
        { _id, petType },
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      dispatch({
        type: ACTION_SUCCESS,
        payload: "deleteSuccess",
      });
      dispatch({ type: FOOD_DIARY_DELETE, payload: res.data.fde });
      endAndResetActionWithDelay(dispatch, 1500);
      history.push(successRoute);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

const foodDiaryActions = {
  fetchFdes,
  addFde,
  updateFde,
  deleteFde,
  addFdeForLitter,
};

export default foodDiaryActions;
