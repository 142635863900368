import { pets, features } from "assets"
import Landing from "components/features/Landing"
import AddLitter from "./Add_Litter_View"

const littersConfig = {
  name: "litters",
  type: "feature",
  defaultRoute: "/userhome/dashboard/litters",
  menuIcon: { img: features.litters.addNew, alt: "litters_add_new_icon" },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "addLitter",
      menuItem: true,
      image: {
        img: pets.addPet,
        alt: "litters_add_litter_icon",
      },
      icon: "far fa-plus-square",
      component: AddLitter,
      path: "/add_litter",
    },
  ],
}

export default littersConfig
