import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class Parasites extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.parasites,
      ...state.intl.messages.containers.litterMemberFeatures
        .litterMemberParasites,
      landing: {
        ...state.intl.messages.containers.litterMemberFeatures
          .litterMemberParasites.landing,
        title: state.intl.messages.containers.features.parasites.landing.title,
        subtitle:
          state.intl.messages.containers.features.parasites.landing.subtitle,
        menuLink:
          state.intl.messages.containers.features.parasites.landing.menuLink,
      },
    },
    parasites: state.reducers.parasites,
  };
};

export default connect(mapStateToProps)(Parasites);
