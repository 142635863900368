import React from "react"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"
import Section from "components/layout/Section"
import FormWrapper from "components/layout/Form_Wrapper"
import ActivityEntryForm from "../forms/Activity_Entry_Form"

function NewEntryDailyActivity(props) {
  return (
    <Section>
      <FormWrapper>
        <ActivityEntryForm valuesToEdit={{}} {...props} />
      </FormWrapper>
    </Section>
  )
}

export default RequireSubFeature(NewEntryDailyActivity)
