import React, { useState } from "react";
import { connect } from "react-redux";
import { billing as actions } from "actions";
import {
  useStripe,
  useElements,
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { createOptions } from "./Card_Element";
import SummaryDetails from "../User_Account/Subscription/Summary_Details";
import StripeElementWrapper from "components/styled/Stripe_Element_Wrapper";
import PoweredByStripeLogo from "components/elements/Powered_By_Stripe";
import Intl from "components/Intl";

function SetupSub({
  user,
  messages,
  locale,
  createFirstTimeSubscription,
  createSubscriptionExistingCustomer,
  createCustomerAndSubscription,
}) {
  // STRIPE HOOK
  const stripe = useStripe();
  const elements = useElements();

  // USE STATE
  //
  //
  const [validation, setValidation] = useState({ error: true, message: "" });
  const [cardInputsCompleted, setCardInputComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCVC: false,
  });

  // FUNCTIONS
  //
  //
  function handleChange({ error, complete }, inputName) {
    if (!error) {
      setValidation({ error: false, message: "" });
    }
    if (error) {
      setCardInputComplete({ ...cardInputsCompleted, [inputName]: false });
      setValidation({ error: true, message: error.message });
    }
    if (!complete) {
      setCardInputComplete({ ...cardInputsCompleted, [inputName]: false });
    }
    if (complete) {
      setCardInputComplete({ ...cardInputsCompleted, [inputName]: true });
    }
  }
  
  async function submit(ev) {
    ev.preventDefault(); // Prevent default form submission behavior
  
    try {
      // Retrieve each card element separately
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);
  
      // Check if card elements are present
      if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
        console.error("Card elements are not initialized.");
        return; // Exit early if elements are not found
      }
  
      // Create payment method
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement, // Pass the card number element directly
        billing_details: {
          email: user.email,
        },
      });
  
      // Handle error in creating payment method
      if (error) {
        console.error("Payment Method Error:", error);
        return; // Exit if there's an error
      }
  
      // Handle customer and subscription creation
      if (!user.stripeCustomerId) {
        // Create a new customer and subscription
        await createCustomerAndSubscription(paymentMethod);
      } else {
        // Create a subscription for existing customer
        await createSubscriptionExistingCustomer(paymentMethod);
      }
    } catch (error) {
      console.error("Submit Error:", error);
    }
  }
  

  // MAIN RENDER
  //
  //
  return (
    <>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <div className="checkout notification" style={{ padding: 8 }}>
            <div className="columns is-2 is-variable is-flex-desktop is-mobile is-multiline">
              <div className="column is-6-desktop is-12-mobile is-flex-desktop">
                <div className="box" style={{ width: "100%" }}>
                  <div className="level is-mobile is-marginless">
                    <div className="level-left">
                      <p className="title is-5 has-text-dark">
                        {messages.labels.enterCardDetails[locale]}
                      </p>
                    </div>
                    <div className="level-right">
                      <PoweredByStripeLogo />
                    </div>
                  </div>
                  <label className="label is-small">
                    {messages.labels.cardNumber[locale]}
                    <StripeElementWrapper>
                      <CardNumberElement
                        onChange={(e) => handleChange(e, "cardNumber")}
                        options={createOptions()}
                      />
                    </StripeElementWrapper>
                  </label>
                  <label className="label is-small">
                    {messages.labels.cardExpiry[locale]}
                    <StripeElementWrapper>
                      <CardExpiryElement
                        onChange={(e) => handleChange(e, "cardExpiry")}
                        options={createOptions()}
                      />
                    </StripeElementWrapper>
                  </label>
                  <label className="label is-small">
                    {messages.labels.cardCVC[locale]}
                    <StripeElementWrapper>
                      <CardCvcElement
                        onChange={(e) => handleChange(e, "cardCVC")}
                        options={createOptions()}
                      />
                    </StripeElementWrapper>
                  </label>
                  {validation.error && (
                    <p className="is-danger help">{validation.message}</p>
                  )}
                </div>
              </div>
              <div className="column is-6-desktop is-12-mobile is-flex-desktop">
                <div className="box" style={{ width: "100%" }}>
                  <div className="buttons">
                    <button
                      className="button is-link is-fullwidth"
                      onClick={submit}
                      disabled={
                        validation.error ||
                        Object.keys(cardInputsCompleted).some(
                          (k) => !cardInputsCompleted[k]
                        )
                      }
                    >
                      <Intl
                        message
                        path={"components.buttons.stripe.setupSubscription"}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ElementsConsumer>
      <SummaryDetails />
    </>
  );
}

export default connect(null, actions)(SetupSub);
