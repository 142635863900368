import React from "react"
import HeatDiaryEntryForm from "forms/Heat_Diary_Prog_Entry_Form"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"
import FormWrapper from "components/layout/Form_Wrapper"
import Section from "components/layout/Section"

function NewEntryProg(props) {
  return (
    <Section>
      <FormWrapper>
        <HeatDiaryEntryForm valuesToEdit={{}} {...props} />
      </FormWrapper>
    </Section>
  )
}

export default RequireSubFeature(NewEntryProg)
