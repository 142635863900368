import { main } from "assets";
import Landing from "components/features/Landing";
import Help from "./Help";
import Feedback from "./Feedback";
import Support from "./Support";

const feedbackSupportConfig = {
  name: "feedbackSupport",
  type: "feedbackSupport",
  defaultRoute: "/userhome/feedback_and_support",
  menuIcon: { img: main.feedbackAndSupport, alt: "feedback_and_support_icon" },
  nav: [
    {
      name: "landing",
      menuItem: true,
      image: {
        img: main.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      name: "help",
      menuItem: true,
      image: {
        img: main.help,
        alt: "help_icon",
      },
      icon: "fas fa-help",
      component: Help,
      path: "/help",
    },
    {
      name: "feedback",
      menuItem: true,
      image: {
        img: main.feedback,
        alt: "feedback_and_support_feedback_icon",
      },
      icon: "fas fa-comments",
      component: Feedback,
      path: "/feedback",
    },
    {
      name: "support",
      menuItem: true,
      image: {
        img: main.support,
        alt: "feedback_and_support_support_icon",
      },
      icon: "fas fa-life-ring",
      component: Support,
      path: "/support",
    },
  ],
};

export default feedbackSupportConfig;
