import React, { Component } from "react"
import { connect } from "react-redux"

export default function RequireSubAddPet(ComposedComponent) {
  class Authentication extends Component {
    UNSAFE_componentWillMount() {
      const { user, history } = this.props
      const arr = [user.trialPeriod, user.subscription]
      if (!arr.includes(true)) {
        history.push("/userhome")
      }
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }

  const mapStateToProps = (state) => {
    return {
      user: state.reducers.auth.user,
    }
  }
  return connect(mapStateToProps)(Authentication)
}
