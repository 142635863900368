import {
  SYS_MESSAGE_DISMISS,
  SYS_MESSAGE_CARD_EXPIRING_DISMISS,
  SYS_MESSAGE_CARD_REQUIRES_ACTION_DISMISS,
} from "./types";

export const dismissSystemMessage = () => async (dispatch) => {
  dispatch({ type: SYS_MESSAGE_DISMISS });
};

export const dismissCardExpiringMessage = () => async (dispatch) => {
  dispatch({ type: SYS_MESSAGE_CARD_EXPIRING_DISMISS });
};

export const dismissCardRequiresActionMessage = () => async (dispatch) => {
  dispatch({ type: SYS_MESSAGE_CARD_REQUIRES_ACTION_DISMISS });
};
