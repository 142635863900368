import React, { Component } from "react";
import { connect } from "react-redux";
import { requestResetPasswordCode } from "actions/auth";
import { requestResetPasswordCodeProvider } from "containers/auth/Pet_Services_Portal/actions";
import { bindActionCreators } from "redux";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { INPUT_FIELDS } from "forms/fields/request_password_reset_code";
import { FormButtons, FormTitle } from "components/form";

class RequestResetPasswordCodeForm extends Component {
  renderFields() {
    return INPUT_FIELDS.map((field, i) => {
      return <Field key={i} {...field} />;
    });
  }

  render() {
    const { errors, dirty, isSubmitting } = this.props;
    return (
      <Form>
        <br />
        <FormTitle group={"requestResetPasswordCode"} />
        {this.renderFields()}
        <br />
        <FormButtons
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          linkTo={"/"}
        />
        <br />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      requestResetPasswordCode,
      requestResetPasswordCodeProvider,
    },
    dispatch
  ),
});

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}
Yup.addMethod(Yup.string, "equalTo", equalTo);

RequestResetPasswordCodeForm = withFormik({
  mapPropsToValues({ locale }) {
    return {
      email: "",
      locale,
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email("invalid").required("required"),
  }),
  handleSubmit(values, { props, setSubmitting, resetForm }) {
    if (props.user) {
      return props.requestResetPasswordCode(
        values,
        setSubmitting,
        props.handleRequestResetPasswordCodeSentSuccess
      );
    }
    if (props.provider) {
      return props.requestResetPasswordCodeProvider(
        values,
        setSubmitting,
        props.handleRequestResetPasswordCodeSentSuccess
      );
    }
  },
})(RequestResetPasswordCodeForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestResetPasswordCodeForm);
