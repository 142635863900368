import React, { Component } from "react"
import { connect } from "react-redux"
import Menu from "components/menu"
import config from "./config"

class LitterExpenses extends Component {
  render() {
    return <Menu {...config} {...this.props} />
  }
}

const mapStateToProps = state => {
  return {
    messages: state.intl.messages.containers.litterFeatures.litterExpenses,
    _litter: state.reducers.litters.selectedLitter._id
  }
}

export default connect(mapStateToProps)(LitterExpenses)
