import React from "react"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"
import Section from "components/layout/Section"
import FormWrapper from "components/layout/Form_Wrapper"
import NewTaskForm from "../forms/New_Task_Form"

function NewEntryView(props) {
  return (
    <Section>
      <FormWrapper>
        <NewTaskForm valuesToEdit={{}} {...props} />
      </FormWrapper>
    </Section>
  )
}

export default RequireSubFeature(NewEntryView)
