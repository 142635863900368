import React from 'react';
import Intl from "components/Intl";

function HelpLabel(props) {
  return (
    <div className="label has-text-weight-medium">
      <Intl message {...props} />
    </div>
  );
}

export default HelpLabel;
