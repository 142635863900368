import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { weight as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import moment from "moment";
import { INPUT_FIELDS } from "./fields/weight";
import FormButtons from "components/form/FormButtons";
import { FormTitle } from "components/form";

class WeightForm extends Component {
  renderFields() {
    const { viewMode, editMode, values, pet, userPreferredUnit } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (field.petTypes && !field.petTypes.includes(pet.petType)) return null;
      if (field.renderCondition && !field.renderCondition(userPreferredUnit))
        return null;
      return (
        <Field
          key={i}
          petType={pet.petType}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          weightUnit={userPreferredUnit}
          {...field}
        />
      );
    });
  }

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      viewMode,
      editMode,
      cancel,
      defaultRoute,
    } = this.props;
    return (
      <Form className="has-text-left">
        <FormTitle group={"weight"} />
        <br />
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={defaultRoute}
        />
        <br />
      </Form>
    );
  }
}

WeightForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet, userPreferredUnit }) {
    return {
      date: moment(valuesToEdit.date).format("YYYY-MM-DD") || "",
      kg: !_.isEmpty(valuesToEdit)
        ? valuesToEdit.kg
        : pet.petWeightKg > 0
        ? pet.petWeightKg
        : 0,
      g: !_.isEmpty(valuesToEdit)
        ? valuesToEdit.g
        : pet.petWeightG > 0
        ? pet.petWeightG
        : 0,
      lbs: !_.isEmpty(valuesToEdit)
        ? valuesToEdit.lbs
        : pet.petWeightLbs > 0
        ? pet.petWeightLbs
        : 0,
      bodyConditionScore: valuesToEdit.bodyConditionScore || 0,
      notes: valuesToEdit.notes || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
      updatePetProfile: true,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    kg: Yup.number(),
    g: Yup.number(),
    lbs: Yup.number(),
    bodyConditionScore: Yup.number(),
    notes: Yup.string().max(250, "max-250"),
    updatePetProfile: Yup.boolean(),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries`;
    if (props.editMode) {
      return props.updateWeightEntry(
        values,
        setSubmitting,
        props.history,
        successRoute,
        props.providerView
      );
    }
    props.addWeightEntry(
      { ...values, weightUnit: props.userPreferredUnit },
      setSubmitting,
      props.history,
      successRoute,
      props.providerContact,
      props.providerView
    );
  },
})(WeightForm);

export default withRouter(connect(null, actions)(WeightForm));
