import main from "./award_cabinet.svg"
import newEntry from "./award_cabinet_new_entry.svg"
import viewEntries from "./award_cabinet_view_entries.svg"
import awardTypes from "./award_types"

const awardCabinet = {
  main,
  newEntry,
  viewEntries,
  awardTypes,
}

export default awardCabinet
