import React from "react";
import { connect } from "react-redux";
import AssociateLabel from "./Associate_Label";
import AssociateLogo from "./Associate_Logo";
import Facebook from "components/facebook";

function AssociatePartnerPageView(props) {
  return (
    <div className="columns is-centered has-text-centered">
      <div className="column is-4-desktop">
        <AssociateLabel messages={props.messages} />
        <div className="content">
          <AssociateLogo
            logo={props.associateLogo}
            url={props.associateSiteUrl}
          />
        </div>
      </div>
      {props.associatePublicFacebookPage && (
        <div className="column is-4-desktop">
          <Facebook
            type="page"
            page={props.associatePublicFacebookPage}
            style={{}}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    messages: {
      locale: state.intl.locale,
      ...state.intl.messages.containers.main.partners,
    },
  };
};

export default connect(mapStateToProps)(AssociatePartnerPageView);
