import React, { useEffect, useState } from "react";
import Section from "components/layout/Section";
import Message from "components/message";
import AppointmentGroup from "../components/AppointmentGroup";
import _ from "lodash";
import { diffInSecondsReturnNumber, dateIsInFuture } from "functions";

function ViewEntriesView(props) {
  const {
    appointments: { fetched, entries, lastFetch },
    pet,
    fetchAppointmentsEntries,
    providerView,
    provider,
    messages,
    locale,
  } = props;

  useEffect(() => {
    function fetchData() {
      if (
        !fetched.includes(pet._id) ||
        diffInSecondsReturnNumber(lastFetch) > 60
      ) {
        fetchAppointmentsEntries(pet._id, providerView);
      }

      // Filter Appointments made by a Provider
      let filteredForAppointmentsByProvider = entries.filter(
        (e) => e._pet === pet._id && e._provider
      );

      // Filter Provider Appoointments that are confirmed and valid
      let confirmedProviderAppointments =
        filteredForAppointmentsByProvider.filter(
          (e) =>
            dateIsInFuture(e.date) && e.confirmationState.status === "confirmed"
        );

      // Filter all User added Appointments that are valid and add them to Provider Confirmed/Valid
      let filteredForAppointmentsByUser = entries.filter(
        (e) => e._pet === pet._id && !e._provider
      );

      let validUserAddedAppointments = filteredForAppointmentsByUser.filter(
        (e) => dateIsInFuture(e.date)
      );

      setConfirmedUpcoming(
        _.sortBy(
          [...confirmedProviderAppointments, ...validUserAddedAppointments],
          "date"
        )
      );

      // Filter Provider Appointments that are unconfirmed or where that state object doesnt exist
      let unconfirmedProviderAppointments =
        filteredForAppointmentsByProvider.filter(
          (e) =>
            (!e.confirmationState ||
              e.confirmationState.status === "unconfirmed") &&
            dateIsInFuture(e.date)
        );
      setUnconfirmed(_.sortBy(unconfirmedProviderAppointments, "date"));

      // Finally filter all User and Provider Appointments that are expired, canceled or declined
      // and add any other Appointment
      let expiredOtherAppointments = entries.filter(
        (e) =>
          e._pet === pet._id &&
          (!dateIsInFuture(e.date) ||
            e.confirmationState.status === "canceled" ||
            e.confirmationState.status === "declined")
      );
      setExpiredOther(_.sortBy(expiredOtherAppointments, "date"));
    }
    fetchData();
  }, [
    entries,
    fetchAppointmentsEntries,
    fetched,
    pet._id,
    providerView,
    lastFetch,
  ]);

  const [confirmedUpcoming, setConfirmedUpcoming] = useState([]);
  const [unconfirmed, setUnconfirmed] = useState([]);
  const [expiredOther, setExpiredOther] = useState([]);

  if (
    confirmedUpcoming.length === 0 &&
    unconfirmed.length === 0 &&
    expiredOther.length === 0
  ) {
    return (
      <Section>
        <Message warning message={"noEntriesToDisplayAutoFetch"} />
      </Section>
    );
  }

  return (
    <Section>
      {confirmedUpcoming.length > 0 && (
        <AppointmentGroup
          providerEntry
          confirmed
          expand
          title={messages.titles.upcoming[locale]}
          items={confirmedUpcoming}
          providerId={provider ? provider._id : undefined}
          logo={{
            path: "features",
            featureName: "appointments",
            name: "confirmed",
          }}
          {...props}
        />
      )}
      {unconfirmed.length > 0 && (
        <AppointmentGroup
          providerEntry
          unconfirmed
          expand
          title={messages.titles.unconfirmed[locale]}
          items={unconfirmed}
          providerId={provider ? provider._id : undefined}
          logo={{
            path: "features",
            featureName: "appointments",
            name: "unconfirmed",
          }}
          {...props}
        />
      )}
      {expiredOther.length > 0 && (
        <AppointmentGroup
          expand
          title={messages.titles.expired[locale]}
          items={expiredOther}
          providerId={provider ? provider._id : undefined}
          logo={{
            path: "features",
            featureName: "appointments",
            name: "expired",
          }}
          {...props}
        />
      )}
    </Section>
  );
}

export default ViewEntriesView;
