import React from "react"
import Section from "components/layout/Section"

export default function Loading() {
  return (
    <Section>
      <div className="has-text-centered has-text-dark">
        <i className="fa fa-spinner fa-pulse fa-2x" />
      </div>
    </Section>
  )
}
