import {
  TRANSFERS_FETCH_TRANSFERS,
  TRANSFERS_TRANSFER_LITTER_MEMBER,
  TRANSFERS_RETRIEVE_LITTER_MEMBER,
  TRANSFERS_UPDATE_PET_AND_TRANSFER,
  TRANSFERS_CANCEL_UPDATE_PET_AND_TRANSFER,
  AUTH_LOG_OUT_USER,
} from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";

const INITIAL_STATE = {
  transfers: [],
  fetched: [],
  petForTransfer: {},
  petTransferObject: {},
};

export default function TransferPetReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TRANSFERS_FETCH_TRANSFERS:
      return {
        ...state,
        transfers: action.payload.array,
      };
    case TRANSFERS_TRANSFER_LITTER_MEMBER:
      return {
        ...state,
        transfers: [action.payload],
      };
    case TRANSFERS_RETRIEVE_LITTER_MEMBER:
      return {
        ...state,
        petForTransfer: action.payload.pet,
        petTransferObject: action.payload.petTransfer,
      };
    case TRANSFERS_UPDATE_PET_AND_TRANSFER:
    case TRANSFERS_CANCEL_UPDATE_PET_AND_TRANSFER:
      return {
        ...state,
        petForTransfer: {},
        petTransferObject: {},
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
