import React, { useEffect } from "react"
import { connect } from "react-redux"
import * as actions from "actions/partners"
import _ from "lodash"
import PartnerPage from "./Partner_Page"

function PartnerPageView({ partnerId, partnerPages, fetchPages }) {
  useEffect(() => {
    fetchPages(partnerId)
  }, [fetchPages, partnerId])

  if (_.isEmpty(partnerPages)) return null

  return (
    <PartnerPage
      pageContent={
        partnerPages.items.filter(
          item => item.fields.partnerId === partnerId
        )[0]
      }
    />
  )
}

const mapStateToProps = state => {
  return {
    partnerPages: state.reducers.partners.partnerPages
  }
}

export default connect(mapStateToProps, actions)(PartnerPageView)
