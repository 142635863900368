import { main } from "assets"
import Benefits from "containers/main/Benefits"
import RequireAuth from "components/hoc/Require_Auth"
import { BENEFITS_FETCH } from "./actions"
import { LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD } from "functions/hostname"

const benefitsConfig = {
  main: {
    name: "benefits",
    path: "/userhome/benefits",
    exact: false,
    component: RequireAuth(Benefits),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    img: {
      src: main.benefits,
      alt: "benefits_main_logo",
    },
    actionTypes: [BENEFITS_FETCH],
  },
  clients: [LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD],
}

export default benefitsConfig
