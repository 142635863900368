import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { awardCabinet as actions } from "actions/index";
import { connect } from "react-redux";
import moment from "moment";
import * as Yup from "yup";
import AwardPreview from "containers/features/Award_Cabinet/components/Award_Preview";
import { INPUT_FIELDS } from "./fields/award";
import FormButtons from "components/form/FormButtons";
import { FormTitle } from "components/form";

class MetaDataForm extends Component {
  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          values={values}
          viewMode={viewMode}
          editMode={editMode}
          {...field}
        />
      );
    });
  }

  render() {
    const {
      awardData,
      type,
      errors,
      editMode,
      viewMode,
      dirty,
      isSubmitting,
      cancel,
    } = this.props;
    return (
      <div className="columns is-mobile is-multiline">
        <div className="column is-4-desktop is-12-mobile">
          <AwardPreview
            awardData={awardData}
            type={editMode ? awardData.type : type}
          />
        </div>
        <div className="column is-8-desktop is-12-mobile">
          <Form>
            <FormTitle group={"awardCabinet"} />
            <article className="box is-shadowless form_box">
              {this.renderFields()}
              <br />
              <FormButtons
                viewMode={viewMode}
                editMode={editMode}
                isSubmitting={isSubmitting}
                errors={errors}
                dirty={dirty}
                cancel={cancel}
                linkTo={"/userhome/dashboard/award_cabinet"}
              />
            </article>
          </Form>
        </div>
      </div>
    );
  }
}

MetaDataForm = withFormik({
  mapPropsToValues({ awardData, pet }) {
    return {
      displayTitle: awardData.displayTitle || "",
      dateAwarded: moment(awardData.dateAwarded).format("YYYY-MM-DD") || "",
      eventName: awardData.eventName || "",
      awardedBy: awardData.awardedBy || "",
      reasonAwarded: awardData.reasonAwarded || "",
      additionalInfo: awardData.additionalInfo || "",
      attachmentURL: awardData.attachmentURL || "",
      _id: awardData._id || "",
      _pet: awardData._pet || pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    displayTitle: Yup.string().max(10, "max-10"),
    dateAwarded: Yup.date().required("required"),
    eventName: Yup.string().max(30, "max-30"),
    awardedBy: Yup.string().max(30, "max-30"),
    reasonAwarded: Yup.string().max(30, "max-30"),
    additionalInfo: Yup.string().max(250, "max-250"),
    attachmentURL: Yup.string().url("url").max(120, "max-120"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = "/userhome/dashboard/award_cabinet/view_entries";
    if (props.editMode) {
      return props.updateAward(
        values,
        setSubmitting,
        props.history,
        successRoute,
        props.pet.petType
      );
    }
    if (props.viewMode) {
      return;
    }
    props.handleSubmit(values, setSubmitting);
  },
})(MetaDataForm);

export default connect(null, actions)(MetaDataForm);
