import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import PROVIDER_CONFIG from "./provider_config";

class Fmpi extends Component {
  render() {
    return <Menu {...PROVIDER_CONFIG} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.fmpi,
      components: {
        result: state.intl.messages.components.painIndex.result,
        chart: state.intl.messages.components.chart,
      },
    },
    fmpi: state.reducers.fmpi,
  };
};

export default connect(mapStateToProps)(Fmpi);
