import React, { useState } from "react";
import Button from "components/elements/Button";
import Intl from "components/Intl";

function FooterLinks({ links, appData, isMobile }) {
  const [copied, setCopied] = useState(false);

  function handleContactEmailClick(text) {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }

  return (
    <ul>
      {links.map((linkName) => (
        <li key={linkName}>
          {linkName === "contactEmail" ? (
            <Button
              className={`is-inverted is-text is-fullwidth ${
                copied && "is-success has-text-weight-bold"
              }`}
              hasChildren
              onClick={() =>
                handleContactEmailClick(appData.footerLinks[linkName])
              }
            >
              {copied ? (
                <Intl message path="components.buttons.common.copied" />
              ) : (
                <span className="icon">
                  <i className="icon fas fa-envelope" />
                  <div>{appData.footerLinks[linkName]}</div>
                </span>
              )}
            </Button>
          ) : (
            <Button
              className="is-text is-inverted is-fullwidth"
              withIcon
              iconLeft
              group="footer"
              href
              url={appData.footerLinks[linkName]}
              name={linkName}
            />
          )}
        </li>
      ))}
    </ul>
  );
}

export default FooterLinks;
