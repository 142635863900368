import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Section from "components/layout/Section";
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons";
import MetaDataForm from "forms/Award_Meta_Data_Form";
import {
  MODALS_SHOW,
  MODALS_CONFIRM_DELETE,
  AWARD_CABINET_DELETE,
} from "actions/types";

function ViewEntry(props) {
  const [awardData, setAwardData] = useState({});
  const [viewMode, setViewMode] = useState(true);

  const { entries, match, history, dispatch, defaultRoute, pet } = props;
  const { id } = match.params;

  useEffect(() => {
    setAwardData(entries.filter((award) => award._id === id)[0]);
  }, [awardData, defaultRoute, entries, history, id]);

  function handleEditClick() {
    setViewMode(!viewMode);
  }

  function handleDeleteClick() {
    const successRoute = `${defaultRoute}/view_entries`;
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "awardCabinet",
        actionType: AWARD_CABINET_DELETE,
        history,
        successRoute,
        item: awardData._id,
        petType: pet.petType,
      },
    });
  }

  if (_.isEmpty(awardData)) return <div />;

  return (
    <Section>
      <BackEditDeleteButtons
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        viewMode={viewMode}
      />
      <MetaDataForm
        editMode
        viewMode={viewMode}
        awardData={awardData}
        cancel={handleEditClick}
        {...props}
      />
    </Section>
  );
}

export default withRouter(ViewEntry);
