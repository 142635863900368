import React from "react"
import { withRouter, Route } from "react-router-dom"
import { connect } from "react-redux"
import PartnerPageView from "components/partners/Partner_Page_View"

function PartnerRoutes(props) {
  return (
    <>
      {props.partnerRoutes.map((route, i) => {
        return (
          <Route
            key={i}
            render={componentProps => (
              <PartnerPageView
                {...componentProps}
                partnerId={props.partnerId}
              />
            )}
          />
        )
      })}
    </>
  )
}

const mapStateToProps = state => {
  return {
    partnerId: state.reducers.auth.user.partnerId,
    partnerRoutes: state.reducers.partners.partnerRoutes
  }
}

export default withRouter(connect(mapStateToProps)(PartnerRoutes))
