import React from "react";
import { connect } from "react-redux";
import inputValidationColor from "./Input_Validation_Color";
import TRAINING_TYPES from "constants/training_types";


const style2 = {
  border: `1px solid lightgrey`,
};

const SelectBox = (props) => {
  const {
    field,
    form,
    icon,
    className,
    viewMode,
    editMode,
    locale,
    messages,
    petType,
    userDefinedSelectOptions,
  } = props;

  const selectOptions =
    userDefinedSelectOptions.length === 0
      ? TRAINING_TYPES.defaultSelection[petType]
      : userDefinedSelectOptions;

  const selectOptionsLocalized = selectOptions.map((opt) => ({
    value: opt,
    label: messages.selectOptions.trainingTypes[opt][locale],
  }));

  const selectOptionsOrdered = selectOptionsLocalized.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );

  return (
    <div className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">
          {messages.labels[field.name][locale]}
        </div>
      </label>
      <div className="control has-icons-left">
        {viewMode || editMode ? (
          <input
            className="input is-small"
            disabled={true}
            value={messages.selectOptions.trainingTypes[field.value][locale]}
            style={style2}
             />
        ) : (
          <div
              className={`${className} ${inputValidationColor(
              field.name,
              form.touched,
              form.errors
            )}`}
            style={style2}
          >
            <select {...field}>
              <option value="">
                {messages.selectOptions.defaultSelect[locale]}
              </option>
              {selectOptionsOrdered.map((option, i) => {
                return (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <span className="icon is-small is-left">
          <i className={icon} />
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      selectOptions: {
        defaultSelect:
          state.intl.messages.components.forms.selectOptions.trainingTypes
            .selectAnOption,
        trainingTypes:
          state.intl.messages.components.forms.selectOptions.trainingTypes,
      },
      labels: {
        ...state.intl.messages.components.forms.labels.common,
        ...state.intl.messages.components.forms.labels.trainingDiary,
      },
    },
  };
};

export default connect(mapStateToProps)(SelectBox);
