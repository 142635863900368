import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { reporting as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { INPUT_FIELDS, FETCH_BUTTONS } from "./fields/report_request";
import SectionTitle from "components/layout/Section_Title";
import FormButtons from "components/form/FormButtons";

class ReportRequestForm extends Component {
  handleSetDates = (days) => {
    this.props.setFieldValue("queryToDate", moment().format("YYYY-MM-DD"));
    this.props.setFieldValue(
      "queryFromDate",
      moment().subtract(days, "d").format("YYYY-MM-DD")
    );
  };

  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          editMode={editMode}
          viewMode={viewMode}
          values={values}
          {...field}
        />
      );
    });
  }

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      viewMode,
      editMode,
      report,
      locale,
      cancel,
      defaultRoute,
    } = this.props;
    const { buttons, reportTypes } = this.props.messages;

    return (
      <Form>
        <div className="has-text-centered">
          <SectionTitle title={reportTypes[report.reportType]} subtitle={{}} />
        </div>
        <br />
        <FetchButtons>
          {FETCH_BUTTONS.map((button, i) => (
            <FetchButton
              key={i}
              onClick={() => this.handleSetDates(button.fetchValue)}
              label={buttons[button.name][locale]}
            />
          ))}
        </FetchButtons>
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={defaultRoute}
        />
        <br />
      </Form>
    );
  }
}

const FetchButtons = (props) => {
  return (
    <div className={"buttons is-centered " + props.className}>
      {props.children}
    </div>
  );
};

const FetchButton = (props) => {
  return (
    <button
      className="button is-warning is-rounded is-small"
      type="button"
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: {
      buttons: {
        ...state.intl.messages.components.fetch.buttons,
      },
      reportTypes:
        state.intl.messages.containers.features.reporting.reportTypes,
    },
  };
};

ReportRequestForm = withFormik({
  mapPropsToValues({ report, pet }) {
    return {
      reportType: report.reportType,
      queryFromDate: "",
      queryToDate: "",
      _template: report._id,
      _pet: pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    queryFromDate: Yup.date().required("required"),
    queryToDate: Yup.date().required("required"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/report_requests`;
    props.submitRequest(values, setSubmitting, props.history, successRoute);
  },
})(ReportRequestForm);

export default connect(mapStateToProps, actions)(ReportRequestForm);
