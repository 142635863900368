import React from "react";
import inputValidationColor from "components/form/Input_Validation_Color";
import SELECT_OPTIONS from "constants/select_options";

export default function SelectBox({
  className,
  type,
  field,
  disabled,
  selectOptions,
  locale,
  form,
  group,
  altName,
}) {
  let selectOptionsAsArray =
    SELECT_OPTIONS[group][altName ? altName : field.name].options;

  if (disabled)
    return (
      <input
        className={`input ${inputValidationColor(
          field.name,
          form.touched,
          form.errors
        )}`}
        type={"text"}
        value={
          selectOptions[field.value]
            ? selectOptions[field.value][locale]
            : field.value
        }
        readOnly={disabled}
      />
    );

  return (
    <div
      className={`${className} ${inputValidationColor(
        field.name,
        form.touched,
        form.errors
      )}`}
    >
      <select {...field} readOnly={disabled} disabled={disabled}>
        {selectOptionsAsArray.map((option, i) => {
          return (
            <option key={i} value={option}>
              {selectOptions[option][locale]}
            </option>
          );
        })}
      </select>
    </div>
  );
}
