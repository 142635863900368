import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as Yup from "yup";
import { INPUT_FIELDS } from "./decline_fields";
import Intl from "components/Intl";
import Message from "components/message";
import { FormTitle, FormButtons } from "components/form";

class DeclineAppointmentForm extends Component {
  renderFields = () => {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  };

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      editMode,
      viewMode,
      cancel,
      defaultRoute,
    } = this.props;
    return (
      <div>
        <br />
        <Message default color="is-info">
          <Intl
            message
            path="components.messages.infoBlocks.appointmentDeclineReason"
          />
        </Message>
        <Form>
          <FormTitle group={"appointmentsDecline"} />
          <br />
          {this.renderFields()}
          <br />
          <FormButtons
            viewMode={viewMode}
            editMode={editMode}
            isSubmitting={isSubmitting}
            errors={errors}
            dirty={dirty}
            cancel={cancel}
            linkTo={defaultRoute}
          />
          <br />
        </Form>
      </div>
    );
  }
}

DeclineAppointmentForm = withFormik({
  mapPropsToValues({ valuesToEdit }) {
    return {
      declineReason: "",
      _id: valuesToEdit._id,
    };
  },
  validationSchema: Yup.object().shape({
    declineReason: Yup.string().max(50, "max-50"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    if (props.editMode) {
      return props.delcineAppointment(values, setSubmitting);
    }
  },
})(DeclineAppointmentForm);

export default connect(null, actions)(DeclineAppointmentForm);
