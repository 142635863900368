import main from "./professionals.svg"
import newContactRequest from "./professionals_new_contact_request.svg"
import deleteContact from "./contact_delete.svg"
import contactsPets from "./contact_pets.svg"

const professionals = {
  main,
  newContactRequest,
  deleteContact,
  contactsPets,
}

export default professionals
