import React, { Component } from "react";
import { connect } from "react-redux";
import { changePassword } from "actions/auth";
import { changePasswordProvider } from "containers/auth/Pet_Services_Portal/actions";
import { bindActionCreators } from "redux";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { INPUT_FIELDS } from "forms/fields/change_password";
import { FormButtons, FormTitle } from "components/form";

class ChangePasswordForm extends Component {
  renderFields() {
    return INPUT_FIELDS.map((field, i) => {
      return <Field key={i} {...field} />;
    });
  }

  render() {
    const { errors, dirty, isSubmitting, cancel } = this.props;

    return (
      <Form>
        <FormTitle group={"changePassword"} />
        {this.renderFields()}
        <br />
        <FormButtons
          useCancel
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
        />
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      changePassword,
      changePasswordProvider,
    },
    dispatch
  ),
});

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}
Yup.addMethod(Yup.string, "equalTo", equalTo);

ChangePasswordForm = withFormik({
  mapPropsToValues() {
    return {
      password: "",
      newPassword: "",
      newPassword2: "",
    };
  },
  validationSchema: Yup.object().shape({
    password: Yup.string().required("required"),
    newPassword: Yup.string().min(8, "min-8").required("required"),
    newPassword2: Yup.string()
      .equalTo(Yup.ref("newPassword"), "passwordsMustMatch")
      .required("required"),
  }),
  handleSubmit(values, { props, setSubmitting, resetForm }) {
    if (props.user) {
      return props.changePassword(
        values,
        setSubmitting,
        resetForm,
        props.history
      );
    }
    if (props.provider) {
      return props.changePasswordProvider(
        values,
        setSubmitting,
        resetForm,
        props.history
      );
    }
  },
})(ChangePasswordForm);

export default connect(null, mapDispatchToProps)(ChangePasswordForm);
