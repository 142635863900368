import React from "react"
import { FacebookProvider, Page } from "react-facebook"

function FacebookPage(props) {
  return (
    <FacebookProvider appId={props.appId}>
      <div style={props.style ? props.style : {}}>
        <Page href={props.page} tabs="timeline" width={380} height={600} />
      </div>
    </FacebookProvider>
  )
}

export default FacebookPage
