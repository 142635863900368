import { features } from "assets";
import { path } from "constants/feature_routes_provider_view";
import BodyMarkerView from "./views/Body_Marker_View";
import LandingRedirect from "components/features/Landing_Redirect";

const bodyMarkerProviderConfig = {
  name: "bodyMarker",
  type: "feature",
  redirect: path("body_marker/markers"),
  defaultRoute: path("body_marker"),
  menuIcon: {
    img: features.bodyMarker.main,
    alt: "body_marker_feature_icon",
  },
  providerView: true,
  providerEdit: true,
  nav: [
    {
      name: "landingRedirect",
      disabled: false,
      menuItem: false,
      exact: true,
      component: LandingRedirect,
      path: "/",
    },
    {
      name: "bodyMarkers",
      disabled: false,
      menuItem: true,
      image: {
        img: features.bodyMarker.markers,
        alt: "body_marker_icon",
      },
      icon: "far fa-plus-square",
      component: BodyMarkerView,
      path: "/markers",
    },
  ],
};

export default bodyMarkerProviderConfig;
