import React from "react";
import { withRouter } from "react-router-dom";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";

function BackButton({ history }) {
  return (
    <Buttons>
      <Button
        group="common"
        name="back"
        onClick={() => history.goBack()}
        withIcon
        iconLeft
      />
    </Buttons>
  );
}

export default withRouter(BackButton);
