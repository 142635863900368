import React from "react"
import { features, litterFeatures } from "assets"
import Landing from "components/features/Landing"
import AddNew from "../components/Add_New"
import ViewEntries from "./View_Entries"

const litterVaccinationsConfig = {
  name: "litterVaccinations",
  type: "litterFeature",
  defaultRoute: "/userhome/dashboard/litter/vaccinations",
  menuIcon: {
    img: litterFeatures.vaccinations,
    alt: "litter_vaccinations_icon",
  },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: litterFeatures.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "addNew",
      menuItem: true,
      image: {
        img: features.vaccinations.newEntry,
        alt: "litter_vaccinations_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: (props) => <AddNew litterVacs {...props} />,
      path: "/add_new",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.vaccinations.viewEntries,
        alt: "litter_vaccinations_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
  ],
}

export default litterVaccinationsConfig
