import main from "./mood.svg"
import newEntry from "./stress_new_entry.svg"
import viewEntries from "./stress_view_enries.svg"

const stressLevel = {
  main,
  newEntry,
  viewEntries,
}

export default stressLevel
