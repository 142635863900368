import moment from "moment";
import localizationFI from "moment/locale/fi";
import localizationSE from "moment/locale/sv";

export const displayDateRange = (from, to) => {
  return `${moment(from).format("DD-MMM-YYYY")} - ${moment(to).format(
    "DD-MMM-YYYY"
  )}`;
};

export const shortDateForDisplay = (date) => {
  return moment(date).format("DD.MM.YYYY");
};

export const shortDate = (date) => {
  return moment(date).format("DD-MMM-YY");
};

export const updateLocale = (locale) => {
  if (locale === "fi") {
    moment.updateLocale(locale, localizationFI);
  }
  if (locale === "se") {
    moment.updateLocale(locale, localizationSE);
  } else {
    moment.locale(locale);
  }
};

export const returnISODateMinusDays = (days) => {
  const date = moment().subtract(days, "days").toISOString();
  return date;
};

export const returnISODatePlusDays = (days) => {
  const date = moment().add(days, "days").toISOString();
  return date;
};

export const returnISODate = () => {
  const date = moment().toISOString();
  return date;
};

export const returnDateLocaleFormat = (date, locale) => {
  if (locale === "fi") {
    return moment(date).format("D[.] MMMM[ta] YYYY");
  }
  updateLocale(locale);
  return moment(date).format("D MMMM YYYY");
};

export const returnDateAndTimeLocaleFormat = (date, locale) => {
  if (locale === "fi") {
    return moment(date).format("D[.] MMMM[ta] YYYY HH:mm");
  }
  updateLocale(locale);
  return moment(date).format("D MMMM YYYY HH:mm");
};

export const year = moment().format("YYYY");

export function diffInDaysReturnNumber(date) {
  const diffDays = moment(date).diff(moment(), "days");

  return diffDays;
}

export function diffInSecondsReturnNumber(date) {
  const diffSeconds = moment().diff(moment(date), "seconds");

  return diffSeconds;
}

export function returnAppointmentStatus(date) {
  const diffDays = moment(date).diff(moment(), "days");

  if (diffDays >= 7) return { status: "valid", color: "success" };

  if (diffDays >= 0 && diffDays < 7)
    return { status: "expiring", color: "warning" };

  if (diffDays < 0) return { status: "expired", color: "danger" };
}

export function isBefore(dateA, dateB) {
  return moment(dateA).isBefore(moment(dateB));
}

export function dateIsInFuture(date) {
  const dateInIsFuture = moment(date).isAfter(moment());

  return dateInIsFuture;
}

export function returnDateRange(fetchType, dob) {
  const to = moment().toISOString();
  switch (fetchType) {
    case "fromDob":
      return {
        from: dob,
        to,
      };
    case "last7Days":
      return {
        from: moment().subtract(7, "days").toISOString(),
        to,
      };
    case "last14Days":
      return {
        from: moment().subtract(14, "days").toISOString(),
        to,
      };
    case "last30Days":
      return {
        from: moment().subtract(30, "days").toISOString(),
        to,
      };
    case "last3Months":
      return {
        from: moment().subtract(3, "months").toISOString(),
        to,
      };
    case "last6Months":
      return {
        from: moment().subtract(6, "months").toISOString(),
        to,
      };
    case "last9Months":
      return {
        from: moment().subtract(9, "months").toISOString(),
        to,
      };
    case "last1Year":
      return {
        from: moment().subtract(1, "year").toISOString(),
        to,
      };
    default:
      return {};
  }
}

export function returnTimeInHrsAndMins(s, labels, locale) {
  var h = Math.floor(s / 3600);
  var m = Math.floor((s % 3600) / 60);

  var hDisplay = h > 0 ? h + "h " : "";
  var mDisplay = m > 0 ? m + "min " : "";
  return hDisplay + mDisplay;
}
