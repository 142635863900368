import axios from "axios"

import {
  AUTH_LOG_OUT_USER,
  PETS_FETCH_ALL,
  PETS_ADD,
  PETS_UPDATE,
  PETS_DELETE,
  PETS_ADD_PROFILE_IMAGE,
  ACTION_STARTED,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_ERROR,
  ACTION_RESET,
  AUTH_FETCH_CURRENT_USER,
  PETS_SELECT_PET,
  PETS_UNSELECT_PET,
  MODALS_HIDE,
  TRANSFERS_UPDATE_PET_AND_TRANSFER,
} from "./types"
import { catchBlockError } from "./functions"

export const fetchPetsAll = (history) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: PETS_FETCH_ALL })
    const res = await axios.get("/api/pets", {
      headers: { authorization: localStorage.getItem("token") },
    })
    if (res.data.noPetsFound) {
      dispatch({
        type: PETS_FETCH_ALL,
        payload: [],
      })
      dispatch({
        type: ACTION_SUCCESS,
        payload: "addPetToGetStarted",
      })
    } else {
      dispatch({
        type: PETS_FETCH_ALL,
        payload: res.data.pets,
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 1000)
  } catch (error) {
    dispatch({
      type: PETS_FETCH_ALL,
      payload: [],
    })
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    })
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: AUTH_LOG_OUT_USER })
      history.push("/")
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const addPet = (values, setSubmitting, resetForm, history) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: PETS_ADD })
    const res = await axios.post("/api/pets/", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    setSubmitting(false)
    resetForm()
    dispatch({ type: PETS_ADD, payload: res.data.pet })
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user })
    dispatch({ type: ACTION_ENDED })
    dispatch({ type: ACTION_RESET })
    return history.push("/userhome")
  } catch (error) {
    setSubmitting(false)
    dispatch({
      type: ACTION_ERROR,
      payload: "somethingWentWrong",
    })
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const selectPet = (pet) => (dispatch) => {
  dispatch({ type: PETS_SELECT_PET, payload: pet })
}

export const unselectPet = () => (dispatch) => {
  dispatch({ type: PETS_UNSELECT_PET })
}

export const updatePet = (values, setSubmitting, cancelEdit) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: PETS_UPDATE })
    const res = await axios.put("/api/pets/update", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    setSubmitting(false)
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({ type: PETS_UPDATE, payload: res.data.pet })
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
    return cancelEdit()
  } catch (error) {
    setSubmitting(false)
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const updatePetAndTransfer = (
  values,
  setSubmitting,
  petTransferId,
  history
) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: TRANSFERS_UPDATE_PET_AND_TRANSFER,
    })
    const res = await axios.put(
      "/api/pets/update_and_transfer",
      { values, petTransferId },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    setSubmitting(false)

    dispatch({ type: PETS_ADD, payload: res.data.pet })
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updatePetAndTransferSuccess",
    })
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
      dispatch({ type: TRANSFERS_UPDATE_PET_AND_TRANSFER })
      return history.push("/userhome")
    }, 5000)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const deletePet = (_id, history) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: PETS_DELETE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      "/api/pets/delete",
      { _id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({ type: PETS_DELETE, payload: res.data.pet._id })
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user })
    dispatch({ type: ACTION_ENDED })
    dispatch({ type: ACTION_RESET })
    return history.push("/userhome")
  } catch (error) {
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const addProfileImageToPet = (_id, imageObj) => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: PETS_ADD_PROFILE_IMAGE })
  try {
    const res = await axios.put(
      "/api/pets/update_profile_image",
      { _id, imageObj },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({ type: PETS_UPDATE, payload: res.data.pet })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "addProfileImageSuccess",
    })
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
  } catch (error) {
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

const petsActions = {
  fetchPetsAll,
  addPet,
  selectPet,
  unselectPet,
  updatePet,
  deletePet,
  addProfileImageToPet,
  updatePetAndTransfer,
}

export default petsActions
