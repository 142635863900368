import { InputFieldV5, SelectV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "petName",
    group: "addPet",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "petGivenName",
    group: "addPet",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "petType",
    group: "addPet",
    type: "select",
    icon: "fa fa-paw",
    className: "select ",
    component: SelectV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "petTypeOther",
    group: "addPet",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "petSex",
    group: "addPet",
    type: "select",
    icon: "fa fa-paw",
    className: "select ",
    component: SelectV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "petBreed",
    group: "addPet",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "hasBeenSterillized",
    group: "addPet",
    type: "select",
    icon: "fa fa-paw",
    className: "select ",
    component: SelectV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "petDob",
    group: "addPet",
    type: "date",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "petInsuranceNumber",
    group: "addPet",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    profileOnly: true,
    required: false,
    userCanEdit: true,
  },
  {
    name: "petRegNumber",
    group: "addPet",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    profileOnly: true,
    required: false,
    userCanEdit: true,
  },
  {
    name: "petChipNumber",
    group: "addPet",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    profileOnly: true,
    required: false,
    userCanEdit: true,
  },
  {
    name: "petPassportNumber",
    group: "addPet",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    profileOnly: true,
    required: false,
    userCanEdit: true,
  },
  {
    name: "researchProject",
    group: "addPet",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    required: false,
    component: InputFieldV5,
    profileOnly: true,
    userCanEdit: true,
  },
  {
    name: "living",
    group: "addPet",
    type: "select",
    icon: "fa fa-paw",
    className: "select ",
    component: SelectV5,
    required: false,
    profileOnly: true,
    userCanEdit: true,
  },
  {
    name: "dateOfDeath",
    group: "addPet",
    type: "date",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: false,
    profileOnly: true,
    userCanEdit: true,
  },
  {
    name: "reasonOfDeath",
    group: "addPet",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: false,
    profileOnly: true,
    userCanEdit: true,
    charLimit: 50,
  },
];

export { INPUT_FIELDS };
