import { TextareaV5 } from "components/form";

export const INPUT_FIELDS = [
  {
    name: "userNotes",
    group: "appointments",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    userCanEdit: true,
    charLimit: 1000,
    lastInGroup: true,
  },
];
