import React from "react";
import FetchEntries from "components/fetch";
import TableAndChartView from "./Table_And_Chart_View";
import Section from "components/layout/Section";
import Message from "components/message";

function ViewEntries(props) {
  const {
    pet,
    stressLevel,
    fetchButtons,
    tableView,
    defaultRoute,
    fetchStressLevelEntries,
    providerView,
  } = props;
  const { fetchType, lastFetchPetID, fetchDates, entries } = stressLevel;

  function handleFetchEntries(value, unit, name) {
    fetchStressLevelEntries(value, unit, name, pet, providerView);
  }

  const entriesFiltered = entries.filter((entry) => entry._pet === pet._id);

  return (
    <>
      <FetchEntries
        fetchButtons={fetchButtons}
        fetchItems={handleFetchEntries}
        fetchType={fetchType}
        fetchDates={fetchDates}
        newPet={pet._id === lastFetchPetID}
      />
      <Section>
        {entriesFiltered.length === 0 ? (
          <Message warning message={"noEntriesToDisplayRetry"} />
        ) : (
          <TableAndChartView
            feature="stressLevel"
            pet={pet._id}
            id={Date.now()}
            headers={tableView.headers}
            mobileHeaders={tableView.mobileHeaders}
            filterByItems={[]}
            filterKey={""}
            viewEntryTo={`${defaultRoute}/view_entry/`}
            fetchDates={fetchDates}
            fetchType={fetchType}
            data={entriesFiltered}
          />
        )}
      </Section>
    </>
  );
}

export default ViewEntries;
