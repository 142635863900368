import React from "react"
import SmartTile from "components/SmartTile"
import config from "containers/main/Advertisements/config"

export default function Advertisements(props) {
  return (
    <div className={props.column}>
      <SmartTile
        appTile
        header
        title={props.name}
        icon={config.main.img.src}
        path={config.main.path}
      />
    </div>
  )
}
