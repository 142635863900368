import { InputFieldV5 } from "components/form";

export const INPUT_FIELDS = [
  {
    name: "clientRef",
    group: "updateClientData",
    type: "text",
    icon: "fas fa-hashtag",
    className: "input ",
    component: InputFieldV5,
    required: false,
  },
  {
    name: "clientUrl",
    group: "updateClientData",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: InputFieldV5,
    required: false,
  },
];
