import { AUTH_LOG_OUT_USER } from "actions/types";
import {
  APPOINTMENTS_FETCH,
  APPOINTMENTS_ADD,
  APPOINTMENTS_UPDATE,
  APPOINTMENTS_DELETE,
} from "./actions";
import _ from "lodash";
import moment from "moment";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";

const INITIAL_STATE = {
  entries: [],
  fetched: [],
  lastFetch: moment(),
};

export default function Appointments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case APPOINTMENTS_FETCH:
      return {
        ...state,
        entries: _.unionBy(state.entries, action.payload, "_id"),
        fetched: [...state.fetched, action._pet],
        lastFetch: moment(),
      };
    case APPOINTMENTS_ADD:
      return {
        ...state,
        entries: _.sortBy(
          [
            ...state.entries.filter(
              (entry) => entry._id !== action.payload._id
            ),
            action.payload,
          ],
          "date"
        ),
      };
    case APPOINTMENTS_UPDATE:
      return {
        ...state,
        entries: _.sortBy(
          [
            ...state.entries.filter(
              (entry) => entry._id !== action.payload._id
            ),
            action.payload,
          ],
          "date"
        ),
      };
    case APPOINTMENTS_DELETE:
      return {
        ...state,
        entries: state.entries.filter((entry) => entry._id !== action.payload),
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
