import React, { Component } from "react"
import { connect } from "react-redux"
import Menu from "components/menu"
import config from "./config"
import { litterUsefulLinks as actions } from "actions"

class LitterUsefulLinks extends Component {
  render() {
    return <Menu {...config} {...this.props} />
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.containers.litterFeatures.litterUsefulLinks,
    usefulLinks: state.reducers.litterUsefulLinks,
  }
}

export default connect(mapStateToProps, actions)(LitterUsefulLinks)
