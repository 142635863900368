import InputField from "./components/InputField";
import SelectBox from "./components/SelectBox";

export const FIELDS = [
  {
    name: "email",
    fieldProps: {
      component: InputField,
      className: "input",
      type: "text",
    },
  },
  {
    name: "vatNumber",
    fieldProps: {
      component: InputField,
      className: "input",
      type: "text",
    },
  },
  {
    name: "firstname",
    fieldProps: {
      component: InputField,
      className: "input",
      type: "text",
    },
    providerCanEdit: true,
  },
  {
    name: "lastname",
    fieldProps: {
      component: InputField,
      className: "input",
      type: "text",
    },
    providerCanEdit: true,
  },
  {
    name: "serviceName",
    fieldProps: {
      component: InputField,
      className: "input",
      type: "text",
    },
    providerCanEdit: true,
  },
  {
    name: "serviceType",
    fieldProps: {
      name: "serviceType",
      group: "providerRegister",
      component: SelectBox,
      className: "select is-fullwidth",
      type: "select",
    },
    providerCanEdit: true,
  },
  {
    name: "streetAddress",
    fieldProps: {
      component: InputField,
      className: "input",
      type: "text",
    },
    providerCanEdit: true,
  },
  {
    name: "postcode",
    fieldProps: {
      component: InputField,
      className: "input",
      type: "text",
    },
    providerCanEdit: true,
  },
  {
    name: "city",
    fieldProps: {
      component: InputField,
      className: "input",
      type: "text",
    },
    providerCanEdit: true,
  },
  {
    name: "province",
    fieldProps: {
      component: InputField,
      className: "input",
      type: "text",
    },
    providerCanEdit: true,
  },
];
