import {
  PETS_FETCH_ALL,
  PETS_ADD,
  PETS_UPDATE,
  PETS_DELETE,
  PETS_SELECT_PET,
  PETS_UNSELECT_PET,
  AUTH_LOG_OUT_USER,
} from "actions/types"
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions"
import { PROVIDER_FETCH_CONTACTS_PETS } from "containers/contacts/actions"
import _ from "lodash"

const INITIAL_STATE = {
  profiles: [],
  profilesFetched: false,
  selectedPet: {},
  numActivePets: 0,
  providerContacts: [],
}

export default function PetsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PETS_FETCH_ALL:
      return {
        ...state,
        profiles: _.sortBy(action.payload, "dateCreated"),
        profilesFetched: true,
        numActivePets: action.payload.length,
      }
    case PROVIDER_FETCH_CONTACTS_PETS:
      return {
        ...state,
        profiles: _.sortBy(
          [...action.payload.pets, ...state.profiles],
          "dateCreated"
        ),
        profilesFetched: true,
        providerContacts: [action.payload._user, ...state.providerContacts],
      }
    case PETS_ADD:
      return {
        ...state,
        profiles: _.sortBy([action.payload, ...state.profiles], "dateCreated"),
        numActivePets: state.numActivePets + 1,
      }
    case PETS_SELECT_PET:
      return { ...state, selectedPet: action.payload }
    case PETS_UNSELECT_PET:
      return { ...state, selectedPet: {} }
    case PETS_UPDATE:
      return {
        ...state,
        profiles: _.sortBy(
          [
            ...state.profiles.filter(
              (profile) => profile._id !== action.payload._id
            ),
            action.payload,
          ],
          "dateCreated"
        ),
        selectedPet: action.payload,
      }
    case PETS_DELETE:
      return {
        ...state,
        profiles: _.sortBy(
          [
            ...state.profiles.filter(
              (profile) => profile._id !== action.payload
            ),
          ],
          "dateCreated"
        ),
        numActivePets: state.numActivePets - 1,
      }
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE
    default:
      return state
  }
}
