import React from "react";
import { connect } from "react-redux";
import Section from "components/layout/Section";
import FetchEntries from "components/fetch";
import TableAndChartDailyActivity from "./Table_And_Chart_Daily_Activity";
import Message from "components/message";

function ViewEntriesDailyActivity(props) {
  const {
    pet,
    activity,
    fetchDailyActivityEntries,
    tableView,
    defaultRoute,
    actionTypes,
    locale,
    trainingTypes,
    chartLabels,
    categories,
    activities,
    providerView,
  } = props;
  const { fetchType, lastFetchPetID, fetchDates, entries } = activity;

  function handleFetchEntries(value, unit, name) {
    fetchDailyActivityEntries(value, unit, name, pet, providerView);
  }

  const entriesFiltered = entries.filter((entry) => entry._pet === pet._id);

  return (
    <>
      <FetchEntries
        fetchItems={handleFetchEntries}
        fetchType={fetchType}
        newPet={pet._id === lastFetchPetID}
        fetchButtons={["last24Hrs", "last7Days", "last30Days"]}
      />
      <Section>
        {entriesFiltered.length === 0 ? (
          <Message warning message={"noEntriesToDisplayRetry"} />
        ) : (
          <TableAndChartDailyActivity
            feature="dailyActivity"
            pet={pet._id}
            id={Date.now()}
            headers={tableView.headers}
            mobileHeaders={tableView.mobileHeaders}
            filterByItems={[]}
            filterKey={""}
            viewEntryTo={`${defaultRoute}/view_entry_daily_activity/`}
            fetchDates={fetchDates}
            fetchType={fetchType}
            data={entriesFiltered}
            actionTypes={actionTypes}
            messages={{
              categories,
              activities,
              trainingTypes,
              chartLabels,
            }}
            locale={locale}
          />
        )}
      </Section>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    trainingTypes:
      state.intl.messages.components.forms.selectOptions.trainingTypes,
    chartLabels: state.intl.messages.components.chart.labels,
    categories:
      state.intl.messages.components.forms.selectOptions.activityCategories,
    activities:
      state.intl.messages.components.forms.selectOptions.activityActivities,
  };
};

export default connect(mapStateToProps)(ViewEntriesDailyActivity);

/* headers: {
  ...messages.table.headers.activity,
  ...messages.table.headers.common,
},
cells: {
  ...messages.table.cells.activity,
  ...messages.table.cells.common,
}, */
