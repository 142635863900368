import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import PROVIDER_CONFIG from "./provider_config";

class HomeCare extends Component {
  render() {
    return <Menu {...PROVIDER_CONFIG} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    petType: state.reducers.pets.selectedPet.petType,
    messages: {
      ...state.intl.messages.containers.features.homeCare,
      categories:
        state.intl.messages.components.forms.selectOptions.homeCareCategories,
      activities:
        state.intl.messages.components.forms.selectOptions.homeCareActivities,
    },
    homeCare: state.reducers.homeCare,
    providerContact: { _id: state.reducers.pets.selectedPet._user },
  };
};

export default connect(mapStateToProps)(HomeCare);
