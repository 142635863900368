import _101 from "./dog_101.svg";
import _102 from "./dog_102.svg";
import _103 from "./dog_103.svg";
import _104 from "./dog_104.svg";
import _105 from "./dog_105.svg";

import _106 from "./dog_106.svg";
import _107 from "./dog_107.svg";
import _108 from "./dog_108.svg";
import _109 from "./dog_109.svg";
import _110 from "./dog_110.svg";

import _201 from "./dog_201.svg";
import _202 from "./dog_202.svg";
import _203 from "./dog_203.svg";
import _204 from "./dog_204.svg";
import _205 from "./dog_205.svg";

import _206 from "./dog_206.svg";
import _207 from "./dog_207.svg";
import _208 from "./dog_208.svg";
import _209 from "./dog_209.svg";
import _210 from "./dog_210.svg";

import _301 from "./dog_301.svg";
import _302 from "./dog_302.svg";
import _303 from "./dog_303.svg";
import _304 from "./dog_304.svg";
import _305 from "./dog_305.svg";
import _306 from "./dog_306.svg";

import _307 from "./dog_307.svg";
import _308 from "./dog_308.svg";
import _309 from "./dog_309.svg";
import _310 from "./dog_310.svg";
import _311 from "./dog_311.svg";

import _401 from "./dog_401.svg";
import _402 from "./dog_402.svg";
import _403 from "./dog_403.svg";
import _404 from "./dog_404.svg";
import _405 from "./dog_405.svg";
import _406 from "./dog_406.svg";

import _407 from "./dog_407.svg";
import _408 from "./dog_408.svg";
import _409 from "./dog_409.svg";
import _410 from "./dog_410.svg";
import _411 from "./dog_411.svg";

const individuals = {
  _101,
  _102,
  _103,
  _104,
  _105,
  _106,
  _107,
  _108,
  _109,
  _110,
  _201,
  _202,
  _203,
  _204,
  _205,
  _206,
  _207,
  _208,
  _209,
  _210,
  _301,
  _302,
  _303,
  _304,
  _305,
  _306,
  _307,
  _308,
  _309,
  _310,
  _311,
  _401,
  _402,
  _403,
  _404,
  _405,
  _406,
  _407,
  _408,
  _409,
  _410,
  _411,
};

export default individuals;
