import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchParasites } from "actions/parasites";
import Section from "components/layout/Section";
import Message from "components/message";
import EntryCardWithStatus from "components/cards/Entry_Card_With_Status";

class ViewEntries extends Component {
  UNSAFE_componentWillMount() {
    const { litterMember, parasites } = this.props;
    if (!parasites.fetched.includes(litterMember._id)) {
      this.props.fetchParasites(litterMember);
    }
  }

  renderSectionContent() {
    const { parasites, cards, litterMember, defaultRoute } = this.props;
    const { entries } = parasites;

    const entriesFiltered = entries.filter(
      (entry) => entry._pet === litterMember._id
    );

    if (entriesFiltered.length === 0) {
      return <Message warning message={"noEntriesToDisplayAutoFetch"} />;
    }

    return (
      <div className="columns is-2 is-variable is-mobile is-multiline is-centered">
        {entriesFiltered.map((entry, i) => (
          <div
            key={i}
            className="column is-full-mobile is-3-desktop is-4-tablet"
          >
            <EntryCardWithStatus
              parasiteEntry
              litterMember
              to={`${defaultRoute}/view_entry/${entry._id}`}
              key={i}
              {...entry}
              messages={cards}
            />
          </div>
        ))}
      </div>
    );
  }

  render() {
    return <Section>{this.renderSectionContent()}</Section>;
  }
}

const mapStateToProps = (state) => {
  return {
    litterMember: state.reducers.litters.selectedLitterMember,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchParasites,
      dispatch,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEntries);
