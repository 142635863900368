import React from "react"
import { connect } from "react-redux"
import styled, { css, ThemeProvider, keyframes} from "styled-components"

const StyledBox = styled.div`
  background-color: ${(props) =>
    props.theme.settings.defaultBox.background} !important;

  ${css`
  ${(props) =>
    props.squareBorder &&
    `border: solid; border-width: 2px; border-color: lightgrey; border-radius: 0 !important;`}
  `}
  
`

const fadeIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0.25);
    opacity: 0;
  }
`

const Fade = styled.div`
  visibility: ${(props) => (props.out ? "hidden" : "visible")};
  animation: ${(props) => (props.out ? fadeOut : fadeIn)}
    ${(props) => 0.3 + props.fadeDelay + "s"} linear;
  transition: visibility 1s linear;

  img {
    transition: transform 0.2s;
  }

  img:hover {
    transform: scale(1.05);
  }
`


function BoxWithBackground(props) {
  return (
    <ThemeProvider theme={props.theme}>
      <StyledBox
        style={{ padding: 8, ...props.boxStyle }}
        {...props}
        className="box"
      >
        {props.withFade ? (
          <Fade fadeDelay={props.fadeDelay}>{props.children}</Fade>
        ) : (
          props.children
        )}
      </StyledBox>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => {
  return {
    theme: state.reducers.appData.theme,
  }
}

export default connect(mapStateToProps)(BoxWithBackground)