import axios from "axios"
import moment from "moment"
import _ from "lodash"
import {
  TEMPERATURE_FETCH,
  TEMPERATURE_FETCH_TYPE,
  TEMPERATURE_ADD,
  TEMPERATURE_UPDATE,
  TEMPERATURE_DELETE,
  ACTION_STARTED,
  ACTION_SUCCESS,
  MODALS_HIDE,
  TABLE_CLEAR_CURRENT_STATE,
} from "./types"
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "./functions"

const API = (route) => `/api/temperature/${route}`

// Action Creators
export const fetchTemperatureEntries = (
  value,
  unit,
  name,
  pet,
  providerView
) => async (dispatch) => {
  const dates = !_.isEmpty(value)
    ? { ...value }
    : {
        from: moment().subtract(value, unit).toISOString(),
        to: moment().toISOString(),
      }
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({
      type: TEMPERATURE_FETCH_TYPE,
      payload: { dates, type: !_.isEmpty(value) ? "range" : name },
    })
    dispatch({ type: ACTION_STARTED, payload: TEMPERATURE_FETCH })
    const res = await axios.get(
      API(providerView ? "fetch_for_provider" : "fetch"),
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
        params: { _pet: pet._id, ...dates },
      }
    )
    dispatch({
      type: TEMPERATURE_FETCH,
      payload: res.data.temperatureEntries,
      pet,
    })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addTemperatureEntry = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerContact,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: TEMPERATURE_ADD })
    const res = await axios.post(
      API(providerView ? "add_by_provider" : "add"),
      { _user: providerView ? providerContact._id : undefined, ...values },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    setSubmitting(false)
    dispatch({ type: TEMPERATURE_ADD, payload: res.data.temperatureEntry })
    endAndResetAction(dispatch)
    history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const updateTemperatureEntry = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: TEMPERATURE_UPDATE })
    const res = await axios.put(
      API(providerView ? "update_by_provider" : "update"),
      values,
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({
      type: TEMPERATURE_UPDATE,
      payload: res.data.temperatureEntry,
    })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const deleteTemperatureEntry = (_id, callback, providerView) => async (
  dispatch
) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: TEMPERATURE_DELETE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      API(providerView ? "delete_by_provider" : "delete"),
      { _id },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({
      type: TEMPERATURE_DELETE,
      payload: res.data.temperatureEntry,
    })
    endAndResetActionWithDelay(dispatch, 1500)
    callback()
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const temperatureActions = {
  fetchTemperatureEntries,
  addTemperatureEntry,
  updateTemperatureEntry,
  deleteTemperatureEntry,
}

export default temperatureActions
