import { features } from "assets"
import Landing from "components/features/Landing"
import ViewEntries from "./View_Entries"
import ViewEntry from "./View_Entry.js"

const litterVaccinationsConfig = {
  name: "litterVaccinations",
  type: "litterMemberFeature",
  defaultRoute:
    "/userhome/dashboard/litter/litter_member_dashboard/vaccinations",
  menuIcon: { img: features.vaccinations.main, alt: "vaccinations_icon" },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.vaccinations.viewEntries,
        alt: "vaccinations_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
    {
      disabled: true,
      name: "viewEntry",
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
  ],
}

export default litterVaccinationsConfig
