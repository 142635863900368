import React from "react";
import { Link } from "react-router-dom";
import BoxWithBackground from "components/styled/Box_With_Background";
import Section from "components/layout/Section";
import providerAccountItems from "./routes";
import Intl from "components/Intl";
import config from "./config";

function ProviderAccount(props) {
  return (
    <Section>
      <div className="columns is-2 is-variable is-mobile is-centered is-multiline is-flex">
        {providerAccountItems.map((item) => {
          if (item.view !== config.view) return null;
          return (
            <div
              key={item.name}
              className="column is-2-desktop is-6-mobile has-text-centered"
            >
              <Link to={item.path}>
                <BoxWithBackground>
                  <div className="level">
                    <div className="level-item">
                      <figure className="image is-48x48">
                        <img src={item.img.src} alt={item.img.alt} />
                      </figure>
                    </div>
                  </div>
                  <p className="title is-6 has-text-grey">
                    <Intl
                      message
                      path={`containers.providers.${[config.view]}.tiles.${[
                        item.name,
                      ]}`}
                    />
                  </p>
                </BoxWithBackground>
              </Link>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

export default ProviderAccount;
