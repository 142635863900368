import React from "react";
import Notification from "components/layout/Notification";
import Permissions from "./Permissions";
import LangSelector from "components/lang_selector";

function Settings(props) {
  return (
    <>
      <Notification>
        <Permissions />
      </Notification>
      <Notification>
        <LangSelector providerView />
      </Notification>
    </>
  );
}

export default Settings;
