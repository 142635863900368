import React from "react"

export default function Hero(props) {
  return (
    <div
      className={`${props.className} ${props.color} `}
      style={{ backgroundColor: props.color }}
    >
      {props.children}
    </div>
  )
}
