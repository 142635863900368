import {
  TABLE_STORE_CURRENT_STATE,
  TABLE_CLEAR_CURRENT_STATE,
  AUTH_LOG_OUT_USER,
} from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";

const INITIAL_STATE = {
  currentState: {},
  feature: "",
  pet: "",
};

export default function TableReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TABLE_STORE_CURRENT_STATE:
      return {
        ...action.payload,
      };
    case TABLE_CLEAR_CURRENT_STATE:
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
