import { features } from "assets"
import { path } from "constants/feature_routes_provider_view"
import NewEntry from "./New_Entry_View"
import ViewEntries from "./View_Entries"
import ViewEntry from "./View_Entry"
import LandingRedirect from "components/features/Landing_Redirect"

const foodDiaryProviderConfig = {
  name: "foodDiary",
  type: "feature",
  redirect: path("food_diary/view_entries"),
  defaultRoute: path("food_diary"),
  menuIcon: { img: features.foodDiary.main, alt: "food_diary_icon" },
  providerView: true,
  providerEdit: true,
  nav: [
    {
      name: "landingRedirect",
      disabled: false,
      menuItem: false,
      exact: true,
      component: LandingRedirect,
      path: "/",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.foodDiary.viewEntries,
        alt: "food_diary_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
    {
      disabled: false,
      name: "newEntry",
      menuItem: true,
      exact: true,
      image: {
        img: features.foodDiary.newEntry,
        alt: "food_diary_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: NewEntry,
      path: "/new_entry",
    },
    {
      disabled: false,
      name: "viewEntry",
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
  ],
  tableView: {
    headers: [
      "date",
      "time",
      "onGoing",
      "foodCategory",
      "foodAmount",
      "foodAmountUnit",
    ],
    mobileHeaders: ["date", "time", "onGoing", "foodCategory"],
  },
}

export default foodDiaryProviderConfig
