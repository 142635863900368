import React from "react"
import SmartTile from "components/SmartTile"
import config from "containers/main/Benefits/config"
import { MODALS_SHOW, MODALS_BENEFITS_INFO, MODALS_HIDE } from "actions/types"

export default function Benefits({ name, user: { subscription }, dispatch }) {
  function handleTileClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_BENEFITS_INFO,
      modalProps: {
        type: "benefits",
        handleModalClose: () => dispatch({ type: MODALS_HIDE }),
      },
    })
  }

  return (
    <div onClick={subscription ? null : handleTileClick}>
      <SmartTile
        appTile
        disabled={!subscription}
        header
        title={name}
        icon={config.main.img.src}
        path={subscription ? config.main.path : "/userhome"}
      />
    </div>
  )
}
