import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Section from "components/layout/Section";
import BoxWithBackground from "components/styled/Box_With_Background";
import petServicesPortalItems from "./routes";

function PetServicesPortal(props) {
  const { messages, locale } = props;
  const view = "petServicesPortal";

  return (
    <Section>
      <p className="title is-4 has-text-centered">
        {messages.titles.petServicesPortal[locale]}
      </p>
      <p className="subtitle is-6 has-text-centered">
        {messages.subtitles.petServicesPortal[locale]}
      </p>
      <div className="columns is-2 is-variable is-mobile is-centered is-multiline">
        {petServicesPortalItems.map((item) => {
          if (item.view !== view || !item.tile) return null;
          return (
            <div
              key={item.name}
              className="column is-2-desktop is-6-mobile has-text-centered"
            >
              <Link to={item.path}>
                <BoxWithBackground>
                  <div className="level">
                    <div className="level-item">
                      <figure className="image is-48x48">
                        <img src={item.img.src} alt={item.img.alt} />
                      </figure>
                    </div>
                  </div>
                  <p className="title is-6 has-text-grey">
                    {messages.titles[item.name][locale]}
                  </p>
                </BoxWithBackground>
              </Link>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.containers.auth.petServicesPortal,
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(PetServicesPortal);
