import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Section from "components/layout/Section";
import Message from "components/message";
import TaskGroup from "../components/TaskGroup";
import _ from "lodash";

function ViewEntriesView({
  tasks: { fetched, entries },
  pet,
  fetchTasks,
  defaultRoute,
  providerView,
  providerContact,
  providerId,
  messages,
  locale,
}) {
  useEffect(() => {
    function fetchData() {
      if (!fetched.includes(pet._id)) {
        fetchTasks(pet._id, providerView);
      }
      let filteredForCompletedTasks = entries.filter(
        (e) => e._pet === pet._id && e.lifeCycleState.status === "complete"
      );
      let filteredForActiveTasks = entries.filter(
        (e) => e._pet === pet._id && e.lifeCycleState.status === "active"
      );
      setCompletedTasks(
        _.sortBy(
          filteredForCompletedTasks,
          "lifeCycleState.completionDate"
        ).reverse()
      );
      setActiveTasks(filteredForActiveTasks);
    }
    fetchData();
  }, [entries, fetchTasks, fetched, pet._id, providerView]);

  const [completedTasks, setCompletedTasks] = useState([]);
  const [activeTasks, setActiveTasks] = useState([]);

  if (completedTasks.length === 0 && activeTasks.length === 0) {
    return (
      <Section>
        <Message warning message={"noEntriesToDisplayAutoFetch"} />
      </Section>
    );
  }

  return (
    <Section>
      {completedTasks.length > 0 && (
        <TaskGroup
          completed
          title={messages.task.titles.completed[locale]}
          items={completedTasks}
          messages={messages}
          locale={locale}
          defaultRoute={defaultRoute}
          providerView={providerView}
          providerContact={providerContact}
          expand
        />
      )}
      {activeTasks.length > 0 && (
        <TaskGroup
          active
          title={messages.task.titles.active[locale]}
          items={activeTasks}
          messages={messages}
          locale={locale}
          defaultRoute={defaultRoute}
          providerView={providerView}
          providerContact={providerContact}
          providerId={providerId}
        />
      )}
    </Section>
  );
}

export default connect(null, actions)(ViewEntriesView);
