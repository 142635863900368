import React from "react";
import { connect } from "react-redux";
import BoxWithBackground from "components/styled/Box_With_Background";
import TileButton from "./Tile_Button";

function TileButtons({ view, tileButtons, messages, locale }) {
  return (
    <div className="columns is-2 is-variable is-mobile is-centered is-multiline">
      {tileButtons.map((button) => (
        <div
          className="column is-2-desktop has-text-centered"
          key={button.name}
        >
          <BoxWithBackground>
            <TileButton {...button} name={messages[button.name][locale]} />
          </BoxWithBackground>
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = (state, { view }) => {
  const { locale } = state.intl;
  return {
    locale,
    messages: state.intl.messages.components.buttons[view],
  };
};

export default connect(mapStateToProps)(TileButtons);
