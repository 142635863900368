import React from "react";
import { connect } from "react-redux";
import { pets } from "assets";
import ImageContainer from "./Image_Container";
import BoxWithBackground from "./Box_With_Background";
import CardLabel from "./Card_Label";

const AddNewPetCard = (props) => {
  return (
    <div onClick={props.onClick}>
      <BoxWithBackground
        padding={12}
        boxStyle={{ height: "100%", cursor: "pointer" }}
      >
        <ImageContainer isMobile={props.isMobile}>
          <figure className={`image ${props.isMobile ? "is-1by1" : "1by1"}`}>
            <img src={pets.addPet} alt={"add_pet_icon"} />
          </figure>
        </ImageContainer>
        <CardLabel>{props.messages.addNewPet[props.locale]}</CardLabel>
      </BoxWithBackground>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: state.intl.messages.containers.pets.addPet.titles,
  };
};

export default connect(mapStateToProps)(AddNewPetCard);
