import React, { Component } from "react"
import Section from "components/layout/Section"

import NewReport from "./New_Report"

class NewReportView extends Component {
  render() {
    return (
      <Section>
        <NewReport {...this.props} />
      </Section>
    )
  }
}

export default NewReportView
