import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import _ from "lodash"
import Section from "components/layout/Section"

import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons"
import HeatDiaryProgEntryForm from "forms/Heat_Diary_Prog_Entry_Form"
import {
  MODALS_SHOW,
  MODALS_CONFIRM_DELETE,
  HEAT_DIARY_DELETE_PROG,
} from "actions/types"

const INITIAL_STATE = {
  viewMode: true,
  valuesToEdit: {},
}

class ViewEntry extends Component {
  state = INITIAL_STATE

  UNSAFE_componentWillMount() {
    const { heatDiary, history, defaultRoute } = this.props
    const { id } = this.props.match.params
    const entry = heatDiary.filter((item) => item._id === id)[0]
    if (!entry) return history.push(`${defaultRoute}/view_entries_prog`)
    this.setState({
      valuesToEdit: entry,
    })
  }

  handleEditClick = () => {
    this.setState({
      viewMode: !this.state.viewMode,
    })
  }

  handleDeleteClick = () => {
    const successRoute = `${this.props.defaultRoute}/view_entries_prog`
    this.props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "heatDiary",
        actionType: HEAT_DIARY_DELETE_PROG,
        history: this.props.history,
        successRoute: successRoute,
        item: this.state.valuesToEdit._id,
        entryType: this.state.valuesToEdit.type,
      },
    })
  }

  renderSectionContent() {
    if (_.isEmpty(this.state.valuesToEdit)) return <div />
    return (
      <>
        <BackEditDeleteButtons
          handleEditClick={this.handleEditClick}
          handleDeleteClick={this.handleDeleteClick}
          viewMode={this.state.viewMode}
        />
        <div className="columns is-mobile is-centered">
          <div className="column is-5-desktop is-full-mobile is-6-tablet">
            <HeatDiaryProgEntryForm
              editMode
              {...this.state}
              {...this.props}
              cancel={this.handleEditClick}
            />
          </div>
        </div>
      </>
    )
  }

  render() {
    return <Section>{this.renderSectionContent()}</Section>
  }
}

const mapStateToProps = (state) => {
  return {
    heatDiary: state.reducers.heatDiary.progs.entries,
  }
}

export default withRouter(connect(mapStateToProps)(ViewEntry))
