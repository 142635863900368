import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class Weight extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.weight,
      ...state.intl.messages.containers.litterMemberFeatures.litterMemberWeight,
      landing: {
        ...state.intl.messages.containers.litterMemberFeatures
          .litterMemberWeight.landing,
        title: state.intl.messages.containers.features.weight.landing.title,
        subtitle:
          state.intl.messages.containers.features.weight.landing.subtitle,
        menuLink:
          state.intl.messages.containers.features.weight.landing.menuLink,
      },
      components: {
        calorieCalculator: state.intl.messages.components.calorieCalculator,
        bodyConditionScore: state.intl.messages.components.bodyConditionScore,
      },
    },
    weight: state.reducers.weight,
    userPreferredUnit: state.reducers.auth.user.featurePreferences.weightUnit,
  };
};

export default connect(mapStateToProps)(Weight);
