//import axios from "axios";
import messages from "messages";
import { returnDefaultLocale } from "functions";

const locale = localStorage.getItem("languagePreference");
const localeFull = navigator.language;

/* async function fetchMessages() {
  try {
    const res = await axios.get("/api/messages/fetch");
    return res.data.messages;
  } catch (error) {
    return messages;
  }
} */

const initialState = {
  intl: {
    locale: locale ? locale : returnDefaultLocale(),
    localeFull: localeFull || returnDefaultLocale(),
    //messages: fetchMessages(),
    messages: Object(messages),
  },
};

export default initialState;
