// Main Imports
import axios from "axios";
import { ACTION_STARTED, ACTION_SUCCESS } from "actions/types";
import { catchBlockError, endAndResetAction } from "actions/functions";

import { extractAndMergeArrays } from "functions";

// TYPES
export const BLOG_FETCH_ALL_TAGS = "blog_fetch_all_tags";
export const BLOG_FETCH_POSTS_LATEST = "blog_fetch_posts_latest";
export const BLOG_FETCH_POSTS_BY_TAG = "blog_fetch_posts_by_tag";
export const BLOG_FETCH_POSTS_BY_TAG_AND_PAGINATE =
  "blog_fetch_posts_by_tag_and_paginate";
export const BLOG_SELECTED_POST = "blog_selected_post";
export const BLOG_PAGINATION_NAVIGATE = "blog_pagination_navigate";

const routes = {
  posts: "/posts",
  tags: "/tags",
};
const headers = {};

export const fetchAllTags = (url) => async (dispatch) => {
  const fields = "?_fields[]=id&_fields[]=name&_fields[]=count";
  const perPage = "&per_page=100";
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: BLOG_FETCH_ALL_TAGS,
    });
    const data1 = await axios.get(
      `${url}${routes.tags}${fields}${perPage}`,
      headers
    );
    const data2 = await axios.get(
      `${url}${routes.tags}${fields}${perPage}&page=2`,
      headers
    );
    const dataMerged = [...data1.data, ...data2.data];
    dispatch({ type: BLOG_FETCH_ALL_TAGS, payload: dataMerged });
    dispatch({
      type: ACTION_SUCCESS,
      payload: "fetchSuccess",
    });
    endAndResetAction(dispatch);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const fetchPostsLatest = (url) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: BLOG_FETCH_POSTS_LATEST,
    });
    const res = await axios.get(`${url}${routes.posts}`, headers);
    dispatch({ type: BLOG_FETCH_POSTS_LATEST, payload: res.data });
    dispatch({
      type: ACTION_SUCCESS,
      payload: "fetchSuccess",
    });
    endAndResetAction(dispatch);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const fetchPostsByTag = (url, tag, history) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: BLOG_FETCH_POSTS_BY_TAG });
    const res = await axios.get(
      `${url}${routes.posts}?tags=${tag}&per_page=100`,
      headers
    );
    const fetchedPostsTags = [
      ...new Set(extractAndMergeArrays(res.data, "tags")),
    ];
    dispatch({
      type: ACTION_SUCCESS,
      payload: "fetchSuccess",
    });
    if (res.data.length > 10) {
      dispatch({
        type: BLOG_FETCH_POSTS_BY_TAG_AND_PAGINATE,
        payload: { entries: res.data, fetchedPostsTags, tag },
      });
    } else {
      dispatch({
        type: BLOG_FETCH_POSTS_BY_TAG,
        payload: { entries: res.data, fetchedPostsTags, tag },
      });
    }
    endAndResetAction(dispatch);
    history.push(`/userhome/blog/fetched_posts_by_tag/${tag}`);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const setSelectedPost = (post) => (dispatch) => {
  try {
    dispatch({ type: BLOG_SELECTED_POST, payload: post });
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const paginationNavigate = (page) => (dispatch) => {
  try {
    dispatch({ type: BLOG_PAGINATION_NAVIGATE, payload: page });
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

const blogActions = {
  fetchAllTags,
  fetchPostsLatest,
  fetchPostsByTag,
  setSelectedPost,
  paginationNavigate,
};

export default blogActions;
