import React from "react";
import AwardPreview from "./Award_Preview";
import BlingSettings from "./Bling_Settings";
import ColorSettings from "./Color_Settings";
import Buttons from "./New_Entry_Buttons";

const AwardEditor = (props) => {
  return (
    <div className="columns is-mobile is-multiline">
      <div className="column is-6-desktop is-12-mobile">
        <AwardPreview awardData={props.awardData} type={props.type} />
      </div>
      <div className="column is-6-desktop is-12-mobile">
        <Options {...props} />
        <br />
        <Buttons {...props} />
      </div>
    </div>
  );
};

const Options = (props) => {
  return (
    <div className="columns is-mobile">
      <div className="column">
        <div className="heading has-text-dark">
          {props.messages.detailingSectionTitles.blingSettings[props.locale]}
        </div>
        <BlingSettings {...props} />
      </div>
      <div className="column">
        <div className="heading has-text-dark">
          {props.messages.detailingSectionTitles.colorSettings[props.locale]}
        </div>
        <ColorSettings {...props} />
      </div>
    </div>
  );
};

export default AwardEditor;
