import React from "react"
import { connect } from "react-redux"
import Level from "components/layout/Level"
import LevelLeft from "components/layout/Level_Left"
import LevelRight from "components/layout/Level_Right"
import LevelItem from "components/layout/Level_Item"
import SubscribeNowButton from "components/elements/Subscribe_Now_Button"
import SiteLogo from "components/site_logo"
import { isMobileDevice } from "functions"

const Header = (props) => {
  const displayCondition =
    isMobileDevice() && props.location.pathname !== "/userhome"
      ? { display: "none" }
      : { paddingTop: 8 }
  return (
    <div style={displayCondition}>
      <Level className="is-mobile">
        <LevelLeft>
          <LevelItem>
            <SiteLogo
              pawWidth={45}
              nameWidth={100}
              isAuthed={props.isAuthed}
              role={props.role}
            />
          </LevelItem>
        </LevelLeft>
        <LevelRight>
          {!props.user.completeRegistrationRequired &&
            !props.user.verifyEmailRequired &&
            !props.user.userEmailRequired && (
              <LevelItem>
                <SubscribeNowButton subscribe />
              </LevelItem>
            )}
        </LevelRight>
      </Level>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuthed: state.reducers.auth.isAuthenticated,
    role: state.reducers.auth.role,
    user: state.reducers.auth.user,
  }
}

export default connect(mapStateToProps)(Header)
