import React from 'react';
import Intl from "components/Intl";

function HelpBullet(props) {
  return (
    <div className="columns has-text-weight-medium is-mobile">
      <div className="column is-1 has-text-right">{props.number}.</div>
      <div className="column is-4-desktop">
        <Intl message {...props} />
      </div>
      <div className="column is-2">
        {props.icon && (
          <div className="figure">
            <img
              className="image is-24x24"
              src={props.icon}
              alt={"bullet icon"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default HelpBullet;
