import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { diffInDaysReturnNumber } from "functions";
import ProfileDisplay from "components/styled/Profile_Display";
import Message from "components/message";

// move setup sub logic to here

const SummaryDetails = (props) => {
  const { messages, locale } = props;
  const FIELDS = [
    {
      label: messages.subscriptionSummary.labels.noActivePets[locale],
      value: returnData("noActivePets", props),
    },
    {
      label: messages.subscriptionSummary.labels.planDesc[locale],
      value: returnData("planDesc", props),
    },
    {
      label: messages.subscriptionSummary.labels.amountToPay[locale],
      value: returnData("amountToPay", props),
    },
    {
      label: messages.subscriptionSummary.labels.trialDaysRemaining[locale],
      value: returnData("trialDaysRemaining", props),
    },
  ];

  if (!props.billing.billingDataFetched) return null;

  return (
    <>
      <Message info message={"subscriptionSummary"} />
      <ProfileDisplay fields={FIELDS} />
    </>
  );
};

const returnData = (label, { pets, user, messages, billing, locale }) => {
  const petNames = () => {
    if (pets) {
      let petNames = Array.from(
        _.map(pets.profiles, ({ petName }, i) => {
          if (i === 0) {
            return petName;
          }
          return ` ${petName}`;
        })
      );
      return petNames;
    }
    return null;
  };
  const amountToPay = () => {
    const options = {
      style: "currency",
      currency: billing.billingData.plans.pricing.currency[locale],
    };
    const amount =
      billing.billingData.plans.pricing[
        user.subLevel
      ].withDecimal.toLocaleString(locale, options) +
      messages.plans.labels.perMonth[locale];
    return amount;
  };

  const trialDaysRemaining = diffInDaysReturnNumber(user.trialEnd);

  const prevSubDaysRemaining = diffInDaysReturnNumber(user.subEnd);

  switch (label) {
    case "noActivePets":
      return `${pets.numActivePets} ${petNames().toString()}`;
    case "planDesc":
      return user.subLevel === "none"
        ? "-"
        : messages.plans.labels[user.subLevel][locale];
    case "amountToPay":
      return trialDaysRemaining > 0 || prevSubDaysRemaining > 0
        ? amountToPay() +
            ` ${messages.subscriptionSummary.labels.dueWithTrialEnd[locale]}`
        : amountToPay();
    case "trialDaysRemaining":
      return prevSubDaysRemaining > 0 && !user.subscription && !user.trialPeriod
        ? prevSubDaysRemaining
        : user.trialPeriod && trialDaysRemaining > 0
        ? trialDaysRemaining
        : 0;
    default:
    // Do nothing
  }
};

const mapStateToProps = (state) => {
  return {
    messages: {
      subscriptionSummary:
        state.intl.messages.containers.subscription.subscriptionSummary,
      plans: state.intl.messages.containers.subscription.plans,
    },
    locale: state.intl.locale,
    billing: state.reducers.billing,
    user: state.reducers.auth.user,
    pets: state.reducers.pets,
  };
};

export default connect(mapStateToProps)(SummaryDetails);
