import assets from "assets";

import { LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD } from "functions/hostname";

const globalTiles = [
  {
    tileName: "TassuApu",
    externalLinks: [
      {
        url: "https://www.tassuapu.fi/",
        icon: "fas fa-globe",
      },
      {
        url: "https://www.facebook.com/tassuapu",
        icon: "fab fa-facebook-square",
      },
    ],
    tileImage: assets.globalTiles.tassuApuLogo,
    clients: [LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD],
    tileColor: "#00a0df",
  },
];

export default globalTiles;
