import {
  InputFieldV5,
  TextareaV5,
  SelectButtonsV5,
  ColorPicker,
  ExistingLitterMemberColors,
  UrlInputLinkV5,
  LitterMemberBirthWeight,
  LitterMemberBirthWeightLBS,
} from "components/form";

const INPUT_FIELDS = [
  {
    name: "litterMemberGivenName",
    group: "addLitterMember",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
    lastInGroup: true,
  },
  {
    name: "litterMemberSex",
    group: "addLitterMember",
    altName: "petSex",
    altGroup: "addPet",
    selectOptionsGroup: "petSex",
    icon: "fa fa-paw",
    className: "",
    component: SelectButtonsV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "litterMemberBreed",
    group: "addLitterMember",
    type: "text",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: false,
  },
  {
    name: "litterMemberPedigreeURL",
    group: "addLitterMember",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: UrlInputLinkV5,
    required: false,
    userCanEdit: true,
    lastInGroup: true,
  },
  {
    name: "litterMemberDob",
    group: "addLitterMember",
    type: "date",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "litterMemberDobTime",
    group: "addLitterMember",
    type: "time",
    icon: "fa fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
    lastInGroup: true,
  },
  {
    name: "litterMemberBirthWeightKg",
    group: "addLitterMember",
    type: "number",
    icon: "fas fa-weight",
    className: "input ",
    component: LitterMemberBirthWeight,
    required: false,
    userCanEdit: true,
    renderCondition: (unit) => unit === "kg",
  },
  {
    name: "litterMemberBirthWeightG",
    group: "addLitterMember",
    type: "number",
    icon: "fas fa-weight",
    className: "input ",
    component: LitterMemberBirthWeight,
    required: false,
    userCanEdit: true,
    renderCondition: (unit) => unit === "kg",
    lastInGroup: true,
  },
  {
    name: "litterMemberBirthWeightLbs",
    group: "addLitterMember",
    type: "number",
    icon: "fas fa-weight",
    className: "input ",
    component: LitterMemberBirthWeightLBS,
    required: false,
    userCanEdit: true,
    renderCondition: (unit) => unit === "lbs",
    lastInGroup: true,
  },
  {
    name: "litterMemberDesc",
    group: "addLitterMember",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 3,
    required: false,
    charLimit: 200,
    userCanEdit: true,
    lastInGroup: true,
  },
  {
    name: "litterMemberColorIdentifier",
    group: "addLitterMember",
    type: "",
    icon: "fa fa-paw",
    className: "",
    component: ColorPicker,
    required: true,
    userCanEdit: true,
  },
  {
    name: "existingLitterMemberColors",
    component: ExistingLitterMemberColors,
    lastInGroup: true,
  },
];

export { INPUT_FIELDS };
