import React from "react";
import { connect } from "react-redux";

const FilterBy = (props) => {
  return (
    <div className="field">
      <div className="control">
        <div className="select is-rounded is-small">
          <select
            onChange={(e) => props.setFilterBy(e, props.customFilterName)}
            value={props.filterBy}
            disabled={props.disabled}
          >
            <option value="">{props.filterByAll[props.locale]}</option>
            {props.filterByItems.map((item, i) => (
              <option key={i} value={item}>
                {props.filterByOptions[item][props.locale]}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    filterByAll: state.intl.messages.components.table.filterByOptions.all,
    filterByOptions: {
      ...state.intl.messages.components.table.filterByOptions,
      ...ownProps.messages,
    },
  };
};

export default connect(mapStateToProps)(FilterBy);
