import React, { useState } from "react";
import { connect } from "react-redux";
import { billing as actions } from "actions";
import { createOptions } from "./Card_Element";
import { CardElement, useStripe } from "@stripe/react-stripe-js";
import Message from "components/message";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";

function AddPaymentMethod({
  user,
  messages,
  locale,
  finalizePaymentMethod,
  history,
}) {
  // STRIPE HOOK
  const stripe = useStripe();

  // USE STATE
  //
  //
  const [validation, setValidation] = useState({ error: true, message: "" });
  const [formComplete, setFormComplete] = useState(false);
  const [element, setElement] = useState(null);
  const [authFail, setAuthFail] = useState(false);

  // FUNCTIONS
  //
  //
  function handleChange({ error, complete }) {
    if (!error) {
      setValidation({ error: false, message: "" });
    }
    if (error) {
      setValidation({ error: true, message: error.message });
    }
    if (!complete) {
      setFormComplete(false);
    }
    if (complete) {
      setFormComplete(true);
    }
  }

  function handleReady(ele) {
    setElement(ele);
  }

  async function submit(ev) {
    setAuthFail(false);
    const res = await stripe.handleCardSetup(user.stripeClientSecret, element);
    if (res.error) {
      return setAuthFail(true);
    }

    return finalizePaymentMethod(res.setupIntent, () =>
      history.push("/userhome/user_account/subscription")
    );
  }

  // MAIN RENDER
  //
  //
  return (
    <>
      {authFail && <Message warning message="authorizationFailed" />}
      <Message warning message="requiresPaymentMethod" />
      <div className="checkout notification" style={{ padding: 8 }}>
        <div className="columns is-2 is-variable is-flex-desktop is-mobile is-multiline">
          <div className="column is-6-desktop is-12-mobile is-flex-desktop">
            <div className="box" style={{ width: "100%" }}>
              <p>{messages.labels.enterCardDetails[locale]}</p>
              <br />
              <CardElement
                onChange={handleChange}
                options={createOptions()}
                onReady={(ele) => handleReady(ele)}
              />
              {validation.error && (
                <p className="is-danger help">{validation.message}</p>
              )}
            </div>
          </div>
          <div className="column is-6-desktop is-12-mobile is-flex-desktop">
            <div
              className="box"
              style={{
                width: "100%",
              }}
            >
              <Buttons>
                <Button
                  className="is-link is-fullwidth"
                  group="stripe"
                  name="updateCard"
                  onClick={submit}
                  disabled={validation.error || !formComplete ? true : false}
                />
              </Buttons>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(null, actions)(AddPaymentMethod);
