import { InputFieldV5, SelectV5, CheckboxV5 } from "components/form";

export const INPUT_FIELDS = [
  {
    name: "vatNumber",
    group: "providerRegister",
    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "password",
    group: "register",
    type: "password",
    icon: "fa fa-key",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "password2",
    group: "register",
    type: "password",
    icon: "fa fa-key",
    className: "input ",
    component: InputFieldV5,
    required: true,
    lastInGroup: true,
  },
  {
    name: "serviceName",
    group: "providerRegister",
    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "serviceType",
    group: "providerRegister",
    type: "select",
    icon: "fa fa-address-book",
    className: "select  is-fullwidth",
    component: SelectV5,
    required: true,
    lastInGroup: true,
  },
  {
    name: "email",
    group: "providerRegister",
    type: "email",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "firstname",
    group: "providerRegister",
    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "lastname",
    group: "providerRegister",
    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: true,
    lastInGroup: true,
  },
  {
    name: "streetAddress",
    group: "providerRegister",
    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "postcode",
    group: "providerRegister",
    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "city",
    group: "providerRegister",
    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "province",
    group: "providerRegister",
    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: true,
    lastInGroup: true,
  },
  {
    name: "customerReg",
    group: "register",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: true,
    withLink: true,
  },
  {
    name: "termsCond",
    group: "register",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: true,
    withLink: true,
  },
  {
    name: "privacyPolicy",
    group: "register",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: true,
    withLink: true,
  },
];
