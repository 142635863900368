import { intlReducer } from "react-intl-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import initialState from "./initialState";
import reducers from "./reducers";

const middleware = [thunk];

const composeEnhancers = composeWithDevTools({});

const store = createStore(
  combineReducers({
    reducers,
    intl: intlReducer,
  }),
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

if (process.env.NODE_ENV !== "production") {
  if (module.hot) {
    module.hot.accept("./reducers", () => {
      store.replaceReducer(reducers);
    });
  }
}

export default store;
