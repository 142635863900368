import React from "react"

function TileButtonImage({ imgObj, iconObj, useIcon }) {
  return (
    <div className="level">
      <div className="level-item">
        <figure className="image is-48x48">
          {useIcon ? (
            <span className="icon is-large">
              <i className={`${iconObj.icon} ${iconObj.size}`} />
            </span>
          ) : (
            <img src={imgObj.img} alt={imgObj.alt} />
          )}
        </figure>
      </div>
    </div>
  )
}

export default TileButtonImage
