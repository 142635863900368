import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { trainingDiary as actions } from "actions/index";
import moment from "moment";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { INPUT_FIELDS } from "./fields/training_diary";
import Button from "components/elements/Button";
import Buttons from "components/elements/Buttons";
import { FormTitle, FormButtons } from "components/form";

class TrainingDiaryEntryForm extends Component {
  state = { showAdditionalFields: false };

  renderFields(additionalFields) {
    const { viewMode, editMode, values, pet } = this.props;
    const { trainingTypes } = pet.trainingDiarySettings;
    return INPUT_FIELDS.map((field, i) => {
      if (!additionalFields && field.additionalField) return null;
      if (field.renderCondition && !field.renderCondition(values)) return null;
      return (
        <Field
          key={i}
          values={values}
          viewMode={viewMode}
          editMode={editMode}
          petType={pet.petType}
          userDefinedSelectOptions={trainingTypes}
          {...field}
        />
      );
    });
  }

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      viewMode,
      editMode,
      cancel,
      defaultRoute,
    } = this.props;
    const { showAdditionalFields } = this.state;
    return (
      <Form className="has-text-left">
        <FormTitle group={"trainingDiary"} />
        {this.renderFields(this.state.showAdditionalFields)}
        <br />
        <Buttons className="is-centered">
          <Button
            group="common"
            name={
              viewMode && !showAdditionalFields
                ? "showDetails"
                : viewMode && showAdditionalFields
                ? "showLess"
                : showAdditionalFields
                ? "showLess"
                : "showFormAdditionalFields"
            }
            onClick={() =>
              this.setState({
                showAdditionalFields: !this.state.showAdditionalFields,
              })
            }
          />
        </Buttons>
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={defaultRoute}
        />
        <br />
      </Form>
    );
  }
}

TrainingDiaryEntryForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet, editMode }) {
    return {
      date: moment(valuesToEdit.date).format("YYYY-MM-DD") || "",
      timeStart: valuesToEdit.timeStart || moment().format("HH:mm"),
      timeEnd: valuesToEdit.timeEnd || moment().add(1, "h").format("HH:mm"),
      trainingType: editMode
        ? valuesToEdit.trainingType
        : pet.trainingDiarySettings.savePreviousTrainingType
        ? pet.trainingDiarySettings.previousTrainingType
        : "",
      trainingTypeOther: valuesToEdit.trainingTypeOther || "",
      trainingSubject: valuesToEdit.trainingSubject || "",
      distance: valuesToEdit.distance || 0,
      time: valuesToEdit.time || "",
      agilityDistanceInMetres: valuesToEdit.agilityDistanceInMetres || 0,
      agilityTimeInSeconds: valuesToEdit.agilityTimeInSeconds || 0,
      agilityDistancePerSecond: valuesToEdit.agilityDistancePerSecond || 0,
      agilityMinimumSpeedInSeconds:
        valuesToEdit.agilityMinimumSpeedInSeconds || 0,
      agilityIdealTimeInSeconds: valuesToEdit.agilityIdealTimeInSeconds || 0,
      points: valuesToEdit.points || 0,
      trainersFeeling: valuesToEdit.trainersFeeling || 3,
      petsConcentration: valuesToEdit.petsConcentration || 3,
      trainerAndPetCoop: valuesToEdit.trainerAndPetCoop || 3,
      overalProgress: valuesToEdit.overalProgress || 3,
      notes: valuesToEdit.notes || "",
      attachmentURL: valuesToEdit.attachmentURL || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
      petType: valuesToEdit.petType || pet.petType,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    timeStart: Yup.string().required("required"),
    timeEnd: Yup.string().required("required"),
    trainingType: Yup.string().required("required"),
    trainingTypeOther: Yup.string().when("trainingType", {
      is: "other",
      then: Yup.string().required("required"),
      otherwise: Yup.string(),
    }),
    trainingSubject: Yup.string().max(30, "max-30"),
    distance: Yup.number(),
    time: Yup.string().max(30, "max-30"),
    agilityDistanceInMetres: Yup.number(),
    agilityTimeInSeconds: Yup.number(),
    agilityDistancePerSecond: Yup.number(),
    agilityMinimumSpeedInSeconds: Yup.number(),
    agilityIdealTimeInSeconds: Yup.number(),
    points: Yup.number(),
    trainersFeeling: Yup.number(),
    petsConcentration: Yup.number(),
    trainerAndPetCoop: Yup.number(),
    overalProgress: Yup.number(),
    notes: Yup.string().max(1200, "max-1200"),
    attachmentURL: Yup.string().url("url"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries`;
    if (props.editMode) {
      return props.updateTde(
        values,
        setSubmitting,
        props.history,
        successRoute,
        props.providerView
      );
    }
    return props.addTde(
      values,
      setSubmitting,
      props.history,
      successRoute,
      props.providerContact,
      props.providerView
    );
  },
})(TrainingDiaryEntryForm);

export default withRouter(connect(null, actions)(TrainingDiaryEntryForm));
