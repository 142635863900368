import React from "react"
import styled from "styled-components"

const CardNoticeWrap = styled.div`
  z-index: 1;
  padding: 4px;
  color: white;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  position: absolute;
  @media only screen and (max-width: 600px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media only screen and (min-width: 600px) {
    right: 0px;
  }
`

const CardNotice = (props) => <CardNoticeWrap>{props.children}</CardNoticeWrap>

export default CardNotice
