import React from "react"
import styled, { css } from "styled-components"

const BoxDiv = styled.div`
  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0px 0px 10px 0px hsl(171, 100%, 41%);
    `};
`

export default function TypeSelection(props) {
  return (
    <div id={props.id} className="columns is-multiline is-mobile">
      {props.types.map((type, i) => {
        if (type.petTypes.includes(props.petType || "all")) {
          return (
            <Type
              key={i}
              {...type}
              {...props}
              selected={type.name === props.state.selectedType}
            />
          )
        }
        return null
      })}
    </div>
  )
}

const Type = ({
  selected,
  name,
  img,
  title,
  messages,
  columnSizes,
  imgSize,
  handleTypeSelection,
}) => (
  <div
    className={
      "column " +
      (columnSizes ? columnSizes : "is-2-desktop is-3-tablet is-6-mobile")
    }
  >
    <BoxDiv
      selected={selected}
      className="box"
      onClick={() => handleTypeSelection(name)}
    >
      <figure className={"image " + (imgSize ? imgSize : "is-4by3")}>
        <img src={img} alt={name} />
      </figure>
      {title && (
        <p className="has-text-centered">
          {messages ? messages.types[name] : name}
        </p>
      )}
    </BoxDiv>
  </div>
)
