import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import PROVIDER_CONFIG from "./provider_config";

class PetProfile extends Component {
  render() {
    return <Menu {...PROVIDER_CONFIG} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    pet: state.reducers.pets.selectedPet,
    messages: state.intl.messages.containers.pets.petProfile,
    userPreferredUnit:
      state.reducers.contacts.selectedContact._user.featurePreferences
        .weightUnit,
  };
};

export default connect(mapStateToProps)(PetProfile);
