import React from 'react';
import Intl from "components/Intl";

function HelpTitle(props) {
  return (
    <div className="title is-5">
      <Intl message {...props} />
    </div>
  );
}

export default HelpTitle;
