// Main Imports
import axios from "axios"
import { ACTION_STARTED, ACTION_SUCCESS } from "actions/types"
import { catchBlockError, endAndResetAction } from "actions/functions"
import { whereAmI } from "functions"

// TYPES
export const ADS_FETCH = "ads_fetch"

const ROUTES = {
  fetch: "/api/offers_and_more/fetch_a",
}

export const fetch = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: ADS_FETCH })
    const res = await axios.get(ROUTES.fetch, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
      params: { client: whereAmI() },
    })
    dispatch({ type: ADS_FETCH, payload: res.data.advertisements })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "fetchSuccess",
    })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const advertisementsActions = {
  fetch,
}

export default advertisementsActions
