import { main } from "assets"
import Landing from "components/features/Landing"
import ProfileDetails from "./Profile_Details"

const litterProfileConfig = {
  name: "litterProfile",
  type: "litterFeature",
  defaultRoute: "/userhome/dashboard/litter/profile",
  menuIcon: { img: main.litterProfile, alt: "litter_profile_main_icon" },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: main.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "profileDetails",
      menuItem: true,
      image: {
        img: main.litterProfile,
        alt: "litter_profile_details_icon",
      },
      icon: "fas fa-copy",
      component: ProfileDetails,
      path: "/details",
    },
  ],
}

export default litterProfileConfig
