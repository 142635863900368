import { InputFieldV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "verifyEmailCode",
    group: "verifyEmail",
    type: "text",
    icon: "fa fa-key",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
];

export { INPUT_FIELDS };
