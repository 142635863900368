import React from "react";
import { connect } from "react-redux";

const NewEntryButtons = (props) => {
  return (
    <div className="buttons is-centered">
      <button
        className="button"
        disabled={props.isSubmitting}
        onClick={() => props.handleGoBack()}
      >
        {props.buttons.back[props.locale]}
      </button>
      {props.stage === 2 && (
        <button
          className="button is-info"
          onClick={() => props.handleConfirmStage2()}
        >
          {props.buttons.next[props.locale]}
        </button>
      )}
      {props.stage === 3 && (
        <button
          className={`button is-info ${props.isSubmitting && "is-loading"}`}
          type="submit"
          disabled={props.isSubmitting}
        >
          {props.buttons.submit[props.locale]}
        </button>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    buttons: state.intl.messages.components.buttons.common,
  };
};

export default connect(mapStateToProps)(NewEntryButtons);
