// React
import React from "react";

// Assets
import assets from "assets";

// HOCs
import RequireAuth from "components/hoc/Require_Auth";
import RequireSelectedPet from "components/hoc/Require_Selected_Pet";
import RequireSelectedLitter from "components/hoc/Require_Selected_Litter";

// Main
import Landing from "containers/auth/Landing";
import Login from "containers/auth/Login";
import Register from "containers/auth/Register";
import ForgotPassword from "containers/auth/Forgot_Password";
import Userhome from "containers/auth/Userhome";
import Logout from "containers/auth/Logout";
import Dashboard from "containers/main/Dashboard";
import FeedbackAndSupport from "containers/main/Feedback_And_Support";
import AddPet from "containers/pets/Add_Pet";
import PetProfile from "containers/pets/Pet_Profile";
import UserAccount from "containers/auth/User_Account";
import LitterDashboard from "containers/main/Litter_Dashboard";
import LitterMemberDashboard from "containers/main/Litter_Member_Dashboard";
import Blog from "containers/main/Blog/config";
import Advertisments from "containers/main/Advertisements/config";
import Benefits from "containers/main/Benefits/config";
import PetServicesPortal from "containers/auth/Pet_Services_Portal";
import ResetPassword from "containers/auth/Forgot_Password/ResetPassword";

const mainRoutes = [
  // **************************
  //
  // Main
  //
  // **************************
  {
    name: "landing",
    path: "/",
    exact: true,
    component: Landing,
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: false,
    img: {
      src: "",
      alt: "",
    },
  },
  {
    name: "login",
    path: "/login",
    exact: true,
    component: Login,
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: false,
    img: {
      src: assets.auth.login,
      alt: "login_logo",
    },
  },
  {
    name: "register",
    path: "/register",
    exact: true,
    component: Register,
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: false,
    img: {
      src: "",
      alt: "",
    },
  },
  {
    name: "forgotPassword",
    path: "/forgot_password",
    exact: true,
    component: () => <ForgotPassword user />,
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: false,
    img: {
      src: "",
      alt: "",
    },
  },
  {
    name: "resetPassword",
    path: "/reset_password",
    exact: true,
    component: () => <ResetPassword user />,
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: false,
    img: {
      src: "",
      alt: "",
    },
  },
  {
    name: "petServicesPortal",
    path: "/pet_services_portal",
    exact: true,
    component: PetServicesPortal,
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: false,
    img: {
      src: "",
      alt: "",
    },
  },
  {
    name: "userhome",
    path: "/userhome",
    exact: true,
    component: RequireAuth(Userhome),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    img: {
      src: assets.main.home,
      alt: "home_logo",
    },
  },
  {
    name: "support",
    path: "/userhome/feedback_and_support",
    exact: false,
    component: RequireAuth(FeedbackAndSupport),
    permaLink: true,
    breadcrumb: false,
    role: "user",
    showIfAuthed: true,
    img: {
      src: assets.main.feedbackAndSupport,
      alt: "support_logo",
    },
  },
  {
    name: "userAccount",
    path: "/userhome/user_account",
    exact: false,
    component: RequireAuth(UserAccount),
    permaLink: true,
    breadcrumb: false,
    role: "user",
    showIfAuthed: true,
    hideIfSignupIncomplete: true,
    img: {
      src: assets.user.main,
      alt: "user_main_logo",
    },
  },
  {
    name: "logout",
    path: "/userhome/logout",
    exact: false,
    component: RequireAuth(Logout),
    permaLink: true,
    breadcrumb: false,
    role: "user",
    showIfAuthed: true,
    img: {
      src: assets.auth.logout,
      alt: "logout_logo",
    },
  },
  {
    name: "logout",
    path: "/providerhome/logout",
    exact: false,
    component: RequireAuth(Logout),
    permaLink: true,
    breadcrumb: false,
    role: "provider",
    showIfAuthed: true,
    img: {
      src: assets.auth.logout,
      alt: "logout_logo",
    },
  },
  {
    name: "dashboard",
    path: "/userhome/dashboard",
    exact: true,
    component: RequireAuth(RequireSelectedPet(Dashboard)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    img: {
      src: assets.main.dashboard,
      alt: "dashboard_logo",
    },
  },
  {
    name: "addPet",
    path: "/userhome/add_pet",
    exact: false,
    component: RequireAuth(AddPet),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: false,
    img: {
      src: assets.pets.addPet,
      alt: "pets_add_pet_logo",
    },
  },
  {
    name: "petProfile",
    path: "/userhome/dashboard/pet_profile",
    exact: false,
    component: RequireAuth(RequireSelectedPet(PetProfile)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    img: {
      src: assets.pets.profile,
      alt: "pets_profile_logo",
    },
  },
  {
    name: "litter",
    path: "/userhome/dashboard/litter",
    exact: true,
    component: RequireAuth(RequireSelectedPet(LitterDashboard)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    img: {
      src: assets.features.litters.main,
      alt: "litter_dashboard_logo",
    },
  },
  {
    name: "litterMemberDashboard",
    path: "/userhome/dashboard/litter/litter_member_dashboard",
    exact: true,
    component: RequireAuth(RequireSelectedLitter(LitterMemberDashboard)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    img: {
      src: assets.main.litter,
      alt: "dashboard_logo",
    },
  },
  Blog.main,
  Advertisments.main,
  Benefits.main,
];

export default mainRoutes;
