import bcsDog1 from "./bcs_dog_1.svg";
import bcsDog2 from "./bcs_dog_2.svg";
import bcsDog3 from "./bcs_dog_3.svg";
import bcsDog4 from "./bcs_dog_4.svg";
import bcsDog5 from "./bcs_dog_5.svg";
import bcsDog6 from "./bcs_dog_6.svg";
import bcsDog7 from "./bcs_dog_7.svg";
import bcsDog8 from "./bcs_dog_8.svg";
import bcsDog9 from "./bcs_dog_9.svg";

const bcsDog = {
  bcsDog1,
  bcsDog2,
  bcsDog3,
  bcsDog4,
  bcsDog5,
  bcsDog6,
  bcsDog7,
  bcsDog8,
  bcsDog9,
};

export default bcsDog;
