import axios from "axios"

import {
  LITTER_USEFUL_LINKS_FETCH,
  LITTER_USEFUL_LINKS_ADD,
  LITTER_USEFUL_LINKS_UPDATE,
  LITTER_USEFUL_LINKS_DELETE,
  ACTION_STARTED,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
} from "./types"
import { catchBlockError } from "./functions"

export const fetchUsefulLinks = (_litter) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: LITTER_USEFUL_LINKS_FETCH })
    const res = await axios.post(
      "/api/litter_useful_links/fetch",
      { _litter },
      {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      }
    )
    dispatch({ type: ACTION_ENDED })
    dispatch({ type: LITTER_USEFUL_LINKS_FETCH, payload: res.data.usefulLinks })
    return dispatch({ type: ACTION_RESET })
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addUsefulLink = (values, setSubmitting, callback) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: LITTER_USEFUL_LINKS_ADD })
    const res = await axios.post("/api/litter_useful_links/add", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({ type: LITTER_USEFUL_LINKS_ADD, payload: res.data.usefulLink })
    dispatch({ type: ACTION_ENDED })
    setSubmitting(false)
    dispatch({ type: ACTION_RESET })
    callback()
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const updateUsefulLink = (values, setSubmitting, callback) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: LITTER_USEFUL_LINKS_UPDATE })
    const res = await axios.put("/api/litter_useful_links/update", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({ type: LITTER_USEFUL_LINKS_UPDATE, payload: res.data.usefulLink })
    dispatch({ type: ACTION_ENDED })
    setSubmitting(false)
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
    callback()
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const deleteUsefulLink = (id, callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: LITTER_USEFUL_LINKS_DELETE })
    const res = await axios.put(
      "/api/litter_useful_links/delete",
      { id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({ type: LITTER_USEFUL_LINKS_DELETE, payload: res.data.usefulLink })
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
    callback()
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const litterUsefulLinksActions = {
  fetchUsefulLinks,
  addUsefulLink,
  updateUsefulLink,
  deleteUsefulLink,
}

export default litterUsefulLinksActions
