import React from "react";
import assets from "assets";
import { getValueAtPath } from "functions";

function Image(props) {
  return (
    <figure
      className={`image is-${props.figureSize} ${props.figureClassName}`}
      style={{ ...props.imgContainerStyle }}
    >
      <img
        style={props.style}
        className={props.imgClassName}
        src={getValueAtPath(assets, props.imgPath)}
        alt={""}
      />
    </figure>
  );
}

export default Image;
