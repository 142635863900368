import React from "react";
import { connect } from "react-redux";
import inputValidationColor from "./Input_Validation_Color";
import FormValidationMessageV5 from "./FormValidationMessageV5";

function InputFieldV5(props) {
  const style2 = {
    border: `1px solid lightgrey`
  };
  const {
    field, form, type, icon, className, viewMode, editMode, max, min, step, userCanEdit, readOnly, label, placeholder, group, required,
  } = props;

  return (
    <div className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">
          {label}
          {!viewMode && required && "*"}
        </div>
      </label>
      <div className="control has-icons-left has-icons-right">
        <input {...field}
          type={type}
          readOnly={viewMode || readOnly || (!userCanEdit && editMode)}
          className={viewMode
            ? className
            : `${className} ${inputValidationColor(
                field.name,
                form.touched,
                form.errors
              )}`}
          max={max}
          min={min}
          step={step}
          placeholder={placeholder}
          style={type === "email" ? { textTransform: "lowercase" } : style2} />
        <span className="icon is-small is-left">
          <i className={icon} />
        </span>
      </div>
      <FormValidationMessageV5
        clientMessage
        group={group}
        name={field.name}
        errors={form.errors}
        touched={form.touched} />
    </div>
  );
}

const mapStateToProps = (state, { group, field }) => {
  const { locale, messages } = state.intl;
  return {
    label: messages.components.forms.labels[group][field.name][locale],
  };
};

export default connect(mapStateToProps)(InputFieldV5);
