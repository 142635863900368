export function getValueAtPath(obj, path) {
  // split the path into segments by .
  let segs = path.trim().split(".");
  // create a pointer to the obj
  let ptr = obj;

  // loop through each segment
  for (const element of segs) {
    // get the current segment
    let seg = element;

    // if the key doesn't exist at the pointer, return null
    if (ptr[seg] === undefined) return null;

    // key exists, so point to the new object
    ptr = ptr[seg];
  }

  // return the final value
  return ptr;
}

export function getValueFromArray(obj, path) {
  // split the path into segments by .
  let segs = path.trim().split(".");
  // create a pointer to the obj
  let ptr = obj;

  // loop through each segment
  for (let i = 0; i < segs.length; i++) {
    // get the current segment
    let seg = segs[i];

    // if the key doesn't exist at the pointer, return null
    if (ptr[seg] === undefined) return null;

    // key exists, so point to the new object
    ptr = ptr[seg];
  }

  // return the final value
  return ptr;
}

export function mergeArrays(arrays) {
  let merged = [].concat.apply([], arrays);
  return merged;
}

export function extractAndMergeArrays(arrayOfObjects, arrayKey) {
  let arrays = arrayOfObjects.map((obj) => obj[arrayKey]);
  let merged = [].concat.apply([], arrays);
  return merged;
}

export function returnNewObjectByValidKey(obj, validKey, locale) {
  const newObject = {};
  Object.keys(obj).forEach((key) => {
    if (key.includes(validKey)) newObject[key] = obj[key][locale];
  });
  return newObject;
}
export function returnNewObjectAsArrayByValidKey(obj, validKey, locale) {
  const newObject = {};
  Object.keys(obj).forEach((key) => {
    if (key.includes(validKey)) newObject[key] = obj[key][locale];
  });
  return Object.values(newObject);
}

export function returnNewObjectOfObjects(obj, validKey) {
  const newObject = {};
  Object.keys(obj).forEach((key) => {
    if (key.includes(validKey)) newObject[key] = obj[key];
  });
  return newObject;
}

export function returnNewObjectOfObjectsAsArray(obj, validKey) {
  const newObject = {};
  Object.keys(obj).forEach((key) => {
    if (key.includes(validKey)) newObject[key] = Object.values(obj[key]);
  });
  return newObject;
}

export function checkIfThereIsOnlyOneElement(arr) {
  arr.sort();
  return arr[0] === arr[arr.length - 1];
}

export function replaceObjectInArrayOfObjects(array, object) {
  let index = array.findIndex(({ _id }) => _id === object._id);

  if (index === -1) {
    array.push(object);
  } else {
    array[index] = object;
  }

  return array;
}
