import axios from "axios"
import moment from "moment"
import {
  REPORTING_FETCH_TEMPLATES,
  REPORTING_FETCH_REQUESTS,
  REPORTING_SUBMIT_REQUEST,
  REPORTING_REFRESH_STATUS,
  ACTION_STARTED,
  ACTION_ERROR,
  ACTION_ENDED,
  ACTION_RESET,
} from "./types"

export const fetchTemplates = () => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: REPORTING_FETCH_TEMPLATES })
    const res = await axios.get("/api/reporting/fetch_templates", {
      headers: { authorization: localStorage.getItem("token") },
    })

    dispatch({
      type: REPORTING_FETCH_TEMPLATES,
      payload: res.data.reportTemplates,
    })

    dispatch({ type: ACTION_ENDED })
    dispatch({ type: ACTION_RESET })
  } catch (error) {
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const fetchRequests = (pet) => async (dispatch) => {
  const dates = {
    from: moment().subtract(10, "d").toISOString(),
    to: moment().toISOString(),
  }
  try {
    dispatch({ type: ACTION_STARTED, payload: REPORTING_FETCH_REQUESTS })
    const res = await axios.get("/api/reporting/fetch_requests", {
      headers: { authorization: localStorage.getItem("token") },
      params: { _pet: pet._id, ...dates },
    })
    dispatch({
      type: REPORTING_FETCH_REQUESTS,
      payload: res.data.requests,
      pet,
    })
    dispatch({ type: ACTION_ENDED })
  } catch (error) {
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const submitRequest = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: REPORTING_SUBMIT_REQUEST })
    const res = await axios.post("/api/reporting/submit_request", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({ type: REPORTING_SUBMIT_REQUEST, payload: res.data.report })
    return setTimeout(() => {
      dispatch({ type: ACTION_RESET })
      history.push(successRoute)
    }, 2000)
  } catch (error) {
    setSubmitting(false)
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const refreshStatus = (id) => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: REPORTING_REFRESH_STATUS })
  try {
    const res = await axios.post(
      "/api/reporting/refresh_status",
      { id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    setTimeout(() => {
      dispatch({
        type: REPORTING_REFRESH_STATUS,
        payload: res.data.report,
      })
      dispatch({ type: ACTION_RESET })
    }, 1000)
  } catch (error) {
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

const reportingActions = {
  fetchTemplates,
  fetchRequests,
  submitRequest,
  refreshStatus,
}

export default reportingActions
