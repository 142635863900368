import React from "react";

export default function WeightWithTextIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 600 600"
      width={600}
      height={600}
      {...props}
    >
      <title>weight_review_fetch_icon</title>
      <g id="Layer_45" data-name="Layer 45">
        <rect
          x="0.04"
          y="-0.16"
          width={600}
          height={600}
          fill="none"
          opacity={0}
        />
      </g>
      <g id="weight_review_fetch_icon">
        <g id="bg">
          <path
            d="M598.56,205.74q-31.62,184.52-63.22,369c-2.4,14-14.16,24.28-27.9,24.28H92.56c-13.74,0-25.5-10.24-27.9-24.28L52.2,502l-10-58.45L1.44,205.75c-2.5-14.61,5.84-28.85,19.47-33.25a896.86,896.86,0,0,1,109.35-27.91l.12,0c19-3.6,39.11-6.81,60.33-9.36l.38,0A907.09,907.09,0,0,1,288,128.72h.12c2.44,0,4.89-.05,7.35-.07h.24c2.61,0,5.22,0,7.86,0h.12c1.15,0,2.31,0,3.48,0a907.19,907.19,0,0,1,94.3,5.63l.4,0c21.18,2.39,41.35,5.41,60.39,8.86l.13,0a898.08,898.08,0,0,1,116.71,29.29C592.74,176.92,601.06,191.16,598.56,205.74Z"
            fill="#7effaa"
          />
          <path
            d="M464.29,169.34A167.86,167.86,0,0,1,296.43,337.2h-.95A168,168,0,0,1,130.26,145.38l.12-.81A167.89,167.89,0,0,1,295.48,1.49h.24c.24,0,.46,0,.71,0A167.93,167.93,0,0,1,462.27,143.2c0,.29.09.57.13.87A168.42,168.42,0,0,1,464.29,169.34Z"
            fill="#7effaa"
          />
        </g>
        <g id="bg-2" data-name="bg" opacity="0.5">
          <path
            d="M599.56,205.74q-31.62,184.52-63.22,369c-2.4,14-14.16,24.28-27.9,24.28H93.56c-13.74,0-25.5-10.24-27.9-24.28L53.2,502l-10-58.45L2.44,205.75c-2.5-14.61,5.84-28.85,19.47-33.25a896.86,896.86,0,0,1,109.35-27.91l.12,0c19-3.6,39.11-6.81,60.33-9.36l.38,0A907.09,907.09,0,0,1,289,128.72h.12c2.44,0,4.89-.05,7.35-.07h.24c2.61,0,5.22,0,7.86,0h.12c1.15,0,2.31,0,3.48,0a907.19,907.19,0,0,1,94.3,5.63l.4,0c21.18,2.39,41.35,5.41,60.39,8.86l.13,0a898.08,898.08,0,0,1,116.71,29.29C593.74,176.92,602.06,191.16,599.56,205.74Z"
            fill="#00d89a"
          />
          <path
            d="M465.29,169.34A167.86,167.86,0,0,1,297.43,337.2h-.95A168,168,0,0,1,131.26,145.38l.12-.81A167.89,167.89,0,0,1,296.48,1.49h.24c.24,0,.46,0,.71,0A167.93,167.93,0,0,1,463.27,143.2c0,.29.09.57.13.87A168.42,168.42,0,0,1,465.29,169.34Z"
            fill="#00d89a"
          />
        </g>
        <g id="centre">
          <path
            d="M545.3,233.94q-26,151.6-51.94,303.18c-2,11.53-11.63,20-22.92,20H129.56c-11.29,0-21-8.42-22.92-20L96.4,477.35l-8.23-48L54.7,234c-2.06-12,4.79-23.71,16-27.32a736,736,0,0,1,89.85-22.94h.1c15.58-3,32.13-5.59,49.57-7.69a3,3,0,0,1,.31,0,744.77,744.77,0,0,1,79.63-5.3h.1c2,0,4,0,6-.06h9.62a746.32,746.32,0,0,1,77.48,4.63l.33,0c17.4,2,34,4.45,49.62,7.29h.11a739.26,739.26,0,0,1,95.88,24.06C540.52,210.26,547.36,222,545.3,233.94Z"
            fill="#7effaa"
          />
        </g>
        <g id="meter">
          <circle cx="296.43" cy="169.34" r="110.72" fill="#f2f2f2" />
          <path
            d="M305.18,147.82a22.74,22.74,0,0,0-9.46-2.06h-.24a22.85,22.85,0,0,0,0,45.7h.24a22.86,22.86,0,0,0,9.46-43.66Zm-9.46,30.81h-.24a10,10,0,0,1,0-20h.24a10,10,0,0,1,0,20Z"
            fill="#ff75b0"
          />
          <path
            d="M293.33,66.17l-7.1,85.42a2.53,2.53,0,0,0,2.52,2.75H303a2.54,2.54,0,0,0,2.53-2.75l-7.11-85.42A2.53,2.53,0,0,0,293.33,66.17Z"
            fill="#ff75b0"
          />
        </g>
        <text
          transform="translate(300 450)"
          fontSize={70}
          fill="#00a0df"
          fontFamily="Roboto-Regular, Roboto"
          textAnchor="middle"
        >
          {props.label}
        </text>
      </g>
    </svg>
  );
}
