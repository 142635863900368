import React, { useEffect } from "react";
import * as actions from "./actions";
import { connect } from "react-redux";
import moment from "moment";
import AdsAndBenefits from "components/features/Ads_And_Benefits";

function Benefits({ benefits, fetch, messages, locale, dispatch }) {
  useEffect(() => {
    if (!benefits) {
      fetch();
    }
  }, [benefits, fetch]);

  if (!benefits) return null;

  return (
    <div className="columns is-variable is-2 is-mobile is-centered is-multiline is-marginless">
      {benefits.items.map((be, i) => (
        <div
          key={be._id}
          className="column is-12-mobile is-4-desktop is-3-tabelt has-text-centered is-flex"
        >
          <AdsAndBenefits {...be} dispatch={dispatch} />
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => {
  const benefits =
    state.reducers.benefits.items.length === 0 ||
    moment(state.reducers.benefits.lastFetch).diff(moment(), "hours") > 1
      ? false
      : state.reducers.benefits;
  return {
    locale: state.intl.locale,
    messages: state.intl.messages.containers.main.benefits,
    benefits,
  };
};

export default connect(mapStateToProps, actions)(Benefits);
