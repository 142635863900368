import main from "./hcpi.svg";
import newQuery from "./hcpi_new_entry.svg";
import viewResults from "./hcpi_view_entries.svg";

const hcpi = {
  main,
  newQuery,
  viewResults,
};
export default hcpi;
