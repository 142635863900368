const dashboardConfig = {
  layout: {
    columns: "columns is-2 is-variable is-mobile is-multiline is-centered",
    column: "column is-2-desktop is-4-mobile is-4-tablet",
  },
  featureOrder: [
    "appointments",
    "tasks",
    "hcpi",
    "fmpi",
    "bodyMarker",
    "toothMarker",
    "stableDiary",
    "hoofCare",
    "healthDiary",
    "homeCare",
    "weight",
    "foodDiary",
    "activity",
    "trainingDiary",
    "stressLevel",
    "vaccinations",
    "parasites",
    "heatDiary",
    "usefulLinks",
    "awardCabinet",
    "reporting",
    "animalHospital",
    "litters",
  ],
  featureOrderProviderView: [
    "appointments",
    "tasks",
    "hcpi",
    "fmpi",
    "bodyMarker",
    "toothMarker",
    "healthDiary",
    "homeCare",
    "weight",
    "foodDiary",
    "activity",
    "trainingDiary",
    "stressLevel",
    "vaccinations",
    "parasites",
    "usefulLinks",
  ],
};

export default dashboardConfig;
