import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { parasites as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./fields/litter_parasites";
import FormButtons from "components/form/FormButtons";

class LitterParasiteForm extends Component {
  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (!values.sideEffectsObserved && field.name === "sideEffectsDesc")
        return null;
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  }

  render() {
    const { errors, dirty, isSubmitting, viewMode, editMode, cancel } =
      this.props;
    return (
      <Form>
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={"/userhome/dashboard/litter/parasites"}
        />
        <br />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    _litter: state.reducers.litters.selectedLitter._id,
  };
};

LitterParasiteForm = withFormik({
  mapPropsToValues({ _litter }) {
    return {
      date: moment().format("YYYY-MM-DD"),
      expiry: moment().add(6, "months").format("YYYY-MM-DD"),
      parasiteType: "",
      productUsed: "",
      _litter,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    expiry: Yup.date().required("required"),
    parasiteType: Yup.string().required("required"),
    productUsed: Yup.string().required("required").max(30, "max-30"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    return props.addParasiteForLitter(
      values,
      props.litterMembers,
      setSubmitting,
      () =>
        props.history.push("/userhome/dashboard/litter/parasites/view_entries")
    );
  },
})(LitterParasiteForm);

export default withRouter(
  connect(mapStateToProps, actions)(LitterParasiteForm)
);
