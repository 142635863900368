import React from "react"
import { features } from "assets"
import Landing from "components/features/Landing"
import NewEntry from "./views/New_Entry"
import ViewEntries from "./views/View_Entries"
import ViewEntry from "./views/View_Entry"
import { actionTypes } from "./actions"

const stressLevelConfig = {
  name: "stressLevel",
  type: "feature",
  defaultRoute: "/userhome/dashboard/stress_level",
  actionTypes,
  menuIcon: {
    img: features.stressLevel.main,
    alt: "features.stressLevel.main",
  },
  nav: [
    {
      name: "landing",
      disabled: false,
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "features_info_menu_icon",
      },
      icon: "",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      name: "newEntry",
      disabled: false,
      menuItem: true,
      image: {
        img: features.stressLevel.newEntry,
        alt: "features.stressLevel.newEntry",
      },
      icon: "",
      component: (props) => <NewEntry {...props} />,
      path: "/new_entry",
    },
    {
      name: "viewEntries",
      disabled: false,
      menuItem: true,
      image: {
        img: features.stressLevel.viewEntries,
        alt: "features.stressLevel.viewEntries",
      },
      icon: "",
      component: (props) => <ViewEntries {...props} />,
      path: "/view_entries",
    },
    {
      name: "viewEntry",
      disabled: false,
      menuItem: false,
      image: {
        img: features.stressLevel.main,
        alt: "features.stressLevel.main",
      },
      icon: "",
      component: (props) => <ViewEntry {...props} />,
      path: "/view_entry/:id",
    },
  ],
  fetchButtons: [
    "last7Days",
    "last14Days",
    "last30Days",
    "last3Months",
    "last1Year",
  ],
  tableView: {
    headers: ["date", "subject", "stressLevel"],
    mobileHeaders: ["date", "subject", "stressLevel"],
    viewEntryTo: "/userhome/dashboard/stress_level/view_entry/",
    itemsPerPage: 10,
  },
}

export default stressLevelConfig
