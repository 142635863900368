import React from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";
import * as actions from "./actions";

function BodyMarker(props) {
  return <Menu {...config} {...props} />;
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.bodyMarker,
    },
    bodyMarker: state.reducers.bodyMarker,
  };
};

export default connect(mapStateToProps, actions)(BodyMarker);
