import React from "react";
import { connect } from "react-redux";
import BoxWithBackground from "components/styled/Box_With_Background";
import RetrievePetForm from "forms/Retrieve_Pet_Form";
import AddPetForm from "forms/Add_Pet_Form";
import Section from "components/layout/Section";
import _ from "lodash";

function RetrieveNewPet(props) {
  return (
    <BoxWithBackground>
      <Section>
        {!props.petForTransfer ? (
          <RetrievePetForm
            valuesToEdit={{}}
            history={props.history}
            cancel={props.cancel}
          />
        ) : (
          <AddPetForm
            transferPet
            petTransferId={props.petTransferObject._id}
            valuesToEdit={props.petForTransfer}
            history={props.history}
            cancel={props.cancel}
          />
        )}
      </Section>
    </BoxWithBackground>
  );
}

const mapStateToProps = (state) => {
  const petForTransfer = _.isEmpty(state.reducers.transferPet.petForTransfer)
    ? false
    : state.reducers.transferPet.petForTransfer;
  return {
    petForTransfer,
    petTransferObject: state.reducers.transferPet.petTransferObject,
  };
};

export default connect(mapStateToProps)(RetrieveNewPet);
