import { InputFieldV5, TextareaV5 } from "components/form";

export const INPUT_FIELDS = [
  {
    name: "receiverEmail",
    group: "inviteContact",
    type: "email",
    icon: "fa fa-calendar",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "codeForReceiver",
    group: "inviteContact",
    type: "text",
    icon: "fa fa-calendar",
    className: "input ",
    component: InputFieldV5,
    required: false,
    charLimit: 20,
  },
  {
    name: "messageForReceiver",
    group: "inviteContact",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    charLimit: 250,
  },
];
