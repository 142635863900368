import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { healthDiary as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "forms/fields/medicine_entry";
import FormButtons from "components/form/FormButtons";

class MedicineEntryForm extends Component {
  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (
        !values.onGoing &&
        (field.name === "dateEnd" || field.name === "frequency")
      )
        return null;
      if (values.onGoing && field.name === "time") return null;
      return (
        <div className="field" key={i}>
          <Field
            key={i}
            viewMode={viewMode}
            editMode={editMode}
            values={values}
            {...field}
          />
          {field.lastInGroup && <br />}
        </div>
      );
    });
  }

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      viewMode,
      editMode,
      cancel,
      defaultRoute,
    } = this.props;
    return (
      <Form>
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={defaultRoute}
        />
        <br />
      </Form>
    );
  }
}

MedicineEntryForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet }) {
    return {
      type: "medicine",
      onGoing: valuesToEdit.onGoing || false,
      date:
        moment(valuesToEdit.date).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      time: valuesToEdit.time || moment().format("HH:mm"),
      dateEnd: valuesToEdit.dateEnd
        ? moment(valuesToEdit.dateEnd).format("YYYY-MM-DD")
        : "",
      medicine: valuesToEdit.medicine || "",
      medicineAmountUnit: valuesToEdit.medicineAmountUnit || "gram",
      medicineAmount: valuesToEdit.medicineAmount || 0,
      frequency: valuesToEdit.frequency || "",
      reasonForMedicine: valuesToEdit.reasonForMedicine || "",
      withdrawalPeriod: valuesToEdit.withdrawalPeriod || "",
      prescribedBy: valuesToEdit.prescribedBy || "",
      provider: valuesToEdit.provider || "",
      notes: valuesToEdit.notes || "",
      sideEffects: valuesToEdit.sideEffects || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    onGoing: Yup.boolean(),
    date: Yup.date().required("required"),
    time: Yup.string().required("required"),
    medicine: Yup.string().required("required"),
    medicineAmountUnit: Yup.string().required("required"),
    medicineAmount: Yup.number().required("required"),
    dateEnd: Yup.date().when("onGoing", {
      is: true,
      then: Yup.date(),
      otherwise: Yup.date(),
    }),
    frequency: Yup.string().when("onGoing", {
      is: true,
      then: Yup.string().max(30, "max-30"),
      otherwise: Yup.string(),
    }),
    reasonForMedicine: Yup.string().max(30, "max-30"),
    withdrawalPeriod: Yup.string().max(30, "max-30"),
    prescribedBy: Yup.string().max(30, "max-30"),
    provider: Yup.string().max(30, "max-30"),
    notes: Yup.string().max(500, "max-500"),
    sideEffects: Yup.string().max(120, "max-120"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries${
      props.type !== "litterMemberFeature" ? "_medicine" : ""
    }`;
    if (props.editMode) {
      return props.updateHealthDiaryEntry(
        values,
        setSubmitting,
        props.history,
        successRoute,
        props.providerView
      );
    }
    props.addHealthDiaryEntry(
      values,
      setSubmitting,
      props.history,
      successRoute,
      props.providerContact,
      props.providerView
    );
  },
})(MedicineEntryForm);

export default withRouter(connect(null, actions)(MedicineEntryForm));
