import React, { Component } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import MenuIcon from "./components/Menu_Icon"
import SideMenu from "./components/Side_Menu"
import RoutedItems from "./components/Routed_Items"
import BoxWithBackground from "components/styled/Box_With_Background"

class MenuDesktop extends Component {
  constructor(props) {
    super(props)
    this.top = React.createRef()
  }

  componentDidMount() {
    this.scrollToMyRef()
  }

  scrollToMyRef = () => {
    window.scrollTo({
      top: this.top.current.offsetTop + 10,
      behavior: "auto",
    })
  }

  render() {
    return (
      <div className="columns is-3 is-variable">
        <div className="column is-2-desktop">
          <BoxWithBackground padding={10}>
            <MenuIcon
              menuIcon={this.props.menuIcon}
              petName={this.props.pet ? this.props.pet.petName : ""}
              useProfileImage={this.props.pet.useImageForProfile}
              profileImage={
                !_.isEmpty(this.props.pet.profileImageObject)
                  ? this.props.pet.profileImageObject.secure_url
                  : {}
              }
              feature={
                this.props.type === "feature" ||
                this.props.type === "litterMemberFeature" ||
                this.props.type === "petProfile"
                  ? true
                  : false
              }
            />
          </BoxWithBackground>
          {!this.props.noNav && (
            <BoxWithBackground padding={10}>
              <SideMenu {...this.props} />
            </BoxWithBackground>
          )}
        </div>
        <div className="column">
          <BoxWithBackground padding={8}>
            <RoutedItems
              ref={this.top}
              routedItems={this.props.nav}
              {...this.props}
            />
          </BoxWithBackground>
        </div>
      </div>
    )
  }
}

export default MenuDesktop

MenuDesktop.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  defaultRoute: PropTypes.string,
  menuIcon: PropTypes.object,
  nav: PropTypes.array,
  routes: PropTypes.object,
}
