import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import PainIndexDataView from "./PainIndexDataView";

function PainIndexTracker(props) {
  const { fetchDataForPainTracker } = props;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      fetchDataForPainTracker();
    }
    return () => (mounted = false);
  }, [fetchDataForPainTracker]);

  return (
    <div>
      <PainIndexDataView {...props} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    painData: state.reducers.providerDashboard.painData,
    messages:
      state.intl.messages.containers.contacts.providerContacts.dataTrackers,
    locale: state.intl.locale,
    contacts: state.reducers.contacts.contacts,
  };
};

export default connect(mapStateToProps, actions)(PainIndexTracker);
