export const blue = {
  name: "blue",
  className: "is-info",
  settings: {
    defaultBox: {
      background: "#8DCCF6",
    },
    hr: "#898989",
    accordion: { entry: "is-primary", footer: "is-dark" },
  },
}

export const red = {
  name: "red",
  className: "is-danger",
  settings: {
    defaultBox: {
      background: "#FF8FA5",
    },
    hr: "#898989",
    accordion: { entry: "is-primary", footer: "is-dark" },
  },
}

export const green = {
  name: "green",
  className: "is-success",
  settings: {
    defaultBox: {
      background: "#87E5A8",
    },
    hr: "#898989",
    accordion: { entry: "is-primary", footer: "is-dark" },
  },
}

export const yellow = {
  name: "yellow",
  className: "is-warning",
  settings: {
    defaultBox: {
      background: "#FFEC9E",
    },
    hr: "#898989",
    accordion: { entry: "is-primary", footer: "is-dark" },
  },
}
//#f5fffc was default box primary is now #c2ffed
export const primary = {
  name: "primary",
  className: "is-primary",
  settings: {
    defaultBox: {
      background: "#f5fffc",
    },
    hr: "#898989",
    accordion: { entry: "is-primary", footer: "is-primary" },
  },
}

const themes = {
  blue,
  red,
  green,
  yellow,
  primary,
}

export default themes
