import React from "react";
import { connect } from "react-redux";
import FadeInAnimation from "components/gsap/FadeInAnimation";

function ToastNotification(props) {
  return (
    <div
      style={{
        zIndex: 20,
        position: "fixed",
        width: "100%",
        maxWidth: "inherit",
      }}
    >
      <FadeInAnimation direction="down">
        <div className={`notification is-light ${props.color}`}>
          {props.messages[props.type][props.message][props.locale]}
        </div>
      </FadeInAnimation>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.components.messages,
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(ToastNotification);
