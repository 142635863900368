import React from "react"
import { auth } from "assets"

export default function PoweredByStripeLogo() {
  return (
    <a rel="noopener noreferrer" href={"https://stripe.com"} target="_blank">
      <figure className="image is-96x96">
        <img src={auth.poweredByStripe} alt="powered_by_stripe_logo" />
      </figure>
    </a>
  )
}
