const contentfulData = {
  partners: {
    SPACE_ID: "ybvn05oa5ere",
    ACCESS_TOKEN: "QU1y5cVG8xmpV5_b9e8oUcRehdKRD_-K69rJ8K_5bF0",
  },
  tipsAndGuides: {
    SPACE_ID: "bhjaqtjjekbh",
    ACCESS_TOKEN:
      "a4ae2c7f14a7e1c3c872fa259f3fca14f6c6a0ee1960ec6197c08c6b23dc2202",
  },
}

export default contentfulData
