import React, { Component } from "react"
import Section from "components/layout/Section"
import FetchButtons from "./Fetch_Buttons"

class ChartsView extends Component {
  render() {
    return (
      <Section>
        <FetchButtons />
      </Section>
    )
  }
}

export default ChartsView
