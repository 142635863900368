import React from "react";
import WeightForm from "forms/Weight_Form";
import RequireSubFeature from "components/hoc/Require_Sub_Feature";
import FormWrapper from "components/layout/Form_Wrapper";

function NewEntry(props) {
  return (
    <FormWrapper>
      <WeightForm valuesToEdit={{}} {...props} />
    </FormWrapper>
  );
}

export default RequireSubFeature(NewEntry);
