import _ from "lodash"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import * as actions from "../actions"
import ProviderContact from "../components/ProviderContact"
import UserPets from "../components/UserPets"

function ContactAndPets(props) {
  const {
    contact,
    fetchContactsPets,
    pets: { profiles, providerContacts },
    history,
  } = props

  useEffect(() => {
    if (_.isEmpty(contact)) {
      return history.replace("/providerhome/contacts")
    }
    if (!_.isEmpty(contact)) {
      if (!providerContacts.includes(contact._user._id)) {
        fetchContactsPets(contact._user._id)
      }
    }
  }, [fetchContactsPets, providerContacts, contact, history])

  if (_.isEmpty(contact)) return null

  return (
    <div>
      <ProviderContact {...contact} petView />
      <UserPets pets={profiles.filter((p) => p._user === contact._user._id)} />
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    contact: state.reducers.contacts.selectedContact,
    pets: state.reducers.pets,
  }
}

export default connect(mapStateToProps, actions)(ContactAndPets)
