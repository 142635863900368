import React, { Component } from "react";
import { connect } from "react-redux";
import { displayDateRange } from "functions/moment";
import ProfileDisplay from "components/styled/Profile_Display";

class SubscriptionDetails extends Component {
  renderContent() {
    const { user, locale } = this.props;
    const messages = this.props.messages.currentStatus;
    const FIELDS = [
      {
        label: messages.labels.status[locale],
        value: user.subscription
          ? messages.subStatuses.active[locale]
          : user.subscriptionEnding
          ? messages.subStatuses.cancelled[locale]
          : messages.subStatuses.inactive[locale],
      },
      {
        label: messages.labels.subType[locale],
        value:
          user.subLevel === "trial"
            ? messages.subLevels.freeTrial[locale]
            : user.subLevel === "blue"
            ? messages.subLevels.blue[locale]
            : user.subLevel === "copper"
            ? messages.subLevels.copper[locale]
            : user.subLevel === "silver"
            ? messages.subLevels.silver[locale]
            : user.subLevel === "gold"
            ? messages.subLevels.gold[locale]
            : user.subLevel === "platinum"
            ? messages.subLevels.platinum[locale]
            : user.subLevel === "diamond" && messages.subLevels.diamond[locale],
      },
      {
        label: messages.labels.trialPeriod[locale],
        value: user.trialPeriod
          ? displayDateRange(user.trialStart, user.trialEnd)
          : null,
      },
      {
        label: messages.labels.billingPeriod[locale],
        value:
          user.subscription || user.subscriptionEnding
            ? `${displayDateRange(user.subStart, user.subEnd)} ${
                user.stripeTrial
                  ? `(${messages.subStatuses.trialling[locale]})`
                  : ""
              }`
            : null,
      },
      {
        label: messages.labels.cardStatus[locale],
        value:
          !user.stripeCustomerId || !user.subscription
            ? null
            : user.stripeCustomerId && user.cardExpiring
            ? messages.cardStatuses.cardExpiring[locale]
            : user.stripeCustomerId && user.stripeRequiresAuth
            ? messages.cardStatuses.scaRequired[locale]
            : user.stripeCustomerId && user.stripeRequiresPaymentMethod
            ? messages.cardStatuses.requiresPaymentMethod[locale]
            : messages.cardStatuses.paymentMethodValid[locale],
      },
      {
        label: messages.labels.cardLast4[locale],
        value: !user.subscription
          ? null
          : user.stripeLast4
          ? `xxxx-xxxx-xxxx-${user.stripeLast4}`
          : null,
      },
    ];

    return <ProfileDisplay fields={FIELDS} />;
  }

  render() {
    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.reducers.auth.user,
    locale: state.intl.locale,
    messages: state.intl.messages.containers.subscription,
  };
};

export default connect(mapStateToProps)(SubscriptionDetails);
