import componentsJSON from "./components.json";
import elementsJSON from "./elements.json";
import formsJSON from "./forms.json";
import featuresJSON from "./features.json";
import authJSON from "./auth.json";
import providersJSON from "./providers.json";
import contactsJSON from "./contacts.json";
import subscriptionJSON from "./subscription.json";
import petsJSON from "./pets.json";
import litterFeaturesJSON from "./litter_features.json";
import litterMemberFeaturesJSON from "./litter_member_features.json";
import mainJSON from "./main.json";

const messages = {
  components: {
    ...componentsJSON.components,
    ...elementsJSON.elements,
    ...formsJSON,
  },
  containers: {
    ...mainJSON,
    ...featuresJSON,
    ...authJSON,
    ...providersJSON,
    ...contactsJSON,
    ...subscriptionJSON,
    ...petsJSON,
    ...litterFeaturesJSON,
    ...litterMemberFeaturesJSON,
  },
};

export default messages;
