import { InputFieldV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "email",
    group: "login",
    type: "email",
    icon: "fa fa-envelope",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "password",
    group: "login",
    type: "password",
    icon: "fa fa-key",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
];

export { INPUT_FIELDS };
