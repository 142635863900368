import React, { Component } from "react";
import { main, auth } from "assets";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AUTH_LOG_OUT_USER } from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";
import Section from "components/layout/Section";
import BoxWithBackground from "components/styled/Box_With_Background";
import Intl from "components/Intl";

class Logout extends Component {
  render() {
    const { role } = this.props;
    const linkToRole = () =>
      role === "user" ? "/userhome" : role === "provider" && "/providerhome";
    const logOutType = () =>
      role === "user"
        ? AUTH_LOG_OUT_USER
        : role === "provider" && AUTH_LOG_OUT_PROVIDER;
    return (
      <Section>
        <div className="columns is-2 is-variable is-mobile is-centered is-multiline">
          <div className="column is-2-desktop has-text-centered">
            <Link to={linkToRole()}>
              <BoxWithBackground>
                <div className="level">
                  <div className="level-item">
                    <figure className="image is-48x48">
                      <img src={main.home} alt={"home_icon"} />
                    </figure>
                  </div>
                </div>
                <p className="title is-6 has-text-grey">
                  <Intl message path="components.buttons.auth.backToUserhome" />
                </p>
              </BoxWithBackground>
            </Link>
          </div>
          <div className="column is-2-desktop has-text-centered">
            <Link
              to={"/"}
              onClick={() => this.props.dispatch({ type: logOutType() })}
            >
              <BoxWithBackground>
                <div className="level">
                  <div className="level-item">
                    <figure className="image is-48x48">
                      <img src={auth.logout} alt={"logout_icon"} />
                    </figure>
                  </div>
                </div>
                <p className="title is-6 has-text-grey">
                  <Intl message path="components.buttons.auth.confirmLogout" />
                </p>
              </BoxWithBackground>
            </Link>
          </div>
        </div>
      </Section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    role: state.reducers.auth.role,
  };
};

export default connect(mapStateToProps)(Logout);
