import individuals from "./individuals";
import fullView from "./teeth_cat.svg";
import incisors from "./incisors";

const catTeeth = {
  individuals,
  fullView,
  incisors,
};

export default catTeeth;
