import React from "react";
import { connect } from "react-redux";

function Divider({ label }) {
  return (
    <div
      style={{ fontWeight: "bold" }}
      className="is-divider"
      data-content={label}
    ></div>
  );
}

function mapStateToProps(state) {
  const { locale, messages } = state.intl;
  return {
    label: messages.components.dividers.main.or[locale],
  };
}

export default connect(mapStateToProps)(Divider);
