import axios from "axios"
import moment from "moment"
import _ from "lodash"
import {
  TRAINING_DIARY_FETCH,
  TRAINING_DIARY_FETCH_TYPE,
  TRAINING_DIARY_ADD,
  TRAINING_DIARY_UPDATE,
  TRAINING_DIARY_DELETE,
  TRAINING_DIARY_SET_CUSTOM_SETTINGS,
  ACTION_STARTED,
  ACTION_SUCCESS,
  MODALS_HIDE,
  PETS_UPDATE,
  TABLE_CLEAR_CURRENT_STATE,
} from "./types"
import {
  DAILY_ACTIVITY_ADD,
  DAILY_ACTIVITY_UPDATE,
  DAILY_ACTIVITY_DELETE,
} from "containers/features/Activity/actions"
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "./functions"

const API = (route) => `/api/training_diary/${route}`

// Action Creators
export const fetchTdes = (value, unit, name, pet, providerView) => async (
  dispatch
) => {
  const dates = !_.isEmpty(value)
    ? { ...value }
    : {
        from: moment().subtract(value, unit).toISOString(),
        to: moment().toISOString(),
      }
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({
      type: TRAINING_DIARY_FETCH_TYPE,
      payload: { dates, type: !_.isEmpty(value) ? "range" : name },
    })
    dispatch({ type: ACTION_STARTED, payload: TRAINING_DIARY_FETCH })
    const res = await axios.get(
      API(providerView ? "fetch_for_provider" : "fetch"),
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
        params: { _pet: pet._id, petType: pet.petType, ...dates },
      }
    )
    dispatch({ type: TRAINING_DIARY_FETCH, payload: res.data.tdes, pet })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addTde = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerContact,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: TRAINING_DIARY_ADD })
    const res = await axios.post(
      API(providerView ? "add_by_provider" : "add"),
      { _user: providerView ? providerContact._id : undefined, ...values },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    if (res.data.pet) {
      dispatch({
        type: PETS_UPDATE,
        payload: res.data.pet,
      })
    }
    dispatch({ type: TRAINING_DIARY_ADD, payload: res.data.tde })
    if (res.data.dailyActivityEntry) {
      dispatch({
        type: DAILY_ACTIVITY_ADD,
        payload: res.data.dailyActivityEntry,
      })
    }
    endAndResetAction(dispatch)
    history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const updateTde = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: TRAINING_DIARY_UPDATE })
    const res = await axios.put(
      API(providerView ? "update_by_provider" : "update"),
      values,
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({ type: TRAINING_DIARY_UPDATE, payload: res.data.tde })
    if (res.data.dailyActivityEntry) {
      dispatch({
        type: DAILY_ACTIVITY_UPDATE,
        payload: res.data.dailyActivityEntry,
      })
    }
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const deleteTde = (
  _id,
  petType,
  history,
  successRoute,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: TRAINING_DIARY_DELETE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      API(providerView ? "delete_by_provider" : "delete"),
      { _id, petType },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({ type: TRAINING_DIARY_DELETE, payload: res.data.tde })
    if (res.data.dailyActivityEntry) {
      dispatch({
        type: DAILY_ACTIVITY_DELETE,
        payload: res.data.dailyActivityEntry,
      })
    }
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const setCustomSettings = (settings, _id, providerView) => async (
  dispatch
) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: TRAINING_DIARY_SET_CUSTOM_SETTINGS,
    })
    const res = await axios.put(
      API(providerView ? "custom_settings_by_provider" : "custom_settings"),
      { ...settings, _id },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "setCustomSettingsSuccess",
    })
    dispatch({
      type: PETS_UPDATE,
      payload: res.data.pet,
    })
    endAndResetActionWithDelay(dispatch, 1500)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const trainingDiaryActions = {
  fetchTdes,
  addTde,
  updateTde,
  deleteTde,
  setCustomSettings,
}

export default trainingDiaryActions
