import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as Yup from "yup";
import { INPUT_FIELDS } from "./Login_Fields";
import { FormButtons, FormTitle } from "components/form";

class LoginProviderForm extends Component {
  renderFields = () => {
    return INPUT_FIELDS.map((field, i) => {
      return <Field key={i} {...field} />;
    });
  };

  render() {
    const { errors, dirty, isSubmitting } = this.props;
    return (
      <Form>
        <FormTitle group={"providerLogin"} />
        {this.renderFields()}
        <br />
        <FormButtons
          login
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
        />
      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.intl.locale,
  };
};

LoginProviderForm = withFormik({
  mapPropsToValues({ locale }) {
    return {
      username: "",
      password: "",
      locale,
    };
  },
  validationSchema: Yup.object().shape({
    username: Yup.string().required("required"),
    password: Yup.string().required("required"),
  }),
  handleSubmit(values, { setSubmitting, props }) {
    return props.logInProvider(values, setSubmitting, props.history);
  },
})(LoginProviderForm);

export default connect(mapStateToProps, actions)(LoginProviderForm);
