import React, { useEffect } from "react"
import { connect } from "react-redux"
import * as actions from "actions/partners"
import SmartTile from "components/SmartTile"
import _ from "lodash"

function PartnerTiles({ partnerId, partnerTiles, fetchTiles, column }) {
  useEffect(() => {
    fetchTiles(partnerId)
  }, [fetchTiles, partnerId])

  if (_.isEmpty(partnerTiles)) return null

  return partnerTiles.items.map((tile, i) => (
    <div key={i} className={column}>
      <SmartTile partnerTile {...tile} />
    </div>
  ))
}

const mapStateToProps = (state) => {
  return {
    partnerId: state.reducers.auth.user.partnerId,
    partnerTiles: state.reducers.partners.partnerTiles,
  }
}

export default connect(mapStateToProps, actions)(PartnerTiles)
