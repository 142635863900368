import React from "react"
import { connect } from "react-redux"
import Title from "components/elements/Title"

function SectionTitle(props) {
  return (
    <>
      <Title
        title={props.title[props.locale]}
        subtitle={props.subtitle[props.locale]}
        titleSize={"is-6"}
        subtitleSize={"is-7"}
        titleColor={"has-text-grey has-text-weight-bold"}
        subtitleColor={"has-text-grey"}
        className={`${props.form && "has-text-centered"}`}
      />
      {props.form && <br />}
    </>
  )
}

const mapStateToProps = state => {
  return {
    locale: state.intl.locale
  }
}

export default connect(mapStateToProps)(SectionTitle)
