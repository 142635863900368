import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "containers/contacts/actions";
import BoxWithBackground from "components/styled/Box_With_Background";
import Section from "components/layout/Section";
import providerAccountItems from "./routes";
import Intl from "components/Intl";
import config from "./config";
import Message from "components/message";
import NewNotificationBadge from "components/SmartTile/NewNotificationBadge";

function ProviderContacts({
  name,
  contacts: { pendingRequests, contacts },
  dispatch,
  fetchContactsForProvider,
}) {
  useEffect(() => {
    fetchContactsForProvider();
  }, [fetchContactsForProvider]);

  function hasRequests() {
    let requests = contacts.filter((c) => {
      if (c.requests) {
        return c.requests._appointmentRequest || c.requests._contactRequest;
      } else {
        return false;
      }
    });

    if (requests.length > 0) return requests.length;

    return false;
  }

  return (
    <Section>
      <Message info message="providerHomeContacts" />
      <div className="columns is-2 is-variable is-mobile is-centered is-multiline">
        {providerAccountItems.map((item) => {
          if (item.view !== config.view) return null;
          if (item.disabled) return null;
          return (
            <div
              key={item.name}
              className="column is-2-desktop is-6-mobile has-text-centered"
            >
              <Link to={item.path}>
                <BoxWithBackground>
                  <div style={{ height: 130, paddingTop: 15 }}>
                    <div className="level">
                      <div className="level-item">
                        <figure className="image is-48x48">
                          {hasRequests() && item.name === "viewContacts" && (
                            <NewNotificationBadge value={hasRequests()} />
                          )}
                          <img src={item.img.src} alt={item.img.alt} />
                        </figure>
                      </div>
                    </div>
                    <p className="title is-6 has-text-grey">
                      <Intl
                        message
                        path={`containers.contacts.${[config.view]}.tiles.${[
                          item.name,
                        ]}`}
                      />
                    </p>
                  </div>
                </BoxWithBackground>
              </Link>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

const mapStateToProps = (state) => {
  return {
    contacts: state.reducers.contacts,
  };
};

export default connect(mapStateToProps, actions)(ProviderContacts);
