import React from "react"
import { connect } from "react-redux"
import { isMobileDevice } from "functions"

const ReadOnlyField = props => {
  const {
    formName,
    field,
    type,
    icon,
    className,
    min,
    step,
    locale,
    messages
  } = props
  return (
    <div className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">
          {messages.labels[formName][field.name][locale]}
        </div>
      </label>
      <div className="control has-icons-left has-icons-right">
        <input
          {...field}
          type={type}
          placeholder={
            !isMobileDevice()
              ? messages.placeholders[formName][field.name][locale]
              : ""
          }
          readOnly
          className={className}
          min={min}
          step={step}
        />
        <span className="icon is-small is-left">
          <i className={icon} />
        </span>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    messages: {
      labels: state.intl.messages.components.form.labels,
      placeholders: state.intl.messages.components.form.placeholders
    },
    locale: state.intl.locale
  }
}

export default connect(mapStateToProps)(ReadOnlyField)
