import { pets, main } from "assets"
import Landing from "components/features/Landing"
import ProfileDetails from "./Profile_Details"
import DeleteProfile from "./Delete_Profile"

const litterMemberProfileConfig = {
  name: "litterMemberProfile",
  type: "petProfile",
  defaultRoute: "/userhome/dashboard/litter/litter_member_dashboard/profile",
  menuIcon: { img: pets.profile, alt: "pet_profile_icon" },
  nav: [
    {
      name: "landing",
      menuItem: true,
      image: {
        img: main.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "profileDetails",
      menuItem: true,
      image: {
        img: pets.editProfile,
        alt: "litter_member_profile_details_icon",
      },
      icon: "fas fa-copy",
      component: ProfileDetails,
      path: "/details",
    },
    {
      name: "deleteProfile",
      menuItem: true,
      image: {
        img: pets.deleteProfile,
        alt: "pet_profile_delete_icon",
      },
      icon: "fas fa-ban",
      component: DeleteProfile,
      path: "/delete",
    },
  ],
}

export default litterMemberProfileConfig
