import { InputFieldV5, TextareaV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "startDate",
    group: "heatDiary",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "endDate",
    group: "heatDiary",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "cycleNotes",
    group: "heatDiary",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    charLimit: 500,
    userCanEdit: true,
  },
];

export { INPUT_FIELDS };
