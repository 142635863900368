import React from "react";
import { connect } from "react-redux";
import assets from "assets";
import { Fragment } from "react";
import moment from "moment";
import NewTaskForm from "../forms/New_Task_Form";
import FullTaskForm from "../forms/Full_Task_Form";
import MarkTaskCompleteForm from "../forms/Mark_Task_Complete_Form";
import {
  MODALS_CONFIRM_DELETE,
  MODALS_HIDE,
  MODALS_SHOW,
  MODALS_UPDATE_DOCUMENT,
} from "actions/types";
import { TASKS_DELETE } from "../actions";

function Task(props) {
  const {
    index,
    taskDueDate,
    taskTitle,
    taskDescription,
    dispatch,
    messages,
    locale,
    defaultRoute,
    lifeCycleState,
    providerView,
    providerId,
    _provider,
    _id,
  } = props;

  function handleViewClick() {
    if (lifeCycleState.status === "active") {
      dispatch({
        type: MODALS_SHOW,
        modalType: MODALS_UPDATE_DOCUMENT,
        modalProps: {
          type: "updateDocument",
          handleModalClose: () => dispatch({ type: MODALS_HIDE }),
          children: (
            <NewTaskForm
              viewMode
              valuesToEdit={props}
              cancel={() => dispatch({ type: MODALS_HIDE })}
              messages={messages}
              locale={locale}
              defaultRoute={defaultRoute}
              providerView={providerView}
            />
          ),
        },
      });
    }

    if (lifeCycleState.status === "complete") {
      dispatch({
        type: MODALS_SHOW,
        modalType: MODALS_UPDATE_DOCUMENT,
        modalProps: {
          type: "updateDocument",
          handleModalClose: () => dispatch({ type: MODALS_HIDE }),
          children: (
            <FullTaskForm
              viewMode
              valuesToEdit={props}
              cancel={() => dispatch({ type: MODALS_HIDE })}
              messages={messages}
              locale={locale}
              defaultRoute={defaultRoute}
            />
          ),
        },
      });
    }
  }

  function handleEditClick() {
    if (lifeCycleState.status === "active") {
      dispatch({
        type: MODALS_SHOW,
        modalType: MODALS_UPDATE_DOCUMENT,
        modalProps: {
          type: "updateDocument",
          handleModalClose: () => dispatch({ type: MODALS_HIDE }),
          children: (
            <NewTaskForm
              editMode
              valuesToEdit={props}
              cancel={() => dispatch({ type: MODALS_HIDE })}
              messages={messages}
              locale={locale}
              defaultRoute={defaultRoute}
              providerView={providerView}
            />
          ),
        },
      });
    }

    if (lifeCycleState.status === "complete") {
      dispatch({
        type: MODALS_SHOW,
        modalType: MODALS_UPDATE_DOCUMENT,
        modalProps: {
          type: "updateDocument",
          handleModalClose: () => dispatch({ type: MODALS_HIDE }),
          children: (
            <FullTaskForm
              editMode
              valuesToEdit={props}
              cancel={() => dispatch({ type: MODALS_HIDE })}
              messages={messages}
              locale={locale}
              defaultRoute={defaultRoute}
            />
          ),
        },
      });
    }
  }

  function handleDeleteClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "task",
        noDefault: true,
        ownBodyText: false,
        actionType: TASKS_DELETE,
        item: _id,
        providerView,
      },
    });
  }

  function handleMarkCompleteClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_UPDATE_DOCUMENT,
      modalProps: {
        type: "updateDocument",
        handleModalClose: () => dispatch({ type: MODALS_HIDE }),
        children: (
          <MarkTaskCompleteForm
            editMode
            valuesToEdit={props}
            cancel={() => dispatch({ type: MODALS_HIDE })}
            messages={messages}
            locale={locale}
            defaultRoute={defaultRoute}
          />
        ),
      },
    });
  }

  return (
    <div
      className="card"
      style={{ backgroundColor: index % 2 === 1 ? "#f2f2f2" : "" }}
    >
      <div className="card-content" style={{ padding: 12 }}>
        <div className="columns is-paddingless is-vcentered">
          <div className="column">
            <label className="label has-text-grey is-size-6">{taskTitle}</label>
            <p className="is-size-7 has-text-dark">{taskDescription}</p>
          </div>
          <div className="column">
            <label className="label has-text-grey is-size-6">
              {messages.labels.taskDueDate[locale]}
            </label>
            <p className="is-size-7 has-text-dark">
              {moment(taskDueDate).format("DD.MM.YYYY")}
            </p>
          </div>
          <Fragment>
            <div className="column">
              <div className="buttons is-right">
                <div style={{ height: 32, paddingRight: 8 }}>
                  <button
                    className="button is-small is-rounded"
                    style={{ padding: 2 }}
                    onClick={handleViewClick}
                  >
                    <figure className={"image is-24x24"}>
                      <img
                        src={assets.features.tasks.viewTask}
                        alt="view_icon"
                      />
                    </figure>
                  </button>
                  {((providerView && _provider && providerId === _provider) ||
                    !providerView) && (
                    <button
                      className="button is-small is-rounded"
                      style={{ padding: 2 }}
                      onClick={handleEditClick}
                    >
                      <figure className={"image is-24x24"}>
                        <img
                          src={assets.features.tasks.editTask}
                          alt="edit_icon"
                        />
                      </figure>
                    </button>
                  )}
                  {((providerView && _provider && providerId === _provider) ||
                    !providerView) && (
                    <button
                      className="button is-small is-rounded"
                      style={{ padding: 2 }}
                      onClick={handleDeleteClick}
                    >
                      <figure className={"image is-24x24"}>
                        <img
                          src={assets.features.tasks.deleteTask}
                          alt="delete_icon"
                        />
                      </figure>
                    </button>
                  )}
                </div>
                {lifeCycleState.status === "active" && !providerView && (
                  <div
                    style={{
                      borderLeft: "2px solid #bebebe",
                      height: 32,
                      paddingLeft: 8,
                    }}
                  >
                    <button
                      className="button is-small is-rounded"
                      style={{ padding: 2 }}
                      onClick={handleMarkCompleteClick}
                    >
                      <figure className={"image is-24x24"}>
                        <img
                          src={assets.features.tasks.markComplete}
                          alt="mark_complete_icon"
                        />
                      </figure>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
}

export default connect(null, null)(Task);
