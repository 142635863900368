import React from "react"

function Tag(props) {
  return (
    <span
      style={{ cursor: "pointer" }}
      className={`tag is-medium ${props.selected ? "is-primary" : "is-darker"}`}
      onClick={props.onClick}
    >
      {props.label}
    </span>
  )
}

export default Tag
