const litterDashboardConfig = {
  layout: {
    columns: "columns is-2 is-variable is-mobile is-multiline",
    column: "column is-2-desktop is-4-mobile is-4-tablet",
  },
  featureOrder: [
    "litterProfile",
    "weightReview",
    "litterVaccinations",
    "litterParasites",
    "litterFoodDiary",
    "litterHomeCare",
    "litterExpenses",
    "litterUsefulLinks",
    "litterAddMember",
  ],
};

export default litterDashboardConfig;
