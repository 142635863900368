import main from "./health_diary.svg"
import newEntry from "./health_diary_new_entry.svg"
import newEntryHealth from "./health_diary_new_health_entry.svg"
import newEntryMedicine from "./health_diary_new_medicine_entry.svg"
import newEntryTemperature from "./health_diary_new_temperature_entry.svg"
import viewEntries from "./health_diary_view_entries.svg"
import viewEntriesHealth from "./health_diary_view_health_entries.svg"
import viewEntriesMedicine from "./health_diary_view_medicine_entries.svg"
import viewEntriesTemperature from "./health_diary_view_temperature_entries.svg"

const healthDiary = {
  main,
  newEntry,
  newEntryHealth,
  newEntryMedicine,
  newEntryTemperature,
  viewEntries,
  viewEntriesHealth,
  viewEntriesMedicine,
  viewEntriesTemperature,
}

export default healthDiary
