import React from "react"
import { useParams } from "react-router-dom"
import Section from "components/layout/Section"
import Post from "../components/Post"
import BackButton from "components/elements/BackButton"

function ViewFetchedPostsByTag(props) {
  const { blog } = props
  const { id } = useParams()
  const tag = blog.tags.filter((t) => t.id === Number(id))[0]
  const posts = blog.entriesFetchedByTag.filter((e) =>
    e.tags.includes(Number(id))
  )

  return (
    <Section>
      <BackButton />
      <div className="tags">
        <div className="tag is-medium">{tag.name}</div>
      </div>
      {posts.map((e) => (
        <Post key={e.id} post={e} excerpt {...props} />
      ))}
    </Section>
  )
}

export default ViewFetchedPostsByTag
