import main from "./hoof_care.svg"
import newEntry from "./hoof_care_new_entry.svg"
import viewEntries from "./hoof_care_view_entries.svg"

const hoofCare = {
  main,
  newEntry,
  viewEntries,
}

export default hoofCare
