import React from "react"

function PostExcerpt({ content }) {
  return (
    <p
      className="post-excerpt"
      dangerouslySetInnerHTML={{
        __html: `${content.substring(0, 150)}...`,
      }}
    />
  )
}

export default PostExcerpt
