import axios from "axios";
import moment from "moment";
import _ from "lodash";
import {
  WEIGHT_FETCH,
  WEIGHT_FETCH_TYPE,
  WEIGHT_ADD,
  WEIGHT_ADD_LITTER_MEMBER,
  WEIGHT_UPDATE,
  WEIGHT_UPDATE_LITTER_MEMBER,
  WEIGHT_DELETE,
  WEIGHT_DELETE_LITTER_MEMBER,
  ACTION_STARTED,
  ACTION_ERROR,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
  MODALS_HIDE,
  PETS_UPDATE,
  TABLE_CLEAR_CURRENT_STATE,
  LITTERS_UPDATE_MEMBER,
} from "./types";
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "./functions";

const API = (route) => `/api/weight/${route}`;

// Action Creators
export const fetchWeightEntries =
  (value, unit, name, pet, providerView) => async (dispatch) => {
    const dates = !_.isEmpty(value)
      ? { ...value }
      : {
          from: moment().subtract(value, unit).toISOString(),
          to: moment().toISOString(),
        };
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({
        type: WEIGHT_FETCH_TYPE,
        payload: { dates, type: !_.isEmpty(value) ? "range" : name },
      });
      dispatch({ type: ACTION_STARTED, payload: WEIGHT_FETCH });
      const res = await axios.get(
        API(providerView ? "fetch_for_provider" : "fetch"),
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
          params: { _pet: pet._id, petType: pet.petType, ...dates },
        }
      );
      dispatch({ type: WEIGHT_FETCH, payload: res.data.weightEntries, pet });
      endAndResetAction(dispatch);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const addWeightEntry =
  (
    values,
    setSubmitting,
    history,
    successRoute,
    providerContact,
    providerView
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: WEIGHT_ADD });
      const res = await axios.post(
        API(providerView ? "add_by_provider" : "add"),
        { _user: providerView ? providerContact._id : undefined, ...values },
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      dispatch({ type: WEIGHT_ADD, payload: res.data.weightEntry });
      if (res.data.pet) {
        dispatch({ type: PETS_UPDATE, payload: res.data.pet });
      }
      endAndResetAction(dispatch);
      history.push(successRoute);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const updateWeightEntry =
  (values, setSubmitting, history, successRoute, providerView) =>
  async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: WEIGHT_UPDATE });
      const res = await axios.put(
        API(providerView ? "update_by_provider" : "update"),
        values,
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      dispatch({
        type: ACTION_SUCCESS,
        payload: "updateSuccess",
      });
      dispatch({ type: WEIGHT_UPDATE, payload: res.data.weightEntry });
      if (res.data.pet) {
        dispatch({ type: PETS_UPDATE, payload: res.data.pet });
      }
      endAndResetActionWithDelay(dispatch, 1500);
      history.push(successRoute);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const deleteWeightEntry =
  (_id, history, successRoute, providerView) => async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: WEIGHT_DELETE });
      dispatch({ type: MODALS_HIDE });
      const res = await axios.put(
        API(providerView ? "delete_by_provider" : "delete"),
        { _id },
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      dispatch({
        type: ACTION_SUCCESS,
        payload: "deleteSuccess",
      });
      dispatch({ type: WEIGHT_DELETE, payload: res.data.weightEntry });
      endAndResetActionWithDelay(dispatch, 1500);
      history.push(successRoute);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const addWeightEntryLitterMember =
  (values, setSubmitting, history, successRoute) => async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: WEIGHT_ADD_LITTER_MEMBER });
      const res = await axios.post("/api/weight/add", values, {
        headers: { authorization: localStorage.getItem("token") },
      });
      dispatch({
        type: WEIGHT_ADD_LITTER_MEMBER,
        payload: res.data.weightEntry,
      });
      if (res.data.pet) {
        dispatch({ type: LITTERS_UPDATE_MEMBER, payload: res.data.pet });
      }
      dispatch({ type: ACTION_ENDED });
      dispatch({ type: ACTION_RESET });
      return history.push(successRoute);
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 3000);
    }
  };

export const updateWeightEntryLitterMember =
  (values, setSubmitting, history, successRoute) => async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: WEIGHT_UPDATE_LITTER_MEMBER });
      const res = await axios.put("/api/weight/update", values, {
        headers: { authorization: localStorage.getItem("token") },
      });
      dispatch({
        type: WEIGHT_UPDATE_LITTER_MEMBER,
        payload: res.data.weightEntry,
      });
      if (res.data.pet) {
        dispatch({ type: LITTERS_UPDATE_MEMBER, payload: res.data.pet });
      }
      dispatch({ type: ACTION_ENDED });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "updateSuccess",
      });
      history.push(successRoute);
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 2000);
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 3000);
    }
  };

export const deleteWeightEntryLitterMember =
  (_id, history, successRoute) => async (dispatch) => {
    try {
      dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
      dispatch({ type: ACTION_STARTED, payload: WEIGHT_DELETE_LITTER_MEMBER });
      dispatch({ type: MODALS_HIDE });
      const res = await axios.put(
        "/api/weight/delete",
        { _id },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      );
      dispatch({
        type: WEIGHT_DELETE_LITTER_MEMBER,
        payload: res.data.weightEntry,
      });
      dispatch({ type: ACTION_ENDED });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "deleteSuccess",
      });
      history.push(successRoute);
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 2000);
    } catch (error) {
      if (error.response) {
        dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 3000);
    }
  };

const weightActions = {
  fetchWeightEntries,
  addWeightEntry,
  updateWeightEntry,
  deleteWeightEntry,
  addWeightEntryLitterMember,
  updateWeightEntryLitterMember,
  deleteWeightEntryLitterMember,
};

export default weightActions;
