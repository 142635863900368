import React from "react"
import Section from "components/layout/Section"
import SmartTile from "components/SmartTile"
import Message from "components/message"

function UserPets({ pets }) {
  if (pets.length === 0)
    return (
      <div>
        <br />
        <Message warning message="noPetsOrAcccess" />
      </div>
    )

  return (
    <Section>
      <div className="columns is-2 is-variable is-mobile is-multiline">
        {pets.map((pet) => (
          <div
            key={pet._id}
            className="column is-2-desktop is-4-mobile is-3-tablet"
          >
            <SmartTile petTile pet={pet} providerView />
          </div>
        ))}
      </div>
    </Section>
  )
}

export default UserPets
