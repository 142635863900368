import bcsCat1 from "./bcs_cat_1.svg";
import bcsCat2 from "./bcs_cat_2.svg";
import bcsCat3 from "./bcs_cat_3.svg";
import bcsCat4 from "./bcs_cat_4.svg";
import bcsCat5 from "./bcs_cat_5.svg";
import bcsCat6 from "./bcs_cat_6.svg";
import bcsCat7 from "./bcs_cat_7.svg";
import bcsCat8 from "./bcs_cat_8.svg";
import bcsCat9 from "./bcs_cat_9.svg";

const bcsCat = {
  bcsCat1,
  bcsCat2,
  bcsCat3,
  bcsCat4,
  bcsCat5,
  bcsCat6,
  bcsCat7,
  bcsCat8,
  bcsCat9,
};

export default bcsCat;
