import React from 'react';
import { useEffect } from "react";
import RequireSubFeature from "components/hoc/Require_Sub_Feature";
import { isMobileDevice } from "functions";
import {
  MODALS_CONFIRM_DELETE,
  MODALS_SHOW,
  MODALS_HIDE,
  MODALS_TOOTH_MARKER_DETAIL,
} from "actions/types";
import { TOOTH_MARKER_DELETE_MARKER_DETAIL } from "../actions";
import { TOOTH_STATES } from "constants/tooth_states";
import TeethImageFull from "../components/TeethImageFull";
import ToothDetail from "../components/ToothDetail";

const IMAGE_SIZE = isMobileDevice()
  ? { height: 480, width: 300 }
  : { height: 600, width: 500 };

function ToothMarkerView(props) {
  const {
    toothMarker: { markers, lastFetchPetID },
    fetchMarkers,
    pet,
    providerView,
    providerContact,
    messages,
    locale,
  } = props;

  useEffect(() => {
    if (lastFetchPetID !== pet._id) {
      fetchMarkers(pet._id, providerView);
    }
  }, [lastFetchPetID, fetchMarkers, pet._id, providerView]);

  function handleToothClicked(e, confirmationRequired) {
    const { id } = e.target;
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_TOOTH_MARKER_DETAIL,
      modalProps: {
        addDetail: true,
        confirmationRequired,
        providerView,
        providerContact,
        toothId: id,
        cancel: () => {
          props.dispatch({ type: MODALS_HIDE });
        },
        handleModalClose: () => {
          props.dispatch({ type: MODALS_HIDE });
        },
      },
    });
  }

  function handleToothState(_toothId) {
    let marker = markers.find((m) => m.some((o) => o.toothId === _toothId));
    if (marker) {
      if (marker.length > 1) {
        let m = marker.reduce((a, b) =>
          TOOTH_STATES[a.toothState].priority <
          TOOTH_STATES[b.toothState].priority
            ? a
            : b
        );
        return TOOTH_STATES[m.toothState];
      } else if (marker.length === 1) {
        return TOOTH_STATES[marker[0].toothState];
      } else {
        return {};
      }
    }
  }

  function handleViewMarkerDetail(detail) {
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_TOOTH_MARKER_DETAIL,
      modalProps: {
        messages,
        locale,
        toothId: detail.toothId,
        valuesToEdit: detail,
        providerView,
        providerContact,
        viewMode: true,
        editMode: false,
        handleModalClose: () => {
          props.dispatch({ type: MODALS_HIDE });
        },
        handleDeleteButtonPressed: (_id) => handleDeleteMarker(_id),
      },
    });
  }

  function handleDeleteMarker(_id) {
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        providerView,
        providerContact,
        type: "toothMarkerDetail",
        actionType: TOOTH_MARKER_DELETE_MARKER_DETAIL,
        item: _id,
      },
    });
  }

  return (
    <div style={{ marginTop: 12 }}>
      <div className="columns">
        <div className="column">
          <div className="card" style={{ height: "100%", paddingTop: 20 }}>
            <div className="card-image has-text-centered">
              <figure className="image is-inline-block">
                <TeethImageFull
                  onClick={handleToothClicked}
                  handleToothState={handleToothState}
                  petType={pet.petType}
                  markers={markers}
                  labels={messages.labels}
                  locale={locale}
                  {...IMAGE_SIZE}
                />
              </figure>
            </div>
          </div>
        </div>
        <div className="column">
          {markers.map((m, i) => {
            return (
              <div key={i}>
                {m.map((d, l) => (
                  <ToothDetail
                    key={l}
                    detailIndex={l}
                    {...d}
                    labels={messages.labels}
                    states={messages.states}
                    locale={locale}
                    onClick={() => handleViewMarkerDetail(d)}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default RequireSubFeature(ToothMarkerView);
