import axios from "axios"
import {
  LITTER_EXPENSES_FETCH,
  LITTER_EXPENSES_ADD,
  LITTER_EXPENSES_UPDATE,
  LITTER_EXPENSES_DELETE,
  ACTION_STARTED,
  ACTION_RESET,
  ACTION_ENDED,
  ACTION_SUCCESS,
} from "actions/types"
import { catchBlockError } from "./functions"

export const litterExpensesFetch = (_litter) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: LITTER_EXPENSES_FETCH })
    const res = await axios.post(
      "/api/litter_expenses/fetch",
      { _litter },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({
      type: LITTER_EXPENSES_FETCH,
      payload: { _litter, data: res.data.litterExpenses },
    })
    dispatch({ type: ACTION_ENDED })
    dispatch({ type: ACTION_RESET })
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const litterExpensesAdd = (values, setSubmitting, callback) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: LITTER_EXPENSES_ADD })
    const res = await axios.post("/api/litter_expenses/add", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({ type: LITTER_EXPENSES_ADD, payload: res.data.litterExpense })
    dispatch({ type: ACTION_ENDED })
    dispatch({ type: ACTION_RESET })
    callback()
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const litterExpensesUpdate = (values, setSubmitting, callback) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: LITTER_EXPENSES_UPDATE })
    const res = await axios.put("/api/litter_expenses/update", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({ type: LITTER_EXPENSES_UPDATE, payload: res.data.litterExpense })
    dispatch({ type: ACTION_ENDED })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    callback()
    return setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const litterExpensesDelete = (_id, callback) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: LITTER_EXPENSES_DELETE })
    const res = await axios.put(
      "/api/litter_expenses/delete",
      { _id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({ type: LITTER_EXPENSES_DELETE, payload: res.data.litterExpense })
    dispatch({ type: ACTION_ENDED })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    callback()
    return setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const litterExpensesActions = {
  litterExpensesFetch,
  litterExpensesAdd,
  litterExpensesUpdate,
}

export default litterExpensesActions
