import React from "react"

function ExpenseSummaryItem(props) {
  return (
    <div className="level is-mobile">
      <div className="level-left">
        <div className="level-item">
          <div className="label">{`${props.label}:`}</div>
        </div>
        <div className="level-item">
          <div className="">{props.value}</div>
        </div>
      </div>
    </div>
  )
}

export default ExpenseSummaryItem
