import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Hr from "components/elements/Hr";
import { renderMeOnClient } from "functions";

const SideMenu = (props) => {
  const { nav, defaultRoute, messages, locale, pet, user } = props;
  return (
    <aside className="menu">
      <ul className="menu-list">
        {nav.map((navItem, i) => {
          if (!navItem.menuItem) return null;
          if (navItem.disabled) return null;
          if (navItem.petTypeReq) {
            if (!navItem.petTypes.includes(pet.petType)) return null;
          }
          if (navItem.requiresSubLevel) {
            if (!navItem.subLevels.includes(user.subLevel)) return null;
          }
          if (navItem.clients && !renderMeOnClient(navItem.clients))
            return null;
          return (
            <li key={i}>
              <NavLink
                exact={true}
                style={{ padding: 3, marginBottom: 12 }}
                to={
                  navItem.linkItem
                    ? navItem.path
                    : `${defaultRoute}${navItem.path}`
                }
                activeStyle={{ background: "lightgrey" }}
              >
                {navItem.image ? (
                  <div className="level">
                    <div className="level-left">
                      <div style={{ marginRight: 4 }} className="level-item">
                        <figure className="image is-24x24">
                          <img
                            src={navItem.image.img}
                            alt={navItem.image.alt}
                          />
                        </figure>
                      </div>
                      <div className="level-item">
                        <div style={{ fontSize: 14 }}>
                          {messages[navItem.name].menuLink[locale]}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span>
                    <i className={navItem.icon + " has-text-grey"} />{" "}
                    <span style={{ fontSize: 14 }}>
                      {messages[navItem.name].menuLink[locale]}
                    </span>
                  </span>
                )}
              </NavLink>
              {navItem.hr && <Hr />}
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default SideMenu;

SideMenu.propTypes = {
  sideNav: PropTypes.array,
  defaultRoute: PropTypes.string,
  routes: PropTypes.array,
};
