import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import PROVIDER_CONFIG from "./provider_config";

class Tasks extends Component {
  render() {
    return <Menu {...PROVIDER_CONFIG} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    providerId: state.reducers.auth.provider._id,
    providerContact: { _id: state.reducers.pets.selectedPet._user },
    messages: {
      ...state.intl.messages.containers.features.tasks,
      task: state.intl.messages.components.task,
      labels: state.intl.messages.components.forms.labels.tasks,
    },
    tasks: state.reducers.tasks,
  };
};

export default connect(mapStateToProps)(Tasks);
