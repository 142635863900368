import main from "./appointments.svg"
import newEntry from "./appointments_new_entry.svg"
import viewEntries from "./appointments_view_entries.svg"
import viewAppointment from "containers/features/Tasks/assets/tasks_view_task.svg"
import confirmAppointment from "containers/features/Tasks/assets/tasks_complete.svg"
import cancelDeclineAppointment from "./decline.svg"
import editAppointment from "assets/main/edit.svg"
import deleteAppointment from "assets/professionals/contact_delete.svg"
import providerAppointments from "./provider_appointments.svg"
import canceled from "./appointments_canceled.svg"
import confirmed from "./appointments_confirmed.svg"
import declined from "./appointments_declined.svg"
import expired from "./appointments_expired.svg"
import unconfirmed from "./appointments_unconfirmed.svg"

const appointments = {
  main,
  newEntry,
  viewEntries,
  viewAppointment,
  confirmAppointment,
  cancelDeclineAppointment,
  editAppointment,
  deleteAppointment,
  providerAppointments,
  canceled,
  confirmed,
  declined,
  expired,
  unconfirmed,
}

export default appointments
