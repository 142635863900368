import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { parasites as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./fields/parasites";
import FormButtons from "components/form/FormButtons";

class ParasiteForm extends Component {
  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (!values.sideEffectsObserved && field.name === "sideEffectsDesc")
        return null;
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  }

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      viewMode,
      editMode,
      cancel,
      defaultRoute,
    } = this.props;
    return (
      <Form>
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={defaultRoute}
        />
        <br />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pet: state.reducers.pets.selectedPet,
  };
};

ParasiteForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet }) {
    return {
      date: moment(valuesToEdit.date).format("YYYY-MM-DD") || "",
      expiry: moment(valuesToEdit.expiry).format("YYYY-MM-DD") || "",
      parasiteType: valuesToEdit.parasiteType || "",
      productUsed: valuesToEdit.productUsed || "",
      dosageAmountUnit: valuesToEdit.dosageAmountUnit || "gram",
      dosageAmount: valuesToEdit.dosageAmount || 0,
      weight: valuesToEdit.weight
        ? valuesToEdit.weight
        : pet.petWeight > 0
        ? pet.petWeight
        : 0,
      sideEffectsObserved: valuesToEdit.sideEffectsObserved || false,
      sideEffectsDesc: valuesToEdit.sideEffectsDesc || "",
      otherObservations: valuesToEdit.otherObservations || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
      petName: valuesToEdit.petName || pet.petName,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    expiry: Yup.date().required("required"),
    parasiteType: Yup.string().required("required"),
    productUsed: Yup.string().required("required").max(30, "max-30"),
    dosageAmountUnit: Yup.string(),
    dosageAmount: Yup.number(),
    sideEffectsObserved: Yup.boolean(),
    sideEffectsDesc: Yup.string().when("sideEffectsObserved", {
      is: true,
      then: Yup.string().required("required").max(300, "max-300"),
      otherwise: Yup.string(),
    }),
    otherObservations: Yup.string().max(300, "max-300"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries`;
    if (props.editMode) {
      return props.updateParasite(
        values,
        setSubmitting,
        props.history,
        successRoute,
        props.providerView
      );
    }
    props.addParasite(
      values,
      setSubmitting,
      props.history,
      successRoute,
      props.providerContact,
      props.providerView
    );
  },
})(ParasiteForm);

export default withRouter(connect(mapStateToProps, actions)(ParasiteForm));
