import React from "react"

export default function Container(props) {
  return (
    <div
      className={`container ${props.className}`}
      style={props.style ? props.style : {}}
    >
      {props.children}
    </div>
  )
}
