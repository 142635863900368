import React from "react";
import { connect } from "react-redux";

function CategoryActivitySelectionSummary(props) {
  return (
    <div className="box">
      <p>
        <label>
          <span>
            <strong>{props.messages.labels.category[props.locale]}:</strong>{" "}
            {props.categories[props.category][props.locale]}
          </span>
        </label>
      </p>
      <p>
        <span>
          <strong>{props.messages.labels.activity[props.locale]}:</strong>{" "}
          {props.activities[props.activity][props.locale]}
        </span>
      </p>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: state.intl.messages.components.forms.summaries,
  };
};

export default connect(mapStateToProps)(CategoryActivitySelectionSummary);
