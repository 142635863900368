import React from "react"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"
import Section from "components/layout/Section"
import FormWrapper from "components/layout/Form_Wrapper"
import AppointmentEntryForm from "../forms/Appointment_Entry_Form"
import {
  MODALS_SHOW,
  MODALS_HIDE,
  MODALS_CONFIRM_APPOINTMENT,
} from "actions/types"

function NewEntryView(props) {
  function handleAddNewAppointment(
    values,
    setSubmitting,
    successRoute,
    resetForm
  ) {
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_APPOINTMENT,
      modalProps: {
        type: "confirmAppointmentNewEntry",
        values,
        user: props.user,
        providerContact: props.providerContact,
        providerView: props.providerView,
        handleModalClose: () => {
          props.dispatch({ type: MODALS_HIDE })
          setSubmitting(false)
        },
        handleConfirmAndAddNew: () => {
          props.dispatch({ type: MODALS_HIDE })
          props.addAppointmentsEntry(
            values,
            setSubmitting,
            props.history,
            successRoute,
            props.providerContact,
            props.providerView
          )
        },
      },
    })
  }

  return (
    <Section>
      <FormWrapper>
        <AppointmentEntryForm
          valuesToEdit={{}}
          handleAddNewAppointment={handleAddNewAppointment}
          {...props}
        />
      </FormWrapper>
    </Section>
  )
}

export default RequireSubFeature(NewEntryView)
