import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchResults, fetchResultsForProvider } from "actions/hcpi";
import Section from "components/layout/Section";
import Message from "components/message";
import FetchEntries from "components/fetch";
import Table from "components/table_2";
import config from "./config";
import Chart from "components/chart";
import { chartDataFunctions } from "functions/chart_data";

class ViewResults extends Component {
  handleFetchResults = (value, unit, name) => {
    const { pet, providerView } = this.props;
    if (providerView) {
      this.props.fetchResultsForProvider(value, unit, name, pet);
    } else {
      this.props.fetchResults(value, unit, name, pet);
    }
  };

  renderTableSection() {
    const { hcpi, pet, defaultRoute } = this.props;
    const { results } = hcpi;
    const resultsFiltered = results.filter((entry) => entry._pet === pet._id);

    // Create data set for table
    const tableData = {
      feature: "hcpi",
      pet: pet._id,
      id: Date.now(),
      headers: config.tableView.headers,
      mobileHeaders: config.tableView.mobileHeaders,
      data: resultsFiltered,
      viewEntryTo: `${defaultRoute}/view_result/`,
      fetchDates: hcpi.fetchDates,
      fetchType: hcpi.fetchType,
    };

    if (resultsFiltered.length === 0) {
      return <Message warning message={"noEntriesToDisplayRetry"} />;
    }

    return (
      <>
        <Table
          withKeys
          tableKeys={config.tableView.tableKeys}
          cellStyle={{ totalPoints: config.tableView.cellStyle.totalPoints }}
          itemsPerPage={20}
          tableData={tableData}
          withCharts
          feature={config.name}
          chartFunc={config.chartFunc}
          ticks={{
            beginAtZero: true,
            min: 0,
            max: 44,
          }}
          chartOptions={{
            plugins: {
              annotation: {
                annotations: [
                  {
                    id: "box1",
                    type: "box",
                    xMin: 0,
                    xMax: 100,
                    yMin: 0,
                    yMax: 6,
                    backgroundColor: "rgba(163, 242, 141, 0.4)",
                    borderColor: "rgba(163, 242, 141, 0.4)",
                  },
                  {
                    id: "box2",
                    type: "box",
                    xMin: 0,
                    xMax: 100,
                    yMin: 6,
                    yMax: 11,
                    backgroundColor: "rgba(254, 222, 135, 0.4)",
                    borderColor: "rgba(254, 222, 135, 0.4)",
                  },
                  {
                    id: "box3",
                    type: "box",
                    xMin: 0,
                    xMax: 100,
                    yMin: 11.6,
                    yMax: 44,
                    backgroundColor: "rgba(250, 172, 166, 0.4)",
                    borderColor: "rgba(250, 172, 166, 0.4)",
                  },
                ],
              },
            },
          }}
        />
        {this.props.providerView && this.props.activityChartData.length > 0 && (
          <div>
            <Chart
              data={chartDataFunctions["activityForHcpiCompare"](
                this.props.activityChartData,
                this.props.chartMessages,
                this.props.locale
              )}
            />
          </div>
        )}
      </>
    );
  }

  render() {
    const { fetchType, lastFetchPetID } = this.props.hcpi;
    const { pet } = this.props;
    return (
      <>
        <FetchEntries
          fetchItems={this.handleFetchResults}
          fetchType={fetchType}
          newPet={pet._id === lastFetchPetID}
          fetchButtons={["last7Days", "last30Days", "last3Months", "last1Year"]}
        />
        <Section>{this.renderTableSection()}</Section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchResultsForProvider,
      fetchResults,
      dispatch,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => {
  return {
    activityChartData: state.reducers.hcpi.activityChartData,
    chartMessages: state.intl.messages.components.chart,
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewResults);
