import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import RequireSubFeature from "components/hoc/Require_Sub_Feature";
import * as actions from "../actions";
import Questions from "./Questions";
import Result from "./Result";
import config from "../config";
import FMPIMetaDataForm from "../forms/FMPI_Meta_Data_Form";
import { ACTION_RESET } from "actions/types";

class NewQuery extends Component {
  state = {
    currentQuestion: 1,
    currentAnswer: {},
    answers: [],
    showResult: false,
    canGoBack: true,
    hasGoneBack: false,
    prevAnswer: {},
  };

  UNSAFE_componentWillMount() {
    const { fmpi, locale } = this.props;
    if (!fmpi.templateFetched || locale !== fmpi.template.language) {
      this.props.fetchQuestions();
    }
    if (!_.isEmpty(fmpi.template)) {
      this.setState({
        numberOfQuestions: fmpi.template.questions.length,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!_.isEmpty(props.fmpi.template)) {
      this.setState({
        numberOfQuestions: props.fmpi.template.questions.length,
      });
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: ACTION_RESET });
  }

  handleNextButtonClick = () => {
    this.setState({
      currentQuestion: this.state.currentQuestion + 1,
      currentAnswer: this.state.lastQuestionAnswer
        ? this.state.lastQuestionAnswer
        : {},
      canGoBack: true,
      hasGoneBack: false,
    });
  };

  registerAnswer = (ans) => {
    this.setState(
      {
        answers: [
          ...this.state.answers.filter((answer) => answer.q !== ans.q),
          ans,
        ],
      },
      () => {
        if (this.state.currentQuestion === this.state.numberOfQuestions) {
        } else {
          this.setState({
            prevAnswer: ans,
          });
          setTimeout(() => {
            this.handleNextButtonClick();
          }, 300);
        }
      }
    );
  };

  handleBackButtonClick = () => {
    this.setState({
      currentQuestion: this.state.currentQuestion - 1,
      canGoBack: false,
      hasGoneBack: true,
      currentAnswer: this.state.prevAnswer,
      showResult: false,
    });
  };

  handleOptionSelect = (e) => {
    this.setState(
      {
        currentAnswer: {
          q: e.target.name,
          a: Number(e.target.value),
          v: Number(e.target.dataset.score),
          calc: e.target.dataset.calc === "true" ? true : false,
        },
      },
      () => {
        this.setState({
          lastQuestionAnswer:
            this.state.currentQuestion === this.state.numberOfQuestions
              ? this.state.currentAnswer
              : {},
        });
        this.registerAnswer(this.state.currentAnswer);
      }
    );
  };

  handleSubmit = () => {
    this.setState({
      showResult: true,
    });
  };

  renderContent() {
    const { questions } = this.props.fmpi.template;
    const { showResult } = this.state;

    if (showResult)
      return (
        <Result
          answers={this.state.answers}
          results={config.viewResult.results}
          messages={this.props.messages.components.result}
          locale={this.props.locale}
        />
      );
    return (
      <Questions
        questions={questions}
        currentQuestion={this.state.currentQuestion}
        currentAnswer={this.state.currentAnswer}
        handleNextButtonClick={this.handleNextButtonClick}
        handleOptionSelect={this.handleOptionSelect}
      />
    );
  }

  renderButtons() {
    const {
      currentQuestion,
      canGoBack,
      currentAnswer,
      showResult,
      hasGoneBack,
      numberOfQuestions,
    } = this.state;
    const { buttons, locale } = this.props;
    const lastQuestion = currentQuestion === numberOfQuestions;
    return (
      <div className="buttons">
        {currentQuestion > 1 && canGoBack && !showResult && (
          <div className="button" onClick={() => this.handleBackButtonClick()}>
            {buttons.back[locale]}
          </div>
        )}
        {!lastQuestion && hasGoneBack && (
          <div className="button" onClick={() => this.handleNextButtonClick()}>
            {buttons.next[locale]}
          </div>
        )}
        {lastQuestion && !_.isEmpty(currentAnswer) && !showResult && (
          <div className="button is-info" onClick={() => this.handleSubmit()}>
            {buttons.showResults[locale]}
          </div>
        )}
      </div>
    );
  }

  renderMetaForm() {
    const { showResult, answers } = this.state;
    const { fmpi } = this.props;

    if (!showResult) return null;
    return (
      <FMPIMetaDataForm
        valuesToEdit={{}}
        {...this.props}
        answers={answers}
        usedqrytemplate={fmpi.template.usedqrytemplate}
      />
    );
  }

  renderFooter() {
    const { footer } = this.props.fmpi.template;
    return (
      <div className="help has-text-centered">
        {footer.text.map((text, i) => (
          <p key={i}>{text}</p>
        ))}
        <p>{footer.email}</p>
      </div>
    );
  }

  render() {
    const { fmpi } = this.props;

    if (_.isEmpty(fmpi.template)) return null;

    return (
      <>
        <div className="columns is-mobile is-centered">
          <div className="column is-5-desktop is-full-mobile is-6-tablet">
            {this.renderContent()}
            {this.renderButtons()}
            {this.renderMetaForm()}
          </div>
        </div>
        {this.renderFooter()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    buttons: state.intl.messages.components.buttons.common,
  };
};

export default RequireSubFeature(connect(mapStateToProps, actions)(NewQuery));
