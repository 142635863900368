import React, { Component } from "react";
import { connect } from "react-redux";
import { temperature as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "forms/fields/temperature";
import { FormTitle, FormButtons } from "components/form";

class TemperatureEntryForm extends Component {
  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (
        !values.medicine &&
        (field.name === "medicine1" ||
          field.name === "medicine2" ||
          field.name === "medicine3")
      )
        return null;
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  }

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      viewMode,
      editMode,
      cancel,
      defaultRoute,
    } = this.props;
    return (
      <Form>
        <FormTitle group={"trainingDiary"} />
        {this.renderFields()}
        <br />
        <FormButtons
          errors={errors}
          dirty={dirty}
          isSubmitting={isSubmitting}
          viewMode={viewMode}
          editMode={editMode}
          cancel={cancel}
          linkTo={defaultRoute}
        />
        <br />
      </Form>
    );
  }
}

TemperatureEntryForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet }) {
    return {
      date:
        moment(valuesToEdit.date).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      time: valuesToEdit.time || moment().format("HH:mm"),
      temperature: valuesToEdit.temperature || "",
      medicine: valuesToEdit.medicine || false,
      medicine1: valuesToEdit.medicine1 || "",
      medicine2: valuesToEdit.medicine2 || "",
      medicine3: valuesToEdit.medicine3 || "",
      notes: valuesToEdit.notes || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    time: Yup.string().required("required"),
    temperature: Yup.number()
      .required("required")
      .min(34, "greaterThanOrEqualTo-35")
      .max(41, "lessThanOrEqualTo-42"),
    medicine: Yup.boolean(),
    medicine1: Yup.string().when("medicine", {
      is: true,
      then: Yup.string().required("required").max(30, "max-30"),
      otherwise: Yup.string(),
    }),
    medicine2: Yup.string().when("medicine", {
      is: true,
      then: Yup.string().max(30, "max-30"),
      otherwise: Yup.string(),
    }),
    medicine3: Yup.string().when("medicine", {
      is: true,
      then: Yup.string().max(30, "max-30"),
      otherwise: Yup.string(),
    }),
    notes: Yup.string().max(250, "max-250"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries_temperature`;
    if (props.editMode) {
      return props.updateTemperatureEntry(
        values,
        setSubmitting,
        props.history,
        successRoute,
        props.providerView
      );
    }
    props.addTemperatureEntry(
      values,
      setSubmitting,
      props.history,
      successRoute,
      props.providerContact,
      props.providerView
    );
  },
})(TemperatureEntryForm);

export default connect(null, actions)(TemperatureEntryForm);
