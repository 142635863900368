import React from "react"
import { features, litterFeatures } from "assets"
import Landing from "components/features/Landing"
import AddNew from "../components/Add_New"
import ViewLinks from "./View_Links"

const litterUsefulLinksConfig = {
  name: "litterUsefulLinks",
  type: "litterFeature",
  defaultRoute: "/userhome/dashboard/litter/useful_links",
  menuIcon: {
    img: features.usefulLinks.main,
    alt: "litter_useful_links_icon",
  },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: litterFeatures.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "addLinks",
      menuItem: true,
      image: {
        img: features.usefulLinks.addNew,
        alt: "litter_useful_links_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: (props) => <AddNew litterUsefulLinks {...props} />,
      path: "/add_new",
    },
    {
      disabled: false,
      name: "viewLinks",
      menuItem: true,
      image: {
        img: features.usefulLinks.main,
        alt: "litter_useful_links_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: ViewLinks,
      path: "/view_links",
    },
  ],
}

export default litterUsefulLinksConfig
