import { features } from "assets";

const selectOptions = {
  addPet: {
    petType: {
      default: "selectPetType",
      options: ["dog", "cat", "horse", "other"],
    },
    petSex: {
      default: "selectPetSex",
      options: ["male", "female"],
    },
    hasBeenSterillized: {
      default: "selectAnOption",
      options: ["yes", "no", "na"],
    },
    living: {
      options: ["yes", "no"],
    },
  },
  feedback: {
    feedbackCategory: {
      default: "selectCategory",
      options: ["general", "feature"],
    },
  },
  support: {
    supportCategory: {
      default: "selectCategory",
      options: [
        "general",
        "feature",
        "account",
        "subscription",
        "payments",
        "technical",
        "other",
      ],
    },
  },
  common: {
    featureCategory: {
      default: "selectFeature",
      options: [
        "appointments",
        "painIndex",
        "tasks",
        "vaccinations",
        "healthDiary",
        "foodDiary",
        "weight",
        "parasites",
        "heatDiary",
        "homeCare",
        "trainingDiary",
        "awardCabinet",
        "usefulLinks",
        "stressLevel",
        "reporting",
        "hoofCare",
        "stableDiary",
        "other",
      ],
    },
  },
  providerRegister: {
    serviceType: {
      default: "selectAnOption",
      options: [
        "veterinaryServices",
        "massageAndPhysioServices",
        "nutritionalServices",
        "groomingServices",
        "trainingServices",
        "animalCareServices",
        "breeders",
        "other",
      ],
    },
  },
  foodDiary: {
    foodCategory: {
      options: [
        "dryFood",
        "rawFood",
        "cookedFood",
        "treats",
        "bones",
        "leftovers",
        "supplements",
        "carbohydrates",
        "vegetables",
        "dairy",
        "foraging",
        "fluids",
        "grass",
        "hay",
        "horseTreats",
        "oats",
        "pellets",
      ],
      categorySelection: [
        {
          name: "dryFood",
          icon: features.foodDiary.foodCategories.dryFood,
          petTypes: ["dog", "cat", "other"],
        },
        {
          name: "rawFood",
          icon: features.foodDiary.foodCategories.rawFood,
          petTypes: ["dog", "cat", "other"],
        },
        {
          name: "cookedFood",
          icon: features.foodDiary.foodCategories.cookedFood,
          petTypes: ["dog", "cat", "other"],
        },
        {
          name: "treats",
          icon: features.foodDiary.foodCategories.treats,
          petTypes: ["dog", "cat", "other"],
        },
        {
          name: "bones",
          icon: features.foodDiary.foodCategories.bones,
          petTypes: ["dog", "cat", "other"],
        },
        {
          name: "leftovers",
          icon: features.foodDiary.foodCategories.leftovers,
          petTypes: ["dog", "cat", "other"],
        },
        {
          name: "supplements",
          icon: features.foodDiary.foodCategories.supplements,
          petTypes: ["dog", "cat", "other", "horse"],
        },
        {
          name: "carbohydrates",
          icon: features.foodDiary.foodCategories.carbohydrates,
          petTypes: ["dog", "cat", "other", "horse"],
        },
        {
          name: "vegetables",
          icon: features.foodDiary.foodCategories.vegetables,
          petTypes: ["dog", "cat", "other"],
        },
        {
          name: "dairy",
          icon: features.foodDiary.foodCategories.dairy,
          petTypes: ["dog", "cat", "other"],
        },
        {
          name: "foraging",
          icon: features.foodDiary.foodCategories.foraging,
          petTypes: ["dog", "cat", "other"],
        },
        {
          name: "fluids",
          icon: features.foodDiary.foodCategories.fluids,
          petTypes: ["dog", "cat", "other", "horse"],
        },
        {
          name: "grass",
          icon: features.foodDiary.foodCategories.grass,
          petTypes: ["horse"],
        },
        {
          name: "hay",
          icon: features.foodDiary.foodCategories.hay,
          petTypes: ["horse"],
        },
        {
          name: "horseTreats",
          icon: features.foodDiary.foodCategories.horseTreats,
          petTypes: ["horse"],
        },
        {
          name: "oats",
          icon: features.foodDiary.foodCategories.oats,
          petTypes: ["horse"],
        },
        {
          name: "pellets",
          icon: features.foodDiary.foodCategories.pellets,
          petTypes: ["horse"],
        },
      ],
    },
    frequency: {
      default: "selectAnOption",
      options: ["notSpecified", "freq4", "freq3", "freq2", "freq1"],
    },
    foodDiaryQ1: {
      default: "selectAnOption",
      options: ["notSpecified", "fdq11", "fdq12", "fdq13", "fdq14", "fdq15"],
    },
    foodDiaryQ2: {
      default: "selectAnOption",
      options: ["notSpecified", "fdq21", "fdq22", "fdq23"],
    },
    foodAmountUnit: {
      options: [
        "gram",
        "kilogram",
        "millilitre",
        "decilitre",
        "litre",
        "pieces",
        "teaspoon",
        "tablespoon",
      ],
    },
  },
  parasites: {
    parasiteType: {
      default: "selectAType",
      options: ["internal", "external"],
    },
    dosageAmountUnit: {
      options: [
        "microgram",
        "milligram",
        "gram",
        "millilitre",
        "decilitre",
        "litre",
        "pieces",
        "tablet",
        "capsule",
        "drop",
      ],
    },
  },
  healthDiaryMedicine: {
    medicineAmountUnit: {
      options: [
        "microgram",
        "milligram",
        "gram",
        "millilitre",
        "decilitre",
        "litre",
        "pieces",
        "tablet",
        "capsule",
        "drop",
      ],
    },
  },
  timeHrs: {
    options: [0, 3600, 7200, 10800],
  },
  timeMins: {
    options: [0, 900, 1800, 2700],
  },
  appointments: {},
  bodyMarkerDetail: {
    bodyMarkerDetailType: {
      default: "selectAType",
      options: ["mass", "rash", "wound", "other"],
    },
  },
  toothMarker: {
    toothState: {
      options: [
        "removed",
        "missing",
        "broken",
        "loose",
        "wornTooth",
        "enamelErosion",
        "periodontalPocket",
        "biteMisalignment",
        "xrayPerformed",
        "periodontitis",
        "resorptionLesion",
        "cyst",
        "unruptured",
        "gingivitis",
        "dentalCalculus",
        "colorChange",
      ],
    },
  },
};

export default selectOptions;
