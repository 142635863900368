import React from "react"
import Image from "components/image"

function CustomCellStressLevel(props) {
  return (
    <Image
      imgPath={`components.buttons.stressLevel${props.value}`}
      imgClassName="is-rounded"
      figureSize="24x24"
      figureClassName=""
      imgContainerStyle={{
        marginLeft: "auto",
        marginRight: "auto"
      }}
    />
  )
}

export default CustomCellStressLevel
