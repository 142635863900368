import React from "react";

export default function TeethDog(props) {
  const { onClick, handleToothState, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 248.89 439.5"
      width="248.89"
      height="439.5"
      {...rest}
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".cls-1,.cls-3{fill:none;stroke-linecap:round;}.cls-1{stroke:#999;}.cls-1,.cls-2,.cls-3{stroke-miterlimit:10;}.cls-2{fill:#f9f7e1;cursor:pointer;}.cls-2,.cls-3{stroke:#333;}.cls-4{font-size:14px;font-family:SegoeUI, Segoe UI;}.cls-5{font-size:10px;fill:#4d4d4d;font-family:SegoeUI-Semibold, Segoe UI;font-weight:600;}",
          }}
        />
      </defs>
      <g id="dog">
        <g id="jaw">
          <path
            className="cls-1"
            d="M124.24,218.11V20.18a23.09,23.09,0,0,0-5.16,0c-10.56,1.23-17.13,9.7-18.34,11.26-6.3,8.14-1.93,11.87-6.81,30.82a166.79,166.79,0,0,1-5.68,18.12c-2.25,5.83-5.45,11.45-8.4,17-1.51,2.85-3,5.73-4.23,8.69-1,2.37-2.52,5.28-2,7.93.16.78.58,2.2-.16,3s-1.88.6-2.52.6c-1.24,0-3.39.55-7.4,5.77-12.1,15.77-16.15,28.75-16.15,28.75-5.56,17.78-7,42.53,9.29,55.51a21.82,21.82,0,0,0,4.93,3c6.88,3,10.18.13,16.59,4.15a24.71,24.71,0,0,1,9.19,11.26"
          />
          <path
            className="cls-1"
            d="M124.24,218.11V20.18a23,23,0,0,1,5.15,0c10.56,1.23,17.13,9.7,18.34,11.26,6.31,8.14,1.93,11.87,6.82,30.82a166.74,166.74,0,0,0,5.67,18.12c2.25,5.83,5.46,11.45,8.41,17,1.5,2.85,3,5.73,4.22,8.69,1,2.37,2.52,5.28,2,7.93-.16.78-.59,2.2.15,3s1.88.6,2.52.6c1.24,0,3.4.55,7.41,5.77,12.09,15.77,16.14,28.75,16.14,28.75,5.56,17.78,7,42.53-9.29,55.51a21.82,21.82,0,0,1-4.93,3c-6.87,3-10.18.13-16.59,4.15a24.71,24.71,0,0,0-9.19,11.26"
          />
          <path
            className="cls-1"
            d="M45.84,191.52a82.64,82.64,0,0,1-12.21,26c-1.61,2.26-3.25,4.33-4.89,6.22"
          />
          <path
            className="cls-1"
            d="M57.67,208.43A31.86,31.86,0,0,0,35,226.85"
          />
          <path
            className="cls-1"
            d="M202.57,191.52a82.64,82.64,0,0,0,12.21,26c1.61,2.26,3.25,4.33,4.89,6.22"
          />
          <path
            className="cls-1"
            d="M190.74,208.43a31.86,31.86,0,0,1,22.71,18.42"
          />
          <path
            className="cls-1"
            d="M124.24,408.33a34.17,34.17,0,0,0,4.08,0,28.81,28.81,0,0,0,9.61-1.92,23.77,23.77,0,0,0,7.85-5,24.09,24.09,0,0,0,6.22-12c5.36-22.87,4.66-46.71,11.67-69.26,6.75-21.67,15.24-42.9,17.17-65.73l.06-.7a149.46,149.46,0,0,0,.3-19.51V234"
          />
          <path
            className="cls-1"
            d="M124.24,408.33a34.34,34.34,0,0,1-4.09,0,28.76,28.76,0,0,1-9.6-1.92,23.57,23.57,0,0,1-7.85-5,24.12,24.12,0,0,1-6.23-12c-5.36-22.87-4.66-46.71-11.67-69.26-6.74-21.67-15.24-42.9-17.16-65.73l-.06-.7a149.46,149.46,0,0,1-.3-19.51V234"
          />
          <path
            className="cls-1"
            d="M84.74,234a71.52,71.52,0,0,0,1.48,21.78c1.34,5.91,3.57,11.59,5.21,17.42,1.74,6.15,3.21,12.37,4.72,18.58,4.26,17.5,9.12,34.42,10.81,40.15a382.34,382.34,0,0,0,17.28,46.67"
          />
          <path
            className="cls-1"
            d="M164,234a71.52,71.52,0,0,1-1.48,21.78c-1.34,5.91-3.57,11.59-5.22,17.42-1.73,6.15-3.2,12.37-4.71,18.58-4.26,17.5-9.12,34.42-10.81,40.15a382.34,382.34,0,0,1-17.28,46.67"
          />
          <line
            className="cls-1"
            x1="124.24"
            y1="378.56"
            x2="124.24"
            y2="407.19"
          />
        </g>
        <g id="upper">
          <g id="incisors">
            <g id="right">
              <path
                id="_101"
                data-name={101}
                onClick={(e) => onClick(e, true)}
                style={props.handleToothState("_101")}
                className="cls-2"
                d="M122.39,14.47a3.91,3.91,0,0,0-5.28-.06c-1.69,1.38-1.33,4-1,6.05.6,4.27,3.15,9.3,5,9,2-.33,2.35-6.94,2.46-9.28C123.73,15.77,122.78,14.8,122.39,14.47Z"
              />
              <path
                id="_102"
                data-name={102}
                onClick={(e) => onClick(e, true)}
                style={props.handleToothState("_102")}
                className="cls-2"
                d="M113,15.66c-1.92-1.68-5.62-.76-7,1.81-1.12,2.07-.41,4.53.17,6.53,1,3.59,3.74,8.51,5.86,8,2.35-.54,2.87-7.47,2.53-11.5C114.38,18.5,114.21,16.72,113,15.66Z"
              />
              <path
                id="_103"
                data-name={103}
                onClick={(e) => onClick(e, true)}
                style={props.handleToothState("_103")}
                className="cls-2"
                d="M96.46,38.46c2.72,1.87,10.46-1.67,11-6,.74-5.87-12-11.9-14-10-.61.58.11,1.57,1,6C95.87,35.46,95,37.45,96.46,38.46Z"
              />
            </g>
            <g id="left">
              <path
                id="_201"
                data-name={201}
                onClick={(e) => onClick(e, true)}
                style={props.handleToothState("_201")}
                className="cls-2"
                d="M126.12,14.47a4.38,4.38,0,0,1,5.27-.06c1.7,1.38,1.34,4,1,6.05-.6,4.27-3.14,9.3-5,9-2-.33-2.34-6.94-2.45-9.28C124.78,15.77,125.73,14.8,126.12,14.47Z"
              />
              <path
                id="_202"
                data-name={202}
                onClick={(e) => onClick(e, true)}
                style={props.handleToothState("_202")}
                className="cls-2"
                d="M135.52,15.66c1.91-1.68,5.62-.76,7,1.81,1.13,2.07.42,4.53-.16,6.53-1,3.59-3.74,8.51-5.86,8-2.35-.54-2.87-7.47-2.54-11.5C134.15,18.5,134.32,16.72,135.52,15.66Z"
              />
              <path
                id="_203"
                data-name={203}
                onClick={(e) => onClick(e, true)}
                style={props.handleToothState("_203")}
                className="cls-2"
                d="M152.09,38.46c-2.72,1.87-10.46-1.67-11-6-.73-5.87,12-11.9,14-10,.61.58-.11,1.57-1,6C152.68,35.46,153.56,37.45,152.09,38.46Z"
              />
            </g>
          </g>
          <g id="canines">
            <g id="right-2" data-name="right">
              <path
                id="_104"
                data-name={104}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_104")}
                className="cls-2"
                d="M74.46,38.46c1.77-2,8.83,1.83,11,3A37.88,37.88,0,0,1,99,53.9c3.34,5,7,12.38,5.45,18.56-2,8.1-11.67,14.18-16,12s.09-10.29-4-24C80.55,47.35,72.41,40.74,74.46,38.46Z"
              />
            </g>
            <g id="left-2" data-name="left">
              <path
                id="_204"
                data-name={204}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_204")}
                className="cls-2"
                d="M173.5,38.46c-1.77-2-8.83,1.83-11,3A38,38,0,0,0,149,53.9c-3.34,5-7,12.38-5.45,18.56,2,8.1,11.67,14.18,16,12s-.09-10.29,4-24C167.41,47.35,175.55,40.74,173.5,38.46Z"
              />
            </g>
          </g>
          <g id="premolars">
            <g id="right-3" data-name="right">
              <path
                id="_105"
                data-name={105}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_105")}
                className="cls-2"
                d="M87.55,88.62a2.87,2.87,0,0,1,4.17,1.31c.76,1.73-.21,3.86-1,5.42s-1.72,3.87-3.33,4.77-4.34.22-4.14-1.88A17.88,17.88,0,0,1,85,92.67C85.58,91.29,86.17,89.41,87.55,88.62Z"
              />
              <path
                id="_106"
                data-name={106}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_106")}
                className="cls-2"
                d="M81.59,103.76a3,3,0,0,1,4.48,2c.68,2.27-.61,4.92-1.6,6.85s-2.28,4.8-4.16,5.81-4.83-.08-4.39-2.75a25.88,25.88,0,0,1,2.41-7C79.14,107,80,104.65,81.59,103.76Z"
              />
              <path
                id="_107"
                data-name={107}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_107")}
                className="cls-2"
                d="M76.55,122.5c1.75,2.6-1.15,7-2.64,9.22-1.16,1.74-4.77,7.17-8.62,6.62-1.79-.26-3.77-1.35-3.37-3.45a6.42,6.42,0,0,1,.79-1.85,88.88,88.88,0,0,1,6.12-9.27c.9-1.2,2-2.81,3.59-3A4.57,4.57,0,0,1,76.55,122.5Z"
              />
              <path
                id="_108"
                data-name={108}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_108")}
                className="cls-2"
                d="M59.2,139.91c4.33-.28,6.06,3.82,8.76,6.27,2.08,1.89,5.41,3.54,4.89,6.91-.65,4.18-5.5,3.71-8.44,5s-2.87,3.94-5.11,7c-2.41,3.3-7,6.5-8.89,5.56C45.72,168.33,52.2,140.37,59.2,139.91Z"
              />
              <g id="detail">
                <path
                  id="crevice"
                  className="cls-3"
                  d="M67.19,151.67a8,8,0,0,1-5.73-1.21,7.86,7.86,0,0,1-3-4"
                />
                <path
                  id="crevice-2"
                  data-name="crevice"
                  className="cls-3"
                  d="M59.92,149.22a15.71,15.71,0,0,1-1.46,9.24,15.37,15.37,0,0,1-4,5"
                />
              </g>
            </g>
            <g id="left-3" data-name="left">
              <path
                id="_205"
                data-name={205}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_205")}
                className="cls-2"
                d="M161.26,88.62a2.87,2.87,0,0,0-4.17,1.31c-.76,1.73.21,3.86,1,5.42s1.72,3.87,3.33,4.77,4.34.22,4.14-1.88a17.88,17.88,0,0,0-1.69-5.57C163.23,91.29,162.64,89.41,161.26,88.62Z"
              />
              <path
                id="_206"
                data-name={206}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_206")}
                className="cls-2"
                d="M167.22,103.76a3,3,0,0,0-4.48,2c-.68,2.27.61,4.92,1.6,6.85s2.28,4.8,4.16,5.81,4.82-.08,4.39-2.75a25.88,25.88,0,0,0-2.41-7C169.67,107,168.84,104.65,167.22,103.76Z"
              />
              <path
                id="_207"
                data-name={207}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_207")}
                className="cls-2"
                d="M172.26,122.5c-1.75,2.6,1.15,7,2.64,9.22,1.16,1.74,4.77,7.17,8.62,6.62,1.79-.26,3.77-1.35,3.36-3.45a6.13,6.13,0,0,0-.78-1.85,88.88,88.88,0,0,0-6.12-9.27c-.9-1.2-1.95-2.81-3.59-3A4.57,4.57,0,0,0,172.26,122.5Z"
              />
              <path
                id="_208"
                data-name={208}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_208")}
                className="cls-2"
                d="M189.61,139.91c-4.33-.28-6.06,3.82-8.76,6.27-2.08,1.89-5.41,3.54-4.89,6.91.65,4.18,5.5,3.71,8.44,5,2.79,1.21,2.87,3.94,5.11,7,2.41,3.3,7,6.5,8.89,5.56C203.09,168.33,196.61,140.37,189.61,139.91Z"
              />
              <g id="detail-2" data-name="detail">
                <path
                  id="crevice-3"
                  data-name="crevice"
                  className="cls-3"
                  d="M181.62,151.67a7.72,7.72,0,0,0,8.73-5.21"
                />
                <path
                  id="crevice-4"
                  data-name="crevice"
                  className="cls-3"
                  d="M188.89,149.22a15.71,15.71,0,0,0,1.46,9.24,15.37,15.37,0,0,0,4,5"
                />
              </g>
            </g>
          </g>
          <g id="molars">
            <g id="right-4" data-name="right">
              <path
                id="_109"
                data-name={109}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_109")}
                className="cls-2"
                d="M56.3,197.22c-5-.61-8.37-6.54-8.89-11.41-.13-1.22-.73-6.76,3.26-10.07,2.08-1.73,5.58-3,8.29-1.78,1.87.83,2.29,2.74,3.63,4.14,3.31,3.45,9.49.12,11.93,5.37a5.81,5.81,0,0,1-.93,6.15c-1.81,2.15-3.77,1.64-6.13,2.37-2.11.66-3.16,2.63-4.91,3.76A9.7,9.7,0,0,1,56.3,197.22Z"
              />
              <path
                id="_110"
                data-name={110}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_110")}
                className="cls-2"
                d="M58.07,205.07c-1-4.74,6.53-8.2,10.14-8.5,4.19-.35,12.87,2.15,8,7.62a15.53,15.53,0,0,1-9,5C64.19,209.65,58.84,208.73,58.07,205.07Z"
              />
            </g>
            <g id="left-4" data-name="left">
              <path
                id="_209"
                data-name={209}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_209")}
                className="cls-2"
                d="M191.66,197.22c5-.61,8.36-6.54,8.88-11.41.14-1.22.73-6.76-3.25-10.07-2.09-1.73-5.59-3-8.3-1.78-1.87.83-2.29,2.74-3.63,4.14-3.31,3.45-9.48.12-11.93,5.37a5.84,5.84,0,0,0,.93,6.15c1.81,2.15,3.78,1.64,6.13,2.37,2.11.66,3.16,2.63,4.91,3.76A9.73,9.73,0,0,0,191.66,197.22Z"
              />
              <path
                id="_210"
                data-name={210}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_210")}
                className="cls-2"
                d="M189.88,205.07c1-4.74-6.52-8.2-10.14-8.5-4.19-.35-12.87,2.15-8,7.62a15.53,15.53,0,0,0,9,5C183.76,209.65,189.11,208.73,189.88,205.07Z"
              />
            </g>
          </g>
        </g>
        <g id="lower">
          <g id="molars-2" data-name="molars">
            <g id="right-5" data-name="right">
              <path
                id="_411"
                data-name={411}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_411")}
                className="cls-2"
                d="M79.63,242.39c-.06,2.69-1.13,5.23-3.3,5.35-2.34.13-3.43-2.9-3.69-5-.28-2.3.55-6.58,3.17-6.73S79.7,239.21,79.63,242.39Z"
              />
              <path
                id="_410"
                data-name={410}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_410")}
                className="cls-2"
                d="M81,257c-.07,2.89-1.35,5.61-4,5.74-2.81.14-4.11-3.11-4.42-5.41-.34-2.47.66-7.06,3.79-7.22S81.1,253.59,81,257Z"
              />
              <path
                id="_409"
                data-name={409}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_409")}
                className="cls-2"
                d="M78.25,266.68c-2.67.72-2.94,10.31-1.29,17a29.79,29.79,0,0,0,3.89,9.38,14,14,0,0,0,3.57,3.76c.46.32,3.47,2.23,4.93,1.17.77-.56.79-1.72.8-2.76a51,51,0,0,0-2.07-16.17,26.23,26.23,0,0,0-3.61-7.31C83.41,270.27,80.43,266.09,78.25,266.68Z"
              />
              <g id="detail-3" data-name="detail">
                <path
                  id="crevice-5"
                  data-name="crevice"
                  className="cls-3"
                  d="M79.1,273.35a22,22,0,0,0,2.08,3.74,21.64,21.64,0,0,0,3.92,4.28,30.84,30.84,0,0,0-.27,4.64,31.65,31.65,0,0,0,.8,6.47"
                />
                <path
                  id="crevice-6"
                  data-name="crevice"
                  className="cls-3"
                  d="M79.1,273.35a22,22,0,0,0,2.08,3.74,21.64,21.64,0,0,0,3.92,4.28,30.84,30.84,0,0,0-.27,4.64,31.65,31.65,0,0,0,.8,6.47"
                />
              </g>
            </g>
            <g id="left-5" data-name="left">
              <path
                id="_311"
                data-name={311}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_311")}
                className="cls-2"
                d="M171.11,242.39c.06,2.69,1.12,5.23,3.29,5.35,2.35.13,3.44-2.9,3.7-5,.28-2.3-.56-6.58-3.17-6.73S171,239.21,171.11,242.39Z"
              />
              <path
                id="_310"
                data-name={310}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_310")}
                className="cls-2"
                d="M169.72,257c.07,2.89,1.35,5.61,3.94,5.74,2.81.14,4.12-3.11,4.43-5.41.33-2.47-.67-7.06-3.8-7.22S169.63,253.59,169.72,257Z"
              />
              <path
                id="_309"
                data-name={309}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_309")}
                className="cls-2"
                d="M171.48,266.68c2.68.72,3,10.31,1.29,17a29.54,29.54,0,0,1-3.88,9.38,14.08,14.08,0,0,1-3.58,3.76c-.45.32-3.46,2.23-4.92,1.17-.77-.56-.79-1.72-.8-2.76a51,51,0,0,1,2.07-16.17,26.23,26.23,0,0,1,3.61-7.31C166.32,270.27,169.3,266.09,171.48,266.68Z"
              />
              <g id="detail-4" data-name="detail">
                <path
                  id="crevice-7"
                  data-name="crevice"
                  className="cls-3"
                  d="M170.63,273.35a22,22,0,0,1-2.08,3.74,21.33,21.33,0,0,1-3.91,4.28,30.84,30.84,0,0,1,.27,4.64,31.65,31.65,0,0,1-.8,6.47"
                />
                <path
                  id="crevice-8"
                  data-name="crevice"
                  className="cls-3"
                  d="M170.63,273.35a22,22,0,0,1-2.08,3.74,21.33,21.33,0,0,1-3.91,4.28,30.84,30.84,0,0,1,.27,4.64,31.65,31.65,0,0,1-.8,6.47"
                />
              </g>
            </g>
          </g>
          <g id="premolars-2" data-name="premolars">
            <g id="right-6" data-name="right">
              <path
                id="_408"
                data-name={408}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_408")}
                className="cls-2"
                d="M89.72,302.89c2.65-.5,4.38,2.62,5.24,4.93,1.17,3.14,2.31,12.24-2.49,12.87S86,311.75,86.78,308.1C87,307.08,87.85,303.24,89.72,302.89Z"
              />
              <path
                id="_407"
                data-name={407}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_407")}
                className="cls-2"
                d="M94,325.19c2.2-.41,3.63,2.19,4.35,4.12,1,2.61,1.9,10.2-2.06,10.72s-5.37-7.45-4.71-10.49C91.73,328.69,92.43,325.49,94,325.19Z"
              />
              <path
                id="_406"
                data-name={406}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_406")}
                className="cls-2"
                d="M96.64,343.8c1.8-.35,3,1.85,3.56,3.47.79,2.2,1.58,8.58-1.67,9s-4.41-6.27-3.87-8.83C94.81,346.74,95.38,344,96.64,343.8Z"
              />
              <path
                id="_405"
                data-name={405}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_405")}
                className="cls-2"
                d="M98.24,360.63c1.61,0,2.37,1.77,2.69,3.08.43,1.77.34,6.72-2.55,6.65s-3.09-5.32-2.31-7.2C96.29,362.64,97.11,360.66,98.24,360.63Z"
              />
            </g>
            <g id="left-6" data-name="left">
              <path
                id="_308"
                data-name={308}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_308")}
                className="cls-2"
                d="M159,302.89c-2.66-.5-4.39,2.62-5.25,4.93-1.16,3.14-2.3,12.24,2.5,12.87s6.49-8.94,5.68-12.59C161.71,307.08,160.86,303.24,159,302.89Z"
              />
              <path
                id="_307"
                data-name={307}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_307")}
                className="cls-2"
                d="M154.75,325.19c-2.19-.41-3.63,2.19-4.34,4.12-1,2.61-1.91,10.2,2.06,10.72s5.37-7.45,4.7-10.49C157,328.69,156.29,325.49,154.75,325.19Z"
              />
              <path
                id="_306"
                data-name={306}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_306")}
                className="cls-2"
                d="M152.08,343.8c-1.8-.35-3,1.85-3.56,3.47-.8,2.2-1.58,8.58,1.67,9s4.41-6.27,3.87-8.83C153.91,346.74,153.34,344,152.08,343.8Z"
              />
              <path
                id="_305"
                data-name={305}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_305")}
                className="cls-2"
                d="M150.48,360.63c-1.61,0-2.38,1.77-2.69,3.08-.43,1.77-.35,6.72,2.54,6.65s3.1-5.32,2.32-7.2C152.43,362.64,151.61,360.66,150.48,360.63Z"
              />
            </g>
          </g>
          <g id="canines-2" data-name="canines">
            <g id="right-7" data-name="right">
              <path
                id="_404"
                data-name={404}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_404")}
                className="cls-2"
                d="M102.37,380.33c4.42,6.79-2.56,17.71-7.16,22.67a34,34,0,0,1-13.3,8.79c-2.15.78-10.15,3.08-11.1-.49-.3-1.16,2.15-2.71,3-3.26C85.51,400.11,86,378.27,95.1,377A7.94,7.94,0,0,1,102.37,380.33Z"
              />
            </g>
            <g id="left-7" data-name="left">
              <path
                id="_304"
                data-name={304}
                onClick={(e) => onClick(e)}
                style={props.handleToothState("_304")}
                className="cls-2"
                d="M146.2,380.33c-4.42,6.79,2.56,17.71,7.16,22.67a34,34,0,0,0,13.3,8.79c2.15.78,10.15,3.08,11.09-.49.31-1.16-2.14-2.71-3-3.26-11.73-7.93-12.23-29.77-21.32-31.09A7.94,7.94,0,0,0,146.2,380.33Z"
              />
            </g>
          </g>
          <g id="incisors-2" data-name="incisors">
            <g id="right-8" data-name="right">
              <path
                id="_403"
                data-name={403}
                onClick={(e) => onClick(e, true)}
                style={props.handleToothState("_403")}
                className="cls-2"
                d="M105.89,408.83l0,0c-1.82,1.56-4.86-.06-5.82-2s0-4.47.55-5.79c1.47-3.44,4.87-6.65,6.49-5.76s1,5,.67,8C107.54,405.13,107.29,407.6,105.89,408.83Z"
              />
              <path
                id="_402"
                data-name={402}
                onClick={(e) => onClick(e, true)}
                style={props.handleToothState("_402")}
                className="cls-2"
                d="M114.87,410.79c-1.64,1.34-4.81.61-6-1.44-1-1.64-.35-3.59.14-5.19.89-2.84,3.2-6.75,5-6.37,2,.43,2.46,5.94,2.17,9.14C116,408.53,115.9,410,114.87,410.79Z"
              />
              <path
                id="_401"
                data-name={401}
                onClick={(e) => onClick(e, true)}
                style={props.handleToothState("_401")}
                className="cls-2"
                d="M122.69,411.74a4,4,0,0,1-4.52.05c-1.45-1.1-1.14-3.18-.89-4.82.51-3.4,2.69-7.4,4.28-7.17s2,5.53,2.1,7.39C123.83,410.7,123,411.47,122.69,411.74Z"
              />
            </g>
            <g id="left-8" data-name="left">
              <path
                id="_303"
                data-name={303}
                onClick={(e) => onClick(e, true)}
                style={props.handleToothState("_303")}
                className="cls-2"
                d="M142.8,408.83l0,0c1.82,1.56,4.87-.06,5.82-2s0-4.47-.55-5.79c-1.47-3.44-4.86-6.65-6.49-5.76s-1,5-.67,8C141.16,405.13,141.4,407.6,142.8,408.83Z"
              />
              <path
                id="_302"
                data-name={302}
                onClick={(e) => onClick(e, true)}
                style={props.handleToothState("_302")}
                className="cls-2"
                d="M133.82,410.79c1.64,1.34,4.81.61,6-1.44,1-1.64.36-3.59-.14-5.19-.88-2.84-3.2-6.75-5-6.37-2,.43-2.46,5.94-2.17,9.14C132.65,408.53,132.8,410,133.82,410.79Z"
              />
              <path
                id="_301"
                data-name={301}
                onClick={(e) => onClick(e, true)}
                style={props.handleToothState("_301")}
                className="cls-2"
                d="M126,411.74a4,4,0,0,0,4.51.05c1.46-1.1,1.14-3.18.89-4.82-.51-3.4-2.69-7.4-4.27-7.17-1.75.26-2,5.53-2.1,7.39C124.86,410.7,125.68,411.47,126,411.74Z"
              />
            </g>
          </g>
        </g>
        <g id="text-2" data-name="text">
          <text className="cls-4" transform="translate(4.15 12.99)">
            {props.labels.rightUpper[props.locale]}
          </text>
          <text
            id="Left_Upper"
            data-name="Left Upper"
            className="cls-4"
            transform="translate(186.71 12.99)"
          >
            {props.labels.leftUpper[props.locale]}
          </text>
          <text className="cls-4" transform="translate(187.34 435.41)">
            {props.labels.leftLower[props.locale]}
          </text>
          <text className="cls-4" transform="translate(3.61 435.41)">
            {props.labels.rightLower[props.locale]}
          </text>
        </g>
        <g id="numbering">
          <g id="upper_left">
            <text className="cls-5" transform="translate(129.97 9.58)">
              201
            </text>
            <text className="cls-5" transform="translate(146.3 17.45)">
              202
            </text>
            <text className="cls-5" transform="translate(159.27 28.31)">
              203
            </text>
            <text className="cls-5" transform="translate(171.16 59.31)">
              204
            </text>
            <text className="cls-5" transform="translate(172.49 96.09)">
              205
            </text>
            <text className="cls-5" transform="translate(179.47 112.09)">
              206
            </text>
            <text className="cls-5" transform="translate(194.4 129.57)">
              207
            </text>
            <text className="cls-5" transform="translate(206.79 156.34)">
              208
            </text>
            <text className="cls-5" transform="translate(208.78 189.82)">
              209
            </text>
            <text className="cls-5" transform="translate(185.43 221.31)">
              210
            </text>
          </g>
          <g id="upper_right">
            <text className="cls-5" transform="translate(105.73 9.58)">
              101
            </text>
            <text className="cls-5" transform="translate(87.06 17.45)">
              102
            </text>
            <text className="cls-5" transform="translate(74.03 28.31)">
              103
            </text>
            <text className="cls-5" transform="translate(61.92 59.31)">
              104
            </text>
            <text className="cls-5" transform="translate(62.25 96.09)">
              105
            </text>
            <text className="cls-5" transform="translate(55.24 112.09)">
              106
            </text>
            <text className="cls-5" transform="translate(42.16 129.57)">
              107
            </text>
            <text className="cls-5" transform="translate(27.56 156.34)">
              108
            </text>
            <text className="cls-5" transform="translate(25.54 189.82)">
              109
            </text>
            <text className="cls-5" transform="translate(52.2 221.31)">
              110
            </text>
          </g>
          <g id="lower_left">
            <text className="cls-5" transform="translate(186.72 244.66)">
              311
            </text>
            <text className="cls-5" transform="translate(185.96 260.66)">
              310
            </text>
            <text className="cls-5" transform="translate(180.18 288.66)">
              309
            </text>
            <text className="cls-5" transform="translate(170.58 317.89)">
              308
            </text>
            <text className="cls-5" transform="translate(164.46 337.71)">
              307
            </text>
            <text className="cls-5" transform="translate(161.76 354.95)">
              306
            </text>
            <text className="cls-5" transform="translate(159.78 369.95)">
              305
            </text>
            <text className="cls-5" transform="translate(169.16 395.07)">
              304
            </text>
            <text className="cls-5" transform="translate(144.81 418.49)">
              303
            </text>
            <text className="cls-5" transform="translate(137.1 428.41)">
              302
            </text>
            <text className="cls-5" transform="translate(129.45 437.7)">
              301
            </text>
          </g>
          <g id="lower_right">
            <text className="cls-5" transform="translate(48.62 244.66)">
              411
            </text>
            <text className="cls-5" transform="translate(47.85 260.66)">
              410
            </text>
            <text className="cls-5" transform="translate(52.07 288.66)">
              409
            </text>
            <text className="cls-5" transform="translate(62.47 317.89)">
              408
            </text>
            <text className="cls-5" transform="translate(67.36 337.71)">
              407
            </text>
            <text className="cls-5" transform="translate(69.66 354.95)">
              406
            </text>
            <text className="cls-5" transform="translate(72.67 369.95)">
              405
            </text>
            <text className="cls-5" transform="translate(64.06 395.07)">
              404
            </text>
            <text className="cls-5" transform="translate(87.7 418.49)">
              403
            </text>
            <text className="cls-5" transform="translate(96 428.41)">
              402
            </text>
            <text className="cls-5" transform="translate(106.34 437.7)">
              401
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}
