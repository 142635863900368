import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Section from "components/layout/Section";
import LangSelector from "components/lang_selector";

function Landing({ messages, locale }) {
  return (
    <div className="hero-body">
      <div className="container">
        <Section className="has-text-centered">
          <div className="title is-4">
            {messages.landing.main.title[locale]}
          </div>
          <div className="subtitle is-6">
            {messages.landing.main.subtitle[locale]}
          </div>
          <div className="buttons is-centered">
            <Link to={"/register"} className="button is-success">
              {messages.buttons.tryNow[locale]}
            </Link>
            <Link to={"/login"} className="button">
              {messages.buttons.login[locale]}
            </Link>
          </div>
        </Section>
        <Section className="has-text-centered">
          <div className="title is-4">
            {messages.landing.petServices.title[locale]}
          </div>
          <div className="subtitle is-6">
            {messages.landing.petServices.subtitle[locale]}
          </div>
          <div className="buttons is-centered">
            <Link to={"/pet_services_portal"} className="button">
              {messages.buttons.petServicesPortal[locale]}
            </Link>
          </div>
        </Section>
        <Section className="has-text-centered">
          <LangSelector />
        </Section>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      landing: state.intl.messages.containers.auth.landing,
      buttons: state.intl.messages.components.buttons.auth,
    },
  };
};

export default connect(mapStateToProps)(Landing);
