import assets from "assets";
import ProviderInfo from "./Provider_Info";
import ManagePassword from "./Manage_Password";
import Settings from "./Settings";

const providerAccountRoutes = [
  {
    name: "providerInfo",
    view: "providerAccount",
    disabled: false,
    exact: true,
    path: "/providerhome/account/info",
    component: ProviderInfo,
    img: {
      src: assets.user.profile,
      alt: "provider_info",
    },
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: true,
  },
  {
    name: "managePassword",
    view: "providerAccount",
    disabled: false,
    exact: true,
    path: "/providerhome/account/password",
    component: ManagePassword,
    img: {
      src: assets.user.password,
      alt: "provider_password_management",
    },
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: true,
  },
  {
    name: "settings",
    view: "providerAccount",
    disabled: false,
    exact: true,
    path: "/providerhome/account/settings",
    component: Settings,
    img: {
      src: assets.provider.providerSettings,
      alt: "provider_settings",
    },
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: true,
  },
];

export default providerAccountRoutes;
