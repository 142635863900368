import React, { Component } from "react";
import { connect } from "react-redux";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "forms/fields/add_litter_member";
import { FormTitle, FormButtons } from "components/form";
import WEIGHT_INPUT_VALUES from "constants/fine_weight_control";

class AddLitterMemberForm extends Component {
  renderFields() {
    const { viewMode, editMode, values, userPreferredUnit } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (field.renderCondition && !field.renderCondition(userPreferredUnit))
        return null;
      return (
        <div key={i} style={{ marginBottom: 8 }}>
          <Field
            viewMode={viewMode}
            editMode={editMode}
            values={values}
            weightUnit={userPreferredUnit}
            {...field}
          />
          {field.lastInGroup && <br />}
        </div>
      );
    });
  }

  render() {
    const { errors, dirty, isSubmitting, editMode, viewMode, cancel } =
      this.props;
    return (
      <Form>
        <FormTitle group={"addLitterMember"} />
        <br />
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={"/userhome/dashboard/litter"}
        />
        <br />
      </Form>
    );
  }
}

AddLitterMemberForm = withFormik({
  mapPropsToValues({ valuesToEdit, litter, locale, userPreferredUnit }) {
    return {
      litterMemberGivenName: valuesToEdit.petGivenName || "",
      litterMemberSex: valuesToEdit.petSex || "",
      litterMemberBreed: valuesToEdit.petBreed || litter.litterBreed,
      litterMemberPedigreeURL: valuesToEdit.litterMemberPedigreeURL || "",
      litterMemberType: valuesToEdit.petType || litter.litterType,
      litterMemberDob: !valuesToEdit.petDob
        ? moment(litter.litterDob).format("YYYY-MM-DD")
        : moment(valuesToEdit.petDob).format("YYYY-MM-DD"),
      litterMemberDobTime: valuesToEdit.litterMemberDobTime || "",
      litterMemberBirthWeightKg: valuesToEdit.litterMemberBirthWeightKg || 0,
      litterMemberBirthWeightG: valuesToEdit.litterMemberBirthWeightG || 0,
      litterMemberBirthWeightLbs: valuesToEdit.litterMemberBirthWeightLbs || 0,
      litterMemberDesc: valuesToEdit.litterMemberDesc || "",
      litterMemberColorIdentifier:
        valuesToEdit.litterMemberColorIdentifier || "",
      _id: valuesToEdit._id || "",
      _litter: valuesToEdit._litter || litter._id,
    };
  },
  validationSchema: Yup.object().shape({
    litterMemberGivenName: Yup.string().required("required"),
    litterMemberSex: Yup.string().required("required"),
    litterMemberColorIdentifier: Yup.string().required("required"),
    litterMemberPedigreeURL: Yup.string().url("url"),
    litterMemberDob: Yup.date().required("required"),
    litterMemberBirthWeightKg: Yup.number()
      .max(
        WEIGHT_INPUT_VALUES.litterMember.litterMemberBirthWeightKg.max,
        `lessThanOrEqualTo-${WEIGHT_INPUT_VALUES.litterMember.litterMemberBirthWeightKg.max}`
      )
      .min(0, `greaterThanOrEqualTo-0`),
    litterMemberBirthWeightG: Yup.number()
      .max(
        WEIGHT_INPUT_VALUES.litterMember.litterMemberBirthWeightG.max,
        `lessThanOrEqualTo-${WEIGHT_INPUT_VALUES.litterMember.litterMemberBirthWeightG.max}`
      )
      .min(0, `greaterThanOrEqualTo-0`),
    litterMemberBirthWeightLbs: Yup.number()
      .max(
        WEIGHT_INPUT_VALUES.litterMember.litterMemberBirthWeightLbs.max,
        `lessThanOrEqualTo-${WEIGHT_INPUT_VALUES.litterMember.litterMemberBirthWeightLbs.max}`
      )
      .min(0, `greaterThanOrEqualTo-0`),
    litterMemberDesc: Yup.string().max(200, "max-200"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    if (props.editMode) {
      return props.updateLitterMember(values, setSubmitting);
    }
    return props.addLitterMember(
      { ...values, litterMemberBirthWeightUnit: props.userPreferredUnit },
      setSubmitting
    );
  },
})(AddLitterMemberForm);

const mapStateToProps = (state) => {
  return {
    userPreferredUnit: state.reducers.auth.user.featurePreferences.weightUnit,
  };
};

export default connect(mapStateToProps)(AddLitterMemberForm);
