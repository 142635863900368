import React from "react";
import SectionTitle from "../components/SectionTitle";
import Contact from "../components/Contact";
import Intl from "components/Intl";
import Message from "components/message";

function Contacts({ contacts: { contacts }, toggleAccessForProvider }) {
  if (contacts.length === 0) return null;
  return (
    <div>
      <Message info message="allowProviderAccess" />
      <SectionTitle
        title={
          <Intl
            message
            path="containers.contacts.userContacts.titles.contacts"
          />
        }
        logo={{ path: "provider", name: "providerNoLogo" }}
      >
        {contacts.map((c) => (
          <Contact
            key={c._id}
            {...c}
            onAllowAccess={() => toggleAccessForProvider(c._id)}
          />
        ))}
      </SectionTitle>
    </div>
  );
}

export default Contacts;
