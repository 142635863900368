import { features, main } from "assets";
import Landing from "components/features/Landing";
import FeatureResources from "components/features/Feature_Resources";
import NewEntry from "./New_Entry_View";
import ViewEntries from "./View_Entries";
import ViewEntry from "./View_Entry";

import { LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD } from "functions/hostname";

const foodDiaryConfig = {
  name: "foodDiary",
  type: "feature",
  defaultRoute: "/userhome/dashboard/food_diary",
  menuIcon: { img: features.foodDiary.main, alt: "food_diary_icon" },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      name: "resources",
      disabled: false,
      clients: [LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD],
      menuItem: true,
      image: {
        img: main.resources,
        alt: "feature_resources_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: FeatureResources,
      path: "/resources",
    },
    {
      disabled: false,
      name: "newEntry",
      menuItem: true,
      image: {
        img: features.foodDiary.newEntry,
        alt: "food_diary_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: NewEntry,
      path: "/new_entry",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.foodDiary.viewEntries,
        alt: "food_diary_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
    {
      disabled: false,
      name: "viewEntry",
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
  ],
  tableView: {
    headers: [
      "date",
      "time",
      "onGoing",
      "foodCategory",
      "foodAmount",
      "foodAmountUnit",
    ],
    mobileHeaders: ["date", "time", "onGoing", "foodCategory"],
  },
};

export default foodDiaryConfig;
