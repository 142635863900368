import React from "react"

function TagFilterInput(props) {
  return (
    <div className="is-sticky">
      <div className="title is-6 has-text-grey">{props.title}</div>
      <div className="field">
        <div className="control">
          <input
            className="input"
            type="text"
            value={props.value}
            onChange={props.onChange}
          />
        </div>
      </div>
      <br />
    </div>
  )
}

export default TagFilterInput
