import { InputFieldV5, TextareaV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "displayName",
    group: "usefulLinks",
    type: "text",
    icon: "fas fa-link",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "desc",
    group: "usefulLinks",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 3,
    required: false,
    charLimit: 120,
    userCanEdit: true,
  },
  {
    name: "url",
    group: "usefulLinks",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
];

export { INPUT_FIELDS };
