import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "containers/auth/Pet_Services_Portal/actions";
import Notification from "components/layout/Notification";
import ToggleButton from "components/elements/ToggleButton";
import Button from "components/elements/Button";
import Buttons from "components/elements/Buttons";
import Intl from "components/Intl";

const TOGGLE_BUTTONS = ["userCanRequestContact", "userCanRequestAppointment"];

function Permissions({ providerPermissions, updateProviderPermissions }) {
  const [permissions, setPermissions] = useState({
    ...providerPermissions,
  });
  const [isLoading, setIsLoading] = useState(false);

  function handleTogglePermssion(e) {
    setPermissions({
      ...permissions,
      [e.target.id]: !permissions[e.target.id],
    });
  }

  function checkObjectKeys(a, b) {
    let aValues = Object.values(a).sort();
    let bValues = Object.values(b).sort();
    return JSON.stringify(aValues) === JSON.stringify(bValues);
  }

  function handleUpdatePermissions() {
    setIsLoading(true);
    updateProviderPermissions(permissions, setIsLoading);
  }

  return (
    <Notification>
      <div className="title is-6 has-text-dark">
        <Intl
          message
          path="containers.providers.providerAccount.titles.permissions"
        />
      </div>
      <div className="subtitle is-7 has-text-dark">
        <Intl
          message
          path="containers.providers.providerAccount.subtitles.permissions"
        />
      </div>
      {TOGGLE_BUTTONS.map((b) => (
        <ToggleButton
          key={b}
          id={b}
          label={
            <Intl
              message
              path={`containers.providers.providerAccount.labels.${b}`}
            />
          }
          checked={permissions[b]}
          onChange={handleTogglePermssion}
        />
      ))}
      <br />
      {!checkObjectKeys(providerPermissions, permissions) && (
        <Buttons>
          <Button
            className={`is-fullwidth ${isLoading && "is-loading"}`}
            group="common"
            name="update"
            disabled={isLoading}
            onClick={() => handleUpdatePermissions()}
          />
        </Buttons>
      )}
    </Notification>
  );
}

const mapStateToProps = (state) => {
  return {
    providerPermissions: state.reducers.auth.provider.permissions,
  };
};

export default connect(mapStateToProps, actions)(Permissions);
