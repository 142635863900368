import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";

function ServiceButtons(props) {
  const values = { _provider: props._provider };

  if (!props.userCanRequestAppointment && !props.userCanRequestContact)
    return null;

  return (
    <div style={{ paddingBottom: 20 }}>
      <Buttons className="is-left">
        {props.userCanRequestAppointment && (
          <Button
            className={props.requestAppointmentSuccess && "is-success"}
            group="contacts"
            name="requestAppointment"
            disabled={props.isBusy || props.requestAppointmentSuccess}
            onClick={() => props.userRequestsAnAppointment(values)}
          />
        )}
        {props.userCanRequestContact && (
          <Button
            className={props.requestContactSuccess && "is-success"}
            group="contacts"
            name="requestContact"
            disabled={props.isBusy || props.requestContactSuccess}
            onClick={() => props.userRequestsToBeContacted(values)}
          />
        )}
      </Buttons>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isBusy: state.reducers.actionCalls.actionInProcess,
  };
};

export default connect(mapStateToProps, actions)(ServiceButtons);
