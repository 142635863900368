import React, { useEffect } from "react"
import { connect } from "react-redux"
import * as actions from "containers/contacts/actions"
import { professionals } from "assets"
import SmartTile from "components/SmartTile"
import { MODALS_SHOW, MODALS_CONTACTS_INFO, MODALS_HIDE } from "actions/types"

function Contacts({
  name,
  contacts: { pendingRequests, contacts },
  dispatch,
  fetchContactsForUser,
}) {
  useEffect(() => {
    fetchContactsForUser()
  }, [fetchContactsForUser])

  function handleTileClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONTACTS_INFO,
      modalProps: {
        type: "contacts",
        handleModalClose: () => dispatch({ type: MODALS_HIDE }),
      },
    })
  }

  return (
    <div
      onClick={
        contacts.length > 0 || pendingRequests.length > 0
          ? null
          : handleTileClick
      }
    >
      <SmartTile
        newNotification={pendingRequests.length > 0}
        appTile
        header
        title={name}
        icon={professionals.main}
        path={
          contacts.length > 0 || pendingRequests.length > 0
            ? "/userhome/contacts"
            : "/userhome"
        }
      />
    </div>
  )
}

export default connect(null, actions)(Contacts)
