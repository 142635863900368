import axios from "axios"

import {
  HOOF_CARE_FETCH,
  HOOF_CARE_ADD,
  HOOF_CARE_UPDATE,
  HOOF_CARE_DELETE,
  ACTION_STARTED,
  ACTION_ERROR,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
  MODALS_HIDE,
} from "./types"

export const fetchHoofCareEntries = (pet) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: HOOF_CARE_FETCH })
    const res = await axios.get("/api/hoof_care/fetch", {
      headers: {
        authorization: localStorage.getItem("token"),
      },
      params: { _pet: pet._id },
    })
    dispatch({ type: HOOF_CARE_FETCH, payload: res.data.hoofCareEntries, pet })
    dispatch({ type: ACTION_ENDED })
    dispatch({ type: ACTION_RESET })
  } catch (error) {
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const addHoofCareEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: HOOF_CARE_ADD })
    const res = await axios.post("/api/hoof_care/add", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({ type: HOOF_CARE_ADD, payload: res.data.hoofCareEntry })
    dispatch({ type: ACTION_ENDED })
    dispatch({ type: ACTION_RESET })
    return history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const updateHoofCareEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: HOOF_CARE_UPDATE })
    const res = await axios.put("/api/hoof_care/update", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    dispatch({ type: HOOF_CARE_UPDATE, payload: res.data.hoofCareEntry })
    dispatch({ type: ACTION_ENDED })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    history.push(successRoute)
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
  } catch (error) {
    setSubmitting(false)
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

export const deleteHoofCareEntry = (id, history, successRoute) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: HOOF_CARE_DELETE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      "/api/hoof_care/delete",
      { id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({ type: HOOF_CARE_DELETE, payload: res.data.hoofCareEntry })
    dispatch({ type: ACTION_ENDED })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    history.push(successRoute)
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 2000)
  } catch (error) {
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

const hoofCareActions = {
  fetchHoofCareEntries,
  addHoofCareEntry,
  updateHoofCareEntry,
  deleteHoofCareEntry,
}

export default hoofCareActions
