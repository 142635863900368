// Assets
import assets from "assets";

// HOCs
import RequireAuth from "components/hoc/Require_Auth";
import RequireSelectedLitterMember from "components/hoc/Require_Selected_Litter_Member";

//Litter Member Features
import LitterMemberProfile from "containers/litter_member_features/Profile";
import LitterMemberWeight from "containers/litter_member_features/Weight";
import LitterMemberVaccinations from "containers/litter_member_features/Vaccinations";
import LitterMemberParasites from "containers/litter_member_features/Parasites";
import LitterMemberHealthDiary from "containers/litter_member_features/Health_Diary";
import LitterMemberFoodDiary from "containers/litter_member_features/Food_Diary";
import TransferLitterMember from "containers/litter_member_features/Transfer_Litter_Member";
import LitterMemberReporting from "containers/litter_member_features/Reporting";
import LitterMemberHomeCare from "containers/litter_member_features/Home_Care";

const litterMemberRoutes = [
  // **************************
  //
  // Litter_Member_Features
  //
  // **************************
  {
    name: "litterMemberProfile",
    disabled: false,
    feature: false,
    litterMemberFeature: false,
    path: "/userhome/dashboard/litter/litter_member_dashboard/profile",
    exact: false,
    component: RequireAuth(RequireSelectedLitterMember(LitterMemberProfile)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.pets.profile,
      alt: "litter_member_profile_logo",
    },
  },
  {
    name: "litterMemberWeight",
    disabled: false,
    feature: true,
    litterMemberFeature: true,
    path: "/userhome/dashboard/litter/litter_member_dashboard/weight",
    exact: false,
    component: RequireAuth(RequireSelectedLitterMember(LitterMemberWeight)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.weight.main,
      alt: "weight_main_logo",
    },
  },
  {
    name: "litterMemberVaccinations",
    disabled: false,
    feature: true,
    litterMemberFeature: true,
    path: "/userhome/dashboard/litter/litter_member_dashboard/vaccinations",
    exact: false,
    component: RequireAuth(
      RequireSelectedLitterMember(LitterMemberVaccinations)
    ),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.vaccinations.main,
      alt: "vaccinations_main_logo",
    },
  },
  {
    name: "litterMemberParasites",
    disabled: false,
    feature: true,
    litterMemberFeature: true,
    path: "/userhome/dashboard/litter/litter_member_dashboard/parasites",
    exact: false,
    component: RequireAuth(RequireSelectedLitterMember(LitterMemberParasites)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.parasites.main,
      alt: "parasites_main_logo",
    },
  },
  {
    name: "litterMemberHealthDiary",
    disabled: false,
    feature: true,
    litterMemberFeature: true,
    path: "/userhome/dashboard/litter/litter_member_dashboard/health_diary",
    exact: false,
    component: RequireAuth(
      RequireSelectedLitterMember(LitterMemberHealthDiary)
    ),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.healthDiary.main,
      alt: "health_diary_main_logo",
    },
  },
  {
    name: "litterMemberFoodDiary",
    disabled: false,
    feature: true,
    litterMemberFeature: true,
    path: "/userhome/dashboard/litter/litter_member_dashboard/food_diary",
    exact: false,
    component: RequireAuth(RequireSelectedLitterMember(LitterMemberFoodDiary)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.foodDiary.main,
      alt: "food_diary_main_logo",
    },
  },
  {
    name: "litterMemberHomeCare",
    disabled: false,
    feature: true,
    litterMemberFeature: true,
    path: "/userhome/dashboard/litter/litter_member_dashboard/home_care",
    exact: false,
    component: RequireAuth(RequireSelectedLitterMember(LitterMemberHomeCare)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.homeCare.main,
      alt: "home_care_main_logo",
    },
  },
  {
    name: "litterMemberReporting",
    disabled: false,
    feature: true,
    litterMemberFeature: true,
    path: "/userhome/dashboard/litter/litter_member_dashboard/reporting",
    exact: false,
    component: RequireAuth(RequireSelectedLitterMember(LitterMemberReporting)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.reporting.main,
      alt: "reporting_main_logo",
    },
  },
  {
    name: "transferLitterMember",
    disabled: false,
    feature: true,
    litterMemberFeature: true,
    path: "/userhome/dashboard/litter/litter_member_dashboard/transfer",
    exact: false,
    component: RequireAuth(RequireSelectedLitterMember(TransferLitterMember)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.pets.transfer,
      alt: "transfer_litter_member_logo",
    },
  },
];

export default litterMemberRoutes;
