import React, { Component } from "react"
import HealthEntryForm from "forms/Health_Entry_Form"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"
import FormWrapper from "components/layout/Form_Wrapper"

class HealthEntry extends Component {
  render() {
    return (
      <FormWrapper>
        <HealthEntryForm valuesToEdit={{}} {...this.props} />
      </FormWrapper>
    )
  }
}

export default RequireSubFeature(HealthEntry)
