import React from "react"

export default function Level(props) {
  return (
    <div
      style={props.style ? props.style : {}}
      onClick={() => (props.onClick ? props.onClick() : null)}
      className={`level is-marginless ${props.className}`}
    >
      {props.children}
    </div>
  )
}
