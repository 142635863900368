import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { vacs as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./fields/vaccinations";
import { FormTitle, FormButtons } from "components/form";

class VaccinationForm extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dirty && nextProps.cancelledByUser) {
      this.props.resetForm();
    }
  }

  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (!values.sideEffectsObserved && field.name === "sideEffectsDesc")
        return null;
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  }

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      viewMode,
      editMode,
      cancel,
      defaultRoute,
    } = this.props;
    return (
      <Form>
        <FormTitle group={"vaccinations"} />
        <br />
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={defaultRoute}
        />
        <br />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pet: state.reducers.pets.selectedPet,
  };
};

VaccinationForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet }) {
    return {
      date: moment(valuesToEdit.date).format("YYYY-MM-DD") || "",
      expiry: moment(valuesToEdit.expiry).format("YYYY-MM-DD") || "",
      vaccine: valuesToEdit.vaccine || "",
      batchNo: valuesToEdit.batchNo || "",
      weight: valuesToEdit.weight
        ? valuesToEdit.weight
        : pet.petWeight > 0
        ? pet.petWeight
        : 0,
      vetsName: valuesToEdit.vetsName || "",
      vetsID: valuesToEdit.vetsID || "",
      sideEffectsObserved: valuesToEdit.sideEffectsObserved || false,
      sideEffectsDesc: valuesToEdit.sideEffectsDesc || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
      petName: valuesToEdit.petName || pet.petName,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    expiry: Yup.date().required("required"),
    vaccine: Yup.string().required("required").max(30, "max-30"),
    batchNo: Yup.string().max(30, "max-30"),
    vetsName: Yup.string().max(30, "max-30"),
    vetsID: Yup.string().max(30, "max-30"),
    sideEffectsObserved: Yup.boolean(),
    sideEffectsDesc: Yup.string().when("sideEffectsObserved", {
      is: true,
      then: Yup.string().required("required").max(300, "max-300"),
      otherwise: Yup.string(),
    }),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries`;
    if (props.editMode) {
      return props.updateVac(
        values,
        setSubmitting,
        props.history,
        successRoute,
        props.providerView
      );
    }
    props.addVac(
      values,
      setSubmitting,
      props.history,
      successRoute,
      props.providerContact,
      props.providerView
    );
  },
})(VaccinationForm);

export default withRouter(connect(mapStateToProps, actions)(VaccinationForm));
