import { AUTH_LOG_OUT_USER } from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";
import {
  TOOTH_MARKER_FETCH_MARKERS,
  TOOTH_MARKER_ADD_MARKER_DETAIL,
  TOOTH_MARKER_DELETE_MARKER_DETAIL,
  TOOTH_MARKER_UPDATE_MARKER_DETAIL,
  TOOTH_MARKER_ADD_MARKER_DETAIL_MANY,
} from "./actions";
import {
  replaceObjectInArrayOfObjects,
  returnArrayOfObjectsGroupedByKey,
  returnArrayOfObjectsSortedByKey,
} from "functions";

const INITIAL_STATE = {
  data: [],
  markers: [],
  toothStates: [],
  lastFetchPetID: "",
};

function sortMarkers(data) {
  let sortedData = returnArrayOfObjectsSortedByKey("toothId", data);
  sortedData = returnArrayOfObjectsGroupedByKey(sortedData, "toothId");
  return Object.values(sortedData);
}

export default function ToothMarker(state = INITIAL_STATE, action) {
  let newArr = [];
  switch (action.type) {
    case TOOTH_MARKER_FETCH_MARKERS:
      return {
        ...state,
        data: action.payload.toothMarkers,
        markers: sortMarkers(action.payload.toothMarkers),
        lastFetchPetID: action.payload._pet,
      };
    case TOOTH_MARKER_ADD_MARKER_DETAIL: {
      newArr = [...state.data, action.payload];
      return {
        ...state,
        data: newArr,
        markers: sortMarkers(newArr),
      };
    }
    case TOOTH_MARKER_ADD_MARKER_DETAIL_MANY:
      newArr = [...state.data, ...action.payload];
      return {
        ...state,
        data: newArr,
        markers: sortMarkers(newArr),
      };
    case TOOTH_MARKER_DELETE_MARKER_DETAIL:
      newArr = state.data.filter((marker) => marker._id !== action.payload);
      return {
        ...state,
        data: newArr,
        markers: sortMarkers(newArr),
      };
    case TOOTH_MARKER_UPDATE_MARKER_DETAIL:
      newArr = replaceObjectInArrayOfObjects(state.data, action.payload);
      return {
        ...state,
        data: newArr,
        markers: sortMarkers(newArr),
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
