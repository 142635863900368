import React from 'react';
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { toothMarker as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import FormButtons from "components/form/FormButtons";
import { INPUT_FIELDS } from "./fields";
import { FormTitle } from "components/form";
import ToothConfirmation from "./ToothConfirmation";
import assets from "../assets";
import { TOOTH_MAPS } from "constants/tooth_maps";

function ToothMarkerDetailForm(props) {
  const {
    errors,
    dirty,
    isSubmitting,
    viewMode,
    editMode,
    cancel,
    resetForm,
    values,
    confirmationRequired,
    pet,
    toothId,
  } = props;

  const [confirmSelection, setConfirmSelection] =
    useState(confirmationRequired);

  function handleConfirmation(toothId) {
    props.setFieldValue("toothId", toothId);
    setConfirmSelection(false);
  }

  function handleCancel() {
    if (editMode) {
      resetForm();
      cancel();
    } else {
      cancel();
    }
  }

  const renderFields = () => {
    return INPUT_FIELDS.map((field, i) => {
      return (
        <div key={i}>
          <Field
            values={values}
            viewMode={viewMode}
            editMode={editMode}
            {...field}
          />
          {field.lastInGroup && <br />}
        </div>
      );
    });
  };

  if (confirmSelection) {
    return (
      <div className="has-text-centered">
        <FormTitle group={"toothMarkerIncisorSelection"} />
        <ToothConfirmation
          petType={pet.petType}
          toothId={toothId}
          confirmSelection={handleConfirmation}
        />
      </div>
    );
  }

  return (
    <Form>
      <FormTitle group={"toothMarkerDetail"} />
      <div className="media">
        <div className="media-left">
          <figure className="image is-48x48">
            <img
              src={assets[pet.petType].individuals[values.toothId]}
              alt={`individual ${values.toothId}`}
            />
          </figure>
        </div>
        <div className="media-content">
          {TOOTH_MAPS[pet.petType][values.toothId].num}
        </div>
      </div>
      <br />
      {renderFields()}
      <FormButtons
        viewMode={viewMode}
        editMode={editMode}
        isSubmitting={isSubmitting}
        errors={errors}
        dirty={dirty}
        cancel={handleCancel}
        useCancel
      />
      <br />
    </Form>
  );
}

const EnhancedToothMarkerDetailForm = withFormik({
  mapPropsToValues({ toothId, pet }) {
    return {
      date: moment().format("YYYY-MM-DD"),
      toothId: toothId,
      toothState: "",
      description: "",
      attachmentURL: "",
      gingivalPockets: 0,
      gumRecession: 0,
      petType: pet.petType,
      _pet: pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    toothState: Yup.array().required("required"),
    description: Yup.string().max(1000, "max-1000"),
    attachmentURL: Yup.string().url("url"),
    gingivalPockets: Yup.number(),
    gumRecession: Yup.number(),
  }),
  handleSubmit(values, { props }) {
    props.addMarkerDetail(values, props.providerContact, props.providerView);
  },
})(ToothMarkerDetailForm);

export default withRouter(
  connect(null, actions)(EnhancedToothMarkerDetailForm)
);
