import {
  LITTER_MEMBERS_SELECT_ONE,
  LITTER_MEMBERS_SELECT_ALL,
  LITTER_MEMBERS_UNSELECT_ONE,
  LITTER_MEMBERS_UNSELECT_ALL,
} from "./types"

export const selectOne = (litterMemberObj) => (dispatch) => {
  try {
    dispatch({ type: LITTER_MEMBERS_SELECT_ONE, payload: litterMemberObj })
  } catch (error) {
    console.log("error", error)
  }
}

export const selectAll = (litterMembersArray) => (dispatch) => {
  try {
    dispatch({ type: LITTER_MEMBERS_SELECT_ALL, payload: litterMembersArray })
  } catch (error) {
    console.log("error", error)
  }
}

export const unselectOne = (id) => (dispatch) => {
  try {
    dispatch({ type: LITTER_MEMBERS_UNSELECT_ONE, payload: id })
  } catch (error) {
    console.log("error", error)
  }
}

export const unselectAll = () => (dispatch) => {
  try {
    dispatch({ type: LITTER_MEMBERS_UNSELECT_ALL })
  } catch (error) {
    console.log("error", error)
  }
}

const litterMembersSelectedActions = {
  selectOne,
  selectAll,
  unselectOne,
  unselectAll,
}

export default litterMembersSelectedActions
