import React from 'react';
export function InfoUpdate(props) {
  return (
    <div className="is-6 has-text-weight-bold">
      <Icon type={props.type} />
      {props.message}
    </div>
  );
}

function Icon({ type }) {
  if (type === "info") {
    return (
      <i
        className="icon fas fa-exclamation-circle"
        style={{ color: "#febe10" }}
      />
    );
  }
  if (type === "update") {
    return (
      <i
        className="icon fas fa-cloud-upload-alt"
        style={{ color: "#00a0df" }}
      />
    );
  }

  return null;
}
