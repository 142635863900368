import { InputFieldV5, CheckboxV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "postcode",
    group: "register",
    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: false,
  },
  {
    name: "customerReg",
    group: "register",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: true,
    withLink: true,
  },
  {
    name: "termsCond",
    group: "register",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: true,
    withLink: true,
  },
  {
    name: "privacyPolicy",
    group: "register",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: true,
    withLink: true,
  },
  {
    name: "canBeContactedResearch",
    group: "register",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: false,
  },
];

export { INPUT_FIELDS };
