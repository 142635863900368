import { InputFieldV5, CheckboxV5, TextareaV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "expiry",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "vaccine",
    group: "vaccinations",
    type: "text",
    icon: "fas fa-barcode",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "batchNo",
    group: "vaccinations",
    type: "text",
    icon: "fas fa-barcode",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "vetsName",
    group: "vaccinations",
    type: "text",
    icon: "far fa-address-card",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "vetsID",
    group: "vaccinations",
    type: "text",
    icon: "far fa-address-card",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "sideEffectsObserved",
    group: "common",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "sideEffectsDesc",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 3,
    required: false,
    charLimit: 300,
    userCanEdit: true,
  },
];

export { INPUT_FIELDS };
