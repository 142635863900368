import {
  WEIGHT_REVIEW_FETCH,
  WEIGHT_ADD_LITTER_MEMBER,
  WEIGHT_UPDATE_LITTER_MEMBER,
  WEIGHT_DELETE_LITTER_MEMBER,
  AUTH_LOG_OUT_USER,
} from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";

const INITIAL_STATE = {};

export default function WeightReviewReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WEIGHT_REVIEW_FETCH:
      return {
        ...state,
        [action.payload._litter]: {
          ...state[action.payload._litter],
          [action.payload.fetchType]: {
            data: action.payload.chartData,
            options: action.payload.chartOptions,
          },
        },
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
    case WEIGHT_ADD_LITTER_MEMBER:
    case WEIGHT_UPDATE_LITTER_MEMBER:
    case WEIGHT_DELETE_LITTER_MEMBER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
