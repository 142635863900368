import { features, main } from "assets";
import Landing from "components/features/Landing";
import FeatureResources from "components/features/Feature_Resources";
import NewEntryDailyActivitySelection from "./views/New_Entry_Daily_Activity_Selection";
import NewEntryDailyActivity from "./views/New_Entry_Daily_Activity";
import ViewEntriesDailyActivity from "./views/View_Entries_Daily_Activity";
import ViewEntryDailyActivity from "./views/View_Entry_Daily_Activity";

const activityRoutes = [
  {
    name: "landing",
    pageInfo: ["info_p1", "info_p2", "info_p3", "info_p4"],
    disabled: false,
    menuItem: true,
    image: {
      img: features.infoMenuIcon,
      alt: "info_menu_icon",
    },
    icon: "fas fa-home",
    hr: true,
    exact: true,
    component: Landing,
    path: "/",
  },
  {
    name: "resources",
    disabled: true,
    menuItem: true,
    image: {
      img: main.resources,
      alt: "feature_resources_icon",
    },
    icon: "fas fa-home",
    hr: true,
    exact: true,
    component: FeatureResources,
    path: "/resources",
  },
  {
    name: "newEntryDailyActivitySelection",
    disabled: false,
    menuItem: true,
    image: {
      img: features.activity.dailyActivityNewEntry,
      alt: "daily_activity_new_entry_icon",
    },
    icon: "far fa-plus-square",
    component: NewEntryDailyActivitySelection,
    path: "/new_entry_daily_activity_selection",
  },
  {
    name: "newEntryDailyActivity",
    disabled: false,
    menuItem: false,
    image: {
      img: features.activity.dailyActivityNewEntry,
      alt: "daily_activity_new_entry_icon",
    },
    icon: "far fa-plus-square",
    component: NewEntryDailyActivity,
    path: "/new_entry_daily_activity",
  },
  {
    name: "viewEntriesDailyActivity",
    disabled: false,
    menuItem: true,
    image: {
      img: features.activity.dailyActivityViewEntries,
      alt: "daily_activity_view_entries_icon",
    },
    icon: "fas fa-copy",
    component: ViewEntriesDailyActivity,
    path: "/view_entries_daily_activity",
  },
  {
    name: "viewEntryDailyActivity",
    disabled: false,
    menuItem: false,
    component: ViewEntryDailyActivity,
    path: "/view_entry_daily_activity/:id",
  },
];

export default activityRoutes;
