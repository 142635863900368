import React, { useEffect } from "react";
import * as actions from "./actions";
import { connect } from "react-redux";
import moment from "moment";
import AdsAndBenefits from "components/features/Ads_And_Benefits";

function Advertisements({ advertisements, fetch, messages, locale, dispatch }) {
  useEffect(() => {
    if (!advertisements) {
      fetch();
    }
  }, [advertisements, fetch]);

  if (!advertisements) return null;

  return (
    <div className="columns is-variable is-2 is-mobile is-centered is-multiline is-marginless">
      {advertisements.items.map((ad, i) => (
        <div
          key={ad._id}
          className="column is-12-mobile is-4-desktop is-3-tabelt has-text-centered is-flex"
        >
          <AdsAndBenefits {...ad} dispatch={dispatch} />
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => {
  const advertisements =
    state.reducers.advertisements.items.length === 0 ||
    moment(state.reducers.advertisements.lastFetch).diff(moment(), "hours") > 1
      ? false
      : state.reducers.advertisements;
  return {
    locale: state.intl.locale,
    messages: state.intl.messages.containers.main.advertisements,
    advertisements,
  };
};

export default connect(mapStateToProps, actions)(Advertisements);
