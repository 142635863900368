import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Section from "components/layout/Section";
import InviteContactForm from "../forms/Invite_Contact_Form";
import BoxWithBackground from "components/styled/Box_With_Background";
import Message from "components/message";

function InviteContact(props) {
  return (
    <BoxWithBackground boxStyle={{ padding: 0, margin: 0 }} squareBorder>
      <SendInviteToContactSection
        title={props.messages.sectionTitles.inviteContact[props.locale]}
        receiverEmail={""}
        cancel={() => props.history.goBack()}
        callBack={null}
        {...props}
      />
    </BoxWithBackground>
  );
}

const SendInviteToContactSection = (props) => {
  const { title } = props;
  return (
    <Section>
      <div className="box" style={{ padding: 8 }}>
        <div className="title is-6 has-text-dark">{title}</div>
      </div>
      <Message info readMore message="inviteContact" />
      <br />
      <InviteContactForm {...props} />
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.containers.contacts.providerContacts,
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps, actions)(InviteContact);
