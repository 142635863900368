import { features } from "assets"
import Links from "./Links"

const usefulLinksConfig = {
  name: "usefulLinks",
  type: "feature",
  defaultRoute: "/userhome/dashboard/useful_links",
  menuIcon: { img: features.usefulLinks.main, alt: "useful_links_main_icon" },
  nav: [
    {
      disabled: false,
      name: "links",
      menuItem: true,
      image: { img: features.usefulLinks.main, alt: "useful_links_main_icon" },
      icon: "fas fa-copy",
      exact: true,
      component: Links,
      path: "/",
    },
  ],
}

export default usefulLinksConfig
