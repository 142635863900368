import React from "react";
import { auth as actions } from "actions/index";
import { connect } from "react-redux";
import RegisterForm from "forms/Register_Form";
import BoxWithBackground from "components/styled/Box_With_Background";
import { MODALS_SHOW, MODALS_HIDE, MODALS_CONFIRM_EMAIL } from "actions/types";

function RegisterFormView(props) {
  function handleFormSubmit(values, setSubmitting, resetForm) {
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_EMAIL,
      modalProps: {
        type: "registerConfirmEmail",
        usersEmail: values.email,
        handleModalClose: () => {
          props.dispatch({ type: MODALS_HIDE });
          setSubmitting(false);
        },
        handleConfirmAndRegister: () => {
          props.dispatch({ type: MODALS_HIDE });
          props.registerUser(values, setSubmitting, resetForm, props.history);
        },
      },
    });
  }

  return (
    <BoxWithBackground squareBorder>
      <br />
      <RegisterForm handleFormSubmit={handleFormSubmit} />
      <br />
    </BoxWithBackground>
  );
}

export default connect(null, actions)(RegisterFormView);
