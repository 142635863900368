import React, { useState } from "react";
import Section from "components/layout/Section";
import { connect } from "react-redux";
import { FETCH_BUTTONS } from "./constants";
import FormWrapper from "components/layout/Form_Wrapper";

function FetchEntries(props) {
  const [fetchDates, setFetchDates] = useState({});
  const [showFetchDates, toggleShowFetchDates] = useState(false);

  const handleSetDate = (e) => {
    const parsedDate = new Date(e.target.value);
    setFetchDates({
      ...fetchDates,
      [e.target.name]: isNaN(parsedDate) ? "" : parsedDate.toISOString(),
    });
  };

  const { buttons } = props.messages;
  const { fetchButtons, fetchType, newPet, fetchItems, locale } = props;

  return (
    <Section>
      <div className="buttons is-centered">
        {FETCH_BUTTONS.map(({ name, className, value, unit }, i) => {
          if (fetchButtons && !fetchButtons.includes(name)) return null;
          return (
            <div
              key={i}
              name={name}
              className={`${className} ${
                fetchType === name && newPet && "is-inverted"
              }`}
              onClick={() => fetchItems(value, unit, name)}
            >
              {buttons[name] ? buttons[name][locale] : name}
            </div>
          );
        })}
        <div
          name={"range"}
          className={`button is-small is-rounded ${
            showFetchDates && "is-dark"
          }`}
          onClick={() => toggleShowFetchDates(!showFetchDates)}
        >
          {props.messages.buttons.range[props.locale]}
        </div>
      </div>
      {showFetchDates && (
        <DateRangeSelector setDate={handleSetDate} {...props} {...fetchDates} />
      )}
    </Section>
  );
}

const DateRangeSelector = (props) => {
  return (
    <FormWrapper>
      <label className="heading has-text-grey">
        {props.messages.labels.range[props.locale]}
      </label>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <p className="control">
              <input
                className="input is-small is-rounded"
                type="date"
                name="from"
                onChange={(e) => props.setDate(e)}
              />
            </p>
          </div>
          <div className="field">
            <p className="control">
              <input
                className="input is-small is-rounded"
                type="date"
                name="to"
                onChange={(e) => props.setDate(e)}
              />
            </p>
          </div>
        </div>
      </div>
      <button
        className={`button is-link is-small is-rounded ${
          props.fetchType === "range" && props.newPet && "is-focused"
        }`}
        disabled={!!props.from && !!props.to ? false : true}
        onClick={() => props.fetchItems({ from: props.from, to: props.to })}
      >
        {props.messages.buttons.fetch[props.locale]}
      </button>
    </FormWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: state.intl.messages.components.fetch,
  };
};

export default connect(mapStateToProps)(FetchEntries);
