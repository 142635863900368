import login from "./login.svg";
import logout from "./logout.svg";
import register from "./register.svg";
import poweredByStripe from "./powered_by_stripe.svg";
import backRound from "./back_round.svg";
import back from "./back.svg";
import providerLogin from "./provider_login.svg";
import providerRegister from "./provider_register.svg";

const auth = {
  login,
  logout,
  register,
  poweredByStripe,
  backRound,
  back,
  providerLogin,
  providerRegister,
};

export default auth;
