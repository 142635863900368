import React, { useState, useEffect } from "react";
import Section from "components/layout/Section";
import FetchAgain from "../components/FetchAgain";
import TagFilterInput from "../components/TagFilterInput";
import AllTags from "../components/AllTags";
import _ from "lodash";

function SearchByTag({
  blog,
  blogUrl,
  fetchAllTags,
  fetchPostsLatest,
  history,
  handleFetchPostsByTag,
  messages,
  locale,
}) {
  useEffect(() => {
    if (!blog) {
      fetchAllTags(blogUrl);
      fetchPostsLatest(blogUrl);
    }
  }, [blog, blogUrl, fetchAllTags, fetchPostsLatest]);

  const [filterBy, setFilterBy] = useState("");

  const handleChange = (e) => {
    setFilterBy(e.target.value);
  };

  return (
    <Section>
      {!blog ? (
        <FetchAgain />
      ) : (
        <>
          <TagFilterInput
            title={messages.sectionTitles.tagFilterInput[locale]}
            value={filterBy}
            onChange={handleChange}
          />
          <AllTags
            filterBy={filterBy}
            title={messages.sectionTitles.allTags[locale]}
            tags={_.sortBy(blog.tags, "name")}
            onClick={handleFetchPostsByTag}
          />
        </>
      )}
    </Section>
  );
}

export default SearchByTag;
