import React from "react"
import styled from "styled-components"

const StyledTitle = styled.div`
  padding-bottom: 10px;
`

const Title = ({
  title,
  subtitle,
  titleSize,
  subtitleSize,
  titleColor,
  subtitleColor,
  isCentered,
  className
}) => (
  <StyledTitle>
    <p
      className={`title ${titleSize ? titleSize : "is-6"} ${titleColor &&
        titleColor} ${isCentered && "has-text-centered"} ${className &&
        className}`}
    >
      {title ? title : "titlePropMissing"}
    </p>
    {subtitle && (
      <p
        className={`subtitle ${
          subtitleSize ? subtitleSize : "is-6"
        } ${subtitleColor && subtitleColor} ${isCentered &&
          "has-text-centered"} ${className && className}`}
      >
        {subtitle}
      </p>
    )}
  </StyledTitle>
)

export default Title
