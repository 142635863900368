import React from "react"
import { connect } from "react-redux"
import Section from "components/layout/Section"
import Post from "../components/Post"
import BackButton from "components/elements/BackButton"

function ViewBlogPost(props) {
  return (
    <Section>
      <BackButton />
      <Post post={props.blog.selectedPost} {...props} viewPost />
    </Section>
  )
}

const mapStateToProps = (state) => {
  return {
    blog: state.reducers.blog,
    locale: state.intl.locale,
  }
}

export default connect(mapStateToProps)(ViewBlogPost)
