import React, { Component } from "react"
import _ from "lodash"
import { connect } from "react-redux"

export default function RequireSelectedLitter(ComposedComponent) {
  class Authentication extends Component {
    UNSAFE_componentWillMount() {
      if (_.isEmpty(this.props.selectedLitter)) {
        this.props.history.push("/userhome/dashboard")
      }
    }

    UNSAFE_componentWillUpdate(nextProps) {
      if (_.isEmpty(nextProps.selectedLitter)) {
        this.props.history.push("/userhome/dashboard")
      }
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }

  const mapStateToProps = (state) => {
    return {
      selectedLitter: state.reducers.litters.selectedLitter,
    }
  }
  return connect(mapStateToProps)(Authentication)
}
