import React from "react";
import DivWrapper from "components/styled/Div_Wrapper";
import Notification from "components/layout/Notification";

export default function ProfileDisplay(props) {
  return (
    <Notification>
      {props.fields.map(({ label, value }, i, arr) => {
        if (!value || value <= 0) return null;
        return (
          <div key={i}>
            <div className="columns is-2 is-variable">
              <div className="column is-3">
                <DivWrapper background="#c8cace" padding={3}>
                  <p className="heading">{label}</p>
                </DivWrapper>
              </div>
              <div className="column">
                <DivWrapper background="#f7f7f7" padding={3}>
                  <p className="heading">{value}</p>
                </DivWrapper>
              </div>
              <br />
            </div>
          </div>
        );
      })}
    </Notification>
  );
}
