import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Section from "components/layout/Section";
import Message from "components/message";
import Loading from "components/spinners/Loading";
import {
  MODALS_SHOW,
  MODALS_CONFIRM_DELETE,
  LITTERS_DELETE_MEMBER,
} from "actions/types";

class DeleteProfile extends Component {
  handleDeleteClick = () => {
    this.props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "litterMember",
        actionType: LITTERS_DELETE_MEMBER,
        history: this.props.history,
        successRoute: "/userhome/dashboard/litter",
        item: this.props.litterMember,
      },
    });
  };

  renderContent() {
    const { buttons, locale, actionCalls } = this.props;

    if (
      actionCalls.actionInProcess &&
      actionCalls.action === LITTERS_DELETE_MEMBER
    )
      return <Loading />;

    if (
      !_.isEmpty(actionCalls.actionError) &&
      actionCalls.action === LITTERS_DELETE_MEMBER
    )
      return <Message error message={actionCalls.actionError.message} />;

    // check to see if litter member has new owner
    // if they do, render message to alert that
    if (this.props.selectedLitterMember.hasNewOwner)
      return <Message warning message="memberHasNewOwner" />;

    return (
      <div className="buttons is-centered">
        <div
          className="button is-danger"
          onClick={() => this.handleDeleteClick()}
        >
          <span>{buttons.petProfile.deleteProfile[locale]}</span>
        </div>
      </div>
    );
  }

  render() {
    return <Section>{this.renderContent()}</Section>;
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    litterMember: state.reducers.litters.selectedLitterMember,
    buttons: state.intl.messages.components.buttons,
    actionCalls: state.reducers.actionCalls,
  };
};

export default connect(mapStateToProps)(DeleteProfile);
