import {
  HEAT_DIARY_FETCH_TYPE,
  HEAT_DIARY_FETCH_CYCLES,
  HEAT_DIARY_FETCH_PROGS,
  HEAT_DIARY_FETCH_BREEDING,
  HEAT_DIARY_ADD_CYCLE,
  HEAT_DIARY_ADD_PROG,
  HEAT_DIARY_ADD_BREEDING,
  HEAT_DIARY_UPDATE_CYCLE,
  HEAT_DIARY_UPDATE_PROG,
  HEAT_DIARY_UPDATE_BREEDING,
  HEAT_DIARY_DELETE_CYCLE,
  HEAT_DIARY_DELETE_PROG,
  HEAT_DIARY_DELETE_BREEDING,
  AUTH_LOG_OUT_USER,
} from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";
import _ from "lodash";

const INITIAL_STATE = {
  cycles: {
    entries: [],
    fetchType: "",
    fetchDates: "",
    lastFetchPetID: "",
  },
  progs: {
    entries: [],
    fetchType: "",
    fetchDates: "",
    lastFetchPetID: "",
  },
  breeding: {
    entries: [],
    fetchType: "",
    fetchDates: "",
    lastFetchPetID: "",
  },
};

export default function HeatDiaryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HEAT_DIARY_FETCH_TYPE:
      return {
        ...state,
        fetchDates: action.payload.dates,
        fetchType: action.payload.type,
      };
    case HEAT_DIARY_FETCH_CYCLES:
      return {
        ...state,
        cycles: {
          ...state.cycles,
          entries: _.sortBy(action.payload, "startDate").reverse(),
          lastFetchPetID: action.pet._id,
        },
      };
    case HEAT_DIARY_FETCH_PROGS:
      return {
        ...state,
        progs: {
          ...state.progs,
          entries: _.sortBy(action.payload, "date").reverse(),
          lastFetchPetID: action.pet._id,
        },
      };
    case HEAT_DIARY_FETCH_BREEDING:
      return {
        ...state,
        breeding: {
          ...state.breeding,
          entries: _.sortBy(action.payload, "date").reverse(),
          lastFetchPetID: action.pet._id,
        },
      };
    case HEAT_DIARY_ADD_CYCLE:
      return {
        ...state,
        cycles: {
          ...state.cycles,
          entries: _.sortBy(
            [...state.cycles.entries, action.payload],
            "startDate"
          ).reverse(),
          fetchDates: "",
        },
      };
    case HEAT_DIARY_ADD_PROG:
      return {
        ...state,
        progs: {
          ...state.progs,
          entries: _.sortBy(
            [...state.progs.entries, action.payload],
            "date"
          ).reverse(),
          fetchDates: "",
        },
      };
    case HEAT_DIARY_ADD_BREEDING:
      return {
        ...state,
        breeding: {
          ...state.breeding,
          entries: _.sortBy(
            [...state.breeding.entries, action.payload],
            "date"
          ).reverse(),
          fetchDates: "",
        },
      };
    case HEAT_DIARY_UPDATE_CYCLE:
      return {
        ...state,
        cycles: {
          ...state.cycles,
          entries: _.sortBy(
            [
              ...state.cycles.entries.filter(
                (entry) => entry._id !== action.payload._id
              ),
              action.payload,
            ],
            "startDate"
          ).reverse(),
        },
      };
    case HEAT_DIARY_UPDATE_PROG:
      return {
        ...state,
        progs: {
          ...state.progs,
          entries: _.sortBy(
            [
              ...state.progs.entries.filter(
                (entry) => entry._id !== action.payload._id
              ),
              action.payload,
            ],
            "date"
          ).reverse(),
        },
      };
    case HEAT_DIARY_UPDATE_BREEDING:
      return {
        ...state,
        breeding: {
          ...state.breeding,
          entries: _.sortBy(
            [
              ...state.breeding.entries.filter(
                (entry) => entry._id !== action.payload._id
              ),
              action.payload,
            ],
            "breedingDate"
          ).reverse(),
        },
      };
    case HEAT_DIARY_DELETE_CYCLE:
      return {
        ...state,
        cycles: {
          ...state.cycles,
          entries: state.cycles.entries.filter(
            (entry) => entry._id !== action.payload
          ),
        },
      };
    case HEAT_DIARY_DELETE_PROG:
      return {
        ...state,
        progs: {
          ...state.progs,
          entries: state.progs.entries.filter(
            (entry) => entry._id !== action.payload
          ),
        },
      };
    case HEAT_DIARY_DELETE_BREEDING:
      return {
        ...state,
        breeding: {
          ...state.breeding,
          entries: state.breeding.entries.filter(
            (entry) => entry._id !== action.payload
          ),
        },
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
