import React from 'react';
import TeethCat from "./TeethCat";
import TeethDog from "./TeethDog";

export default function TeethImageFull(props) {
  const { petType, ...rest } = props;
  switch (petType) {
    case "cat":
      return <TeethCat {...rest} />;
    case "dog":
      return <TeethDog {...rest} />;
    default:
      return null;
  }
}
