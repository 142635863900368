import { features } from "assets";
import Landing from "components/features/Landing";
import ToothMarkerView from "./views/Tooth_Marker_View";

const toothMarkerRoutes = [
  {
    name: "landing",
    pageInfo: [],
    disabled: false,
    menuItem: true,
    image: {
      img: features.infoMenuIcon,
      alt: "info_menu_icon",
    },
    icon: "fas fa-home",
    hr: true,
    exact: true,
    component: Landing,
    path: "/",
  },
  {
    name: "markers",
    disabled: false,
    menuItem: true,
    image: {
      img: features.toothMarker.main,
      alt: "tooth_marker_icon",
    },
    icon: "far fa-plus-square",
    component: ToothMarkerView,
    path: "/markers",
  },
];

export default toothMarkerRoutes;
