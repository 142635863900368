import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class FoodDiary extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.foodDiary,
      ...state.intl.messages.containers.litterMemberFeatures
        .litterMemberFoodDiary,
      landing: {
        ...state.intl.messages.containers.litterMemberFeatures
          .litterMemberFoodDiary.landing,
        title: state.intl.messages.containers.features.foodDiary.landing.title,
        subtitle:
          state.intl.messages.containers.features.foodDiary.landing.subtitle,
        menuLink:
          state.intl.messages.containers.features.foodDiary.landing.menuLink,
      },
      foodCategories:
        state.intl.messages.components.forms.selectOptions.foodCategory,
    },
    foodDiary: state.reducers.foodDiary,
  };
};

export default connect(mapStateToProps)(FoodDiary);
