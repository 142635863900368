import React from "react"
import { useParams } from "react-router-dom"
import Section from "components/layout/Section"
import FetchAgain from "../components/FetchAgain"
import Post from "../components/Post"
import PaginatedPosts from "../components/PaginatedPosts"
import BackButton from "components/elements/BackButton"

function FetchPostsByTag(props) {
  const { blog } = props
  const { id } = useParams()
  const tag = blog.tags.filter((t) => t.id === Number(id))[0]

  return (
    <Section>
      <BackButton />
      <div className="tags">
        <div className="tag is-medium">{tag.name}</div>
      </div>
      {!blog ? (
        <FetchAgain />
      ) : blog && !blog.pagination ? (
        blog.entriesFetchedByTag.map((e) => {
          return <Post key={e.id} post={e} excerpt {...props} />
        })
      ) : (
        <PaginatedPosts {...props} />
      )}
    </Section>
  )
}

export default FetchPostsByTag
