import { features } from "assets"
import { actionTypes } from "./actions"
import { path } from "constants/feature_routes_provider_view"
import ViewEntryDailyActivity from "./views/View_Entry_Daily_Activity"
import ViewEntriesDailyActivity from "./views/View_Entries_Daily_Activity"

const activityProviderConfig = {
  name: "activity",
  type: "feature",
  defaultRoute: path("activity"),
  menuIcon: {
    img: features.activity.main,
    alt: "activity_feature_icon",
  },
  providerView: true,
  providerEdit: false,
  noNav: true,
  nav: [
    {
      name: "viewEntriesDailyActivity",
      disabled: false,
      menuItem: true,
      exact: true,
      image: {
        img: features.activity.dailyActivityViewEntries,
        alt: "daily_activity_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntriesDailyActivity,
      path: "/",
    },
    {
      name: "viewEntryDailyActivity",
      disabled: false,
      menuItem: false,
      component: ViewEntryDailyActivity,
      path: "/view_entry_daily_activity/:id",
    },
  ],
  actionTypes,
  tableView: {
    headers: ["date", "category", "activity", "totalTimeInSeconds"],
    mobileHeaders: ["date", "category", "activity", "totalTimeInSeconds"],
  },
  chartView: {},
}

export default activityProviderConfig
