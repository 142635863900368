import React from "react";
import Button from "components/elements/Button";

function Pagination({ pagination, paginationNavigate }) {
  const pages = new Array(pagination.total_pages).fill(0).map((v, i) => i + 1);

  return (
    <nav
      className="pagination is-centered"
      role="navigation"
      aria-label="pagination"
    >
      <Button
        group="common"
        name="prev"
        className="pagination-previous"
        onClick={
          pagination.page === 1
            ? null
            : () => paginationNavigate(pagination.page - 1)
        }
      />
      <Button
        group="common"
        name="next"
        className="pagination-previous"
        onClick={
          pagination.page === pagination.total_pages
            ? null
            : () => paginationNavigate(pagination.next_page)
        }
      />
      <ul className="pagination-list">
        {pages.map((p) => (
          <li key={p}>
            <button
              className={`button pagination-link ${
                p === pagination.page && "is-active is-link"
              }`}
              aria-label={`Goto page ${p}`}
              onClick={() => paginationNavigate(p)}
            >
              {p}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Pagination;
