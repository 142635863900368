import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Section from "components/layout/Section";
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons";
import WeightForm from "forms/Weight_Form";
import FormWrapper from "components/layout/Form_Wrapper";
import {
  MODALS_SHOW,
  MODALS_CONFIRM_DELETE,
  WEIGHT_DELETE,
} from "actions/types";

const INITIAL_STATE = {
  viewMode: true,
  valuesToEdit: {},
};

class ViewEntry extends Component {
  state = INITIAL_STATE;

  UNSAFE_componentWillMount() {
    const { weightEntries, history, defaultRoute } = this.props;
    const { id } = this.props.match.params;
    const weight = weightEntries.filter((weight) => weight._id === id)[0];
    if (!weight) return history.push(`${defaultRoute}/view_entries`);
    this.setState({
      valuesToEdit: weight,
    });
  }

  handleEditClick = () => {
    this.setState({
      viewMode: !this.state.viewMode,
    });
  };

  handleDeleteClick = () => {
    const successRoute = `${this.props.defaultRoute}/view_entries`;
    this.props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "weight",
        actionType: WEIGHT_DELETE,
        history: this.props.history,
        successRoute: successRoute,
        item: this.state.valuesToEdit._id,
        providerView: this.props.providerView,
      },
    });
  };

  renderSectionContent() {
    if (_.isEmpty(this.state.valuesToEdit)) return <div />;

    return (
      <>
        <BackEditDeleteButtons
          handleEditClick={this.handleEditClick}
          handleDeleteClick={this.handleDeleteClick}
          viewMode={this.state.viewMode}
          providerView={this.props.providerView}
          providerEdit={this.props.providerEdit}
        />
        <FormWrapper>
          <WeightForm
            editMode
            {...this.state}
            cancel={this.handleEditClick}
            {...this.props}
          />
        </FormWrapper>
      </>
    );
  }

  render() {
    return <Section>{this.renderSectionContent()}</Section>;
  }
}

const mapStateToProps = (state) => {
  return {
    weightEntries: state.reducers.weight.entries,
  };
};

export default withRouter(connect(mapStateToProps)(ViewEntry));
