import { InputFieldV5 } from "components/form";

export const INPUT_FIELDS = [
  {
    name: "username",
    group: "providerRegister",
    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "password",
    group: "register",
    type: "password",
    icon: "fa fa-key",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
];
