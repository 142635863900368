import React from "react"
import Styles from "./smartTile.module.css"

export default function TileBody(props) {
  return (
    <div className={`card-body ${Styles.tileBody}`}>
      <div className={`card-image ${Styles.tileBodyImage}`}>
        <figure
          className="image is-1by1"
          onClick={props.onClick ? props.onClick : null}
        >
          {props.litterMemberIdentifier && props.children}
          <img
            src={props.img}
            alt={`tile_image_${props.img}`}
            className={`${props.imgClassName} ${
              props.disabled && Styles.disabled
            }`}
          />
        </figure>
      </div>
    </div>
  )
}
