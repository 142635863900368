import { pets, main } from "assets"
import Landing from "components/features/Landing"
import Profile from "./Profile"
import ProfileImage from "./Profile_Image"
import DeleteProfile from "./Delete_Profile"

const petProfileConfig = {
  name: "petProfile",
  type: "petProfile",
  defaultRoute: "/userhome/dashboard/pet_profile",
  menuIcon: { img: pets.profile, alt: "pet_profile_icon" },
  nav: [
    {
      name: "landing",
      menuItem: true,
      image: {
        img: main.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      name: "profile",
      menuItem: true,
      image: {
        img: pets.editProfile,
        alt: "pet_profile_edit_icon",
      },
      icon: "fas fa-paw",
      component: Profile,
      path: "/profile",
    },
    {
      name: "profileImage",
      menuItem: true,
      image: {
        img: pets.profileImage,
        alt: "pet_profile_image_icon",
      },
      icon: "fas fa-image",
      component: ProfileImage,
      path: "/profile_image",
    },
    {
      name: "deleteProfile",
      menuItem: true,
      image: {
        img: pets.deleteProfile,
        alt: "pet_profile_delete_icon",
      },
      icon: "fas fa-ban",
      component: DeleteProfile,
      path: "/delete",
    },
  ],
}

export default petProfileConfig
