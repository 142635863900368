import React from "react";
import { connect } from "react-redux";
import BodyConditionDescription from "components/bcs/Body_Condition_Desc";

function BodyConditionScoreModal(props) {
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <section className="modal-card-body has-text-dark">
          <div
            className="has-text-right"
            style={{ fontSize: 18, cursor: "pointer" }}
          >
            <div className="icon is-large">
              <i
                onClick={() => props.handleModalClose()}
                className="far fa-times-circle fa-2x"
              ></i>
            </div>
          </div>
          <BodyConditionDescription {...props} />
        </section>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.components.bodyConditionScore,
  };
};

export default connect(mapStateToProps)(BodyConditionScoreModal);
