import { InputFieldV5, UrlInputLinkV5, TextareaV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "litterName",
    group: "addLitter",
    type: "text",
    icon: "fas fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "litterBreed",
    group: "addLitter",
    type: "text",
    icon: "fas fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "litterDob",
    group: "addLitter",
    type: "date",
    icon: "fas fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "litterMotherGivenName",
    group: "addLitter",
    type: "text",
    icon: "fas fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "litterMotherLink",
    group: "addLitter",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: UrlInputLinkV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "litterFatherGivenName",
    group: "addLitter",
    type: "text",
    icon: "fas fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "litterFatherLink",
    group: "addLitter",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: UrlInputLinkV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "litterNotes",
    group: "addLitter",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    charLimit: 500,
    userCanEdit: true,
  },
];

export { INPUT_FIELDS };
