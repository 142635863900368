import React, { useState } from "react";
import { connect } from "react-redux";
import { auth as actions } from "actions";
import Notification from "components/layout/Notification";
import ToggleButton from "components/elements/ToggleButton";
import Button from "components/elements/Button";
import Buttons from "components/elements/Buttons";

const TOGGLE_BUTTONS = [
  "reminders",
  "birthdaysNamedays",
  "newsletter",
  "marketing",
];

function ProfilePreferences({
  user,
  messages,
  locale,
  updateEmailPreferences,
}) {
  const [emailPreferences, setEmailPreferences] = useState(
    user.emailPreferences
  );
  const [isLoading, setIsLoading] = useState(false);

  function handleToggleEmailPreference(e) {
    setEmailPreferences({
      ...emailPreferences,
      [e.target.id]: !emailPreferences[e.target.id],
    });
  }

  function checkObjectKeys(a, b) {
    let aValues = Object.values(a).sort();
    let bValues = Object.values(b).sort();
    return JSON.stringify(aValues) === JSON.stringify(bValues);
  }

  function handleUpdatePreferences() {
    updateEmailPreferences(emailPreferences);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }

  function handleCancel() {
    setEmailPreferences(user.emailPreferences);
  }

  return (
    <Notification>
      <div className="title is-6 has-text-dark">
        {messages.emailPreferences.title[locale]}
      </div>
      <div className="subtitle is-7 has-text-dark">
        {messages.emailPreferences.subtitle[locale]}
      </div>
      {TOGGLE_BUTTONS.map((b) => (
        <ToggleButton
          key={b}
          id={b}
          label={messages.emailPreferences[b][locale]}
          checked={emailPreferences[b]}
          onChange={handleToggleEmailPreference}
        />
      ))}
      <br />
      {!checkObjectKeys(user.emailPreferences, emailPreferences) && (
        <Buttons>
          <Button
            className={`is-fullwidth is-info ${isLoading && "is-loading"}`}
            group="common"
            name="update"
            disabled={isLoading}
            onClick={() => handleUpdatePreferences()}
          />
          <Button
            className={`is-fullwidth ${isLoading && "is-loading"}`}
            group="common"
            name="cancel"
            disabled={isLoading}
            onClick={() => handleCancel()}
          />
        </Buttons>
      )}
    </Notification>
  );
}

export default connect(null, actions)(ProfilePreferences);
