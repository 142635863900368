import React from "react";
import { connect } from "react-redux";
import inputValidationColor from "./Input_Validation_Color";
import FormValidationMessageV5 from "./FormValidationMessageV5";
import SELECT_OPTIONS from "constants/select_options";

const SelectV5 = (props) => {
  const {
    field,
    form,
    group,
    icon,
    className,
    viewMode,
    editMode,
    userCanEdit,
    label,
    locale,
    selectOptionsStrings,
    altName,
    orderAZ,
    required,
  } = props;

  // Create main list of options in A-Z order
  let selectOptions = SELECT_OPTIONS[group][altName ? altName : field.name];

  let selectionOptionsDefault = selectOptions.default
    ? selectOptionsStrings[selectOptions.default][locale]
    : false;
  let selectOptionsArray = selectOptions.options.map((option) => ({
    value: option,
    string: selectOptionsStrings[option][locale],
  }));

  let selectOptionsFilteredAndOrdered = orderAZ
    ? selectOptionsArray.sort((a, b) => (a.string > b.string ? 1 : -1))
    : selectOptionsArray;
const style2 = {
  border: `1px solid lightgrey`,
};
  return (
    <div className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">
          {label}
          {!viewMode && required && "*"}
        </div>
      </label>
      <div className="control has-icons-left">
        <div
          className={`${className} ${inputValidationColor(
            field.name,
            form.touched,
            form.errors
          )}`}
          style={style2}
        >
          <select
            {...field}
            disabled={viewMode || (!userCanEdit && editMode) ? true : false}
          >
            {selectionOptionsDefault && (
              <option value={""}>{selectionOptionsDefault}</option>
            )}
            {selectOptionsFilteredAndOrdered.map((option, i) => {
              return (
                <option key={i} value={option.value}>
                  {option.string}
                </option>
              );
            })}
          </select>
        </div>
        <span className="icon is-small is-left">
          <i className={icon} />
        </span>
      </div>
      <FormValidationMessageV5
        clientMessage
        group={group}
        name={field.name}
        errors={form.errors}
        touched={form.touched}
      />
    </div>
  );
};

const mapStateToProps = (state, { group, altName, field }) => {
  const { locale, messages } = state.intl;
  return {
    locale,
    label:
      messages.components.forms.labels[group][altName ? altName : field.name][
        locale
      ],
    selectOptionsStrings:
      messages.components.forms.selectOptions[altName ? altName : field.name],
  };
};

export default connect(mapStateToProps)(SelectV5);
