import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function SubscribeNowButton(props) {
  if (!props.isAuthed) return null;

  if (_.isEmpty(props.user)) return null;

  if (props.user.completeRegistrationRequired) return null;

  if (props.user.verifyEmailRequired) return null;

  if (props.user.subscription) return null;

  return (
    <div className="buttons">
      {props.subscribe && (
        <Link
          to={"/userhome/user_account/subscription"}
          className="button is-link"
        >
          <span className="icon">
            <i className={props.buttons.subscribeNow.icon} />
          </span>
          <span>{props.buttons.subscribeNow[props.locale]}</span>
        </Link>
      )}
      {props.redeemCode && (
        <Link
          to={"/userhome/user_account/redeem_code"}
          className="button is-link"
        >
          <span className="icon">
            <i className={props.buttons.redeemCode.icon} />
          </span>
          <span>{props.buttons.redeemCode[props.locale]}</span>
        </Link>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.reducers.auth.user,
    isAuthed: state.reducers.auth.isAuthenticated,
    locale: state.intl.locale,
    buttons: state.intl.messages.components.buttons.common,
  };
};

export default connect(mapStateToProps)(SubscribeNowButton);
