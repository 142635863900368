import { features } from "assets"
import Landing from "components/features/Landing"
import NewEntry from "./New_Entry_View"
import ViewEntries from "./View_Entries"
import ViewEntry from "./View_Entry"

const weightConfig = {
  name: "weight",
  chartFunc: "weight",
  type: "feature",
  defaultRoute: "/userhome/dashboard/litter/litter_member_dashboard/weight",
  menuIcon: { img: features.weight.main, alt: "weight_icon" },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "newEntry",
      menuItem: true,
      image: {
        img: features.weight.newEntry,
        alt: "weight_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: NewEntry,
      path: "/new_entry",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.weight.viewEntries,
        alt: "weight_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
    {
      disabled: false,
      name: "viewEntry",
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
  ],
  tableView: {
    headers: ["date", "lbs", "kg", "g"],
    mobileHeaders: ["date", "lbs", "kg", "g"],
  },
}

export default weightConfig
