import weight from "./litter_weight_box.svg"
import vaccinations from "./litter_vaccination.svg"
import parasites from "./litter_parasite.svg"
import addMember from "./litter_add_litter.svg"
import breederNotes from "./litter_breeder_notes.svg"
import expenses from "./litter_expenses.svg"
import weightReview from "./litter_weight_review.svg"
import expensesNewEntry from "./litter_expenses_new_entry.svg"
import expensesViewEntries from "./litter_expenses_view_entries.svg"
import infoMenuIcon from "assets/main/info.svg"

const litterFeatures = {
  weight,
  vaccinations,
  parasites,
  addMember,
  breederNotes,
  expenses,
  weightReview,
  expensesNewEntry,
  expensesViewEntries,
  infoMenuIcon,
}

export default litterFeatures
