import {
  InputFieldV5,
  CheckboxV5,
  TextareaV5,
  SliderV2,
} from "components/form";

const INPUT_FIELDS = [
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "time",
    group: "common",
    type: "time",
    icon: "far fa-clock",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
    lastInGroup: true,
  },
  {
    name: "notes",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    charLimit: 250,
    userCanEdit: true,
  },
  {
    name: "diagnosis",
    group: "painIndex",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    charLimit: 250,
    lastInGroup: true,
    userCanEdit: true,
  },
  {
    name: "medicine",
    group: "painIndex",
    type: "checkbox",
    labelOnly: true,
    icon: "",
    className: "",
    component: CheckboxV5,
    required: false,
    lastInGroup: true,
    userCanEdit: true,
  },
  {
    name: "medicine1",
    group: "painIndex",
    type: "text",
    icon: "fas fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "medicine2",
    group: "painIndex",
    type: "text",
    icon: "fas fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "medicine3",
    group: "painIndex",
    type: "text",
    icon: "fas fa-paw",
    className: "input ",
    component: InputFieldV5,
    required: false,
    lastInGroup: true,
    userCanEdit: true,
  },
  {
    name: "prevMedicine",
    group: "painIndex",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: false,
    lastInGroup: true,
    userCanEdit: true,
  },
  {
    name: "moveProblems",
    group: "hcpi",
    type: "range",
    min: "0",
    max: "100",
    step: "1",
    className: "slider is-fullwidth is-large",
    endLabels: true,
    endLabelLeft: "noDifficulties",
    endLabelRight: "worstPossible",
    component: SliderV2,
    required: false,
    userCanEdit: true,
  },
  {
    name: "lifeQuality",
    group: "hcpi",
    type: "range",
    min: "0",
    max: "100",
    step: "1",
    className: "slider is-fullwidth is-large",
    endLabels: true,
    endLabelLeft: "bestPossible",
    endLabelRight: "worstPossible",
    component: SliderV2,
    required: false,
    userCanEdit: true,
  },
];

export { INPUT_FIELDS };
