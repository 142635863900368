import React from "react";
import { connect } from "react-redux";
import { billing as actions } from "actions";
import { useStripe } from "@stripe/react-stripe-js";
import Intl from "components/Intl";

function AuthorizeCard({
  user,
  messages,
  locale,
  finalizeSubscriptionAfterSCA,
  setPaymentMethodRequired,
  history,
}) {
  // STRIPE HOOK
  const stripe = useStripe();

  async function handleAuthorization() {
    // Handle different types of intents for SCA
    // handleCardSetup
    try {
      if (user.stripeActionType === "setupIntent") {
        await stripe.handleCardSetup(user.stripeClientSecret);
      }
      if (user.stipeActionType === "SCA") {
        await stripe.confirmCardPayment(user.stripeClientSecret);
      }
      return finalizeSubscriptionAfterSCA(() =>
        history.push("/userhome/user_account/subscription")
      );
    } catch (error) {
      // handle error after authorization
      console.log("handleAuthorization error", error);
      return setPaymentMethodRequired(() =>
        history.push("/userhome/user_account/subscription")
      );
    }
  }

  return (
    <div className="message is-info">
      <div className="message-body">
        <p>{messages.sca.authRequired[locale]}</p>
        <br />
        <div className="button is-link" onClick={handleAuthorization}>
          <Intl message path="components.buttons.stripe.authorizeCard" />
        </div>
      </div>
    </div>
  );
}

export default connect(null, actions)(AuthorizeCard);
