import React from "react";
import { connect } from "react-redux";
import assets from "assets";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";
import { returnServiceType } from "functions";

function ContactRequest({
  _id,
  _provider: {
    providerInfo: { serviceName, serviceType, email },
    logo,
  },
  onClickAccept,
  onClickReject,
  serviceTypes,
  locale,
}) {
  return (
    <div className="card">
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure className="image is-48x48">
              <img src={assets.provider.providerNoLogo} alt="Placeholder" />
            </figure>
          </div>
          <div className="media-content">
            <p className="title is-5 has-text-dark">{serviceName}</p>
            <p className="subtitle is-6 has-text-dark">
              {returnServiceType(serviceTypes, serviceType, locale)}
            </p>
            <Buttons className="buttons is-right">
              <Button
                group="auth"
                name="rejectRequest"
                className="button is-danger is-small"
                onClick={onClickReject}
              />
              <Button
                group="auth"
                name="acceptRequest"
                className="button is-success is-small"
                onClick={onClickAccept}
              />
            </Buttons>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    serviceTypes:
      state.intl.messages.components.forms.selectOptions.serviceType,
  };
};

export default connect(mapStateToProps)(ContactRequest);
