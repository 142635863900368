import {
  AUTH_LOG_OUT_USER,
  SYS_MESSAGE,
  SYS_MESSAGE_DISMISS,
  SYS_MESSAGE_CARD_EXPIRING,
  SYS_MESSAGE_CARD_EXPIRING_DISMISS,
  SYS_MESSAGE_CARD_REQUIRES_ACTION,
  SYS_MESSAGE_CARD_REQUIRES_ACTION_DISMISS,
} from "actions/types";

const INITIAL_STATE = {
  showSystemMessage: true,
  systemMessage: "",
  showCardExpiringMessage: true,
  cardExpiringMessage: "",
  showCardRequiresActionMessage: true,
  cardRequiresActionMessage: "",
};

export default function SysMessageReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SYS_MESSAGE:
      return { ...state, systemMessage: action.payload };
    case SYS_MESSAGE_DISMISS:
      return { ...state, showSystemMessage: false };
    case SYS_MESSAGE_CARD_EXPIRING:
      return { ...state, cardExpiringMessage: action.payload };
    case SYS_MESSAGE_CARD_EXPIRING_DISMISS:
      return { ...state, showCardExpiringMessage: false };
    case SYS_MESSAGE_CARD_REQUIRES_ACTION:
      return { ...state, cardRequiresActionMessage: action.payload };
    case SYS_MESSAGE_CARD_REQUIRES_ACTION_DISMISS:
      return { ...state, showCardRequiresActionMessage: false };
    case AUTH_LOG_OUT_USER:
      return INITIAL_STATE;
    default:
    // Do Nothing
  }
  return state;
}
