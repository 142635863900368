import React from "react"
import Styles from "./smartTile.module.css"
import NewNotificationBadge from "./NewNotificationBadge"

export default function TileHeader(props) {
  return (
    <div className={`card-header ${Styles.tileHeader}`}>
      {props.newNotification && <NewNotificationBadge />}
      <div
        className={`card-header-title is-centered ${Styles.tileHeaderTitle}`}
      >
        {props.title}
      </div>
    </div>
  )
}
