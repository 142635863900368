import React from "react";
import { connect } from "react-redux";
import RADIO_BUTTON_OPTIONS from "constants/radio_button_options";

const RadioButtonsV5 = (props) => {
  const { label, field, type, locale, form, radioButtons, group } = props;
  return (
    <div className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">{label}</div>
      </label>
      <div className="field-body">
        <div className="field">
          {RADIO_BUTTON_OPTIONS[group].options.map((item, i) => {
            return (
              <div key={i} className="control">
                <label className={props.type}>
                  <input
                    id={i}
                    type={type}
                    {...field}
                    value={item.value}
                    checked={form.values[field.name] === item.value.toString()}
                  />
                  <span
                    className={
                      form.values[field.name] === item.value.toString()
                        ? " has-text-weight-bold"
                        : ""
                    }
                  >
                    {" "}
                    {radioButtons[item.option][locale]}
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, { group, field }) => {
  const { locale, messages } = state.intl;
  return {
    locale,
    label: messages.components.forms.labels[group][field.name][locale],
    radioButtons: messages.components.forms.radioButtons[group],
  };
};

export default connect(mapStateToProps)(RadioButtonsV5);
