import {
  TextareaV5,
  SelectV5,
  UrlInputLinkV5,
  CheckboxV5,
} from "components/form";

const INPUT_FIELDS = [
  {
    name: "bodyMarkerDetailType",
    group: "bodyMarkerDetail",
    type: "select",
    icon: "fa fa-paw",
    className: "select ",
    required: true,
    component: SelectV5,
    userCanEdit: true,
    lastInGroup: true,
  },
  {
    name: "description",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    charLimit: 1000,
    userCanEdit: true,
  },
  {
    name: "attachmentURL",
    group: "common",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: UrlInputLinkV5,
    required: false,
    userCanEdit: true,
    lastInGroup: true,
  },
  {
    name: "painArea",
    group: "bodyMarkerDetail",
    type: "checkbox",
    className: "input",
    component: CheckboxV5,
    required: false,
    userCanEdit: true,
    labelOnly: false,
    noLabel: true,
  },
  {
    name: "sampleTaken",
    group: "bodyMarkerDetail",
    type: "checkbox",
    className: "input",
    component: CheckboxV5,
    required: false,
    userCanEdit: true,
    labelOnly: false,
    noLabel: true,
  },
  {
    name: "removedOrHealed",
    group: "bodyMarkerDetail",
    type: "checkbox",
    className: "input",
    component: CheckboxV5,
    required: false,
    userCanEdit: true,
    labelOnly: false,
    noLabel: true,
    lastInGroup: true,
  },
];

export { INPUT_FIELDS };
