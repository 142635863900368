import React from "react";
import Bounds from "components/bounds";
import { connect } from "react-redux";

import config from "./config";

import Hero from "components/layout/Hero";
import HeroHead from "components/layout/Hero_Head";
import HeroBody from "components/layout/Hero_Body";
import HeroFoot from "components/layout/Hero_Foot";

import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import Navigation from "./Navigation";
import SysMessage from "components/sys_message";
import Toast from "components/toast";
import PetNameDashboard from "./PetNameDashboard";

function Layout(props) {
  return (
    <Hero {...config.hero}>
      <HeroHead {...config.heroHead}>
        <Header {...props} />
      </HeroHead>
      <HeroBody {...config.heroBody}>
        <Toast {...props.actionCalls} />
        <Bounds
          minWidth="601"
          render={() => (
            <div>
              <br />
              <Navigation {...props} />
              <br />
            </div>
          )}
          else={() => <div style={{ margin: -24 }} />}
        />
        <SysMessage />
        <div style={{ paddingBottom: 10 }}>
          <PetNameDashboard pathname={props.location.pathname} />
          <Content {...props} />
        </div>
      </HeroBody>
      <HeroFoot {...config.heroFoot}>
        <Bounds
          maxWidth="600"
          render={() => (
            <>
              <Footer {...props} />
              <div
                className="navbar is-fixed-bottom"
                style={{ maxWidth: "100vw" }}
              >
                <Navigation mobile {...props} />
              </div>
            </>
          )}
          else={() => <Footer {...props} />}
        />
      </HeroFoot>
    </Hero>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthed: state.reducers.auth.isAuthenticated,
    role: state.reducers.auth.role,
    actionCalls: state.reducers.actionCalls,
  };
};

export default connect(mapStateToProps)(Layout);
