import React from "react";
import { connect } from "react-redux";
import Label from "components/styled/Label";
import inputValidationColor from "./Input_Validation_Color";
import FormValidationMessageV5 from "./FormValidationMessageV5";
import { returnCharsLeft } from "functions";

const TextareaV5 = (props) => {
  const {
    field,
    form,
    className,
    viewMode,
    editMode,
    userCanEdit,
    rows,
    type,
    icon,
    labels,
    placeholder,
    group,
    charLimit,
    values,
  } = props;

  return (
    <div className="field" style={{ paddingBottom: 8 }}>
      <label className="label is-small has-text-grey">
        <div className="heading">{labels.label}</div>
        <Label fontSize={10} transform={"uppercase"}>
          {" "}
          {`${labels.charsLeft}: ${returnCharsLeft(
            field.name,
            charLimit,
            values
          )}`}
        </Label>
      </label>
      <div className="control has-icons-left has-icons-right">
        <textarea
          {...field}
          type={type}
          rows={rows}
          className={
            viewMode
              ? className
              : `${className} ${inputValidationColor(
                  field.name,
                  form.touched,
                  form.errors
                )}`
          }
          placeholder={placeholder}
          readOnly={viewMode || (!userCanEdit && editMode) ? true : false}
          style={style2}
        />
        <span className="icon is-small is-left">
          <i className={icon} />
        </span>
      </div>
      <FormValidationMessageV5
        clientMessage
        group={group}
        name={field.name}
        errors={form.errors}
        touched={form.touched}
      />
    </div>
  );
};
const style2 = {
  border: `1px solid lightgrey`,
};

const mapStateToProps = (state, { group, field }) => {
  const { locale, messages } = state.intl;
  const placeholder = messages.components.forms.placeholders[group]
    ? messages.components.forms.placeholders[group][field.name][locale]
    : "";
  return {
    labels: {
      label: messages.components.forms.labels[group][field.name][locale],
      charsLeft: messages.components.forms.labels.common.charsLeft[locale],
    },
    placeholder,
  };
};

export default connect(mapStateToProps)(TextareaV5);
