import axios from "axios"

import {
  USEFUL_LINK_FETCH,
  USEFUL_LINK_ADD,
  USEFUL_LINK_UPDATE,
  USEFUL_LINK_DELETE,
  ACTION_STARTED,
  ACTION_SUCCESS,
} from "./types"
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "./functions"

const API = (route) => `/api/useful_link/${route}`

export const fetchUsefulLinks = (_user, providerView) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: USEFUL_LINK_FETCH })
    const res = await axios.get(
      API(providerView ? "fetch_all_by_provider" : "fetch"),
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
        params: {
          _user: providerView ? _user : undefined,
          providerView,
        },
      }
    )
    dispatch({ type: USEFUL_LINK_FETCH, payload: res.data.usefulLinks })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addUsefulLink = (
  values,
  setSubmitting,
  callback,
  providerContact,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: USEFUL_LINK_ADD })
    const res = await axios.post(
      API(providerView ? "add_by_provider" : "add"),
      { _user: providerView ? providerContact._id : undefined, ...values },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({ type: USEFUL_LINK_ADD, payload: res.data.usefulLink })
    endAndResetAction(dispatch)
    callback()
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const updateUsefulLink = (
  values,
  setSubmitting,
  callback,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: USEFUL_LINK_UPDATE })
    const res = await axios.put(
      API(providerView ? "update_by_provider" : "update"),
      values,
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({ type: USEFUL_LINK_UPDATE, payload: res.data.usefulLink })
    endAndResetActionWithDelay(dispatch, 1500)
    callback()
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const deleteUsefulLink = (id, callback, providerView) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: USEFUL_LINK_DELETE })
    const res = await axios.put(
      API(providerView ? "delete_by_provider" : "delete"),
      { id },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({ type: USEFUL_LINK_DELETE, payload: res.data.usefulLink })
    endAndResetActionWithDelay(dispatch, 1500)
    callback()
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const usefulLinksActions = {
  fetchUsefulLinks,
  addUsefulLink,
  updateUsefulLink,
  deleteUsefulLink,
}

export default usefulLinksActions
