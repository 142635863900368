import React from "react";
import { connect } from "react-redux";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";
import BodyText from "./BodyText";
import Intl from "components/Intl";

function ConfirmProviderFields({
  handleModalClose,
  handleConfirmAndRegister,
  fieldsToConfirm,
  messages,
  locale,
}) {
  const FIELD_KEYS = Object.keys(fieldsToConfirm);
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title is-size-6-mobile">
            {messages.title[locale]}
          </p>
        </header>
        <section className="modal-card-body has-text-dark">
          <BodyText
            noDefault={true}
            ownBodyText={true}
            messages={messages.bodyText}
            type={"confirmProviderFields"}
            locale={locale}
          />
          {FIELD_KEYS.map((key) => (
            <ItemToConfirm key={key} label={key} item={fieldsToConfirm[key]} />
          ))}
        </section>
        <footer className="modal-card-foot">
          <Buttons>
            <Button name="back" group="common" onClick={handleModalClose} />
            <Button
              name="confirmAndRegister"
              group="common"
              onClick={handleConfirmAndRegister}
              className="is-success"
            />
          </Buttons>
        </footer>
      </div>
    </div>
  );
}

const ItemToConfirm = ({ label, item }) => {
  return (
    <div style={{ marginBottom: 16 }}>
      <label className="has-text-weight-semibold">
        <Intl
          message
          path={`components.forms.labels.providerRegister.${label}`}
        />
      </label>
      <p
        className={`box has-text-weight-bold ${
          label === "email" && "is-lowercase"
        }`}
        style={{ padding: 12 }}
      >
        {item}
      </p>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: {
      title: state.intl.messages.components.modals.titles.confirmProviderFields,
      bodyText: state.intl.messages.components.modals.bodyText,
    },
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(ConfirmProviderFields);
