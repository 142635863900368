import React from "react"
import styled from "styled-components"

const Container = styled.div`
  position: relative;
  overflow: none;
  z-index: 10;
  padding: ${props => (props.isMobile ? 4 : 20)}px;
`

const ImageContainer = props => (
  <Container {...props}>{props.children}</Container>
)

export default ImageContainer
