import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { weight as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import moment from "moment";
import { INPUT_FIELDS } from "./fields/litter_member_weight";
import FormButtons from "components/form/FormButtons";

class LitterMemberWeightForm extends Component {
  renderFields() {
    const { viewMode, editMode, values, userPreferredUnit } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (field.renderCondition && !field.renderCondition(userPreferredUnit))
        return null;
      return (
        <Field
          key={i}
          petType={"litterMember"}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  }

  render() {
    const { errors, dirty, isSubmitting, viewMode, editMode, cancel } =
      this.props;
    return (
      <Form className="has-text-left">
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={"/userhome/dashboard/litter/litter_member_dashboard/weight"}
        />
        <br />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pet: state.reducers.litters.selectedLitterMember,
  };
};

LitterMemberWeightForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet }) {
    return {
      date: moment(valuesToEdit.date).format("YYYY-MM-DD") || "",
      kg: !_.isEmpty(valuesToEdit)
        ? valuesToEdit.kg
        : pet.petWeightKg > 0
        ? pet.petWeightKg
        : 0,
      g: !_.isEmpty(valuesToEdit)
        ? valuesToEdit.g
        : pet.petWeightG > 0
        ? pet.petWeightG
        : 0,
      lbs: !_.isEmpty(valuesToEdit)
        ? valuesToEdit.lbs
        : pet.petWeightLbs > 0
        ? pet.petWeightLbs
        : 0,
      notes: valuesToEdit.notes || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
      _litter: valuesToEdit._litter || pet._litter,
      updatePetProfile: true,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    kg: Yup.number(),
    g: Yup.number(),
    notes: Yup.string().max(250, "max-250"),
    updatePetProfile: Yup.boolean(),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute =
      "/userhome/dashboard/litter/litter_member_dashboard/weight/view_entries";
    if (props.editMode) {
      return props.updateWeightEntryLitterMember(
        values,
        setSubmitting,
        props.history,
        successRoute
      );
    }
    props.addWeightEntryLitterMember(
      { ...values, weightUnit: props.userPreferredUnit },
      setSubmitting,
      props.history,
      successRoute
    );
  },
})(LitterMemberWeightForm);

export default withRouter(
  connect(mapStateToProps, actions)(LitterMemberWeightForm)
);
