import React, { Component } from "react"
import MedicineEntryForm from "forms/Medicine_Entry_Form"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"
import FormWrapper from "components/layout/Form_Wrapper"

class MedicineEntry extends Component {
  render() {
    return (
      <FormWrapper>
        <MedicineEntryForm valuesToEdit={{}} {...this.props} />
      </FormWrapper>
    )
  }
}

export default RequireSubFeature(MedicineEntry)
