import React, { useState } from "react";
import { connect } from "react-redux";

const ReviewImage = (props) => {
  const [saved, setSaved] = useState(false);

  async function handleSaveToProfileButtonClick() {
    await props.handleAddProfileImageToPet();
    setSaved(true);
  }

  return (
    <>
      <div className="level">
        <div className="level-item">
          <figure className="image is-128x128">
            <img
              className="is-rounded"
              src={props.img}
              alt="menu_image_preview"
            />
          </figure>
        </div>
      </div>
      {!saved && (
        <div className="buttons is-centered">
          <button
            className="button is-success is-fullwidth"
            onClick={handleSaveToProfileButtonClick}
          >
            {props.buttons.saveAsProfileImage[props.locale]}
          </button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    buttons: state.intl.messages.components.buttons.petProfile,
  };
};

export default connect(mapStateToProps)(ReviewImage);
