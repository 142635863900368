import { features } from "assets"
import { path } from "constants/feature_routes_provider_view"
import NewEntry from "./New_Entry_View"
import ViewEntries from "./View_Entries"
import ViewEntry from "./View_Entry.js"
import LandingRedirect from "components/features/Landing_Redirect"

const parasitesProviderConfig = {
  name: "parasites",
  type: "feature",
  redirect: path("parasites/view_entries"),
  defaultRoute: path("parasites"),
  menuIcon: { img: features.parasites.main, alt: "parasites_icon" },
  providerView: true,
  providerEdit: true,
  nav: [
    {
      name: "landingRedirect",
      disabled: false,
      menuItem: false,
      exact: true,
      component: LandingRedirect,
      path: "/",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.parasites.viewEntries,
        alt: "parasites_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
    {
      disabled: false,
      name: "newEntry",
      menuItem: true,
      image: {
        img: features.parasites.newEntry,
        alt: "parasites_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: NewEntry,
      path: "/new_entry",
    },
    {
      disabled: false,
      name: "viewEntry",
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
  ],
}

export default parasitesProviderConfig
