import auth from "./auth"
import main from "./main"
import features from "./features"
import pets from "./pets"
import user from "./user"
import litterFeatures from "./litter_features"
import components from "./components"
import globalTiles from "./global_tiles"
import professionals from "./professionals"
import provider from "./provider"

const assets = {
  auth,
  main,
  features,
  pets,
  user,
  litterFeatures,
  components,
  globalTiles,
  professionals,
  provider,
}

export default assets

export {
  auth,
  main,
  features,
  pets,
  user,
  litterFeatures,
  components,
  globalTiles,
  professionals,
  provider,
}
