// Assets
import assets from "assets";

// Constants
import {
  LOCALHOST,
  TASSUAPU_STAGING,
  PETFILER_STAGING,
  TASSUAPU_PROD,
  PETFILER_PROD,
} from "functions/hostname";

// HOCs
import RequireAuth from "components/hoc/Require_Auth";
import RequireSelectedPet from "components/hoc/Require_Selected_Pet";

//Features
import AwardCabinet from "containers/features/Award_Cabinet";
import FoodDiary from "containers/features/Food_Diary";
import Hcpi from "containers/features/Hcpi";
import HealthDiary from "containers/features/Health_Diary";
import HoofCare from "containers/features/Hoof_Care";
import Parasites from "containers/features/Parasites";
import TrainingDiary from "containers/features/Training_Diary";
import Activity from "containers/features/Activity";
import Reporting from "containers/features/Reporting";
import StableDiary from "containers/features/Stable_Diary";
import Vaccinations from "containers/features/Vaccinations";
import Weight from "containers/features/Weight";
import Litters from "containers/features/Litters";
import AnimalHospital from "containers/features/Animal_Hospital";
import UsefulLinks from "containers/features/Useful_Links";
import HeatDiary from "containers/features/Heat_Diary";
import HomeCare from "containers/features/Home_Care";
import Appointments from "containers/features/Appointments";
import StressLevel from "containers/features/Stress_Level";
import Fmpi from "containers/features/Fmpi";
import Tasks from "containers/features/Tasks";
import BodyMarker from "containers/features/Body_Marker";
import ToothMarker from "containers/features/Tooth_Marker";

const featureRoutes = [
  // **************************
  //
  // Features
  //
  // **************************
  {
    name: "hcpi",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/hcpi",
    exact: false,
    component: RequireAuth(RequireSelectedPet(Hcpi)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog"],
    img: {
      src: assets.features.hcpi.main,
      alt: "hcpi_main_logo",
    },
  },
  {
    name: "vaccinations",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/vaccinations",
    exact: false,
    component: RequireAuth(RequireSelectedPet(Vaccinations)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.vaccinations.main,
      alt: "vaccinations_main_logo",
    },
  },
  {
    name: "weight",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/weight",
    exact: false,
    component: RequireAuth(RequireSelectedPet(Weight)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.weight.main,
      alt: "weight_main_logo",
    },
  },
  {
    name: "foodDiary",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/food_diary",
    exact: false,
    component: RequireAuth(RequireSelectedPet(FoodDiary)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.foodDiary.main,
      alt: "food_diary_main_logo",
    },
  },
  {
    name: "healthDiary",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/health_diary",
    exact: false,
    component: RequireAuth(RequireSelectedPet(HealthDiary)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.healthDiary.main,
      alt: "health_diary_main_logo",
    },
  },
  {
    name: "parasites",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/parasites",
    exact: false,
    component: RequireAuth(RequireSelectedPet(Parasites)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.parasites.main,
      alt: "parasites_main_logo",
    },
  },
  {
    name: "trainingDiary",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/training_diary",
    exact: false,
    component: RequireAuth(RequireSelectedPet(TrainingDiary)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat", "horse"],
    img: {
      src: assets.features.trainingDiary.viewEntries,
      alt: "training_diary_feature_logo",
    },
  },
  {
    name: "activity",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/activity",
    exact: false,
    component: RequireAuth(RequireSelectedPet(Activity)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog"],
    img: {
      src: assets.features.activity.dailyActivityViewEntries,
      alt: "activity_feature_logo",
    },
  },
  {
    name: "stressLevel",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/stress_level",
    exact: false,
    component: RequireAuth(RequireSelectedPet(StressLevel)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog"],
    img: {
      src: assets.features.stressLevel.main,
      alt: "stress_level_feature_logo",
    },
  },
  {
    name: "stableDiary",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/stable_diary",
    exact: false,
    component: RequireAuth(RequireSelectedPet(StableDiary)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["horse"],
    img: {
      src: assets.features.stableDiary.main,
      alt: "stable_diary_main_logo",
    },
  },
  {
    name: "hoofCare",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/hoof_care",
    exact: false,
    component: RequireAuth(RequireSelectedPet(HoofCare)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["horse"],
    img: {
      src: assets.features.hoofCare.main,
      alt: "hoof_care_main_logo",
    },
  },
  {
    name: "awardCabinet",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/award_cabinet",
    exact: false,
    component: RequireAuth(RequireSelectedPet(AwardCabinet)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.awardCabinet.main,
      alt: "award_cabinet_main_logo",
    },
  },
  {
    name: "reporting",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/reporting",
    exact: false,
    component: RequireAuth(RequireSelectedPet(Reporting)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.reporting.main,
      alt: "reporting_main_logo",
    },
  },
  {
    name: "litters",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/litters",
    exact: false,
    component: RequireAuth(RequireSelectedPet(Litters)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    petSex: "female",
    img: {
      src: assets.features.litters.addNew,
      alt: "litters_add_new_logo",
    },
  },
  {
    name: "animalHospital",
    disabled: false,
    feature: true,
    clients: [LOCALHOST, TASSUAPU_PROD, TASSUAPU_STAGING],
    path: "/userhome/dashboard/animal_hospital",
    exact: false,
    component: RequireAuth(RequireSelectedPet(AnimalHospital)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.animalHospital.main,
      alt: "features_animal_hospital_main_logo",
    },
  },
  {
    name: "usefulLinks",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/useful_links",
    exact: false,
    component: RequireAuth(RequireSelectedPet(UsefulLinks)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.usefulLinks.main,
      alt: "useful_links_main_logo",
    },
  },
  {
    name: "heatDiary",
    disabled: false,
    comingSoon: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/heat_diary",
    exact: false,
    component: RequireAuth(RequireSelectedPet(HeatDiary)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog"],
    petSex: "female",
    img: {
      src: assets.features.heatDiary.main,
      alt: "heat_diary_main_logo",
    },
  },
  {
    name: "homeCare",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/home_care",
    exact: false,
    component: RequireAuth(RequireSelectedPet(HomeCare)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.homeCare.main,
      alt: "home_care_feature_logo",
    },
  },
  {
    name: "appointments",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/appointments",
    exact: false,
    component: RequireAuth(RequireSelectedPet(Appointments)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.appointments.main,
      alt: "appointments_feature_logo",
    },
  },
  {
    name: "fmpi",
    disabled: false,
    feature: true,
    clients: [LOCALHOST, TASSUAPU_PROD, TASSUAPU_STAGING],
    path: "/userhome/dashboard/fmpi",
    exact: false,
    component: RequireAuth(RequireSelectedPet(Fmpi)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["cat"],
    img: {
      src: assets.features.fmpi.main,
      alt: "fmpi_main_logo",
    },
  },
  {
    name: "tasks",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/tasks",
    exact: false,
    component: RequireAuth(RequireSelectedPet(Tasks)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat", "horse", "other"],
    img: {
      src: assets.features.tasks.main,
      alt: "tasks_main_logo",
    },
  },
  {
    name: "bodyMarker",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/body_marker",
    exact: false,
    component: RequireAuth(RequireSelectedPet(BodyMarker)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.bodyMarker.main,
      alt: "body_marker_main_logo",
    },
  },
  {
    name: "toothMarker",
    disabled: false,
    feature: true,
    clients: [
      LOCALHOST,
      TASSUAPU_PROD,
      TASSUAPU_STAGING,
      PETFILER_PROD,
      PETFILER_STAGING,
    ],
    path: "/userhome/dashboard/tooth_marker",
    exact: false,
    component: RequireAuth(RequireSelectedPet(ToothMarker)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.toothMarker.main,
      alt: "tooth_marker_main_logo",
    },
  },
];

export default featureRoutes;
