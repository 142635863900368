import React from "react"
import { returnDateLocaleFormat } from "functions"

function PostTitle({ title, date, locale }) {
  return (
    <>
      <strong dangerouslySetInnerHTML={{ __html: title }} />
      <p>
        <small
          dangerouslySetInnerHTML={{
            __html: returnDateLocaleFormat(date, locale),
          }}
        />
      </p>
    </>
  )
}

export default PostTitle
