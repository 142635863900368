import React, { Component } from "react"
import { connect } from "react-redux"
import Message from "components/message"

export default function RequireSubFeature(ComposedComponent) {
  class Authentication extends Component {
    render() {
      const { user } = this.props
      const arr = [user.trialPeriod, user.subscription]
      if (!arr.includes(true) && !this.props.providerView) {
        return <Message warning message={"trialOrSubscriptionRequired"} />
      }

      return <ComposedComponent {...this.props} />
    }
  }

  const mapStateToProps = (state) => {
    return {
      user: state.reducers.auth.user,
    }
  }
  return connect(mapStateToProps)(Authentication)
}
