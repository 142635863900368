import axios from "axios";
import {
  ACTION_STARTED,
  ACTION_SUCCESS,
  ACTION_ERROR,
  ACTION_ENDED,
  ACTION_RESET,
  AUTH_LOG_OUT_USER,
} from "actions/types";
import { endAndResetAction } from "actions/functions";

import { updateIntl } from "react-intl-redux";
import { updateLocale } from "functions";
import messages from "messages";

export const AUTH_FETCH_CURRENT_PROVIDER = "auth_fetch_current_provider";
export const AUTH_LOG_OUT_PROVIDER = "auth_log_out_provider";
export const AUTH_VERIFY_EMAIL_PROVIDER_REQUIRED =
  "auth_verify_email_provider_required";
export const AUTH_RESEND_VERIFY_EMAIL_PROVIDER =
  "auth_resend_verify_email_provider";

export const fetchCurrentProvider = (history) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: AUTH_FETCH_CURRENT_PROVIDER });
    const res = await axios.get("/api/auth/provider/fetch_current_provider", {
      headers: { authorization: localStorage.getItem("tokenProvider") },
    });
    if (res.data.verifyEmailRequired) {
      dispatch({
        type: AUTH_VERIFY_EMAIL_PROVIDER_REQUIRED,
        payload: res.data,
      });
      return endAndResetAction(dispatch);
    }
    if (res.data.provider.providerInfo.locale) {
      dispatch(
        updateIntl({
          locale: res.data.provider.providerInfo.locale,
          messages,
        })
      );
      updateLocale(res.data.provider.providerInfo.locale);
    }
    dispatch({ type: AUTH_FETCH_CURRENT_PROVIDER, payload: res.data.provider });
    endAndResetAction(dispatch);
  } catch (error) {
    dispatch({
      type: ACTION_ERROR,
      payload: "fetchProfile",
    });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: AUTH_LOG_OUT_PROVIDER });
      history.push("/");
      dispatch({ type: ACTION_RESET });
    }, 3000);
  }
};

export const logOutProvider = () => async (dispatch) => {
  dispatch({ type: AUTH_LOG_OUT_PROVIDER });
  dispatch({ type: AUTH_LOG_OUT_USER });
};

export const verifyEmailProvider =
  ({ verifyEmailCode }, resetForm) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: AUTH_VERIFY_EMAIL_PROVIDER_REQUIRED,
      });
      const res = await axios.post(
        "/api/auth/provider/verify_email",
        { verifyEmailCode: verifyEmailCode },
        {
          headers: { authorization: localStorage.getItem("tokenProvider") },
        }
      );
      dispatch({
        type: ACTION_SUCCESS,
        payload: "emailVerificationSuccess",
      });
      dispatch({
        type: AUTH_FETCH_CURRENT_PROVIDER,
        payload: res.data.provider,
      });
      endAndResetAction(dispatch);
    } catch (error) {
      resetForm();
      if (error.response) {
        if (error.response.status === 400) {
          dispatch({
            type: ACTION_ERROR,
            payload: "invalidVerifyEmailCode",
          });
        } else {
          dispatch({
            type: ACTION_ERROR,
            payload: "somethingWentWrong",
          });
        }
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 4000);
    }
  };

export const resendVerifyEmailProvider = (locale) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: AUTH_RESEND_VERIFY_EMAIL_PROVIDER,
    });
    await axios.post(
      "/api/auth/provider/verify_email/resend",
      { locale: locale },
      {
        headers: { authorization: localStorage.getItem("tokenProvider") },
      }
    );
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_SUCCESS, payload: "verificationCodeSentSuccess" });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 2000);
  } catch (error) {
    if (error) {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      });
    }
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 2000);
  }
};

const providerHomeActions = {
  fetchCurrentProvider,
  logOutProvider,
  verifyEmailProvider,
  resendVerifyEmailProvider,
};

export default providerHomeActions;
