import { main } from "assets"
import Advertisements from "containers/main/Advertisements"
import RequireAuth from "components/hoc/Require_Auth"
import { ADS_FETCH } from "./actions"
import { LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD } from "functions/hostname"

const advertisementsConfig = {
  main: {
    name: "advertisements",
    path: "/userhome/offers_and_more",
    exact: false,
    component: RequireAuth(Advertisements),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    img: {
      src: main.advertisements,
      alt: "advertisements_main_logo",
    },
    actionTypes: [ADS_FETCH],
  },
  clients: [LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD],
}

export default advertisementsConfig
