import React from "react"
import { connect } from "react-redux"
import * as actions from "../actions"
import ContactRequests from "./Contact_Requests"
import Contacts from "./Contacts"
import Message from "components/message"

function UserContacts(props) {
  if (
    props.contacts.contacts.length === 0 &&
    props.contacts.pendingRequests.length === 0
  )
    return <Message warning message="noContactsToDisplay" />

  return (
    <div>
      <ContactRequests {...props} />
      <Contacts {...props} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    contacts: state.reducers.contacts,
  }
}

export default connect(mapStateToProps, actions)(UserContacts)
