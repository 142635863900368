import { main } from "assets"
import {
  BLOG_FETCH_ALL_TAGS,
  BLOG_FETCH_POSTS_LATEST,
  BLOG_FETCH_POSTS_BY_TAG,
} from "./actions"
import Blog from "containers/main/Blog"
import LatestPosts from "./views/LatestPosts"
import RequireAuth from "components/hoc/Require_Auth"
import Landing from "components/features/Landing"
import ViewBlogPost from "./views/ViewBlogPost"
import SearchByTag from "./views/SearchByTag"
import FetchPostsByTag from "./views/FetchPostsByTag"
import ViewFetchedPostsByTag from "./views/ViewFetchedPostsByTag"
import { LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD } from "functions/hostname"

const blogConfig = {
  main: {
    name: "blog",
    path: "/userhome/blog",
    exact: false,
    component: RequireAuth(Blog),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    img: {
      src: main.blogMain,
      alt: "blog_main_logo",
    },
    actionTypes: [
      BLOG_FETCH_ALL_TAGS,
      BLOG_FETCH_POSTS_LATEST,
      BLOG_FETCH_POSTS_BY_TAG,
    ],
  },
  clients: [LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD],
  nav: [
    {
      name: "landing",
      path: "/",
      exact: true,
      component: Landing,
      disabled: false,
      menuItem: true,
      hr: true,
      image: {
        img: main.infoMenuIcon,
        alt: "info_menu_icon",
      },
    },
    {
      name: "latestPosts",
      path: "/latest_posts",
      exact: false,
      component: LatestPosts,
      disabled: false,
      menuItem: true,
      image: {
        img: main.blogMain,
        alt: "blog_main_logo",
      },
    },
    {
      name: "searchByTag",
      path: "/search_by_tag",
      exact: false,
      component: SearchByTag,
      disabled: false,
      menuItem: true,
      image: {
        img: main.blogSearch,
        alt: "blog_search_by_tag_icon",
      },
    },
    {
      name: "viewBlogPost",
      path: "/post",
      exact: false,
      component: ViewBlogPost,
      disabled: false,
      menuItem: false,
    },
    {
      name: "fetchPostsByTag",
      path: "/fetched_posts_by_tag/:id",
      exact: false,
      component: FetchPostsByTag,
      disabled: false,
      menuItem: false,
    },
    {
      name: "filterBySingleTag",
      path: "/posts_with_tag/:id",
      exact: false,
      component: ViewFetchedPostsByTag,
      disabled: false,
      menuItem: false,
    },
  ],
}

export default blogConfig
