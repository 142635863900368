import React from "react"
import { Link } from "react-router-dom"
import TileWrapper from "./TileWrapper"
import TileBody from "./TileBody"

function PartnerTile(props) {
  const { tileImage, path } = props.fields
  return (
    <TileWrapper>
      <Link to={`/userhome${path}`}>
        <TileBody img={tileImage.fields.file.url} />
      </Link>
    </TileWrapper>
  )
}

export default PartnerTile
