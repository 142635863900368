import {
  AWARD_CABINET_FETCH,
  AWARD_CABINET_ADD,
  AWARD_CABINET_UPDATE,
  AWARD_CABINET_DELETE,
  AUTH_LOG_OUT_USER,
} from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";
import _ from "lodash";

const initialState = {
  awards: [],
  awardsFetched: [],
};

export default function AwardCabinetReducer(state = initialState, action) {
  switch (action.type) {
    case AWARD_CABINET_FETCH:
      return {
        ...state,
        awards: _.uniqWith([...state.awards, ...action.payload], _.isEqual),
        awardsFetched: [...state.awardsFetched, action.pet._id],
      };
    case AWARD_CABINET_ADD:
      return {
        ...state,
        awards: _.sortBy(
          [
            action.payload,
            ...state.awards.filter((award) => award._id !== action.payload),
          ],
          "dateAwarded"
        ).reverse(),
      };
    case AWARD_CABINET_UPDATE:
      return {
        ...state,
        awards: _.sortBy(
          [
            ...state.awards.filter((award) => award._id !== action.payload._id),
            action.payload,
          ],
          "dateAwarded"
        ),
      };
    case AWARD_CABINET_DELETE:
      return {
        ...state,
        awards: state.awards.filter((award) => award._id !== action.payload),
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return initialState;
    default:
    // Do Nothing
  }
  return state;
}
