import React from "react"
import FacebookPage from "./Facebook_Page"
import { returnFacebookAppId } from "functions"

function Facebook(props) {
  switch (true) {
    case props.type === "page":
      return <FacebookPage appId={returnFacebookAppId()} {...props} />
    default:
      return <div />
  }
}

export default Facebook
