import assets from "assets";
import ProviderContacts from "containers/contacts/Provider_Contacts";
import ProviderAccount from "containers/Provider_Account";

const providerHomeRoutes = [
  {
    name: "contacts",
    view: "providerHome",
    disabled: false,
    exact: true,
    path: "/providerhome/contacts",
    component: ProviderContacts,
    img: {
      src: assets.provider.providerContactsHome,
      alt: "provider_contacts",
    },
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
  },
  {
    name: "account",
    view: "providerHome",
    disabled: false,
    exact: true,
    path: "/providerhome/account",
    component: ProviderAccount,
    img: {
      src: assets.provider.providerAccount,
      alt: "provider_account",
    },
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
  },
];

export default providerHomeRoutes;
