import React, { useEffect } from "react";
import WEIGHT_INPUT_VALUES from "constants/fine_weight_control";
import inputValidationColor from "./Input_Validation_Color";

function FineWeightControlNew(props) {
  const {
    form: { setFieldValue, values, touched, errors },
    field,
    viewMode,
    className,
    petType,
    weightUnit,
  } = props;

  useEffect(() => {
    if (weightUnit === "kg") {
      let lbs = (values.kg * 1000 + values.g) / 454;
      setFieldValue("lbs", Number(lbs.toFixed(0)));
    }
    if (weightUnit === "lbs") {
      let lbsToKg = (values.lbs / 2.205).toFixed(3);
      let kgParsed = lbsToKg.toString().split(".");
      setFieldValue("kg", Number(kgParsed[0]));
      setFieldValue("g", Number(kgParsed[1] ? kgParsed[1] : 0));
    }
  }, [weightUnit, setFieldValue, values.kg, values.g, values.lbs]);

  function fineWeightControl(unit, arrow) {
    switch (true) {
      case arrow === "left" &&
        values[unit] > WEIGHT_INPUT_VALUES[petType][unit].min:
        return setFieldValue(
          unit,
          values[unit] + WEIGHT_INPUT_VALUES[petType][unit].arrowLeft
        );
      case arrow === "right" &&
        values[unit] < WEIGHT_INPUT_VALUES[petType][unit].max:
        return setFieldValue(
          unit,
          values[unit] + WEIGHT_INPUT_VALUES[petType][unit].arrowRight
        );
      default:
      // Do nothing
    }
  }

  const arrowStyle = { cursor: "pointer" };
  return (
    <>
      <div className="field">
        <div className="control">
          <div className="is-size-4 has-text-centered">
            <div className="level is-mobile">
              {!viewMode && (
                <div className="level-item">
                  <i
                    style={arrowStyle}
                    className="fas fa-angle-left fa-2x"
                    onClick={() => fineWeightControl(field.name, "left")}
                  />
                </div>
              )}
              <div className="level-item">
                <div className="field has-addons">
                  <p className="control is-expanded">
                    <input
                      {...field}
                      className={
                        viewMode
                          ? className
                          : `${className} ${inputValidationColor(
                              field.name,
                              touched,
                              errors
                            )}`
                      }
                      type="number"
                      value={field.value}
                      min={WEIGHT_INPUT_VALUES[petType][field.name].min}
                      max={WEIGHT_INPUT_VALUES[petType][field.name].max}
                    />
                  </p>
                  <div className="control">
                    <div className="button is-static">{field.name}</div>
                  </div>
                </div>
              </div>
              {!viewMode && (
                <div className="level-item">
                  <i
                    style={arrowStyle}
                    className="fas fa-angle-right fa-2x"
                    onClick={() => fineWeightControl(field.name, "right")}
                  />
                </div>
              )}
            </div>
          </div>
          {!viewMode && (
            <input
              style={{ padding: "0 34px" }}
              {...field}
              type="range"
              className="slider is-primary has-output-tooltip is-fullwidth is-circle is-large"
              step={WEIGHT_INPUT_VALUES[petType][field.name].sliderStep}
              min={WEIGHT_INPUT_VALUES[petType][field.name].min}
              max={WEIGHT_INPUT_VALUES[petType][field.name].max}
            />
          )}
        </div>
      </div>
      <br />
    </>
  );
}
export default FineWeightControlNew;
