import { InputFieldV5, TextareaV5, UrlInputLinkV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "displayTitle",
    group: "awardCabinet",
    type: "text",
    icon: "fas fa-trophy",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "dateAwarded",
    group: "awardCabinet",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "eventName",
    group: "awardCabinet",
    type: "text",
    icon: "fas fa-trophy",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "awardedBy",
    group: "awardCabinet",
    type: "text",
    icon: "fas fa-trophy",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "reasonAwarded",
    group: "awardCabinet",
    type: "text",
    icon: "fas fa-trophy",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "additionalInfo",
    group: "awardCabinet",
    type: "text",
    className: "textarea ",
    component: TextareaV5,
    rows: 5,
    required: false,
    charLimit: 250,
    userCanEdit: true,
  },
  {
    name: "attachmentURL",
    group: "common",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: UrlInputLinkV5,
    required: false,
    userCanEdit: true,
  },
];

export { INPUT_FIELDS };
