import { features } from "assets";
import Landing from "components/features/Landing";
import NewQueryView from "./New_Query_View";
import ViewResults from "./View_Results";
import ViewResult from "./View_Result";

const hcpiConfig = {
  testing: false,
  testData: "showResult",
  chartFunc: "hcpiShowAll",
  name: "hcpi",
  type: "feature",
  defaultRoute: "/userhome/dashboard/hcpi",
  menuIcon: { img: features.hcpi.main, alt: "hcpi_icon" },
  nav: [
    {
      name: "landing",
      disabled: false,
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      name: "newQuery",
      disabled: false,
      menuItem: true,
      image: {
        img: features.hcpi.newQuery,
        alt: "hcpi_new_query_icon",
      },
      icon: "far fa-plus-square",
      component: NewQueryView,
      path: "/new_query",
    },
    {
      name: "viewResults",
      disabled: false,
      menuItem: true,
      image: {
        img: features.hcpi.viewResults,
        alt: "hcpi_view_results_icon",
      },
      icon: "fas fa-copy",
      component: ViewResults,
      path: "/view_results",
    },
    {
      name: "viewResult",
      disabled: false,
      menuItem: false,
      component: ViewResult,
      path: "/view_result/:id",
    },
  ],
  hcpi: {
    viewResult: {
      colors: {},
      results: (score) => {
        return score < 7
          ? {
              color: "#a3f28d",
              text: "clear",
            }
          : score >= 7 && score <= 11
          ? {
              color: "#fede87",
              text: "gray",
            }
          : score > 11 && {
              color: "#faaca6",
              text: "chronic",
            };
      },
    },
    TEST_DATA: {
      showResult: {
        currentQuestion: 0,
        currentAnswer: {},
        answers: [
          { q: "1", a: 0, v: 0, calc: true },
          { q: "2", a: 2, v: 2, calc: true },
          { q: "3", a: 2, v: 2, calc: true },
          { q: "4a", a: 2, v: 2, calc: true },
          { q: "4b", a: 3, v: 3, calc: false },
          { q: "5a", a: 1, v: 1, calc: true },
          { q: "5b", a: 4, v: 4, calc: false },
          { q: "6a", a: 0, v: 0, calc: true },
          { q: "6b", a: 4, v: 4, calc: false },
          { q: "7a", a: 4, v: 4, calc: true },
          { q: "7b", a: 4, v: 4, calc: false },
          { q: "8", a: 4, v: 4, calc: true },
          { q: "9", a: 4, v: 4, calc: true },
          { q: "10", a: 4, v: 4, calc: true },
          { q: "11", a: 4, v: 4, calc: true },
        ],
        showResult: true,
      },
    },
  },
  tableView: {
    headers: ["date", "time", "medicine", "totalPoints"],
    mobileHeaders: ["date", "time", "medicine", "totalPoints"],
    tableKeys: [
      { label: "0 - 6", text: "clear", color: "#a3f28d" },
      { label: "7 - 11", text: "gray", color: "#fede87" },
      { label: "11 +", text: "chronic", color: "#faaca6" },
    ],
    cellStyle: {
      totalPoints: (score) => {
        return score < 7
          ? {
              backgroundColor: "#a3f28d",
              maxWidth: 40,
              margin: "auto",
              padding: 4,
              borderRadius: 10,
            }
          : score >= 7 && score <= 11
          ? {
              backgroundColor: "#fede87",
              maxWidth: 40,
              margin: "auto",
              padding: 4,
              borderRadius: 10,
            }
          : score > 11 && {
              backgroundColor: "#faaca6",
              maxWidth: 40,
              margin: "auto",
              padding: 4,
              borderRadius: 10,
            };
      },
    },
  },
};

export default hcpiConfig;
