import React, { Component } from "react"
import Section from "components/layout/Section"
import FormWrapper from "components/layout/Form_Wrapper"
import MedicineEntryForm from "forms/Medicine_Entry_Form"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"

class MedicineEntry extends Component {
  render() {
    return (
      <Section>
        <FormWrapper>
          <MedicineEntryForm valuesToEdit={{}} {...this.props} />
        </FormWrapper>
      </Section>
    )
  }
}

export default RequireSubFeature(MedicineEntry)
