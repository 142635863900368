import React from "react";
import { connect } from "react-redux";

function TableKey(props) {
  switch (props.feature) {
    case "hcpi":
    case "fmpi":
      return <HCPIKey {...props} />;
    default:
    // do nothing
  }
}

const HCPIKey = (props) => {
  return (
    <div>
      <ul>
        {props.tableKeys.map(({ label, text, color }, i) => (
          <li key={i}>
            <div style={{ margin: 0 }} className="columns is-tablet">
              <div
                style={{ backgroundColor: color, padding: 4 }}
                className="column is-2-mobile is-2-tablet is-1-desktop heading has-text-centered"
              >
                {label}
              </div>
              <div style={{ padding: 6 }} className="column">
                {props.messages.painIndex[text][props.locale]}
              </div>
            </div>
          </li>
        ))}
      </ul>
      <br />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: { painIndex: state.intl.messages.components.painIndex.result },
  };
};

export default connect(mapStateToProps)(TableKey);
