import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import PROVIDER_CONFIG from "./provider_config";

class HealthDiary extends Component {
  render() {
    return <Menu {...PROVIDER_CONFIG} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.containers.features.healthDiary,
    healthDiary: state.reducers.healthDiary,
    temperature: state.reducers.temperature,
    providerContact: { _id: state.reducers.pets.selectedPet._user },
  };
};

export default connect(mapStateToProps)(HealthDiary);
