import { features } from "assets"
import routes from "./routes"

const appointmentsConfig = {
  name: "appointments",
  type: "feature",
  defaultRoute: "/userhome/dashboard/appointments",
  menuIcon: {
    img: features.appointments.main,
    alt: "appointments_feature_icon",
  },
  nav: routes,
  tableView: {},
  chartView: {},
}

export default appointmentsConfig
