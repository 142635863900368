export function localizeNumber(number, locale) {
  const newNumber = number.toLocaleString(locale)
  return newNumber
}

export function localizeNumberToFixed3(number, locale) {
  const newNumber = number.toLocaleString(locale, {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3
  })
  return newNumber
}
