import React from "react"
import { connect } from "react-redux"

function UpdateDocument({ handleModalClose, messages, locale, children }) {
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <section className="modal-card-body has-text-dark">
          <div className="has-text-right">
            <button
              className="delete is-right"
              aria-label="close"
              onClick={() => handleModalClose()}
            />
          </div>
          {children}
        </section>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.components.modals,
    locale: state.intl.locale,
  }
}

export default connect(mapStateToProps)(UpdateDocument)
