import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import LoginForm from "forms/Login_Form";
import BoxWithBackground from "components/styled/Box_With_Background";
import SocialLogins from "containers/auth/Social_Logins";
import Divider from "components/elements/Divider";
import Button from "components/elements/Button";
import Message from "components/message";
import Intl from "components/Intl";

function Login({ history }) {
  const [loginFormVisible, toggleLoginForm] = useState(false);

  // Render Helper
  function renderContent() {
    return (
      <BoxWithBackground squareBorder>
        {!loginFormVisible && <Message info message={"authLogin"} />}
        <br />
        {!loginFormVisible && (
          <Button
            className="is-fullwidth"
            onClick={() => toggleLoginForm(!loginFormVisible)}
            style={{ background: "#F5F5F5" }}
            group="auth"
            name="loginEmail"
            withIcon
            iconLeft
          />
        )}
        {loginFormVisible && <LoginForm history={history} />}
        {loginFormVisible && (
          <>
            <br />
            <p className="heading has-text-centered has-text-link has-text-weight-bold">
              <Link to={"/forgot_password"}>
                <Intl
                  message
                  path="components.forms.authLinks.login.forgotPassword"
                />
              </Link>
            </p>
            <p className="heading has-text-centered has-text-link has-text-weight-bold">
              <Link to={"/register"}>
                <Intl
                  message
                  path="components.forms.authLinks.login.notYetRegistered"
                />
              </Link>
            </p>
          </>
        )}
        {!loginFormVisible && (
          <>
            <Divider />
            <SocialLogins login />
          </>
        )}
      </BoxWithBackground>
    );
  }

  return (
    <div className="columns is-mobile is-centered">
      <div className="column is-5-desktop is-12-mobile is-5-tablet">
        {renderContent()}
      </div>
    </div>
  );
}

export default withRouter(Login);
