import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { renderMeOnClient } from "functions";

const ACTIVE_STYLE = {
  borderBottom: "4px solid hsl(217, 71%, 53%)",
};

const BottomNav = ({ nav, defaultRoute, pet, user }) => {
  return (
    <div className="columns is-2 is-variable is-mobile is-centered is-multiline">
      {nav.map((navItem, i) => {
        if (!navItem.menuItem) return null;
        if (navItem.disabled) return null;
        if (navItem.petTypeReq) {
          if (!navItem.petTypes.includes(pet.petType)) return null;
        }
        if (navItem.requiresSubLevel) {
          if (!navItem.subLevels.includes(user.subLevel)) return null;
        }
        if (navItem.clients && !renderMeOnClient(navItem.clients)) return null;
        return (
          <div
            key={i}
            className="column is-narrow has-text-centered"
            style={{ paddingLeft: 4, paddingRight: 4, paddingTop: 14 }}
          >
            <NavLink
              exact={true}
              to={
                navItem.linkItem
                  ? `${navItem.path}`
                  : `${defaultRoute}${navItem.path}`
              }
              style={{ padding: 4 }}
              className="box"
              activeStyle={ACTIVE_STYLE}
            >
              {navItem.image ? (
                <figure className="image is-28x28">
                  <img src={navItem.image.img} alt={navItem.image.alt} />
                </figure>
              ) : (
                <figure className="image is-28x28">
                  <i className={navItem.icon + " fa-lg has-text-dark"} />
                </figure>
              )}
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};

export default BottomNav;

BottomNav.propTypes = {
  nav: PropTypes.array,
  defaultRoute: PropTypes.string,
  routes: PropTypes.object,
};
