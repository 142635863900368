import axios from "axios"
import { ACTION_STARTED, ACTION_SUCCESS, MODALS_HIDE } from "actions/types"
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "actions/functions"

export const TASKS_FETCH = "tasks_fetch"
export const TASKS_ADD = "tasks_add"
export const TASKS_UPDATE = "tasks_update"
export const TASKS_MARK_COMPLETE = "tasks_mark_complete"
export const TASKS_DELETE = "tasks_delete"

const API = (route) => `/api/tasks/${route}`

export const fetchTasks = (_pet, providerView) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: TASKS_FETCH })

    const res = await axios.get(
      API(providerView ? "fetch_for_provider" : "fetch"),
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
        params: { _pet },
      }
    )
    dispatch({
      type: TASKS_FETCH,
      payload: res.data.tasks,
      _pet,
    })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addTask = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerContact,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: TASKS_ADD })
    const res = await axios.post(
      API(providerView ? "add_by_provider" : "add"),
      { _user: providerView ? providerContact._id : undefined, ...values },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({ type: TASKS_ADD, payload: res.data.newTask })
    endAndResetAction(dispatch)
    history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const updateTask = (values, setSubmitting, providerView) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: TASKS_UPDATE })
    const res = await axios.put(
      API(providerView ? "update_by_provider" : "update"),
      values,
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({
      type: TASKS_UPDATE,
      payload: res.data.updatedTask,
    })
    endAndResetActionWithDelay(dispatch, 1500)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const markTaskAsComplete = (values, setSubmitting) => async (
  dispatch
) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: TASKS_MARK_COMPLETE })
    const res = await axios.put(API("mark_complete"), values, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    })
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({
      type: TASKS_MARK_COMPLETE,
      payload: res.data.updatedTask,
    })
    endAndResetActionWithDelay(dispatch, 1500)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const deleteTask = (_id, providerView) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: TASKS_DELETE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      API(providerView ? "delete_by_provider" : "delete"),
      { _id },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({
      type: TASKS_DELETE,
      payload: res.data.deletedTask,
    })
    endAndResetActionWithDelay(dispatch, 1500)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const tasksActions = {
  fetchTasks,
  addTask,
  updateTask,
  markTaskAsComplete,
  deleteTask,
}

export default tasksActions
