import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Message from "components/message";
import SectionTitle from "../components/SectionTitle";
import ProviderContact from "../components/ProviderContact";
import Intl from "components/Intl";
import Pagination from "../components/Pagination";

function ViewContacts(props) {
  const {
    contacts: { contacts, pendingRequests },
    setCurrentContact,
  } = props;

  function handleSetCurrentContact(c) {
    setCurrentContact(c);
  }

  const pendingRequestsCount = pendingRequests.length;

  if (contacts.length === 0 && pendingRequests.length === 0)
    return <Message warning message="noContactsToDisplay" />;

  return (
    <div>
      {pendingRequestsCount > 0 && (
        <SectionTitle
          title={
            <Intl
              message
              path="containers.contacts.providerContacts.titles.pendingRequests"
            />
          }
          logo={{ path: "user", name: "profile" }}
          badge={pendingRequestsCount}
          expand
        >
          <Pagination items={pendingRequests}>
            {pendingRequests.map((c) => (
              <ProviderContact key={c._id} pendingRequest {...c} />
            ))}
          </Pagination>
        </SectionTitle>
      )}
      {contacts.length > 0 && (
        <SectionTitle
          title={
            <Intl
              message
              path="containers.contacts.providerContacts.titles.providerContacts"
            />
          }
          logo={{ path: "provider", name: "providerContacts" }}
        >
          <Pagination items={contacts}>
            {contacts.map((c, i) => (
              <div key={c._id}>
                <ProviderContact
                  {...props}
                  {...c}
                  index={i}
                  handleSetCurrentContact={() => handleSetCurrentContact(c)}
                  updateClientDataForProvider={
                    props.updateClientDataForProvider
                  }
                  deleteContactForProvider={props.deleteContactForProvider}
                />
              </div>
            ))}
          </Pagination>
        </SectionTitle>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    contacts: state.reducers.contacts,
  };
};

export default connect(mapStateToProps, actions)(ViewContacts);
