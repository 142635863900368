import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "actions/award_cabinet"
import Menu from "components/menu"
import config from "./config"

class AwardCabinet extends Component {
  render() {
    return <Menu {...config} {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    messages: state.intl.messages.containers.features.awardCabinet,
    requiresFetch: !state.reducers.awardCabinet.awardsFetched.includes(
      ownProps.pet._id
    )
      ? true
      : false,
    entries: state.reducers.awardCabinet.awards.filter(
      (award) => award._pet === ownProps.pet._id
    ),
  }
}

export default connect(mapStateToProps, actions)(AwardCabinet)
