import React from "react";
import { connect } from "react-redux";
import { getValueAtPath } from "functions";

function Message(props) {
  try {
    let text = getValueAtPath(props.messages, props.path)[props.locale];
    if (props.messageProps) {
      let re = /%([a-z0-9_]+)%/gi;
      let tag, key;

      while ((tag = re.exec(text))) {
        key = tag[1];
        text = text.replace(tag[0], props.messageProps[key] || "");
      }
    }

    if (!text) return null;

    if (Array.isArray(text)) {
      return text.map((t, i) => <p key={i}>{t}</p>);
    }
    return text;
  } catch (error) {
    return props.path;
  }
}

function mapStateToProps(state) {
  return {
    locale: state.intl.locale,
    messages: state.intl.messages,
  };
}

export default connect(mapStateToProps)(Message);
