import axios from "axios";
import {
  TRANSFERS_FETCH_TRANSFERS,
  TRANSFERS_TRANSFER_LITTER_MEMBER,
  TRANSFERS_RETRIEVE_LITTER_MEMBER,
  ACTION_STARTED,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_ERROR,
  ACTION_RESET,
} from "actions/types";
import { catchBlockError } from "./functions";

export const transfersFetch = (_pet) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: TRANSFERS_FETCH_TRANSFERS,
    });
    const res = await axios.get("/api/transfer_pet/fetch_transfers", {
      headers: { authorization: localStorage.getItem("token") },
      params: { _pet },
    });
    dispatch({
      type: TRANSFERS_FETCH_TRANSFERS,
      payload: { array: res.data.petTransfers, _pet },
    });
    dispatch({
      type: ACTION_ENDED,
    });
    dispatch({
      type: ACTION_RESET,
    });
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const transferLitterMember =
  (values, setSubmitting) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: TRANSFERS_TRANSFER_LITTER_MEMBER,
      });
      const res = await axios.post(
        "/api/transfer_pet/initiate_transfer",
        values,
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      );
      dispatch({
        type: TRANSFERS_TRANSFER_LITTER_MEMBER,
        payload: res.data.petTransfer,
      });
      dispatch({
        type: ACTION_ENDED,
      });
      dispatch({
        type: ACTION_RESET,
      });
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const reAttemptTransferLitterMember =
  (values, prevAttemptId, setSubmitting) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: TRANSFERS_TRANSFER_LITTER_MEMBER,
      });
      const res = await axios.post(
        "/api/transfer_pet/reattempt_transfer",
        { values, prevAttemptId },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      );
      dispatch({
        type: TRANSFERS_TRANSFER_LITTER_MEMBER,
        payload: res.data.petTransfer,
      });
      setSubmitting(false);
      dispatch({
        type: ACTION_ENDED,
      });
      dispatch({
        type: ACTION_RESET,
      });
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const fetchPetForTransfer =
  ({ retrievePetCode }, setSubmitting) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: TRANSFERS_RETRIEVE_LITTER_MEMBER,
      });
      const res = await axios.get("/api/transfer_pet/fetch_pet_for_transfer", {
        headers: { authorization: localStorage.getItem("token") },
        params: { retrievePetCode },
      });
      setSubmitting(false);
      if (!res.data.fetchPetForTransfer) {
        dispatch({ type: ACTION_ERROR, payload: "noPetForTransferFound" });
        dispatch({
          type: ACTION_ENDED,
        });
        return setTimeout(() => {
          dispatch({ type: ACTION_RESET });
        }, 3000);
      }
      dispatch({
        type: ACTION_SUCCESS,
        payload: "fetchPetForTransferSuccess",
      });
      dispatch({
        type: TRANSFERS_RETRIEVE_LITTER_MEMBER,
        payload: { pet: res.data.pet, petTransfer: res.data.petTransfer },
      });
      dispatch({
        type: ACTION_ENDED,
      });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 2000);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

const transfersActions = {
  transfersFetch,
  transferLitterMember,
  fetchPetForTransfer,
};

export default transfersActions;
