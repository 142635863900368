import React from "react"
import { features, litterFeatures } from "assets"
import Landing from "components/features/Landing"
import AddNew from "../components/Add_New"
import ViewEntries from "./View_Entries"

const litterParasitesConfig = {
  name: "litterParasites",
  type: "litterFeature",
  defaultRoute: "/userhome/dashboard/litter/parasites",
  menuIcon: { img: litterFeatures.parasites, alt: "litter_parasites_icon" },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: litterFeatures.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "addNew",
      menuItem: true,
      image: {
        img: features.parasites.newEntry,
        alt: "litter_parasites_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: (props) => <AddNew litterParasites {...props} />,
      path: "/add_new",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.parasites.viewEntries,
        alt: "litter_parasites_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
  ],
}

export default litterParasitesConfig
