import React, { Component } from "react";
import { connect } from "react-redux";
import Section from "components/layout/Section";
import ProfileDisplay from "components/styled/Profile_Display";
import { returnDateLocaleFormat } from "functions/moment";
import ProfilePreferences from "./Profile_Preferences";
import LangSelector from "components/lang_selector";
import WeightUnitSelector from "components/weight_unit_selector.js";
import Notification from "components/layout/Notification";
import DashboardPreferences from "./Dashboard_Preferences";

class Profile extends Component {
  renderContent() {
    const { user, numActivePets, locale } = this.props;
    const { labels } = this.props.messages;
    const FIELDS = [
      {
        label: labels.name[locale],
        value: `${user.firstname} ${user.lastname}`,
      },
      { label: labels.email[locale], value: user.email },
      { label: labels.postcode[locale], value: user.postcode },
      {
        label: labels.petCount[locale],
        value: `${numActivePets} (${labels.max[locale]} ${user.petLimit})`,
      },
      {
        label: labels.method[locale],
        value: labels[user.accountType][locale],
      },
      {
        label: labels.dateCreated[locale],
        value: returnDateLocaleFormat(user.dateCreated, locale),
      },
    ];

    return <ProfileDisplay fields={FIELDS} />;
  }

  render() {
    return (
      <Section>
        {this.renderContent()}
        <ProfilePreferences
          user={this.props.user}
          messages={this.props.messages}
          locale={this.props.locale}
        />
        <WeightUnitSelector />
        <DashboardPreferences
          user={this.props.user}
          messages={this.props.messages}
          locale={this.props.locale}
        />
        <Notification>
          <LangSelector />
        </Notification>
      </Section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.reducers.auth.user,
    numActivePets: state.reducers.pets.numActivePets,
    messages: {
      ...state.intl.messages.containers.auth.userAccount.profile,
      emailPreferences:
        state.intl.messages.containers.auth.userAccount.emailPreferences,
      dashboardPreferences:
        state.intl.messages.containers.auth.userAccount.dashboardPreferences,
      labels: state.intl.messages.containers.auth.userAccount.profileDisplay,
    },
  };
};

export default connect(mapStateToProps)(Profile);
