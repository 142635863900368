import React, { useEffect } from "react";
import { connect } from "react-redux";
import inputValidationColor from "./Input_Validation_Color";
import FormValidationMessageV5 from "./FormValidationMessageV5";

function TaxPercentage(props) {
  const {
    field,
    form,
    type,
    icon,
    className,
    viewMode,
    editMode,
    min,
    step,
    userCanEdit,
    readOnly,
    label,
    placeholder,
    group,
  } = props;

  const { price, taxPercentage } = form.values;
  const { setFieldValue } = form;

  useEffect(() => {
    setFieldValue(
      "taxAmount",
      isNaN(price) || isNaN(taxPercentage)
        ? 0
        : Number((price / (100 / taxPercentage)).toFixed(2))
    );
  }, [setFieldValue, price, taxPercentage]);

  return (
    <div className="field">
      <label className="label is-small">
        <label className="label is-small">
          <div className="heading has-text-grey">{label}</div>
        </label>
      </label>
      <div className="control has-icons-left has-icons-right">
        <input
          {...field}
          type={type}
          readOnly={
            viewMode || readOnly || (!userCanEdit && editMode) ? true : false
          }
          className={
            viewMode
              ? className
              : `${className} ${inputValidationColor(
                  field.name,
                  form.touched,
                  form.errors
                )}`
          }
          min={min}
          step={step}
          placeholder={placeholder}
        />
        <span className="icon is-small is-left">
          <i className={icon} />
        </span>
      </div>
      <FormValidationMessageV5
        clientMessage
        group={group}
        name={field.name}
        errors={form.errors}
        touched={form.touched}
      />
    </div>
  );
}

const mapStateToProps = (state, { group, field }) => {
  const { locale, messages } = state.intl;
  return {
    label: messages.components.forms.labels[group][field.name][locale],
  };
};

export default connect(mapStateToProps)(TaxPercentage);
