import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import Section from "components/layout/Section";
import Message from "components/message";
import FetchEntries from "components/fetch";
import TableAndChartView from "./Table_And_Chart_View";

function ViewEntriesViews(props) {
  const { pet, homeCare, tableView, defaultRoute, providerView } = props;
  const { fetchType, lastFetchPetID, fetchDates, entries } = homeCare;

  function handleFetchEntries(value, unit, name) {
    props.fetchHomeCareEntries(value, unit, name, pet, providerView);
  }

  const entriesFiltered = entries.filter((entry) => entry._pet === pet._id);

  return (
    <>
      <FetchEntries
        fetchItems={handleFetchEntries}
        fetchType={fetchType}
        newPet={pet._id === lastFetchPetID}
        fetchButtons={["last24Hrs", "last7Days", "last30Days"]}
      />
      <Section>
        {entriesFiltered.length === 0 ? (
          <Message warning message={"noEntriesToDisplayRetry"} />
        ) : (
          <TableAndChartView
            feature={props.name}
            pet={pet._id}
            id={Date.now()}
            headers={tableView.headers}
            mobileHeaders={tableView.mobileHeaders}
            filterByItems={[]}
            filterKey={""}
            viewEntryTo={`${defaultRoute}/view_entry/`}
            fetchDates={fetchDates}
            fetchType={fetchType}
            data={entriesFiltered}
            messages={{
              categories: props.messages.categories,
              activities: props.messages.activities,
            }}
            locale={props.locale}
          />
        )}
      </Section>
    </>
  );
}

export default connect(null, actions)(ViewEntriesViews);
