import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { INPUT_FIELDS } from "./Register_Fields";
import { FormButtons, FormTitle } from "components/form";

class RegisterProviderForm extends Component {
  renderFields = () => {
    const { locale, links } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <div key={field.name} style={{ paddingTop: 8 }}>
          <Field locale={locale} links={links} {...field} />
          {field.lastInGroup && <br />}
        </div>
      );
    });
  };

  render() {
    const { errors, dirty, isSubmitting } = this.props;
    return (
      <div>
        <Form>
          <FormTitle group={"providerRegister"} />
          {this.renderFields()}
          <br />
          <FormButtons
            register
            isSubmitting={isSubmitting}
            errors={errors}
            dirty={dirty}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.intl.locale,
    links: state.reducers.appData.footerLinks,
  };
};

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}
Yup.addMethod(Yup.string, "equalTo", equalTo);

const EnhancedRegisterProviderForm = withFormik({
  mapPropsToValues({ locale }) {
    return {
      firstname: "",
      lastname: "",
      serviceName: "",
      serviceType: "",
      email: "",
      streetAddress: "",
      postcode: "",
      city: "",
      province: "",
      vatNumber: "",
      password: "",
      password2: "",
      customerReg: false,
      termsCond: false,
      privacyPolicy: false,
      locale,
    };
  },
  validationSchema: Yup.object().shape({
    firstname: Yup.string().required("required"),
    lastname: Yup.string().required("required"),
    serviceName: Yup.string().required("required"),
    serviceType: Yup.string().required("required"),
    email: Yup.string().email("invalid").required("required"),
    streetAddress: Yup.string().required("required"),
    postcode: Yup.string().required("required").max(10, "max-10"),
    city: Yup.string().required("required"),
    province: Yup.string().required("required"),
    vatNumber: Yup.string().required("required"),
    password: Yup.string().min(8, "min-8").required("required"),
    password2: Yup.string()
      .equalTo(Yup.ref("password"), "passwordsMustMatch")
      .required("required"),
    inviteCode: Yup.string(),
    customerReg: Yup.boolean()
      .test("customerReg", "consent-CustomerReg", (value) => value === true)
      .required("consent"),
    termsCond: Yup.boolean()
      .test("termsCond", "consent-TermsCond", (value) => value === true)
      .required("consent"),
    privacyPolicy: Yup.boolean()
      .test("privacyPolicy", "consent-PrivacyPolicy", (value) => value === true)
      .required("consent"),
  }),
  handleSubmit(values, { props, setSubmitting, resetForm }) {
    props.handleFormSubmit(values, setSubmitting, resetForm);
  },
})(RegisterProviderForm);

export default connect(mapStateToProps)(EnhancedRegisterProviderForm);
