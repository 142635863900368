import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { main } from "assets";

function BenefitsInfo({ handleModalClose, messages = {}, locale, buttons = {} }) {
  const bodyText = messages.bodyText?.[locale];
  const subscribeNow = buttons.subscribeNow?.[locale];
  const subscribeNowIcon = buttons.subscribeNow?.icon;

  return (
    <div className="modal is-active" aria-labelledby="benefits-modal-title">
      <div className="modal-background" onClick={handleModalClose} />
      <div className="modal-card">
        <section className="modal-card-body has-text-dark">
          <div className="has-text-right">
            <button
              className="delete is-right"
              aria-label="close"
              onClick={handleModalClose}
            />
          </div>
          <div className="columns is-mobile is-2 is-variable is-paddingless">
            <div className="column"></div>
            <div className="column is-10 has-text-centered">
              <img src={main.benefitsPopup} alt="Benefits Information" />
              {bodyText ? (
                bodyText.map((t, i) => (
                  <div key={i} style={{ paddingBottom: 6 }}>
                    <p style={{ padding: 0 }}>{t}</p>
                  </div>
                ))
              ) : (
                <p>No content available</p>
              )}
              <br />
              <Link
                to="/userhome/user_account/subscription"
                className="button is-link is-fullwidth"
                onClick={handleModalClose}
              >
                {subscribeNowIcon && (
                  <span className="icon">
                    <i className={subscribeNowIcon} />
                  </span>
                )}
                <span>{subscribeNow || "Subscribe Now"}</span>
              </Link>
            </div>
            <div className="column"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  messages: state.intl.messages.components.modals.benefitsMoreInfo,
  buttons: state.intl.messages.components.buttons,
  locale: state.intl.locale,
});

export default connect(mapStateToProps)(BenefitsInfo);
