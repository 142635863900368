import React, { Component } from "react";
import { connect } from "react-redux";
import { billing as actions } from "actions";
import { BILLING_CANCEL_SUBSCRIPTION } from "actions/types";

class CancelSubscription extends Component {
  state = { showConfirmCancel: false };

  handleCancelConfirm = () => {
    this.setState({
      showConfirmCancel: false,
    });
  };

  handleConfirmCancelSub = () => {
    this.handleCancelConfirm();
    this.props.cancelSubscription();
  };

  render() {
    const { buttons, actionCalls, locale, user } = this.props;

    if (!user.subscription) return null;

    if (!user.stripeCustomerId) return null;

    if (!user.stripeSubscriptionId) return null;

    if (
      actionCalls.actionInProcess &&
      actionCalls.action === BILLING_CANCEL_SUBSCRIPTION
    )
      return null;

    if (this.state.showConfirmCancel)
      return (
        <ConfirmCancelSubscription
          handleCancelConfirm={this.handleCancelConfirm}
          handleConfirmCancelSub={this.handleConfirmCancelSub}
          {...this.props}
        />
      );
    return (
      <div
        className={`button is-fullwidth is-danger`}
        onClick={
          this.state.showConfirmCancel
            ? null
            : () => this.setState({ showConfirmCancel: true })
        }
      >
        {buttons.cancelSub[locale]}
      </div>
    );
  }
}

const ConfirmCancelSubscription = (props) => {
  return (
    <div className="buttons is-centered">
      <div className="button" onClick={() => props.handleCancelConfirm()}>
        {props.buttons.cancelSubConfirm.cancel[props.locale]}
      </div>
      <div
        className="button is-danger"
        onClick={() => props.handleConfirmCancelSub()}
      >
        {props.buttons.cancelSubConfirm.confirm[props.locale]}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.reducers.auth.user,
    locale: state.intl.locale,
    buttons: {
      cancelSubConfirm: state.intl.messages.components.buttons.cancelSubConfirm,
      ...state.intl.messages.components.buttons.stripe,
      ...state.intl.messages.components.buttons.common,
    },
    actionCalls: state.reducers.actionCalls,
  };
};

export default connect(mapStateToProps, actions)(CancelSubscription);
