import React, { useState } from "react";
import { connect } from "react-redux";
import { auth as actions } from "actions/index";
import Intl from "components/Intl";
import Image from "components/image";

function LangSelector({
  supportedLanguages,
  locale,
  isAuthed,
  updateLanguagePreference,
  providerView,
}) {
  const [selectedLang, selectLang] = useState(locale);

  if (!supportedLanguages) return null;

  function isSelected(lang) {
    if (lang === selectedLang) {
      return "64x64";
    } else {
      return "48x48";
    }
  }

  function handleLangSelection(lang) {
    selectLang(lang);
    updateLanguagePreference(isAuthed, lang, providerView);
  }

  const style = {
    landing: {
      mainWrapper: {},
      buttonWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    clientSettings: {
      mainWrapper: { textAlign: "center" },
      buttonWrapper: { alignItems: "center" },
    },
  };

  return (
    <div style={style[isAuthed ? "clientSettings" : "landing"].mainWrapper}>
      <LanguageSelectionTitle isAuthed={isAuthed} />
      <div style={style[isAuthed ? "clientSettings" : "landing"].buttonWrapper}>
        <Languages className="is-centered">
          {supportedLanguages.map((lang) => (
            <div
              key={lang}
              style={{ padding: 4 }}
              onClick={() => handleLangSelection(lang)}
            >
              <Image
                imgPath={`components.flags.${lang}`}
                figureSize={`${isSelected(lang)}`}
              />
            </div>
          ))}
        </Languages>
      </div>
    </div>
  );
}

function LanguageSelectionTitle({ isAuthed }) {
  return (
    <div style={{ marginBottom: 10 }}>
      <Intl
        message
        path={`components.languageSelector.titles.${
          isAuthed ? "clientSettingsView" : "landingView"
        }`}
      />
    </div>
  );
}

function Languages({ className, children }) {
  return <div className={`buttons ${className}`}>{children}</div>;
}

function mapStateToProps(state) {
  const { supportedLanguages } = state.reducers.appData;
  return {
    isAuthed: state.reducers.auth.isAuthenticated,
    locale: state.intl.locale,
    supportedLanguages: supportedLanguages || false,
  };
}

export default connect(mapStateToProps, actions)(LangSelector);
