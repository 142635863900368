import { BILLING_FETCH_BILLING_DATA, AUTH_LOG_OUT_USER } from "actions/types"

const INITIAL_STATE = {
  billingData: {},
  billingDataFetched: false,
}

export default function BillingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BILLING_FETCH_BILLING_DATA:
      return {
        ...state,
        billingData: {
          plans: action.payload,
        },
        billingDataFetched: true,
      }
    case AUTH_LOG_OUT_USER:
      return INITIAL_STATE
    default:
      return state
  }
}
