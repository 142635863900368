import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteVac } from "actions/vacs";
import { deleteParasite } from "actions/parasites";
import { deletePet } from "actions/pets";
import { deleteWeightEntry } from "actions/weight";
import { deleteFde } from "actions/food_diary";
import { deleteTde } from "actions/training_diary";
import { deleteHealthDiaryEntry } from "actions/health_diary";
import { deleteAward } from "actions/award_cabinet";
import { deleteStableDiaryEntry } from "actions/stable_diary";
import { deleteHoofCareEntry } from "actions/hoof_care";
import { deleteLitterMember } from "actions/litters";
import { deleteHcpiResult } from "actions/hcpi";
import { deleteTemperatureEntry } from "actions/temperature";
import {
  deleteHeatDiaryCycleEntry,
  deleteHeatDiaryProgEntry,
  deleteHeatDiaryBreedingEntry,
} from "actions/heat_diary";
import { deleteHomeCareEntry } from "containers/features/Home_Care/actions";
import { deleteAppointmentsEntry } from "containers/features/Appointments/actions";
import { deleteContactForProvider } from "containers/contacts/actions";
import { deleteFmpiResult } from "containers/features/Fmpi/actions";
import { deleteTask, TASKS_DELETE } from "containers/features/Tasks/actions";
import {
  deleteMarker,
  deleteMarkerDetail,
  BODY_MARKER_DELETE_MARKER,
  BODY_MARKER_DELETE_MARKER_DETAIL,
} from "containers/features/Body_Marker/actions";
import {
  deleteToothMarkerDetail,
  TOOTH_MARKER_DELETE_MARKER_DETAIL,
} from "containers/features/Tooth_Marker/actions";
import {
  MODALS_HIDE,
  VACS_DELETE,
  PARASITES_DELETE,
  PETS_DELETE,
  WEIGHT_DELETE,
  FOOD_DIARY_DELETE,
  HEALTH_DIARY_DELETE,
  TRAINING_DIARY_DELETE,
  AWARD_CABINET_DELETE,
  STABLE_DIARY_DELETE,
  HEAT_DIARY_DELETE_CYCLE,
  HEAT_DIARY_DELETE_PROG,
  HEAT_DIARY_DELETE_BREEDING,
  HOOF_CARE_DELETE,
  LITTERS_DELETE_MEMBER,
  HCPI_DELETE,
  TEMPERATURE_DELETE,
} from "actions/types";
import { HOME_CARE_DELETE } from "containers/features/Home_Care/actions";
import { APPOINTMENTS_DELETE } from "containers/features/Appointments/actions";
import { STRESS_LEVEL_DELETE } from "containers/features/Stress_Level/actions";
import { DAILY_ACTIVITY_DELETE } from "containers/features/Activity/actions";
import { FMPI_DELETE } from "containers/features/Fmpi/actions";
import { PROVIDER_DELETES_CONTACT } from "containers/contacts/actions";
import BodyText from "./BodyText";
import ConfirmDeleteInput from "./Confirm_Delete_Input";

class ConfirmDelete extends Component {
  state = {
    isMatch: false,
  };

  selectAction(props) {
    switch (props.actionType) {
      case VACS_DELETE:
        return props.deleteVac(
          props.item,
          props.history,
          props.successRoute,
          props.providerView
        );
      case PARASITES_DELETE:
        return props.deleteParasite(
          props.item,
          props.history,
          props.successRoute,
          props.providerView
        );
      case WEIGHT_DELETE:
        return props.deleteWeightEntry(
          props.item,
          props.history,
          props.successRoute,
          props.providerView
        );
      case FOOD_DIARY_DELETE:
        return props.deleteFde(
          props.item,
          props.petType,
          props.history,
          props.successRoute,
          props.providerView
        );
      case TRAINING_DIARY_DELETE:
        return props.deleteTde(
          props.item,
          props.petType,
          props.history,
          props.successRoute,
          props.providerView
        );
      case DAILY_ACTIVITY_DELETE:
        return props.action(props.item, props.callback);
      case HOME_CARE_DELETE:
        return props.deleteHomeCareEntry(
          props.item,
          props.history,
          props.successRoute,
          props.providerView
        );
      case APPOINTMENTS_DELETE:
        return props.deleteAppointmentsEntry(
          props.item,
          props.history,
          props.successRoute,
          props.providerView
        );
      case HEALTH_DIARY_DELETE:
        return props.deleteHealthDiaryEntry(
          props.item,
          props.entryType,
          props.history,
          props.successRoute,
          props.providerView
        );
      case AWARD_CABINET_DELETE:
        return props.deleteAward(
          props.item,
          props.petType,
          props.history,
          props.successRoute
        );
      case STABLE_DIARY_DELETE:
        return props.deleteStableDiaryEntry(
          props.item,
          props.petType,
          props.history,
          props.successRoute
        );
      case HOOF_CARE_DELETE:
        return props.deleteHoofCareEntry(
          props.item,
          props.history,
          props.successRoute
        );
      case HEAT_DIARY_DELETE_CYCLE:
        return props.deleteHeatDiaryCycleEntry(
          props.item,
          props.petType,
          props.history,
          props.successRoute
        );
      case HEAT_DIARY_DELETE_PROG:
        return props.deleteHeatDiaryProgEntry(
          props.item,
          props.petType,
          props.history,
          props.successRoute
        );
      case HEAT_DIARY_DELETE_BREEDING:
        return props.deleteHeatDiaryBreedingEntry(
          props.item,
          props.petType,
          props.history,
          props.successRoute
        );
      case STRESS_LEVEL_DELETE:
        return props.action(props.item, props.callback);
      case TEMPERATURE_DELETE:
        return props.deleteTemperatureEntry(
          props.item,
          props.callback,
          props.providerView
        );
      case HCPI_DELETE:
        return props.deleteHcpiResult(props.item, props.callback);
      case FMPI_DELETE:
        return props.deleteFmpiResult(props.item, props.callback);
      case TASKS_DELETE:
        return props.deleteTask(props.item, props.providerView);
      case PETS_DELETE:
        return props.deletePet(props.item._id, props.history);
      case LITTERS_DELETE_MEMBER:
        return props.deleteLitterMember(props.item._id, props.history);
      case PROVIDER_DELETES_CONTACT:
        return props.deleteContactForProvider(props.item);
      case BODY_MARKER_DELETE_MARKER:
        return props.deleteMarker(props.item, props.providerView);
      case BODY_MARKER_DELETE_MARKER_DETAIL:
        return props.deleteMarkerDetail(props.item, props.providerView);
      case TOOTH_MARKER_DELETE_MARKER_DETAIL:
        return props.deleteToothMarkerDetail(props.item, props.providerView);
      default:
      // Do nothing
    }
  }

  registerMatch = (value) => {
    this.setState({
      isMatch: value,
    });
  };

  render() {
    const {
      dispatch,
      messages,
      buttons,
      type,
      locale,
      noDefault,
      ownBodyText,
    } = this.props;
    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title is-size-6-mobile">
              {`${messages.titles.confirmDelete[locale]} - ${messages.titles[type][locale]}`}
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => dispatch({ type: MODALS_HIDE })}
            />
          </header>
          <section className="modal-card-body has-text-dark">
            <BodyText
              noDefault={noDefault}
              ownBodyText={ownBodyText}
              messages={messages.bodyText}
              type={type}
              locale={locale}
            />
            {(this.props.actionType === PETS_DELETE ||
              this.props.actionType === LITTERS_DELETE_MEMBER) && (
              <ConfirmDeleteInput
                itemToMatch={this.props.item.petName}
                registerMatch={this.registerMatch}
              />
            )}
            {this.props.actionType === PETS_DELETE ||
            this.props.actionType === LITTERS_DELETE_MEMBER ? (
              <p className="content">
                {messages.bodyText.confirmDeleteWithInput[locale]}
              </p>
            ) : (
              <p className="content">
                {messages.bodyText.confirmDelete[locale]}
              </p>
            )}
          </section>
          <footer className="modal-card-foot">
            <button
              className="button"
              onClick={() => dispatch({ type: MODALS_HIDE })}
            >
              {buttons.cancel[locale]}
            </button>
            <button
              className="button is-danger"
              onClick={() => this.selectAction(this.props)}
              disabled={
                (this.props.actionType === PETS_DELETE ||
                  this.props.actionType === LITTERS_DELETE_MEMBER) &&
                !this.state.isMatch
                  ? true
                  : false
              }
            >
              {buttons.delete[locale]}
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        deleteVac,
        deleteParasite,
        deletePet,
        deleteWeightEntry,
        deleteFde,
        deleteTde,
        deleteHealthDiaryEntry,
        deleteAward,
        deleteStableDiaryEntry,
        deleteHoofCareEntry,
        deleteLitterMember,
        deleteHcpiResult,
        deleteHomeCareEntry,
        deleteAppointmentsEntry,
        deleteHeatDiaryCycleEntry,
        deleteHeatDiaryProgEntry,
        deleteHeatDiaryBreedingEntry,
        deleteTemperatureEntry,
        deleteContactForProvider,
        deleteFmpiResult,
        deleteTask,
        deleteMarker,
        deleteMarkerDetail,
        deleteToothMarkerDetail,
        dispatch,
      },
      dispatch
    ),
  };
};

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    buttons: state.intl.messages.components.buttons.common,
    messages: state.intl.messages.components.modals,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDelete);
