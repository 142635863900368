// Main
import * as contentful from "contentful"
import contentfulData from "constants/contentful_data"

import {
  PARTNERS_CONTENTFUL_API_CALL,
  PARTNERS_CONTENTFUL_API_ERROR,
  PARTNERS_CONTENTFUL_FETCH_TILES,
  PARTNERS_CONTENTFUL_FETCH_PAGES,
} from "actions/types"

// Contentful API
const client = contentful.createClient({
  space: contentfulData.partners.SPACE_ID,
  accessToken: contentfulData.partners.ACCESS_TOKEN,
})

// Action Creators
export const fetchTiles = (partnerId) => async (dispatch) => {
  dispatch({ type: PARTNERS_CONTENTFUL_API_ERROR, payload: false })
  dispatch({ type: PARTNERS_CONTENTFUL_API_CALL, payload: true })
  try {
    const tiles = await client.getEntries({
      content_type: "partnerTile",
      "fields.partnerId": partnerId || 0,
    })
    if (tiles.items.length > 0) {
      dispatch({ type: PARTNERS_CONTENTFUL_FETCH_TILES, payload: tiles })
    }
    dispatch({ type: PARTNERS_CONTENTFUL_API_CALL, payload: false })
  } catch (error) {
    dispatch({ type: PARTNERS_CONTENTFUL_API_ERROR, payload: error })
    dispatch({ type: PARTNERS_CONTENTFUL_API_CALL, payload: false })
  }
}

export const fetchPages = (partnerId) => async (dispatch) => {
  dispatch({ type: PARTNERS_CONTENTFUL_API_ERROR, payload: false })
  dispatch({ type: PARTNERS_CONTENTFUL_API_CALL, payload: true })
  try {
    const pages = await client.getEntries({
      content_type: "partnerPage",
      "fields.partnerId": partnerId || 0,
    })
    if (pages.items.length > 0) {
      dispatch({ type: PARTNERS_CONTENTFUL_FETCH_PAGES, payload: pages })
    }
    dispatch({ type: PARTNERS_CONTENTFUL_API_CALL, payload: false })
  } catch (error) {
    dispatch({ type: PARTNERS_CONTENTFUL_API_ERROR, payload: error })
    dispatch({ type: PARTNERS_CONTENTFUL_API_CALL, payload: false })
  }
}

const partnersActions = {
  fetchTiles,
  fetchPages,
}

export default partnersActions
