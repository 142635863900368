import axios from "axios";
import {
  AUTH_FETCH_CURRENT_USER,
  BILLING_CREATE_SUBSCRIPTION,
  BILLING_FETCH_BILLING_DATA,
  BILLING_UPDATE_CARD,
  BILLING_CANCEL_SUBSCRIPTION,
  BILLING_ACTION_REQUIRED,
  ACTION_STARTED,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
  ACTION_ERROR,
} from "./types";

import { catchBlockError } from "./functions";

export const finalizeSubscriptionAfterSCA = (onSuccess) => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_CREATE_SUBSCRIPTION });
  try {
    const res = await axios.get(
      "/api/billing/stripe/finalize_subscription_after_sca",
      { headers: { authorization: localStorage.getItem("token") } }
    );
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_SUCCESS, payload: "createSubscriptionSuccess" });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 1000);
    return onSuccess();
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const fetchBillingData = () => async (dispatch) => {
  // Start the billing data fetch action
  dispatch({ type: ACTION_STARTED, payload: BILLING_FETCH_BILLING_DATA });

  try {
    // Ensure the token exists before making the request
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Authorization token missing. Please log in again.');
    }

    // Fetch the billing subscription data from the backend
    const res = await axios.get('/api/billing/stripe/subscription_data', {
      headers: { authorization: token },
    });

    // Dispatch the fetched billing data
    dispatch({
      type: BILLING_FETCH_BILLING_DATA,
      payload: res.data,
    });

  } catch (error) {
    // Handle errors (network issues, missing tokens, etc.)
    catchBlockError(error, dispatch);

  } finally {
    // Ensure ACTION_ENDED is always dispatched after the process
    dispatch({ type: ACTION_ENDED });

    // Optionally reset the action state after a short delay
    dispatch({ type: ACTION_RESET });
  }
};


export const cancelSubscription = () => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_CANCEL_SUBSCRIPTION });
  try {
    const res = await axios.get("/api/billing/stripe/cancel_subscription", {
      headers: { authorization: localStorage.getItem("token") },
    });
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_SUCCESS, payload: "cancelSubscriptionSuccess" });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 3000);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const setPaymentMethodRequired = () => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_ACTION_REQUIRED });
  try {
    const res = await axios.get(
      "/api/billing/stripe/set_payment_method_required",
      { headers: { authorization: localStorage.getItem("token") } }
    );

    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 1000);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const retrieveSetupIntent = () => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_ACTION_REQUIRED });
  try {
    const res = await axios.get("/api/billing/stripe/retrieve_setup_intent", {
      headers: { authorization: localStorage.getItem("token") },
    });

    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    dispatch({ type: ACTION_RESET });
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const createSetupIntent = () => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_UPDATE_CARD });
  try {
    const res = await axios.get("/api/billing/stripe/create_setup_intent", {
      headers: { authorization: localStorage.getItem("token") },
    });

    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 1000);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const cancelSetupIntent = () => async (dispatch) => {
  dispatch({ type: ACTION_STARTED, payload: BILLING_UPDATE_CARD });
  try {
    const res = await axios.get("/api/billing/stripe/cancel_setup_intent", {
      headers: { authorization: localStorage.getItem("token") },
    });
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
    dispatch({ type: ACTION_ENDED });
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 1000);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const finalizePaymentMethod =
  (intent, onSuccess) => async (dispatch) => {
    dispatch({ type: ACTION_STARTED, payload: BILLING_UPDATE_CARD });
    try {
      const res = await axios.post(
        "/api/billing/stripe/finalize_payment_method",
        { intent },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      );
      dispatch({ type: ACTION_SUCCESS, payload: "updateCardSuccess" });
      dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
      dispatch({ type: ACTION_ENDED });
      onSuccess();
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 1000);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

  /*
export const createCustomerAndSubscription =
  (paymentMethod) => async (dispatch) => {
    dispatch({ type: ACTION_STARTED, payload: BILLING_CREATE_SUBSCRIPTION });
    try {
      const res = await axios.post(
        "/api/billing/stripe/first_time_subscription",
        { paymentMethod },
        { headers: { authorization: localStorage.getItem("token") } }
      );

      if (!res.data.SCArequired) {
        dispatch({
          type: ACTION_SUCCESS,
          payload: "createSubscriptionSuccess",
        });
      }

      dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: res.data.user });
      dispatch({ type: ACTION_ENDED });
      setTimeout(() => {
        dispatch({ type: ACTION_RESET });
      }, 1000);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };
*/

export const createCustomerAndSubscription = (paymentMethod) => async (dispatch) => {
  // Start the action
  dispatch({ type: ACTION_STARTED, payload: BILLING_CREATE_SUBSCRIPTION });

  try {
    // Ensure the token exists before making the request
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Authorization token missing. Please log in again.');
    }

    // Sending payment method to backend to create a customer and subscription
    const res = await axios.post(
      '/api/billing/stripe/first_time_subscription',
      { paymentMethod },
      { headers: { authorization: token } }
    );

    const { SCArequired, user } = res.data;

    // Handle the response
    if (!SCArequired) {
      // Success case: SCA not required
      dispatch({ type: ACTION_SUCCESS, payload: 'createSubscriptionSuccess' });

      // Update current user details
      dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: user });
    } else {
      // SCA required: handle this scenario (e.g., Stripe.js, modal, etc.)
      dispatch({
        type: ACTION_ERROR,
        payload: 'SCA required: Please authenticate your payment method.',
      });

      // You can trigger some modal or other UI here to ask the user to authenticate
      // e.g., showSCAModal();
    }

  } catch (error) {
    // Handle errors
    catchBlockError(error, dispatch);

  } finally {
    // Ensure ACTION_ENDED is always dispatched
    dispatch({ type: ACTION_ENDED });

    // Reset the action state after a short delay
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 1000);
  }
};


export const createSubscriptionExistingCustomer = (paymentMethod) => async (dispatch) => {
  // Start the subscription action
  dispatch({ type: ACTION_STARTED, payload: BILLING_CREATE_SUBSCRIPTION });

  try {
    // Ensure the token exists before making the request
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('Authorization token missing. Please log in again.');
    }

    // Sending payment method to backend for an existing customer subscription
    const res = await axios.post(
      '/api/billing/stripe/create_subscription_existing_customer',
      { paymentMethod },
      { headers: { authorization: token } }
    );

    const { SCArequired, user } = res.data;

    // If no SCA required, mark subscription as successful
    if (!SCArequired) {
      dispatch({ type: ACTION_SUCCESS, payload: 'createSubscriptionSuccess' });
    } else {
      // You can handle SCA here if necessary (e.g., Stripe.js or modal prompt)
      dispatch({
        type: ACTION_ERROR,
        payload: 'SCA required: Please authenticate your payment method.',
      });

      // You can trigger additional UI behavior, such as showing an authentication modal
      // e.g., showSCAModal();
    }

    // Update current user details
    dispatch({ type: AUTH_FETCH_CURRENT_USER, payload: user });

  } catch (error) {
    // Handle any errors that occur during the process
    catchBlockError(error, dispatch);

  } finally {
    // Ensure ACTION_ENDED is dispatched in all cases (success or error)
    dispatch({ type: ACTION_ENDED });

    // Reset the state after a short delay
    setTimeout(() => {
      dispatch({ type: ACTION_RESET });
    }, 1000);
  }
};


const billingActions = {
  finalizeSubscriptionAfterSCA,
  fetchBillingData,
  cancelSubscription,
  setPaymentMethodRequired,
  retrieveSetupIntent,
  createSetupIntent,
  finalizePaymentMethod,
  cancelSetupIntent,
  // New Actions
  createCustomerAndSubscription,
  createSubscriptionExistingCustomer,
};

export default billingActions;
