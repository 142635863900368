import {
  HCPI_FETCH_QUESTIONS,
  HCPI_FETCH_RESULTS,
  HCPI_FETCH_TYPE,
  HCPI_SUBMIT_RESULT,
  HCPI_ACTIVITY_CHART_DATA,
  HCPI_DELETE,
  HCPI_UPDATE,
  AUTH_LOG_OUT_USER,
} from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";
import _ from "lodash";

const INITIAL_STATE = {
  template: {},
  templateFetched: false,
  results: [],
  fetchType: "",
  fetchDates: "",
  lastFetchPetID: "",
  activityChartData: [],
};

export default function HcpiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HCPI_FETCH_QUESTIONS:
      return {
        ...state,
        template: action.payload,
        templateFetched: true,
      };
    case HCPI_FETCH_TYPE:
      return {
        ...state,
        fetchDates: action.payload.dates,
        fetchType: action.payload.type,
      };
    case HCPI_FETCH_RESULTS:
      return {
        ...state,
        results: _.sortBy(action.payload, "date").reverse(),
        lastFetchPetID: action.pet._id,
      };
    case HCPI_ACTIVITY_CHART_DATA:
      return {
        ...state,
        activityChartData: action.payload,
      };
    case HCPI_SUBMIT_RESULT:
      return {
        ...state,
        results: _.sortBy([action.payload, ...state.results], "date").reverse(),
        fetchDates: "",
      };
    case HCPI_UPDATE:
      return {
        ...state,
        results: _.sortBy(
          [
            ...state.results.filter(
              (result) => result._id !== action.payload._id
            ),
            action.payload,
          ],
          "date"
        ).reverse(),
      };
    case HCPI_DELETE:
      return {
        ...state,
        results: state.results.filter(
          (result) => result._id !== action.payload
        ),
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
