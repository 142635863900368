import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import assets from "assets";
import { Fragment } from "react";
import Intl from "components/Intl";
import {
  MODALS_CONFIRM_DELETE,
  MODALS_HIDE,
  MODALS_SHOW,
  MODALS_UPDATE_DOCUMENT,
} from "actions/types";
import UpdateClientDataForm from "../forms/Update_Client_Data_Form";
import { PROVIDER_DELETES_CONTACT } from "../actions";
import AcknowledgeRequestButton from "./AcknowledgeRequestButton";

function ProviderContact({
  index,
  pendingRequest,
  _user: {
    userInfo: { firstname, lastname, email },
  },
  _id,
  providerClientData: { clientRef, clientUrl },
  requests,
  handleSetCurrentContact,
  dispatch,
  updateClientDataForProvider,
  deleteContactForProvider,
  providerAcknowledgesAppointmentRequest,
  providerAcknowledgesContactRequest,
  petView,
}) {
  function handleUpdateClientData(values, setSubmitting) {
    dispatch({ type: MODALS_HIDE });
    const data = {
      _id: values._id,
      clientRef: values.clientRef,
      clientUrl: values.clientUrl,
    };
    updateClientDataForProvider(data, setSubmitting);
  }

  function handleEditClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_UPDATE_DOCUMENT,
      modalProps: {
        type: "updateClientData",
        handleModalClose: () => dispatch({ type: MODALS_HIDE }),
        children: (
          <UpdateClientDataForm
            _id={_id}
            clientRef={clientRef}
            clientUrl={clientUrl}
            cancel={() => dispatch({ type: MODALS_HIDE })}
            handleUpdateClientData={handleUpdateClientData}
          />
        ),
      },
    });
  }

  function handleDeleteClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "providerContact",
        noDefault: true,
        actionType: PROVIDER_DELETES_CONTACT,
        item: _id,
      },
    });
  }

  function handleAcknowledgeAppointmentRequest() {
    providerAcknowledgesAppointmentRequest(_id);
  }

  function handleAcknowledgeContactRequest() {
    providerAcknowledgesContactRequest(_id);
  }

  return (
    <div
      className="card"
      style={{ backgroundColor: index % 2 === 1 ? "#f2f2f2" : "" }}
    >
      <div className="card-content" style={{ padding: 12 }}>
        <div className="columns is-paddingless">
          <div className="column is-3-desktop">
            <label className="label has-text-grey is-size-6">
              {firstname} {lastname}{" "}
            </label>
            <p className="is-size-7 has-text-dark">
              <i className="far fa-envelope" /> {email}
            </p>
          </div>
          {!pendingRequest && !petView && (
            <Fragment>
              <div className="column">
                <label className="label has-text-grey is-size-6">
                  <Intl
                    message
                    path="components.forms.labels.updateClientData.clientRef"
                  />
                </label>
                <p className="is-size-7 has-text-dark">
                  {clientRef ? (
                    <span>
                      <i className="icon fas fa-hashtag" /> {clientRef}
                    </span>
                  ) : (
                    "-"
                  )}
                </p>
              </div>
              <div className="column">
                <label className="label has-text-grey is-size-6">
                  <Intl
                    message
                    path="components.forms.labels.updateClientData.clientUrl"
                  />
                </label>
                <p className="is-size-7 has-text-dark">
                  {clientUrl ? (
                    <span>
                      <LinkItem link={clientUrl}>
                        <i className="icon fas fa-link" />
                        {clientUrl}
                      </LinkItem>
                    </span>
                  ) : (
                    "-"
                  )}
                </p>
              </div>
              <div className="column has-text-centered">
                <label className="label has-text-grey is-size-6">
                  <Intl
                    message
                    path="components.contact.labels.appointmentRequest"
                  />
                </label>
                <p className="is-size-7 has-text-dark">
                  {requests && requests._appointmentRequest ? (
                    <AcknowledgeRequestButton
                      onClick={handleAcknowledgeAppointmentRequest}
                    />
                  ) : (
                    "-"
                  )}
                </p>
              </div>
              <div className="column has-text-centered">
                <label className="label has-text-grey is-size-6">
                  <Intl
                    message
                    path="components.contact.labels.contactRequest"
                  />
                </label>
                <p className="is-size-7 has-text-dark">
                  {requests && requests._contactRequest ? (
                    <AcknowledgeRequestButton
                      onClick={handleAcknowledgeContactRequest}
                    />
                  ) : (
                    "-"
                  )}
                </p>
              </div>
              <div className="column is-2-desktop">
                <div className="buttons is-right">
                  <div style={{ height: 32, paddingRight: 8 }}>
                    <button
                      className="button is-small is-rounded"
                      style={{ padding: 2 }}
                      onClick={handleEditClick}
                    >
                      <figure className={"image is-24x24"}>
                        <img src={assets.main.edit} alt="edit_icon" />
                      </figure>
                    </button>
                    <button
                      className="button is-small is-rounded"
                      style={{ padding: 2 }}
                      onClick={handleDeleteClick}
                    >
                      <figure className={"image is-24x24"}>
                        <img
                          src={assets.professionals.deleteContact}
                          alt="edit_icon"
                        />
                      </figure>
                    </button>
                  </div>
                  <div
                    style={{
                      borderLeft: "2px solid #bebebe",
                      height: 32,
                      paddingLeft: 8,
                    }}
                  >
                    <Link
                      to="/providerhome/contacts/contact"
                      className="button is-small is-rounded"
                      style={{ padding: 2 }}
                      onClick={() => handleSetCurrentContact()}
                    >
                      <figure className={"image is-24x24"}>
                        <img
                          src={assets.professionals.contactsPets}
                          alt="pets_icon"
                        />
                      </figure>
                    </Link>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

const LinkItem = (props) => (
  <a
    style={{
      textDecoration: "none",
    }}
    rel="noopener noreferrer"
    href={props.link}
    target="_blank"
  >
    {props.children}
  </a>
);

export default connect(null, null)(ProviderContact);
