import React from "react";
import { connect } from "react-redux";
import assets from "assets";
import Intl from "components/Intl";
import ServiceButtons from "./ServiceButtons";
import { returnServiceType } from "functions";

function Contact({
  _id,
  _provider,
  permissions: { providerCanEdit },
  requests,
  onAllowAccess,
  messages,
  locale,
}) {
  const { providerInfo, userCanRequestContact, userCanRequestAppointment } =
    _provider;
  const { _appointmentRequest, _contactRequest } = requests;

  return (
    <div className="card" style={{ padding: 8 }}>
      <div className="card-content is-paddingless">
        <div className="media">
          <div className="media-left">
            <figure className="image is-32x32">
              <img src={assets.provider.providerNoLogo} alt="Placeholder" />
            </figure>
          </div>
          <div className="media-content" style={{ padding: 12 }}>
            <p className="title is-5 has-text-dark">
              {providerInfo.serviceName}
            </p>
            <p className="subtitle is-6 has-text-dark">
              {returnServiceType(
                messages.serviceTypes,
                providerInfo.serviceType,
                locale
              )}
            </p>
            <ServiceButtons
              _provider={_provider._id}
              userCanRequestContact={userCanRequestContact}
              userCanRequestAppointment={userCanRequestAppointment}
              requestAppointmentSuccess={
                _appointmentRequest &&
                _appointmentRequest.lifeCycleState.status === "sent"
              }
              requestContactSuccess={
                _contactRequest &&
                _contactRequest.lifeCycleState.status === "sent"
              }
            />
            <div className="columns is-mobile is-vcentered">
              <div
                className="column is-variable is-2 is-1-mobile is-1-desktop is-2-tablet"
                style={{ padding: 12 }}
              >
                <div className="field">
                  <input
                    id={_id}
                    type="checkbox"
                    name="allowProviderAccess"
                    className="switch is-rounded"
                    checked={providerCanEdit}
                    onChange={onAllowAccess}
                  />
                  <label htmlFor={_id} />
                </div>
              </div>
              <div className="column" style={{ padding: 20 }}>
                <Intl
                  message
                  path={"components.buttons.auth.allowProviderAccess"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      serviceTypes:
        state.intl.messages.components.forms.selectOptions.serviceType,
    },
  };
};

export default connect(mapStateToProps)(Contact);
