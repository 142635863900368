import React from "react";
import Table from "components/table_2";
import PieChart from "components/chart/Pie";
import {
  chartDataForActivityCategories,
  chartDataForActivityActivities,
} from "functions/chart_data";
import ChartTitleBox from "components/chart/Chart_Title_Box";
import { returnTimeInHrsAndMins } from "functions";

function TableAndChartDailyActivity(props) {
  const tableData = props.data.filter((item) => item.type !== "trainingDiary");
  const totalActivityTimeInSeconds = props.data.reduce(
    (a, b) => a + (b.totalTimeInSeconds || 0),
    0
  );

  return (
    <>
      <div className="columns">
        <div className="column has-text-centered">
          <ChartTitleBox path="components.chart.titles.categories" />
          <PieChart
            data={chartDataForActivityCategories(
              props.data,
              props.messages.categories,
              props.locale
            )}
            options={{
              title: {
                display: true,
                text: `${
                  props.messages.chartLabels.totalTime[props.locale]
                }: ${returnTimeInHrsAndMins(totalActivityTimeInSeconds)}`,
              },
            }}
          />
        </div>
        <div className="column has-text-centered">
          <ChartTitleBox path="components.chart.titles.activities" />
          <PieChart
            data={chartDataForActivityActivities(
              props.data,
              {
                ...props.messages.activities,
                ...props.messages.trainingTypes,
              },
              props.locale
            )}
            options={{
              title: {
                display: true,
                text: `${
                  props.messages.chartLabels.totalTime[props.locale]
                }: ${returnTimeInHrsAndMins(totalActivityTimeInSeconds)}`,
              },
            }}
          />
        </div>
      </div>
      {tableData.length > 0 && (
        <div
          className="box"
          style={{ padding: 4, paddingTop: 8, paddingBottom: 12 }}
        >
          <Table
            tableData={{
              ...props,
              data: tableData,
            }}
            {...props}
            itemsPerPage={5}
          />
        </div>
      )}
    </>
  );
}

export default TableAndChartDailyActivity;
