import React from "react";

export default function DogLowerIncisors(props) {
  const { confirmSelection, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 263.7 113.78"
      width="263.7"
      height="113.78"
      {...rest}
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".cls-6{fill:#f9f7e1;stroke:#333;stroke-miterlimit:10;stroke-width:1.5px;cursor:pointer;}.cls-7{font-size:12px;fill:#4d4d4d;font-family:SegoeUI-Semibold, Segoe UI;font-weight:600;}",
          }}
        />
      </defs>
      <g id="incisors" style={{ pointerEvents: "bounding-box" }}>
        <g id="dog_lower_incisors">
          <g id="right">
            <path
              id="_403"
              data-name={403}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M35,75.23l-.18.15C25.27,83.51,9.35,75.08,4.33,65-1,54.1,4.28,41.63,7.23,34.7,14.91,16.7,32.65-.06,41.16,4.56c8,4.36,5.38,26.39,3.51,41.88C43.53,55.86,42.24,68.79,35,75.23Z"
            />
            <path
              id="_402"
              data-name={402}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M81.86,85.46c-8.57,7-25.13,3.18-31.41-7.52-5-8.57-1.86-18.78.74-27.12,4.62-14.88,16.72-35.33,26.2-33.3,10.5,2.25,12.83,31,11.35,47.75C88,73.67,87.23,81.08,81.86,85.46Z"
            />
            <path
              id="_401"
              data-name={401}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M122.72,90.41c-6.21,4.93-17.21,5.12-23.6.27-7.6-5.76-6-16.61-4.66-25.2C97.15,47.7,108.53,26.78,116.83,28c9.12,1.36,10.49,28.89,11,38.61C128.71,85,124.45,89,122.72,90.41Z"
            />
          </g>
          <g id="left" style={{ pointerEvents: "bounding-box" }}>
            <path
              id="_303"
              data-name={303}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M227.87,75.23l.18.15c9.5,8.13,25.42-.3,30.44-10.42,5.37-10.86.05-23.33-2.9-30.26-7.68-18-25.42-34.76-33.93-30.14-8,4.36-5.38,26.39-3.51,41.88C219.29,55.86,220.58,68.79,227.87,75.23Z"
            />
            <path
              id="_302"
              data-name={302}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M181,85.46c8.57,7,25.13,3.18,31.41-7.52,5-8.57,1.86-18.78-.74-27.12-4.62-14.88-16.72-35.33-26.2-33.3-10.5,2.25-12.83,31-11.35,47.75C174.83,73.67,175.59,81.08,181,85.46Z"
            />
            <path
              id="_301"
              data-name={301}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M140.1,90.41c6.21,4.93,17.21,5.12,23.6.27,7.6-5.76,6-16.61,4.66-25.2C165.67,47.7,154.29,26.78,146,28c-9.12,1.36-10.49,28.89-11,38.61C134.11,85,138.37,89,140.1,90.41Z"
            />
          </g>
          <g id="numbering">
            <text className="cls-7" transform="translate(10.68 91.3)">
              403
            </text>
            <text className="cls-7" transform="translate(56.27 103.34)">
              402
            </text>
            <text className="cls-7" transform="translate(102.8 107.21)">
              401
            </text>
            <text className="cls-7" transform="translate(146.41 107.21)">
              301
            </text>
            <text className="cls-7" transform="translate(191.74 102.88)">
              302
            </text>
            <text className="cls-7" transform="translate(236.16 91.3)">
              303
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}
