import React from "react";
import { connect } from "react-redux";
import MessageText from "./MessageText";
import { returnNewObjectAsArrayByValidKey } from "functions";

function MessageRoot(props) {
  const infoBlocks = props.info
    ? returnNewObjectAsArrayByValidKey(
        props.messages.infoBlocks,
        props.message,
        props.locale
      )
    : undefined;

  const warnings = props.warning
    ? returnNewObjectAsArrayByValidKey(
        props.messages.warnings,
        props.message,
        props.locale
      )
    : undefined;

  switch (true) {
    case props.default:
      return <DefaultMessage {...props} />;
    case props.landing:
      return <InfoMessage {...props} />;
    case props.info:
      return <Message info {...props} infoBlocks={infoBlocks} />;
    case props.warning:
      return <Message warning {...props} warnings={warnings} />;
    case props.error:
      return <Message error {...props} />;
    case props.success:
      return <Message success {...props} />;
    default:
      return null;
  }
}

const DefaultMessage = (props) => (
  <div className={`message ${props.color} `}>
    {props.withHeader && <div className="message-header">{props.header}</div>}
    <div className="message-body content">{props.children}</div>
  </div>
);

const InfoMessage = (props) => (
  <div className="message is-info">
    <div className="message-body content">{props.children}</div>
  </div>
);

const Message = (props) => (
  <div
    className={`message ${
      props.info
        ? "is-info"
        : props.warning
        ? "is-warning"
        : props.error
        ? "is-danger"
        : props.success
        ? "is-success"
        : ""
    }`}
  >
    <div className="message-body content">
      {props.info ? (
        <MessageText text={props.infoBlocks} readMore={props.readMore} />
      ) : props.warning ? (
        <MessageText text={props.warnings} />
      ) : props.error ? (
        <MessageText
          text={props.messages.errors[props.message][props.locale]}
        />
      ) : (
        <MessageText
          text={
            props.success &&
            props.messages.successes[props.message][props.locale]
          }
        />
      )}
      {props.children}
    </div>
  </div>
);

const mapStatToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      errors: state.intl.messages.components.messages.errors,
      successes: state.intl.messages.components.messages.successes,
      warnings: state.intl.messages.components.messages.warnings,
      infoBlocks: state.intl.messages.components.messages.infoBlocks,
    },
  };
};

export default connect(mapStatToProps)(MessageRoot);
