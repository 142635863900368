import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchHealthDiaryEntries } from "actions/health_diary";
import Section from "components/layout/Section";
import Message from "components/message";
import moment from "moment";
import FetchEntries from "components/fetch";
import Table from "components/table_2";
import config from "./config";

class ViewEntries extends Component {
  handleFetchEntries = (value, unit, name) => {
    const { pet } = this.props;
    this.props.fetchHealthDiaryEntries(value, unit, name, pet);
  };

  renderSectionContent() {
    const { healthDiary, messages, pet, defaultRoute } = this.props;
    const { entries } = healthDiary;
    const entriesFiltered = entries.filter((entry) => entry._pet === pet._id);
    const filterByItems = [
      ...new Set(entriesFiltered.map((entry) => entry.type)),
    ];

    // Create data set for table
    const tableData = {
      feature: "healthDiary",
      pet: pet._id,
      id: Date.now(),
      data: entriesFiltered,
      headers: config.tableView.headers,
      mobileHeaders: config.tableView.mobileHeaders,
      messages: {
        ...messages.entryTypes,
        ...messages.medicineAmountUnit,
        ...messages.filterBy,
      },
      filterByItems,
      filterKey: "type",
      viewEntryTo: `${defaultRoute}/view_entry/`,
      fetchDates: healthDiary.fetchDates,
      fetchType: healthDiary.fetchType,
      customFilters: {
        customFilter1: {
          name: "onGoing",
          filterBy: "",
          filterByItems: ["onGoing", "notOnGoing"],
          renderCondition: (filter) => filter === "medicine",
          applyCustomFilter: (filterBy, data) =>
            filterBy === ""
              ? data
              : data.filter((item) =>
                  filterBy === "onGoing"
                    ? item.onGoing
                    : filterBy === "notOnGoing" && !item.onGoing
                ),
        },
        customFilter2: {
          name: "valid",
          filterBy: "",
          filterByItems: ["valid", "expired"],
          renderCondition: (filter) => filter === "onGoing",
          applyCustomFilter: (filterBy, data) =>
            filterBy === ""
              ? data
              : data.filter((item) =>
                  filterBy === "valid"
                    ? moment(item.dateEnd).isAfter(moment())
                    : filterBy === "expired" &&
                      moment(item.dateEnd).isBefore(moment())
                ),
        },
      },
    };

    if (entriesFiltered.length === 0) {
      return <Message warning message={"noEntriesToDisplayRetry"} />;
    }

    return <Table tableData={tableData} itemsPerPage={5} />;
  }

  render() {
    const { fetchType, lastFetchPetID } = this.props.healthDiary;
    const { pet } = this.props;
    return (
      <>
        <FetchEntries
          fetchItems={this.handleFetchEntries}
          fetchType={fetchType}
          newPet={pet._id === lastFetchPetID}
          fetchButtons={["last7Days", "last30Days", "last3Months", "last1Year"]}
        />
        <Section>{this.renderSectionContent()}</Section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchHealthDiaryEntries,
      dispatch,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(ViewEntries);
