import React from "react";
import { connect } from "react-redux";
import inputValidationColor from "./Input_Validation_Color";
import FormValidationMessageV5 from "./FormValidationMessageV5";
import ButtonIconOnly from "components/elements/ButtonIconOnly";

const UrlInputLinkV5 = (props) => {
  const {
    field,
    form,
    type,
    icon,
    className,
    viewMode,
    editMode,
    userCanEdit,
    min,
    max,
    step,
    readOnly,
    label,
    group,
  } = props;

  const style2 = {
    border: `1px solid lightgrey`,
  };

  return (
    <div className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">{label}</div>
      </label>
      <div className="field has-addons is-fullwidth">
        <div className="control has-icons-left has-icons-right is-expanded">
          <input {...field}
          type={type}
          readOnly={
            viewMode || readOnly || (!userCanEdit && editMode) ? true : false
          }
          className={
            viewMode
              ? className
              : `${className} ${inputValidationColor(
                    field.name,
                    form.touched,
                    form.errors
                  )}`
          }
          min={min}
          max={max}
          step={step}
          style={style2}>
          </input>
          
          <span className="icon is-left">
            <i className={icon} />
          </span>
         
        </div>
        {viewMode && (
          <div className="control">
            <ButtonIconOnly
              href={field.value}
              group="usefulLinks"
              name="gotoLink"
              className="is-link is-rounded"
              />
         </div>
        )}
       
      </div>
      <FormValidationMessageV5
        clientMessage
        group={group}
        name={field.name}
        errors={form.errors}
        touched={form.touched}
      />
    </div>
  );
};

const mapStateToProps = (state, { group, field }) => {
  const { locale, messages } = state.intl;
  return {
    label: messages.components.forms.labels[group][field.name][locale],
  };
};

export default connect(mapStateToProps)(UrlInputLinkV5);
