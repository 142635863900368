import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { pets as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import RequireSubAddPet from "components/hoc/Require_Sub_Add_Pet";
import { INPUT_FIELDS } from "./fields/add_pet";
import { FormTitle } from "components/form";

class AddPetForm extends Component {
  renderFields(type) {
    const { values, editMode, valuesToEdit } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (values.petType !== "other" && field.name === "petTypeOther")
        return null;
      if (!editMode && field.profileOnly) return null;
      if (
        field.petTypeSpecific &&
        !field.petTypes.includes(valuesToEdit.petType)
      )
        return null;
      if (
        values.living === "yes" &&
        (field.name === "dateOfDeath" || field.name === "reasonOfDeath")
      )
        return null;
      return <Field key={i} values={values} editMode={editMode} {...field} />;
    });
  }

  render() {
    const { errors, dirty, isSubmitting, editMode, locale, transferPet } =
      this.props;
    const { buttons } = this.props.messages;

    return (
      <Form>
        <FormTitle group={"addPet"} />
        {this.renderFields()}
        <br />
        <div className="buttons is-centered">
          {editMode ? (
            <button className="button" onClick={this.props.cancelEdit}>
              {buttons.cancel[locale]}
            </button>
          ) : (
            <button className="button" onClick={this.props.cancel}>
              {buttons.cancel[locale]}
            </button>
          )}
          <button
            disabled={isSubmitting}
            className={`button ${
              !_.isEmpty(errors)
                ? "is-danger"
                : _.isEmpty(errors) && dirty
                ? "is-success"
                : "is-info"
            } ${isSubmitting && "is-loading"}`}
            type="submit"
          >
            {editMode
              ? buttons.update[locale]
              : transferPet
              ? buttons.completeTransfer[locale]
              : buttons.submit[locale]}
          </button>
        </div>
        <br />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      buttons: {
        ...state.intl.messages.components.buttons.common,
        ...state.intl.messages.components.buttons.petTransfer,
      },
    },
    actionCalls: state.reducers.actionCalls,
  };
};

function parseBoolean(bool) {
  if (bool) {
    return "yes";
  } else {
    return "no";
  }
}

AddPetForm = withFormik({
  mapPropsToValues({ valuesToEdit }) {
    return {
      petName: valuesToEdit.petName || "",
      petGivenName: valuesToEdit.petGivenName || "",
      petType: valuesToEdit.petType || "",
      petTypeOther: valuesToEdit.petTypeOther || "",
      petSex: valuesToEdit.petSex || "",
      petBreed: valuesToEdit.petBreed || "",
      hasBeenSterillized: valuesToEdit.hasBeenSterillized || "",
      petWeight: valuesToEdit.petWeight || 0,
      petDob: moment(valuesToEdit.petDob).format("YYYY-MM-DD") || "",
      petInsuranceNumber: valuesToEdit.petInsuranceNumber || "",
      petRegNumber: valuesToEdit.petRegNumber || "",
      petChipNumber: valuesToEdit.petChipNumber || "",
      petPassportNumber: valuesToEdit.petPassportNumber || "",
      researchProject: valuesToEdit.researchProject || "",
      living: parseBoolean(valuesToEdit.living) || "yes",
      dateOfDeath: valuesToEdit.dateOfDeath
        ? moment(valuesToEdit.dateOfDeath).format("YYYY-MM-DD")
        : "",
      reasonOfDeath: valuesToEdit.reasonOfDeath || "",
      _id: valuesToEdit._id || "",
    };
  },
  validationSchema: Yup.object().shape({
    petName: Yup.string().required("required"),
    petGivenName: Yup.string(),
    petType: Yup.string().required("required"),
    petTypeOther: Yup.string().when("petType", {
      is: "other",
      then: Yup.string().required("required"),
      otherwise: Yup.string(),
    }),
    petSex: Yup.string().required("required"),
    petBreed: Yup.string(),
    hasBeenSterillized: Yup.string().required("required"),
    petDob: Yup.date().required("required"),
    petInsuranceNumber: Yup.string(),
    petRegNumber: Yup.string(),
    petChipNumber: Yup.string(),
    petPassportNumber: Yup.string(),
    researchProject: Yup.string(),
    living: Yup.string(),
    dateOfDeath: Yup.date(),
    reasonOfDeath: Yup.string().max(50, "max-50"),
  }),
  handleSubmit(values, { props, setSubmitting, resetForm }) {
    if (props.transferPet) {
      return props.updatePetAndTransfer(
        values,
        setSubmitting,
        props.petTransferId,
        props.history
      );
    }

    if (props.editMode) {
      return props.updatePet(values, setSubmitting, props.cancelEdit);
    }
    props.addPet(values, setSubmitting, resetForm, props.history);
  },
})(AddPetForm);

export default RequireSubAddPet(connect(mapStateToProps, actions)(AddPetForm));
