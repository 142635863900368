import React, { Component } from "react";
import { connect } from "react-redux";
import { billing as actions } from "actions";
import _ from "lodash";
import Loading from "components/spinners/Loading";
import Section from "components/layout/Section";
import SubscriptionPlans from "./Subscription/Subscription_Plans";
import SectionTitle from "components/layout/Section_Title";
import SubscriptionDetails from "./Subscription/Subscription_Details";
import CancelSubscription from "./Subscription/Cancel_Subscription";

import Stripe from "containers/auth/Stripe";

class Subscription extends Component {
  async UNSAFE_componentWillMount() {
    if (!this.props.billing.billingDataFetched) {
      return await this.props.fetchBillingData();
    }
  }

  render() {
    const { messages } = this.props;

    return (
      <>
        {_.isEmpty(this.props.billing.billingData) ? (
          <Loading />
        ) : (
          <>
            <Section>
              <Stripe />
            </Section>
            <Section hr>
              <SectionTitle
                title={messages.titles.subscriptionDetails.title}
                subtitle={messages.titles.subscriptionDetails.subtitle}
              />
            </Section>
            <Section>
              <SubscriptionDetails />
            </Section>
            <Section hr>
              <SectionTitle
                title={messages.titles.subscriptionPlans.title}
                subtitle={messages.titles.subscriptionPlans.subtitle}
              />
            </Section>
            <Section>
              <SubscriptionPlans {...this.props} />
            </Section>
            <Section>
              <br />
              <CancelSubscription />
            </Section>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    billing: state.reducers.billing,
    user: state.reducers.auth.user,
    pets: state.reducers.pets.profiles,
    numActivePets: state.reducers.pets.numActivePets,
    locale: state.intl.locale,
    actionCalls: state.reducers.actionCalls,
    messages: state.intl.messages.containers.subscription,
  };
};

export default connect(mapStateToProps, actions)(Subscription);
