import { AUTH_LOG_OUT_USER } from "actions/types"
import {
  BLOG_FETCH_ALL_TAGS,
  BLOG_FETCH_POSTS_LATEST,
  BLOG_FETCH_POSTS_BY_TAG,
  BLOG_FETCH_POSTS_BY_TAG_AND_PAGINATE,
  BLOG_SELECTED_POST,
  BLOG_PAGINATION_NAVIGATE,
} from "./actions"
import moment from "moment"
import _ from "lodash"
import { paginator } from "functions"

const INITIAL_STATE = {
  tags: [],
  entries: [],
  lastFetch: moment(),
  entriesFetchedByTag: [],
  tagsFetchedBy: [],
}

export default function BlogReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case BLOG_FETCH_POSTS_LATEST:
      return {
        ...state,
        entries: action.payload,
        lastFetch: moment(),
      }
    case BLOG_FETCH_ALL_TAGS:
      return {
        ...state,
        tags: _.sortBy(action.payload, "count").reverse(),
      }
    case BLOG_FETCH_POSTS_BY_TAG:
      return {
        ...state,
        entriesFetchedByTag: action.payload.entries,
        pagination: false,
        tagsFetchedBy: [action.payload.tag],
        fetchedPostsTags: action.payload.fetchedPostsTags,
      }
    case BLOG_FETCH_POSTS_BY_TAG_AND_PAGINATE:
      return {
        ...state,
        entriesFetchedByTag: action.payload.entries,
        pagination: paginator(action.payload.entries, 1, 10),
        tagsFetchedBy: [action.payload.tag],
        fetchedPostsTags: action.payload.fetchedPostsTags,
      }
    case BLOG_PAGINATION_NAVIGATE:
      return {
        ...state,
        pagination: paginator(state.entriesFetchedByTag, action.payload, 10),
      }
    case BLOG_SELECTED_POST:
      return {
        ...state,
        selectedPost: action.payload,
      }
    case AUTH_LOG_OUT_USER:
      return INITIAL_STATE
    default:
      return state
  }
}
