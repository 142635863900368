import { features } from "assets"
import { path } from "constants/feature_routes_provider_view"
import NewEntryView from "./views/New_Entry_View"
import ViewEntriesView from "./views/View_Entries_View"
import LandingRedirect from "components/features/Landing_Redirect"

const tasksProviderConfig = {
  name: "tasks",
  type: "feature",
  redirect: path("tasks/view_entries"),
  defaultRoute: path("tasks"),
  menuIcon: {
    img: features.tasks.main,
    alt: "tasks_feature_icon",
  },
  providerView: true,
  providerEdit: true,
  nav: [
    {
      name: "landingRedirect",
      disabled: false,
      menuItem: false,
      exact: true,
      component: LandingRedirect,
      path: "/",
    },
    {
      name: "viewEntriesView",
      disabled: false,
      menuItem: true,
      exact: true,
      image: {
        img: features.tasks.viewEntries,
        alt: "view_entries_icon",
      },
      component: ViewEntriesView,
      path: "/view_entries",
    },
    {
      name: "newEntryView",
      disabled: false,
      menuItem: true,
      exact: true,
      image: {
        img: features.tasks.newEntry,
        alt: "new_entry_icon",
      },
      component: NewEntryView,
      path: "/new_entry",
    },
  ],
  tableView: {},
  chartView: {},
}

export default tasksProviderConfig
