import React from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import PROVIDER_CONFIG from "./provider_config";
import * as actions from "./actions";

function BodyMarker(props) {
  return <Menu {...PROVIDER_CONFIG} {...props} />;
}

const mapStateToProps = (state) => {
  return {
    provider: state.reducers.auth.provider,
    providerContact: {
      _id: state.reducers.contacts.selectedContact._user._id,
      ...state.reducers.contacts.selectedContact._user.userInfo,
    },
    messages: {
      ...state.intl.messages.containers.features.bodyMarker,
    },
    bodyMarker: state.reducers.bodyMarker,
  };
};

export default connect(mapStateToProps, actions)(BodyMarker);
