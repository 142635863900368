import awardCabinet from "./award_cabinet";
import foodDiary from "./food_diary";
import hcpi from "./hcpi";
import healthDiary from "./health_diary";
import hoofCare from "./hoof_care";
import parasites from "./parasites";
import reporting from "./reporting";
import stableDiary from "./stable_diary";
import tipsAndGuides from "./tips_and_guides";
import vaccinations from "./vaccinations";
import weight from "./weight";
import litters from "./litters";
import animalHospital from "./animal_hospital";
import usefulLinks from "./useful_links";
import infoMenuIcon from "assets/main/info.svg";
import heatDiary from "./heat_diary";
import activity from "containers/features/Activity/assets";
import homeCare from "./home_care";
import placeholder from "assets/main/placeholder.svg";
import appointments from "containers/features/Appointments/assets";
import stressLevel from "containers/features/Stress_Level/assets";
import trainingDiary from "./training_diary";
import firstvet from "./firstvet";
import fmpi from "containers/features/Fmpi/assets";
import tasks from "containers/features/Tasks/assets";
import bodyMarker from "containers/features/Body_Marker/assets";
import toothMarker from "containers/features/Tooth_Marker/assets";

const features = {
  awardCabinet,
  foodDiary,
  hcpi,
  healthDiary,
  hoofCare,
  parasites,
  reporting,
  stableDiary,
  tipsAndGuides,
  vaccinations,
  weight,
  litters,
  animalHospital,
  usefulLinks,
  infoMenuIcon,
  heatDiary,
  activity,
  homeCare,
  placeholder,
  appointments,
  stressLevel,
  trainingDiary,
  firstvet,
  fmpi,
  tasks,
  bodyMarker,
  toothMarker,
};

export default features;
