import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import _ from "lodash"
import Section from "components/layout/Section"
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons"
import TrainingDiaryEntryForm from "forms/Training_Diary_Entry_Form"
import {
  MODALS_SHOW,
  MODALS_CONFIRM_DELETE,
  TRAINING_DIARY_DELETE,
} from "actions/types"

const INITIAL_STATE = {
  viewMode: true,
  valuesToEdit: {},
}

class ViewEntry extends Component {
  state = INITIAL_STATE

  UNSAFE_componentWillMount() {
    const { tdes, history, defaultRoute } = this.props
    const { id } = this.props.match.params
    const tde = tdes.filter((tde) => tde._id === id)[0]
    if (!tde) return history.push(`${defaultRoute}/view_entries`)
    this.setState({
      valuesToEdit: tde,
    })
  }

  handleEditClick = () => {
    this.setState({
      viewMode: !this.state.viewMode,
    })
  }

  handleDeleteClick = () => {
    const successRoute = `${this.props.defaultRoute}/view_entries`
    this.props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "trainingDiary",
        actionType: TRAINING_DIARY_DELETE,
        history: this.props.history,
        successRoute: successRoute,
        item: this.state.valuesToEdit._id,
        petType: this.props.pet.petType,
        providerView: this.props.providerView,
      },
    })
  }

  renderSectionContent() {
    if (_.isEmpty(this.state.valuesToEdit)) return <div />
    return (
      <>
        <BackEditDeleteButtons
          handleEditClick={this.handleEditClick}
          handleDeleteClick={this.handleDeleteClick}
          viewMode={this.state.viewMode}
          providerView={this.props.providerView}
          providerEdit={this.props.providerEdit}
        />
        <div className="columns is-mobile is-centered">
          <div className="column is-5-desktop is-full-mobile is-6-tablet">
            <TrainingDiaryEntryForm
              editMode
              {...this.state}
              {...this.props}
              cancel={this.handleEditClick}
            />
          </div>
        </div>
      </>
    )
  }

  render() {
    return <Section>{this.renderSectionContent()}</Section>
  }
}

const mapStateToProps = (state) => {
  return {
    tdes: state.reducers.trainingDiary.entries,
  }
}

export default withRouter(connect(mapStateToProps)(ViewEntry))
