import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  MODALS_SHOW,
  MODALS_HIDE,
  MODALS_BCS_DESCRIPTION,
} from "actions/types";
import assets from "assets";
import moment from "moment";
import { setCurrentContact } from "../../actions";
import { selectPet } from "actions/pets";
import SectionTitle from "containers/contacts/components/SectionTitle";
import Pagination from "containers/contacts/components/Pagination";
import Intl from "components/Intl";
import BCS from "containers/features/Weight/constants";

function WeightDataView(props) {
  function handleButtonClick(userId, pet, path) {
    const contact = props.contacts.filter((c) => c._user !== userId)[0];
    props.setCurrentContact(contact);
    props.selectPet(pet);
    props.history.push(path);
  }

  function convertToKg(kg, g) {
    const a = kg * 1000;
    const b = a + g;
    const c = b / 1000;
    return c;
  }

  function returnBCSColor(score, petType) {
    if (score === 0) return "";
    const color = BCS[petType].scale.find((obj) => obj.score === score).color;
    return color.slice(3);
  }

  function returnBCS(score, petType) {
    const bcs = BCS[petType].scale.find((obj) => obj.score === score);
    return bcs;
  }

  const buttonStyle = { padding: 6, height: 36 };

  function returnChangeIcon(weight1, weight2) {
    const p1 = parseInt(weight1);
    const p2 = parseInt(weight2);

    if (isNaN(p2)) return <div className="has-text-weight-bold">{"-"}</div>;

    if (p1 === p2) return <i className="fas fa-minus" />;
    if (p1 > p2) return <i className="fas fa-arrow-up" />;
    if (p1 < p2) return <i className="fas fa-arrow-down" />;
  }

  function handleBCSScoreClick(score, petType) {
    if (score === 0) return;
    props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_BCS_DESCRIPTION,
      modalProps: {
        ...returnBCS(score, petType),
        petType,
        locale: props.locale,
        handleModalClose: () => {
          props.dispatch({ type: MODALS_HIDE });
        },
      },
    });
  }

  return (
    <div>
      <SectionTitle
        title={
          <Intl
            message
            path="containers.contacts.providerContacts.tiles.nutritionAndWeightTracker"
          />
        }
        logo={{ path: "provider", name: "providerDashboard" }}
      >
        <Pagination items={props.weightData}>
          {props.weightData.map((obj, i) => (
            <div key={obj._id}>
              <div
                className="card"
                style={{ backgroundColor: i % 2 === 1 ? "#f2f2f2" : "" }}
              >
                <div className="card-content" style={{ padding: 12 }}>
                  {/* Headers and Cells START */}
                  <div className="columns is-paddingless">
                    <div className="column">
                      <label className="label has-text-grey is-size-6">
                        {obj.userFullName}
                      </label>
                      <p className="is-size-6 has-text-dark">
                        <i className="far fa-envelope" /> {obj.userEmail}
                      </p>
                    </div>
                    <div className="column has-text-centered">
                      <label className="label has-text-grey is-size-6">
                        {props.messages.petName[props.locale]}
                      </label>
                      <p className="is-size-6 has-text-dark has-text-weight-bold">
                        {obj._pet.petName}
                      </p>
                    </div>
                    <div className="column has-text-centered">
                      <label className="label has-text-grey is-size-6">
                        {props.messages.change[props.locale]}
                      </label>
                      <div className="is-size-6">
                        {returnChangeIcon(
                          convertToKg(obj.kg1, obj.g1),
                          convertToKg(obj.kg2, obj.g2)
                        )}
                      </div>
                    </div>
                    <div className="column has-text-centered">
                      <label className="label has-text-grey is-size-6">
                        {moment(obj.created1).format("DD.MM.YYYY")}
                      </label>
                      <p className="is-size-6 has-text-weight-bold">
                        {obj.weightUnit === "kg"
                          ? `${convertToKg(obj.kg1, obj.g1)} kg`
                          : `${obj.lbs1} lbs`}
                      </p>
                    </div>
                    <div className="column  has-text-centered">
                      <label className="label has-text-grey is-size-6">
                        {obj.created2 === null
                          ? props.messages.noData[props.locale]
                          : moment(obj.created2).format("DD.MM.YYYY")}
                      </label>
                      <div className="is-size-6 has-text-weight-bold">
                        {obj.created2 === null ? (
                          "-"
                        ) : obj.weightUnit === "kg" ? (
                          <div>{convertToKg(obj.kg2, obj.g2)} kg</div>
                        ) : (
                          <div>{obj.lbs2} lbs</div>
                        )}
                      </div>
                    </div>
                    <div className="column has-text-centered">
                      <label className="label has-text-grey is-size-6">
                        {moment(obj.created1).format("DD.MM.YYYY")} (
                        {props.messages.bcs[props.locale]})
                      </label>
                      <div className="is-size-6 has-text-weight-bold">
                        {obj.petType === "dog" || obj.petType === "cat" ? (
                          <div
                            onClick={() =>
                              handleBCSScoreClick(
                                obj.bodyConditionScore,
                                obj.petType
                              )
                            }
                            className={`is-size-6 has-text-weight-bold`}
                          >
                            <BCSScoreWrapper
                              score={obj.bodyConditionScore}
                              color={returnBCSColor(
                                obj.bodyConditionScore,
                                obj.petType
                              )}
                            />
                          </div>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                    {/* Headers and Cells END */}
                    {/* Buttons Start */}
                    <div className="column has-text-centered is-2">
                      <label className="label has-text-grey is-size-6">
                        {props.messages.links[props.locale]}
                      </label>
                      <div className="buttons is-centered">
                        <div
                          className="button is-small is-rounded"
                          style={buttonStyle}
                          onClick={() =>
                            handleButtonClick(
                              obj._user,
                              obj._pet,
                              "/providerhome/contacts/contact/contacts_pet_dashboard/weight"
                            )
                          }
                        >
                          <figure className={"image is-24x24"}>
                            <img
                              src={assets.features.weight.main}
                              alt="weight_icon"
                            />
                          </figure>
                        </div>
                        <div
                          className="button is-small is-rounded"
                          style={buttonStyle}
                          onClick={() =>
                            handleButtonClick(
                              obj._user,
                              obj._pet,
                              "/providerhome/contacts/contact/contacts_pet_dashboard/food_diary"
                            )
                          }
                        >
                          <figure className={"image is-24x24"}>
                            <img
                              src={assets.features.foodDiary.main}
                              alt="food_diary_icon"
                            />
                          </figure>
                        </div>
                        <div
                          className="button is-small is-rounded"
                          style={buttonStyle}
                          onClick={() =>
                            handleButtonClick(
                              obj._user,
                              obj._pet,
                              "/providerhome/contacts/contact/contacts_pet_dashboard"
                            )
                          }
                        >
                          <figure className={"image is-24x24"}>
                            <img
                              src={assets.professionals.contactsPets}
                              alt="pets_dashboard_icon"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Buttons END */}
                </div>
              </div>
            </div>
          ))}
        </Pagination>
      </SectionTitle>
    </div>
  );
}

const BCSScoreWrapper = ({ score, color }) => {
  return (
    <div
      className={`button is-${color}`}
      style={{
        width: 30,
        height: 30,
      }}
    >
      {score}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setCurrentContact,
      selectPet,
      dispatch,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(WeightDataView);
