import main from "./user_account.svg"
import deleteProfile from "./user_account_delete.svg"
import password from "./user_account_password.svg"
import profile from "./user_account_profile.svg"
import subscription from "./user_account_subscription.svg"
import redeemCode from "./user_account_redeem_code.svg"
import passwordReset from "./password_reset.svg"
import passwordUpdate from "./password_update.svg"
import infoMenuIcon from "assets/main/info.svg"

const user = {
  main,
  deleteProfile,
  password,
  profile,
  subscription,
  redeemCode,
  passwordReset,
  passwordUpdate,
  infoMenuIcon,
}

export default user
