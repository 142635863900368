import React from "react";

export default function CatLowerIncisors(props) {
  const { confirmSelection, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 263.7 78.7"
      width="263.7"
      height="78.7"
      {...rest}
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".cls-6{fill:#f9f7e1;stroke:#333;stroke-miterlimit:10;stroke-width:1.5px;cursor:pointer;}.cls-7{font-size:12px;fill:#4d4d4d;font-family:SegoeUI-Semibold, Segoe UI;font-weight:600;}",
          }}
        />
      </defs>
      <g id="incisors" style={{ pointerEvents: "bounding-box" }}>
        <g id="cat_lower_incisors">
          <g id="right">
            <path
              id="_403"
              data-name={403}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M31,4.82c4.6,3,5.63,8.87,6.66,19.85,1.49,16.09,2.2,24.18,0,26.54-6.54,7-24.79,4.57-31.54-5C-4.12,31.88,13.29,3.29,25.14,3A10.77,10.77,0,0,1,31,4.82Z"
            />
            <path
              id="_402"
              data-name={402}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M82.58,28.24c1.82,8.34,2.92,19.19-4.26,25.43C71.85,59.29,61.34,59,54,55.29c-6.13-3.12-8.94-9.14-8.14-15.94C47,29.72,54.1,6,66.72,6,74.58,5.94,80.7,19.58,82.58,28.24Z"
            />
            <path
              id="_401"
              data-name={401}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M126.8,30.93c2.08,8.28,3.54,19.08-3.44,25.55-6.29,5.82-16.8,5.89-24.25,2.41a15.25,15.25,0,0,1-5.79-4.66c-7.86-10.45,1.38-45,16.9-45C119,9.17,125,23.58,126.8,30.93Z"
            />
          </g>
          <g id="left">
            <path
              id="_303"
              data-name={303}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M232.41,4.78c-4.61,3-5.64,8.87-6.66,19.86-1.5,16.09-2.2,24.18,0,26.54,6.54,7,24.78,4.57,31.53-5,10.2-14.38-7.21-43-19-43.28A10.87,10.87,0,0,0,232.41,4.78Z"
            />
            <path
              id="_302"
              data-name={302}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M180.78,28.21C179,36.55,177.86,47.4,185,53.64c6.48,5.62,17,5.34,24.31,1.62,6.14-3.12,9-9.14,8.15-15.95C216.37,29.69,209.27,6,196.65,5.94,188.79,5.91,182.66,19.55,180.78,28.21Z"
            />
            <path
              id="_301"
              data-name={301}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M136.56,30.9C134.48,39.17,133,50,140,56.45c6.28,5.82,16.8,5.89,24.24,2.4A15.21,15.21,0,0,0,170,54.2c7.86-10.45-1.38-45-16.9-45.05C144.37,9.14,138.41,23.54,136.56,30.9Z"
            />
          </g>
          <g id="numbering">
            <text className="cls-7" transform="translate(12.75 68.6)">
              403
            </text>
            <text className="cls-7" transform="translate(56.34 72.64)">
              402
            </text>
            <text className="cls-7" transform="translate(102.87 74.51)">
              401
            </text>
            <text className="cls-7" transform="translate(147.48 74.51)">
              301
            </text>
            <text className="cls-7" transform="translate(190.81 72.18)">
              302
            </text>
            <text className="cls-7" transform="translate(235.23 68.6)">
              303
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}
