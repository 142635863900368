import React from "react";
import { pets } from "assets";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import Level from "components/layout/Level";
import LevelItem from "components/layout/Level_Item";

function PetNameDashboard(props) {
  if (
    props.pathname === "/userhome/dashboard" ||
    props.pathname === "/providerhome/contacts/contact/contacts_pet_dashboard"
  ) {
    const mobileStyles = isMobile
      ? { paddingRight: 8, paddingLeft: 8, paddingTop: 8, marginTop: 24 }
      : {};

    return (
      <div style={{ paddingBottom: 18, ...mobileStyles }}>
        <div className="box" style={{ padding: 8 }}>
          <Level className="is-mobile">
            <LevelItem></LevelItem>
            <LevelItem>
              <label className="label">{props.selectedPet.petName}</label>
              <figure
                className="image is-14x14"
                style={{ float: "right", marginLeft: 4, marginBottom: 4 }}
              >
                <img src={pets.heart} alt={"heart_icon"} />
              </figure>
            </LevelItem>
            <LevelItem></LevelItem>
          </Level>
        </div>
      </div>
    );
  }

  return <br />;
}

const mapStateToProps = (state) => {
  return {
    selectedPet: state.reducers.pets.selectedPet,
  };
};

export default connect(mapStateToProps)(PetNameDashboard);
