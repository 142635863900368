import React from "react"
import { Link } from "react-router-dom"
import TileButtonImage from "./Tile_Button_Image"
import TileButtonLabel from "./Tile_Button_Label"

/* 
  Main Props
  {
    view={String}: name of the view
    tileButtons={Array}: Array of tile button objects
  }
  
  Button Props
  {
    name: String,
    isLink: Boolean|Default:false, // Will it use the Link component or not
    props: Object: {
      to: String, // If Link
      onClick: Func // Additional or primary function
    },
    imgObj: Object: {
      img: Object, // Import of image,
      alt: String // Alt name for image
    },
    useIcon: Boolean|Default:false, // Will overide img object by using icon props instead
    iconObj: Object: {
      icon: String, // FA icon name
      size: String // Additional prop for sizing
    }
  }

  Update messages/components/tile_buttons with view name and each button
*/

function TileButton({ isLink, props, icon, imgObj, iconObj, useIcon, name }) {
  if (isLink) {
    return (
      <Link {...props}>
        <TileButtonImage imgObj={imgObj} iconObj={iconObj} useIcon={useIcon} />
        <TileButtonLabel label={name} />
      </Link>
    )
  } else {
    return (
      <div {...props} style={{ cursor: "pointer" }}>
        <TileButtonImage imgObj={imgObj} iconObj={iconObj} useIcon={useIcon} />
        <TileButtonLabel label={name} />
      </div>
    )
  }
}

export default TileButton
