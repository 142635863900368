import React from 'react';
import ImageMarker from "react-image-marker";
import CustomMarker from "./CustomMarker";
import BodyMarkerDetail from "./BodyMarkerDetail";
import styles from "./bodyMarkerImage.module.css";

function BodyMarkerImage(props) {
  return (
    <div>
      <div className={styles.wrapper}>
        <figure className="image">
          <ImageMarker
            src={props.img}
            markers={props.markers}
            onAddMarker={(marker) =>
              props.setMarker({ ...marker, details: [] })
            }
            markerComponent={(marker) => <CustomMarker {...marker} />}
          />
        </figure>
      </div>
      <br />
      {props.markers.map((m, i) => (
        <BodyMarkerDetail
          key={i}
          itemNumber={i}
          {...m}
          addMarkerDetail={() => props.addMarkerDetail(m._id)}
          deleteMarker={props.deleteMarker}
          viewDetail={props.viewDetail}
        />
      ))}
      <br />
    </div>
  );
}

export default BodyMarkerImage;
