import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "containers/auth/Pet_Services_Portal/actions";
import ProviderInfoForm from "./ProviderInfoForm";

function ProviderInfo({
  provider,
  labels,
  selectOptions,
  locale,
  updateProviderInfo,
  history,
}) {
  const [fieldInEditState, setFieldInEditState] = useState("");

  function handleSetFieldInEditState(field) {
    setFieldInEditState(field);
  }

  function handleCancelEdit() {
    setFieldInEditState("");
  }

  return (
    <div className="notification" style={{ padding: 6 }}>
      <ProviderInfoForm
        providerInfo={provider.providerInfo}
        labels={labels}
        selectOptions={selectOptions}
        locale={locale}
        updateProviderInfo={updateProviderInfo}
        history={history}
        setFieldInEditState={handleSetFieldInEditState}
        fieldInEditState={fieldInEditState}
        cancelEdit={handleCancelEdit}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    provider: state.reducers.auth.provider,
    labels: state.intl.messages.components.forms.labels.providerRegister,
    selectOptions:
      state.intl.messages.components.forms.selectOptions.serviceType,
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps, actions)(ProviderInfo);
