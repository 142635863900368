import React from "react";
import styled from "styled-components";
import { AWARD_TYPE_SETTINGS } from "../constants";

const StyledButton = styled.button`
  background: ${(props) => props.background} !important;
  padding: 0.75em;
  width: 50px;
`;

const ColorSettings = (props) => {
  return AWARD_TYPE_SETTINGS[props.type].sections.map((section, i) => {
    if (!props.awardData.bling[section.name] && section.toggleVis) {
      return null;
    }
    return (
      <div key={i} className="field">
        <label className="label is-small">
          {props.messages.awardPartLabels[section.name][props.locale]}
        </label>
        <div className="control">
          <StyledButton
            background={props.awardData.colors[section.name]}
            id={section.name}
            name={section.name}
            className="button is-small is-rounded"
            onClick={() => props.showModal(section)}
          />
        </div>
      </div>
    );
  });
};

export default ColorSettings;
