import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class LitterFoodDiary extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.foodDiary,
      ...state.intl.messages.containers.litterFeatures.litterFoodDiary,
      foodCategories:
        state.intl.messages.components.forms.selectOptions.foodCategory,
    },
  };
};

export default connect(mapStateToProps)(LitterFoodDiary);
