import React from "react"
import { features, litterFeatures } from "assets"
import Landing from "components/features/Landing"
import AddNew from "../components/Add_New"
import NewEntryView from "./New_Entry_View"
import AddForLitterSuccess from "../components/Add_For_Litter_Success"

const litterHomeCareConfig = {
  name: "litterHomeCare",
  type: "litterFeature",
  defaultRoute: "/userhome/dashboard/litter/home_care",
  menuIcon: {
    img: features.homeCare.main,
    alt: "litter_home_care_icon",
  },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: litterFeatures.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "newEntrySelectionView",
      menuItem: true,
      image: {
        img: features.homeCare.newEntry,
        alt: "litter_home_care_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: (props) => <AddNew litterHomeCare {...props} />,
      path: "/new_entry_selection",
    },
    {
      disabled: false,
      name: "newEntryView",
      menuItem: false,
      component: (props) => <NewEntryView litterHomeCare {...props} />,
      path: "/new_entry",
    },
    {
      disabled: false,
      name: "addForLitterSuccess",
      menuItem: false,
      component: (props) => <AddForLitterSuccess {...props} />,
      path: "/add_for_litter_success",
    },
  ],
}

export default litterHomeCareConfig
