import { combineReducers } from "redux";
import appData from "./appDataReducer";
import actionCalls from "./actionCallsReducer";
import billing from "./billingReducer";
import auth from "./authReducer";
import pets from "./petsReducer";
import modals from "./modalsReducer";
import vacs from "./vacsReducer";
import parasites from "./parasitesReducer";
import hcpi from "./hcpiReducer";
import foodDiary from "./foodDiaryReducer";
import weight from "./weightReducer";
import healthDiary from "./healthDiaryReducer";
import temperature from "./temperatureReducer";
import reporting from "./reportingReducer";
import trainingDiary from "./trainingDiaryReducer";
import activity from "containers/features/Activity/reducer";
import cloudinary from "./cloudinaryReducer";
import awardCabinet from "./awardCabinetReducer";
import table from "./tableReducer";
import stableDiary from "./stableDiaryReducer";
import hoofCare from "./hoofCareReducer";
import theme from "./themeReducer";
import sysMessages from "./sysMessageReducer";
import usefulLinks from "./usefulLinkReducer";
import litterUsefulLinks from "./litterUsefulLinksReducer";
import heatDiary from "./heatDiaryReducer";
import litters from "./littersReducer";
import weightReview from "./weightReviewReducer";
import litterMembersSelected from "./litterMembersSelectedReducer";
import stripe from "./stripeReducer";
import litterExpenses from "./litterExpenses";
import transferPet from "./transferPetReducer";
import featureResources from "./featureResourcesReducer";
import partners from "./partnersReducer";
import homeCare from "containers/features/Home_Care/reducer";
import appointments from "containers/features/Appointments/reducer";
import blog from "containers/main/Blog/reducer";
import stressLevel from "containers/features/Stress_Level/reducer";
import advertisements from "containers/main/Advertisements/reducer";
import benefits from "containers/main/Benefits/reducer";
import contacts from "containers/contacts/reducer";
import fmpi from "containers/features/Fmpi/reducer";
import tasks from "containers/features/Tasks/reducer";
import providerAppointments from "containers/contacts/Provider_Appointments/reducer";
import providerDashboard from "containers/contacts/Provider_Dashboard/reducer";
import bodyMarker from "containers/features/Body_Marker/reducer";
import toothMarker from "containers/features/Tooth_Marker/reducer";

const reducers = combineReducers({
  appData,
  actionCalls,
  billing,
  auth,
  pets,
  modals,
  vacs,
  parasites,
  hcpi,
  fmpi,
  foodDiary,
  weight,
  healthDiary,
  temperature,
  reporting,
  trainingDiary,
  activity,
  cloudinary,
  awardCabinet,
  table,
  stableDiary,
  hoofCare,
  theme,
  sysMessages,
  usefulLinks,
  litterUsefulLinks,
  heatDiary,
  litters,
  weightReview,
  litterMembersSelected,
  stripe,
  litterExpenses,
  transferPet,
  featureResources,
  partners,
  homeCare,
  appointments,
  blog,
  stressLevel,
  advertisements,
  benefits,
  contacts,
  tasks,
  providerAppointments,
  providerDashboard,
  bodyMarker,
  toothMarker,
});

export default reducers;
