import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchTemperatureEntries } from "actions/temperature";
import Section from "components/layout/Section";
import Message from "components/message";
import FetchEntries from "components/fetch";
import Table from "components/table_2";
import config from "./config";

class ViewEntries extends Component {
  handleFetchEntries = (value, unit, name) => {
    const { pet, providerView } = this.props;
    this.props.fetchTemperatureEntries(value, unit, name, pet, providerView);
  };

  renderTableSection() {
    const { temperature, pet, defaultRoute } = this.props;
    const { entries } = temperature;
    const entriesFiltered = entries.filter((entry) => entry._pet === pet._id);

    // Create data set for table
    const tableData = {
      feature: "temperature",
      pet: pet._id,
      id: Date.now(),
      headers: config.tableView.temperature.headers,
      mobileHeaders: config.tableView.temperature.mobileHeaders,
      data: entriesFiltered,
      viewEntryTo: `${defaultRoute}/view_entry_temperature/`,
      fetchDates: temperature.fetchDates,
      fetchType: temperature.fetchType,
    };

    if (entriesFiltered.length === 0) {
      return <Message warning message={"noEntriesToDisplayRetry"} />;
    }

    return (
      <Table
        tableData={tableData}
        itemsPerPage={5}
        feature={config.name}
        withCharts
        chartFunc={config.chartFunc}
        ticks={config.chart.temperature.chartData.ticks}
      />
    );
  }

  render() {
    const { fetchType, lastFetchPetID, fetchDates } = this.props.temperature;
    const { pet } = this.props;
    return (
      <>
        <FetchEntries
          fetchItems={this.handleFetchEntries}
          fetchType={fetchType}
          newPet={pet._id === lastFetchPetID}
          fetchDates={fetchDates}
          fetchButtons={["last7Days", "last30Days", "last3Months", "last1Year"]}
        />
        <Section>{this.renderTableSection()}</Section>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchTemperatureEntries,
      dispatch,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(ViewEntries);
