import { features } from "assets";
import { actionTypes } from "./actions";
import nav from "./routes";

const toothMarkerConfig = {
  name: "toothMarker",
  type: "feature",
  defaultRoute: "/userhome/dashboard/tooth_marker",
  menuIcon: {
    img: features.toothMarker.main,
    alt: "tooth_marker_feature_icon",
  },
  nav,
  actionTypes,
  tableView: {
    headers: [],
    mobileHeaders: [],
  },
  chartView: {},
};

export default toothMarkerConfig;
