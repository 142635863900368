import React from "react"
import Loading from "components/spinners/Loading"

function BusyLoading() {
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-content">
        <Loading />
      </div>
    </div>
  )
}

export default BusyLoading
