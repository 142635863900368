import {
  CLOUDINARY_GET_CONFIG,
  CLOUDINARY_HANDLE_UPLOAD,
  CLOUDINARY_RESET,
  AUTH_LOG_OUT_USER,
} from "actions/types"

const INITIAL_STATE = {
  cloudinaryApi: {},
  uploadResult: {},
}

export default function CloudinaryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLOUDINARY_GET_CONFIG:
      return {
        ...state,
        cloudinaryApi: action.payload,
      }
    case CLOUDINARY_HANDLE_UPLOAD:
      return {
        ...state,
        uploadResult: action.payload,
      }
    case AUTH_LOG_OUT_USER:
    case CLOUDINARY_RESET:
      return INITIAL_STATE
    default:
    // Do Nothing
  }
  return state
}
