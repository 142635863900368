import axios from "axios"
import moment from "moment"
import _ from "lodash"
import {
  ACTION_STARTED,
  ACTION_SUCCESS,
  MODALS_HIDE,
  TABLE_CLEAR_CURRENT_STATE,
} from "actions/types"
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "actions/functions"

export const HOME_CARE_FETCH_TYPE = "home_care_fetch_type"
export const HOME_CARE_FETCH = "home_care_fetch"
export const HOME_CARE_ADD = "home_care_add"
export const HOME_CARE_UPDATE = "home_care_update"
export const HOME_CARE_DELETE = "home_care_delete"
export const HOME_CARE_ADD_FOR_LITTER = "home_care_add_for_litter"

const API = (route) => `/api/home_care/${route}`

export const fetchHomeCareEntries = (
  value,
  unit,
  name,
  pet,
  providerView
) => async (dispatch) => {
  const dates = !_.isEmpty(value)
    ? { ...value }
    : {
        from: moment().subtract(value, unit).toISOString(),
        to: moment().toISOString(),
      }
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({
      type: HOME_CARE_FETCH_TYPE,
      payload: { dates, type: !_.isEmpty(value) ? "range" : name },
    })
    dispatch({ type: ACTION_STARTED, payload: HOME_CARE_FETCH })
    const res = await axios.get(
      API(providerView ? "fetch_for_provider" : "fetch"),
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
        params: { _pet: pet._id, ...dates },
      }
    )
    dispatch({
      type: HOME_CARE_FETCH,
      payload: res.data.homeCareEntries,
      pet,
    })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addHomeCareEntry = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerContact,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HOME_CARE_ADD })
    const res = await axios.post(
      API(providerView ? "add_by_provider" : "add"),
      { _user: providerView ? providerContact._id : undefined, ...values },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({ type: HOME_CARE_ADD, payload: res.data.homeCareEntry })
    endAndResetAction(dispatch)
    history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const addHomeCareEntryForLitter = (
  values,
  litterMembers,
  setSubmitting,
  onSuccess
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: HOME_CARE_ADD_FOR_LITTER })
    const res = await axios.post(
      API("add_for_litter"),
      { values, litterMembers },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({ type: HOME_CARE_ADD_FOR_LITTER, payload: res.data.result })
    setSubmitting(false)
    endAndResetAction(dispatch)
    onSuccess()
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const updateHomeCareEntry = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HOME_CARE_UPDATE })
    const res = await axios.put(
      API(providerView ? "update_by_provider" : "update"),
      values,
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({
      type: HOME_CARE_UPDATE,
      payload: res.data.homeCareEntry,
    })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const deleteHomeCareEntry = (
  _id,
  history,
  successRoute,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HOME_CARE_DELETE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      API(providerView ? "delete_by_provider" : "delete"),
      { _id },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({
      type: HOME_CARE_DELETE,
      payload: res.data.homeCareEntry,
    })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const homeCareActions = {
  fetchHomeCareEntries,
  addHomeCareEntry,
  updateHomeCareEntry,
  deleteHomeCareEntry,
  addHomeCareEntryForLitter,
}

export default homeCareActions
