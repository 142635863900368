import React from "react";
import BoxWithBackground from "components/styled/Box_With_Background";
import AddPetForm from "forms/Add_Pet_Form";
import Section from "components/layout/Section";

function AddNewPet(props) {
  return (
    <BoxWithBackground>
      <Section>
        <AddPetForm
          valuesToEdit={{}}
          history={props.history}
          cancel={props.cancel}
        />
      </Section>
    </BoxWithBackground>
  );
}

export default AddNewPet;
