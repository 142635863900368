import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { stableDiary as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./fields/stable_diary";
import { FormTitle, FormButtons } from "components/form";

class StableDiaryEntryForm extends Component {
  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  }

  render() {
    const { errors, dirty, isSubmitting, viewMode, editMode, cancel } =
      this.props;
    return (
      <Form>
        <FormTitle group={"stableDiary"} />
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={"/userhome/dashboard/stable_diary"}
        />
        <br />
      </Form>
    );
  }
}

StableDiaryEntryForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet }) {
    return {
      date:
        moment(valuesToEdit.date).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      time: valuesToEdit.time || moment().format("HH:mm"),
      subject: valuesToEdit.subject || "",
      body: valuesToEdit.body || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    time: Yup.string().required("required"),
    subject: Yup.string().required("required").max(30, "max-30"),
    body: Yup.string().required("required").max(500, "max-500"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries`;
    if (props.editMode) {
      return props.updateStableDiaryEntry(
        values,
        setSubmitting,
        props.history,
        successRoute
      );
    }
    props.addStableDiaryEntry(
      values,
      setSubmitting,
      props.history,
      successRoute
    );
  },
})(StableDiaryEntryForm);

export default withRouter(connect(null, actions)(StableDiaryEntryForm));
