import {
  PARTNERS_CONTENTFUL_API_CALL,
  PARTNERS_CONTENTFUL_API_ERROR,
  PARTNERS_CONTENTFUL_FETCH_TILES,
  PARTNERS_CONTENTFUL_FETCH_PAGES,
  AUTH_LOG_OUT_USER,
} from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";

const INITIAL_STATE = {
  apiCall: false,
  apiError: false,
  partnerTiles: {},
  partnerPages: {},
  partnerRoutes: [],
};

export default function PartnersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PARTNERS_CONTENTFUL_API_CALL:
      return { ...state, apiCall: action.payload };
    case PARTNERS_CONTENTFUL_FETCH_TILES:
      return {
        ...state,
        partnerTiles: action.payload,
        partnerRoutes: action.payload.items,
      };
    case PARTNERS_CONTENTFUL_FETCH_PAGES:
      return { ...state, partnerPages: action.payload };
    case PARTNERS_CONTENTFUL_API_ERROR:
      return { ...state, apiError: action.payload };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
