import {
  InputFieldV5,
  TextareaV5,
  CheckboxV5,
  SelectButtonsV5,
} from "components/form";

const INPUT_FIELDS = [
  {
    name: "onGoing",
    group: "healthDiaryMedicine",
    type: "checkbox",
    className: "input ",
    component: CheckboxV5,
    required: false,
    lastInGroup: true,
    userCanEdit: true,
    noLabel: true,
  },
  {
    name: "date",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: true,
    lastInGroup: false,
    userCanEdit: true,
  },
  {
    name: "dateEnd",
    group: "common",
    type: "date",
    icon: "far fa-calendar-alt",
    className: "input ",
    component: InputFieldV5,
    required: false,
    lastInGroup: true,
    userCanEdit: true,
  },
  {
    name: "time",
    group: "common",
    type: "time",
    icon: "far fa-clock",
    className: "input ",
    component: InputFieldV5,
    required: true,
    lastInGroup: true,
    userCanEdit: true,
  },
  {
    name: "medicine",
    group: "healthDiaryMedicine",
    type: "text",
    icon: "fas fa-syringe",
    className: "input ",
    component: InputFieldV5,
    required: true,
    lastInGroup: false,
    userCanEdit: true,
  },
  {
    name: "frequency",
    group: "healthDiaryMedicine",
    type: "text",
    icon: "fas fa-syringe",
    className: "input ",
    component: InputFieldV5,
    required: false,
    lastInGroup: false,
    userCanEdit: true,
  },
  {
    name: "medicineAmount",
    group: "healthDiaryMedicine",
    type: "number",
    icon: "fas fa-syringe",
    className: "input ",
    component: InputFieldV5,
    required: true,
    lastInGroup: false,
    min: 0,
    step: 0.001,
    userCanEdit: true,
  },
  {
    name: "medicineAmountUnit",
    group: "healthDiaryMedicine",
    selectOptionsGroup: "amountUnit",
    icon: "fas fa-syringe",
    className: "",
    component: SelectButtonsV5,
    required: true,
    lastInGroup: true,
    userCanEdit: true,
  },
  {
    name: "reasonForMedicine",
    group: "healthDiaryMedicine",
    type: "text",
    icon: "fas fa-syringe",
    className: "input ",
    component: InputFieldV5,
    required: false,
    lastInGroup: false,
    userCanEdit: true,
  },
  {
    name: "withdrawalPeriod",
    group: "healthDiaryMedicine",
    type: "text",
    icon: "fas fa-syringe",
    className: "input ",
    component: InputFieldV5,
    required: false,
    lastInGroup: false,
    userCanEdit: true,
  },
  {
    name: "prescribedBy",
    group: "healthDiaryMedicine",
    type: "text",
    icon: "fas fa-syringe",
    className: "input ",
    component: InputFieldV5,
    required: false,
    lastInGroup: false,
    userCanEdit: true,
  },
  {
    name: "provider",
    group: "healthDiaryMedicine",
    type: "text",
    icon: "fas fa-syringe",
    className: "input ",
    component: InputFieldV5,
    required: false,
    lastInGroup: true,
    userCanEdit: true,
  },
  {
    name: "notes",
    group: "common",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: false,
    charLimit: 500,
    lastInGroup: false,
    userCanEdit: true,
  },
  {
    name: "sideEffects",
    group: "healthDiaryMedicine",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 3,
    required: false,
    charLimit: 120,
    lastInGroup: true,
    userCanEdit: true,
  },
];

export { INPUT_FIELDS };
