import React, { Component } from "react"
import Section from "components/layout/Section"
import Message from "components/message"
import MedicineEntry from "./Medicine_Entry"

class MedicineEntryView extends Component {
  render() {
    // check to see if litter member has new owner
    // if they do, render message to alert that
    if (this.props.selectedLitterMember.hasNewOwner)
      return (
        <Section>
          <Message warning message="memberHasNewOwner" />
        </Section>
      )
    return (
      <Section>
        <MedicineEntry {...this.props} />
      </Section>
    )
  }
}

export default MedicineEntryView
