import foodDiary from "assets/features/food_diary/food_diary.svg"
import hcpiResults from "assets/features/hcpi/hcpi.svg"
import medicineReport from "assets/features/health_diary/health_diary.svg"
import trainingDiary from "assets/features/training_diary/training_diary_view_entries.svg"
import activity from "containers/features/Activity/assets/activity_daily_activity_view_entries.svg"
import weight from "assets/features/weight/weight.svg"
import litterExpenses from "assets/litter_features/litter_expenses.svg"
import heatDiary from "assets/features/heat_diary/heat_diary.svg"
import fmpi from "containers/features/Fmpi/assets/fmpi.svg"

const REPORT_TYPE_ICONS = {
  foodDiary,
  hcpiResults,
  medicineReport,
  trainingDiary,
  activity,
  weight,
  litterExpenses,
  heatDiary,
  fmpi,
}

export default REPORT_TYPE_ICONS
