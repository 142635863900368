import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./new_task_fields";
import { FormTitle, FormButtons } from "components/form";

class NewTaskForm extends Component {
  renderFields = () => {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={field.name}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  };

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      editMode,
      viewMode,
      cancel,
      defaultRoute,
    } = this.props;
    return (
      <div>
        <Form>
          <FormTitle group={"newTask"} />
          <br />
          {this.renderFields()}
          <br />
          <FormButtons
            viewMode={viewMode}
            editMode={editMode}
            isSubmitting={isSubmitting}
            errors={errors}
            dirty={dirty}
            cancel={cancel}
            linkTo={defaultRoute}
          />
          <br />
        </Form>
      </div>
    );
  }
}

NewTaskForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet }) {
    return {
      taskDueDate: moment(valuesToEdit.taskDueDate).format("YYYY-MM-DD") || "",
      taskDueTime: valuesToEdit.taskDueTime || "",
      taskTitle: valuesToEdit.taskTitle || "",
      taskDescription: valuesToEdit.taskDescription || "",
      taskAttachmentURL: valuesToEdit.taskAttachmentURL || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    taskDueDate: Yup.date().required("required"),
    taskDueTime: Yup.string(),
    taskTitle: Yup.string().max(50, "max-50").required("required"),
    taskDescription: Yup.string().max(500, "max-500").required("required"),
    taskAttachmentURL: Yup.string().url("url"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries`;
    if (props.editMode) {
      return props.updateTask(values, setSubmitting, props.providerView);
    }
    return props.addTask(
      values,
      setSubmitting,
      props.history,
      successRoute,
      props.providerContact,
      props.providerView
    );
  },
})(NewTaskForm);

export default connect(null, actions)(NewTaskForm);
