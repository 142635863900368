import React from "react"
import FormWrapper from "components/layout/Form_Wrapper"
import StressLevelEntryForm from "../forms/Stress_Level_Entry_Form"
import Section from "components/layout/Section"

function NewEntry(props) {
  return (
    <Section>
      <FormWrapper>
        <StressLevelEntryForm valuesToEdit={{}} pet={props.pet} {...props} />
      </FormWrapper>
    </Section>
  )
}

export default NewEntry
