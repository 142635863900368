import React from "react";
import { AWARD_TYPE_SETTINGS } from "../constants";

const BlingSettings = (props) => {
  return AWARD_TYPE_SETTINGS[props.type].sections.map((section, i) => {
    if (!section.toggleVis) return null;
    return (
      <div key={i} className="field">
        <label className="label is-small">
          {props.messages.awardPartLabels[section.name][props.locale]}
        </label>
        <div className="control">
          <input
            id={section.name}
            type="checkbox"
            name={section.name}
            className="switch is-rounded"
            checked={props.awardData.bling[section.name]}
            onChange={() => props.handleBlingChange(section.name)}
          />
          <label htmlFor={section.name} />
        </div>
      </div>
    );
  });
};

export default BlingSettings;
