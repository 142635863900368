import React from "react"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"
import Section from "components/layout/Section"
import SectionTitle from "components/layout/Section_Title"
import { ACTIVITIES } from "../constants"

function NewEntryDailyActivity(props) {
  const { messages, locale, history, defaultRoute } = props
  const { newEntryDailyActivitySelection, categories, activities } = messages

  function handleActivitySelection(category, activity) {
    history.push(`${defaultRoute}/new_entry_daily_activity`, {
      category,
      activity,
    })
  }

  const CATEGORIES = [...new Set(ACTIVITIES.map((a) => a.category))]

  return (
    <Section style={{ paddingBottom: 300 }}>
      <SectionTitle
        title={newEntryDailyActivitySelection.title}
        subtitle={newEntryDailyActivitySelection.subtitle}
        form
      />
      {CATEGORIES.map((c) => (
        <div key={c} style={{ marginBottom: 26 }}>
          <SectionTitle title={categories[c]} subtitle={""} />
          <div className="buttons">
            {ACTIVITIES.map((a) => {
              if (a.category === c) {
                return (
                  <div
                    key={a.activity}
                    className={"button is-light is-small"}
                    onClick={() => handleActivitySelection(c, a.activity)}
                    label={activities[a.activity][locale]}
                  >
                    {activities[a.activity][locale]}
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
      ))}
    </Section>
  )
}

export default RequireSubFeature(NewEntryDailyActivity)
