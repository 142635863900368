import React from "react"
import { main } from "assets"
import { Link } from "react-router-dom"
import { returnLogoOverlayText } from "functions"

export default function SiteLogo({ isAuthed, role, pawWidth, nameWidth }) {
  return (
    <div className="site-logo-container">
      <Link
        to={
          isAuthed && role === "user"
            ? "/userhome"
            : isAuthed && role === "provider"
            ? "/providerhome"
            : "/"
        }
      >
        <img
          className="site-logo-paw"
          src={main.paw}
          alt="site_logo"
          width={pawWidth}
        />
        <img
          className="site-logo-name"
          src={main.siteLogo}
          alt="site_logo"
          width={nameWidth}
        />
      </Link>
      <div className="site-logo-hostname-text">
        {returnLogoOverlayText().str}
      </div>
    </div>
  )
}
