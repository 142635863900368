import React from "react"
import SmartTile from "components/SmartTile"
import blogConfig from "containers/main/Blog/config"
import { LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD } from "functions/hostname"
import { renderMeOnClient } from "functions"

export default function TassuApuBlog(props) {
  const clients = [LOCALHOST, TASSUAPU_PROD, TASSUAPU_STAGING]

  if (!renderMeOnClient(clients)) return null

  return (
    <div className={props.column}>
      <SmartTile
        appTile
        header
        title={props.name}
        icon={blogConfig.main.img.src}
        path={blogConfig.main.path}
      />
    </div>
  )
}
