import main from "./tasks.svg"
import newEntry from "./tasks_new_entry.svg"
import viewEntries from "./tasks_view_entries.svg"
import viewTask from "./tasks_view_task.svg"
import completed from "./tasks_completed.svg"
import markComplete from "./tasks_complete.svg"
import editTask from "assets/main/edit.svg"
import deleteTask from "assets/professionals/contact_delete.svg"

const tasks = {
  main,
  newEntry,
  viewEntries,
  viewTask,
  completed,
  markComplete,
  editTask,
  deleteTask,
}

export default tasks
