import React from "react"

function AssociateLabel(props) {
  return (
    <div className="subtitle has-text-dark has-text-weight-bold">
      {props.messages.titles.inAssociationWith[props.messages.locale]}
    </div>
  )
}

export default AssociateLabel
