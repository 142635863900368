import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { foodDiary as actions } from "actions/index";
import moment from "moment";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { INPUT_FIELDS } from "forms/fields/food_diary";
import FOOD_CATEGORIES from "constants/select_options";
import { FormTitle, FormButtons } from "components/form";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";

class FoodDiaryEntryForm extends Component {
  state = { showAdditionalFields: false };

  renderFields(additionalFields) {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (!additionalFields && field.additionalField) return null;
      if (
        (!values.onGoing && field.name === "dateEnd") ||
        (values.onGoing && field.name === "foodDiaryQ1") ||
        (values.onGoing && field.name === "foodDiaryQ2")
      )
        return null;
      if (!values.sideEffectsObserved && field.name === "sideEffectsDesc")
        return null;
      return (
        <div className="field" key={i}>
          <Field
            viewMode={viewMode}
            editMode={editMode}
            values={values}
            {...field}
          />
          {field.lastInGroup && <br />}
        </div>
      );
    });
  }

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      viewMode,
      editMode,
      locale,
      values,
      cancel,
      defaultRoute,
    } = this.props;
    const { showAdditionalFields } = this.state;
    const { foodCategories } = this.props.messages;
    const foodCategory =
      FOOD_CATEGORIES.foodDiary.foodCategory.categorySelection.filter(
        (cat) => cat.name === values.foodCategory
      );
    return (
      <Form className="has-text-left">
        <FormTitle group={"foodDiary"} />
        <br />
        <div className="box">
          <div
            style={{ justifyContent: "center", padding: 0 }}
            className="card-content is-flex"
          >
            <figure className="image is-96x96">
              <img src={foodCategory[0].icon} alt={foodCategory[0].name} />
            </figure>
          </div>
          <p className="heading has-text-centered is-uppercase">
            {foodCategories[foodCategory[0].name][locale]}
          </p>
        </div>
        {this.renderFields(showAdditionalFields)}
        <br />
        <Buttons className="is-centered">
          <Button
            group="common"
            name={
              viewMode && !showAdditionalFields
                ? "showDetails"
                : viewMode && showAdditionalFields
                ? "showLess"
                : showAdditionalFields
                ? "showLess"
                : "showFormAdditionalFields"
            }
            onClick={() =>
              this.setState({
                showAdditionalFields: !this.state.showAdditionalFields,
              })
            }
          />
        </Buttons>
        <br />
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={defaultRoute}
        />
        <br />
      </Form>
    );
  }
}

FoodDiaryEntryForm = withFormik({
  mapPropsToValues({ foodCategory, valuesToEdit, pet }) {
    return {
      foodCategory: valuesToEdit.foodCategory || foodCategory,
      onGoing: valuesToEdit.onGoing || false,
      date:
        moment(valuesToEdit.date).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      time: valuesToEdit.time || moment().format("HH:mm"),
      dateEnd: valuesToEdit.dateEnd
        ? moment(valuesToEdit.dateEnd).format("YYYY-MM-DD")
        : "",
      productName: valuesToEdit.productName || "",
      manufacturer: valuesToEdit.manufacturer || "",
      foodDetail: valuesToEdit.foodDetail || "",
      foodAmountUnit: valuesToEdit.foodAmountUnit || "gram",
      foodAmount: valuesToEdit.foodAmount || 0,
      foodDiaryQ1: valuesToEdit.q1a || "notSpecified",
      foodDiaryQ2: valuesToEdit.q2a || "notSpecified",
      frequency: valuesToEdit.frequency || "notSpecified",
      kcalKg: valuesToEdit.kcalKg || 0,
      kJKg: valuesToEdit.kJKg || 0,
      proteinContent: valuesToEdit.proteinContent || 0,
      carbohydrateContent: valuesToEdit.carbohydrateContent || 0,
      fatContent: valuesToEdit.fatContent || 0,
      calciumContent: valuesToEdit.calciumContent || 0,
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
      petName: valuesToEdit.petName || pet.petName,
      petType: valuesToEdit.petType || pet.petType,
    };
  },
  validationSchema: Yup.object().shape({
    onGoing: Yup.boolean(),
    foodCategory: Yup.string(),
    date: Yup.date().required("required"),
    dateEnd: Yup.date().when("onGoing", {
      is: true,
      then: Yup.date(),
      otherwise: Yup.date(),
    }),
    time: Yup.string().required("required"),
    productName: Yup.string(),
    manufacturer: Yup.string(),
    foodDetail: Yup.string(),
    foodAmountUnit: Yup.string().required("required"),
    foodAmount: Yup.number().required("required"),
    foodDiaryQ1: Yup.string(),
    foodDiaryQ2: Yup.string(),
    frequency: Yup.string(),
    kcalKg: Yup.number()
      .min(0, "greaterThanOrEqualTo-0")
      .max(7000, "lessThanOrEqualTo-7000"),
    kJKg: Yup.number()
      .min(0, "greaterThanOrEqualTo-0")
      .max(30000, "lessThanOrEqualTo-30000"),
    proteinContent: Yup.number(),
    carbohydrateContent: Yup.number(),
    fatContent: Yup.number(),
    calciumContent: Yup.number(),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries`;
    if (props.editMode) {
      return props.updateFde(
        values,
        setSubmitting,
        props.history,
        successRoute,
        props.providerView
      );
    }
    props.addFde(
      values,
      setSubmitting,
      props.history,
      successRoute,
      props.providerContact,
      props.providerView
    );
  },
})(FoodDiaryEntryForm);

export default withRouter(connect(null, actions)(FoodDiaryEntryForm));
