import bones from "./bones.svg"
import carbohydrates from "./carbohydrates.svg"
import cookedFood from "./cookedfood.svg"
import dairy from "./dairy.svg"
import dryFood from "./dryfood.svg"
import foraging from "./foraging.svg"
import leftovers from "./leftovers.svg"
import rawFood from "./rawfood.svg"
import supplements from "./supplements.svg"
import treats from "./treats.svg"
import vegetables from "./vegetables.svg"
import fluids from "./fluids.svg"
import grass from "./grass.svg"
import hay from "./hay.svg"
import horseTreats from "./horsetreats.svg"
import oats from "./oats.svg"
import pellets from "./pellets.svg"

const FOOD_CATEGORY_ICONS = {
  bones,
  carbohydrates,
  cookedFood,
  dairy,
  dryFood,
  foraging,
  leftovers,
  rawFood,
  supplements,
  treats,
  vegetables,
  fluids,
  grass,
  hay,
  horseTreats,
  oats,
  pellets
}

export default FOOD_CATEGORY_ICONS
