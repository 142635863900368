import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Section from "components/layout/Section";
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons";
import LitterMemberWeightForm from "forms/Litter_Member_Weight_Form";
import {
  MODALS_SHOW,
  MODALS_CONFIRM_DELETE,
  WEIGHT_DELETE,
} from "actions/types";
import FormWrapper from "components/layout/Form_Wrapper";

const INITIAL_STATE = {
  viewMode: true,
  valuesToEdit: {},
};

class ViewEntry extends Component {
  state = INITIAL_STATE;

  UNSAFE_componentWillMount() {
    const { weightEntries, history } = this.props;
    const { id } = this.props.match.params;
    const weight = weightEntries.filter((weight) => weight._id === id)[0];

    if (!weight)
      return history.push(
        `/userhome/dashboard/litter/litter_member_dashboard/weight/view_entries`
      );
    this.setState({
      valuesToEdit: weight,
    });
  }

  handleEditClick = () => {
    this.setState({
      viewMode: !this.state.viewMode,
    });
  };

  handleDeleteClick = () => {
    const successRoute = `/userhome/dashboard/litter/litter_member_dashboard/weight/view_entries`;
    this.props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "weight",
        actionType: WEIGHT_DELETE,
        history: this.props.history,
        successRoute: successRoute,
        item: this.state.valuesToEdit._id,
      },
    });
  };

  renderSectionContent() {
    if (_.isEmpty(this.state.valuesToEdit)) return <div />;

    return (
      <>
        <BackEditDeleteButtons
          disableEditButton={this.props.selectedLitterMember.hasNewOwner}
          disableDeleteButton={this.props.selectedLitterMember.hasNewOwner}
          handleEditClick={this.handleEditClick}
          handleDeleteClick={this.handleDeleteClick}
          viewMode={this.state.viewMode}
        />
        <FormWrapper>
          <LitterMemberWeightForm
            editMode
            {...this.state}
            cancel={this.handleEditClick}
            {...this.props}
          />
        </FormWrapper>
      </>
    );
  }

  render() {
    return <Section>{this.renderSectionContent()}</Section>;
  }
}

const mapStateToProps = (state) => {
  return {
    weightEntries: state.reducers.weight.entries,
  };
};

export default withRouter(connect(mapStateToProps)(ViewEntry));
