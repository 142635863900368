// Color picker input
// Return string to be saved for color identifier for the litter member

import React from "react";
import { connect } from "react-redux";
import { HuePicker } from "react-color";
import FormValidationMessageV5 from "./FormValidationMessageV5";

function ColorPicker(props) {
  function handleChange(color) {
    props.form.setFieldValue(props.field.name, color.hex);
  }

  return (
    <div className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">{props.label}</div>
      </label>
      {!props.viewMode && (
        <div className="control">
          <HuePicker
            width="100%"
            onChange={handleChange}
            color={props.field.value}
          />
        </div>
      )}
      <div className="control">
        <div
          style={{
            marginTop: 6,
            backgroundColor: props.field.value,
            padding: 12,
            borderRadius: 3,
          }}
        />
      </div>
      <FormValidationMessageV5
        clientMessage
        group={props.group}
        name={props.field.name}
        errors={props.form.errors}
        touched={props.form.touched}
      />
    </div>
  );
}

const mapStateToProps = (state, { group, field }) => {
  const { locale, messages } = state.intl;
  return {
    label: messages.components.forms.labels[group][field.name][locale],
  };
};

export default connect(mapStateToProps)(ColorPicker);
