import {
  FineWeightControl,
  FineWeightControlLBS,
  RadioButtonsV5,
} from "components/form";

const CALORIE_CALCULATOR_FORM_FIELDS = [
  {
    name: "kg",
    group: "calorieCalculator",
    type: "number",
    icon: "fas fa-weight",
    className: "input",
    component: FineWeightControl,
    renderCondition: (unit) => unit === "kg",
    required: true,
  },
  {
    name: "g",
    group: "calorieCalculator",
    type: "number",
    icon: "fas fa-weight",
    className: "input",
    component: FineWeightControl,
    renderCondition: (unit) => unit === "kg",
    required: true,
  },
  {
    name: "lbs",
    type: "number",
    icon: "fas fa-weight",
    className: "input",
    component: FineWeightControlLBS,
    required: true,
    userCanEdit: true,
    renderCondition: (unit) => unit === "lbs",
  },
  {
    name: "activityAndAge",
    group: "calorieCalculator",
    type: "radio",
    icon: "",
    className: "",
    component: RadioButtonsV5,
    required: true,
  },
];

export { CALORIE_CALCULATOR_FORM_FIELDS };
