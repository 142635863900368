import { features, main } from "assets"
import Landing from "components/features/Landing"
import FeatureResources from "components/features/Feature_Resources"
import NewEntrySelectionView from "./views/New_Entry_Selection_View"
import NewEntryView from "./views/New_Entry_View"
import ViewEntriesView from "./views/View_Entries_View"
import ViewEntryView from "./views/View_Entry_View"

import {
  LOCALHOST,
  TASSUAPU_STAGING,
  TASSUAPU_PROD,
  //PETFILER_STAGING,
  //PETFILER_PROD,
} from "functions/hostname"

const homeCareRoutes = [
  {
    name: "landing",
    disabled: false,
    menuItem: true,
    image: {
      img: features.infoMenuIcon,
      alt: "info_menu_icon",
    },
    hr: true,
    exact: true,
    component: Landing,
    path: "/",
  },
  {
    name: "resources",
    disabled: true,
    clients: [LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD],
    menuItem: true,
    image: {
      img: main.resources,
      alt: "feature_resources_icon",
    },
    hr: true,
    exact: true,
    component: FeatureResources,
    path: "/resources",
  },
  {
    name: "newEntrySelectionView",
    disabled: false,
    menuItem: true,
    image: {
      img: features.homeCare.newEntry,
      alt: "new_entry_icon",
    },
    component: NewEntrySelectionView,
    path: "/new_entry_selection",
  },
  {
    name: "newEntryView",
    disabled: false,
    menuItem: false,
    component: NewEntryView,
    path: "/new_entry",
  },
  {
    name: "viewEntriesView",
    disabled: false,
    menuItem: true,
    image: {
      img: features.homeCare.viewEntries,
      alt: "view_entries_icon",
    },
    component: ViewEntriesView,
    path: "/view_entries",
  },
  {
    name: "viewEntryView",
    disabled: false,
    menuItem: false,
    component: ViewEntryView,
    path: "/view_entry/:id",
  },
]

export default homeCareRoutes
