import React, { useState, useEffect } from "react";
import FEATURES from "constants/routes";
import config from "./config";
import SmartTile from "components/SmartTile";

function LitterMemberDashboardFeatures(props) {
  const [featureOrderSet, setFeatureOrderState] = useState(false);
  const [featureOrder, addArrayOfFeatures] = useState([]);

  useEffect(() => {
    function setFeatureOrder() {
      let features = config.featureOrder.map((name) =>
        FEATURES.find((feature) => feature.name === name)
      );
      addArrayOfFeatures(features);
      setFeatureOrderState(true);
    }
    setFeatureOrder();
  }, []);

  if (!featureOrderSet) return null;
  return (
    <>
      <div className={config.layout.column}>
        <SmartTile
          petTile
          dashboard
          litterMember
          path={"/userhome/dashboard/litter/litter_member_dashboard/profile"}
          pet={props.pet}
        />
      </div>
      {featureOrder.map((feature, i) => {
        if (!feature.litterMemberFeature) return null;
        if (feature.disabled) return null;
        return (
          <div key={feature.name} className={config.layout.column}>
            <SmartTile featureTile {...feature} />
          </div>
        );
      })}
    </>
  );
}

export default LitterMemberDashboardFeatures;
