import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import { CALORIE_CALCULATOR_FORM_FIELDS } from "./fields/calorie_calculator";

import { FormButtons } from "components/form";

class CalorieCalculatorForm extends Component {
  renderFields() {
    const { pet, userPreferredUnit } = this.props;
    return _.map(CALORIE_CALCULATOR_FORM_FIELDS, (field, i) => {
      if (field.renderCondition && !field.renderCondition(userPreferredUnit))
        return null;
      return <Field key={i} {...field} petType={pet.petType} />;
    });
  }

  renderResult() {
    const { values, messages, locale } = this.props;
    const {
      components: {
        calorieCalculator: { labels },
      },
    } = messages;
    return (
      <div className="box has-text-centered">
        <p>{labels.calorieCalcResult[locale]}</p>
        {!!values.singleResult ? (
          <p>{`${values.singleResult} ${labels.kcal[locale]}`}</p>
        ) : (
          <p>{`${values.minResult} - ${values.maxResult} ${labels.kcal[locale]}`}</p>
        )}
      </div>
    );
  }

  render() {
    const { values, userPreferredUnit } = this.props;
    return (
      <article className="box is-shadowless form_box">
        <Form>
          {this.renderFields()}
          {(!!values.singleResult ||
            !!values.minResult ||
            !!values.maxResult) &&
            this.renderResult()}
          <FormButtons
            noCancel
            buttonsClass="is-right"
            submitButtonLabel={{ group: "common", name: "calculate" }}
            disabled={
              (userPreferredUnit === "kg" && values.kg + values.g === 0) ||
              (userPreferredUnit === "lbs" && values.lbs === 0) ||
              !values.activityAndAge
                ? true
                : false
            }
          />
        </Form>
      </article>
    );
  }
}

CalorieCalculatorForm = withFormik({
  mapPropsToValues({ pet }) {
    return {
      kg: pet.petWeightKg > 0 ? pet.petWeightKg : 0,
      g: pet.petWeightG > 0 ? pet.petWeightG : 0,
      lbs: pet.petWeightLbs > 0 ? pet.petWeightLbs : 0,
      activityAndAge: "",
      singleResult: "",
      minResult: "",
      maxResult: "",
    };
  },
  validationSchema: Yup.object().shape({
    kg: Yup.number(),
    g: Yup.number(),
    lbs: Yup.number(),
    activityAndAge: Yup.string().required("req"),
  }),
  handleSubmit(values, props) {
    let petWeight;
    if (props.userPreferredUnit === "kg") {
      petWeight = values.kg * 1000 + values.g;
    } else {
      petWeight = petWeight = values.lbs * 454;
    }
    const array = values.activityAndAge.split(",");
    const x = Math.pow(petWeight / 1000, 0.75);
    const RER = x * 70;
    if (array.length > 1) {
      props.setFieldValue("singleResult", "");
      props.setFieldValue("minResult", Math.round(RER * array[0]));
      props.setFieldValue("maxResult", Math.round(RER * array[1]));
      return;
    }
    props.setFieldValue("minResult", "");
    props.setFieldValue("maxResult", "");
    props.setFieldValue("singleResult", Math.round(RER * array[0]));
  },
})(CalorieCalculatorForm);

export default CalorieCalculatorForm;
