import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./fields";
import CategoryActivitySelectionSummary from "components/form/Category_Activity_Selection_Summary";
import { FormTitle, FormButtons } from "components/form";

class HomeCareEntryForm extends Component {
  renderFields = () => {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  };

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      editMode,
      viewMode,
      cancel,
      values,
      defaultRoute,
      messages,
    } = this.props;
    const { categories, activities } = messages;
    return (
      <Form>
        <FormTitle group={"homeCare"} />
        <br />
        <div className="columns is-1 is-variable is-mobile is-multiline">
          <div className="column">
            <CategoryActivitySelectionSummary
              categories={categories}
              activities={activities}
              category={values.category}
              activity={values.activity}
            />
            {this.renderFields()}
            <br />
          </div>
        </div>
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={defaultRoute}
        />
        <br />
      </Form>
    );
  }
}

HomeCareEntryForm = withFormik({
  mapPropsToValues({ selectedLitter, history }) {
    return {
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm"),
      category: history.location.state.category,
      activity: history.location.state.activity,
      notes: "",
      _litter: selectedLitter._id,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    time: Yup.string().required("required"),
    notes: Yup.string().max(250, "max-250"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/add_for_litter_success`;
    props.addHomeCareEntryForLitter(
      values,
      props.history.location.state.litterMembers,
      setSubmitting,
      () => props.history.push(successRoute)
    );
  },
})(HomeCareEntryForm);

export default connect(null, actions)(HomeCareEntryForm);
