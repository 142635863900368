import React from "react";
import { Link } from "react-router-dom";
import BoxWithBackground from "components/styled/Box_With_Background";
import SocialLogins from "containers/auth/Social_Logins";
import Divider from "components/elements/Divider";
import Button from "components/elements/Button";
import Message from "components/message";
import Intl from "components/Intl";

function RegisterButtonsView({ handleLocalSignupClick }) {
  return (
    <div>
      <Message info message={"authRegister"} />
      <BoxWithBackground squareBorder>
        <div style={{ padding: "16px 0" }}>
          <Button
            name="registerWithOwnEmail"
            group="auth"
            className="is-fullwidth"
            onClick={() => handleLocalSignupClick()}
            withIcon
            iconLeft
          />
        </div>
        <Divider />
        <SocialLogins register />
        <br />
        <div className="heading has-text-centered has-text-link has-text-weight-bold">
          <Link to={"/login"}>
            <Intl
              message
              path="components.forms.authLinks.register.alreadyRegistered"
            />
          </Link>
        </div>
      </BoxWithBackground>
    </div>
  );
}

export default RegisterButtonsView;
