import { MODALS_SHOW, MODALS_HIDE } from "actions/types"

const INITIAL_STATE = {
  modalType: "",
  modalProps: {},
}

export default function ModalsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MODALS_SHOW:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      }
    case MODALS_HIDE:
      return INITIAL_STATE
    default:
      return state
  }
}
