import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "actions/transfers";
import * as Yup from "yup";
import { INPUT_FIELDS } from "./fields/retrieve_pet";
import { FormTitle } from "components/form";

class RetrievePetForm extends Component {
  renderFields = () => {
    return INPUT_FIELDS.map((field, i) => {
      return <Field key={i} {...field} />;
    });
  };

  render() {
    const { cancel, messages, locale } = this.props;
    return (
      <Form>
        <FormTitle group={"retrievePet"} />
        <br />
        {this.renderFields()}
        <br />
        <div className="buttons is-centered">
          <button className="button" onClick={cancel}>
            {messages.buttons.cancel[locale]}
          </button>
          <button type="submit" className="button is-info">
            {messages.buttons.retrievePet[locale]}
          </button>
        </div>
        <br />
      </Form>
    );
  }
}

RetrievePetForm = withFormik({
  mapPropsToValues() {
    return {
      retrievePetCode: "",
    };
  },
  validationSchema: Yup.object().shape({
    retrievePetCode: Yup.string()
      .max(8, "retrievePetCodeLength")
      .min(8, "retrievePetCodeLength")
      .required("required"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    props.fetchPetForTransfer(values, setSubmitting);
  },
})(RetrievePetForm);

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      buttons: {
        ...state.intl.messages.components.buttons.common,
        ...state.intl.messages.components.buttons.petTransfer,
      },
    },
  };
};

export default withRouter(connect(mapStateToProps, actions)(RetrievePetForm));
