import themes from "constants/themes";

import {
  AUTH_FETCH_INFO_AND_UPDATES,
  CHANGE_THEME,
  CLIENT_SETTINGS_FETCH,
} from "actions/types";

const INITIAL_STATE = {
  currentTheme: "primary",
  theme: themes.primary,
  clientSettingsFetched: false,
  infoAndUpdates: [],
};

export default function AppDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...state,
        currentTheme: themes[action.payload].name,
        theme: themes[action.payload],
      };
    case CLIENT_SETTINGS_FETCH:
      return {
        ...state,
        clientSettingsFetched: true,
        supportedLanguages: action.payload.supportedLanguages,
        appName: action.payload.appName,
        copyRightOwner: action.payload.copyRightOwner,
        blog: action.payload.blog,
        footerLinks: {
          owner: action.payload.owner,
          facebook: action.payload.facebook,
          mainSite: action.payload.mainSite,
          contactEmail: action.payload.contactEmail,
          customerReg: action.payload.customerReg,
          termsCond: action.payload.termsCond,
          privacyPolicy: action.payload.privacyPolicy,
        },
      };
    case AUTH_FETCH_INFO_AND_UPDATES:
      return {
        ...state,
        infoAndUpdates: action.payload,
      };
    default:
      return state;
  }
}
