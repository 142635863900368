import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./fields";
import CategoryActivitySelectionSummary from "components/form/Category_Activity_Selection_Summary";
import { FormTitle, FormButtons } from "components/form";

class HomeCareEntryForm extends Component {
  renderFields = () => {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          {...field}
        />
      );
    });
  };

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      editMode,
      viewMode,
      cancel,
      values,
      defaultRoute,
      messages,
    } = this.props;
    const { categories, activities } = messages;
    return (
      <Form>
        <FormTitle group={"homeCare"} />
        <br />
        <div className="columns is-1 is-variable is-mobile is-multiline">
          <div className="column">
            <CategoryActivitySelectionSummary
              categories={categories}
              activities={activities}
              category={values.category}
              activity={values.activity}
            />
            {this.renderFields()}
            <br />
          </div>
        </div>
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={defaultRoute}
        />
        <br />
      </Form>
    );
  }
}

HomeCareEntryForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet, history }) {
    return {
      date:
        moment(valuesToEdit.date).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      time: valuesToEdit.time || moment().format("HH:mm"),
      category: valuesToEdit.category || history.location.state.category,
      activity: valuesToEdit.activity || history.location.state.activity,
      notes: valuesToEdit.notes || "",
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    time: Yup.string().required("required"),
    notes: Yup.string().max(250, "max-250"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/view_entries`;
    if (props.editMode) {
      return props.updateHomeCareEntry(
        values,
        setSubmitting,
        props.history,
        successRoute,
        props.providerView
      );
    }
    return props.addHomeCareEntry(
      values,
      setSubmitting,
      props.history,
      successRoute,
      props.providerContact,
      props.providerView
    );
  },
})(HomeCareEntryForm);

export default connect(null, actions)(HomeCareEntryForm);
