import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { hoofCare as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import FormButtons from "components/form/FormButtons";
import { INPUT_FIELDS } from "./fields/hoof_care";
import { FormTitle } from "components/form";

class HoofCareForm extends Component {
  renderFields() {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          values={values}
          viewMode={viewMode}
          editMode={editMode}
          {...field}
        />
      );
    });
  }

  render() {
    const { errors, dirty, isSubmitting, viewMode, editMode, cancel } =
      this.props;
    return (
      <Form>
        <FormTitle group={"hoofCare"} />
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={"/userhome/dashboard/hoof_care"}
        />
        <br />
      </Form>
    );
  }
}

HoofCareForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet }) {
    return {
      date: valuesToEdit.date
        ? moment(valuesToEdit.date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      nextCareDate: valuesToEdit.nextCareDate
        ? moment(valuesToEdit.nextCareDate).format("YYYY-MM-DD")
        : moment().add(2, "months").format("YYYY-MM-DD"),
      subject: valuesToEdit.subject || "",
      notes: valuesToEdit.notes || "",
      _pet: valuesToEdit._pet || pet._id,
      _id: valuesToEdit._id,
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    nextCareDate: Yup.date().required("required"),
    subject: Yup.string().required("required").max(30, "max-30"),
    notes: Yup.string().max(500, "max-500"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = "/userhome/dashboard/hoof_care/view_entries";
    if (props.editMode) {
      return props.updateHoofCareEntry(
        values,
        setSubmitting,
        props.history,
        successRoute
      );
    }
    props.addHoofCareEntry(values, setSubmitting, props.history, successRoute);
  },
})(HoofCareForm);

export default withRouter(connect(null, actions)(HoofCareForm));
