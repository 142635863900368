import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import assets from "assets";
import moment from "moment";
import { setCurrentContact } from "../../actions";
import { selectPet } from "actions/pets";
import SectionTitle from "containers/contacts/components/SectionTitle";
import Pagination from "containers/contacts/components/Pagination";
import Intl from "components/Intl";
import hcpiConfig from "containers/features/Hcpi/config";

function PainIndexDataView(props) {
  function handleButtonClick(userId, pet, path) {
    const contact = props.contacts.filter((c) => c._user !== userId)[0];
    props.setCurrentContact(contact);
    props.selectPet(pet);
    props.history.push(path);
  }

  const buttonStyle = { padding: 6, height: 36 };

  function returnChangeIcon(stotalpoints1, stotalpoints2) {
    const p1 = parseInt(stotalpoints1);
    const p2 = parseInt(stotalpoints2);

    if (isNaN(p2)) return <div className="has-text-weight-bold">{"-"}</div>;

    if (p1 === p2)
      return (
        <div style={hcpiConfig.tableView.cellStyle.totalPoints(7)}>
          <i className="fas fa-minus" />
        </div>
      );
    if (p1 > p2)
      return (
        <div style={hcpiConfig.tableView.cellStyle.totalPoints(12)}>
          <i className="fas fa-arrow-up" />
        </div>
      );
    if (p1 < p2)
      return (
        <div style={hcpiConfig.tableView.cellStyle.totalPoints(6)}>
          <i className="fas fa-arrow-down" />
        </div>
      );
  }

  return (
    <div>
      <SectionTitle
        title={
          <Intl
            message
            path="containers.contacts.providerContacts.tiles.painIndexTracker"
          />
        }
        logo={{ path: "provider", name: "providerDashboard" }}
      >
        <Pagination items={props.painData}>
          {props.painData.map((obj, i) => (
            <div key={obj._id}>
              <div
                className="card"
                style={{ backgroundColor: i % 2 === 1 ? "#f2f2f2" : "" }}
              >
                <div className="card-content" style={{ padding: 12 }}>
                  {/* Headers and Cells START */}
                  <div className="columns is-paddingless">
                    <div className="column">
                      <label className="label has-text-grey is-size-6">
                        {obj.userFullName}
                      </label>
                      <p className="is-size-6 has-text-dark">
                        <i className="far fa-envelope" /> {obj.userEmail}
                      </p>
                    </div>
                    <div className="column has-text-centered">
                      <label className="label has-text-grey is-size-6">
                        {props.messages.petName[props.locale]}
                      </label>
                      <p className="is-size-6 has-text-dark has-text-weight-bold">
                        {obj._pet.petName}
                      </p>
                    </div>
                    <div className="column has-text-centered">
                      <label className="label has-text-grey is-size-6">
                        {props.messages.change[props.locale]}
                      </label>
                      <div className="is-size-6">
                        {returnChangeIcon(
                          obj.stotalpoints1, // latestPoints
                          obj.stotalpoints2 // prevPoints
                        )}
                      </div>
                    </div>
                    <div className="column has-text-centered">
                      <label className="label has-text-grey is-size-6">
                        {moment(obj.created1).format("DD.MM.YYYY")}
                      </label>
                      <p
                        className="is-size-6 has-text-weight-bold"
                        style={hcpiConfig.tableView.cellStyle.totalPoints(
                          obj.stotalpoints1
                        )}
                      >
                        {obj.stotalpoints1}
                      </p>
                    </div>
                    <div className="column  has-text-centered">
                      <label className="label has-text-grey is-size-6">
                        {obj.created2 === null
                          ? props.messages.noData[props.locale]
                          : moment(obj.created2).format("DD.MM.YYYY")}
                      </label>
                      <div className="is-size-6 has-text-weight-bold">
                        {obj.created2 === null ? (
                          "-"
                        ) : (
                          <div
                            style={hcpiConfig.tableView.cellStyle.totalPoints(
                              obj.stotalpoints2
                            )}
                          >
                            {obj.stotalpoints2}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Headers and Cells END */}
                    {/* Buttons Start */}
                    <div className="column has-text-centered">
                      <label className="label has-text-grey is-size-6">
                        {props.messages.links[props.locale]}
                      </label>
                      <div className="buttons is-centered">
                        <div
                          className="button is-small is-rounded"
                          style={buttonStyle}
                          onClick={() =>
                            handleButtonClick(
                              obj._user,
                              obj._pet,
                              "/providerhome/contacts/contact/contacts_pet_dashboard/hcpi"
                            )
                          }
                        >
                          <figure className={"image is-24x24"}>
                            <img
                              src={assets.features.hcpi.main}
                              alt="hcpi_icon"
                            />
                          </figure>
                        </div>
                        <div
                          className="button is-small is-rounded"
                          style={buttonStyle}
                          onClick={() =>
                            handleButtonClick(
                              obj._user,
                              obj._pet,
                              "/providerhome/contacts/contact/contacts_pet_dashboard/appointments"
                            )
                          }
                        >
                          <figure className={"image is-24x24"}>
                            <img
                              src={assets.features.appointments.main}
                              alt="appointments_icon"
                            />
                          </figure>
                        </div>
                        <div
                          className="button is-small is-rounded"
                          style={buttonStyle}
                          onClick={() =>
                            handleButtonClick(
                              obj._user,
                              obj._pet,
                              "/providerhome/contacts/contact/contacts_pet_dashboard/tasks"
                            )
                          }
                        >
                          <figure className={"image is-24x24"}>
                            <img
                              src={assets.features.tasks.main}
                              alt="task_icon"
                            />
                          </figure>
                        </div>
                        <div
                          className="button is-small is-rounded"
                          style={buttonStyle}
                          onClick={() =>
                            handleButtonClick(
                              obj._user,
                              obj._pet,
                              "/providerhome/contacts/contact/contacts_pet_dashboard"
                            )
                          }
                        >
                          <figure className={"image is-24x24"}>
                            <img
                              src={assets.professionals.contactsPets}
                              alt="pets_dashboard_icon"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Buttons END */}
                </div>
              </div>
            </div>
          ))}
        </Pagination>
      </SectionTitle>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setCurrentContact,
      selectPet,
      dispatch,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(PainIndexDataView);
