import React from "react";
import moment from "moment";
import { features } from "assets";

const ReportMediaObject = ({
  messages,
  locale,
  report,
  refreshStatus,
  refreshStatusId,
  serverIsBusy,
}) => {
  return (
    <div className="card">
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <figure className="image is-64x64">
              <img
                src={features.reporting.reportTypes[report.reportType]}
                alt={report.reportType}
              />
            </figure>
          </div>
          <div className="media-content">
            <p className="title is-6 has-text-dark">
              {messages.reportTypes[report.reportType][locale]}
            </p>
          </div>
        </div>
        <div className="content">
          <p>
            <label className="label heading">
              {messages.reportCard.labels.dateCreated[locale]}
            </label>
            {moment(report.dateCreated).format("DD-MMM-YY")}
          </p>
          <p>
            <label className="label heading">
              {messages.reportCard.labels.datePeriod[locale]}
            </label>
            {moment(report.queryFromDate).format("DD-MMM-YY")}
            {" - "}
            {moment(report.queryToDate).format("DD-MMM-YY")}
          </p>
        </div>
        <div className="level is-mobile">
          <div className="level-item">
            <div
              className={
                "has-text-weight-bold " + textColour(report.lifeCycleState)
              }
            >
              {messages.reportCard.statuses[report.lifeCycleState][locale]}
            </div>
          </div>
          <div className="level-item">
            <button
              className="button"
              onClick={() => refreshStatus(report._id)}
            >
              <span className="icon">
                <i
                  className={
                    "fas fa-sync-alt " +
                    (serverIsBusy &&
                      report._id === refreshStatusId &&
                      "fa-pulse")
                  }
                />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const textColour = (state) => {
  switch (state) {
    case "inqueue":
      return "has-text-info";
    case "inprocess":
      return "has-text-primary";
    case "processed":
      return "has-text-success";
    default:
      return "has-text-dark";
  }
};

export default ReportMediaObject;
