import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class LitterHomeCare extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    petType: state.reducers.pets.selectedPet.petType,
    messages: {
      ...state.intl.messages.containers.features.homeCare,
      ...state.intl.messages.containers.litterFeatures.litterHomeCare,
      categories:
        state.intl.messages.components.forms.selectOptions.homeCareCategories,
      activities:
        state.intl.messages.components.forms.selectOptions.homeCareActivities,
    },
  };
};

export default connect(mapStateToProps)(LitterHomeCare);
