import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class TrainingDiary extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    messages: {
      ...state.intl.messages.containers.features.trainingDiary,
      trainingTypes:
        state.intl.messages.components.forms.selectOptions.trainingTypes,
      components: {
        chart: state.intl.messages.components.chart,
      },
    },
    trainingDiary: state.reducers.trainingDiary,
  };
};

export default connect(mapStateToProps)(TrainingDiary);
