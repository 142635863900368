import React from "react";
import assets from "assets";
import ProviderLogin from "./Provider_Login";
import ProviderRegister from "./Provider_Register";
import ForgotPassword from "containers/auth/Forgot_Password";

const petServicesPortalRoutes = [
  {
    name: "providerLogin",
    view: "petServicesPortal",
    tile: true,
    disabled: false,
    exact: true,
    path: "/pet_services_portal/login",
    component: ProviderLogin,
    img: {
      src: assets.auth.providerLogin,
      alt: "provider_login",
    },
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: false,
  },
  {
    name: "providerRegister",
    view: "petServicesPortal",
    tile: true,
    disabled: false,
    exact: true,
    path: "/pet_services_portal/register",
    component: ProviderRegister,
    img: {
      src: assets.auth.providerRegister,
      alt: "provider_register",
    },
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: false,
  },
  {
    name: "home",
    view: "petServicesPortal",
    tile: true,
    disabled: false,
    exact: true,
    path: "/",
    img: {
      src: assets.auth.back,
      alt: "landing_home",
    },
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: false,
  },
  {
    name: "forgotPassword",
    disabled: false,
    exact: true,
    path: "/pet_services_portal/forgot_password",
    component: () => <ForgotPassword provider />,
  },
];

export default petServicesPortalRoutes;
