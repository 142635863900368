import { InputFieldV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "retrievePetCode",
    group: "retrievePet",
    type: "text",
    icon: "fa fa-key",
    className: "input ",
    component: InputFieldV5,
    required: false,
  },
];

export { INPUT_FIELDS };
