import main from "./food_diary.svg"
import newEntry from "./food_diary_new_entry.svg"
import viewEntries from "./food_diary_view_entries.svg"
import foodCategories from "./food_categories"

const foodDiary = {
  main,
  newEntry,
  viewEntries,
  foodCategories,
}

export default foodDiary
