import React from "react"
import FeatureLink from "./Feature_Link"
import FeaturePromo from "./Feature_Promo"

function FeatureResource({
  img,
  title,
  promo,
  description,
  shortDesc,
  url,
  imageOnly,
  links,
}) {
  return (
    <div className="card">
      <div className="card-image">
        <div style={{ marginLeft: 50, marginRight: 50 }}>
          {!!url ? (
            <a rel="noopener noreferrer" href={url} target="_blank">
              <figure className="image">
                <img src={img} alt={`${title}_image`} />
              </figure>
            </a>
          ) : (
            <figure className="image">
              <img src={img} alt={`${title}_image`} />
            </figure>
          )}
        </div>
      </div>
      {!imageOnly && (
        <div className="card-content">
          <div className="content">
            <div className="title is-4 has-text-dark">{title}</div>
            <div className="subtitle is-6 has-text-dark">
              <a
                style={{ color: "grey" }}
                rel="noopener noreferrer"
                href={url}
                target="_blank"
              >
                {url}
              </a>
            </div>
            {!!promo && (
              <div style={{ padding: 10, marginBottom: 16 }}>
                <FeaturePromo title={promo} />
              </div>
            )}
            <p className="subtitle is-6 has-text-dark">{description}</p>
          </div>
          {links.length > 0 &&
            links.map((l, i) => (
              <div key={i} style={{ padding: 10, marginTop: 16 }}>
                <a rel="noopener noreferrer" href={l.url} target="_blank">
                  <FeatureLink {...l} />
                </a>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default FeatureResource
