import React from "react";
import inputValidationColor from "components/form/Input_Validation_Color";

export default function InputField({ className, type, field, disabled, form }) {
  return (
    <input
      className={`${className} ${inputValidationColor(
        field.name,
        form.touched,
        form.errors
      )}`}
      type={type}
      {...field}
      readOnly={disabled}
    />
  );
}
