import main from "./tips_and_guides.svg"
import textGuides from "./guides_tips.svg"
import videoGuides from "./guides_videos.svg"

const tipsAndGuides = {
  main,
  textGuides,
  videoGuides,
}

export default tipsAndGuides
