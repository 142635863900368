import React, { Component } from "react";
import Section from "components/layout/Section";
import SupportForm from "forms/Support_Form";

class Support extends Component {
  render() {
    return (
      <Section>
        <div className="columns is-mobile is-centered">
          <div className="column is-5-desktop is-full-mobile is-6-tablet">
            <SupportForm />
          </div>
        </div>
      </Section>
    );
  }
}

export default Support;
