import React from "react"
import styled from "styled-components"
import PartnerPageLink from "./Partner_Page_Link"
import Facebook from "components/facebook"
import AssociatePartnerPageView from "components/associates/Associate_Partner_Page_View"

const StyledView = styled.div`
  section {
    border-radius: 4px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.3) 40%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .partner-content,
  .associate-content {
    margin-top: 24px;
    padding-top: 16px;
  }

  .hero-body {
    border-radius: 4px;
    background-image: url(${props => props.backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .image-wrap {
    padding: 20px;
  }
`

const PartnerPage = ({ pageContent }) => {
  return (
    <StyledView
      backgroundImage={pageContent.fields.backgroundImage.fields.file.url}
    >
      <section className="hero is-medium">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="hero-body-content">
              <div className="title">{pageContent.fields.pageTitle}</div>
              <div className="subtitle is-6">
                {pageContent.fields.pageSubtitle}
              </div>
              <div className="image-wrap">
                <figure
                  className="image is-128x128 is-centered"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                >
                  <img
                    src={pageContent.fields.partnerLogo.fields.file.url}
                    alt="partner_logo"
                  />
                </figure>
              </div>
              <div className="subtitle">{pageContent.fields.pageTagline}</div>
            </div>
          </div>
        </div>
        <div className="container has-text-centered"></div>
      </section>
      <section className="partner-content">
        <div
          style={{ marginBottom: 44 }}
          className="columns is-centered has-text-centered"
        >
          <div className="column is-4-desktop">
            <div className="content">
              {pageContent.fields.externalLinksObject.links.map((link, i) => (
                <PartnerPageLink key={i} {...link} />
              ))}
            </div>
          </div>
          {pageContent.fields.publicFacebookPage && (
            <div className="column is-4-desktop">
              <Facebook
                type="page"
                page={pageContent.fields.publicFacebookPage}
                style={{}}
              />
            </div>
          )}
        </div>
      </section>
      {pageContent.fields.associateName && (
        <section className="associate-content">
          <AssociatePartnerPageView {...pageContent.fields} />
        </section>
      )}
    </StyledView>
  )
}

export default PartnerPage
