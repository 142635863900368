import axios from "axios"

import { FEATURE_RESOURCES_FETCH, ACTION_STARTED } from "./types"
import { catchBlockError, endAndResetActionWithDelay } from "./functions"

export const fetchFeatureResources = (feature) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: FEATURE_RESOURCES_FETCH })
    const res = await axios.get("/api/feature_resources/fetch", {
      headers: {
        authorization: localStorage.getItem("token"),
      },
      params: { feature },
    })
    dispatch({
      type: FEATURE_RESOURCES_FETCH,
      payload: { feature, featureResources: res.data.featureResources },
    })
    endAndResetActionWithDelay(dispatch, 2000)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const featureResourcesActions = {
  fetchFeatureResources,
}

export default featureResourcesActions
