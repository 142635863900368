import {
  PROVIDER_DELETES_CONTACT,
  PROVIDER_FETCH_CONTACTS,
  PROVIDER_SETS_CURRENT_CONTACT,
  PROVIDER_UNSETS_CURRENT_CONTACT,
  PROVIDER_UPDATES_CLIENT_DATA,
  USER_FETCH_CONTACTS,
} from "./actions";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";
import { AUTH_LOG_OUT_USER } from "actions/types";
import _ from "lodash";

const INITIAL_STATE = {
  pendingRequests: [],
  contacts: [],
  contactsFetched: false,
  selectedContact: {},
};

export default function Contacts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_FETCH_CONTACTS:
    case PROVIDER_FETCH_CONTACTS:
      return {
        ...state,
        pendingRequests: _.sortBy(
          action.payload.filter(
            (req) => req.lifeCycleState.status === "created"
          ),
          "lifeCycleState.createdDate"
        ),
        contacts: action.payload.filter(
          (req) => req.lifeCycleState.status === "accepted"
        ),
        contactsFetched: true,
      };
    case PROVIDER_SETS_CURRENT_CONTACT:
      return {
        ...state,
        selectedContact: action.payload,
      };
    case PROVIDER_UNSETS_CURRENT_CONTACT:
      return {
        ...state,
        selectedContact: {},
      };
    case PROVIDER_UPDATES_CLIENT_DATA:
      const i = state.contacts.findIndex((o) => o._id === action.payload._id);
      let updatedContacts = [...state.contacts];
      if (updatedContacts[i]) {
        updatedContacts[i] = action.payload;
      } else {
        updatedContacts.push(action.payload);
      }
      return {
        ...state,
        contacts: updatedContacts,
      };
    case PROVIDER_DELETES_CONTACT:
      return {
        ...state,
        contacts: state.contacts.filter((c) => c._id !== action.payload),
        selectedContact: {},
      };
    case AUTH_LOG_OUT_PROVIDER:
    case AUTH_LOG_OUT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
