import assets from "assets"
import UserContacts from "containers/contacts/User_Contacts"

const userContactsRoutes = [
  {
    name: "contacts",
    view: "userContacts",
    disabled: false,
    exact: true,
    path: "/userhome/contacts",
    component: UserContacts,
    img: {
      src: assets.professionals.main,
      alt: "user_contacts",
    },
    permaLink: false,
    breadcrumb: false,
    showIfAuthed: true,
  },
]

export default userContactsRoutes
