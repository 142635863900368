import { litterFeatures } from "assets"
import Landing from "components/features/Landing"
import AddExpense from "./Add_Expense_View"
import ViewExpenses from "./View_Expenses"
import ViewExpenseItem from "./View_Expense_Item"

const litterExpensesConfig = {
  name: "litterExpenses",
  type: "litterFeature",
  defaultRoute: "/userhome/dashboard/litter/expenses",
  menuIcon: {
    img: litterFeatures.expenses,
    alt: "litter_expenses_icon",
  },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: litterFeatures.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "addExpense",
      menuItem: true,
      image: {
        img: litterFeatures.expensesNewEntry,
        alt: "litter_expenses_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: AddExpense,
      path: "/add_expense",
    },
    {
      disabled: false,
      name: "viewExpenses",
      menuItem: true,
      image: {
        img: litterFeatures.expensesViewEntries,
        alt: "litter_expenses_view_entries_icon",
      },
      icon: "far fa-plus-square",
      component: ViewExpenses,
      path: "/view_expenses",
    },
    {
      name: "viewExpenseItem",
      disabled: false,
      menuItem: false,
      component: ViewExpenseItem,
      path: "/view_item",
    },
  ],
}

export default litterExpensesConfig
