import React from "react";
import { Link } from "react-router-dom";
import LoginForm from "./forms/Login";
import FormWrapper from "components/layout/Form_Wrapper";
import BoxWithBackground from "components/styled/Box_With_Background";
import Intl from "components/Intl";

function ProviderLogin(props) {
  return (
    <FormWrapper>
      <BoxWithBackground squareBorder>
        <br />
        <LoginForm {...props} defaultRoute={"/pet_services_portal"} />
        <br />
        <p className="heading has-text-centered has-text-link has-text-weight-bold">
          <Link to={"/pet_services_portal/forgot_password"}>
            <Intl
              message
              path="components.forms.authLinks.login.forgotPassword"
            />
          </Link>
        </p>
        <p className="heading has-text-centered has-text-link has-text-weight-bold">
          <Link to={"/pet_services_portal/register"}>
            <Intl
              message
              path="components.forms.authLinks.login.notYetRegistered"
            />
          </Link>
        </p>
      </BoxWithBackground>
    </FormWrapper>
  );
}

export default ProviderLogin;
