import React, { useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import Section from "components/layout/Section"
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons"
import HomeCareEntryForm from "../forms/Home_Care_Entry_Form"
import FormWrapper from "components/layout/Form_Wrapper"
import { MODALS_SHOW, MODALS_CONFIRM_DELETE } from "actions/types"
import { HOME_CARE_DELETE } from "../actions"

function ViewEntryView(props) {
  const {
    history,
    homeCare,
    match,
    defaultRoute,
    dispatch,
    providerView,
    providerEdit,
  } = props
  const { id } = match.params
  const { entries } = homeCare

  const successRoute = `${defaultRoute}/view_entries`

  const [valuesToEdit, setValuesToEdit] = useState({})
  const [viewMode, toggleViewMode] = useState(true)

  const returnToEntriesView = useCallback(() => {
    history.push(successRoute)
  }, [history, successRoute])

  useEffect(() => {
    function findEntryAndStoreInState() {
      const entry = entries.filter((e) => e._id === id)[0]
      if (!entry) return returnToEntriesView()
      setValuesToEdit(entry)
    }
    findEntryAndStoreInState()
  }, [entries, id, returnToEntriesView])

  function handleCancelClick() {
    toggleViewMode(!viewMode)
    returnToEntriesView()
  }

  function handleDeleteClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "homeCare",
        actionType: HOME_CARE_DELETE,
        history,
        successRoute,
        providerView,
        item: valuesToEdit._id,
      },
    })
  }

  const renderSectionContent = () => {
    if (_.isEmpty(valuesToEdit)) return <div />
    return (
      <>
        <BackEditDeleteButtons
          handleEditClick={() => toggleViewMode(!viewMode)}
          handleDeleteClick={handleDeleteClick}
          viewMode={viewMode}
          providerView={providerView}
          providerEdit={providerEdit}
        />
        <FormWrapper>
          <HomeCareEntryForm
            editMode
            viewMode={viewMode}
            valuesToEdit={valuesToEdit}
            cancel={() => handleCancelClick()}
            {...props}
          />
        </FormWrapper>
      </>
    )
  }

  return <Section>{renderSectionContent()}</Section>
}

export default withRouter(ViewEntryView)
