import React, { useEffect } from "react";
import Section from "components/layout/Section";
import FetchAgain from "../components/FetchAgain";
import PopularTags from "../components/PopularTags";
import PriorityTags from "../components/PriorityTags";
import Post from "../components/Post";

function LatestPosts(props) {
  const {
    blog,
    blogUrl,
    priorityTags,
    fetchAllTags,
    fetchPostsLatest,
    locale,
    handleFetchPostsByTag,
    history,
    messages,
  } = props;

  useEffect(() => {
    if (!blog) {
      fetchAllTags(blogUrl);
      fetchPostsLatest(blogUrl);
    }
  }, [blog, blogUrl, fetchAllTags, fetchPostsLatest]);

  return (
    <Section>
      {!blog ? (
        <FetchAgain />
      ) : (
        blog && (
          <>
            <PriorityTags
              title={messages.sectionTitles.priorityTags[locale]}
              tags={priorityTags}
              blogUrl={blogUrl}
              onClick={handleFetchPostsByTag}
              history={history}
            />
            <PopularTags
              title={messages.sectionTitles.popularTags[locale]}
              tags={blog.tags.slice(0, 10)}
              blogUrl={blogUrl}
              onClick={handleFetchPostsByTag}
              history={history}
            />
            <div className="title is-6 has-text-grey">
              {messages.sectionTitles.latestPosts[locale]}
            </div>
            {blog.entries.map((e) => {
              return <Post key={e.id} post={e} excerpt {...props} />;
            })}
          </>
        )
      )}
    </Section>
  );
}

export default LatestPosts;
