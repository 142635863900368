import React, { Component } from "react";
import FoodCategorySelection from "containers/features/Food_Diary/Food_Category_Selection";
import LitterFoodDiaryEntryForm from "forms/Litter_Food_Diary_Entry_Form";
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons";
import RequireSubFeature from "components/hoc/Require_Sub_Feature";

class NewEntry extends Component {
  state = { stage: 1 };

  handleCategorySelection = (cat) => {
    this.setState({
      stage: 2,
      foodCategory: cat,
    });
  };

  handleBackToCategorySelection = () => {
    this.setState({
      stage: 1,
      foodCategory: "",
    });
  };

  renderStage = () => {
    const { foodCategory } = this.state;
    switch (this.state.stage) {
      case 1:
        return (
          <div style={{ paddingTop: 30 }}>
            <FoodCategorySelection
              {...this.props}
              handleCategorySelection={this.handleCategorySelection}
              handleBackToCategorySelection={this.handleBackToCategorySelection}
            />
          </div>
        );
      case 2:
        return (
          <div className="columns is-mobile is-centered">
            <div className="column is-5-desktop is-full-mobile is-6-tablet has-text-centered">
              <BackEditDeleteButtons
                backButtonFunc
                handleBackClick={this.handleBackToCategorySelection}
                disableEditButton
                disableDeleteButton
              />

              <LitterFoodDiaryEntryForm
                valuesToEdit={{}}
                foodCategory={foodCategory}
                {...this.props}
              />
            </div>
          </div>
        );
      default:
      // do nothing
    }
  };

  render() {
    return this.renderStage();
  }
}

export default RequireSubFeature(NewEntry);
