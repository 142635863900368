import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "forms/fields/add_litter";
import { FormTitle, FormButtons } from "components/form";

class AddLitterForm extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dirty && nextProps.cancelledByUser) {
      this.props.resetForm();
    }
  }

  renderFields() {
    const { viewMode, editMode, pet, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          viewMode={viewMode}
          editMode={editMode}
          values={values}
          pet={pet}
          {...field}
        />
      );
    });
  }

  render() {
    const { errors, dirty, isSubmitting, editMode, viewMode, cancel } =
      this.props;

    return (
      <Form>
        <FormTitle group={"addLitter"} />
        <br />
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={"/userhome/dashboard/litters"}
        />
        <br />
      </Form>
    );
  }
}

AddLitterForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet, editMode }) {
    return {
      litterName: valuesToEdit.litterName || "",
      litterBreed: valuesToEdit.litterBreed || "",
      litterDob: moment(valuesToEdit.litterDob).format("YYYY-MM-DD") || "",
      litterMotherGivenName: editMode
        ? valuesToEdit.litterMotherGivenName
        : pet.petGivenName,
      litterMotherLink: valuesToEdit.litterMotherLink || "",
      litterFatherGivenName: valuesToEdit.litterFatherGivenName || "",
      litterFatherLink: valuesToEdit.litterFatherLink || "",
      litterNotes: valuesToEdit.litterNotes || "",
      litterType: valuesToEdit.litterType || pet.petType,
      _id: valuesToEdit._id || "",
      _pet: valuesToEdit._pet || pet._id,
    };
  },
  validationSchema: Yup.object().shape({
    litterName: Yup.string().required("required"),
    litterBreed: Yup.string().required("required"),
    litterDob: Yup.date().required("required"),
    litterMotherGivenName: Yup.string(),
    litterMotherLink: Yup.string().url("url"),
    litterFatherGivenName: Yup.string(),
    litterFatherLink: Yup.string().url("url"),
    litterNotes: Yup.string().max(500, "max-500"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    if (props.editMode) {
      // Check for changes to Breed
      const updateToBreed =
        values.litterBreed !== props.valuesToEdit.litterBreed;

      // Check for changes to DoB
      const updateToDob =
        values.litterDob !==
        moment(props.valuesToEdit.litterDob).format("YYYY-MM-DD");

      return props.updateLitter(
        values,
        updateToBreed,
        updateToDob,
        setSubmitting
      );
    }
    return props.addLitter(values, setSubmitting);
  },
})(AddLitterForm);

export default AddLitterForm;
