import React from "react";
import { features, main } from "assets";
import FeatureTitleCard from "./FeatureTitleCard";
import DownloadLink from "./DownloadLink";

const animalHospitalConfig = {
  name: "animalHospital",
  type: "feature",
  defaultRoute: "/userhome/dashboard/animal_hospital",
  menuIcon: {
    img: features.animalHospital.main,
    alt: "animal_hospital_main_icon",
  },
  nav: [
    {
      disabled: false,
      name: "landing",
      pageInfo: ["info_p1"],
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: false,
      exact: true,
      component: (props) => (
        <FeatureTitleCard
          {...props}
          useMedia
          useLogo
          logo={features.animalHospital.logo}
        />
      ),
      path: "/",
    },
    {
      disabled: false,
      name: "externalLink",
      pageInfo: ["info_p1"],
      menuItem: true,
      image: {
        img: main.externalLink,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: false,
      exact: true,
      component: (props) => (
        <FeatureTitleCard
          {...props}
          useMedia
          useLogo
          logo={features.animalHospital.logo}
        >
          <DownloadLink {...props} />
        </FeatureTitleCard>
      ),
      path: "/link",
    },
  ],
  animalHospitalLinks: {
    dog: "smallAnimals",
    cat: "smallAnimals",
    other: "smallAnimals",
    horse: "horses",
  },
  links: {
    smallAnimals: {
      url: "https://www.helsinki.fi/fi/yliopistollinen-elainsairaala/ajanvaraus#section-47444",
    },
    horses: {
      url: "https://www.helsinki.fi/fi/yliopistollinen-elainsairaala/ajanvaraus#section-47445",
    },
  },
};

export default animalHospitalConfig;
