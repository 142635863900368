import React, { useState } from "react"
import { connect } from "react-redux"
import { litters as actions } from "actions/index"
import Section from "components/layout/Section"
import AddLitterForm from "forms/Add_Litter_Form"
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons"
import FormWrapper from "components/layout/Form_Wrapper"

function ProfileDetails(props) {
  // Props deconstructed
  const { litter, litterHasMembers } = props

  // Use State
  const [viewMode, setViewMode] = useState(true)
  const [cancelledByUser, setCancelledByUser] = useState(false)

  // Functions
  function handleCancelClick() {
    setCancelledByUser(true)
    setViewMode(true)
  }

  function handleSetViewMode() {
    setCancelledByUser(false)
    setViewMode(false)
  }

  function onUpdateSuccess() {
    const { history } = props
    const successRoute = "/userhome/dashboard/litter/profile/details"

    setCancelledByUser(false)
    setViewMode(true)
    history.push(successRoute)
  }

  function handleUpdateLitter(
    values,
    updateToBreed,
    updateToDob,
    setSubmitting
  ) {
    const { updateLitter } = props
    updateLitter(
      values,
      { litterHasMembers, updateToBreed, updateToDob },
      setSubmitting,
      () => onUpdateSuccess()
    )
  }

  // Main Render
  return (
    <>
      <Section>
        <BackEditDeleteButtons
          disableBackButton
          disableDeleteButton
          handleEditClick={handleSetViewMode}
          viewMode={viewMode}
        />
        <FormWrapper>
          <AddLitterForm
            cancelledByUser={cancelledByUser}
            valuesToEdit={litter}
            editMode
            viewMode={viewMode}
            cancel={handleCancelClick}
            updateLitter={handleUpdateLitter}
          />
        </FormWrapper>
      </Section>
    </>
  )
}

export default connect(null, actions)(ProfileDetails)
