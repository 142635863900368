import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./actions";
import Menu from "components/menu";
import PROVIDER_CONFIG from "./provider_config";

class Appointments extends Component {
  render() {
    return <Menu {...PROVIDER_CONFIG} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    provider: state.reducers.auth.provider,
    providerContact: {
      _id: state.reducers.contacts.selectedContact._user._id,
      ...state.reducers.contacts.selectedContact._user.userInfo,
    },
    messages: {
      ...state.intl.messages.containers.features.appointments,
      labels: {
        ...state.intl.messages.components.appointment.labels,
        ...state.intl.messages.components.forms.labels.common,
        ...state.intl.messages.components.forms.labels.appointments,
      },
      statuses: state.intl.messages.components.appointment.statuses,
    },
    appointments: state.reducers.appointments,
  };
};

export default connect(mapStateToProps, actions)(Appointments);
