import React, { useEffect } from "react";
import { connect } from "react-redux";
import WEIGHT_INPUT_VALUES from "constants/fine_weight_control";
import inputValidationColor from "./Input_Validation_Color";
import FormValidationMessageV5 from "./FormValidationMessageV5";

function LitterMemberBirthWeight(props) {
  const {
    form: { setFieldValue, values, touched, errors },
    field,
    viewMode,
    className,
    weightUnit,
    editMode,
    userCanEdit,
    readOnly,
    label,
    group,
    form,
    icon,
  } = props;

  useEffect(() => {
    let lbs =
      (values.litterMemberBirthWeightKg * 1000 +
        values.litterMemberBirthWeightG) /
      454;
    setFieldValue("litterMemberBirthWeightLbs", Number(lbs.toFixed(0)));
  }, [
    weightUnit,
    setFieldValue,
    values.litterMemberBirthWeightKg,
    values.litterMemberBirthWeightG,
  ]);

  return (
    <div className="field">
      <label className="label is-small">
        <div className="heading has-text-grey">{label}</div>
      </label>
      <div className="control has-icons-left has-icons-right">
        <input
          {...field}
          type={"number"}
          readOnly={
            viewMode || readOnly || (!userCanEdit && editMode) ? true : false
          }
          className={
            viewMode
              ? className
              : `${className} ${inputValidationColor(
                  field.name,
                  form.touched,
                  form.errors
                )}`
          }
          step={WEIGHT_INPUT_VALUES.litterMember[field.name].step}
          min={WEIGHT_INPUT_VALUES.litterMember[field.name].min}
          max={WEIGHT_INPUT_VALUES.litterMember[field.name].max}
        />
        <span className="icon is-small is-left">
          <i className={icon} />
        </span>
      </div>
      <FormValidationMessageV5
        clientMessage
        group={group}
        name={field.name}
        errors={errors}
        touched={touched}
      />
    </div>
  );
}

const mapStateToProps = (state, { group, field }) => {
  const { locale, messages } = state.intl;
  return {
    label: messages.components.forms.labels[group][field.name][locale],
  };
};

export default connect(mapStateToProps)(LitterMemberBirthWeight);
