import { InputFieldV5, TextareaV5, UrlInputLinkV5 } from "components/form";

export const INPUT_FIELDS = [
  {
    name: "taskDueDate",
    group: "tasks",
    type: "date",
    icon: "fa fa-calendar",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
  },
  {
    name: "taskDueTime",
    group: "tasks",
    type: "time",
    icon: "fa fa-clock",
    className: "input ",
    component: InputFieldV5,
    required: false,
    userCanEdit: true,
  },
  {
    name: "taskTitle",
    group: "tasks",
    type: "text",
    icon: "fas fa-comment",
    className: "input ",
    component: InputFieldV5,
    required: true,
    userCanEdit: true,
    charLimit: 50,
  },
  {
    name: "taskDescription",
    group: "tasks",
    type: "text",
    className: "textarea",
    component: TextareaV5,
    rows: 5,
    required: true,
    userCanEdit: true,
    charLimit: 500,
  },
  {
    name: "taskAttachmentURL",
    group: "tasks",
    type: "url",
    icon: "fas fa-link",
    className: "input ",
    component: UrlInputLinkV5,
    required: false,
    userCanEdit: true,
  },
];
