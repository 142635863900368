import React from 'react';
import CatLowerIncisors from "../components/CatLowerIncisors";
import CatUpperIncisors from "../components/CatUpperIncisors";
import DogLowerIncisors from "../components/DogLowerIncisors";
import DogUpperIncisors from "../components/DogUpperIncisors";

export default function ToothConfirmation(props) {
  const { petType, toothId, ...rest } = props;
  switch (true) {
    case petType === "cat" && toothId === "_301":
    case petType === "cat" && toothId === "_302":
    case petType === "cat" && toothId === "_303":
    case petType === "cat" && toothId === "_401":
    case petType === "cat" && toothId === "_402":
    case petType === "cat" && toothId === "_403":
      return <CatLowerIncisors {...rest} />;
    case petType === "cat" && toothId === "_101":
    case petType === "cat" && toothId === "_102":
    case petType === "cat" && toothId === "_103":
    case petType === "cat" && toothId === "_201":
    case petType === "cat" && toothId === "_202":
    case petType === "cat" && toothId === "_203":
      return <CatUpperIncisors {...rest} />;
    case petType === "dog" && toothId === "_301":
    case petType === "dog" && toothId === "_302":
    case petType === "dog" && toothId === "_303":
    case petType === "dog" && toothId === "_401":
    case petType === "dog" && toothId === "_402":
    case petType === "dog" && toothId === "_403":
      return <DogLowerIncisors {...rest} />;
    case petType === "dog" && toothId === "_101":
    case petType === "dog" && toothId === "_102":
    case petType === "dog" && toothId === "_103":
    case petType === "dog" && toothId === "_201":
    case petType === "dog" && toothId === "_202":
    case petType === "dog" && toothId === "_203":
      return <DogUpperIncisors {...rest} />;
    default:
      return null;
  }
}
