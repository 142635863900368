import axios from "axios";
import {
  ACTION_STARTED,
  ACTION_SUCCESS,
  ACTION_ERROR,
  ACTION_ENDED,
} from "actions/types";
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "actions/functions";

export const AUTH_REGISTER_PROVIDER = "auth_register_provider";
export const AUTH_LOG_IN_PROVIDER = "auth_log_in_provider";
export const AUTH_LOG_OUT_PROVIDER = "auth_log_out_provider";
export const AUTH_FETCH_CURRENT_PROVIDER = "auth_fetch_current_provider";
export const AUTH_REQUEST_PASSWORD_RESET_CODE_PROVIDER =
  "auth_request_password_reset_code_provider";
export const AUTH_RESET_PASSWORD_PROVIDER = "auth_reset_password_provider";
export const AUTH_CHANGE_PASSWORD_PROVIDER = "auth_change_password_provider";
export const AUTH_PROVIDER_UPDATE_INFO = "auth_provider_update_info";
export const AUTH_PROVIDER_UPDATE_PERMISSIONS = "auth_provider_update_permissions";

const API = (route) => `/api/auth/provider/${route}`;

export const registerProvider =
  (values, setSubmitting, resetForm, history) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AUTH_REGISTER_PROVIDER });
      const res = await axios.post(API("signup"), values);
      localStorage.setItem("tokenProvider", res.data.token);
      dispatch({ type: AUTH_LOG_IN_PROVIDER });
      history.push("/providerhome");
      endAndResetAction(dispatch);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const logInProvider =
  (values, setSubmitting, history) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AUTH_LOG_IN_PROVIDER });
      const res = await axios.post(API("signin"), values);
      localStorage.setItem("tokenProvider", res.data.token);
      dispatch({ type: AUTH_LOG_IN_PROVIDER });
      history.push("/providerhome");
      endAndResetAction(dispatch);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const requestResetPasswordCodeProvider =
  (values, setSubmitting, setNextStage) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: AUTH_REQUEST_PASSWORD_RESET_CODE_PROVIDER,
      });
      await axios.post(API("request_reset_password_code"), values);
      dispatch({ type: ACTION_ENDED });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "requestResetPasswordCodeSuccess",
      });
      setNextStage();
      endAndResetActionWithDelay(dispatch, 2000);
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({
            type: ACTION_ERROR,
            payload: "emailNotFound",
          });
        } else {
          dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
        }
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      endAndResetActionWithDelay(dispatch, 3000);
    }
  };

export const resetPasswordProvider =
  (values, setSubmitting, resetForm, history) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AUTH_RESET_PASSWORD_PROVIDER });
      await axios.post(API("reset_password"), values);
      dispatch({ type: ACTION_ENDED });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "passwordChangeSuccess",
      });
      dispatch({ type: AUTH_LOG_OUT_PROVIDER });
      history.push("/pet_services_portal/login");
      endAndResetActionWithDelay(dispatch, 3500);
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({
            type: ACTION_ERROR,
            payload: "invalidResetPasswordCode",
          });
        } else {
          dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
        }
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      dispatch({ type: ACTION_ENDED });
      endAndResetActionWithDelay(dispatch, 3500);
    }
  };

export const changePasswordProvider =
  (values, setSubmitting, resetForm, history) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: AUTH_CHANGE_PASSWORD_PROVIDER,
      });
      await axios.post(API("change_password"), values, {
        headers: { authorization: localStorage.getItem("tokenProvider") },
      });
      dispatch({ type: ACTION_ENDED });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "passwordChangeSuccess",
      });
      dispatch({ type: AUTH_LOG_OUT_PROVIDER });
      history.push("/pet_services_portal/login");
      endAndResetActionWithDelay(dispatch, 3500);
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch({
            type: ACTION_ERROR,
            payload: "changePasswordMismatch",
          });
        } else {
          dispatch({ type: ACTION_ERROR, payload: error.response.data.error });
        }
      } else {
        dispatch({
          type: ACTION_ERROR,
          payload: "somethingWentWrong",
        });
      }
      resetForm();
      dispatch({ type: ACTION_ENDED });
      endAndResetActionWithDelay(dispatch, 3500);
    }
  };

export const updateProviderInfo =
  (values, setSubmitting) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: AUTH_PROVIDER_UPDATE_INFO });
      const res = await axios.put(API("update_info"), values, {
        headers: { authorization: localStorage.getItem("tokenProvider") },
      });
      dispatch({
        type: AUTH_FETCH_CURRENT_PROVIDER,
        payload: res.data.provider,
      });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "updateSuccess",
      });
      setSubmitting(false);
      endAndResetActionWithDelay(dispatch, 1500);
    } catch (error) {
      setSubmitting(false);
      catchBlockError(error, dispatch);
    }
  };

export const updateProviderPermissions =
  (values, setIsLoading) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: AUTH_PROVIDER_UPDATE_PERMISSIONS,
      });
      const res = await axios.put(API("update_permissions"), values, {
        headers: { authorization: localStorage.getItem("tokenProvider") },
      });
      dispatch({
        type: AUTH_FETCH_CURRENT_PROVIDER,
        payload: res.data.provider,
      });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "updateSuccess",
      });
      setIsLoading(false);
      endAndResetActionWithDelay(dispatch, 1500);
    } catch (error) {
      setIsLoading(false);
      catchBlockError(error, dispatch);
    }
  };

const petServicesAuthActions = {
  registerProvider,
  logInProvider,
  requestResetPasswordCodeProvider,
  resetPasswordProvider,
  changePasswordProvider,
  updateProviderInfo,
  updateProviderPermissions,
};

export default petServicesAuthActions;
