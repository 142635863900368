import { returnSiteLogo } from "functions";
import tassuApuLogo from "./logo_tassuapu.svg";
import pawesomerLogo from "./logo_pawesomer.svg";
import dashboard from "./dashboard.svg";
import feedbackAndSupport from "./feedback_and_support.svg";
import help from "./feedback_and_support_help.svg";
import support from "./feedback_and_support_support.svg";
import feedback from "./feedback_and_support_feedback.svg";
import home from "./my_pets.svg";
import paw from "./logo_paw.svg";
import litter from "./litter.svg";
import litterProfile from "./litter_profile.svg";
import infoMenuIcon from "./info.svg";
import placeholder from "assets/main/placeholder.svg";
import resources from "./resources.svg";
import blogMain from "./blogs.svg";
import blogSearch from "./blog_search.svg";
import externalLink from "./external_link.svg";
import advertisements from "./ads_tile_logo.svg";
import benefits from "./benefits.svg";
import benefitsPopup from "./benefits_popup.svg";
import edit from "./edit.svg";

const main = {
  siteLogo: returnSiteLogo(tassuApuLogo, pawesomerLogo),
  dashboard,
  feedbackAndSupport,
  help,
  support,
  feedback,
  home,
  paw,
  litter,
  litterProfile,
  infoMenuIcon,
  placeholder,
  resources,
  blogMain,
  blogSearch,
  externalLink,
  advertisements,
  benefits,
  benefitsPopup,
  edit,
};

export default main;
