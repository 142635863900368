import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "actions/auth";
import Notification from "components/layout/Notification";
import Button from "components/elements/Button";

function _isButtonSelected(state, btnName) {
  if (state === btnName) {
    return "is-primary";
  } else {
    return "";
  }
}

function WeightUnitSelector(props) {
  const { messages, locale, userPreferredUnit, updateWeightUnitPreference } =
    props;

  const [selectedUnit, setSelectedUnit] = useState(userPreferredUnit);

  function handleUnitSelection(unit) {
    updateWeightUnitPreference(unit);
    setSelectedUnit(unit);
  }

  return (
    <Notification>
      <div className="title is-6 has-text-dark">
        {messages.featurePreferences.title[locale]}
      </div>
      <div className="subtitle is-7 has-text-dark">
        {messages.featurePreferences.subtitle[locale]}
      </div>
      <label className="label is-size-7">
        {messages.featurePreferences.weightUnit[locale]}
      </label>
      <div className="box" style={{ padding: 8 }}>
        <div className="columns is-mobile is-paddingless">
          <div className="column">
            <Button
              name="kg"
              group="weight"
              onClick={() => handleUnitSelection("kg")}
              className={`is-fullwidth ${_isButtonSelected(
                selectedUnit,
                "kg"
              )}`}
            />
          </div>
          <div className="column">
            <Button
              name="lbs"
              group="weight"
              onClick={() => handleUnitSelection("lbs")}
              className={`is-fullwidth ${_isButtonSelected(
                selectedUnit,
                "lbs"
              )}`}
            />
          </div>
        </div>
      </div>
    </Notification>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      featurePreferences:
        state.intl.messages.containers.auth.userAccount.featurePreferences,
    },
    userPreferredUnit: state.reducers.auth.user.featurePreferences.weightUnit,
  };
};

export default connect(mapStateToProps, actions)(WeightUnitSelector);
