import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../actions";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { INPUT_FIELDS } from "./verify_email_fields";
import { FormTitle } from "components/form";

class VerifyEmailForm extends Component {
  renderFields() {
    return INPUT_FIELDS.map((field, i) => {
      return <Field key={i} autocomplete={false} {...field} />;
    });
  }

  handleResendEmailClick = (e) => {
    e.preventDefault();
    this.props.resendVerifyEmailProvider(this.props.locale);
  };

  render() {
    const { errors, dirty, isSubmitting, locale } = this.props;
    const { buttons } = this.props.messages;

    return (
      <>
        <Form autoComplete={"off"}>
          <FormTitle group={"verifyEmail"} />
          {this.renderFields()}
          <br />
          <div className="buttons is-centered">
            <Link to="/userhome/logout" className="button">
              {buttons.cancel[locale]}
            </Link>
            <button
              disabled={isSubmitting}
              className={`button ${
                !_.isEmpty(errors)
                  ? "is-danger"
                  : _.isEmpty(errors) && dirty
                  ? "is-success"
                  : "is-info"
              } ${isSubmitting && "is-loading"}`}
              type="submit"
            >
              {buttons.verifyEmailCode[locale]}
            </button>
          </div>
        </Form>
        <br />
        <div className="buttons is-centered">
          <button
            disabled={isSubmitting}
            onClick={(e) => this.handleResendEmailClick(e)}
            className="button"
          >
            {buttons.resendVerifyEmailCode[locale]}
          </button>
        </div>
        <br />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      buttons: {
        ...state.intl.messages.components.buttons.auth,
        ...state.intl.messages.components.buttons.common,
      },
    },
  };
};

VerifyEmailForm = withFormik({
  mapPropsToValues() {
    return {
      verifyEmailCode: "",
    };
  },
  validationSchema: Yup.object().shape({
    verifyEmailCode: Yup.string()
      .required("required")
      .min(8, "verifyEmailCodeLength")
      .max(8, "verifyEmailCodeLength")
      .trim(),
  }),
  handleSubmit(values, { props, resetForm }) {
    props.verifyEmailProvider(
      { verifyEmailCode: values.verifyEmailCode.trim() },
      resetForm
    );
  },
})(VerifyEmailForm);

export default connect(mapStateToProps, actions)(VerifyEmailForm);
