import main from "./weight.svg";
import calorieCalc from "./weight_calorie_calculator.svg";
import bodyConditionScore from "./weight_body_condition_score.svg";
import newEntry from "./weight_new_entry.svg";
import viewEntries from "./weight_view_entries.svg";
import bcs from "./bcs";

const weight = {
  main,
  calorieCalc,
  bodyConditionScore,
  newEntry,
  viewEntries,
  bcs,
};

export default weight;
