import assets from "assets";

function pathHelper(group, key) {
  return `containers.main.helpMenu.${group}.${key}`;
}

export const HELP_MENU_STRUCTURE = [
  {
    label: pathHelper("title", "general"),
    nodes: [
      {
        label: pathHelper("title", "redeemingACode"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "redeemingACode"),
          },
          {
            type: "text",
            path: pathHelper("text", "redeemingACode_1"),
          },
          {
            type: "bullet",
            number: 1,
            path: pathHelper("bullet", "redeemingACode_1"),
            icon: assets.user.main,
          },
          {
            type: "bullet",
            number: 2,
            path: pathHelper("bullet", "redeemingACode_2"),
            icon: assets.user.redeemCode,
          },
          {
            type: "bullet",
            number: 3,
            path: pathHelper("bullet", "redeemingACode_3"),
          },
          {
            type: "bullet",
            number: 4,
            path: pathHelper("bullet", "redeemingACode_4"),
          },
        ],
      },
      {
        label: pathHelper("title", "howToUseAsAnApp"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "howToUseAsAnApp"),
          },
          {
            type: "text",
            path: pathHelper("text", "howToUseAsAnApp_1"),
          },
          {
            type: "label",
            path: pathHelper("label", "howToUseAsAnApp_1"),
          },
          {
            type: "link",
            path: pathHelper("link", "howToUseAsAnApp_1"),
          },
          {
            type: "label",
            path: pathHelper("label", "howToUseAsAnApp_2"),
          },
          {
            type: "link",
            path: pathHelper("link", "howToUseAsAnApp_2"),
          },
        ],
      },
      {
        label: pathHelper("title", "howDoWeSupportVeterinaryResearch"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "howDoWeSupportVeterinaryResearch"),
          },
          {
            type: "text",
            path: pathHelper("text", "howDoWeSupportVeterinaryResearch_1"),
          },
          {
            type: "text",
            path: pathHelper("text", "howDoWeSupportVeterinaryResearch_2"),
          },
        ],
      },
      {
        label: pathHelper("title", "onWhatDevicesDoesItWork"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "onWhatDevicesDoesItWork"),
          },
          {
            type: "text",
            path: pathHelper("text", "onWhatDevicesDoesItWork_1"),
          },
          {
            type: "text",
            path: pathHelper("text", "onWhatDevicesDoesItWork_2"),
          },
          {
            type: "label",
            path: pathHelper("label", "onWhatDevicesDoesItWork_1"),
          },
          {
            type: "link",
            path: pathHelper("link", "onWhatDevicesDoesItWork_1"),
          },
          {
            type: "label",
            path: pathHelper("label", "onWhatDevicesDoesItWork_2"),
          },
          {
            type: "link",
            path: pathHelper("link", "onWhatDevicesDoesItWork_2"),
          },
        ],
      },
    ],
  },
  {
    label: pathHelper("title", "account"),
    nodes: [
      {
        label: pathHelper("title", "registration"),
        nodes: [
          {
            label: pathHelper("title", "registrationUsingGoogle"),
            nodes: null,
            info: [
              {
                type: "title",
                path: pathHelper("title", "registrationUsingGoogle"),
              },
              {
                type: "bullet",
                number: 1,
                path: pathHelper("bullet", "registrationUsingGoogle_1"),
              },
              {
                type: "bullet",
                number: 2,
                path: pathHelper("bullet", "registrationUsingGoogle_2"),
              },
              {
                type: "bullet",
                number: 3,
                path: pathHelper("bullet", "registrationUsingGoogle_3"),
              },
              {
                type: "bullet",
                number: 4,
                path: pathHelper("bullet", "registrationUsingGoogle_4"),
              },
              {
                type: "bullet",
                number: 5,
                path: pathHelper("bullet", "registrationUsingGoogle_5"),
              },
              {
                type: "bullet",
                number: 6,
                path: pathHelper("bullet", "registrationUsingGoogle_6"),
              },
            ],
          },
          {
            label: pathHelper("title", "registrationUsingEmail"),
            nodes: null,
            info: [
              {
                type: "title",
                path: pathHelper("title", "registrationUsingEmail"),
              },
              {
                type: "bullet",
                number: 1,
                path: pathHelper("bullet", "registrationUsingEmail_1"),
              },
              {
                type: "bullet",
                number: 2,
                path: pathHelper("bullet", "registrationUsingEmail_2"),
              },
              {
                type: "bullet",
                number: 3,
                path: pathHelper("bullet", "registrationUsingEmail_3"),
              },
              {
                type: "bullet",
                number: 4,
                path: pathHelper("bullet", "registrationUsingEmail_4"),
              },
              {
                type: "bullet",
                number: 5,
                path: pathHelper("bullet", "registrationUsingEmail_5"),
              },
              {
                type: "bullet",
                number: 6,
                path: pathHelper("bullet", "registrationUsingEmail_6"),
              },
            ],
          },
          {
            label: pathHelper("title", "registerAsAServiceProvider"),
            nodes: null,
            info: [
              {
                type: "title",
                path: pathHelper("title", "registerAsAServiceProvider"),
              },
              {
                type: "bullet",
                number: 1,
                path: pathHelper("bullet", "registerAsAServiceProvider_1"),
              },
              {
                type: "bullet",
                number: 2,
                path: pathHelper("bullet", "registerAsAServiceProvider_2"),
              },
              {
                type: "bullet",
                number: 3,
                path: pathHelper("bullet", "registerAsAServiceProvider_3"),
              },
              {
                type: "bullet",
                number: 4,
                path: pathHelper("bullet", "registerAsAServiceProvider_4"),
              },
              {
                type: "bullet",
                number: 5,
                path: pathHelper("bullet", "registerAsAServiceProvider_5"),
              },
              {
                type: "bullet",
                number: 6,
                path: pathHelper("bullet", "registerAsAServiceProvider_6"),
              },
              {
                type: "bullet",
                number: 7,
                path: pathHelper("bullet", "registerAsAServiceProvider_7"),
              },
            ],
          },
        ],
      },
      {
        label: pathHelper("title", "signingIn"),
        nodes: [
          {
            label: pathHelper("title", "signInUsingGoogle"),
            nodes: null,
            info: [
              {
                type: "title",
                path: pathHelper("title", "signInUsingGoogle"),
              },
              {
                type: "bullet",
                number: 1,
                path: pathHelper("bullet", "signInUsingGoogle_1"),
              },
              {
                type: "bullet",
                number: 2,
                path: pathHelper("bullet", "signInUsingGoogle_2"),
              },
              {
                type: "bullet",
                number: 3,
                path: pathHelper("bullet", "signInUsingGoogle_3"),
              },
              {
                type: "bullet",
                number: 4,
                path: pathHelper("bullet", "signInUsingGoogle_4"),
              },
            ],
          },
          {
            label: pathHelper("title", "signInUsingEmail"),
            nodes: null,
            info: [
              {
                type: "title",
                path: pathHelper("title", "signInUsingEmail"),
              },
              {
                type: "bullet",
                number: 1,
                path: pathHelper("bullet", "signInUsingEmail_1"),
              },
              {
                type: "bullet",
                number: 2,
                path: pathHelper("bullet", "signInUsingEmail_2"),
              },
              {
                type: "bullet",
                number: 3,
                path: pathHelper("bullet", "signInUsingEmail_3"),
              },
              {
                type: "bullet",
                number: 4,
                path: pathHelper("bullet", "signInUsingEmail_4"),
              },
              {
                type: "bullet",
                number: 5,
                path: pathHelper("bullet", "signInUsingEmail_5"),
              },
            ],
          },
          {
            label: pathHelper("title", "signInAsAServiceProvider"),
            nodes: null,
            info: [
              {
                type: "title",
                path: pathHelper("title", "signInAsAServiceProvider"),
              },
              {
                type: "bullet",
                number: 1,
                path: pathHelper("bullet", "signInAsAServiceProvider_1"),
              },
              {
                type: "bullet",
                number: 2,
                path: pathHelper("bullet", "signInAsAServiceProvider_2"),
              },
              {
                type: "bullet",
                number: 3,
                path: pathHelper("bullet", "signInAsAServiceProvider_3"),
              },
              {
                type: "bullet",
                number: 4,
                path: pathHelper("bullet", "signInAsAServiceProvider_4"),
              },
              {
                type: "bullet",
                number: 5,
                path: pathHelper("bullet", "signInAsAServiceProvider_5"),
              },
              {
                type: "bullet",
                number: 6,
                path: pathHelper("bullet", "signInAsAServiceProvider_6"),
              },
            ],
          },
        ],
      },
      {
        label: pathHelper("title", "loggingOut"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "loggingOut"),
          },
          {
            type: "bullet",
            number: 1,
            path: pathHelper("bullet", "loggingOut_1"),
          },
          {
            type: "bullet",
            number: 2,
            path: pathHelper("bullet", "loggingOut_2"),
            icon: assets.auth.logout,
          },
          {
            type: "bullet",
            number: 3,
            path: pathHelper("bullet", "loggingOut_3"),
          },
        ],
      },
      {
        label: pathHelper("title", "deletingAnAccount"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "deletingAnAccount"),
          },
          {
            type: "text",
            path: pathHelper("text", "deletingAnAccount_1"),
          },
          {
            type: "text",
            path: pathHelper("text", "deletingAnAccount_2"),
          },
        ],
      },
      {
        label: pathHelper("title", "howManyPetsCanIAdd"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "howManyPetsCanIAdd"),
          },
          {
            type: "text",
            path: pathHelper("text", "howManyPetsCanIAdd_1"),
          },
          {
            type: "label",
            path: pathHelper("label", "howManyPetsCanIAdd_1"),
          },
          {
            type: "link",
            path: pathHelper("link", "howManyPetsCanIAdd_1"),
          },
        ],
      },
    ],
  },
  {
    label: pathHelper("title", "securityAndPrivacy"),
    nodes: [
      {
        label: pathHelper("title", "isItSafeToUse"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "isItSafeToUse"),
          },
          {
            type: "text",
            path: pathHelper("text", "isItSafeToUse_1"),
          },
        ],
      },
      {
        label: pathHelper("title", "doesTheAppShareUserData"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "doesTheAppShareUserData"),
          },
          {
            type: "text",
            path: pathHelper("text", "doesTheAppShareUserData_1"),
          },
          {
            type: "text",
            path: pathHelper("text", "doesTheAppShareUserData_2"),
          },
          {
            type: "label",
            path: pathHelper("label", "doesTheAppShareUserData_1"),
          },
          {
            type: "link",
            path: pathHelper("link", "doesTheAppShareUserData_1"),
          },
        ],
      },
      {
        label: pathHelper("title", "doWeSaveYourCreditCardInfo"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "doWeSaveYourCreditCardInfo"),
          },
          {
            type: "text",
            path: pathHelper("text", "doWeSaveYourCreditCardInfo_1"),
          },
        ],
      },
    ],
  },
  {
    label: pathHelper("title", "subscriptionAndPayment"),
    nodes: [
      {
        label: pathHelper("title", "howMuchDoesASubscriptionCost"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "howMuchDoesASubscriptionCost"),
          },
          {
            type: "text",
            path: pathHelper("text", "howMuchDoesASubscriptionCost_1"),
          },
          {
            type: "label",
            path: pathHelper("label", "howMuchDoesASubscriptionCost_1"),
          },
          {
            type: "link",
            path: pathHelper("link", "howMuchDoesASubscriptionCost_1"),
          },
        ],
      },
      {
        label: pathHelper("title", "isThereAFreeVersion"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "isThereAFreeVersion"),
          },
          {
            type: "text",
            path: pathHelper("text", "isThereAFreeVersion_1"),
          },
          {
            type: "text",
            path: pathHelper("text", "isThereAFreeVersion_2"),
          },
        ],
      },
      {
        label: pathHelper("title", "howDoISubscribe"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "howDoISubscribe"),
          },
          {
            type: "bullet",
            number: 1,
            path: pathHelper("bullet", "howDoISubscribe_1"),
            icon: assets.user.main,
          },
          {
            type: "bullet",
            number: 2,
            path: pathHelper("bullet", "howDoISubscribe_2"),
            icon: assets.user.subscription,
          },
          {
            type: "bullet",
            number: 3,
            path: pathHelper("bullet", "howDoISubscribe_3"),
          },
          {
            type: "bullet",
            number: 4,
            path: pathHelper("bullet", "howDoISubscribe_4"),
          },
          {
            type: "bullet",
            number: 5,
            path: pathHelper("bullet", "howDoISubscribe_5"),
          },
        ],
      },
      {
        label: pathHelper("title", "redeemingACode"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "redeemingACode"),
          },
          {
            type: "text",
            path: pathHelper("text", "redeemingACode_1"),
          },
          {
            type: "bullet",
            number: 1,
            path: pathHelper("bullet", "redeemingACode_1"),
            icon: assets.user.main,
          },
          {
            type: "bullet",
            number: 2,
            path: pathHelper("bullet", "redeemingACode_2"),
            icon: assets.user.redeemCode,
          },
          {
            type: "bullet",
            number: 3,
            path: pathHelper("bullet", "redeemingACode_3"),
          },
          {
            type: "bullet",
            number: 4,
            path: pathHelper("bullet", "redeemingACode_4"),
          },
        ],
      },
      {
        label: pathHelper("title", "updatingCardDetails"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "updatingCardDetails"),
          },
          {
            type: "bullet",
            number: 1,
            path: pathHelper("bullet", "updatingCardDetails_1"),
            icon: assets.user.main,
          },
          {
            type: "bullet",
            number: 2,
            path: pathHelper("bullet", "updatingCardDetails_2"),
            icon: assets.user.subscription,
          },
          {
            type: "bullet",
            number: 3,
            path: pathHelper("bullet", "updatingCardDetails_3"),
          },
          {
            type: "bullet",
            number: 4,
            path: pathHelper("bullet", "updatingCardDetails_4"),
          },
          {
            type: "bullet",
            number: 5,
            path: pathHelper("bullet", "updatingCardDetails_5"),
          },
        ],
      },
      {
        label: pathHelper("title", "cancelASubscription"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "cancelASubscription"),
          },
          {
            type: "bullet",
            number: 1,
            path: pathHelper("bullet", "cancelASubscription_1"),
            icon: assets.user.main,
          },
          {
            type: "bullet",
            number: 2,
            path: pathHelper("bullet", "cancelASubscription_2"),
            icon: assets.user.subscription,
          },
          {
            type: "bullet",
            number: 3,
            path: pathHelper("bullet", "cancelASubscription_3"),
          },
          {
            type: "bullet",
            number: 4,
            path: pathHelper("bullet", "cancelASubscription_4"),
          },
        ],
      },
      {
        label: pathHelper("title", "paymentMethods"),
        nodes: [
          {
            label: pathHelper("title", "stripe"),
            nodes: null,
            info: [
              {
                type: "title",
                path: pathHelper("title", "stripe"),
              },
              {
                type: "text",
                path: pathHelper("text", "stripe_1"),
              },
              {
                type: "text",
                path: pathHelper("text", "stripe_2"),
              },
              {
                type: "text",
                path: pathHelper("text", "stripe_3"),
              },
              {
                type: "label",
                path: pathHelper("label", "stripe_1"),
              },
              {
                type: "link",
                path: pathHelper("link", "stripe_1"),
              },
            ],
          },
          {
            label: pathHelper("title", "onlineShop"),
            nodes: null,
            info: [
              {
                type: "title",
                path: pathHelper("title", "onlineShop"),
              },
              {
                type: "text",
                path: pathHelper("text", "onlineShop_1"),
              },
              {
                type: "label",
                path: pathHelper("label", "onlineShop_1"),
              },
              {
                type: "link",
                path: pathHelper("link", "onlineShop_1"),
              },
            ],
          },
          {
            label: pathHelper("title", "isStripeSafe"),
            nodes: null,
            info: [
              {
                type: "title",
                path: pathHelper("title", "isStripeSafe"),
              },
              {
                type: "text",
                path: pathHelper("text", "isStripeSafe_1"),
              },
              {
                type: "label",
                path: pathHelper("label", "isStripeSafe_1"),
              },
              {
                type: "link",
                path: pathHelper("link", "isStripeSafe_1"),
              },
              {
                type: "label",
                path: pathHelper("label", "isStripeSafe_2"),
              },
              {
                type: "link",
                path: pathHelper("link", "isStripeSafe_2"),
              },
            ],
          },
          {
            label: pathHelper("title", "doWeSaveYourCreditCardInfo"),
            nodes: null,
            info: [
              {
                type: "title",
                path: pathHelper("title", "doWeSaveYourCreditCardInfo"),
              },
              {
                type: "text",
                path: pathHelper("text", "doWeSaveYourCreditCardInfo_1"),
              },
            ],
          },
        ],
      },
    ],
  },

  {
    label: pathHelper("title", "pets"),
    nodes: [
      {
        label: pathHelper("title", "addingAPet"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "addingAPet"),
          },
          {
            type: "bullet",
            number: 1,
            path: pathHelper("bullet", "addingAPet_1"),
            icon: assets.pets.addPet,
          },
          {
            type: "bullet",
            number: 2,
            path: pathHelper("bullet", "addingAPet_2"),
          },
          {
            type: "bullet",
            number: 3,
            path: pathHelper("bullet", "addingAPet_3"),
          },
        ],
      },
      {
        label: pathHelper("title", "editingAPetProfile"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "editingAPetProfile"),
          },
          {
            type: "bullet",
            number: 1,
            path: pathHelper("bullet", "editingAPetProfile_1"),
          },
          {
            type: "bullet",
            number: 2,
            path: pathHelper("bullet", "editingAPetProfile_2"),
          },
          {
            type: "bullet",
            number: 3,
            path: pathHelper("bullet", "editingAPetProfile_3"),
            icon: assets.pets.profile,
          },
          {
            type: "bullet",
            number: 4,
            path: pathHelper("bullet", "editingAPetProfile_4"),
          },
          {
            type: "bullet",
            number: 5,
            path: pathHelper("bullet", "editingAPetProfile_5"),
          },
          {
            type: "bullet",
            number: 6,
            path: pathHelper("bullet", "editingAPetProfile_6"),
          },
        ],
      },
      {
        label: pathHelper("title", "deletingAPetProfile"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "deletingAPetProfile"),
          },
          {
            type: "bullet",
            number: 1,
            path: pathHelper("bullet", "deletingAPetProfile_1"),
          },
          {
            type: "bullet",
            number: 2,
            path: pathHelper("bullet", "deletingAPetProfile_2"),
          },
          {
            type: "bullet",
            number: 3,
            path: pathHelper("bullet", "deletingAPetProfile_3"),
            icon: assets.pets.deleteProfile,
          },
          {
            type: "bullet",
            number: 4,
            path: pathHelper("bullet", "deletingAPetProfile_4"),
          },
          {
            type: "bullet",
            number: 5,
            path: pathHelper("bullet", "deletingAPetProfile_5"),
          },
        ],
      },
    ],
  },
  {
    label: pathHelper("title", "serviceProviders"),
    nodes: [
      {
        label: pathHelper("title", "connectingWithClients"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "connectingWithClients"),
          },
          {
            type: "text",
            path: pathHelper("text", "connectingWithClients_1"),
          },
          {
            type: "text",
            path: pathHelper("text", "connectingWithClients_2"),
          },
          {
            type: "bullet",
            number: 1,
            path: pathHelper("bullet", "connectingWithClients_1"),
            icon: assets.provider.providerContactsHome,
          },
          {
            type: "bullet",
            number: 2,
            path: pathHelper("bullet", "connectingWithClients_2"),
            icon: assets.provider.sendInvite,
          },
          {
            type: "bullet",
            number: 3,
            path: pathHelper("bullet", "connectingWithClients_3"),
          },
          {
            type: "bullet",
            number: 4,
            path: pathHelper("bullet", "connectingWithClients_4"),
          },
          {
            type: "bullet",
            number: 5,
            path: pathHelper("bullet", "connectingWithClients_5"),
          },
          {
            type: "bullet",
            number: 6,
            path: pathHelper("bullet", "connectingWithClients_6"),
            icon: assets.provider.providerContacts,
          },
        ],
      },
      {
        label: pathHelper("title", "viewingClientAndPetInfo"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "viewingClientAndPetInfo"),
          },
          {
            type: "bullet",
            number: 1,
            path: pathHelper("bullet", "viewingClientAndPetInfo_1"),
            icon: assets.provider.providerContactsHome,
          },
          {
            type: "bullet",
            number: 2,
            path: pathHelper("bullet", "viewingClientAndPetInfo_2"),
            icon: assets.provider.sendInvite,
          },
          {
            type: "bullet",
            number: 3,
            path: pathHelper("bullet", "viewingClientAndPetInfo_3"),
          },
          {
            type: "bullet",
            number: 4,
            path: pathHelper("bullet", "viewingClientAndPetInfo_4"),
          },
          {
            type: "bullet",
            number: 5,
            path: pathHelper("bullet", "viewingClientAndPetInfo_5"),
          },
          {
            type: "bullet",
            number: 6,
            path: pathHelper("bullet", "viewingClientAndPetInfo_6"),
          },
        ],
      },
      {
        label: pathHelper("title", "acceptingAContactRequest"),
        nodes: null,
        info: [
          {
            type: "title",
            path: pathHelper("title", "acceptingAContactRequest"),
          },
          {
            type: "bullet",
            number: 1,
            path: pathHelper("bullet", "acceptingAContactRequest_1"),
            icon: assets.professionals.main,
          },
          {
            type: "bullet",
            number: 2,
            path: pathHelper("bullet", "acceptingAContactRequest_2"),
          },
          {
            type: "bullet",
            number: 3,
            path: pathHelper("bullet", "acceptingAContactRequest_3"),
          },
        ],
      },
    ],
  },
];
