import React from "react"
import styled from "styled-components"

const StyledDiv = styled.div`
  padding: 4px;
  background-color: #dddddd;
  border-radius: 4px;
`

function StripeElementWrapper(props) {
  return <StyledDiv>{props.children}</StyledDiv>
}

export default StripeElementWrapper
