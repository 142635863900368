import React from "react";
import { connect } from "react-redux";
import Message from "components/message";
import ExpenseSummaryItem from "./Expense_Summary_Item";

function ExpenseSummary(props) {
  const { messages, locale, itemsToDisplay, expenses } = props;

  if (!itemsToDisplay) return null;

  const total = expenses.map((exp) => exp.total).reduce((a, b) => a + b, 0);
  const curr = "";

  const EXPENSE_SUMMARY_ITEMS = [
    {
      label: messages.labels.total[locale],
      value: `${curr} ${total.toFixed(2)}`,
    },
    {
      label: messages.labels.numberOfItems[locale],
      value: expenses.length,
    },
  ];

  return (
    <Message
      default
      withHeader
      color={"is-info"}
      header={messages.headers.listSummary[locale]}
    >
      {EXPENSE_SUMMARY_ITEMS.map((item, i) => (
        <ExpenseSummaryItem key={i} {...item} />
      ))}
    </Message>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: state.intl.messages.components.expenseSummary,
  };
};

export default connect(mapStateToProps)(ExpenseSummary);
