import React from 'react';
import Buttons from "components/elements/Buttons";
import Intl from "components/Intl";
import CustomMarker from "./CustomMarker";

export default function DetailMarker(props) {
  return (
    <div>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <CustomMarker
              itemNumber={props.itemNumber}
              className="markerDetails"
            />
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <Buttons>
              <button
                className="button is-small is-rounded"
                onClick={props.addMarkerDetail}
                style={{ width: 30 }}
              >
                <i className="fas fa-plus"></i>
              </button>
              <button
                className="button is-small is-rounded"
                onClick={() => props.deleteMarker(props._id)}
                style={{ width: 30 }}
              >
                <i className="fas fa-trash"></i>
              </button>
            </Buttons>
          </div>
        </div>
      </div>
      <div style={{ marginTop: -18, paddingLeft: 14, marginBottom: 16 }}>
        {props.details.map((d, i) => (
          <div
            key={i}
            className="box"
            style={{
              padding: 8,
              marginRight: 2,
              marginBottom: 6,
              cursor: "pointer",
            }}
            onClick={() => props.viewDetail(d)}
          >
            <p>
              <span
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "30ch",
                }}
              >
                {" - "}{" "}
                <span className="has-text-weight-bold">
                  <Intl
                    message
                    path={`components.forms.selectOptions.bodyMarkerDetailType.${[
                      d.bodyMarkerDetailType,
                    ]}`}
                  />
                  :
                </span>{" "}
                {d.description}
              </span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
