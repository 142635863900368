import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "components/menu";
import config from "./config";

class FeedbackAndSupport extends Component {
  render() {
    return <Menu {...config} {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      ...state.intl.messages.containers.main.feedbackAndSupport,
      helpMenu: state.intl.messages.containers.main.helpMenu,
    },
  };
};

export default connect(mapStateToProps)(FeedbackAndSupport);
