import _ from "lodash";
import moment from "moment";
import {
  APPOINTMENTS_FETCH_ALL_FOR_PROVIDER,
  APPOINTMENTS_UPDATE,
  APPOINTMENTS_DELETE,
} from "containers/features/Appointments/actions";
import { AUTH_LOG_OUT_USER } from "actions/types";
import { AUTH_LOG_OUT_PROVIDER } from "containers/auth/Pet_Services_Portal/actions";

const INITIAL_STATE = {
  appointments: [],
  lastFetch: moment(),
};

export default function ProviderAppointments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case APPOINTMENTS_FETCH_ALL_FOR_PROVIDER:
      return {
        ...state,
        appointments:
          action.payload.length > 0
            ? _.uniqWith([...state.appointments, ...action.payload], _.isEqual)
            : false,
        lastFetch: moment(),
      };
    case APPOINTMENTS_UPDATE:
      return {
        ...state,
        appointments: _.sortBy(
          [
            ...state.appointments.filter(
              (entry) => entry._id !== action.payload._id
            ),
            action.payload,
          ],
          "date"
        ),
      };
    case APPOINTMENTS_DELETE:
      return {
        ...state,
        appointments: state.appointments.filter(
          (entry) => entry._id !== action.payload
        ),
      };
    case AUTH_LOG_OUT_USER:
    case AUTH_LOG_OUT_PROVIDER:
      return INITIAL_STATE;
    default:
      return state;
  }
}
