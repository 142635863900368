import React from "react"
import Tag from "components/elements/Tag"
import TagGroup from "components/elements/TagGroup"

function PopularTags(props) {
  return (
    <>
      <div className="title is-6 has-text-grey">{props.title}</div>
      <TagGroup>
        {props.tags.map((t) => (
          <Tag key={t.id} label={t.name} onClick={() => props.onClick(t.id)} />
        ))}{" "}
      </TagGroup>
    </>
  )
}

export default PopularTags
