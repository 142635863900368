import React from "react";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";

function PostReadMoreButton(props) {
  const handleReadMoreClick = async () => {
    await props.setSelectedPost(props.post);
    props.history.push("/userhome/blog/post");
  };

  return (
    <Buttons>
      <Button
        onClick={handleReadMoreClick}
        className="is-small"
        group="common"
        name="readMore"
      />
    </Buttons>
  );
}

export default PostReadMoreButton;
