// APP
export const ACTION_STARTED = "action_started";
export const ACTION_STARTED_NO_LOADING_STATE = "action_started_no_loading_state";
export const ACTION_ENDED = "action_ended";
export const ACTION_SUCCESS = "action_success";
export const ACTION_ERROR = "action_error";
export const ACTION_RESET = "action_reset";
export const ACTION_NO_SUCCESS = "action_no_success";

// APP SYSTEM MESSAGES
export const SYS_MESSAGE = "sys_message";
export const SYS_MESSAGE_DISMISS = "sys_message_dismiss";
export const SYS_MESSAGE_CARD_EXPIRING = "sys_message_card_expiring";
export const SYS_MESSAGE_CARD_EXPIRING_DISMISS = "sys_message_card_expiring_dismiss";
export const SYS_MESSAGE_CARD_REQUIRES_ACTION = "sys_message_card_requires_action";
export const SYS_MESSAGE_CARD_REQUIRES_ACTION_DISMISS = "sys_message_card_requires_action_dismiss";

// APP DATA
export const CHANGE_THEME = "change_theme";

// AUTH
export const AUTH_REGISTER_USER = "auth_register_user";
export const AUTH_LOG_IN_USER = "auth_log_in_user";
export const AUTH_FETCH_CURRENT_USER = "auth_fetch_current_user";
export const AUTH_LOG_OUT_USER = "auth_log_out_user";
export const AUTH_GOOGLE_LOG_IN = "auth_google_log_in";
export const AUTH_COMPLETE_REGISTRATION_REQUIRED = "auth_complete_registration_required";
export const AUTH_VERIFY_EMAIL_REQUIRED = "auth_verify_email_required";
export const AUTH_VERIFY_EMAIL = "auth_verify_email";
export const AUTH_RESEND_VERIFY_EMAIL = "auth_resend_verify_email";
export const AUTH_COMPLETE_REGISTRATION = "auth_compete_registration";
export const AUTH_CHANGE_PASSWORD = "auth_change_password";
export const AUTH_RESET_PASSWORD = "auth_reset_password";
export const AUTH_REQUEST_RESET_PASSWORD_CODE = "auth_request_reset_password_code";
export const AUTH_DISMISS_USERHOME_SUB_MESSAGE = "auth_dismiss_userhome_sub_message";
export const AUTH_UPDATE_EMAIL_PREFERENCES = "auth_update_email_preferences";
export const AUTH_USER_EMAIL_REQUIRED = "auth_user_email_required";
export const AUTH_ADD_USER_EMAIL = "auth_add_user_email";
export const AUTH_UPDATE_LANGUAGE_PREFERENCE = "auth_update_language_preference";
export const AUTH_UPDATE_USER_WEIGHT_UNIT_PREFERENCE = "auth_update_user_weight_unit_preference";
export const AUTH_UPDATE_DASHBOARD_PREFERENCES = "auth_update_dashboard_preferences";
export const AUTH_FETCH_INFO_AND_UPDATES = "auth_fetch_info_and_updates";

// Modals
export const MODALS_SHOW = "modals_show";
export const MODALS_HIDE = "modals_hide";
export const MODALS_CONFIRM_DELETE = "modals_confirm_delete";
export const MODALS_DISPLAY_COLOR_PICKER = "modals_display_color_picker";
export const MODALS_CONFIRM_EMAIL = "modals_confirm_email";
export const MODALS_BENEFITS_INFO = "modals_benefits_info";
export const MODALS_CONTACTS_INFO = "modals_contacts_info";
export const MODALS_MORE_INFO = "modals_more_info";
export const MODALS_BUSY_LOADING = "modals_busy_loading";
export const MODALS_UPDATE_DOCUMENT = "modals_update_document";
export const MODALS_CONFIRM_APPOINTMENT = "modals_confirm_appointment";
export const MODALS_BCS_DESCRIPTION = "modals_bcs_description";
export const MODALS_BODY_MARKER_DETAIL = "modals_body_marker_detail";
export const MODALS_CONFIRM_ARCHIVE = "modals_confirm_archive";
export const MODALS_TOOTH_MARKER_DETAIL = "modals_tooth_marker_detail";
export const MODALS_CONFIRM_PROVIDER_FIELDS = "modals_confirm_provider_fields";

// Pets
export const PETS_FETCH_ALL = "pets_fetch_all";
export const PETS_FETCH_ONE = "pets_fetch_one";
export const PETS_ADD = "pets_add";
export const PETS_UPDATE = "pets_update";
export const PETS_DELETE = "pets_delete";
export const PETS_SELECT_PET = "pets_select_pet";
export const PETS_UNSELECT_PET = "pets_unselect_pet";
export const PETS_ADD_PROFILE_IMAGE = "pets_add_profile_image";

// Billing
export const BILLING_CREATE_SUBSCRIPTION = "billing_create_subscription";
export const BILLING_CREATE_SUBSCRIPTION_EXISTING_CARD = "billing_create_subscription_existing_card";
export const BILLING_FETCH_BILLING_DATA = "billing_fetch_billing_data";
export const BILLING_UPDATE_CARD = "billing_update_card";
export const BILLING_CANCEL_SUBSCRIPTION = "billing_cancel_subscription";
export const BILLING_ACTION_REQUIRED = "billing_action_required";

// Promos
export const CAMPAIGNS_REDEEM_CODE = "campaigns_redeem_code";

// Vaccinations
export const VACS_FETCH = "vacs_fetch";
export const VACS_ADD = "vacs_add";
export const VACS_UPDATE = "vacs_update";
export const VACS_DELETE = "vacs_delete";
export const VACS_ADD_FOR_LITTER = "vacs_add_for_litter";
export const VACS_FETCH_FOR_LITTER = "vacs_fetch_for_litter";

// Parasites
export const PARASITES_FETCH = "parasites_fetch";
export const PARASITES_ADD = "parasites_add";
export const PARASITES_UPDATE = "parasites_update";
export const PARASITES_DELETE = "parasites_delete";
export const PARASITES_ADD_FOR_LITTER = "parasites_add_for_litter";
export const PARASITES_FETCH_FOR_LITTER = "parasites_fetch_for_litter";

// HCPI
export const HCPI_FETCH_QUESTIONS = "hcpi_fetch_questions";
export const HCPI_FETCH_RESULTS = "hcpi_fetch_results";
export const HCPI_FETCH_TYPE = "hcpi_fetch_type";
export const HCPI_SUBMIT_RESULT = "hcpi_submit_result";
export const HCPI_DELETE = "hcpi_delete";
export const HCPI_UPDATE = "hcpi_update";
export const HCPI_ACTIVITY_CHART_DATA = "hcpi_activity_chart_data";

// Food Diary
export const FOOD_DIARY_FETCH = "food_diary_fetch";
export const FOOD_DIARY_FETCH_TYPE = "food_diary_fetch_type";
export const FOOD_DIARY_ADD = "food_diary_add";
export const FOOD_DIARY_UPDATE = "food_diary_update";
export const FOOD_DIARY_DELETE = "food_diary_delete";
export const FOOD_DIARY_ADD_FOR_LITTER = "food_diary_add_for_litter";

// Weight
export const WEIGHT_FETCH = "weight_fetch";
export const WEIGHT_FETCH_TYPE = "weigh0t_fetch_type";
export const WEIGHT_ADD = "weight_add";
export const WEIGHT_UPDATE = "weight_update";
export const WEIGHT_DELETE = "weight_delete";
export const WEIGHT_ADD_LITTER_MEMBER = "weight_add_litter_member";
export const WEIGHT_UPDATE_LITTER_MEMBER = "weight_update_litter_member";
export const WEIGHT_DELETE_LITTER_MEMBER = "weight_delete_litter_member";

// Feedback and Support
export const FEEDBACK_SUBMIT = "feedback_submit";
export const SUPPORT_SUBMIT = "support_submit";

// Health Diary
export const HEALTH_DIARY_FETCH = "health_diary_fetch";
export const HEALTH_DIARY_FETCH_TYPE = "health_diary_fetch_type";
export const HEALTH_DIARY_ADD = "health_diary_add";
export const HEALTH_DIARY_UPDATE = "health_diary_update";
export const HEALTH_DIARY_DELETE = "health_diary_delete";

// Reporting
export const REPORTING_FETCH_TEMPLATES = "reporting_fetch_templates";
export const REPORTING_FETCH_REQUESTS = "reporting_fetch_requests";
export const REPORTING_SUBMIT_REQUEST = "reporting_submit_request";
export const REPORTING_REFRESH_STATUS = "reporting_refresh_status";

// Cloudinary
export const CLOUDINARY_GET_SIGNATURE = "cloudinary_get_signature";
export const CLOUDINARY_GET_CONFIG = "cloudinary_get_config";
export const CLOUDINARY_HANDLE_UPLOAD = "cloudinary_handle_upload";
export const CLOUDINARY_RESET = "cloudinary_reset";

// Award Cabinet
export const AWARD_CABINET_FETCH = "award_cabinet_fetch";
export const AWARD_CABINET_ADD = "award_cabinet_add";
export const AWARD_CABINET_UPDATE = "award_cabinet_update";
export const AWARD_CABINET_DELETE = "award_cabinet_delete";

// Table
export const TABLE_STORE_CURRENT_STATE = "table_store_current_state";
export const TABLE_CLEAR_CURRENT_STATE = "table_clear_current_state";

// Stable Diary
export const STABLE_DIARY_FETCH = "stable_diary_fetch";
export const STABLE_DIARY_FETCH_TYPE = "stable_diary_fetch_type";
export const STABLE_DIARY_ADD = "stable_diary_add";
export const STABLE_DIARY_UPDATE = "stable_diary_update";
export const STABLE_DIARY_DELETE = "stable_diary_delete";

// Hoof Care
export const HOOF_CARE_FETCH = "hoof_care_fetch";
export const HOOF_CARE_ADD = "hoof_care_add";
export const HOOF_CARE_UPDATE = "hoof_care_update";
export const HOOF_CARE_DELETE = "hoof_care_delete";

// Partners and Contenful Tiles and Pages
export const PARTNERS_CONTENTFUL_API_CALL = "parnters_contentful_api_call";
export const PARTNERS_CONTENTFUL_API_ERROR = "partners_contentful_api_error";
export const PARTNERS_CONTENTFUL_FETCH_TILES = "partners_contentful_fetch_tiles";
export const PARTNERS_CONTENTFUL_FETCH_PAGES = "partners_contentful_fetch_pages";

// Useful Links
export const USEFUL_LINK_FETCH = "useful_link_fetch";
export const USEFUL_LINK_ADD = "useful_link_add";
export const USEFUL_LINK_UPDATE = "useful_link_update";
export const USEFUL_LINK_DELETE = "useful_link_delete";

export const LITTER_USEFUL_LINKS_ADD = "litter_useful_link_add";
export const LITTER_USEFUL_LINKS_FETCH = "litter_useful_link_fetch";
export const LITTER_USEFUL_LINKS_UPDATE = "litter_useful_link_update";
export const LITTER_USEFUL_LINKS_DELETE = "litter_useful_link_delete";

// Litters
export const LITTERS_FETCH = "litters_fetch";
export const LITTERS_ADD = "litters_add";
export const LITTERS_UPDATE = "litters_update";
export const LITTERS_DELETE = "litters_delete";
export const LITTERS_SELECT_LITTER = "litters_select_litter";
export const LITTERS_UNSELECT_LITTER = "litters_unselect_litter";
export const LITTERS_FETCH_LITTER_MEMBERS = "litters_fetch_litter_members";
export const LITTERS_ADD_LITTER_MEMBER = "litters_add_litter_member";
export const LITTERS_SELECT_LITTER_MEMBER = "litters_select_litter_member";
export const LITTERS_UNSELECT_LITTER_MEMBER = "litters_unselect_litter_member";
export const LITTERS_UPDATE_MEMBER = "litters_update_member";
export const LITTERS_DELETE_MEMBER = "litters_delete_member";

// Weight Review
export const WEIGHT_REVIEW_FETCH = "weight_review_fetch";

// Litter Vaccinations
export const LITTER_VACS_ADD = "litter_vacs_add";
export const LITTER_VACS_FETCH = "litter_vacs_fetch";
export const LITTER_VACS_SELECT_MEMBERS = "litter_vacs_select_members";

// Litter Members
export const LITTER_MEMBERS_SELECT_ONE = "litter_members_select_one";
export const LITTER_MEMBERS_SELECT_ALL = "litter_members_select_all";
export const LITTER_MEMBERS_UNSELECT_ONE = "litter_members_unselect_one";
export const LITTER_MEMBERS_UNSELECT_ALL = "litter_members_unselect_all";

// Stripe
export const STRIPE_CREATE_PAYMENT_INTENT = "stripe_create_payment_intent";
export const STRIPE_UPDATE_PAYMENT_INTENT = "stripe_update_payment_intent";
export const STRIPE_CARD_SETUP = "stripe_card_setup";

// Litter Expenses
export const LITTER_EXPENSES_ADD = "litter_expenses_add";
export const LITTER_EXPENSES_FETCH = "litter_expenses_fetch";
export const LITTER_EXPENSES_UPDATE = "litter_expenses_updates";
export const LITTER_EXPENSES_DELETE = "litter_expenses_delete";

// Transfers
export const TRANSFERS_FETCH_TRANSFERS = "transfers_fetch_transfers";
export const TRANSFERS_TRANSFER_LITTER_MEMBER = "transfers_transfer_litter_member";
export const TRANSFERS_RETRIEVE_LITTER_MEMBER = "transfers_retrieve_litter_member";
export const TRANSFERS_GET_STATUS = "transfers_get_status";
export const TRANSFERS_UPDATE_PET_AND_TRANSFER = "transfers_update_pet_and_transfer";
export const TRANSFERS_CANCEL_UPDATE_PET_AND_TRANSFER = "transfers_cancel_update_pet_and_transfer";

// Client Settings
export const CLIENT_SETTINGS_FETCH = "client_settings_fetch";
export const CLIENT_VERSION_CHECK = "client_version_check";

// Feature Resources
export const FEATURE_RESOURCES_FETCH = "feature_resources_fetch";

// Heat Diary
export const HEAT_DIARY_FETCH_CYCLES = "heat_diary_fetch_cycles";
export const HEAT_DIARY_FETCH_PROGS = "heat_diary_fetch_progs";
export const HEAT_DIARY_FETCH_BREEDING = "heat_diary_fetch_breeding";
export const HEAT_DIARY_FETCH_TYPE = "heat_diary_fetch_type";

export const HEAT_DIARY_ADD_CYCLE = "heat_diary_add_cycle";
export const HEAT_DIARY_ADD_PROG = "heat_diary_add_prog";
export const HEAT_DIARY_ADD_BREEDING = "heat_diary_add_breeding";

export const HEAT_DIARY_UPDATE_CYCLE = "heat_diary_update_cycle";
export const HEAT_DIARY_UPDATE_PROG = "heat_diary_update_prog";
export const HEAT_DIARY_UPDATE_BREEDING = "heat_diary_update_breeding";

export const HEAT_DIARY_DELETE_CYCLE = "heat_diary_delete_cycle";
export const HEAT_DIARY_DELETE_PROG = "heat_diary_delete_prog";
export const HEAT_DIARY_DELETE_BREEDING = "heat_diary_delete_breeding";

// Activity
// Training Diary
export const TRAINING_DIARY_FETCH_TYPE = "training_diary_fetch_type";
export const TRAINING_DIARY_FETCH = "training_diary_fetch";
export const TRAINING_DIARY_ADD = "training_diary_add";
export const TRAINING_DIARY_UPDATE = "training_diary_update";
export const TRAINING_DIARY_DELETE = "trianing_diary_delete";
export const TRAINING_DIARY_SET_CUSTOM_SETTINGS =
  "training_diary_set_custom_settings";

// Temperature
export const TEMPERATURE_FETCH = "temperature_fetch";
export const TEMPERATURE_FETCH_TYPE = "temperature_fetch_type";
export const TEMPERATURE_ADD = "temperature_add";
export const TEMPERATURE_UPDATE = "temperature_update";
export const TEMPERATURE_DELETE = "temperature_delete";
