import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { foodDiary as actions } from "actions/index";
import moment from "moment";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { INPUT_FIELDS } from "forms/fields/litter_food_diary";
import FOOD_CATEGORIES from "constants/select_options";
import FormButtons from "components/form/FormButtons";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";

class LitterFoodDiaryEntryForm extends Component {
  state = { showAdditionalFields: false };

  renderFields(additionalFields) {
    const { viewMode, editMode, values } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (!additionalFields && field.additionalField) return null;
      if (
        (!values.onGoing && field.name === "dateEnd") ||
        (values.onGoing && field.name === "frequency")
      )
        return null;
      if (!values.sideEffectsObserved && field.name === "sideEffectsDesc")
        return null;
      return (
        <div className="field" key={i}>
          <Field
            viewMode={viewMode}
            editMode={editMode}
            values={values}
            {...field}
          />
          {field.lastInGroup && <br />}
        </div>
      );
    });
  }

  render() {
    const {
      errors,
      dirty,
      isSubmitting,
      viewMode,
      editMode,
      locale,
      values,
      cancel,
      defaultRoute,
    } = this.props;
    const { foodCategories } = this.props.messages;
    const { showAdditionalFields } = this.state;
    const foodCategory =
      FOOD_CATEGORIES.foodDiary.foodCategory.categorySelection.filter(
        (cat) => cat.name === values.foodCategory
      );
    return (
      <Form className="has-text-left">
        <br />
        <div className="box">
          <div
            style={{ justifyContent: "center", padding: 0 }}
            className="card-content is-flex"
          >
            <figure className="image is-96x96">
              <img src={foodCategory[0].icon} alt={foodCategory[0].name} />
            </figure>
          </div>
          <p className="heading has-text-centered is-uppercase">
            {foodCategories[foodCategory[0].name][locale]}
          </p>
        </div>
        {this.renderFields(showAdditionalFields)}
        <br />
        <Buttons className="is-centered">
          <Button
            group="common"
            name={
              viewMode && !showAdditionalFields
                ? "showDetails"
                : viewMode && showAdditionalFields
                ? "showLess"
                : showAdditionalFields
                ? "showLess"
                : "showFormAdditionalFields"
            }
            onClick={() =>
              this.setState({
                showAdditionalFields: !this.state.showAdditionalFields,
              })
            }
          />
        </Buttons>
        <br />
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          cancel={cancel}
          linkTo={defaultRoute}
        />
        <br />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    _litter: state.reducers.litters.selectedLitter._id,
    petType: state.reducers.litters.selectedLitter.litterType,
    locale: state.intl.locale,
    messages: {
      foodCategories:
        state.intl.messages.components.forms.selectOptions.foodCategory,
    },
  };
};

LitterFoodDiaryEntryForm = withFormik({
  mapPropsToValues({ foodCategory, _litter }) {
    return {
      foodCategory: foodCategory,
      onGoing: false,
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm"),
      dateEnd: "",
      productName: "",
      manufacturer: "",
      foodDetail: "",
      foodAmountUnit: "gram",
      foodAmount: 0,
      frequency: "notSpecified",
      kcalKg: 0,
      kJKg: 0,
      proteinContent: 0,
      carbohydrateContent: 0,
      fatContent: 0,
      calciumContent: 0,
      _litter,
    };
  },
  validationSchema: Yup.object().shape({
    onGoing: Yup.boolean(),
    foodCategory: Yup.string(),
    date: Yup.date().required("required"),
    dateEnd: Yup.date().when("onGoing", {
      is: true,
      then: Yup.date(),
      otherwise: Yup.date(),
    }),
    time: Yup.string().required("required"),
    productName: Yup.string(),
    manufacturer: Yup.string(),
    foodDetail: Yup.string(),
    foodAmountUnit: Yup.string().required("required"),
    foodAmount: Yup.number().required("required"),
    frequency: Yup.string(),
    kcalKg: Yup.number()
      .min(0, "greaterThanOrEqualTo-0")
      .max(7000, "lessThanOrEqualTo-7000"),
    kJKg: Yup.number()
      .min(0, "greaterThanOrEqualTo-0")
      .max(30000, "lessThanOrEqualTo-30000"),
    proteinContent: Yup.number(),
    carbohydrateContent: Yup.number(),
    fatContent: Yup.number(),
    calciumContent: Yup.number(),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = `${props.defaultRoute}/add_for_litter_success`;
    props.addFdeForLitter(
      values,
      props.litterMembers,
      props.petType,
      setSubmitting,
      () => props.history.push(successRoute)
    );
  },
})(LitterFoodDiaryEntryForm);

export default withRouter(
  connect(mapStateToProps, actions)(LitterFoodDiaryEntryForm)
);
