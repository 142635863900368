import React from "react";
import { connect } from "react-redux";

function MoreInfo({ handleModalClose, messages, locale, buttons, children }) {
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <section className="modal-card-body has-text-dark">
          <div className="has-text-right" style={{ fontSize: 20 }}>
            <div className="icon is-large">
              <i
                onClick={() => handleModalClose()}
                className="far fa-times-circle fa-2x"
              ></i>
            </div>
          </div>
          <div className="columns is-mobile is-2 is-variable is-paddingless">
            <div className="column"></div>
            <div className="column is-10 has-text-centered">{children}</div>
            <div className="column"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    messages: state.intl.messages.components.modals.benefitsMoreInfo,
    buttons: state.intl.messages.components.buttons,
    locale: state.intl.locale,
  };
};

export default connect(mapStateToProps)(MoreInfo);
