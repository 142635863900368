import React, { Component } from "react";
import { connect } from "react-redux";
import { support as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "forms/fields/support";
import { FormButtons, FormTitle } from "components/form";

class SupportForm extends Component {
  renderFields() {
    const { values, isAuthed } = this.props;
    return INPUT_FIELDS.map((field, i) => {
      if (isAuthed && field.name === "email") return null;
      if (
        values.supportCategory !== "feature" &&
        field.name === "featureCategory"
      )
        return null;
      return <Field key={i} values={values} {...field} />;
    });
  }

  render() {
    const { viewMode, editMode, errors, dirty, isSubmitting } = this.props;

    return (
      <Form>
        <FormTitle group={"support"} />
        {this.renderFields()}
        <br />
        <FormButtons
          viewMode={viewMode}
          editMode={editMode}
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          linkTo={"/userhome/feedback_and_support"}
        />
        <br />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.reducers.auth.user,
    isAuthed: state.reducers.auth.isAuthenticated,
  };
};

SupportForm = withFormik({
  mapPropsToValues({ isAuthed, user }) {
    return {
      date: moment().format("YYYY-MM-DD"),
      time: moment().format("HH:mm"),
      email: isAuthed ? user.email : "",
      supportCategory: "",
      featureCategory: "",
      issue: "",
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email("invalid").required("required"),
    supportCategory: Yup.string().required("required"),
    featureCategory: Yup.string().when("supportCategory", {
      is: "feature",
      then: Yup.string().required("required"),
      otherwise: Yup.string(),
    }),
    issue: Yup.string().max(500, "max-500").required("required"),
  }),
  handleSubmit(values, { props, setSubmitting, resetForm }) {
    props.submitRequestForSupport(values, setSubmitting, resetForm);
  },
})(SupportForm);

export default connect(mapStateToProps, actions)(SupportForm);
