import { features } from "assets"
import { path } from "constants/feature_routes_provider_view"
import ViewEntriesView from "./views/View_Entries_View"
import ViewEntryView from "./views/View_Entry_View"
import NewEntryView from "./views/New_Entry_View"
import LandingRedirect from "components/features/Landing_Redirect"
import NewEntrySelectionView from "./views/New_Entry_Selection_View"

const homeCareProviderConfig = {
  name: "homeCare",
  type: "feature",
  defaultRoute: path("home_care"),
  redirect: path("home_care/view_entries"),
  menuIcon: {
    img: features.homeCare.main,
    alt: "home_care_feature_icon",
  },
  providerView: true,
  providerEdit: true,
  nav: [
    {
      name: "landingRedirect",
      disabled: false,
      menuItem: false,
      exact: true,
      component: LandingRedirect,
      path: "/",
    },
    {
      name: "viewEntriesView",
      disabled: false,
      menuItem: true,
      exact: true,
      image: {
        img: features.homeCare.viewEntries,
        alt: "view_entries_icon",
      },
      component: ViewEntriesView,
      path: "/view_entries",
    },
    {
      name: "newEntrySelectionView",
      disabled: false,
      menuItem: true,
      image: {
        img: features.homeCare.newEntry,
        alt: "new_entry_icon",
      },
      component: NewEntrySelectionView,
      path: "/new_entry_selection",
    },
    {
      name: "newEntryView",
      disabled: false,
      menuItem: false,
      component: NewEntryView,
      path: "/new_entry",
    },
    {
      name: "viewEntryView",
      disabled: false,
      menuItem: false,
      component: ViewEntryView,
      path: "/view_entry/:id",
    },
  ],
  tableView: {
    headers: ["date", "category", "activity"],
    mobileHeaders: ["date", "category", "activity"],
  },
  chartView: {},
}

export default homeCareProviderConfig
