import React from "react";
import { connect } from "react-redux";
import { MODALS_HIDE } from "actions/types";
import { CirclePicker } from "react-color";
import Intl from "components/Intl";

const disableTouchAction = {
  touchAction: "none",
};

const ColorPickerModal = ({
  awardData,
  messages,
  locale,
  name,
  colors,
  dispatch,
  handleColorPickerComplete,
  selectedType,
}) => {
  return (
    <div className="modal is-active">
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title is-size-6-mobile">
            <Intl
              message
              path="components.modals.titles.colorPicker"
              messageProps={{
                awardPart: messages.awardPartLabels[name][locale],
              }}
            />
          </p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => dispatch({ type: MODALS_HIDE })}
          />
        </header>
        <section className="modal-card-body has-text-dark">
          <div style={disableTouchAction}>
            <CirclePicker
              colors={colors}
              name={name}
              color={awardData.colors[name]}
              onChangeComplete={(color) =>
                handleColorPickerComplete(color, name)
              }
            />
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button"
            onClick={() => dispatch({ type: MODALS_HIDE })}
          >
            {messages.buttons.close[locale]}
          </button>
        </footer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    messages: {
      awardPartLabels:
        state.intl.messages.containers.features.awardCabinet.awardPartLabels,
      buttons: state.intl.messages.components.buttons.common,
    },
  };
};

export default connect(mapStateToProps)(ColorPickerModal);
