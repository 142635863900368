import trainingDiaryLevel1 from "./one_to_five_level_5.svg";
import trainingDiaryLevel2 from "./one_to_five_level_4.svg";
import trainingDiaryLevel3 from "./one_to_five_level_3.svg";
import trainingDiaryLevel4 from "./one_to_five_level_2.svg";
import trainingDiaryLevel5 from "./one_to_five_level_1.svg";
import stressLevel1 from "./stress_level_1.svg";
import stressLevel2 from "./stress_level_2.svg";
import stressLevel3 from "./stress_level_3.svg";
import stressLevel4 from "./stress_level_4.svg";
import stressLevel5 from "./stress_level_5.svg";
import editItem from "./edit_item.svg";
import deleteItem from "./delete_item.svg";
import archiveItem from "./archive_item.svg";

const buttons = {
  trainingDiaryLevel1,
  trainingDiaryLevel2,
  trainingDiaryLevel3,
  trainingDiaryLevel4,
  trainingDiaryLevel5,
  stressLevel1,
  stressLevel2,
  stressLevel3,
  stressLevel4,
  stressLevel5,
  editItem,
  deleteItem,
  archiveItem,
};

export default buttons;
