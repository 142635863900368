import React from "react";
import { connect } from "react-redux";
import { litters as actions } from "actions/index";
import Section from "components/layout/Section";
import BoxWithBackground from "components/styled/Box_With_Background";
import AddLitterMemberForm from "forms/Add_Litter_Member_Form";
import FormWrapper from "components/layout/Form_Wrapper";

function AddLitterMember(props) {
  const { addLitterMember, history, litter, locale, userPreferredUnit } = props;

  function handleAddLitterMember(values, setSubmitting) {
    const successRoute = "/userhome/dashboard/litter";
    addLitterMember(values, setSubmitting, history, successRoute);
  }

  if (!litter) return null;

  return (
    <Section>
      <FormWrapper>
        <BoxWithBackground>
          <AddLitterMemberForm
            valuesToEdit={{}}
            litter={litter}
            addLitterMember={handleAddLitterMember}
            locale={locale}
            userPreferredUnit={userPreferredUnit}
          />
        </BoxWithBackground>
      </FormWrapper>
    </Section>
  );
}

const mapStateToProps = (state) => {
  return {
    litter: state.reducers.litters.selectedLitter,
    locale: state.intl.locale,
    userPreferredUnit: state.reducers.auth.user.featurePreferences.weightUnit,
  };
};

export default connect(mapStateToProps, actions)(AddLitterMember);
