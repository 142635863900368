import React, { Component } from "react";
import { Pie } from "react-chartjs-2";

class PieChart extends Component {
  render() {
    const { data, options, maintainAspectRatio } = this.props;
    return (
      <div className="box" style={{ paddingBottom: 24 }}>
        <Pie
          data={{ ...data }}
          height={300}
          options={{
            ...options,
            responsive: true,
            maintainAspectRatio: maintainAspectRatio,
            legend: {
              position: "top",
              labels: {
                boxWidth: 10,
              },
            },
          }}
        />
      </div>
    );
  }
}

export default PieChart;
