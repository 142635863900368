import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bodyMarker as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import FormButtons from "components/form/FormButtons";
import { INPUT_FIELDS } from "./fields";
import { FormTitle } from "components/form";

function BodyMarkerDetail(props) {
  const {
    errors,
    dirty,
    isSubmitting,
    viewMode,
    editMode,
    cancel,
    resetForm,
    values,
  } = props;

  function handleCancel() {
    if (editMode) {
      resetForm();
      cancel();
    } else {
      cancel();
    }
  }

  const renderFields = () => {
    return INPUT_FIELDS.map((field, i) => {
      return (
        <div key={i}>
          <Field
            values={values}
            viewMode={viewMode}
            editMode={editMode}
            {...field}
          />
          {field.lastInGroup && <br />}
        </div>
      );
    });
  };

  return (
    <Form>
      <FormTitle group={"bodyMarkerDetail"} />
      {renderFields()}
      <FormButtons
        viewMode={viewMode}
        editMode={editMode}
        isSubmitting={isSubmitting}
        errors={errors}
        dirty={dirty}
        cancel={handleCancel}
        useCancel
      />
    </Form>
  );
}

const EnhancedBodyMarkerDetail = withFormik({
  mapPropsToValues({ valuesToEdit, _pet, _bodyMarker }) {
    return {
      bodyMarkerDetailType: valuesToEdit.bodyMarkerDetailType || "",
      description: valuesToEdit.description || "",
      attachmentURL: valuesToEdit.attachmentURL || "",
      painArea: valuesToEdit.painArea || false,
      sampleTaken: valuesToEdit.sampleTaken || false,
      removedOrHealed: valuesToEdit.removedOrHealed || false,
      _pet: valuesToEdit._pet || _pet,
      _bodyMarker: valuesToEdit._bodyMarker || _bodyMarker,
      _id: valuesToEdit._id || null,
    };
  },
  validationSchema: Yup.object().shape({
    bodyMarkerDetailType: Yup.string().required("required"),
    description: Yup.string().max(1000, "max-1000").required("required"),
    attachmentURL: Yup.string().url("url"),
    painArea: Yup.boolean(),
    sampleTaken: Yup.boolean(),
    removedOrHealed: Yup.boolean(),
  }),
  handleSubmit(values, { props }) {
    if (props.editMode) {
      return props.updateMarkerDetail(values, props.providerView);
    }
    if (props.initialMarkerDetails) {
      return props.addMarker(
        props.marker,
        values,
        props.providerContact,
        props.providerView
      );
    }
    props.addMarkerDetail(values, props.providerContact, props.providerView);
  },
})(BodyMarkerDetail);

export default withRouter(connect(null, actions)(EnhancedBodyMarkerDetail));
