import { features } from "assets"
import Landing from "components/features/Landing"
import NewReport from "containers/features/Reporting/New_Report_View"
import ReportRequests from "containers/features/Reporting/Report_Requests"

const reportingConfig = {
  name: "reporting",
  type: "litterMemberFeature",
  defaultRoute: "/userhome/dashboard/litter/litter_member_dashboard/reporting",
  menuIcon: { img: features.reporting.main, alt: "reporting_icon" },
  nav: [
    {
      name: "landing",
      disabled: false,
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      name: "newReport",
      disabled: false,
      menuItem: true,
      image: {
        img: features.reporting.newReport,
        alt: "reporting_new_report_icon",
      },
      icon: "far fa-plus-square",
      component: NewReport,
      path: "/new_report",
    },
    {
      name: "reportRequests",
      disabled: false,
      menuItem: true,
      image: {
        img: features.reporting.viewReportRequests,
        alt: "reporting_report_requests_icon",
      },
      icon: "fas fa-copy",
      component: ReportRequests,
      path: "/report_requests",
    },
  ],
}

export default reportingConfig
