export const FETCH_BUTTONS = [
  {
    name: "last24Hrs",
    value: 1,
    unit: "days",
    className: "button is-small is-warning is-rounded",
  },
  {
    name: "last7Days",
    value: 7,
    unit: "days",
    className: "button is-small is-warning is-rounded",
  },
  {
    name: "last30Days",
    value: 30,
    unit: "days",
    className: "button is-small is-warning is-rounded",
  },
  {
    name: "last3Months",
    value: 3,
    unit: "months",
    className: "button is-small is-warning is-rounded",
  },
  {
    name: "last1Year",
    value: 1,
    unit: "year",
    className: "button is-small is-warning is-rounded",
  },
];
