import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { hcpi as actions } from "actions/index";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import moment from "moment";
import { INPUT_FIELDS } from "forms/fields/hcpi";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";
import { FormTitle, FormButtons } from "components/form";

class HCPIMetaDataForm extends Component {
  state = { showFields: false };

  renderFields() {
    const { viewMode, editMode, values } = this.props;
    if (!this.state.showFields) return null;
    return INPUT_FIELDS.map((field, i) => {
      if (
        !values.medicine &&
        (field.name === "medicine1" ||
          field.name === "medicine2" ||
          field.name === "medicine3")
      )
        return null;
      return (
        <div key={i}>
          <Field
            values={values}
            viewMode={viewMode}
            editMode={editMode}
            {...field}
          />
          {field.lastInGroup && <br />}
        </div>
      );
    });
  }

  render() {
    const { errors, dirty, isSubmitting, viewMode, editMode, cancel } =
      this.props;
    return (
      <div>
        <Buttons className="is-centered">
          <Button
            group="common"
            name={viewMode ? "showDetails" : "showFormAdditionalFields"}
            onClick={() =>
              this.setState({ showFields: !this.state.showFields })
            }
          />
        </Buttons>
        <Form>
          <FormTitle group={"hcpi"} />
          <br />
          {this.renderFields()}
          <br />
          <FormButtons
            viewMode={viewMode}
            editMode={editMode}
            isSubmitting={isSubmitting}
            errors={errors}
            dirty={dirty}
            cancel={cancel}
            linkTo={"/userhome/dashboard/hcpi"}
          />
          <br />
        </Form>
      </div>
    );
  }
}

HCPIMetaDataForm = withFormik({
  mapPropsToValues({ valuesToEdit, pet, usedqrytemplate }) {
    return {
      usedqrytemplate,
      date:
        moment(valuesToEdit.date).format("YYYY-MM-DD") ||
        moment().format("YYYY-MM-DD"),
      time: valuesToEdit.time || moment().format("HH:mm"),
      notes: valuesToEdit.notes || "",
      diagnosis: valuesToEdit.diagnosis || "",
      prevMedicine: valuesToEdit.prevMedicine
        ? moment(valuesToEdit.prevMedicine).format("YYYY-MM-DD")
        : "",
      moveProblems: valuesToEdit.moveProblems || 0,
      lifeQuality: valuesToEdit.lifeQuality || 0,
      medicine: valuesToEdit.medicine || false,
      medicine1: valuesToEdit.medicine1 || "",
      medicine2: valuesToEdit.medicine2 || "",
      medicine3: valuesToEdit.medicine3 || "",
      _pet: valuesToEdit._pet || pet._id,
      _id: valuesToEdit._id || "",
    };
  },
  validationSchema: Yup.object().shape({
    date: Yup.date().required("required"),
    time: Yup.string().required("required"),
    notes: Yup.string().max(250, "max-250"),
    diagnosis: Yup.string().max(250, "max-250"),
    prevMedicine: Yup.date(),
    moveProblems: Yup.number(),
    lifeQuality: Yup.number(),
    medicine: Yup.boolean(),
    medicine1: Yup.string().when("medicine", {
      is: true,
      then: Yup.string().required("required").max(30, "max-30"),
      otherwise: Yup.string(),
    }),
    medicine2: Yup.string().when("medicine", {
      is: true,
      then: Yup.string().max(30, "max-30"),
      otherwise: Yup.string(),
    }),
    medicine3: Yup.string().when("medicine", {
      is: true,
      then: Yup.string().max(30, "max-30"),
      otherwise: Yup.string(),
    }),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    const successRoute = "/userhome/dashboard/hcpi/view_results";
    if (props.editMode) {
      return props.updateHCPIMetaData(
        values,
        setSubmitting,
        props.history,
        successRoute
      );
    }
    const valuesWithResults = {
      ...values,
      points: props.answers.map((ans) => ans.v),
      totalPoints: _.sum(props.answers.map((ans) => (ans.calc ? ans.v : 0))),
    };
    props.submitResult(
      valuesWithResults,
      setSubmitting,
      props.history,
      successRoute
    );
  },
})(HCPIMetaDataForm);

export default withRouter(connect(null, actions)(HCPIMetaDataForm));
