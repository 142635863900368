import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Bounds from "components/bounds";
import * as actions from "actions/sys_message";
import Message from "components/message";
import { returnNewObjectAsArrayByValidKey } from "functions";

function SysMessage(props) {
  function renderSystemMessage(padding) {
    if (!props.sysMessages.sysMessage) return null;
    return (
      <div className="container is-widescreen" style={{ padding }}>
        <article className="message is-link">
          <div className="message-header">
            <button
              className="delete"
              onClick={() => this.props.dismissSystemMessage()}
            />
          </div>
          <div className="message-body"></div>
        </article>
      </div>
    );
  }

  function renderCardExpiringMessage(padding) {
    if (!props.sysMessages.showCardExpiringMessage) return null;

    if (!props.user.cardExpiring) return null;

    if (props.user.stripeActionType !== "updateCard") return null;

    return (
      <div className="container is-widescreen" style={padding}>
        <Message
          default
          color={"is-warning"}
          withHeader
          header={
            <button
              className="delete"
              aria-label="delete"
              onClick={() => props.dismissCardExpiringMessage()}
            />
          }
        >
          {props.warnings.cardExpiring.map((item, i) => {
            if (!item) return null;
            return <p key={i}>{item}</p>;
          })}
          <br />
          <Link
            to={"/userhome/user_account/subscription"}
            className="button is-link"
            onClick={() => props.dismissCardExpiringMessage()}
          >
            {props.buttons.goToAccount[props.locale]}
          </Link>
        </Message>
      </div>
    );
  }

  function renderCardRequiresActionMessage(padding) {
    if (!props.sysMessages.showCardRequiresActionMessage) return null;

    if (!props.user.stripeRequiresAction) return null;

    if (props.user.stripeActionType !== "SCA") return null;

    return (
      <div className="container is-widescreen" style={padding}>
        <Message
          default
          color={"is-warning"}
          withHeader
          header={
            <button
              className="delete"
              aria-label="delete"
              onClick={() => props.dismissCardRequiresActionMessage()}
            />
          }
        >
          {props.warnings.cardRequiresAction.map((item, i) => {
            if (!item) return null;
            return <p key={i}>{item}</p>;
          })}
          <br />
          <Link
            to={"/userhome/user_account/subscription"}
            className="button is-link"
            onClick={() => props.dismissCardRequiresActionMessage()}
          >
            {props.buttons.goToAccount[props.locale]}
          </Link>
        </Message>
      </div>
    );
  }

  return (
    <>
      <Bounds
        maxWidth="600"
        render={() => renderSystemMessage({ padding: 8 })}
        else={() => renderSystemMessage({ paddingBottom: 8 })}
      />
      <Bounds
        maxWidth="600"
        render={() => (
          <>
            {renderCardExpiringMessage({ padding: 8, paddingBottom: 18 })}
            {renderCardRequiresActionMessage({ padding: 8, paddingBottom: 18 })}
          </>
        )}
        else={() => (
          <>
            {renderCardExpiringMessage({ paddingBottom: 18 })}
            {renderCardRequiresActionMessage({ padding: 8, paddingBottom: 18 })}
          </>
        )}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  const { messages, locale } = state.intl;
  const cardExpiring = returnNewObjectAsArrayByValidKey(
    messages.components.messages.warnings,
    "cardExpiring_",
    locale
  );
  const cardRequiresAction = returnNewObjectAsArrayByValidKey(
    messages.components.messages.warnings,
    "cardRequiresAction_",
    locale
  );
  return {
    locale,
    warnings: { cardExpiring, cardRequiresAction },
    buttons: messages.components.buttons.common,
    sysMessages: state.reducers.sysMessages,
    user: state.reducers.auth.user,
  };
};

export default connect(mapStateToProps, actions)(SysMessage);
