import React from "react"

function Switch({ id, label, checked, onChange }) {
  return (
    <div className="field">
      <input
        id={id}
        type="checkbox"
        name="switchNormal"
        className="switch"
        checked={checked}
        onChange={(e) => onChange(e)}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export default Switch
