import _ from "lodash";

export const returnCharsLeft = (field, charLimit, values) => {
  const charsLength = _.size(values[field]);
  const charsLeft = charLimit - charsLength;
  return charsLeft;
};

export const objectIsEmpty = (obj) => {
  return _.isEmpty(obj);
};

export function difference(object, base) {
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] =
        _.isObject(value) && _.isObject(base[key])
          ? difference(value, base[key])
          : value;
    }
  });
}

export function mergeArraysOfObjects(arr1, arr2) {
  const merged = _.merge(arr1, arr2);
  return merged;
}

export function returnArrayOfObjectsGroupedByKey(array, key) {
  let newArr = _.groupBy(array, (arr) => arr[key]);
  return newArr;
}

export function returnArrayOfObjectsSortedByKey(key, arrayOfObjects) {
  return _.sortBy(arrayOfObjects, key);
}
