import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

class RoutedItem extends Component {
  render() {
    const { routedItems, defaultRoute } = this.props;
    return (
      <Switch>
        {routedItems.map((route, i) => (
          <Route
            key={i}
            exact={route.exact}
            path={`${defaultRoute}${route.path}`}
            render={() => (
              <route.component {...this.props} routeName={route.name} />
            )}
          />
        ))}
      </Switch>
    );
  }
}

export default RoutedItem;

RoutedItem.propTypes = {
  routes: PropTypes.object,
  routedItems: PropTypes.array,
  defaultRoute: PropTypes.string,
};
