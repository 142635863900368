import React from "react";
import Button from "components/elements/Button";

const RedeemCodeInput = (props) => {
  const { serverIsBusy } = props;
  const disabled = props.code.length === 0 ? true : false;

  return (
    <div id="redeem_code_input">
      <form>
        <div className="field is-grouped is-grouped-multiline">
          <p className="control is-expanded">
            <input
              id="redeemCodeInput"
              className="input"
              type="text"
              value={props.code}
              onChange={(e) => props.onChange(e)}
            />
          </p>
          <p className="control">
            <Button
              onClick={(e) => props.onClick(e)}
              className={`is-info ${serverIsBusy && "is-loading"}`}
              disabled={disabled}
              group="common"
              name="redeemCode"
            />
          </p>
        </div>
      </form>
    </div>
  );
};

export default RedeemCodeInput;
