import React from "react"

import PartnerTiles from "./PartnerTiles"
import GlobalTiles from "./GlobalTiles"

const columnClasses = {
  columns: "columns is-2 is-variable is-mobile is-multiline",
  column: "column is-2-desktop is-4-mobile is-3-tablet",
}

function GlobalAndPartnerTiles() {
  return (
    <div>
      <hr />
      <div className={columnClasses.columns}>
        <PartnerTiles column={columnClasses.column} />
      </div>
      <div className={columnClasses.columns}>
        <GlobalTiles column={columnClasses.column} />
      </div>
    </div>
  )
}

export default GlobalAndPartnerTiles
