import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "actions/litter_expenses";

function ExpenseItem(props) {
  const { litterExpensesDelete, children, expense, history, buttons, locale } =
    props;

  const [deleteMe, togggleToDelete] = useState(false);

  function handleDeleteToggle() {
    togggleToDelete(!deleteMe);
  }

  function handleActualDelete() {
    litterExpensesDelete(
      expense._id,
      history.push("/userhome/dashboard/litter/expenses/view_expenses")
    );
  }

  return (
    <div className="card">
      <div className="card-content">
        <div className="content">{children.map((child) => child)}</div>
      </div>
      <footer className="card-footer">
        <div className="card-footer-item">
          <Link
            to={{
              pathname: "/userhome/dashboard/litter/expenses/view_item",
              _id: expense._id,
            }}
            className="button is-small"
          >
            {buttons.edit[locale]}
          </Link>
        </div>
        <div className="card-footer-item">
          <button
            className={`button is-small ${deleteMe && "is-warning"}`}
            onClick={() => handleDeleteToggle()}
          >
            {!deleteMe ? buttons.delete[locale] : buttons.cancel[locale]}
          </button>
        </div>
      </footer>
      {deleteMe && (
        <footer className="card-footer">
          <div className="card-footer-item">
            <button
              className={`button is-small is-fullwidth is-danger`}
              onClick={() => handleActualDelete()}
            >
              {buttons.confirmDelete[locale]}
            </button>
          </div>
        </footer>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    buttons: state.intl.messages.components.buttons.common,
  };
};

export default withRouter(connect(mapStateToProps, actions)(ExpenseItem));
