import React from "react"

const Notification = props => (
  <div
    style={{ padding: 12 }}
    className={`notification is-white ${props.centered &&
      "has-text-centered"} `}
  >
    {props.children}
  </div>
)

export default Notification
