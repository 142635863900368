import { features } from "assets"
import { actionTypes } from "./actions"
import nav from "./routes"

const activityConfig = {
  name: "activity",
  type: "feature",
  defaultRoute: "/userhome/dashboard/activity",
  menuIcon: {
    img: features.activity.main,
    alt: "activity_feature_icon",
  },
  nav,
  actionTypes,
  tableView: {
    headers: ["date", "category", "activity", "totalTimeInSeconds"],
    mobileHeaders: ["date", "category", "activity", "totalTimeInSeconds"],
  },
  chartView: {},
}

export default activityConfig
