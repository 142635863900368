import axios from "axios"
import moment from "moment"
import _ from "lodash"
import {
  HEALTH_DIARY_FETCH,
  HEALTH_DIARY_FETCH_TYPE,
  HEALTH_DIARY_ADD,
  HEALTH_DIARY_UPDATE,
  HEALTH_DIARY_DELETE,
  ACTION_STARTED,
  ACTION_SUCCESS,
  MODALS_HIDE,
  TABLE_CLEAR_CURRENT_STATE,
} from "./types"
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "./functions"

const API = (route) => `/api/health_diary/${route}`

// Action Creators
export const fetchHealthDiaryEntries = (
  value,
  unit,
  name,
  pet,
  providerView
) => async (dispatch) => {
  const dates = !_.isEmpty(value)
    ? { ...value }
    : {
        from: moment().subtract(value, unit).toISOString(),
        to: moment().toISOString(),
      }
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({
      type: HEALTH_DIARY_FETCH_TYPE,
      payload: { dates, type: !_.isEmpty(value) ? "range" : name },
    })
    dispatch({ type: ACTION_STARTED, payload: HEALTH_DIARY_FETCH })
    const res = await axios.get(
      API(providerView ? "fetch_for_provider" : "fetch"),
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
        params: { _pet: pet._id, ...dates },
      }
    )
    dispatch({
      type: HEALTH_DIARY_FETCH,
      payload: res.data.healthDiaryEntries,
      pet,
    })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addHealthDiaryEntry = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerContact,
  providerView
) => async (dispatch) => {
  let route =
    values.type === "health" ? "add_health_entry" : "add_medicine_entry"
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HEALTH_DIARY_ADD })
    const res = await axios.post(
      API(providerView ? `${route}_by_provider` : route),
      { _user: providerView ? providerContact._id : undefined, ...values },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    setSubmitting(false)
    dispatch({ type: HEALTH_DIARY_ADD, payload: res.data.healthDiaryEntry })
    endAndResetAction(dispatch)
    history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const updateHealthDiaryEntry = (
  values,
  setSubmitting,
  history,
  successRoute,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HEALTH_DIARY_UPDATE })
    const res = await axios.put(
      API(providerView ? "update_by_provider" : "update"),
      values,
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({ type: HEALTH_DIARY_UPDATE, payload: res.data.result })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    setSubmitting(false)
    catchBlockError(error, dispatch)
  }
}

export const deleteHealthDiaryEntry = (
  _id,
  type,
  history,
  successRoute,
  providerView
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HEALTH_DIARY_DELETE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      API(providerView ? "delete_by_provider" : "delete"),
      { _id, type },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({ type: HEALTH_DIARY_DELETE, payload: res.data.result })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const healthDiaryActions = {
  fetchHealthDiaryEntries,
  addHealthDiaryEntry,
  updateHealthDiaryEntry,
  deleteHealthDiaryEntry,
}

export default healthDiaryActions
