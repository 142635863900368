import React from "react"
import Tag from "components/elements/Tag"
import TagGroup from "components/elements/TagGroup"

function AllTags(props) {
  const tags = !!props.filterBy
    ? props.tags.filter((t) => t.name.includes(props.filterBy))
    : props.tags

  return (
    <>
      <div className="title is-6 has-text-grey">{props.title}</div>
      <TagGroup>
        {tags.map((t) => (
          <Tag key={t.id} label={t.name} onClick={() => props.onClick(t.id)} />
        ))}{" "}
      </TagGroup>
    </>
  )
}

export default AllTags
