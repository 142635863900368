import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPassword } from "actions/auth";
import { resetPasswordProvider } from "containers/auth/Pet_Services_Portal/actions";
import { bindActionCreators } from "redux";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { INPUT_FIELDS } from "forms/fields/reset_password";
import { FormButtons, FormTitle } from "components/form";

class ResetPasswordForm extends Component {
  renderFields() {
    return INPUT_FIELDS.map((field, i) => {
      return <Field key={i} {...field} autoComplete={"new-password"} />;
    });
  }

  render() {
    const { isSubmitting, errors, dirty } = this.props;

    return (
      <Form>
        <br />
        <FormTitle group={"resetPassword"} />
        {this.renderFields()}
        <br />
        <FormButtons
          isSubmitting={isSubmitting}
          errors={errors}
          dirty={dirty}
          linkTo={"/"}
        />
        <br />
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetPassword,
      resetPasswordProvider,
    },
    dispatch
  );

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: "equalTo",
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return value === this.resolve(ref);
    },
  });
}
Yup.addMethod(Yup.string, "equalTo", equalTo);
ResetPasswordForm = withFormik({
  mapPropsToValues() {
    return {
      resetPasswordCode: "",
      newPassword: "",
      newPassword2: "",
    };
  },
  validationSchema: Yup.object().shape({
    resetPasswordCode: Yup.string()
      .required("required")
      .min(12, "resetPasswordCodeLength")
      .max(12, "resetPasswordCodeLength")
      .trim(),
    newPassword: Yup.string().min(8, "min-8").required("required"),
    newPassword2: Yup.string()
      .equalTo(Yup.ref("newPassword"), "passwordsMustMatch")
      .required("required"),
  }),
  handleSubmit(values, { props, setSubmitting, resetForm }) {
    if (props.user) {
      return props.resetPassword(
        {
          ...values,
          resetPasswordCode: values.resetPasswordCode.trim(),
        },
        setSubmitting,
        resetForm,
        props.history
      );
    }
    if (props.provider) {
      return props.resetPasswordProvider(
        {
          ...values,
          resetPasswordCode: values.resetPasswordCode.trim(),
        },
        setSubmitting,
        resetForm,
        props.history
      );
    }
  },
})(ResetPasswordForm);

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
