import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import { AUTH_LOG_OUT_USER } from "actions/types";
import Section from "components/layout/Section";
import ChangePasswordForm from "forms/Change_Password_Form";
import TileButtons from "components/tile_buttons";
import { user } from "assets";
import { AUTH_LOG_OUT_PROVIDER } from "../Pet_Services_Portal/actions";
import BoxWithBackground from "components/styled/Box_With_Background";
import Intl from "components/Intl";

class Password extends Component {
  state = { changePassword: false, resetPassword: false };

  UNSAFE_componentWillMount() {
    if (!this.props.isAuthed) {
      this.props.history.push("/");
    }
  }

  handleUpdateState = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  renderContent() {
    return (
      <>
        {this.state.changePassword ? (
          <ChangePassword
            {...this.props}
            handleCancel={this.handleUpdateState}
          />
        ) : this.state.resetPassword ? (
          <ResetPassword
            {...this.props}
            handleCancel={this.handleUpdateState}
          />
        ) : (
          <Section>
            <TileButtons
              view={"passwordManagement"}
              tileButtons={[
                {
                  name: "changePassword",
                  isLink: false,
                  props: {
                    to: null,
                    onClick: () => this.handleUpdateState("changePassword"),
                  },
                  imgObj: {
                    img: user.passwordUpdate,
                    alt: "update_password_icon",
                  },
                  useIcon: false,
                  iconObj: { icon: "fas fa-home", size: "fa-3x" },
                },
                {
                  name: "resetPassword",
                  isLink: false,
                  props: {
                    to: null,
                    onClick: () => this.handleUpdateState("resetPassword"),
                  },
                  imgObj: {
                    img: user.passwordReset,
                    alt: "password_reset_icon",
                  },
                },
              ]}
            />
          </Section>
        )}
      </>
    );
  }

  render() {
    return this.renderContent();
  }
}

const ChangePassword = (props) => {
  if (props.provider)
    return (
      <BoxWithBackground squareBorder>
        <br />
        <div className="columns is-mobile is-centered">
          <div className="column is-5-desktop is-full-mobile is-6-tablet">
            <ChangePasswordForm
              provider
              history={props.history}
              cancel={() => props.handleCancel("changePassword")}
            />
          </div>
        </div>
        <br />
      </BoxWithBackground>
    );

  return (
    <Section>
      <div className="columns is-mobile is-centered">
        <div className="column is-5-desktop is-full-mobile is-6-tablet">
          <ChangePasswordForm
            user
            history={props.history}
            cancel={() => props.handleCancel("changePassword")}
          />
        </div>
      </div>
    </Section>
  );
};

const ResetPassword = ({
  messages,
  links,
  buttons,
  locale,
  user,
  provider,
  dispatch,
  history,
  handleCancel,
}) => {
  return (
    <Section>
      <div className="message is-info">
        <div className="message-body">
          <p className="title is-6 has-text-grey">
            <Intl message path="components.messages.warnings.resetPassword" />
          </p>
          <div className="buttons">
            {!_.isEmpty(user) && (
              <Link
                to={"/forgot_password"}
                className="button is-info is-outlined"
                onClick={() => dispatch({ type: AUTH_LOG_OUT_USER })}
              >
                {buttons.logMeOut[locale]}
              </Link>
            )}
            {!_.isEmpty(provider) && (
              <Link
                to={"/pet_services_portal/forgot_password"}
                className="button is-info is-outlined"
                onClick={() => dispatch({ type: AUTH_LOG_OUT_PROVIDER })}
              >
                {buttons.logMeOut[locale]}
              </Link>
            )}
            <button
              className="button is-warning"
              onClick={() => handleCancel("resetPassword")}
            >
              {buttons.cancel[locale]}
            </button>
          </div>
        </div>
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    user: _.isEmpty(state.reducers.auth.user)
      ? false
      : state.reducers.auth.user,
    provider: _.isEmpty(state.reducers.auth.provider)
      ? false
      : state.reducers.auth.provider,
    isAuthed: state.reducers.auth.isAuthenticated,
    locale: state.intl.locale,
    buttons: {
      ...state.intl.messages.components.buttons.common,
      ...state.intl.messages.components.buttons.passwordManagement,
    },
    /*     messages: state.intl.messages.containers.auth.userAccount.password, */
    actionCalls: state.reducers.actionCalls,
  };
};

export default withRouter(connect(mapStateToProps)(Password));
