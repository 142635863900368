import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ACTION_RESET, AUTH_GOOGLE_LOG_IN } from "actions/types";
import Loading from "components/spinners/Loading";
import RegisterButtonsView from "./Register_Buttons_View";
import RegisterFormView from "./Register_Form_View";

class Register extends Component {
  state = {
    localSignup: false,
  };

  UNSAFE_componentWillMount() {
    this.props.dispatch({ type: ACTION_RESET });
  }

  handleLocalSignupClick = () => {
    this.setState({
      localSignup: !this.state.localSignup,
    });
  };

  renderContent() {
    const { actionCalls } = this.props;

    if (
      actionCalls.action === AUTH_GOOGLE_LOG_IN &&
      actionCalls.actionInProcess
    ) {
      return <Loading />;
    }

    if (this.state.localSignup) return <RegisterFormView {...this.props} />;

    return (
      <RegisterButtonsView
        handleLocalSignupClick={this.handleLocalSignupClick}
      />
    );
  }

  render() {
    return (
      <div className="columns is-mobile is-centered">
        <div className="column is-5-desktop is-12-mobile is-5-tablet">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { dispatch };
};

const mapStateToProps = (state) => {
  return {
    actionCalls: state.reducers.actionCalls,
    locale: state.intl.locale,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Register));
