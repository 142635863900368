import { features } from "assets";
import ViewResults from "./views/View_Results";
import ViewResult from "./views/View_Result";
import { path } from "constants/feature_routes_provider_view";

const fmpiConfig = {
  name: "fmpi", // Feline Musculoskeletal Pain Index, the FMPI
  type: "feature",
  chartFunc: "hcpiShowAll",
  defaultRoute: path("fmpi"),
  menuIcon: { img: features.fmpi.main, alt: "fmpi" },
  providerView: true,
  providerEdit: false,
  noNav: true,
  nav: [
    {
      name: "viewResults",
      disabled: false,
      exact: true,
      image: {
        img: features.hcpi.viewResults,
        alt: "hcpi_view_results_icon",
      },
      icon: "fas fa-copy",
      component: ViewResults,
      path: "/",
    },
    {
      name: "viewResult",
      disabled: false,
      component: ViewResult,
      path: "/view_result/:id",
    },
  ],
  viewResult: {
    colors: {},
    results: (score) => {
      return score < 20
        ? {
            color: "#a3f28d",
            text: "noPain",
          }
        : score >= 20 && score <= 21
        ? {
            color: "#fede87",
            text: "probablePain",
          }
        : score > 22 && {
            color: "#faaca6",
            text: "distinctPain",
          };
    },
  },
  tableView: {
    headers: ["date", "time", "medicine", "totalPoints"],
    mobileHeaders: ["date", "time", "medicine", "totalPoints"],
    tableKeys: [
      { label: "0 - 19", text: "noPain", color: "#a3f28d" },
      { label: "20 - 28", text: "probablePain", color: "#fede87" },
      { label: "29 +", text: "distinctPain", color: "#faaca6" },
    ],
    cellStyle: {
      totalPoints: (score) => {
        return score < 20
          ? {
              backgroundColor: "#a3f28d",
              maxWidth: 40,
              margin: "auto",
              padding: 4,
              borderRadius: 10,
            }
          : score >= 20 && score <= 21
          ? {
              backgroundColor: "#fede87",
              maxWidth: 40,
              margin: "auto",
              padding: 4,
              borderRadius: 10,
            }
          : score > 22 && {
              backgroundColor: "#faaca6",
              maxWidth: 40,
              margin: "auto",
              padding: 4,
              borderRadius: 10,
            };
      },
    },
  },
};

export default fmpiConfig;
