import { features } from "assets";
import Landing from "components/features/Landing";
import NewEntryView from "./New_Entry_View";
import NewEntryHealth from "./Health_Entry";
import NewEntryMedicine from "./Medicine_Entry";
import NewEntryTemperature from "./Temperature_Entry";
import ViewEntriesView from "./View_Entries_View";
import ViewEntriesHealth from "./View_Entries_Health";
import ViewEntriesMedicine from "./View_Entries_Medicine";
import ViewEntriesTemperature from "./View_Entries_Temperature";
import ViewEntry from "./View_Entry";
import ViewEntryTemperature from "./View_Entry_Temperature";

const healthDiaryConfig = {
  name: "healthDiary",
  type: "feature",
  chartFunc: "temperature",
  defaultRoute: "/userhome/dashboard/health_diary",
  menuIcon: { img: features.healthDiary.main, alt: "health_diary_icon" },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "newEntry",
      menuItem: true,
      image: {
        img: features.healthDiary.newEntry,
        alt: "health_diary_new_entry_icon",
      },
      icon: "fas fa-heart",
      component: NewEntryView,
      path: "/new_entry",
    },
    {
      disabled: false,
      name: "newEntryHealth",
      menuItem: false,
      image: {
        img: features.healthDiary.newEntryHealth,
        alt: "health_diary_new_entry_health_icon",
      },
      icon: "fas fa-heart",
      component: NewEntryHealth,
      path: "/new_entry_health",
    },
    {
      disabled: false,
      name: "newEntryMedicine",
      menuItem: false,
      image: {
        img: features.healthDiary.newEntryMedicine,
        alt: "health_diary_new_entry_medicine_icon",
      },
      icon: "fas fa-syringe",
      component: NewEntryMedicine,
      path: "/new_entry_medicine",
    },
    {
      disabled: false,
      name: "newEntryTemperature",
      menuItem: false,
      image: {
        img: features.healthDiary.newEntryTemperature,
        alt: "health_diary_new_entry_temperature_icon",
      },
      icon: "fas fa-syringe",
      component: NewEntryTemperature,
      path: "/new_entry_temperature",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.healthDiary.viewEntries,
        alt: "health_diary_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntriesView,
      path: "/view_entries",
    },
    {
      disabled: false,
      name: "viewEntriesHealth",
      menuItem: false,
      image: {
        img: features.healthDiary.viewEntriesHealth,
        alt: "health_diary_view_entries_health_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntriesHealth,
      path: "/view_entries_health",
    },
    {
      disabled: false,
      name: "viewEntriesMedicine",
      menuItem: false,
      image: {
        img: features.healthDiary.viewEntriesMedicine,
        alt: "health_diary_view_entries_medicine_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntriesMedicine,
      path: "/view_entries_medicine",
    },
    {
      disabled: false,
      name: "viewEntriesTemperature",
      menuItem: false,
      image: {
        img: features.healthDiary.viewEntriesTemperature,
        alt: "health_diary_view_entries_temperature_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntriesTemperature,
      path: "/view_entries_temperature",
    },
    {
      disabled: false,
      name: "viewEntry",
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
    {
      disabled: false,
      name: "viewEntryTemperature",
      menuItem: false,
      component: ViewEntryTemperature,
      path: "/view_entry_temperature/:id",
    },
  ],
  tableView: {
    health: {
      headers: ["date", "subject", "notes"],
      mobileHeaders: ["date", "subject", "notes"],
    },
    medicine: {
      headers: ["date", "medicine", "reasonForMedicine", "onGoing"],
      mobileHeaders: ["date", "medicine", "reasonForMedicine", "onGoing"],
    },
    temperature: {
      headers: ["date", "time", "medicine", "temperature"],
      mobileHeaders: ["date", "time", "medicine", "temperature"],
    },
  },
  chart: {
    temperature: {
      withChart: true,
      chartFunc: "temperature",
      chartData: {
        chartFunc: "temperature",
        ticks: {
          stepSize: 0.5,
          min: 35,
          max: 42,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    },
  },
};

export default healthDiaryConfig;
