import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Pagination from "./Pagination";
import {
  TABLE_STORE_CURRENT_STATE,
  TABLE_CLEAR_CURRENT_STATE,
} from "actions/types";
import Cells from "./Cells";
import Filters from "./Filters";
import TableKey from "./Table_Key";
import Chart from "components/chart";
import { chartDataFunctions } from "functions/chart_data";
import { checkIfThereIsOnlyOneElement } from "functions";

class EntriesTable extends Component {
  state = {
    loading: true,
    pages: 0,
    page: 1,
    headers: [],
    data: [],
    arrPos: 0,
    mobileHeaders: [],
    filterByItems: [],
    filterBy: "",
    filterKey: "",
    customFilter1: {},
    customFilter2: {},
    customFilter3: {},
  };

  componentDidMount() {
    if (
      !_.isEmpty(this.props.tableDataStore.currentState) &&
      this.props.tableDataStore.pet === this.props.pet &&
      this.props.tableDataStore.feature === this.props.tableData.feature
    ) {
      return this.setState({ ...this.props.tableDataStore.currentState });
    }
    this.props.dispatch({ type: TABLE_CLEAR_CURRENT_STATE });
    this.updateState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.tableData.id !== prevProps.tableData.id) {
      this.updateState();
    }
  }

  updateState = () => {
    const pages = Math.ceil(
      this.props.tableData.data.length / this.props.itemsPerPage
    );

    this.setState({
      headers: this.props.tableData.headers,
      data: this.props.tableData.data,
      filteredData: this.props.tableData.data,
      messages: this.props.tableData.messages,
      loading: false,
      pages,
      mobileHeaders: this.props.tableData.mobileHeaders,
      filterByItems: this.props.tableData.filterByItems || [],
      filterKey: this.props.tableData.filterKey || "",
      itemsPerPage: this.props.itemsPerPage,
      filterBy: "",
      ...this.props.tableData.customFilters,
    });
  };

  storeState = (state) =>
    this.props.dispatch({
      type: TABLE_STORE_CURRENT_STATE,
      payload: {
        currentState: state,
        pet: this.props.pet,
        feature: this.props.tableData.feature,
      },
    });

  handleNextPage = () => {
    this.setState(
      {
        page: this.state.page + 1,
        arrPos: this.state.arrPos + this.state.itemsPerPage,
      },
      () => this.storeState(this.state)
    );
  };

  handlePrevPage = () => {
    this.setState(
      {
        page: this.state.page - 1,
        arrPos: this.state.arrPos - this.state.itemsPerPage,
      },
      () => this.storeState(this.state)
    );
  };

  handleGotoPage = (page) => {
    this.setState(
      {
        page,
        arrPos: this.state.itemsPerPage * page - this.state.itemsPerPage,
      },
      () => this.storeState(this.state)
    );
  };

  handleSetFilterBy = (e) => {
    let newState = {
      ...this.state,
      filterBy: e.target.value,
      customFilter1: !_.isEmpty(this.state.customFilter1)
        ? {
            ...this.state.customFilter1,
            filterBy: "",
          }
        : {},
      customFilter2: !_.isEmpty(this.state.customFilter2)
        ? { ...this.state.customFilter2, filterBy: "" }
        : {},
      customFilter3: !_.isEmpty(this.state.customFilter3)
        ? { ...this.state.customFilter3, filterBy: "" }
        : {},
    };
    newState.filteredData =
      newState.filterBy === ""
        ? newState.data
        : newState.data.filter(
            (obj) => obj[newState.filterKey] === newState.filterBy
          );

    newState.pages = Math.ceil(
      newState.filteredData.length / newState.itemsPerPage
    );
    newState.page = 1;
    newState.arrPos = 0;

    this.setState({
      ...newState,
    });
  };

  handleSetCustomFilterBy1 = (e) => {
    let newState = {
      ...this.state,
      customFilter1: { ...this.state.customFilter1, filterBy: e.target.value },
    };
    newState.filteredData = newState.customFilter1.applyCustomFilter(
      newState.customFilter1.filterBy,
      newState.data.filter(
        (obj) => obj[newState.filterKey] === newState.filterBy
      )
    );
    newState.customFilter1Data = newState.filteredData;
    this.setState({
      ...newState,
    });
    this.storeState(newState);
  };

  handleSetCustomFilterBy2 = (e) => {
    let newState = {
      ...this.state,
      customFilter2: { ...this.state.customFilter2, filterBy: e.target.value },
    };
    newState.filteredData = newState.customFilter2.applyCustomFilter(
      newState.customFilter2.filterBy,
      newState.customFilter1Data
    );
    this.setState({
      ...newState,
    });
    this.storeState(newState);
  };

  handleSetCustomFilterBy3 = (e) => {
    // TO DO
  };

  render() {
    if (this.state.loading) return null;
    const {
      messages,
      tableKeys,
      locale,
      withCharts,
      withKeys,
      chartFunc,
      feature,
      userPreferredUnit,
    } = this.props;
    const { mobileHeaders } = this.state;
    const isHiddenMobile = (name) => mobileHeaders.includes(name);
    return (
      <>
        {/* Table Pagination */}
        {this.state.pages > 1 && (
          <Pagination
            nextPage={this.handleNextPage}
            prevPage={this.handlePrevPage}
            gotoPage={this.handleGotoPage}
            {...this.state}
          />
        )}
        {/* Table Filters */}
        <Filters
          {...this.state}
          locale={locale}
          handleSetFilterBy={this.handleSetFilterBy}
          handleSetCustomFilterBy1={this.handleSetCustomFilterBy1}
          handleSetCustomFilterBy2={this.handleSetCustomFilterBy2}
          handleSetCustomFilterBy3={this.handleSetCustomFilterBy3}
        />
        {/* Table */}
        <table className="table is-mobile is-narrow is-hoverable is-striped is-fullwidth">
          <thead>
            <tr>
              {this.state.headers.map((header, i) => {
                return (
                  <th
                    key={i}
                    className={`has-text-centered ${
                      !isHiddenMobile(header) && "is-hidden-mobile"
                    }`}
                  >
                    <abbr
                      title={messages.headers[header][locale]}
                      className="help is-uppercase"
                    >
                      {messages.headersAbbr[header][locale]}
                    </abbr>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.filteredData.map((item, i) => {
              if (
                i >= this.state.arrPos + this.state.itemsPerPage ||
                i < this.state.arrPos
              )
                return null;
              return (
                <tr key={i} style={{ cursor: "pointer" }}>
                  {this.state.headers.map((header, i) => (
                    <td
                      key={i}
                      className={`has-text-centered ${
                        !isHiddenMobile(header) && "is-hidden-mobile"
                      }`}
                    >
                      <div
                        style={
                          !this.props.cellStyle
                            ? {}
                            : this.props.cellStyle[header]
                            ? this.props.cellStyle[header](item[header])
                            : {}
                        }
                        className="help"
                        onClick={
                          header !== "attachmentURL"
                            ? () =>
                                this.props.history.push(
                                  this.props.tableData.viewEntryTo + item._id
                                )
                            : null
                        }
                      >
                        <Cells
                          cellType={header}
                          cellContent={item[header]}
                          {...this.state.messages}
                          locale={locale}
                          cellMessages={messages.cells}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* Table Keys */}
        {withKeys && <TableKey feature={feature} tableKeys={tableKeys} />}
        {/* Table Charts */}
        {withCharts && (
          <Chart
            data={chartDataFunctions[chartFunc](
              this.state.filteredData,
              messages.chart,
              locale,
              userPreferredUnit
            )}
            scales={{
              y: {
                ...this.props.ticks,
              },
            }}
            legendPosition="bottom"
            options={this.props.chartOptions}
          />
        )}
        {this.props.feature === "trainingDiary" &&
          ((checkIfThereIsOnlyOneElement(this.state.filterByItems) &&
            this.state.filterByItems[0] === "agility") ||
            this.state.filterBy === "agility") && (
            <Chart
              data={chartDataFunctions["trainingDiaryAgility"](
                this.state.filteredData.filter(
                  (obj) => obj.trainingType === "agility"
                ),
                messages.chart,
                locale
              )}
              scales={{
                y: {
                  ...this.props.ticks,
                  gridLines: {
                    drawTicks: false,
                    display: false,
                  },
                },
              }}
              legendPosition="bottom"
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    feature: ownProps.tableData.feature,
    tableDataStore: state.reducers.table,
    pet: state.reducers.pets.selectedPet._id,
    locale: state.intl.locale,
    messages: {
      ...ownProps.messages,
      ...state.intl.messages.components.table,
      chart: state.intl.messages.components.chart,
    },
  };
};

export default withRouter(connect(mapStateToProps)(EntriesTable));
