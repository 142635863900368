import React from "react"
import { features } from "assets"
import ViewEntries from "./views/View_Entries"
import ViewEntry from "./views/View_Entry"
import { actionTypes } from "./actions"
import { path } from "constants/feature_routes_provider_view"

const stressLevelProviderConfig = {
  name: "stressLevel",
  type: "feature",
  defaultRoute: path("stress_level"),
  actionTypes,
  menuIcon: {
    img: features.stressLevel.main,
    alt: "features.stressLevel.main",
  },
  providerView: true,
  providerEdit: false,
  noNav: true,
  nav: [
    {
      name: "viewEntries",
      disabled: false,
      menuItem: false,
      exact: true,
      image: {
        img: features.stressLevel.viewEntries,
        alt: "features.stressLevel.viewEntries",
      },
      icon: "",
      component: (props) => <ViewEntries {...props} />,
      path: "/",
    },
    {
      name: "viewEntry",
      disabled: false,
      menuItem: false,
      image: {
        img: features.stressLevel.main,
        alt: "features.stressLevel.main",
      },
      icon: "",
      component: (props) => <ViewEntry {...props} />,
      path: "/view_entry/:id",
    },
  ],
  fetchButtons: [
    "last7Days",
    "last14Days",
    "last30Days",
    "last3Months",
    "last1Year",
  ],
  tableView: {
    headers: ["date", "subject", "stressLevel"],
    mobileHeaders: ["date", "subject", "stressLevel"],
    viewEntryTo: "/userhome/dashboard/stress_level/view_entry/",
    itemsPerPage: 10,
  },
}

export default stressLevelProviderConfig
