import React, { Component } from "react"

class ConfirmDeleteInput extends Component {
  componentDidMount() {
    this.setState({
      itemToMatch: this.props.itemToMatch
    })
  }

  state = {
    itemToMatch: "",
    isMatch: false,
    inputItem: ""
  }

  handleChange = e => {
    this.setState(
      {
        inputItem: e.target.value,
        isMatch: this.state.itemToMatch === e.target.value ? true : false
      },
      () => {
        this.props.registerMatch(this.state.isMatch)
      }
    )
  }

  render() {
    return (
      <input
        className={`input ${this.state.isMatch ? "is-success" : "is-danger"}`}
        type="text"
        onChange={e => this.handleChange(e)}
      />
    )
  }
}

export default ConfirmDeleteInput
