import React from "react";
import assets from "assets";

function AcknowledgeRequestButton(props) {
  return (
    <button
      className="button is-small is-rounded"
      style={{ padding: 2 }}
      onClick={props.onClick}
    >
      <figure className={"image is-24x24"}>
        <img
          src={assets.features.appointments.confirmAppointment}
          alt="accept_icon"
        />
      </figure>
    </button>
  );
}

export default AcknowledgeRequestButton;
