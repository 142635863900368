import individuals from "./individuals";
import fullView from "./teeth_dog.svg";
import incisors from "./incisors";

const dogTeeth = {
  individuals,
  fullView,
  incisors,
};

export default dogTeeth;
