import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import _ from "lodash"
import Section from "components/layout/Section"
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons"
import TemperatureEntryForm from "forms/Temperature_Entry_Form"
import {
  MODALS_SHOW,
  MODALS_CONFIRM_DELETE,
  TEMPERATURE_DELETE,
} from "actions/types"
import FormWrapper from "components/layout/Form_Wrapper"

const INITIAL_STATE = {
  viewMode: true,
  valuesToEdit: {},
}

class ViewEntry extends Component {
  state = INITIAL_STATE

  UNSAFE_componentWillMount() {
    const { temperatureEntries, history, defaultRoute } = this.props
    const { id } = this.props.match.params
    const temperature = temperatureEntries.filter(
      (entry) => entry._id === id
    )[0]
    if (!temperature) return history.push(`${defaultRoute}/view_entries`)
    this.setState({
      valuesToEdit: temperature,
    })
  }

  handleEditClick = () => {
    this.setState({
      viewMode: !this.state.viewMode,
    })
  }

  handleDeleteClick = () => {
    this.props.dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "temperature",
        actionType: TEMPERATURE_DELETE,
        item: this.state.valuesToEdit._id,
        callback: this.handleDeleteResult,
        providerView: this.props.providerView,
      },
    })
  }

  handleDeleteResult = () => {
    this.props.history.push(
      `${this.props.defaultRoute}/view_entries_temperature`
    )
  }

  renderSectionContent() {
    if (_.isEmpty(this.state.valuesToEdit)) return <div />

    return (
      <>
        <BackEditDeleteButtons
          handleEditClick={this.handleEditClick}
          handleDeleteClick={this.handleDeleteClick}
          viewMode={this.state.viewMode}
          providerView={this.props.providerView}
          providerEdit={this.props.providerEdit}
        />
        <FormWrapper>
          <TemperatureEntryForm editMode {...this.state} {...this.props} />
        </FormWrapper>
      </>
    )
  }

  render() {
    return <Section>{this.renderSectionContent()}</Section>
  }
}

const mapStateToProps = (state) => {
  return {
    temperatureEntries: state.reducers.temperature.entries,
  }
}

export default withRouter(connect(mapStateToProps)(ViewEntry))
