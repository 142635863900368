import React, { Component } from "react"
import StableDiaryEntryForm from "forms/Stable_Diary_Entry_Form"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"

class NewEntry extends Component {
  render() {
    return (
      <div className="columns is-mobile is-centered">
        <div className="column is-5-desktop is-full-mobile is-6-tablet">
          <StableDiaryEntryForm valuesToEdit={{}} {...this.props} />
        </div>
      </div>
    )
  }
}

export default RequireSubFeature(NewEntry)
