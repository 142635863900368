import { features, main, professionals } from "assets";
import Landing from "components/features/Landing";
import FeatureResources from "components/features/Feature_Resources";
import NewEntryView from "./views/New_Entry_View";
import ViewEntriesView from "./views/View_Entries_View";

const appointmentsRoutes = [
  {
    name: "landing",
    pageInfo: ["info_p1", "info_p2", "info_p3", "info_p4"],
    disabled: false,
    menuItem: true,
    image: {
      img: features.infoMenuIcon,
      alt: "info_menu_icon",
    },
    hr: true,
    exact: true,
    component: Landing,
    path: "/",
  },
  {
    name: "resources",
    disabled: true,
    menuItem: true,
    image: {
      img: main.resources,
      alt: "feature_resources_icon",
    },
    hr: true,
    exact: true,
    component: FeatureResources,
    path: "/resources",
  },
  {
    name: "newEntryView",
    disabled: false,
    menuItem: true,
    image: {
      img: features.appointments.newEntry,
      alt: "new_entry_icon",
    },
    component: NewEntryView,
    path: "/new_entry",
  },
  {
    name: "viewEntriesView",
    disabled: false,
    menuItem: true,
    image: {
      img: features.appointments.viewEntries,
      alt: "view_entries_icon",
    },
    component: ViewEntriesView,
    path: "/view_entries",
  },
  {
    name: "requestAppointment",
    disabled: false,
    menuItem: true,
    exact: true,
    linkItem: true,
    image: {
      img: professionals.main,
      alt: "contacts_icon",
    },
    path: "/userhome/contacts",
  },
];

export default appointmentsRoutes;
