import { features, main } from "assets";
import Landing from "components/features/Landing";
import FeatureResources from "components/features/Feature_Resources";
import NewEntryCycle from "./New_Entry_Cycle";
import NewEntryProg from "./New_Entry_Prog";
import NewEntryBreeding from "./New_Entry_Breeding";
import ViewEntriesCycle from "./View_Entries_Cycle";
import ViewEntriesProg from "./View_Entries_Prog";
import ViewEntriesBreeding from "./View_Entries_Breeding";
import ViewEntryCycle from "./View_Entry_Cycle";
import ViewEntryProg from "./View_Entry_Prog";
import ViewEntryBreeding from "./View_Entry_Breeding";
import NewEntryView from "./New_Entry_View";
import ViewEntriesView from "./View_Entries_View";

import { LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD } from "functions/hostname";

const heatDiaryConfig = {
  name: "heatDiary",
  chartFunc: "heatDiaryProg",
  type: "feature",
  defaultRoute: "/userhome/dashboard/heat_diary",
  menuIcon: { img: features.heatDiary.main, alt: "heat_diary_icon" },
  nav: [
    {
      name: "landing",
      disabled: false,
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      name: "resources",
      disabled: false,
      clients: [LOCALHOST, TASSUAPU_STAGING, TASSUAPU_PROD],
      menuItem: true,
      image: {
        img: main.resources,
        alt: "feature_resources_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: FeatureResources,
      path: "/resources",
    },
    {
      disabled: false,
      name: "newEntry",
      menuItem: true,
      image: {
        img: features.heatDiary.newEntry,
        alt: "heat_diary_new_entry_icon",
      },
      icon: "fas fa-heart",
      component: NewEntryView,
      path: "/new_entry",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.heatDiary.main,
        alt: "heat_diary_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntriesView,
      path: "/view_entries",
    },
    {
      name: "newEntryCycle",
      disabled: false,
      menuItem: false,
      image: {
        img: features.heatDiary.newEntryCycle,
        alt: "heat_diary_new_entry_icon",
      },
      icon: "fas fa-heart",
      component: NewEntryCycle,
      path: "/new_entry_cycle",
    },
    {
      name: "viewEntriesCycle",
      disabled: false,
      menuItem: false,
      hr: true,
      image: {
        img: features.heatDiary.viewEntriesCycle,
        alt: "heat_diary_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntriesCycle,
      path: "/view_entries_cycle",
    },
    {
      name: "newEntryProg",
      disabled: false,
      menuItem: false,
      image: {
        img: features.heatDiary.newEntryProg,
        alt: "heat_diary_new_entry_icon",
      },
      icon: "fas fa-heart",
      component: NewEntryProg,
      path: "/new_entry_prog",
    },
    {
      name: "viewEntriesProg",
      disabled: false,
      menuItem: false,
      hr: true,
      image: {
        img: features.heatDiary.viewEntriesProg,
        alt: "heat_diary_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntriesProg,
      path: "/view_entries_prog",
    },
    {
      name: "newEntryBreeding",
      disabled: false,
      menuItem: false,
      image: {
        img: features.heatDiary.newEntryBreeding,
        alt: "heat_diary_new_entry_icon",
      },
      icon: "fas fa-heart",
      component: NewEntryBreeding,
      path: "/new_entry_breeding",
    },
    {
      name: "viewEntriesBreeding",
      disabled: false,
      menuItem: false,
      image: {
        img: features.heatDiary.viewEntriesBreeding,
        alt: "heat_diary_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntriesBreeding,
      path: "/view_entries_breeding",
    },
    {
      name: "viewEntryCycle",
      disabled: false,
      menuItem: false,
      component: ViewEntryCycle,
      path: "/view_entry_cycle/:id",
    },
    {
      name: "viewEntryProg",
      disabled: false,
      menuItem: false,
      component: ViewEntryProg,
      path: "/view_entry_prog/:id",
    },
    {
      name: "viewEntryBreeding",
      disabled: false,
      menuItem: false,
      component: ViewEntryBreeding,
      path: "/view_entry_breeding/:id",
    },
  ],
  tableView: {
    cycles: {
      headers: ["startDate", "endDate", "cycleNotes"],
      mobileHeaders: ["startDate", "endDate", "cycleNotes"],
    },
    progs: {
      headers: ["date", "progesteroneLevel", "progNotes"],
      mobileHeaders: ["date", "progesteroneLevel", "progNotes"],
    },
    breeding: {
      headers: ["breedingDate", "breedingNotes"],
      mobileHeaders: ["breedingDate", "breedingNotes"],
    },
  },
};

export default heatDiaryConfig;
