import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Section from "components/layout/Section";
import LinkItem from "containers/features/Useful_Links/components/Link_Item";
import LitterUsefulLinkForm from "forms/Litter_Useful_Link_Form";
import LitterMembersTags from "../components/Litter_Members_Tags";
import Message from "components/message";

function ViewLinks(props) {
  // Deconstructing props
  const {
    usefulLinks,
    fetchUsefulLinks,
    updateUsefulLink,
    deleteUsefulLink,
    selectedLitter,
    litterMembers,
  } = props;
  const { entries, fetched } = usefulLinks;
  const entriesFiltered = entries.filter(
    (entry) => entry._litter === selectedLitter._id
  );

  // ComponentWillMount & ComponentDidMount
  useEffect(() => {
    if (!fetched) fetchUsefulLinks(selectedLitter._id);
  }, [fetched, fetchUsefulLinks, selectedLitter._id]);

  // Hooks
  //const [showForm, toggleForm] = useState(false)
  const [editMode, toggleEditMode] = useState(false);
  const [itemToEdit, setItemToEdit] = useState("");

  function handleToggleEditMode(item) {
    toggleEditMode(!editMode);
    setItemToEdit(item);
  }

  function handleUpdateLinkAfterEdit(link, setSubmitting) {
    updateUsefulLink(link, setSubmitting, handleToggleEditMode);
  }

  function handleDeleteLink(_id) {
    deleteUsefulLink(_id, handleToggleEditMode);
  }

  return (
    <Section>
      {entriesFiltered.length === 0 ? (
        <Message warning message={"noEntriesToDisplayAutoFetch"} />
      ) : (
        entriesFiltered.map((e) => (
          <div key={e._id}>
            <LinkItem {...e} toggleEditMode={handleToggleEditMode}>
              {editMode && itemToEdit === e._id && (
                <LitterUsefulLinkForm
                  editMode
                  valuesToEdit={e}
                  cancel={handleToggleEditMode}
                  updateLink={handleUpdateLinkAfterEdit}
                  deleteLink={handleDeleteLink}
                />
              )}
            </LinkItem>
            <LitterMembersTags
              litterMembers={litterMembers.filter((m) =>
                e.litterMembers.includes(m._id)
              )}
            />
            <br />
          </div>
        ))
      )}
    </Section>
  );
}

const mapStateToProps = (state) => {
  const selectedLitterId = state.reducers.litters.selectedLitter._id;
  return {
    litterMembers: state.reducers.litters.litterMembers[selectedLitterId],
  };
};

export default connect(mapStateToProps)(ViewLinks);
