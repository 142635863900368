import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { INPUT_FIELDS } from "forms/fields/litter_useful_link";
import Buttons from "components/elements/Buttons";
import Button from "components/elements/Button";

class LitterUsefulLinkForm extends Component {
  renderFields() {
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={i}
          editMode={this.props.editMode}
          viewMode={this.props.viewMode}
          values={this.props.values}
          {...field}
        />
      );
    });
  }

  render() {
    const { errors, dirty, isSubmitting, editMode } = this.props;
    return (
      <Form>
        {this.renderFields()}
        <br />
        <div style={{ padding: 0, margin: 0 }} className="level is-mobile">
          <div className="level-item">
            <Buttons className="is-centered">
              <Button
                group="common"
                name="cancel"
                onClick={this.props.cancel}
              />
              <Button
                group="common"
                name="submit"
                className={`${
                  !_.isEmpty(errors)
                    ? "is-danger"
                    : _.isEmpty(errors) && dirty
                    ? "is-success"
                    : "is-info"
                } ${isSubmitting && "is-loading"}`}
                onClick={this.props.handleSubmit}
              />
            </Buttons>
          </div>
          {editMode && (
            <div className="level-right">
              <div className="level-item">
                <Button
                  group="common"
                  name="delete"
                  onClick={() => this.props.deleteLink(this.props.values._id)}
                />
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  }
}

LitterUsefulLinkForm = withFormik({
  mapPropsToValues({ valuesToEdit, _litter }) {
    return {
      displayName: valuesToEdit.displayName || "",
      desc: valuesToEdit.desc || "",
      url: valuesToEdit.url || "",
      _id: valuesToEdit._id || "",
      _litter: valuesToEdit._litter || _litter,
    };
  },
  validationSchema: Yup.object().shape({
    displayName: Yup.string().required("required").max(40, "max-40"),
    desc: Yup.string().max(120, "max-120"),
    url: Yup.string().url("url").required("required").max(120, "max-120"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    if (props.editMode) {
      return props.updateLink(values, setSubmitting);
    }
    props.addUsefulLink(
      { ...values, litterMembers: props.litterMembers.map((m) => m._id) },
      setSubmitting,
      () =>
        props.history.push("/userhome/dashboard/litter/useful_links/view_links")
    );
  },
})(LitterUsefulLinkForm);

export default LitterUsefulLinkForm;
