import React from 'react';
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Section from "components/layout/Section";
import { HELP_MENU_STRUCTURE } from "constants/help_menu";
import HelpItem from "./components/HelpItem";
import Intl from "components/Intl";

const INITIAL_NODES = () => {
  let startingNodes = [];
  for (let items of Object.values(HELP_MENU_STRUCTURE)) {
    startingNodes.push(items);
  }
  return startingNodes;
};

const INITIAL_CRUMBS = [
  {
    label: <Intl message path="containers.main.helpMenu.title.helpHome" />,
    nodes: INITIAL_NODES,
  },
];

function Help(props) {
  const [nodes, setNodes] = useState([]);
  const [helpItemInfo, setHelpItemInfo] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    setNodes(INITIAL_NODES);
    setHelpItemInfo([]);
    setBreadcrumbs(INITIAL_CRUMBS);
  }, []);

  function handleClickItem(item) {
    if (item.nodes) {
      setNodes(item.nodes);
      setBreadcrumbs((prevBreadcrumbs) => [
        ...prevBreadcrumbs,
        { label: item.label, nodes: item.nodes },
      ]);
    } else {
      setBreadcrumbs((prevBreadcrumbs) => [
        ...prevBreadcrumbs,
        { label: item.label, nodes: [] },
      ]);
      setNodes([]);
      setHelpItemInfo(item.info);
    }
  }

  function handleBreadcrumbItemClick(index, breadcrumbItemNodes) {
    if (breadcrumbItemNodes.length === 0 && index !== 0) return;
    if (index > 0) {
      setBreadcrumbs(breadcrumbs.slice(0, index + 1));
    }
    if (index === 0) {
      setBreadcrumbs(breadcrumbs.slice(0, 1));
    }
    setNodes(breadcrumbItemNodes);
    setHelpItemInfo([]);
  }

  const Menu = () => {
    return (
      <ul>
        {nodes.map((n, i) => (
          <MenuItem key={i} {...n} />
        ))}
      </ul>
    );
  };

  const MenuItem = (props) => {
    return (
      <li className="box" style={{ marginBottom: 8, cursor: "pointer" }}>
        <div onClick={() => handleClickItem(props)}>
          <HelpItem type="title" path={props.label} />
        </div>
      </li>
    );
  };

  const Breadcrumb = () => {
    return (
      <div
        className="box"
        style={{ padding: 8, marginBottom: 8, paddingLeft: 18 }}
      >
        <nav className="breadcrumb has-dot-separator" aria-label="breadcrumbs">
          <ul>
            {breadcrumbs.map((b, i) => (
              <BreadcrumbItem
                key={i}
                index={i}
                label={b.label}
                nodes={b.nodes}
              />
            ))}
          </ul>
        </nav>
      </div>
    );
  };

  const BreadcrumbItem = (props) => {
    return (
      <li>
        <a
          href="/#"
          onClick={(e) => {
            e.preventDefault();
            handleBreadcrumbItemClick(props.index, props.nodes);
          }}
        >
          <div
            style={{
              maxWidth: 170,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Intl message path={props.label} />
          </div>
        </a>
      </li>
    );
  };

  return (
    <Section>
      <Breadcrumb />
      {helpItemInfo.length > 0 ? (
        <div className="box">
          {helpItemInfo.map((item, i) => (
            <HelpItem key={i} {...item} />
          ))}
        </div>
      ) : (
        <Menu />
      )}
    </Section>
  );
}

export default connect(null, null)(Help);
