const litterMemberDashboardConfig = {
  layout: {
    columns: "columns is-2 is-variable is-mobile is-multiline",
    column: "column is-2-desktop is-4-mobile is-4-tablet",
  },
  featureOrder: [
    "litterMemberWeight",
    "litterMemberVaccinations",
    "litterMemberParasites",
    "litterMemberHealthDiary",
    "litterMemberFoodDiary",
    "litterMemberHomeCare",
    "litterMemberReporting",
    "transferLitterMember",
  ],
};

export default litterMemberDashboardConfig;
