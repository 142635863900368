import main from "./tooth_marker.svg";
import dog from "./dog_teeth";
import cat from "./cat_teeth";

const toothMarker = {
  main,
  dog,
  cat,
};

export default toothMarker;
