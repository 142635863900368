import React from "react";
import RequireSubFeature from "components/hoc/Require_Sub_Feature";
import Section from "components/layout/Section";
import SectionTitle from "components/layout/Section_Title";

import HOME_CARE_OPTIONS from "../constants/home_care_options";

function NewEntrySelectionView(props) {
  const { messages, locale, history, defaultRoute, litterMembers, petType } =
    props;
  const { newEntrySelectionView, categories, activities } = messages;

  function handleActivitySelection(category, activity) {
    history.push(`${defaultRoute}/new_entry`, {
      category,
      activity,
      litterMembers,
    });
  }

  return (
    <Section style={{ paddingBottom: 300 }}>
      <SectionTitle
        title={newEntrySelectionView.title}
        subtitle={newEntrySelectionView.subtitle}
        form
      />
      {HOME_CARE_OPTIONS[petType].map((opt) => (
        <div key={opt.category} style={{ marginBottom: 26 }}>
          <SectionTitle title={categories[opt.category]} subtitle={""} />
          <div className="buttons">
            {opt.activities.map((a) => (
              <div
                key={a}
                className={"button is-light is-small"}
                onClick={() => handleActivitySelection(opt.category, a)}
              >
                {activities[a][locale]}
              </div>
            ))}
          </div>
        </div>
      ))}
    </Section>
  );
}

export default RequireSubFeature(NewEntrySelectionView);
