import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Section from "components/layout/Section";
import Message from "components/message";
import AwardPreview from "./components/Award_Preview";

function ViewEntries(props) {
  const { requiresFetch, entries, defaultRoute, fetchAwards, pet } = props;

  useEffect(() => {
    if (requiresFetch) {
      fetchAwards(pet);
    }
  }, [requiresFetch, fetchAwards, pet]);

  if (entries.length === 0) {
    return (
      <Section>
        <Message warning message={"noEntriesToDisplayAutoFetch"} />
      </Section>
    );
  }

  return (
    <Section>
      <div className="columns is-2 is-variable is-mobile is-multiline is-centered">
        {entries.map((award, i) => (
          <div key={i} className="column is-3-desktop is-6-mobile is-3-tablet">
            <Link to={`${defaultRoute}/view_entry/${award._id}`}>
              <AwardPreview
                type={award.type}
                awardData={{ bling: award.bling, colors: award.colors }}
              />
            </Link>
            <div className="heading has-text-centered">
              {award.displayTitle}
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
}

export default ViewEntries;
