import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { auth as actions } from "actions";
import Notification from "components/layout/Notification";
import ToggleButton from "components/elements/ToggleButton";
import Button from "components/elements/Button";
import Buttons from "components/elements/Buttons";
import dashboardConfig from "containers/main/Dashboard/config";
import FEATURES from "constants/feature_routes";
import { renderMeOnClient } from "functions";

function DashboardPreferences({
  user,
  messages,
  locale,
  updateDashboardPreferences,
}) {
  const [dashboardPreferences, setDashboardPreferences] = useState(
    user.featurePreferences.dashboard
  );
  const [features, setFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let features = dashboardConfig.featureOrder.map((name) => {
      return FEATURES.find((feature) => feature.name === name);
    });
    setFeatures(features);
  }, []);

  function handleToggleEmailPreference(e) {
    setDashboardPreferences({
      ...dashboardPreferences,
      [e.target.id]: !dashboardPreferences[e.target.id],
    });
  }

  function checkObjectKeys(a, b) {
    let aValues = Object.values(a).sort();
    let bValues = Object.values(b).sort();
    return JSON.stringify(aValues) === JSON.stringify(bValues);
  }

  function handleUpdatePreferences() {
    setIsLoading(true);
    updateDashboardPreferences(dashboardPreferences);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }

  function handleCancel() {
    setDashboardPreferences(user.featurePreferences.dashboard);
  }

  return (
    <Notification>
      <div className="title is-6 has-text-dark">
        {messages.dashboardPreferences.title[locale]}
      </div>
      <div className="subtitle is-7 has-text-dark">
        {messages.dashboardPreferences.subtitle[locale]}
      </div>
      {features.map((feature) => {
        if (!renderMeOnClient(feature.clients)) return null;
        return (
          <div key={feature.name} className="columns is-mobile">
            <div className="column is-3">
              <div style={{ paddingTop: 2 }}>
                <ToggleButton
                  key={feature.name}
                  id={feature.name}
                  checked={dashboardPreferences[feature.name]}
                  onChange={handleToggleEmailPreference}
                />
              </div>
            </div>
            <div className="column is-2">
              <figure className="image is-24x24">
                <img src={feature.img.src} alt={feature.img.alt} />
              </figure>
            </div>
            <div className="column">
              {messages.dashboardPreferences[feature.name][locale]}
            </div>
          </div>
        );
      })}
      {!checkObjectKeys(
        user.featurePreferences.dashboard,
        dashboardPreferences
      ) && (
        <Buttons>
          <Button
            className={`is-fullwidth is-info ${isLoading && "is-loading"}`}
            group="common"
            name="update"
            disabled={isLoading}
            onClick={() => handleUpdatePreferences()}
          />
          <Button
            className={`is-fullwidth ${isLoading && "is-loading"}`}
            group="common"
            name="cancel"
            disabled={isLoading}
            onClick={() => handleCancel()}
          />
        </Buttons>
      )}
    </Notification>
  );
}

export default connect(null, actions)(DashboardPreferences);
