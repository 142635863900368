import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { connect } from "react-redux";
import * as actions from "../actions";
import * as Yup from "yup";
import { INPUT_FIELDS } from "./update_client_data_form_fields";
import { FormTitle, FormButtons } from "components/form";

class UpdateClientDataForm extends Component {
  renderFields = () => {
    return INPUT_FIELDS.map((field, i) => {
      return <Field key={i} {...field} />;
    });
  };

  render() {
    const { errors, dirty, isSubmitting, cancel } = this.props;
    return (
      <div>
        <Form>
          <FormTitle group={"updateClientData"} />
          <br />
          {this.renderFields()}
          <br />
          <FormButtons
            isSubmitting={isSubmitting}
            errors={errors}
            dirty={dirty}
            cancel={cancel}
            useCancel
          />
        </Form>
        <br />
      </div>
    );
  }
}

UpdateClientDataForm = withFormik({
  mapPropsToValues({ _id, clientRef, clientUrl }) {
    return {
      _id,
      clientRef,
      clientUrl,
    };
  },
  validationSchema: Yup.object().shape({
    clientRef: Yup.string(),
    clientUrl: Yup.string(),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    return props.handleUpdateClientData(values, setSubmitting);
  },
})(UpdateClientDataForm);

export default connect(null, actions)(UpdateClientDataForm);
