import React, { Component } from "react"
import { connect } from "react-redux"
import { litters as actions } from "actions/index"
import AddLitterForm from "forms/Add_Litter_Form"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"

class AddLitter extends Component {
  handleAddLitter = (values, setSubmitting) => {
    const { addLitter, history } = this.props
    const successRoute = "/userhome/dashboard/litter"
    addLitter(values, setSubmitting, history, successRoute)
  }

  render() {
    return (
      <div className="columns is-mobile is-centered">
        <div className="column is-5-desktop is-full-mobile is-6-tablet">
          <AddLitterForm
            valuesToEdit={{}}
            {...this.props}
            addLitter={this.handleAddLitter}
          />
        </div>
      </div>
    )
  }
}

export default connect(null, actions)(RequireSubFeature(AddLitter))
