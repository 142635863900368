import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  archiveMarkerDetail,
  BODY_MARKER_ARCHIVE_MARKER_DETAIL,
} from "containers/features/Body_Marker/actions";
import { MODALS_HIDE } from "actions/types";
import BodyText from "./BodyText";

class ConfirmArchive extends Component {
  state = {
    isMatch: false,
  };

  selectAction(props) {
    switch (props.actionType) {
      case BODY_MARKER_ARCHIVE_MARKER_DETAIL:
        return props.archiveMarkerDetail(props.item);
      default:
      // Do nothing
    }
  }

  registerMatch = (value) => {
    this.setState({
      isMatch: value,
    });
  };

  render() {
    const {
      dispatch,
      messages,
      buttons,
      type,
      locale,
      noDefault,
      ownBodyText,
    } = this.props;
    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title is-size-6-mobile">
              {`${messages.titles.confirmDelete[locale]} - ${messages.titles[type][locale]}`}
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => dispatch({ type: MODALS_HIDE })}
            />
          </header>
          <section className="modal-card-body has-text-dark">
            <BodyText
              noDefault={noDefault}
              ownBodyText={ownBodyText}
              messages={messages.bodyText}
              type={type}
              locale={locale}
            />
          </section>
          <footer className="modal-card-foot">
            <button
              className="button"
              onClick={() => dispatch({ type: MODALS_HIDE })}
            >
              {buttons.cancel[locale]}
            </button>
            <button
              className="button is-success"
              onClick={() => this.selectAction(this.props)}
            >
              {buttons.archive[locale]}
            </button>
          </footer>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        archiveMarkerDetail,
        dispatch,
      },
      dispatch
    ),
  };
};

const mapStateToProps = (state) => {
  return {
    locale: state.intl.locale,
    buttons: state.intl.messages.components.buttons.common,
    messages: state.intl.messages.components.modals,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmArchive);
