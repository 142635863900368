import axios from "axios";
import {
  WEIGHT_REVIEW_FETCH,
  ACTION_STARTED,
  ACTION_ENDED,
  ACTION_RESET,
} from "./types";
import { catchBlockError } from "./functions";

export const weightReviewFetch =
  (dates, fetchType, _litter) => async (dispatch) => {
    try {
      dispatch({ type: ACTION_STARTED, payload: WEIGHT_REVIEW_FETCH });
      const res = await axios.get("/api/weight/fetch_entries_for_litter", {
        headers: {
          authorization: localStorage.getItem("token"),
        },
        params: { _litter, ...dates, fetchType },
      });
      dispatch({ type: ACTION_ENDED });
      dispatch({
        type: WEIGHT_REVIEW_FETCH,
        payload: { ...res.data, fetchType, _litter },
      });
      return dispatch({ type: ACTION_RESET });
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

const weightReviewActions = {
  weightReviewFetch,
};

export default weightReviewActions;
