const kgInput = {
  sliderStep: 1,
  step: 1,
  arrowRight: 1,
  arrowLeft: -1,
};

const gInput = {
  sliderStep: 10,
  step: 1,
  min: 0,
  max: 999,
  arrowRight: 1,
  arrowLeft: -1,
};

const lbsInput = {
  sliderStep: 1,
  step: 1,
  arrowRight: 1,
  arrowLeft: -1,
};

const fineWightControl = {
  other: {
    kg: {
      min: 0,
      max: 200,
      ...kgInput,
    },
    lbs: {
      min: 0,
      max: 200,
      ...lbsInput,
    },
    g: gInput,
  },
  litterMember: {
    kg: {
      min: 0,
      max: 20,
      ...kgInput,
    },
    litterMemberBirthWeightKg: {
      min: 0,
      max: 20,
      ...kgInput,
    },
    lbs: {
      min: 0,
      max: 200,
      ...lbsInput,
    },
    litterMemberBirthWeightLbs: {
      min: 0,
      max: 200,
      ...lbsInput,
    },
    g: gInput,
    litterMemberBirthWeightG: gInput,
  },
  dog: {
    kg: {
      min: 0,
      max: 200,
      ...kgInput,
    },
    lbs: {
      min: 0,
      max: 200,
      ...lbsInput,
    },
    g: gInput,
  },
  cat: {
    kg: {
      min: 0,
      max: 20,
      ...kgInput,
    },
    lbs: {
      min: 0,
      max: 50,
      ...lbsInput,
    },
    g: gInput,
  },
  horse: {
    kg: {
      min: 0,
      max: 1000,
      ...kgInput,
    },
    lbs: {
      min: 0,
      max: 2200,
      ...lbsInput,
    },
    g: gInput,
  },
};

export default fineWightControl;
