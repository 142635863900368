import React from "react";

export default function DogUpperIncisors(props) {
  const { confirmSelection, ...rest } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 263.7 118.8"
      width="263.7"
      height="118.8"
      {...rest}
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              ".cls-6{fill:#f9f7e1;stroke:#333;stroke-miterlimit:10;stroke-width:1.5px;cursor:pointer;}.cls-7{font-size:12px;fill:#4d4d4d;font-family:SegoeUI-Semibold, Segoe UI;font-weight:600;}",
          }}
        />
      </defs>
      <g id="incisors" style={{ pointerEvents: "bounding-box" }}>
        <g id="dog_upper_incisors">
          <g id="right">
            <path
              id="_101"
              data-name={101}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M124,7.17c-.36-.3-10.39-9.78-22-.28-7.1,5.79-5.56,16.68-4.35,25.3C100.09,50,110.71,71,118.46,69.78c8.52-1.37,9.8-29,10.25-38.76C129.56,12.59,125.58,8.54,124,7.17Z"
            />
            <path
              id="_102"
              data-name={102}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M84.77,12.15c-8-7-23.46-3.2-29.33,7.56-4.7,8.61-1.73,18.88.69,27.27,4.32,15,15.61,35.53,24.47,33.49,9.81-2.27,12-31.21,10.59-48C90.49,24,89.78,16.55,84.77,12.15Z"
            />
            <path
              id="_103"
              data-name={103}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M15.67,107.38c11.35,7.81,43.69-7,45.95-25.06C64.68,57.78,11.53,32.58,3.14,40.55.59,43,3.58,47.09,7.32,65.61,13.21,94.83,9.53,103.15,15.67,107.38Z"
            />
          </g>
          <g id="left">
            <path
              id="_201"
              data-name={201}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M139.53,7.17c5.8-4.95,16.08-5.14,22-.28,7.1,5.79,5.56,16.68,4.35,25.3C163.41,50,152.79,71,145,69.78c-8.52-1.37-9.8-29-10.25-38.76C133.94,12.59,137.92,8.54,139.53,7.17Z"
            />
            <path
              id="_202"
              data-name={202}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M178.8,12.15c8-7,23.47-3.2,29.34,7.56,4.69,8.61,1.73,18.88-.69,27.27-4.33,15-15.62,35.53-24.47,33.49-9.81-2.27-12-31.21-10.6-48C173.08,24,173.79,16.55,178.8,12.15Z"
            />
            <path
              id="_203"
              data-name={203}
              onClick={(e) => confirmSelection(e.target.id)}
              className="cls-6"
              d="M248,107.38c-11.35,7.81-43.69-7-46-25.06C199,57.78,252.16,32.58,260.55,40.55c2.55,2.41-.44,6.54-4.18,25.06C250.48,94.83,254.16,103.15,248,107.38Z"
            />
          </g>
          <g id="numbering">
            <text className="cls-7" transform="translate(50.14 113.9)">
              103
            </text>
            <text className="cls-7" transform="translate(75.89 96.09)">
              102
            </text>
            <text className="cls-7" transform="translate(111.53 86.12)">
              101
            </text>
            <text className="cls-7" transform="translate(138.59 86.12)">
              201
            </text>
            <text className="cls-7" transform="translate(171.67 96.21)">
              202
            </text>
            <text className="cls-7" transform="translate(196.72 113.78)">
              203
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}
