import axios from "axios"
import moment from "moment"
import _ from "lodash"
import {
  HEAT_DIARY_FETCH_TYPE,
  HEAT_DIARY_FETCH_CYCLES,
  HEAT_DIARY_FETCH_PROGS,
  HEAT_DIARY_FETCH_BREEDING,
  HEAT_DIARY_ADD_CYCLE,
  HEAT_DIARY_ADD_PROG,
  HEAT_DIARY_ADD_BREEDING,
  HEAT_DIARY_UPDATE_CYCLE,
  HEAT_DIARY_UPDATE_PROG,
  HEAT_DIARY_UPDATE_BREEDING,
  HEAT_DIARY_DELETE_CYCLE,
  HEAT_DIARY_DELETE_PROG,
  HEAT_DIARY_DELETE_BREEDING,
  ACTION_STARTED,
  ACTION_SUCCESS,
  MODALS_HIDE,
  TABLE_CLEAR_CURRENT_STATE,
} from "./types"
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "./functions"

export const fetchHeatDiaryCycleEntries = (value, unit, name, pet) => async (
  dispatch
) => {
  // Dates block
  const dates = !_.isEmpty(value)
    ? { ...value }
    : {
        from: moment().subtract(value, unit).toISOString(),
        to: moment().toISOString(),
      }
  // Dates block end
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({
      type: HEAT_DIARY_FETCH_TYPE,
      payload: { dates, type: !_.isEmpty(value) ? "range" : name },
    })
    dispatch({ type: ACTION_STARTED, payload: HEAT_DIARY_FETCH_CYCLES })
    const res = await axios.get("/api/heat_diary/fetch_cycle_entries", {
      headers: {
        authorization: localStorage.getItem("token"),
      },
      params: { _pet: pet._id, ...dates },
    })
    dispatch({
      type: HEAT_DIARY_FETCH_CYCLES,
      payload: res.data.heatDiaryEntries,
      pet,
    })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const fetchHeatDiaryProgEntries = (value, unit, name, pet) => async (
  dispatch
) => {
  // Dates block
  const dates = !_.isEmpty(value)
    ? { ...value }
    : {
        from: moment().subtract(value, unit).toISOString(),
        to: moment().toISOString(),
      }
  // Dates block end
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({
      type: HEAT_DIARY_FETCH_TYPE,
      payload: { dates, type: !_.isEmpty(value) ? "range" : name },
    })
    dispatch({ type: ACTION_STARTED, payload: HEAT_DIARY_FETCH_PROGS })
    const res = await axios.get("/api/heat_diary/fetch_prog_entries", {
      headers: {
        authorization: localStorage.getItem("token"),
      },
      params: { _pet: pet._id, ...dates },
    })
    dispatch({
      type: HEAT_DIARY_FETCH_PROGS,
      payload: res.data.heatDiaryEntries,
      pet,
    })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const fetchHeatDiaryBreedingEntries = (value, unit, name, pet) => async (
  dispatch
) => {
  // Dates block
  const dates = !_.isEmpty(value)
    ? { ...value }
    : {
        from: moment().subtract(value, unit).toISOString(),
        to: moment().toISOString(),
      }
  // Dates block end
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({
      type: HEAT_DIARY_FETCH_TYPE,
      payload: { dates, type: !_.isEmpty(value) ? "range" : name },
    })
    dispatch({ type: ACTION_STARTED, payload: HEAT_DIARY_FETCH_BREEDING })
    const res = await axios.get("/api/heat_diary/fetch_breeding_entries", {
      headers: {
        authorization: localStorage.getItem("token"),
      },
      params: { _pet: pet._id, ...dates },
    })
    dispatch({
      type: HEAT_DIARY_FETCH_BREEDING,
      payload: res.data.heatDiaryEntries,
      pet,
    })
    endAndResetAction(dispatch)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addHeatDiaryCycleEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HEAT_DIARY_ADD_CYCLE })
    const res = await axios.post("/api/heat_diary/add_cycle_entry", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    setSubmitting(false)
    dispatch({ type: HEAT_DIARY_ADD_CYCLE, payload: res.data.heatDiaryEntry })
    endAndResetAction(dispatch)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addHeatDiaryProgEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HEAT_DIARY_ADD_PROG })
    const res = await axios.post("/api/heat_diary/add_prog_entry", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    setSubmitting(false)
    dispatch({ type: HEAT_DIARY_ADD_PROG, payload: res.data.heatDiaryEntry })
    endAndResetAction(dispatch)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const addHeatDiaryBreedingEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HEAT_DIARY_ADD_BREEDING })
    const res = await axios.post("/api/heat_diary/add_breeding_entry", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    setSubmitting(false)
    dispatch({
      type: HEAT_DIARY_ADD_BREEDING,
      payload: res.data.heatDiaryEntry,
    })
    endAndResetAction(dispatch)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const updateHeatDiaryCycleEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HEAT_DIARY_UPDATE_CYCLE })
    const res = await axios.put("/api/heat_diary/update_cycle_entry", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    setSubmitting(false)
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({
      type: HEAT_DIARY_UPDATE_CYCLE,
      payload: res.data.heatDiaryEntry,
    })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const updateHeatDiaryProgEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HEAT_DIARY_UPDATE_PROG })
    const res = await axios.put("/api/heat_diary/update_prog_entry", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    setSubmitting(false)
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({ type: HEAT_DIARY_UPDATE_PROG, payload: res.data.heatDiaryEntry })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const updateHeatDiaryBreedingEntry = (
  values,
  setSubmitting,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HEAT_DIARY_UPDATE_BREEDING })
    const res = await axios.put(
      "/api/heat_diary/update_breeding_entry",
      values,
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    setSubmitting(false)
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    })
    dispatch({
      type: HEAT_DIARY_UPDATE_BREEDING,
      payload: res.data.heatDiaryEntry,
    })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const deleteHeatDiaryCycleEntry = (
  _id,
  type,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HEAT_DIARY_DELETE_CYCLE })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      "/api/heat_diary/delete_cycle_entry",
      { _id, type },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({
      type: HEAT_DIARY_DELETE_CYCLE,
      payload: res.data.heatDiaryEntry,
    })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const deleteHeatDiaryProgEntry = (
  _id,
  type,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HEAT_DIARY_DELETE_PROG })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      "/api/heat_diary/delete_prog_entry",
      { _id, type },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({ type: HEAT_DIARY_DELETE_PROG, payload: res.data.heatDiaryEntry })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

export const deleteHeatDiaryBreedingEntry = (
  _id,
  type,
  history,
  successRoute
) => async (dispatch) => {
  try {
    dispatch({ type: TABLE_CLEAR_CURRENT_STATE })
    dispatch({ type: ACTION_STARTED, payload: HEAT_DIARY_DELETE_BREEDING })
    dispatch({ type: MODALS_HIDE })
    const res = await axios.put(
      "/api/heat_diary/delete_breeding_entry",
      { _id, type },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    )
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    })
    dispatch({
      type: HEAT_DIARY_DELETE_BREEDING,
      payload: res.data.heatDiaryEntry,
    })
    endAndResetActionWithDelay(dispatch, 1500)
    history.push(successRoute)
  } catch (error) {
    catchBlockError(error, dispatch)
  }
}

const heatDiaryActions = {
  fetchHeatDiaryCycleEntries,
  fetchHeatDiaryProgEntries,
  fetchHeatDiaryBreedingEntries,
  addHeatDiaryCycleEntry,
  addHeatDiaryProgEntry,
  addHeatDiaryBreedingEntry,
  updateHeatDiaryCycleEntry,
  updateHeatDiaryProgEntry,
  updateHeatDiaryBreedingEntry,
  deleteHeatDiaryCycleEntry,
  deleteHeatDiaryProgEntry,
  deleteHeatDiaryBreedingEntry,
}

export default heatDiaryActions
