import axios from "axios";
import { ACTION_STARTED, ACTION_SUCCESS, MODALS_HIDE } from "actions/types";
import {
  catchBlockError,
  endAndResetAction,
  endAndResetActionWithDelay,
} from "actions/functions";

export const BODY_MARKER_FETCH_MARKERS = "body_marker_fetch_markers";
export const BODY_MARKER_ADD_MARKER = "body_marker_add_marker";
export const BODY_MARKER_DELETE_MARKER = "body_marker_delete_marker";
export const BODY_MARKER_ADD_MARKER_DETAIL = "body_marker_add_marker_detail";
export const BODY_MARKER_ADD_MARKER_DETAIL_FRONT =
  "body_marker_add_marker_detail_front";
export const BODY_MARKER_ADD_MARKER_DETAIL_BACK =
  "body_marker_add_marker_detail_back";
export const BODY_MARKER_UPDATE_MARKER_DETAIL =
  "body_marker_update_marker_detail";
export const BODY_MARKER_UPDATE_MARKER_DETAIL_FRONT =
  "body_marker_update_marker_detail_front";
export const BODY_MARKER_UPDATE_MARKER_DETAIL_BACK =
  "body_marker_update_marker_detail_back";
export const BODY_MARKER_DELETE_MARKER_DETAIL =
  "body_marker_delete_marker_detail";
export const BODY_MARKER_ARCHIVE_MARKER_DETAIL =
  "body_marker_archive_marker_detail";
export const BODY_MARKER_DELETE_MARKER_DETAIL_BACK =
  "body_marker_delete_marker_detail_back";
export const BODY_MARKER_DELETE_MARKER_DETAIL_FRONT =
  "body_marker_delete_marker_detail_front";
export const BODY_MARKER_ARCHIVE_MARKER_DETAIL_BACK =
  "body_marker_archive_marker_detail_back";
export const BODY_MARKER_ARCHIVE_MARKER_DETAIL_FRONT =
  "body_marker_archive_marker_detail_front";

export const actionTypes = [
  BODY_MARKER_FETCH_MARKERS,
  BODY_MARKER_ADD_MARKER,
  BODY_MARKER_DELETE_MARKER,
  BODY_MARKER_ADD_MARKER_DETAIL,
  BODY_MARKER_ADD_MARKER_DETAIL_FRONT,
  BODY_MARKER_ADD_MARKER_DETAIL_BACK,
  BODY_MARKER_UPDATE_MARKER_DETAIL,
  BODY_MARKER_UPDATE_MARKER_DETAIL_FRONT,
  BODY_MARKER_UPDATE_MARKER_DETAIL_BACK,
  BODY_MARKER_DELETE_MARKER_DETAIL,
  BODY_MARKER_ARCHIVE_MARKER_DETAIL,
  BODY_MARKER_DELETE_MARKER_DETAIL_BACK,
  BODY_MARKER_DELETE_MARKER_DETAIL_FRONT,
  BODY_MARKER_ARCHIVE_MARKER_DETAIL_BACK,
  BODY_MARKER_ARCHIVE_MARKER_DETAIL_FRONT,
];

const API = (route) => `/api/body_marker/${route}`;

export const fetchMarkers = (_pet, providerView) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: BODY_MARKER_FETCH_MARKERS });
    const res = await axios.get(
      API(providerView ? "fetch_markers_for_provider" : "fetch_markers"),
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
        params: { _pet },
      }
    );
    dispatch({
      type: BODY_MARKER_FETCH_MARKERS,
      payload: {
        ...res.data.markers,
        _pet,
      },
    });
    endAndResetAction(dispatch);
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const addMarker =
  (marker, details, providerContact, providerView) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: BODY_MARKER_ADD_MARKER,
      });
      const res = await axios.post(
        API(providerView ? "add_marker_by_provider" : "add_marker"),
        {
          _user: providerView ? providerContact._id : undefined,
          marker,
          details,
        },
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      dispatch({
        type: BODY_MARKER_ADD_MARKER,
        payload: res.data.marker,
      });
      endAndResetAction(dispatch);
      dispatch({ type: MODALS_HIDE });
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const deleteMarker = (_id, providerView) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: BODY_MARKER_DELETE_MARKER });
    const res = await axios.put(
      API(providerView ? "delete_marker_by_provider" : "delete_marker"),
      { _id },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    );
    dispatch({
      type: BODY_MARKER_DELETE_MARKER,
      payload: res.data.markerId,
    });
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    });
    endAndResetActionWithDelay(dispatch, 1500);
    dispatch({ type: MODALS_HIDE });
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const addMarkerDetail =
  (values, providerContact, providerView) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: BODY_MARKER_ADD_MARKER_DETAIL,
      });
      const res = await axios.post(
        API(
          providerView ? "add_marker_detail_by_provider" : "add_marker_detail"
        ),
        {
          _user: providerView ? providerContact._id : undefined,
          details: values,
        },
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      if (res.data.marker.type === "front") {
        dispatch({
          type: BODY_MARKER_ADD_MARKER_DETAIL_FRONT,
          payload: res.data.marker,
        });
      }
      if (res.data.marker.type === "back") {
        dispatch({
          type: BODY_MARKER_ADD_MARKER_DETAIL_BACK,
          payload: res.data.marker,
        });
      }
      endAndResetAction(dispatch);
      dispatch({ type: MODALS_HIDE });
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const updateMarkerDetail =
  (values, providerView) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_STARTED,
        payload: BODY_MARKER_UPDATE_MARKER_DETAIL,
      });
      const res = await axios.put(
        API(
          providerView
            ? "update_marker_detail_by_provider"
            : "update_marker_detail"
        ),
        values,
        {
          headers: {
            authorization: localStorage.getItem(
              providerView ? "tokenProvider" : "token"
            ),
          },
        }
      );
      if (res.data.marker.type === "front") {
        dispatch({
          type: BODY_MARKER_UPDATE_MARKER_DETAIL_FRONT,
          payload: res.data.marker,
        });
      }
      if (res.data.marker.type === "back") {
        dispatch({
          type: BODY_MARKER_UPDATE_MARKER_DETAIL_BACK,
          payload: res.data.marker,
        });
      }
      dispatch({ type: MODALS_HIDE });
      dispatch({
        type: ACTION_SUCCESS,
        payload: "updateSuccess",
      });
      endAndResetActionWithDelay(dispatch, 1500);
    } catch (error) {
      catchBlockError(error, dispatch);
    }
  };

export const deleteMarkerDetail = (_id, providerView) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: BODY_MARKER_DELETE_MARKER_DETAIL,
    });
    const res = await axios.put(
      API(
        providerView
          ? "delete_marker_detail_by_provider"
          : "delete_marker_detail"
      ),
      { _id },
      {
        headers: {
          authorization: localStorage.getItem(
            providerView ? "tokenProvider" : "token"
          ),
        },
      }
    );
    if (res.data.marker.type === "front") {
      dispatch({
        type: BODY_MARKER_DELETE_MARKER_DETAIL_FRONT,
        payload: res.data.marker,
      });
    }
    if (res.data.marker.type === "back") {
      dispatch({
        type: BODY_MARKER_DELETE_MARKER_DETAIL_BACK,
        payload: res.data.marker,
      });
    }
    dispatch({
      type: ACTION_SUCCESS,
      payload: "deleteSuccess",
    });
    endAndResetActionWithDelay(dispatch, 1500);
    dispatch({ type: MODALS_HIDE });
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

export const archiveMarkerDetail = (_id) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_STARTED,
      payload: BODY_MARKER_ARCHIVE_MARKER_DETAIL,
    });
    const res = await axios.put(
      API("archive_marker_detail"),
      { _id },
      {
        headers: { authorization: localStorage.getItem("token") },
      }
    );
    if (res.data.marker.type === "front") {
      dispatch({
        type: BODY_MARKER_ARCHIVE_MARKER_DETAIL_FRONT,
        payload: res.data.marker,
      });
    }
    if (res.data.marker.type === "back") {
      dispatch({
        type: BODY_MARKER_ARCHIVE_MARKER_DETAIL_BACK,
        payload: res.data.marker,
      });
    }
    dispatch({
      type: ACTION_SUCCESS,
      payload: "updateSuccess",
    });
    endAndResetActionWithDelay(dispatch, 1500);
    dispatch({ type: MODALS_HIDE });
  } catch (error) {
    catchBlockError(error, dispatch);
  }
};

const bodyMarker = {
  fetchMarkers,
  addMarker,
  deleteMarker,
  addMarkerDetail,
  deleteMarkerDetail,
  updateMarkerDetail,
  archiveMarkerDetail,
};

export default bodyMarker;
