import React, { useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons"
import { MODALS_SHOW, MODALS_CONFIRM_DELETE } from "actions/types"
import FormWrapper from "components/layout/Form_Wrapper"
import StressLevelEntryForm from "../forms/Stress_Level_Entry_Form"
import Section from "components/layout/Section"

function ViewEntry(props) {
  const {
    match,
    stressLevel,
    defaultRoute,
    history,
    dispatch,
    actionTypes,
    deleteStressLevelEntry,
    providerView,
    providerEdit,
  } = props
  const { id } = match.params
  const stressLevelEntry = stressLevel.entries.filter(
    (entry) => entry._id === id
  )[0]
  const successRoute = `${defaultRoute}/view_entries`

  const [viewMode, toggleViewMode] = useState(true)

  useEffect(() => {
    if (!stressLevelEntry) return history.push(successRoute)
  })

  const returnToEntriesView = useCallback(() => {
    history.push(successRoute)
  }, [history, successRoute])

  function handleDeleteClick() {
    dispatch({
      type: MODALS_SHOW,
      modalType: MODALS_CONFIRM_DELETE,
      modalProps: {
        type: "stressLevel",
        actionType: actionTypes[4],
        action: deleteStressLevelEntry,
        item: stressLevelEntry._id,
        callback: returnToEntriesView,
        providerView,
      },
    })
  }

  if (!stressLevelEntry) return null

  return (
    <Section>
      {viewMode && (
        <BackEditDeleteButtons
          handleEditClick={() => toggleViewMode(!viewMode)}
          handleDeleteClick={() => handleDeleteClick()}
          viewMode={viewMode}
          providerView={providerView}
          providerEdit={providerEdit}
        />
      )}
      <FormWrapper>
        <StressLevelEntryForm
          editMode
          viewMode={viewMode}
          valuesToEdit={stressLevelEntry}
          cancel={() => toggleViewMode(!viewMode)}
          {...props}
        />
      </FormWrapper>
    </Section>
  )
}

export default withRouter(ViewEntry)
