import axios from "axios"

import {
  SUPPORT_SUBMIT,
  ACTION_STARTED,
  ACTION_ERROR,
  ACTION_ENDED,
  ACTION_SUCCESS,
  ACTION_RESET,
} from "./types"

export const submitRequestForSupport = (
  values,
  setSubmitting,
  resetForm
) => async (dispatch) => {
  try {
    dispatch({ type: ACTION_STARTED, payload: SUPPORT_SUBMIT })
    await axios.post("/api/support/submit", values, {
      headers: { authorization: localStorage.getItem("token") },
    })
    setSubmitting(false)
    dispatch({
      type: ACTION_SUCCESS,
      payload: "submitSupportRequestSuccess",
    })
    resetForm()
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 4000)
  } catch (error) {
    setSubmitting(false)
    if (error.response) {
      dispatch({ type: ACTION_ERROR, payload: error.response.data.error })
    } else {
      dispatch({
        type: ACTION_ERROR,
        payload: "somethingWentWrong",
      })
    }
    dispatch({ type: ACTION_ENDED })
    setTimeout(() => {
      dispatch({ type: ACTION_RESET })
    }, 3000)
  }
}

const supportActions = {
  submitRequestForSupport,
}

export default supportActions
