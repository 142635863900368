import React from "react"
import TileWrapper from "./TileWrapper"
import Header from "./Header"
import TileBody from "./TileBody"
import Footer from "./Footer"

function GlobalTile(props) {
  return (
    <TileWrapper>
      <Header />
      <TileBody />
      <Footer />
    </TileWrapper>
  )
}

export default GlobalTile
