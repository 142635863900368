import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Yup from "yup";
import moment from "moment";
import { INPUT_FIELDS } from "./fields/transfer_litter_member";
import { FormTitle, FormButtons } from "components/form";

class TransferLitterMemberForm extends Component {
  // Render Fields
  renderFields = () => {
    return INPUT_FIELDS.map((field, i) => {
      return (
        <Field
          key={field.name}
          viewMode={this.props.viewMode}
          editMode={this.props.editMode}
          {...field}
        />
      );
    });
  };

  render() {
    // Render Form
    const { errors, dirty, isSubmitting, editMode, viewMode, cancel } =
      this.props;
    return (
      <>
        <Form>
          <FormTitle group={"transferLitterMember"} />
          <br />
          {this.renderFields()}
          <br />
          <FormButtons
            viewMode={viewMode}
            editMode={editMode}
            isSubmitting={isSubmitting}
            errors={errors}
            dirty={dirty}
            cancel={cancel}
            linkTo={"/userhome/dashboard/litter/litter_member_dashboard"}
          />
          <br />
        </Form>
      </>
    );
  }
}

TransferLitterMemberForm = withFormik({
  mapPropsToValues({ litterMember }) {
    return {
      date: moment().toISOString(),
      type: "petTransferInitiateReport",
      newOwnerEmail: "",
      petName: litterMember.petName,
      _pet: litterMember._id,
      _user: litterMember._user,
    };
  },
  validationSchema: Yup.object().shape({
    newOwnerEmail: Yup.string().email().required("required"),
  }),
  handleSubmit(values, { props, setSubmitting }) {
    if (props.reAttempt) {
      return props.handleReAttemptAtTransfer(values, setSubmitting);
    }
    return props.handleTransferRequest(values, setSubmitting);
  },
})(TransferLitterMemberForm);

export default withRouter(connect(null)(TransferLitterMemberForm));
