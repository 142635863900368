import React, { Component } from "react"
import Section from "components/layout/Section"
import FoodCategorySelection from "containers/features/Food_Diary/Food_Category_Selection"
import FoodDiaryEntryForm from "forms/Food_Diary_Entry_Form"
import BackEditDeleteButtons from "components/elements/Back_Edit_Delete_Buttons"
import RequireSubFeature from "components/hoc/Require_Sub_Feature"
import Message from "components/message"

class NewEntry extends Component {
  state = { stage: 1 }

  handleCategorySelection = (cat) => {
    this.setState({
      stage: 2,
      foodCategory: cat,
    })
  }

  handleBackToCategorySelection = () => {
    this.setState({
      stage: 1,
      foodCategory: "",
    })
  }

  renderStage = () => {
    const { foodCategory } = this.state
    switch (this.state.stage) {
      case 1:
        return (
          <FoodCategorySelection
            {...this.props}
            pet={this.props.selectedLitterMember}
            handleCategorySelection={this.handleCategorySelection}
            handleBackToCategorySelection={this.handleBackToCategorySelection}
          />
        )
      case 2:
        return (
          <div className="columns is-mobile is-centered">
            <div className="column is-5-desktop is-full-mobile is-6-tablet has-text-centered">
              <BackEditDeleteButtons
                backButtonFunc
                handleBackClick={this.handleBackToCategorySelection}
                disableEditButton
                disableDeleteButton
              />

              <FoodDiaryEntryForm
                valuesToEdit={{}}
                foodCategory={foodCategory}
                {...this.props}
                pet={this.props.selectedLitterMember}
              />
            </div>
          </div>
        )
      default:
      // do nothing
    }
  }

  render() {
    // check to see if litter member has new owner
    // if they do, render message to alert that
    if (this.props.selectedLitterMember.hasNewOwner)
      return (
        <Section>
          <Message warning message="memberHasNewOwner" />
        </Section>
      )

    return <Section>{this.renderStage()}</Section>
  }
}

export default RequireSubFeature(NewEntry)
