const radioButtonOptions = {
  calorieCalculator: {
    options: [
      { option: "underFourMonths", value: ["3"] },
      { option: "fourMonthsToOneYear", value: ["2"] },
      { option: "neuteredAdult", value: ["1.6"] },
      { option: "intactAdult", value: ["1.8"] },
      { option: "inactiveAdult", value: ["1.2", "1.4"] },
      { option: "inactiveAdultWeightLoss", value: ["1.0"] },
      { option: "inactiveAdultWeightGain", value: ["1.2", "1.8"] },
      { option: "highlyActiveAdult", value: ["2", "5"] },
    ],
  },
};
export default radioButtonOptions;
