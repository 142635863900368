const appointmentStatusColors = {
  valid: { background: "#a3f28d", textColor: "#fff" },
  expiring: { background: "#fede87", textColor: "#fff" },
  unconfirmed: { background: "#fede87", textColor: "#fff" },
  expired: { background: "#faaca6", textColor: "#fff" },
  canceled: { background: "#faaca6", textColor: "#fff" },
  declined: { background: "#faaca6", textColor: "#fff" },
}

export default appointmentStatusColors
