import React from "react";
import moment from "moment";
import { returnTimeInHrsAndMins } from "functions";
import StressLevel from "./Custom_Cell_Stress_Level";

function Cells(props) {
  switch (props.cellType) {
    case "date":
    case "dateCreated":
    case "startDate":
    case "endDate":
    case "breedingDate":
      return moment(props.cellContent).format("DD.MM.YYYY");
    case "onGoing":
      if (Boolean(props.cellContent))
        return props.cellMessages.yes[props.locale];
      return props.cellMessages.no[props.locale];
    case "medicine":
      if (typeof props.cellContent === "string") return props.cellContent;
      if (Boolean(props.cellContent))
        return props.cellMessages.yes[props.locale];
      return props.cellMessages.no[props.locale];
    case "temperature":
      return props.cellContent + " °C";
    case "attachmentURL":
      return <AttachmentUrl url={props.cellContent} />;
    case "notes":
    case "breedingNotes":
    case "cycleNotes":
    case "progNotes":
    case "subject":
    case "reasonForMedicine":
      if (!props.cellContent) return "-";
      return <Notes notes={props.cellContent} />;
    case "category":
      return props.categories[props.cellContent][props.locale];
    case "activity":
      return props.activities[props.cellContent][props.locale];
    case "totalTimeInSeconds":
      return returnTimeInHrsAndMins(
        props.cellContent,
        props.cells,
        props.locale
      );
    case "stressLevel":
      return <StressLevel value={props.cellContent} />;
    case "trainingType":
    case "foodCategory":
    case "type":
      return props[props.cellContent][props.locale];
    case "foodAmountUnit":
      return props.cellMessages[props.cellContent][props.locale];
    default:
      if (!props.cellContent) return "-";
      return props.cellContent;
  }
}

export default Cells;

const AttachmentUrl = ({ url }) => (
  <a rel="noopener noreferrer" href={url} target="_blank">
    {url.substring(0, 20)}
  </a>
);

const Notes = ({ notes }) => notes.substring(0, 20);
