import React from 'react';
import styles from "./customMarker.module.css";

export default function CustomMarker(props) {
  return (
    <div id={props.itemNumber} className="box is-size-7 is-paddingless">
      <div
        className={props.className ? styles[props.className] : styles.marker}
      >
        {props.itemNumber + 1}
      </div>
    </div>
  );
}
