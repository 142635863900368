// Assets
import assets from "assets";

// HOCs
import RequireAuth from "components/hoc/Require_Auth";
import RequireSelectedLitter from "components/hoc/Require_Selected_Litter";

//Litter Features
import LitterProfile from "containers/litter_features/Litter_Profile";
import LitterAddMember from "containers/litter_features/Add_Litter_Member";
import LitterVaccinations from "containers/litter_features/Litter_Vaccinations";
import LitterParasites from "containers/litter_features/Litter_Parasites";
import LitterFoodDiary from "containers/litter_features/Litter_Food_Diary";
import WeightReview from "containers/litter_features/Weight_Review";
import LitterExpenses from "containers/litter_features/Litter_Expenses";
import LitterUsefulLinks from "containers/litter_features/Litter_Useful_Links";
import LitterHomeCare from "containers/litter_features/Litter_Home_Care";

const litterRoutes = [
  // **************************
  //
  // Litter_Features
  //
  // **************************
  {
    name: "litterProfile",
    disabled: false,
    feature: true,
    litterFeature: true,
    path: "/userhome/dashboard/litter/profile",
    exact: false,
    component: RequireAuth(RequireSelectedLitter(LitterProfile)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.main.litterProfile,
      alt: "litters_profile_icon",
    },
  },
  {
    name: "weightReview",
    disabled: false,
    feature: true,
    litterFeature: true,
    path: "/userhome/dashboard/litter/weight_review",
    exact: false,
    component: RequireAuth(RequireSelectedLitter(WeightReview)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.litterFeatures.weight,
      alt: "weight_review_icon",
    },
  },
  {
    name: "litterVaccinations",
    disabled: false,
    feature: true,
    litterFeature: true,
    path: "/userhome/dashboard/litter/vaccinations",
    exact: false,
    component: RequireAuth(RequireSelectedLitter(LitterVaccinations)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.litterFeatures.vaccinations,
      alt: "vaccinate_all_icon",
    },
  },
  {
    name: "litterParasites",
    disabled: false,
    feature: true,
    litterFeature: true,
    path: "/userhome/dashboard/litter/parasites",
    exact: false,
    component: RequireAuth(RequireSelectedLitter(LitterParasites)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.litterFeatures.parasites,
      alt: "parasite_control_icon",
    },
  },
  {
    name: "litterFoodDiary",
    disabled: false,
    feature: true,
    litterFeature: true,
    path: "/userhome/dashboard/litter/food_diary",
    exact: false,
    component: RequireAuth(RequireSelectedLitter(LitterFoodDiary)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.foodDiary.main,
      alt: "feature_food_diary_icon",
    },
  },
  //LitterHomeCare
  {
    name: "litterHomeCare",
    disabled: false,
    feature: true,
    litterFeature: true,
    path: "/userhome/dashboard/litter/home_care",
    exact: false,
    component: RequireAuth(RequireSelectedLitter(LitterHomeCare)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.homeCare.main,
      alt: "litters_home_care_icon",
    },
  },
  {
    name: "litterExpenses",
    disabled: false,
    feature: true,
    litterFeature: true,
    path: "/userhome/dashboard/litter/expenses",
    exact: false,
    component: RequireAuth(RequireSelectedLitter(LitterExpenses)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.litterFeatures.expenses,
      alt: "litters_expenses_main_icon",
    },
  },
  {
    name: "litterUsefulLinks",
    disabled: false,
    feature: true,
    litterFeature: true,
    path: "/userhome/dashboard/litter/useful_links",
    exact: false,
    component: RequireAuth(RequireSelectedLitter(LitterUsefulLinks)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.features.usefulLinks.main,
      alt: "litters_useful_links_main_icon",
    },
  },
  {
    name: "litterAddMember",
    disabled: false,
    feature: true,
    litterFeature: true,
    requiresSubOrTrial: true,
    path: "/userhome/dashboard/litter/litter_add_member",
    exact: false,
    component: RequireAuth(RequireSelectedLitter(LitterAddMember)),
    permaLink: false,
    breadcrumb: true,
    showIfAuthed: true,
    petTypes: ["dog", "cat"],
    img: {
      src: assets.litterFeatures.addMember,
      alt: "add_member_icon",
    },
  },
];

export default litterRoutes;
