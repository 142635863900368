import { InputFieldV5, CheckboxV5 } from "components/form";

const INPUT_FIELDS = [
  {
    name: "firstname",
    group: "register",
    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "lastname",
    group: "register",

    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "postcode",
    group: "register",
    type: "text",
    icon: "fa fa-address-book",
    className: "input ",
    component: InputFieldV5,
    required: false,
  },
  {
    name: "email",
    group: "register",
    type: "email",
    icon: "fa fa-envelope",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "password",
    group: "register",
    type: "password",
    icon: "fa fa-key",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "password2",
    group: "register",
    type: "password",
    icon: "fa fa-key",
    className: "input ",
    component: InputFieldV5,
    required: true,
  },
  {
    name: "customerReg",
    group: "register",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: true,
    withLink: true,
  },
  {
    name: "termsCond",
    group: "register",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: true,
    withLink: true,
  },
  {
    name: "privacyPolicy",
    group: "register",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: true,
    withLink: true,
  },
  {
    name: "canBeContactedResearch",
    group: "register",
    type: "checkbox",
    icon: "",
    className: "",
    component: CheckboxV5,
    required: false,
  },
];

export { INPUT_FIELDS };
