import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Intl from "components/Intl";

const FormValidationMessageV5 = (props) => {
  const {
    serverMessage,
    clientMessage,
    touched,
    errors,
    labels,
    locale,
    group,
    name,
    server,
  } = props;

  if (serverMessage) {
    if (!_.isEmpty(server.actionError)) {
      return (
        <Message>
          <Intl
            message
            path={`components.forms.validation.common.${server.actionError.message}`}
          />
        </Message>
      );
    }
  }

  if (clientMessage && touched[name] && errors[name]) {
    if (errors[name].split("-")[0] === "greaterThanOrEqualTo") {
      let value = Number(errors[name].split("-")[1]);
      if (value === 0) value = "0";
      return (
        <Message>
          <Intl
            message
            path="components.forms.validation.common.greaterThanOrEqualTo"
            messageProps={{ field: labels[group][name][locale], value }}
          />
        </Message>
      );
    }
    if (errors[name].split("-")[0] === "lessThanOrEqualTo") {
      let value = Number(errors[name].split("-")[1]);
      return (
        <Message>
          <Intl
            message
            path="components.forms.validation.common.lessThanOrEqualTo"
            messageProps={{ field: labels[group][name][locale], value }}
          />
        </Message>
      );
    }
    if (errors[name].split("-")[0] === "min") {
      let value = Number(errors[name].split("-")[1]);
      return (
        <Message>
          <Intl
            message
            path="components.forms.validation.common.minimum"
            messageProps={{ field: labels[group][name][locale], value }}
          />
        </Message>
      );
    }
    if (errors[name].split("-")[0] === "max") {
      let value = Number(errors[name].split("-")[1]);
      return (
        <Message>
          <Intl
            message
            path="components.forms.validation.common.max"
            messageProps={{ field: labels[group][name][locale], value }}
          />
        </Message>
      );
    }
    if (errors[name] === "required") {
      return (
        <Message>
          <Intl
            message
            path="components.forms.validation.common.required"
            messageProps={{ field: labels[group][name][locale] }}
          />
        </Message>
      );
    }
    if (errors[name] === "invalid") {
      return (
        <Message>
          <Intl
            message
            path="components.forms.validation.common.invalid"
            messageProps={{ field: labels[group][name][locale] }}
          />
        </Message>
      );
    }
    if (errors[name] === "passwordsMustMatch") {
      return (
        <Message>
          <Intl
            message
            path="components.forms.validation.common.passwordsMustMatch"
          />
        </Message>
      );
    }
    if (errors[name] === "url") {
      return (
        <Message>
          <Intl message path="components.forms.validation.common.url" />
        </Message>
      );
    }
    if (errors[name].split("-")[0] === "consent") {
      let value = errors[name].split("-")[1];
      return (
        <Message>
          <Intl
            message
            path={`components.forms.validation.common.consent${value}`}
          />
        </Message>
      );
    }
    if (errors[name] === "verifyEmailCodeLength") {
      return (
        <Message>
          <Intl
            message
            path="components.forms.validation.common.verifyEmailCodeLength"
            messageProps={{ value: 8 }}
          />
        </Message>
      );
    }
    if (errors[name] === "retrievePetCodeLength") {
      return (
        <Message>
          <Intl
            message
            path="components.forms.validation.common.retrievePetCodeLength"
            messageProps={{ value: 8 }}
          />
        </Message>
      );
    }
    if (errors[name] === "resetPasswordCodeLength") {
      return (
        <Message>
          <Intl
            message
            path="components.forms.validation.common.resetPasswordCodeLength"
            messageProps={{ value: 12 }}
          />
        </Message>
      );
    }
  }
  return null;
};

const Message = (props) => {
  return (
    <div style={{ paddingTop: 4 }}>
      <article className={"message is-danger"}>
        <div className="message-body is-size-7">{props.children}</div>
      </article>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.intl.locale,
    labels: state.intl.messages.components.forms.labels,
  };
};

export default connect(mapStateToProps)(FormValidationMessageV5);
