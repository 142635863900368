import { features } from "assets"
import Landing from "components/features/Landing"
import NewEntry from "./New_Entry_View"
import ViewEntries from "./View_Entries"
import ViewEntry from "./View_Entry"
import BodyConditionScore from "./Body_Condition_Score"
import CalorieCalculator from "./Calorie_Calculator"

const weightConfig = {
  name: "weight",
  chartFunc: "weight",
  type: "feature",
  defaultRoute: "/userhome/dashboard/weight",
  menuIcon: { img: features.weight.main, alt: "weight_icon" },
  nav: [
    {
      disabled: false,
      name: "landing",
      menuItem: true,
      image: {
        img: features.infoMenuIcon,
        alt: "info_menu_icon",
      },
      icon: "fas fa-home",
      hr: true,
      exact: true,
      component: Landing,
      path: "/",
    },
    {
      disabled: false,
      name: "newEntry",
      menuItem: true,
      image: {
        img: features.weight.newEntry,
        alt: "weight_new_entry_icon",
      },
      icon: "far fa-plus-square",
      component: NewEntry,
      path: "/new_entry",
    },
    {
      disabled: false,
      name: "viewEntries",
      menuItem: true,
      image: {
        img: features.weight.viewEntries,
        alt: "weight_view_entries_icon",
      },
      icon: "fas fa-copy",
      component: ViewEntries,
      path: "/view_entries",
    },
    {
      disabled: false,
      name: "bodyConditionScore",
      petTypeReq: true,
      petTypes: ["cat", "dog"],
      menuItem: true,
      image: {
        img: features.weight.bodyConditionScore,
        alt: "body_condition_score_icon",
      },
      icon: "fas fa-copy",
      component: BodyConditionScore,
      path: "/body_condition_score",
    },
    {
      disabled: false,
      name: "calorieCalculator",
      petTypeReq: true,
      petTypes: ["dog", "cat"],
      menuItem: true,
      image: {
        img: features.weight.calorieCalc,
        alt: "calorie_calculator_icon",
      },
      icon: "fas fa-copy",
      component: CalorieCalculator,
      path: "/calorie_calculator",
    },
    {
      disabled: false,
      name: "viewEntry",
      menuItem: false,
      component: ViewEntry,
      path: "/view_entry/:id",
    },
  ],
  tableView: {
    headers: ["date", "kg", "g", "lbs"],
    mobileHeaders: ["date", "kg", "g", "lbs"],
  },
}

export default weightConfig
