import React, { Component } from "react"
import { connect } from "react-redux"
import { auth as actions } from "actions"
import moment from "moment"
import Menu from "components/menu"
import config from "./config"

class UserAccount extends Component {
  componentDidMount() {
    const lastFetch = moment().diff(this.props.auth.lastFetch, "minutes")
    if (lastFetch > 2) this.props.fetchCurrentUser(this.props.history)
  }

  render() {
    return <Menu {...config} {...this.props} />
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.reducers.auth,
    locale: state.intl.locale,
    messages: state.intl.messages.containers.auth.userAccount,
  }
}

export default connect(mapStateToProps, actions)(UserAccount)
