import React from "react"

function PostTags({ postTags, blogTags }) {
  return (
    <div className="content">
      <div className="tags">
        {postTags.map((ptag) => {
          let tag = blogTags.find((btag) => ptag === btag.id)
          if (!tag) return null
          return (
            <span key={tag.id} className="tag">
              {tag.name}
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default PostTags
